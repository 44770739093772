import React, { memo } from "react";
import { RegulationCreationOrSelectionTemplateDialogView } from "./RegulationCreationOrSelectionTemplateDialogView";
import { TemplateDialogContentTypeKeys } from "../consts/consts";

export interface RegulationCreationOrSelectionTemplateDialogProps {
  isOpen: boolean;
  onClose: () => void;
  contentType: TemplateDialogContentTypeKeys;
}

export const RegulationCreationOrSelectionTemplateDialog = memo(
  (props: RegulationCreationOrSelectionTemplateDialogProps) => {
    return <RegulationCreationOrSelectionTemplateDialogView {...props} />;
  }
);
