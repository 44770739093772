import React, { memo } from "react";
import { ILabelProps } from "./Label.interface";
import "./Label.scss";

export const Label = memo((props: ILabelProps) => {
  return (
    <div className={`ui-label ui-label_${props.type ?? "primary"}`}>
      { props.text }
    </div>
  )
});