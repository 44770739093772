import React, { memo, useMemo } from "react";
import { IssueActivityUser } from "../issueActivityUser/issueActivityUser";
import { IIssueActivityObserver } from "./issueActivityObserver.interface";
import { EntityAuditState } from "../../../../../../../../api/models/IssueActivityDto";

export const IssueActivityObserver = memo((props: IIssueActivityObserver) => {

  const isDeleted = props.state === EntityAuditState.Removed;
  const background = isDeleted ? "var(--color-calendar-default)" : "var(--color-calendar-primary-weaker)";

  return props.user ? (
    <IssueActivityUser
      user={props.user}
      background={background}
      isLineThrough={isDeleted}
    />
  ) : null;
});
