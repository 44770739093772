import "./ContentEditable.scss";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ReactElement } from "react";

export interface IContentEditable {
  className?: string;
  height?: number | string;
  editMode?: boolean;
  disabled?: boolean;
}

export default function LexicalContentEditable(props: IContentEditable): ReactElement {
  return (
    <ContentEditable
      id={"content-editable"}
      className={`${props.className || "ContentEditable__root"} ${props.disabled && "disabled-mode"}`}
      contentEditable={false}
      style={{ minHeight: props.height, padding: !props.editMode ? "" : "8px 24px" }}
    />
  );
}
