import * as React from "react";
import { ChangeEvent, forwardRef, LegacyRef, memo } from "react";

type Props = Readonly<{
  accept?: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}>;

export const FileInput = memo(
  forwardRef(({ accept, label, onChange }: Props, ref: LegacyRef<HTMLInputElement>): JSX.Element => {
    return <input ref={ref} type="file" accept={accept} onChange={onChange} style={{ display: "none" }} />;
  })
);
