import React from "react";
import { Segmented, SegmentedProps } from "antd";
import "./Segmented.scss";
import clsx from "clsx";

interface ISegmentedView extends Omit<SegmentedProps, "ref"> {
  //
}

export function SegmentedView({ className, ...rest }: ISegmentedView) {
  return <Segmented className={clsx("uiKit-segmented", className ?? "")} {...rest} />;
}
