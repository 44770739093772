import React, { useEffect, useState } from "react";
import "./ZoomButton.scss";
import { Button, Card, Icon, IconButton, Input, Text } from "../../uiKit";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import useDebounce from "../../../hooks/useDebounce";

export interface IZoomButtonView {
  value: number;
  step: number;
  min: number;
  max: number;
  valueToReset?: number;
  buttonProps?: {
    text?: {
      afterValue?: string;
    };
  };

  handleChange(value: number): void;
  handleReset(): void;
  handleSetZoomValue(value: number): void;
}

function ZoomButtonView(props: IZoomButtonView) {

  const [zoomValue, setZoomValue] = useState(props.value);
  const debouncedZoomValue = useDebounce(zoomValue, 500);

  useEffect(() => {
    const minZoomValue = debouncedZoomValue < props.min ? props.min : debouncedZoomValue;
    const maxZoomValue = debouncedZoomValue > props.max ? props.max : minZoomValue;
    props.handleSetZoomValue(maxZoomValue);
  }, [debouncedZoomValue]);

  useEffect(() => {
    setZoomValue(props.value);
  }, [props.value]);


  return (
    <Card
      className="br-md flex-shrink-0 zoom-wrap"
      style={{ boxShadow: "none" }}
      bodyStyle={{ padding: "0px", display: "flex", flexWrap: "nowrap", alignItems: "center" }}
    >
      <IconButton
        disabled={props.value <= props.min || !CSS.supports("zoom", "1")}
        onClick={() => props.handleChange(-props.step)}
        type="text"
        // size="small"
        icon={<Icon component={() => <FiMinus color="var(--color-primary-base)" />} />}
      />
      <div className="zoom-input">
        <Input
          className="x-2"
          size="small"
          type={"number"}
          style={{
            maxWidth: 40,
            fontSize: 14,
            color: "var(--color-primary-base)"
          }}
          value={zoomValue}
          onChange={(event) => {
            const zoom =  +event.currentTarget.value
            if (zoom < 0 || zoom > props.max) {
              setZoomValue( zoom < 0 ? 1 : props.max)
            } else {
              setZoomValue(+event.currentTarget.value);
            }
          }}
        />
        <Text style={{ color: "var(--color-primary-base)" }} children={props.buttonProps?.text?.afterValue ?? "%"} />
      </div>
      <IconButton
        disabled={props.value >= props.max || !CSS.supports("zoom", "1")}
        onClick={() => props.handleChange(props.step)}
        type="text"
        // size="small"
        icon={<Icon component={() => <FiPlus color="var(--color-primary-base)" />} />}
      />
    </Card>
  );
}

export default ZoomButtonView;
