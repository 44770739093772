import React from "react";
import "./dropdownMenu.scss";
import { IDropdownMenu } from "./dropdownMenu.interface";
import { FaEllipsisV } from "@react-icons/all-files/fa/FaEllipsisV";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaEllipsisH } from "@react-icons/all-files/fa/FaEllipsisH";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import { Button, Icon, IconButton } from "../../uiKit";
import { Dropdown } from "antd";

export const DropdownMenu = (props: IDropdownMenu) => {
  const getBtnIcon = () => {
    if (props.iconType == "dots") return <FaEllipsisV color="var(--color-primary-base)" />;
    if (props.iconType == "dots-horizontal") return <FaEllipsisH color="var(--color-primary-base)" />;
    if (props.iconType == "chevron") return <FaChevronDown color="var(--color-primary-base)" />;
    return <FaEllipsisV color="var(--color-primary-base)" />;
  };

  const onOpenChange = (value: boolean) => {
    props.onOpenChange?.(value)
  }

  return (
    <Dropdown
      menu={{
        items: props.items
          .filter((i) => !i.hidden)
          .map((i) => ({
            key: i.id,
            icon: i.icon,
            label: i.text,
            disabled: i.disabled,
            // onTitleClick: ({ key, domEvent }) => {
            //   console.log(i.text);
            //   i.action;
            // },
            onClick: ({ domEvent }) => {
              i.action();
              domEvent.stopPropagation();
            },
          })),
      }}
      disabled={props.buttonProps?.disabled || props.disabled}
      destroyPopupOnHide
      trigger={props.trigger ?? ["click"]}
      placement={props.placement}
      onOpenChange={onOpenChange}
    >
      {props.children ?? (props?.buttonProps?.text ?? "").trim().length > 0 ? (
        <Button
          // icon={<FiChevronDown style={{ marginLeft: "8px" }} size="20px" className="filter-communications-icon" />}
          type={props.buttonProps?.type ?? "primary"}
          size={props.buttonProps?.size ?? "middle"}
          className={props.buttonProps?.className}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {props.buttonProps?.text}
            <FiChevronDown style={{ marginLeft: "8px" }} />
          </div>
        </Button>
      ) : (
        <IconButton
          size={props.buttonProps?.size ?? "middle"}
          disabled={props.disabled}
          // type="default"
          onClick={(event) => event.stopPropagation()}
          // shape="circle"
          icon={<Icon component={() => getBtnIcon()} />}
        />
      )}
    </Dropdown>
  );
};
