import { Avatar, Text } from "../../../uiKit";
import React, { memo } from "react";
import { useApi } from "../../../../hooks";
import { api } from "../../../../services";

export interface IOrgchartComponent {
  id: number;
  color: string;
}

export const OrgchartComponent = memo((props: IOrgchartComponent) => {
  const orgchart = useApi(() => api.orgchart.getById(props.id));

  return (
    <span
      style={{
        padding: "2px",
        verticalAlign: "middle",
        borderRadius: "var(--border-radius-sm)",
        display: "inline-block",
        pointerEvents: "none",
        userSelect: "none",
        backgroundColor: props.color,
      }}
      contentEditable={false}
    >
      <div className="d-stack-row align-center">
        <Avatar
          size={23}
          src={orgchart.value?.image?.url}
          text={orgchart.value?.name}
          color={orgchart.value?.colorHex}
        />
        <Text className="ml-2" children={<>{orgchart.value?.name}</>} />
      </div>
    </span>
  );
});
