import { IValidatorParams } from "./interfaces/validator";
import { IVerificationCode } from "./interfaces/verificationCode.interface";

export const verificationCode =
  ({ ...params }: IVerificationCode) =>
  ({ ...validator }: IValidatorParams): void => {
    const defaultValidationError = params.t("validation:invalid_code");
    if (validator.value) {
      const valueIsNumber = (value: number | string): boolean => !isNaN(Number(value));
      if (params.isNumberOnly && !valueIsNumber(validator.value)) return validator.callback(defaultValidationError);
      if (params.length && validator.value.toString().length !== params.length)
        return validator.callback(defaultValidationError);
      validator.callback();
    } else validator.callback(defaultValidationError);
  };
