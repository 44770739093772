import "./ScheduleRow.scss";
import { RoleDto, User2RoleDto, UserScheduleDto } from "../../../../../api";
import { Card, CollapsibleContainer, Tooltip } from "../../../../uiKit";
import clsx from "clsx";
import { LucideChevronRight } from "lucide-react";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import React, { memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { ScheduleCell } from "../scheduleCell/ScheduleCell";
import { SelectableGroup } from "react-selectable-fast";

interface IScheduleRowProps {
  role: RoleDto;
  isExpanded: boolean;
  datesToDisplay: Dayjs[];
  defaultPeriodSchedule: (UserScheduleDto | undefined)[];
  selectionResetTrigger: number;
  useUserTimeZone: boolean;

  onExpandChange: () => void;
  onSelectionFinish: (selectedItems: any[], user2role: User2RoleDto) => void;
}

export const ScheduleRow = memo((props: IScheduleRowProps) => {
  const { t } = useTranslation();
  const selectableGroupRef = useRef<(SelectableGroup | null)[]>([]);

  const todayDate = useRef<Dayjs>(
    dayjs
      .utc({
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
      .tz()
      .startOf("day")
  );

  const handleSelectionClear = () => {
    selectableGroupRef.current.forEach((ref) => ref?.clearSelection());
  };

  useEffect(() => {
    handleSelectionClear();
  }, [props.selectionResetTrigger]);

  return (
    <div className="schedule-row__wrapper">
      <div className="schedule__row__role-wrapper no-select d-stack-column spacing-2" key={props.role.id!}>
        <Tooltip title={props.role.name} placement="right">
          <div
            className={clsx("schedule__row__role__expander", {
              __expanded: props.isExpanded,
            })}
            onClick={props.onExpandChange}
          >
            <LucideChevronRight size={14} color="var(--color-text-weak)" className="mr-1 flex-shrink-0" />
            <span className="__role-name" children={props.role.name} />
          </div>
        </Tooltip>
        <CollapsibleContainer isOpen={props.isExpanded}>
          <div className="d-stack-column spacing-1">
            {props.role.users?.map((u2r) => (
              <div key={u2r.id as number} className="d-flex">
                <div className="schedule__row__role__user-card__wrapper __pztZoom">
                  <Card isShadowed={false} className="schedule__row__role__user-card">
                    <UserInfo user={u2r.user} />
                    {(u2r.substitutes ?? []).length > 0 && <span className="__substitute" children={t("parse:sub")} />}
                  </Card>
                </div>
                <SelectableGroup
                  ref={(el) => (selectableGroupRef.current = [...selectableGroupRef.current, el])}
                  className="d-stack spacing-1"
                  enableDeselect
                  scrollContainer="#pageContent"
                  tolerance={0}
                  // globalMouse
                  ignoreList={[".__disabled"]}
                  onSelectionFinish={(selectedItems: any[]) => props.onSelectionFinish(selectedItems, u2r)}
                  resetOnStart
                >
                  {props.datesToDisplay.map((date, dateIndex) => (
                    <ScheduleCell
                      key={date.toISOString()}
                      date={date}
                      user2role={u2r}
                      index={dateIndex}
                      useUserTimeZone={props.useUserTimeZone}
                      isDisabled={!props.role.isScheduleUpdateAllowed || date.tz().isBefore(todayDate.current, "date")}
                      defaultPeriodSchedule={props.defaultPeriodSchedule}
                    />
                  ))}
                </SelectableGroup>
              </div>
            ))}
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
});
