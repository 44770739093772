import React from "react";
import { AvatarProps } from "antd";
import { AvatarView } from "./AvatarView";

export type TAvatar = "user" | "company";

interface IAvatar extends AvatarProps {
  type?: TAvatar;
  isOutlined?: boolean;
  color?: string | null;
  text?: string | null | (string | undefined | null)[];
  useFullText?: boolean;
  isNoFlex?: boolean;
}

export function Avatar(props: IAvatar) {
  return <AvatarView {...props} />;
}
