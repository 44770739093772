import React from 'react';
import { IProgress } from "./Progress.interface";
import { Progress } from "antd";
import "./Progress.scss";

export const ProgressView = (props: IProgress) => {
  return (
    <Progress
      style={{ fontSize: "12px" }}
      percent={props.percent}
      format={props.format}
      className={`ui-progress ${props.className}`}
      status={props.status}
    />
  );
};