import { AppPageTabType } from "../../../../../../utils/appLinksAndTabs";
import { dashboardSectionAndRoutesKeys, dashboardSections } from "../constants/routesKeys";

export const getDashboardSection = (uri?: string): dashboardSectionAndRoutesKeys | number | null => {
  if (dashboardSections.includes(<dashboardSectionAndRoutesKeys>uri)) return uri as dashboardSectionAndRoutesKeys;
  if (uri && !dashboardSections.includes(<dashboardSectionAndRoutesKeys>uri)) return Number(uri) as number;
  return null;
};

export interface DashboardPageTabType extends AppPageTabType {
  uri: dashboardSectionAndRoutesKeys;
}

const getTabLocalizationBySection = (key: dashboardSectionAndRoutesKeys): string => {
  switch (key) {
    case dashboardSectionAndRoutesKeys.myMetrics:
      return "common:tab.dashboard.my_metrics";
    case dashboardSectionAndRoutesKeys.mySubordinates:
      return "common:tab.dashboard.my_subordinates";
    case dashboardSectionAndRoutesKeys.fillOut:
      return "common:tab.dashboard.fill_out";
  }
};

export const getDashboardTabs = (): DashboardPageTabType[] =>
  dashboardSections.map((item, index) => ({
    id: index,
    key: "dashboard",
    link: `/dashboard/${item}`,
    name: getTabLocalizationBySection(item),
    uri: item,
  }));
