import React from "react";
import "./RestorePasswordForm.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { Button, Input, InputPassword } from "../../../uiKit";

interface IRestorePasswordFormView {
  step: number;
  isLoading: boolean;

  setStep(value: number): void;

  handleSendCode({ email }: { email: string }): void;

  handleReset({ email, code, password }: { email: string; code: string; password: string }): void;
}

function RestorePasswordFormView(props: IRestorePasswordFormView) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Form
      className="full-width"
      validateTrigger="onSubmit"
      requiredMark={false}
      autoComplete="on"
      layout="vertical"
      onFinish={(values) => {
        props.step == 0
          ? props.handleSendCode({ email: values.login })
          : props.handleReset({ email: values.login, password: values.password, code: values.code });
      }}
    >
      <Form.Item
        name="login"
        label={t("ui:subheader.email")}
        rules={[
          { required: true, message: t("validation:field_required") },
          { type: "email", message: t("validation:wrong_email") },
        ]}
        normalize={(value) => value.trim()}
      >
        <Input disabled={props.step == 1} name="login" size="large" placeholder={t("ui:placeholder.email")} />
      </Form.Item>
      {props.step == 1 && (
        <>
          <Form.Item
            name="code"
            label={t("ui:subheader.restore_code")}
            normalize={(value) => (value || "").toUpperCase().trim()}
            rules={[
              { required: true, message: t("validation:field_required") },
              { min: 5, max: 5, message: t("validation:min_char_number", { number: 5 }) },
            ]}
          >
            <Input autoComplete="off" name="code" size="large" placeholder={t("ui:placeholder.restore_code")} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("ui:subheader.password")}
            rules={[
              { required: true, message: t("validation:field_required") },
              { min: 6, message: t("validation:min_char_number", { number: 6 }) },
            ]}
          >
            <InputPassword name="password" size="large" placeholder={t("ui:placeholder.password")} />
          </Form.Item>
          <Form.Item
            name="passwordCheck"
            label={t("ui:subheader.password_check")}
            rules={[
              { required: true, message: t("validation:field_required") },
              { min: 6, message: t("validation:min_char_number", { number: 6 }) },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("validation:passwords_should_match")));
                },
              }),
            ]}
          >
            <InputPassword name="passwordCheck" size="large" placeholder={t("ui:placeholder.password")} />
          </Form.Item>
        </>
      )}

      <Form.Item className="mt-6">
        <div className="d-flex">
          <Button size="large" variant="outlined" allowTextWrap onClick={() => navigate("/login")}>
            {t("ui:button.already_registered")}
          </Button>
          <Button
            className="ml-4 flex-grow-1"
            loading={props.isLoading}
            allowTextWrap
            htmlType="submit"
            size="large"
            variant="filled"
          >
            {props.step == 0 ? t("ui:button.send_reset_code") : t("ui:button.save")}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default RestorePasswordFormView;
