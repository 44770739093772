import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { UserDto } from "../../../../api";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";

interface IWatchUserCardDialogView {
  open: boolean;
  userId?: number;
  allUsers: UserDto[];

  setUserId: (id: number | undefined) => void;

  onClose(): void;

  onAccept(): void;
}

function WatchUserCardDialogView(props: IWatchUserCardDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("parse:view_user_card")} open={props.open} onClose={() => props.onClose()} closable={false}>
      <div>
        <SubheaderText text={t("parse:user")} />
        <AutocompleteAsync
          type="user"
          style={{ width: "100%" }}
          requestOptions={{ ignoreContext: true }}
          allowClear
          value={props.userId}
          onChange={(value) => {
            props.setUserId(value ? Number(value as number) : undefined);
          }}
        />
      </div>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button disabled={!props.userId} onClick={props.onAccept} variant="filled">
          {t("ui:button.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WatchUserCardDialogView;
