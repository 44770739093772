import React, { useEffect, useMemo, useRef, useState } from "react";
import { IListMessengersBotDialogInterface } from "./ListMessengersBotDialog.interface";
import { messengerKeys } from "../../pages/groupCompany/constants/keys";
import { useTranslation } from "react-i18next";
import { useNotifier } from "../../../../hooks";
import { api } from "../../../../services";
import { ActivateMessengerBotDialog } from "../activateMessengerBotDialog/ActivateMessengerBotDialog";
import { ListMessengersBotViewDialog } from "./ListMessengersBotViewDialog";

export const ListMessengersBotDialog = (props: IListMessengersBotDialogInterface) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const timerRef = useRef<{ resetTimer?: () => void }>();

  const [currentTypeMessenager, setCurrentTypeMessenager] = useState<number | undefined>(undefined);
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);

  const countValueForValidateTimer = 3;
  const verificationCodeLength = useMemo(
    () => (currentTypeMessenager == messengerKeys.telegram ? 5 : 5),
    [currentTypeMessenager]
  );

  const currentMessangerId: number | undefined = useMemo(
    () => props.messengers?.find((item) => item.type == currentTypeMessenager)?.id,
    [currentTypeMessenager]
  );

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleOpenDialog = (type: number) => {
    if (type) {
      setCurrentTypeMessenager(type);
      setIsOpenVerificationDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenVerificationDialog(false);
    setCurrentTypeMessenager(undefined);
    timerRef.current?.resetTimer && timerRef.current?.resetTimer();
  };

  const handleActivateBot = async () => {
    if (!currentMessangerId || !currentTypeMessenager) return handleApiError();
    !isOpenVerificationDialog && handleOpenDialog(currentTypeMessenager);
    const r = await api.companyGroup.activate(currentMessangerId);
    if (r == null) {
      handleApiError();
      handleCloseDialog();
    }
  };

  useEffect(() => {
    if (currentTypeMessenager && isOpenVerificationDialog) handleActivateBot();
  }, [currentTypeMessenager]);

  return (
    <>
      {isOpenVerificationDialog && (
        <ActivateMessengerBotDialog
          ref={timerRef}
          isOpen={isOpenVerificationDialog}
          onClose={handleCloseDialog}
          onActivateBot={handleActivateBot}
          defaultCodeLength={verificationCodeLength}
          timerValueMinute={countValueForValidateTimer}
          codeIsNumberOnly
          withTimer
        />
      )}
      <ListMessengersBotViewDialog
        onClose={props.onClose}
        isOpen={props.isOpen}
        messangers={props.messengers}
        onActivateBot={handleOpenDialog}
      />
    </>
  );
};
