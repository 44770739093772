import React, { useEffect, useState } from "react";
import { Button, Card, Dialog, DialogActions, Empty, Icon, IconButton, Spin, Text } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserShortDto } from "../../../../../api";
import { useClaims } from "../../../../../hooks";
import { permissionKeys } from "../../../../../utils/permissions";
import { AutocompleteAsync } from "../../../../elements/autocompleteAsync/AutocompleteAsync";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { FiTrash } from "react-icons/fi";

interface IModuleRegulationRestudyAccessViewProps {
  isLoading: boolean;
  isAddDialogOpen: boolean;
  usersWhoCantResendToRestudy: UserShortDto[];

  onUserAdd: (userId: number | null) => void;
  onUserDelete: (userId: number) => void;
  setIsAddDialogOpen: (isOpen: boolean) => void;
}

interface IModuleRegulationRestudyAccessAddDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  userIds: number[];

  onAdd: (userId: number | null) => void;
  onClose: () => void;
}

const ModuleRegulationRestudyAccessAddDialog = (props: IModuleRegulationRestudyAccessAddDialogProps) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    setUserId(null);
  }, [props.isOpen]);

  return (
    <Dialog title={t("parse:select_user_who_allowed_resend")} open={props.isOpen} onClose={props.onClose}>
      <AutocompleteAsync
        type="user"
        className="full-width"
        value={userId}
        onChange={setUserId}
        disabled={props.isLoading}
        idsToFilter={props.userIds}
      />
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={() => props.onAdd(userId)}
          disabled={userId == null}
          variant="filled"
          loading={props.isLoading}
        >
          {t("ui:button.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ModuleRegulationRestudyAccessView = (props: IModuleRegulationRestudyAccessViewProps) => {
  const { t } = useTranslation();
  const claims = useClaims();

  return (
    <>
      <ModuleRegulationRestudyAccessAddDialog
        isOpen={props.isAddDialogOpen}
        isLoading={props.isLoading}
        userIds={props.usersWhoCantResendToRestudy.map((u) => u.id) as number[]}
        onAdd={props.onUserAdd}
        onClose={() => props.setIsAddDialogOpen(false)}
      />
      <div className="d-stack-column spacing-4">
        <Text>{t("parse:users_who_allowed_resend")}</Text>
        <Spin spinning={props.isLoading} wrapperClassName={"full-height"}>
          {props.usersWhoCantResendToRestudy.length == 0 && !props.isLoading ? (
            <Empty className="py-6" isVerticallyCentered />
          ) : (
            <div className="d-stack-column spacing-2">
              {props.usersWhoCantResendToRestudy.map((user) => (
                <Card variant="secondary" isShadowed={false} key={user.id}>
                  <div className="d-flex align-center">
                    <div className="flex-grow-1 mr-2" style={{ overflow: "hidden" }}>
                      <UserInfo user={user} isTooltipDisabled={true} />
                    </div>
                    {claims.has(permissionKeys.company.employees.managePermissionsForResendRegulation) && (
                      <IconButton
                        onClick={() => props.onUserDelete(user.id as number)}
                        loading={props.isLoading}
                        icon={<Icon component={() => <FiTrash />} />}
                        type="text"
                        size="small"
                        danger
                      />
                    )}
                  </div>
                </Card>
              ))}
            </div>
          )}
        </Spin>
        {claims.has(permissionKeys.company.employees.managePermissionsForResendRegulation) && (
          <Button loading={props.isLoading} className="align-self-start" onClick={() => props.setIsAddDialogOpen(true)}>
            {t("ui:button.add")}
          </Button>
        )}
      </div>
    </>
  );
};
