import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageContentLoader from "../../../service/pageContentLoader/PageContentLoader";
import { PositionSelectionBox } from "./PositionSelectionBox";
import { RoleSelectionBox } from "./RoleSelectionBox";
import { IUserSelectionDialogView, positionsKeys } from "./UserSelectionDialog.interface";
import { Button, Dialog, DialogActions, Divider, Spin } from "../../../uiKit";
import { useRootStore } from "../../../../hooks";

export const UserSelectionDialogView = (props: IUserSelectionDialogView) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [disabledFromDown, setDisabledFromDown] = useState<boolean>(false);

  // const allAllIsDisabled = () =>
  //   props.orgchartsForBlockAllAll?.find((orgchartId) => orgchartId === props.orgchartId)
  //     ? props.activeRoleId === null
  //     : false;

  const handleCheckPositionType = () =>
    props.rolePositionId === positionsKeys.allManagers || props.rolePositionId === positionsKeys.allPerformers;

  const isSupportOldUi = authStore.getCurrentCompanyUiType == 1;
  // const isSupportOldUi = true;

  // const handleCheckPositionType = () => props.rolePositionId === positionsKeys.allManagers;

  return (
    <Dialog
      open={props.open}
      width={isSupportOldUi ? 853 : 646}
      title={t("ui:subheader.role_select")}
      scrollStrategy="dialogBody"
      onClose={props.onClose}
      styles={{
        body: {
          display: "flex",
        }
      }}
      // bodyStyle={{
      //   display: "flex",
      // }}
      closable={false}
    >
      <div className="user-selection-dialog__content">
        <Spin
          size="large"
          spinning={props.isLoading}
        >

          <div className="d-flex align-stretch" style={{ width: "100%", height: "100%" }}>
            <RoleSelectionBox
              isSupportOldUi={isSupportOldUi}
              orgchartId={props.orgchartId}
              onChangeOrgchartId={props.onChangeOrgchartId}
              // rolesIsDone={props.rolesIsDone}
              // labels={
              //   isSupportOldUi
              //     ? props.roles.map((item) => ({ id: item.id!, name: item.name! }))
              //     : props.rolesAutocomplete.map((item) => ({ id: item.id!, name: item.text! }))
              // }
              // activeLabelId={props.activeRoleId}
              activeRole={props.activeRole}
              onSelectActiveRole={props.onSelectActiveRole}
              searchRoleValue={props.searchRoleValue}
              // loadNextRoles={props.loadNextRoles}
              onChangeSearchRoleValue={props.onChangeSearchRoleValue}
              treeData={props.treeData}
              // onLoadData={props.onLoadData}
              expandedKeys={props.expandedKeys}
              onExpand={props.onExpand}
              selectedRoles={props.selectedRoles}
              setSelectedRoles={props.setSelectedRoles}
              updateTreeItemData={props.updateTreeItemData}
            />
            {isSupportOldUi && <Divider isVertical className="mx-4" />}
            {isSupportOldUi && (
              <PositionSelectionBox
                orgchartId={props.orgchartId}
                disabledRules={props.disabledRules}
                rolePositionId={props.rolePositionId}
                role={props.activeRole}
                setDisabledFromDown={setDisabledFromDown}
                // disabledAllAll={allAllIsDisabled()}
                onChangeRolePositionId={props.onChangeRolePositionId}
                positions={props.currentPositions}
                updateRolePosition={props.updateRolePosition}
                treeData={props.treeData}
              />
            )}
          </div>
        </Spin>
      </div>
      <DialogActions>
        <Button disabled={props.isLoading} onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          // disabled={(allAllIsDisabled() && !handleCheckPositionType()) || disabledFromDown}
          onClick={props.onSave}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
