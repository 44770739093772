import React, { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./ColorPicker.scss";
import { IColorPickerProps } from "./IColorPicker";
import { hex2rgb } from "../../../utils/hex2rgb";
import clsx from "clsx";
// import { FiArrowDown } from "@react-icons/all-files/fi/FiArrowDown";
import { Icon } from "../icon/Icon";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { ArrowDownIcon } from "../../../constants/icon";

export const ColorPicker = memo((props: IColorPickerProps) => {

  const colors = ["#252525", "#EB2F96", "#52C41A", "#F5222D", "#FA541C", "#FA8C16", "#FAAD14",
  "#A0D911", "#13C2C2", "#1890FF", "#2F54EB", "#722ED1"];

  const getColor = (color: string, alfa?: number) => {
    const {r, g, b} = hex2rgb(color) ?? {};

    return r && g && b ? `rgba(${r}, ${g}, ${b}, ${alfa ?? 1})` : color;
  }

  const getBorderColor = useCallback((color) => {
    if (props.activeColor === color) {
      return getColor(color, 1);
    }
    return getColor(color, props.isNoBorder ? 0 : 0.4);
  }, [props.activeColor, props.isNoBorder]);

  const getBackgroundColor = useCallback((color) => {
    if (props.activeColor === color || !props.isNoBorder) {
      return getColor(color, 0.05);
    }
    return getColor(color, 0);
  }, [props.activeColor, props.isNoBorder]);

  const [isShowColorList, setIsShowColorList] = useState<boolean>(false);
  const [colorListPosition, setColorListPosition] = useState({
    top: '0',
    left: '0',
  });

  const colorRef = useRef<HTMLDivElement>(null);

  const handleToggleShowColorList = useCallback((e) => {
    // e.stopPropagation();
    if (colorRef?.current) {
      const el = e.currentTarget;
      if (!colorRef.current.contains(e.target)) {
        setIsShowColorList(false);
      } else if (el) {
        // console.log("el.getBoundingClientRect()", el.getBoundingClientRect());
        if (el.getBoundingClientRect) {
          const { left, top } = el.getBoundingClientRect?.() ?? {};
          //
          setColorListPosition({
            left: `${left - 16}px`,
            top: `${top + 25}px`
          });
          setIsShowColorList((prev) => !prev);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isShowColorList) {
      document.addEventListener("click", handleToggleShowColorList);
    } else {
      document.removeEventListener("click", handleToggleShowColorList);

    }
  }, [isShowColorList]);

  const handleChangeColor = useCallback((color: string) => {
    props.onChangeColor(color);
  }, [])


  return (
    <div className="color-picker">
      {!props.isOpen && (
        <div
          ref={colorRef}
          className={clsx("color-picker-item", {
            "color-picker-item_open": isShowColorList
          })}
          onClick={handleToggleShowColorList}
        >
          <div className="color-picker-item__color">
            <div
              style={{
                background: props.activeColor
              }}
            />
          </div>
          <div className="color-picker-item__arrow">
            <Icon component={() => <ArrowDownIcon size={16} />} />
          </div>
        </div>
      )}
      {(isShowColorList || props.isOpen) && (
        <div
          className={clsx("color-picker-list",
            {
              "color-picker-list_row": props.isRow,
              "color-picker-list_static":  props.isOpen
            }
          )}
          style={colorListPosition}
        >
          {colors.map((color) => (
            <div
              className="color-picker-list-item"
              onClick={() => handleChangeColor(color)}
            >
              <div
                className="color-picker-list-item__border"
                style={{ borderColor: props.activeColor === color ? getBorderColor(color) : "transparent" }}
              />
              <div
                className="color-picker-list-item__bg"
                style={{ background: props.activeColor === color ? getBackgroundColor(color) : "transparent" }}
              />
              <div
                className="color-picker-list-item__content"
                style={{ background: color }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
})