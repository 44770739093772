import React, { ChangeEvent, memo, useCallback } from "react";
import { IconButton, Input, Popover } from "../../../../uiKit";
import { currentUrl, urlValidation } from "../../utils/urlValidation";
import { sanitizeUrl } from "../../utils/url";
import { LexicalEditor } from "lexical";
import { useTranslation } from "react-i18next";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { LucideCheck, LucideLink } from "lucide-react";
import { LINK_ATTRIBUTES } from "../../plugins/AutoLinkPlugin/AutoLinkPlugin";
import { LinkIcon } from "../../../../../constants/icon";

interface LinkPopupProps {
  openLinkPopup: boolean;
  setOpenLinkPopup: (value: boolean) => void;
  linkUrl: string;
  setLinkUrl: (value: string) => void;
  editor: LexicalEditor;
  isEditable: boolean;
  isLink: boolean;
  activeLinkButton: boolean;
  insertLink: () => void;
  isNewStyle?: boolean;
}

export const LinkPopup = memo((props: LinkPopupProps) => {
  const { t } = useTranslation();

  const handleUrlChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.setLinkUrl(event.target.value);
    },
    [props]
  );

  const handleClick = useCallback(() => {
    props.editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
      url: sanitizeUrl("https://" + currentUrl(props.linkUrl)),
      ...LINK_ATTRIBUTES,
    });
    props.setOpenLinkPopup(false);
  }, [props]);

  const handleOpenChange = useCallback(() => {
    if (!props.isEditable || !props.activeLinkButton) return;
    !props.isLink && props.setOpenLinkPopup(!props.openLinkPopup);
    // props.setOpenLinkPopup(!props.openLinkPopup);
  }, [props]);

  return (
    <Popover
      placement="bottom"
      trigger={"click"}
      open={props.openLinkPopup}
      onOpenChange={handleOpenChange}
      content={
        <div className="d-stack-row align-center justify-center">
          <Input
            size="middle"
            status={urlValidation(props.linkUrl) || !props.linkUrl ? "" : "error"}
            value={props.linkUrl}
            onChange={handleUrlChange}
            placeholder={t("ui:placeholder.text_editor_url")}
          />
          <IconButton
            className="ml-3"
            icon={<LucideCheck size={18} />}
            onClick={handleClick}
            disabled={!urlValidation(props.linkUrl) || !props.linkUrl}
          />
        </div>
      }
    >
      <IconButton
        disabled={!props.isEditable || !props.activeLinkButton}
        onClick={props.insertLink}
        title={t("common:text_editor_toolbar.link")}
        icon={props.isNewStyle ? <LinkIcon/> : <LucideLink size={18} className={props.isLink ? "toolbar__active" : ""} />}
      />
    </Popover>
  );
});
