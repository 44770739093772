export enum messengerKeys {
  telegram = 1,
  whatsapp,
}

export enum messengerConfigFieldKeys {
  api_id = "api_id",
  api_hash = "api_hash",
  phone_number = "phone_number",
  first_name = "first_name",
  last_name = "last_name",
}
