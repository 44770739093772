import React, { useCallback } from "react";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserCard } from "../../../elements/userCard/UserCard";
import "./NoAccessResource.scss";
import { useNotifier } from "../../../../hooks";
import { INoAccessResource } from "./NoAccessResource.interface";

export const NoAccessResource = (props: INoAccessResource) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const handleClickCopyLink = useCallback(async () => {
    await navigator.clipboard
      .writeText(props.item.link)
      .then(() => notifier.show({ message: t("notifier:success.copy"), theme: "success" }))
      .catch(() => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" }));
  }, [props.item.link]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      bodyStyle={{ paddingBottom: 4 }}
      title={props.item.isSection ? t("ui:no_access_resource_dialog.section_title") : t("ui:no_access_resource_dialog.title")}
      closable={false}
      isNewDesign
      className={"no-access-resource-dialog"}
    >
      <div className="no-access-resource">
        <div className="no-access-resource__text">
          { props.item.isSection
            ? t("ui:no_access_resource_dialog.section_text")
            : t("ui:no_access_resource_dialog.text")
          }
        </div>
        <div className="no-access-resource-content">
          <div className="no-access-resource-content__title">
            { props.item.title }
          </div>
          <UserCard
            userInfo={props.item.user}
            boldName
            avatarSize={20}
            // additionalInfo={`${props.dateSented}`}
            isNewStyle={true}
            nameSize={12}
          />
          <Button
            onClick={handleClickCopyLink}
            variant="link"
          >
            { t("ui:no_access_resource_dialog.copy_link") }
          </Button>
        </div>

      </div>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="default"
        >
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}