import React from "react";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { useTranslation } from "react-i18next";

export const CancelButton: React.FC<IRegulationIcons> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.cancel")}>
      <IconButton className="regulation-button-icons__cancel" disabled={props.disabled} onClick={props.onClick}>
        <MdCancel size={22} />
      </IconButton>
    </Tooltip>
  );
};
