import React from "react";
import { DrawerProps } from "antd";
import { DrawerView } from "./DrawerView";

interface IDrawer extends DrawerProps {
  //
}

export function Drawer(props: IDrawer) {
  // useEffect(() => {
  //   const mainEl = document.getElementsByClassName("app-content__wrapper")[0];
  //   if (mainEl == null) return;
  //
  //   if (props.open) {
  //     // Drawer opened
  //     mainEl.classList.add("__overflowed");
  //   } else {
  //     mainEl.classList.remove("__overflowed");
  //   }
  // }, [props.open]);
  return <DrawerView placement="right" {...props} />;
}
