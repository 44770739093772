import React, { memo, useCallback, useMemo } from "react";
import { ISubordinatesList } from "./SubordinatesList.interface";
import { AvatarGroup, Icon } from "../../../../../uiKit";
import { Subordinate } from "../subordinate/Subordinate";
import "./SubordinatesList.scss";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { useTranslation } from "react-i18next";


export const SubordinatesList = memo((props: ISubordinatesList) => {
  const { t } = useTranslation();
  const isCheckedHiddenUser = useMemo(() => {
    // @ts-ignore
    const lastIndex: number = props.subordinatesList.findLastIndex((item) => props.filter.includes(item.id));
    return lastIndex >= (props.maxCount ?? 6);
  }, [props.subordinatesList, props.filter]);

  return props.subordinatesList.length > 0 ? (
    <div className={"subordinates-list"}>
      <AvatarGroup
        maxCount={props.maxCount ?? 6}
        // className={"subordinates-list"}
        prefixCls={"subordinates-list"}
        maxStyle={{
          border: isCheckedHiddenUser ? "2px solid var(--color-calendar-primary-base)" : "2px solid var(--color-layout-drawer)",
          width: 32,
          height: 32,
          boxSizing: "border-box",
        }}
      >
        {
          props.subordinatesList.map((user, i) => (
            <Subordinate
              key={user.id}
              user={user}
              index={i}
              isActive={props.filter.includes(user.id!)}
              onChange={props.onChangeFilter}
              isChoiceOnlyOneUser={props.isChoiceOnlyOneUser}
              maxCount={props.maxCount ?? 6}
            />
          ))
        }
      </AvatarGroup>
      {!!props.filter.length && (
        <div
          className={"subordinates-list-clear"}
          onClick={props.onResetFilter}
        >
          <div className="subordinates-list-clear__icon">
            <Icon component={() => <FiX size={16} />}/>
          </div>
          <div className="subordinates-list-clear__text">
            { t("ui:button.reset") }
          </div>
        </div>
      )}
    </div>
  ) : null
});