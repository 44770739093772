import React, { memo, useCallback, useEffect, useState } from "react";
import { useRegulationTreeData } from "../../../../hooks/useRegulationTreeData";
import { Icon, Radio, RadioGroup, Spin, Tree } from "../../../uiKit";
import { ArrowDownIcon } from "../../../../constants/icon";
import "./AddingRegulationsToSection.scss";
import { RegulationTreeNode } from "../../../../api/models/RegulationTreeDto";
//@ts-ignore
export const AddingRegulationsToSection = memo((props: any) => {

  const {
    treeData,
    loadData,
    defaultSelectedKeys,
    selectedKeys,
    handleLoadData,
  } = useRegulationTreeData();

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const handleExpand = useCallback((keys: string[], isChangeRoute?: boolean) => {
    setExpandedKeys((prev) => {
      const uniqClosedItem = keys.filter((item) => isChangeRoute ? prev : !prev.includes(item));
      const uniqOpenItem = prev.filter((item) => !keys.includes(item));
      return [...uniqOpenItem.concat(uniqClosedItem)];
    })
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const onFirstLoadData = async () => {
    setIsLoading(true);
    await loadData(false, props.sectionId, props.isSingleChoice ? false : undefined);
    setIsLoading(false);

  }

  useEffect(() => {
    onFirstLoadData();
  }, []);

  useEffect(() => {
    onCheck(defaultSelectedKeys);
  }, [defaultSelectedKeys]);

  const handleSelect = (key: any) => {
    console.log("handleSelect", key);
  }

  const onCheck = useCallback((checkedKeysValue: any) => {
    props.setCheckedKeysList(checkedKeysValue);
  }, []);

  const rendererContent = (children: React.ReactNode) => props.isSingleChoice
    ? (
      <RadioGroup  name="dddd" className="function-selection-radio-group">
        {children}
      </RadioGroup>
    )
    : children;

  const onLoadData = useCallback(async (item: RegulationTreeNode) => {
    return await handleLoadData(item, props.isSingleChoice ? false : undefined)
  }, [props.isSingleChoice]);


  return isLoading
    ? (
      <div className="adding-regulations-loader full-height full-width d-flex align-center justify-center">
        <Spin spinning={true} size="large" />
      </div>
    )
    : rendererContent(
      <Tree
        className={"adding-regulations-tree"}
        // showLine
        checkable={!props.isSingleChoice}
        multiple
        treeData={treeData}
        defaultExpandedKeys={expandedKeys}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        blockNode
        titleRender={(titleNode) => {
          return props.isSingleChoice ? (
            <Radio
              // @ts-ignore
              value={titleNode.id}
              children={titleNode.title}
              // @ts-ignore
              onChange={(event) => props.setParentSectionId(titleNode.id)}
            />
          ) : titleNode.title
          // (
          // <RegulationSidebarTreeItem
          //   title={titleNode.title as string}
          //   //@ts-ignore
          //   id={titleNode.id}
          //   isLeaf={titleNode.isLeaf}
          //   key={titleNode.key}
          //   icon={titleNode.icon}
          //   itemKey={titleNode.key}
          //   onOpenAccessControlDialog={onOpenAccessControlDialog}
          //   onOpenDeletingTopicDialog={onOpenDeletingTopicDialog}
          //   onSelect={handleSelect}
          // />

          // );
        }}
        switcherIcon={(
          <Icon
            className="tree-switcher-icon"
            style={{ verticalAlign: "middle" }}
            component={() => <ArrowDownIcon size={16} />}
          />
        )}
        virtual={false}
        rootStyle={{ overflow: "hidden" }}
        onCheck={onCheck}
        checkedKeys={props.checkedKeysList}
        onExpand={(_, info) => {
          handleExpand([info.node.key] as string[]);
        }}
        onSelect={(_, info) => {
          handleSelect(info.node.key as string)
        }}
        // @ts-ignore
        loadData={onLoadData}
      />
    ) ?? null;

});
