import React from "react";
import { CalendarGoogleEventBlockView } from "./CalendarGoogleEventBlockView";
import { ICalendarGoogleEvent } from "./CalendarGoogleEventBlock.interface";

export const CalendarGoogleEventBlock = (props: ICalendarGoogleEvent) => {

  return (
    <CalendarGoogleEventBlockView
      event={props.event}
      onClose={props.onClose}
    />
  )
};