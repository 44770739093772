import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, CollapsePanel, Divider, Empty, Icon, Text } from "../../../../../../uiKit";
import { ISidebarCollapseElement } from "./SidebarCollapseElement.interface";
import "./SidebarCollapseElement.scss";
import { LucideChevronDown } from "lucide-react";
import { closestCorners, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DashboardCollapseListItem } from "./DashboardCollapseListItem/DashboardCollapseListItem";
import { DashboardDto } from "../../../../../../../api";
import { useRootStore } from "../../../../../../../hooks";
import { observer } from "mobx-react-lite";
import useLocalStorage from "../../../../../../../hooks/useLocalStorage";

interface DashboardWithOrder extends DashboardDto {
  order: number;
}

export const SidebarCollapseElement = observer((props: ISidebarCollapseElement) => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const { uri } = useParams();

  const [dashboards, setDashboards] = useState<DashboardWithOrder[]>([]);
  const [dashboardsFromLs, setDashboardsFromLs] = useLocalStorage<
    {
      id: number;
      order: number;
      name: string;
    }[]
  >(props.elementKey + "DashboardsByCompanyId" + authStore.getCurrentCompanyId, []);

  const sensors = useSensors(useSensor(PointerSensor));

  const dashboardsWithOrder = useMemo(
    () =>
      props.dashboards.map((d) => {
        const isCurrentDashboardStored = dashboardsFromLs?.find((sD) => sD.id === d.id);
        return {
          ...d,
          order: isCurrentDashboardStored ? isCurrentDashboardStored.order : null,
          name: d.name,
        };
      }),
    [props.dashboards, dashboardsFromLs]
  );

  const handleRedirectToPlan = (id?: number) => {
    if (id) navigate(`/dashboard/${id}`);
  };

  const handleDashboardDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        setDashboards((dashboard) => {
          const oldIndex = dashboard.findIndex((m) => m.order == active.id);
          const newIndex = dashboard.findIndex((m) => m.order == over?.id);
          if (oldIndex == null || newIndex == null || dashboard.length == 0) return dashboard;
          const dashboardsArr: DashboardWithOrder[] = [...(arrayMove(dashboard ?? [], oldIndex, newIndex) ?? [])].map(
            (d, i) => ({ ...d, order: i + 1 })
          );
          setDashboardsFromLs(dashboardsArr.map((dA) => ({ id: dA.id!, order: dA.order, name: dA.name! })));
          return dashboardsArr;
        });
      }
    },
    [setDashboardsFromLs]
  );

  const handleDashboardsChangingCheck = useCallback(() => {
    if (dashboardsWithOrder.length !== dashboards.length) {
      const dashboardsWithNewOrder = dashboardsWithOrder
        .map((d, i) => ({
          ...d,
          order: d.order
            ? d.order
            : dashboardsFromLs.length
            ? dashboardsFromLs[dashboardsFromLs.length - 1]?.order + 1
            : i + 1,
        }))
        .sort((a, b) => a.order - b.order);
      setDashboards(dashboardsWithNewOrder);
      setDashboardsFromLs(
        dashboardsWithNewOrder.map((d) => ({
          id: d.id!,
          order: d.order,
          name: d.name!,
        }))
      );
    }
  }, [dashboards.length, dashboardsFromLs, dashboardsWithOrder, setDashboardsFromLs]);

  const handleSyncDashboardsNames = useCallback(() => {
    const correctDashboards = dashboards.map((d) => {
      const isCurrentDashboard = props.dashboards?.find((pD) => pD.id === d.id);
      return {
        ...d,
        name: isCurrentDashboard ? isCurrentDashboard.name : d.name,
      };
    });
    setDashboards(correctDashboards);
  }, [dashboards, props.dashboards]);

  useEffect(() => handleDashboardsChangingCheck(), [handleDashboardsChangingCheck]);

  useEffect(() => {
    handleSyncDashboardsNames();
  }, [props.dashboards]);

  useEffect(() => {
    !dashboardsFromLs.length &&
      localStorage.removeItem(props.elementKey + "DashboardsByCompanyId" + authStore.getCurrentCompanyId);
  }, [authStore.getCurrentCompanyId, dashboardsFromLs.length, props.elementKey]);

  return (
    <Collapse
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <Icon
          style={{ transform: isActive ? "rotate(0)" : "rotate(-90deg)", transition: "transform 0.2s" }}
          component={() => <LucideChevronDown color="var(--color-gray-weak)" size={16} />}
        />
      )}
      style={{ background: "var(--color-layout-container)" }}
      bordered={false}
    >
      <CollapsePanel
        key={props.collapsePanelKey}
        header={<Text size="12px" weight="bold" children={props.collapseName} />}
        className="collapse__custom-panel"
      >
        <Divider />
        {dashboards.length === 0 ? (
          <Empty className="my-4" />
        ) : (
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              onDragEnd={handleDashboardDragEnd}
            >
              <SortableContext
                // items={(props.dashboards ?? []).map((d) => d.order) as number[]}
                items={dashboards.map((d) => d.order)}
                strategy={verticalListSortingStrategy}
              >
                {dashboards.map((dashboard) => (
                  <DashboardCollapseListItem
                    key={dashboard.id}
                    id={dashboard.id}
                    uri={uri}
                    name={dashboard.name}
                    order={dashboard.order}
                    onRedirectToPlan={handleRedirectToPlan}
                  />
                ))}
              </SortableContext>
            </DndContext>
            {/*{!props.isDone && (*/}
            {/*<div>*/}
            {/*  <ScrollTrigger*/}
            {/*    fullWidth*/}
            {/*    hidden={props.isDone}*/}
            {/*    disabled={props.isDone}*/}
            {/*    onIntersection={props.loadNext}*/}
            {/*    // options={{ root: document.body }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*)}*/}
          </>
        )}
      </CollapsePanel>
    </Collapse>
  );
});
