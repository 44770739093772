import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RolePositionDto, User2RoleDto } from "../../../../../api";
import { Text } from "../../../../uiKit";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import clsx from "clsx";
import { IOrgchartFunctionCardHubbardView } from "./OrgchartFunctionCardHubbardView";
import { useRootStore } from "../../../../../hooks";
import { useTranslation } from "react-i18next";
import { OrgchartFilterContext } from "../../../../../contexts/orgchartFilterContext";
import { OrgchartContext } from "../../../../../contexts/orgchartContext";
export const OrgchartFunctionCardHubbardContent = (props:  IOrgchartFunctionCardHubbardView) => {
  const { orgchartStore, authStore } = useRootStore();
  const { t } = useTranslation();

  const { orgchartFilters } = useContext(OrgchartFilterContext);
  const { handleEditMetric } = useContext(OrgchartContext) ?? {};
  // console.log("orgchartFilters", orgchartFilters);

  const content = useRef(null);
  const [isShowContent, setIsShowContent] = useState(false);
  const [height, setHeight] = useState<string>('');

  //
  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach(entry => {
  //       if (entry.isIntersecting) {
  //         setHeight('');
  //         setIsShowContent(true);
  //         console.log('Элемент вошел в видимую область', entries);
  //         //@ts-ignore
  //         console.log("content", content.current.getBoundingClientRect().height);
  //       } else {
  //         console.log('Элемент вышел из видимой области', entries);
  //         //@ts-ignore
  //         console.log("content", content.current.getBoundingClientRect().height);
  //         //@ts-ignore
  //         setHeight(content.current.getBoundingClientRect().height)
  //         setIsShowContent(false);
  //       }
  //     });
  //   });
  //
  //   if (content.current) {
  //     observer.observe(content.current);
  //   }
  //
  //   return () => {
  //     if (content.current) {
  //       observer.unobserve(content.current);
  //     }
  //   };
  // }, []);

  const [isShowAllMetrics, setIsShowAllMetrics] = useState<boolean>()

  const handleToggleIsShowAllMetrics = useCallback((e) => {
    e.stopPropagation();
    setIsShowAllMetrics((prev) => !prev);
  }, []);

  const metrics = useMemo(() => {
    const metrics = props.blockRole?.metrics ?? [];
    const metricsCount = isShowAllMetrics ? metrics.length : 5;
    const metricsList = metrics.slice(0, metricsCount);
    return metricsList;
  }, [isShowAllMetrics, props.blockRole?.metrics]);

  const onEditMetric = useCallback((event, metric) => {
    event.stopPropagation();
    handleEditMetric && handleEditMetric(metric);
  }, [handleEditMetric]);

  return (
    <div
      ref={content}
      className="orgchart-card-content"
      // style={{
      //   height
      // }}
    >
      {/*<div style={{*/}
      {/*  display: isShowContent  ? "block" : "none"*/}
      {/*}}>*/}
        <>
          {(props.blockRole?.positions ?? []).length > 0 && (
            <div className="orgchart-card__inner__users d-stack-column spacing-1">
              {orgchartFilters.employeeFilter &&
                (
                  props.blockRole?.positions?.filter(
                    (pos: RolePositionDto) =>
                      pos.parentPositionId == null &&
                      (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                  ) ?? []
                ).length > 0 && (
                  <div className="orgchart-card__inner__users__admins">
                    <div className="__position__wrapper">
                      <Text
                        size="12px"
                        type="secondary"
                        children={
                          props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)?.name
                        }
                      />
                      <div className="my-1 d-stack-column spacing-1">
                        {props.blockRole?.users
                          ?.filter(
                            (u2r: User2RoleDto) =>
                              u2r.positionId ==
                              props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                                ?.id
                          )
                          ?.map((u2r: User2RoleDto) => (
                            <div key={u2r.id}>
                            <UserInfo
                              key={u2r.id}
                              className="orgchart-card__inner__users__user"
                              avatarSize={20}
                              user={u2r.user}
                              nameDisplayMode="lastNameAndFirstName"
                              isStaticImg
                            />
                            </div>
                          ))}
                      </div>
                    </div>
                    {(
                        props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                          ?.description ?? ""
                      ).trim().length > 0 &&
                      orgchartFilters.resultFilter && (
                        <div style={{ lineHeight: "1.5em" }}>
                          <Text type="secondary" weight="bold" size="12px">
                            {`${orgchartStore.getCurrentOrgchartResultName}: `}
                            <Text
                              type="secondary"
                              weight={500}
                              size="12px"
                              children={
                                props.blockRole?.positions?.find((pos: RolePositionDto) => pos.parentPositionId == null)
                                  ?.description
                              }
                            />
                          </Text>
                        </div>
                      )}
                  </div>
                )}
              {orgchartFilters.employeeFilter &&
                authStore.getCurrentCompanyUiType != 2 &&
                (
                  props.blockRole?.positions?.filter(
                    (pos: RolePositionDto) =>
                      pos.parentPositionId != null &&
                      (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                  ) ?? []
                ).length > 0 && (
                  <div
                    className={clsx("orgchart-orgchart-card__inner__users__executors", {
                      "__bordered pl-1 ml-2":
                        (
                          props.blockRole?.positions?.filter(
                            (pos: RolePositionDto) =>
                              pos.parentPositionId == null &&
                              (props.blockRole?.users?.filter((u) => u.positionId == pos.id)?.length ?? 0) > 0
                          ) ?? []
                        ).length > 0
                    })}
                  >
                    <div className="d-stack-column">
                      {props.blockRole?.positions
                        ?.filter((pos: RolePositionDto) => pos.parentPositionId != null)
                        ?.sort((a, b) => (a.parentPositionId != null ? 1 : -1))
                        ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                        ?.map((pos: RolePositionDto, index) =>
                          (
                            props.blockRole?.users?.filter(
                              (u2r: User2RoleDto) =>
                                u2r.positionId ==
                                props.blockRole?.positions?.find((p: RolePositionDto) => p.id == pos.id)?.id
                            ) ?? []
                          ).length > 0 ? (
                            <div key={pos.id} className="__position__wrapper">
                              <Text className="__position_name" size="12px" type="secondary" children={pos.name} />
                              <div className="d-stack-column my-1 spacing-1">
                                {props.blockRole?.users
                                  ?.filter((u2r: User2RoleDto) => u2r.positionId == pos.id)
                                  ?.map((u2r: User2RoleDto) => (
                                    <UserInfo
                                      key={u2r.id}
                                      className="orgchart-card__inner__users__user"
                                      avatarSize={20}
                                      user={u2r.user}
                                      nameDisplayMode="lastNameAndFirstName"
                                    />
                                  ))}
                              </div>
                              {(pos.description ?? "").trim().length > 0 && orgchartFilters.resultFilter && (
                                <div style={{ lineHeight: "1.5em" }}>
                                  <Text
                                    type="secondary"
                                    weight="bold"
                                    size="12px"
                                  >
                                    {`${orgchartStore.getCurrentOrgchartResultName}: `}
                                    <Text type="secondary" weight="normal" size="12px" children={pos.description} />
                                  </Text>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div key={pos.id} />
                          )
                        )}
                    </div>
                  </div>
                )}
            </div>
          )}
          {(props.blockRole?.awaitedResult ?? "").trim().length > 0 && orgchartFilters.resultFilter && (
            <div style={{ lineHeight: "1.5em" }}>
              <Text type="secondary" weight="bold" size="12px">
                {`${orgchartStore.getCurrentOrgchartResultName}: `}
                <Text type="secondary" weight={500} size="12px" children={props.blockRole?.awaitedResult} />
              </Text>
            </div>
          )}
          {(props.blockRole?.description ?? "").trim().length > 0 && orgchartFilters.descriptionFilter && (
            <div style={{ lineHeight: "1.5em" }}>
              <Text type="secondary" weight="bold" size="12px">
                {` ${t("parse:description")}:  `}
                <Text
                  type="secondary"
                  weight={500}
                  size="12px"
                  children={props.blockRole?.description}
                  style={{ whiteSpace: "pre-line" }}
                />
              </Text>
            </div>
          )}
          {metrics.length > 0 && orgchartFilters.withMetrics && (
            <>
            <div style={{ lineHeight: "1.5em" }}>
              <Text
                type="secondary"
                weight="bold"
                size="12px"
                children={`${t("ui:subheader.select_metrics")}:`}
                style={{ whiteSpace: "pre-line", marginTop: 8 }}
              />
              {metrics.map((metric) => (
                  <div
                    key={metric.id}
                    className="orgchart-card-metric-wrap"
                  >
                    <Text
                      className={"orgchart-card-metric"}
                      type="secondary"
                      weight={500}
                      size="12px"
                      children={`* ${metric.name}`}
                      onClick={(e) => onEditMetric(e, metric)}
                    />
                  </div>
                )
              )}
            </div>
            {(props.blockRole?.metrics ?? []).length > 5 && (
              <div onClick={handleToggleIsShowAllMetrics}>
                <Text
                  className={"orgchart-card-metric orgchart-card-metric_show-more"}
                  type="secondary"
                  weight={"bold"}
                  size="12px"
                  children={isShowAllMetrics ? t("parse:hide") : t("parse:show_more")}
                />
              </div>
            )}
            </>
          )}
        </>
      {/*</div>*/}
    </div>
  )
}