import React, { useCallback } from "react";
import { IUpdateNoteCreateHeader } from "./UpdateNoteCreateHeader.interface";
import { LocaleSwitcher } from "../../components/LocaleSwitcher/LocaleSwitcher";
import { Checkbox, IconButton } from "../../../../../uiKit";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const UpdateNoteCreateHeader = (props: IUpdateNoteCreateHeader) => {
  const navigate = useNavigate();

  const goToBack = useCallback(() => {
    navigate("/update-note");
  },  [])

  return (
    <div className="d-stack-row align-center justify-space-between full-width">
      <div className="d-stack-row align-center justify-start spacing-2">
        <IconButton onClick={goToBack}>
          <IoArrowBackSharp />
        </IconButton>
        <LocaleSwitcher currentLocale={props.currentLocale} onChangeLocale={props.onChangeLocale} />
      </div>
      <Checkbox
        checked={props.isHideUpdateModal}
        onChange={(e) => props.onChangeIsHideUpdateModal(e.target.checked)}>
        Публикация без модального окна
      </Checkbox>
    </div>
  );
};
