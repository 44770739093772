import React, { createRef, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Icon, IconButton, Tag, Text } from "../../../../../uiKit";
import { getColor } from "../../../../../../utils/hex2rgb";
import { EditIcon, TagIcon } from "../../../../../../constants/icon";
import { useTranslation } from "react-i18next";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";

import "./Tags.scss";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import { CreateTagDialog } from "../../../../dialogs/tags/createTagDialog/CreateTagDialog";
import { EditTagsDialog } from "../../../../dialogs/tags/editTagsDialog/EditTagsDialog";
import { useApi, useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { TagDto } from "../../../../../../api/models/TagDto";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";
import clsx from "clsx";
import { ITags } from "./ITags";
import { TagItem } from "./TagItem/TagItem";
import useDebounce from "../../../../../../hooks/useDebounce";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { TAG_WITH_THIS_NAME_ALREADY_EXISTS } from "../../../../../../constants/ApiErrorCode";

export const Tags = memo((props: ITags) => {
  const { tagsList, allTagsList,  refetchTags } = useContext(BoardFilterContext);
  const { t } = useTranslation();
  const notifier = useNotifier();

  const [isShowCreateTagDialog, setIsShowCreateTagDialog] = useState<boolean>(false);
  const handleOpenCreateTagDialog = useCallback(() => {
    setIsShowCreateTagDialog(true);
    props.onChangeIsOpenFilter?.(false);
  }, []);
  const handleCloseCreateTagDialog = useCallback(() => {
    setIsShowCreateTagDialog(false);
    props.onChangeIsOpenFilter?.(true);
  }, []);

  const [isShowEditTagsDialog, setIsShowEditTagsDialog] = useState<boolean>(false);
  const handleOpenEditTagsDialog = useCallback(() => {
    setIsShowEditTagsDialog(true);
    props.onChangeIsOpenFilter?.(false);
  }, []);
  const handleCloseEditTagsDialog = useCallback(() => {
    setIsShowEditTagsDialog(false);
    props.onChangeIsOpenFilter?.(true);
  }, []);
  //
  // const tags = useApi(
  //   () =>
  //     api.tags.getAll({
  //       pageSize: -1,
  //     }),
  //   null,
  //   () => {},
  //   {},
  //   false
  // );
  //
  // useEffect(() => {
  //   tags.fetch();
  // }, []);


  const [tags, setTags] = useState<TagDto[]>(allTagsList);
  useEffect(() => {
    setTags(allTagsList);
  }, [allTagsList]);

  const onError = (err: any) => {
    const { data: { apiError } } =  err.response;
    return apiError ?? TAG_WITH_THIS_NAME_ALREADY_EXISTS;
  }

  const handleUpdateTag = useCallback(async (tag) => {
    setTags((prev) => {
      const list = [...prev];
      const indexCurrentTag = list.findIndex((item) => item.id === tag.id);
      if (indexCurrentTag !== -1) {
        list.splice(indexCurrentTag, 1, tag);
        return list;
      }
      return [...prev, tag];
    });
    const status: TagDto | null | string = await api.tags.editTag(tag.id, tag, onError);
    if (status && typeof status !== "string") {
      notifier.show({
        message: t("notifier:success.tag_edit", { tag: tag.name }),
        theme: "success",
      });
      refetchTags();
    }
    return status;
  }, []);

  // useEffect(() => {
  //   setTagsList(tags.value?.items ?? []);
  // }, [tags.value?.items]);

  // const resetData = useCallback(() => {
  //   return tags.fetch();
  // }, []);

  const tagsListContainer = createRef<HTMLDivElement>();

  const [isOpenTagList, setIsOpenTagsList] = useState<boolean>(false);
  const handleChangeOpenTagList = useCallback(() => {
    setIsOpenTagsList((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isOpenTagList && tagsListContainer.current) {
      tagsListContainer.current.scrollTop = 0;
    }
  }, [isOpenTagList]);

  const [selectedTagsList, setSelectedTagsList] = useState<number[]>(props.selectedTags);

  const [isUpdate, setIsUpdate] = useState<boolean>(true);

  useEffect(() => {
    setSelectedTagsList(props.selectedTags);
    setIsUpdate(false);
  }, [props.selectedTags]);

  const handleSelectTags = useCallback((tagId: number) => {
    setSelectedTagsList((prev) => {
      if (prev.includes(tagId)) {
        return prev.filter((item) => item !== tagId)
      }
      return [...prev, tagId];
    });
    setIsUpdate(true);
  }, []);

  const debounceSelectedTags = useDebounce(selectedTagsList, 1000);
  const prevDebounceSelectedTags = useRef<number[] | null>(null);

  useEffect(() => {
    console.log("debounceSelectedTags", debounceSelectedTags);
    if (prevDebounceSelectedTags.current && isUpdate) {
      props.onSelectedTags(debounceSelectedTags);
    }
    prevDebounceSelectedTags.current = debounceSelectedTags
  }, [debounceSelectedTags]);

  return (
    <>
      {isShowCreateTagDialog && (
        <CreateTagDialog
          onClose={handleCloseCreateTagDialog}
          resetData={refetchTags}
        />
      )}
      {isShowEditTagsDialog && (
        <EditTagsDialog
          tags={tags}
          resetData={refetchTags}
          onClose={handleCloseEditTagsDialog}
          onUpdateTag={handleUpdateTag}
        />
      )}
      <div className="tags-block">
        <div className="tags-block-header mb-3">
          <div className="tags-block-header__left">
            <div className="tags-block-header__icon">
              <Icon component={() => <TagIcon size={16}/> }/>
            </div>
            <Text
              size="14px"
              children={t("ui:title.tags")}
            />

            <CounterChip
              colorMode={ColorModeKeys.faint}
              count={selectedTagsList.length}
            />
            <IconButton
              className="tags-block-arrow"
              onClick={handleChangeOpenTagList}
            >
              <FiChevronDown/>
            </IconButton>
          </div>
          <div className="tags-block-header__right">
            <IconButton
              className={"tags-block-header__action"}
              size="small"
              onClick={handleOpenCreateTagDialog}
            >
              <FiPlus/>
            </IconButton>
            <IconButton
              className={"tags-block-header__action"}
              size="small"
              onClick={handleOpenEditTagsDialog}
            >
              <EditIcon size={16}/>
            </IconButton>
          </div>
        </div>
        <div
          ref={tagsListContainer}
          className={clsx("tags-list", {"tags-list_open": isOpenTagList})}
        >
          {tagsList.map((tag) => (
            <TagItem
              key={tag.id}
              tag={tag}
              onSelectTag={handleSelectTags}
              isSelected={selectedTagsList.includes(tag.id)}
            />
        ))}
        </div>
      </div>
    </>
  )
});