import React from "react";
import { useRootStore } from "../../../../../hooks";
import ruContent from "../../../../../utils/guides-lexical/communication/ru.json";
import enContent from "../../../../../utils/guides-lexical/communication/en.json";
import ukContent from "../../../../../utils/guides-lexical/communication/uk.json";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return JSON.stringify(ruContent);
    case "en":
      return JSON.stringify(enContent);
    case "uk":
      return JSON.stringify(ukContent);
  }
};
export const CommunicationGuide = () => {
  const { hintStore } = useRootStore();

  // const guideParser = new GuideParser(getGuideContent(appStore.getAppLocale, guideKeys.communications));
  // return appStore.getAppLocale == "en" || appStore.getAppLocale == "uk" ? (
  //   <div className="drawer-body">{guideParser.renderGuide()}</div>
  // ) : (
  //   <TextEditor readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
  //   // <TextEditorLexical readOnly value={getContent(appStore.getAppLocale)} />
  // );
  return (
    // <TextEditor readOnly={true} id="guide-dasboard" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
    <TextEditorLexical readOnly value={getContent(hintStore.getLang)} withoutBackground />
  );
};
