import React from "react";
import ruContent from "../../../../../utils/guides-lexical/dashboard/ru.json";
import enContent from "../../../../../utils/guides-lexical/dashboard/en.json";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return JSON.stringify(ruContent);
    case "en":
      return JSON.stringify(enContent);
    case "uk":
      return JSON.stringify(ruContent);
  }
};

export const DashboardGuide = () => {
  const { hintStore } = useRootStore();

  return (
    // <TextEditor
    //   readOnly={true}
    //   anchorKey={hintStore.getAnchorId as string}
    //   id="guide-dasboard"
    //   onChange={() => {}}
    //   value={getContent(appStore.getAppLocale)}
    // />
    <TextEditorLexical
      readOnly
      anchorKey={hintStore.getAnchorId as string}
      value={getContent(hintStore.getLang)}
      withoutBackground
    />
  );
};
