import React, { useState } from "react";
import { OrgchartDrawerTabPermissionsView } from "./OrgchartDrawerTabPermissionsView";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { RoleDto } from "../../../../../../api";
import { observer } from "mobx-react-lite";
import { api } from "../../../../../../services";
import { useTranslation } from "react-i18next";

export interface IOrgchartDrawerTabPermissions {
  currentRole: RoleDto;
  isLoading: boolean;

  onRoleChange: (role: RoleDto, withoutApiCall: boolean) => void;
}

function OrgchartDrawerTabPermissionsObserved(props: IOrgchartDrawerTabPermissions) {
  const { authStore } = useRootStore();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSwitchChange = async (value: boolean, id: number, type: string) => {
    const dataToSend: RoleDto = {
      ...props.currentRole,
      claims: value
        ? [...(props.currentRole.claims ?? []), { id: 0, roleId: props.currentRole.id, type: type }]
        : [...(props.currentRole.claims ?? []).filter((claim) => claim.type != type)],
    };

    setIsLoading(true);
    const r = await api.role.edit(props.currentRole.id as number, dataToSend, { includeClaims: true });
    if (r == null) {
      setIsLoading(false);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    } else {
      props.onRoleChange(r, true);
      await updateCurrentUserInitialInfoIfNeed();
      setIsLoading(false);
    }
  };

  const updateCurrentUserInitialInfoIfNeed = async () => {
    if (props.currentRole.users?.some((user) => user.userId == authStore.getInitialInfo?.identity?.id)) {
      await authStore.refreshInitialInfo();
    }
  };

  return (
    <OrgchartDrawerTabPermissionsView
      currentRole={props.currentRole}
      isLoading={isLoading}
      onSwitchChange={handleSwitchChange}
    />
  );
}

export const OrgchartDrawerTabPermissions = observer(OrgchartDrawerTabPermissionsObserved);
