import React from "react";
import { RecurringIssueDto } from "../../../../../../api";
import { PagingInfo } from "../../../../../../hooks/usePaging";
import { PagingModel } from "../../../../../../api/types";
import { TIssuesFilters } from "./OrgchartCommonDrawerTabRecurringTasks";
import "./OrgchartCommonDrawerTabRecurringTasks.scss";
import DeleteRecurringIssueDialog from "../../../../dialogs/deleteRecurringIssueDialog/DeleteRecurringIssueDialog";
import RecurringIssueHistoryDialog from "../../../../dialogs/recurringIssueHistoryDialog/RecurringIssueHistoryDialog";
import { useTranslation } from "react-i18next";
import RecurringIssueCreateEditDialog from "../../../../dialogs/recurringIssueCreateEditDialog/RecurringIssueCreateEditDialog";
import { Empty, Icon, IconButton, Input, Select, SelectOption } from "../../../../../uiKit";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import TaskRecurringCard from "../../taskRecurringCard/TaskRecurringCard";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { EmptyBlock } from "../../../../layouts/emptyBlock/EmptyBlock";

interface IOrgchartCommonDrawerTabRecurringTasksView {
  tasks: {
    items: RecurringIssueDto[];
    info: PagingInfo;
    lastResult: PagingModel<RecurringIssueDto> | null;
    loadNext: () => Promise<void>;
    reset: () => void;
    restart: () => Promise<void>;
  };
  issueFilters: TIssuesFilters;
  isDeleteIssueDialogOpen: boolean;
  isIssueHistoryDialogOpen: boolean;
  isIssueCreateEditDialogOpen: boolean;
  issueToManageId: number | null;
  issueToManageData: RecurringIssueDto | null;
  sortSettings: { by: string; order: string };

  setSortSettings: (value: { by: string; order: string }) => void;
  setIsDeleteIssueDialogOpen: (value: boolean) => void;
  setIsIssueHistoryDialogOpen: (value: boolean) => void;
  setIsIssueCreateEditDialogOpen: (value: boolean) => void;
  setIssueFilters: (value: TIssuesFilters) => void;
  setIssueToManageData: (data: RecurringIssueDto | null) => void;
  onCardPauseClick: (issueId: number) => void;
  onCardWatchHistoryClick: (issueId: number) => void;
  onCardEditClick: (issue: RecurringIssueDto) => void;
  onCardDeleteClick: (issueId: number) => void;
  handleIssueDelete: () => void;
}

function OrgchartCommonDrawerTabRecurringTasksView(props: IOrgchartCommonDrawerTabRecurringTasksView) {
  const { t } = useTranslation();
  const executorTypes = [
    { id: 0, name: t("common:menu.executor_select.admins_of_function"), value: "role_admins", disabled: false },
    { id: 1, name: t("common:menu.executor_select.executors_of_function"), value: "role_executors", disabled: false },
    { id: 2, name: t("common:menu.executor_select.all_admins"), value: "all_admins", disabled: false },
    { id: 3, name: t("common:menu.executor_select.all"), value: "all", disabled: false },
  ];

  const pauseTypes = [
    { id: 0, name: t("parse:all_tasks"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:on_pause"), value: true, valueSelect: "true", disabled: false },
    { id: 2, name: t("parse:active"), value: false, valueSelect: "false", disabled: false },
  ];

  const sortBy = [
    { id: 0, name: t("parse:sort.by_date"), value: "date_created", disabled: false },
    { id: 1, name: t("parse:sort.by_name"), value: "name", disabled: false },
  ];

  const sortOrders = [
    { id: 0, name: t("common:misc.sort_items.ones_first"), value: "desc", disabled: false },
    { id: 1, name: t("common:misc.sort_items.old_ones"), value: "asc", disabled: false },
  ];

  const executorPositionsTypes = [
    { id: 0, name: t("parse:for_all"), value: null, valueSelect: "null", disabled: false },
    { id: 1, name: t("parse:for_admins"), value: 1, valueSelect: "1", disabled: false },
    { id: 2, name: t("parse:for_executors"), value: 2, valueSelect: "2", disabled: false },
  ];

  return (
    <div className="d-flex flex-column" style={{ height: "100%", overflow: "hidden", position: "relative" }} key={1}>
      <DeleteRecurringIssueDialog
        open={props.isDeleteIssueDialogOpen}
        onDelete={props.handleIssueDelete}
        onClose={() => props.setIsDeleteIssueDialogOpen(false)}
      />
      <RecurringIssueHistoryDialog
        open={props.isIssueHistoryDialogOpen}
        issueId={props.issueToManageId}
        onClose={() => props.setIsIssueHistoryDialogOpen(false)}
      />
      <RecurringIssueCreateEditDialog
        open={props.isIssueCreateEditDialogOpen}
        data={props.issueToManageData ?? undefined}
        onSave={() => {
          props.setIsIssueCreateEditDialogOpen(false);
          props.tasks.reset();
          props.setIssueToManageData(null);
          props.tasks.restart();
        }}
        onClose={() => {
          props.setIsIssueCreateEditDialogOpen(false);
          // props.setIssueToManageData(null);
        }}
      />

      <div className="d-stack-column spacing-2 mb-3">
        <Input
          size="middle"
          className="flex-grow-1"
          placeholder={t("parse:task_name") as string}
          value={props.issueFilters.name}
          onInput={(event: any) => props.setIssueFilters({ ...props.issueFilters, name: event.target.value })}
        />
        <div className="d-stack spacing-3 flex-nowrap">
          <Select
            size="middle"
            style={{ width: "120px" }}
            className="flex-shrink-0 flex-grow-0"
            value={pauseTypes.find((t) => t.value == props.issueFilters.isPaused)?.valueSelect ?? "null"}
            onChange={(value) =>
              props.setIssueFilters({
                ...props.issueFilters,
                isPaused: pauseTypes.find((t) => t.valueSelect == value)?.value ?? null,
              })
            }
          >
            {pauseTypes.map((ex) => (
              <SelectOption key={ex.id} value={ex.valueSelect}>
                {ex.name}
              </SelectOption>
            ))}
          </Select>
          <AutocompleteAsync
            size="middle"
            style={{ overflow: "hidden" }}
            allowClear
            className="flex-grow-1"
            type="role"
            value={props.issueFilters.roleId ?? undefined}
            onChange={(id) => props.setIssueFilters({ ...props.issueFilters, roleId: id ?? null })}
          />
          <Select
            size="middle"
            style={{ width: "150px" }}
            className="flex-shrink-0 flex-grow-0"
            value={
              executorPositionsTypes.find((t) => t.value == props.issueFilters.executorPositionType)?.valueSelect ??
              "null"
            }
            onChange={(value) =>
              props.setIssueFilters({
                ...props.issueFilters,
                executorPositionType: executorPositionsTypes.find((t) => t.valueSelect == value)?.value ?? null,
              })
            }
          >
            {executorPositionsTypes.map((ex) => (
              <SelectOption key={ex.id} value={ex.valueSelect}>
                {ex.name}
              </SelectOption>
            ))}
          </Select>
          <Select
            size="middle"
            style={{ width: "170px" }}
            className="flex-shrink-0 flex-grow-0"
            value={props.sortSettings.order ?? "desc"}
            onChange={(value) =>
              props.setSortSettings({
                ...props.sortSettings,
                order: value ?? "desc",
              })
            }
          >
            {sortOrders.map((so) => (
              <SelectOption key={so.id} value={so.value}>
                {so.name}
              </SelectOption>
            ))}
          </Select>
          <IconButton
            disabled={
              !(
                props.issueFilters.name.length > 0 ||
                props.issueFilters.roleId != null ||
                props.issueFilters.isPaused != null ||
                props.issueFilters.executorConfigType.length != 0 ||
                props.issueFilters.executorPositionType != null
              )
            }
            onClick={() =>
              props.setIssueFilters({
                ...props.issueFilters,
                name: "",
                roleId: null,
                isPaused: null,
                executorConfigType: "",
                executorPositionType: null,
              })
            }
            size="middle"
            icon={<Icon component={() => <FiX />} />}
          />
        </div>
      </div>
      <div className="flex-grow-1" style={{ overflow: "auto" }}>
        <div className="d-stack-column spacing-2">
          {props.tasks.items.map((t, index) => (
            <TaskRecurringCard
              key={t.id as number}
              data={t}
              onPauseClick={() => props.onCardPauseClick(t.id as number)}
              onWatchHistoryClick={() => props.onCardWatchHistoryClick(t.id as number)}
              onEditClick={(i) => props.onCardEditClick(i)}
              onDeleteClick={() => props.onCardDeleteClick(t.id as number)}
            />
          ))}
        </div>
        <ScrollTrigger
          onIntersection={() => props.tasks.loadNext()}
          hidden={props.tasks.info.isDone}
          marginTop={props.tasks.items.length > 0}
        />
        {props.tasks.items.length == 0 && props.tasks.info.isDone && <Empty />}
      </div>
    </div>
  );
}

export default OrgchartCommonDrawerTabRecurringTasksView;
