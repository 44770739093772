import React, { useEffect, useState } from "react";
import IssueProofFormView from "./IssueProofFormView";
import { useTranslation } from "react-i18next";
import { IssueProofRequirementDto, IssueProofRequirementRuleDto } from "../../../../api";

interface IIssueProofForm {
  data: IssueProofRequirementDto | null;
  readOnly?: boolean;
  showHintIcon?: boolean;
  onBlur?: () => void;
  onChange(data: IssueProofRequirementDto | null): void;
}

function IssueProofForm(props: IIssueProofForm) {
  const { t } = useTranslation();

  const proofTypesList = [
    { id: 0, value: "", text: t("ui:label.proof_not_needed") },
    {
      id: 1,
      value: "require.text_min_length",
      text: t("ui:label.proof_text"),
    },
    {
      id: 2,
      value: "require.images_min_count",
      text: t("ui:label.proof_image"),
    },
    {
      id: 3,
      value: "require.files_min_count",
      text: t("ui:label.proof_file"),
    },
  ];

  useEffect(() => {
    // console.log(props.data);
  }, [props.data]);

  const handleChange = (data: IssueProofRequirementDto | null) => {
    props.onChange({
      ...props.data,
      ...data,
    });
  };

  const onProofTypeChange = (type: string | null) => {
    const rule = props.data?.rules?.[0] ?? { id: 0, key: type, value: 1 };
    rule.key = type;
    if (type == "") {
      props.onChange(null);
    } else {
      props.onChange({ ...props.data, rules: [rule] });
    }
  };

  const onProofValueChange = (value: number) => {
    let v = value;
    // console.log(v);
    // if (v.toString().length == 0) {
    //   return;
    // }
    if (v < 1) {
      v = 1;
    }
    const rule = props.data?.rules?.[0] ?? { id: 0, key: "require.text_min_length", value: 1 };
    rule.value = v;
    props.onChange({ ...props.data, rules: [rule] });
  };

  const [rules, setRules] = useState<IssueProofRequirementRuleDto[]>([]);

  return (
    <IssueProofFormView
      readOnly={props.readOnly}
      rules={rules}
      proofTypesList={proofTypesList}
      data={props.data}
      onChange={handleChange}
      onProofTypeChange={onProofTypeChange}
      onProofValueChange={onProofValueChange}
      onBlur={props.onBlur}
      showHintIcon={props.showHintIcon}
    />
  );
}

export default IssueProofForm;
