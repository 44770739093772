import { ColorModeKeys, CountChipColorKeys } from "./keys";

const colorWsitchCase = (param?: ColorModeKeys): CountChipColorKeys => {
  switch (param) {
    case ColorModeKeys.primary:
      return CountChipColorKeys.primary;
    case ColorModeKeys.faint:
      return CountChipColorKeys.faint;
    default:
      return CountChipColorKeys.primary;
  }
};

export const getColorChip = (param?: ColorModeKeys): CountChipColorKeys =>
  !param ? CountChipColorKeys.primary : colorWsitchCase(param);

export const getCount = (count?: number, isShortMode?: boolean): number | string | undefined =>
  !count ? count : isShortMode ? (count > 99 ? "99+" : count) : count;
