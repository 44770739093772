import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RiUserSettingsLine } from "@react-icons/all-files/ri/RiUserSettingsLine";
import { EditableTypography } from "../../../elements/editableTypography/EditableTypography";
import { ICreateRegulatiomFormView } from "./CreateRegulatiomForm.interface";
import { CreateTestsForm } from "../createTestsForm/CreateTestsForm";
import { Button, Card, Icon } from "../../../uiKit";
import { TextEditor as TextEditorLexical } from "../../../modules/textEditor/TextEditor";
import { RegulationEditorToolbarAction } from "../../pages/regulation/components/RegulationEditorToolbarAction/RegulationEditorToolbarAction";

// const db = new TextEditorContentDB();

export const CreateRegulationFormView: React.FC<ICreateRegulatiomFormView> = (props, ref) => {
  const { t } = useTranslation();

  // const handleSave = useCallback(async () => {
  //   // await db.del("create-regulation");
  //   props.onSave();
  // }, [props]);
  //
  // const handleCancel = useCallback(async () => {
  //   // await db.del("create-regulation");
  //   props.onCancel();
  //   localStorage.removeItem("create-regulation-editor");
  // }, [props]);

  return (
    <div className="create-regulation-form">
      <Card className="create-regulation-editor" bodyStyle={{ padding: 0 }}>
        {/*<TextEditor*/}
        {/*  id={"create-regulation"}*/}
        {/*  placeholder={t("ui:placeholder.start_write_regulation")}*/}
        {/*  value={props.regulationContent}*/}
        {/*  initialValue={props.regulationContent}*/}
        {/*  onChange={props.onChangeRegulationContent}*/}
        {/*/>*/}
        <TextEditorLexical
          id={"create-regulation-editor"}
          ref={props.editorRef}
          placeholder={t("ui:placeholder.start_write_regulation")}
          value={props.regulationContent}
          initialValue={props.regulationContent}
          onChange={props.onChangeRegulationContent}
          isShowEditorTitle={true}
          variant={"new-style"}
          titlePlaceholder={t("ui:placeholder.regulation_name1")}
          titleValue={props.regulationTitle}
          onChangeTitle={props.onChangeRegulationTitle}
          titleError={props.titleError}
          toolbar={(
            <RegulationEditorToolbarAction
              handleClickOpenTest={props.onOpenTest}
            />
          )}
        />
        {/*<RichTextEditor*/}
        {/*  placeholder={t("ui:placeholder.start_write_regulation")}*/}
        {/*  value={props.regulationContent}*/}
        {/*  onChange={props.onChangeRegulationContent}*/}
        {/*/>*/}
      </Card>
      <div className="create-regulation-form-tests-form-wrap">
        <div>
          {props.isOpenCreatingTest && (
            <CreateTestsForm
              regulationContentId={0}
              ref={props.testRef}
              onClose={props.onCloseTest}
              // onSave={props.onSaveTest}
            />
          )}
          {/*{!props.isOpenCreatingTest && (*/}
          {/*  <Button variant="filled" onClick={props.isOpenCreatingTest ? props.onCloseTest : props.onOpenTest}>*/}
          {/*    {props.test.length ? t("ui:button.edit_test") : t("ui:button.add_testing")}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};
