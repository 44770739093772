import React from "react";
import { ConfigKeys, IFiltersContainerView } from "./FiltersContainer.interface";
import { Button, Input } from "../../../../uiKit";
import { PickPeriodButton } from "../../../../elements/pickPeriodButton/PickPeriodButton";
import { useTranslation } from "react-i18next";

export const FiltersContainerView = (props: IFiltersContainerView) => {
  const { t } = useTranslation();

  return (
    <>
      {props.config.includes(ConfigKeys.phoneNumber) && (
        <Input
          className="ml-2"
          size="middle"
          style={{ maxWidth: "320px" }}
          placeholder={t("ui:placeholder.phone_number")}
          value={props.params.phoneNumber}
          onChange={(event) => props.onChangePhone(event.currentTarget.value)}
        />
      )}
      {props.config.includes(ConfigKeys.userName) && (
        <Input
          className="ml-2"
          size="middle"
          style={{ maxWidth: "320px" }}
          placeholder={t("ui:placeholder.name")}
          value={props.params.userName}
          onChange={(event) => props.onChangeName(event.currentTarget.value)}
        />
      )}
      {props.config.includes(ConfigKeys.period) && (
        <PickPeriodButton
          className="ml-2"
          allowNull
          period={props.params.period}
          onChangePeriod={props.onChangePeriod}
        />
      )}
      <Button variant="filled" className="ml-2" loading={props.isLoading} onClick={props.onRefresh}>
        {t("ui:button.refresh")}
      </Button>
    </>
  );
};
