import React, { memo } from "react";
import { IEducationCardCover } from "./EducationCardCover.interface";
import "./EducationCardCover.scss";


export const EducationCardCover = memo((props: IEducationCardCover) => {
  return (
    <div className="education-card-cover">
      <svg width="372" height="191" viewBox="0 0 372 191" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_25429_41474)">
          <path
            d="M341.334 68.5962C320.814 -13.7343 202.903 -54.255 118.903 -43.7659C22.1033 -31.6679 -6.27448 45.2595 -82.527 31.3688C-152.109 18.7171 -164.03 -51.8567 -214.775 -57.5055C-261.411 -62.6945 -328.576 -11.6964 -423.381 214.123"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-416.671 222.908C-322.963 0.321607 -255.943 -52.3397 -208.876 -49.1268C-173.986 -46.6965 -156.947 -13.6873 -127.178 10.2728C-112.273 22.6534 -94.712 30.9886 -76.072 34.5302C-15.0099 46.1793 15.6406 1.82657 76.7646 -22.5733C92.3388 -28.8438 108.581 -33.1628 125.158 -35.4416C209.351 -47.3104 325.221 -13.717 347.13 65.3765"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-409.951 231.663C-317.338 12.3243 -250.503 -41.9812 -203.098 -40.7191C-168.251 -39.7883 -150.614 -8.51088 -120.944 14.073C-105.774 25.9186 -88.2663 34.0023 -69.7541 37.7076C-9.50899 49.7549 21.5768 9.4133 83.011 -14.2035C98.6193 -20.2409 114.799 -24.5589 131.291 -27.0883C215.722 -40.0644 329.506 -13.6852 352.802 62.1714"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-403.248 240.389C-311.753 24.3453 -245.059 -31.5946 -197.267 -32.3328C-162.432 -32.8756 -144.255 -3.35273 -114.641 17.8646C-99.2379 29.1708 -81.7946 37.0101 -63.4229 40.8828C-3.93993 53.3221 27.4561 16.991 89.2705 -5.83604C104.916 -11.6792 121.05 -16.0134 137.474 -18.7852C222.111 -33.0257 333.825 -13.7021 358.511 58.9176"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-396.537 249.189C-306.142 36.3493 -239.641 -21.19 -191.42 -23.9332C-156.637 -25.9305 -137.852 1.83014 -108.312 21.6392C-92.6644 32.4114 -75.2551 40.0136 -56.9954 44.0476C1.72546 56.879 33.4745 24.5682 95.6951 2.51348C111.349 -3.09182 127.405 -7.41914 143.719 -10.4302C228.575 -25.9365 338.222 -13.6541 364.31 55.7272"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-389.884 257.965C-300.545 48.3545 -234.339 -12.1894 -185.575 -15.5482C-150.772 -17.986 -131.46 6.91175 -102.008 25.4311C-86.126 35.6481 -68.7513 43.0123 -50.6087 47.2168C7.35004 60.4402 39.4246 32.1527 101.957 10.8956C117.628 5.48857 133.626 1.15042 149.851 -2.09193C234.92 -18.9219 342.505 -13.6373 369.982 52.5215"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-383.108 266.728C-294.933 60.3731 -228.794 -0.280062 -179.752 -7.23339C-145.094 -11.9622 -125.031 12.0773 -95.6898 29.2362C-79.5897 38.8955 -62.2471 46.027 -44.221 50.4008C12.9756 64.0163 45.4154 39.7332 108.273 19.272C123.964 14.0806 139.907 9.74075 156.04 6.26947C241.328 -11.8264 346.84 -13.6407 375.711 49.3245"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-376.401 275.498C-289.364 72.3811 -223.394 10.0966 -173.899 1.23782C-139.326 -5.00022 -118.589 17.1092 -89.3755 33.0119C-73.0705 42.1041 -55.7535 48.9975 -37.8405 53.5265C18.5819 67.5499 51.39 47.3 114.582 27.5899C129.288 23.0221 145.022 18.4559 162.221 14.558C247.737 -4.84859 351.18 -13.6015 381.436 46.0979"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-369.692 284.268C-283.724 84.3964 -217.975 20.6326 -168.07 9.59577C-133.556 1.9768 -112.132 22.1546 -83.0341 36.7705C-66.5243 45.2898 -49.2215 51.9624 -31.4143 56.6769C24.2459 71.0924 57.39 54.8355 120.938 35.9472C135.732 31.5447 151.428 27.012 168.462 22.8843C254.085 2.19522 355.518 -13.5761 387.161 42.872"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-362.972 293.023C-278.112 96.4001 -212.559 31.1394 -162.22 18.0241C-127.775 9.04012 -105.602 27.2209 -76.7562 40.58C-60.0627 48.5225 -42.7757 54.9791 -25.0792 59.8811C29.8327 74.6872 63.3299 62.4505 127.209 44.2845C142.022 40.0551 157.732 35.5208 174.603 31.1778C260.531 9.11107 359.848 -13.6375 392.881 39.5881"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-356.264 301.793C-272.516 108.405 -207.141 41.675 -156.417 26.4135C-122.04 16.079 -99.1757 32.2402 -70.4657 44.3877C-53.5936 51.7326 -36.3115 57.9667 -18.7208 63.0533C35.4152 78.2529 69.2637 70.0222 133.504 52.7212C148.408 48.686 164.075 44.1419 180.771 39.5994C266.907 16.1658 364.153 -13.5358 398.602 36.4498"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-349.557 310.564C-266.903 120.424 -201.695 52.208 -150.545 34.7959C-116.22 23.1238 -92.6545 37.2348 -64.1351 48.177C-47.7415 54.4605 -30.9693 60.7746 -12.3071 66.2199C41.0651 81.7971 75.2683 77.6011 139.846 61.0801C154.812 57.2279 170.45 52.6724 187.012 47.9259C273.356 23.1254 368.545 -13.5309 404.37 33.2338"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-342.848 319.334C-261.285 132.485 -196.342 62.7803 -144.712 43.197C-110.441 30.1728 -86.1361 42.2003 -57.8178 51.9674C-41.107 57.7335 -24.3195 63.8123 -5.92049 69.3893C46.6945 85.4018 81.2305 85.1699 146.161 69.4418C161.191 65.7869 176.814 61.2185 193.171 56.2612C279.707 30.0666 372.855 -13.517 410.07 30.0252"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-336.168 328.107C-255.693 144.447 -190.836 73.3499 -138.879 51.5978C-104.658 37.2648 -79.6201 47.151 -51.4996 55.7721C-34.4611 60.9903 -17.6703 66.8496 0.412212 72.5789C52.274 88.9387 87.1404 92.7734 152.479 77.8321C167.583 74.3441 183.163 69.7659 199.361 64.622C286.098 37.0029 377.192 -13.5065 415.797 26.8132"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-329.42 336.859C-250.082 156.451 -185.423 83.9594 -133.034 59.983C-98.8502 44.3252 -73.0945 52.0574 -45.1978 59.5497C-27.833 64.2057 -11.0084 69.8714 6.85214 75.7278C57.9243 92.4826 93.1696 100.32 158.792 86.1795C174.594 82.5943 190.202 78.1604 205.555 72.8952C292.474 43.9266 381.574 -13.4714 421.523 23.5872"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-322.712 345.629C-244.469 168.469 -179.904 94.5131 -127.244 68.3741C-93.0953 51.4059 -66.6116 56.9536 -38.8667 63.3385C-21.1774 67.4177 -4.34626 72.8929 13.2389 78.8969C63.5627 96.0423 99.1473 107.902 165.08 94.544C180.892 91.1788 196.471 86.7462 211.719 81.2736C298.841 50.7635 385.87 -13.4562 427.258 20.3161"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-316.005 354.399C-238.873 180.475 -174.376 105.168 -121.358 76.7546C-87.2696 58.508 -60.0505 61.812 -32.5656 67.1161C-14.5534 70.6043 2.289 75.9321 19.611 82.0678C69.159 99.6068 105.109 115.471 171.421 102.903C187.242 99.7386 202.792 95.2921 217.932 89.6028C305.204 57.7032 390.208 -13.4309 432.991 17.1621"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-309.283 363.168C-233.253 192.551 -168.96 115.792 -115.525 85.156C-81.4309 65.5944 -53.5328 66.6464 -26.2619 70.9084C-7.8752 73.7704 8.9373 78.9556 25.9977 85.2374C74.7804 103.14 111.073 123.054 177.738 111.279C193.563 108.307 209.087 103.846 224.121 97.9498C311.562 64.5854 394.546 -13.4198 438.719 13.9507"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-302.575 371.938C-227.718 204.504 -163.461 126.435 -109.674 93.5984C-75.5987 72.7538 -47.025 71.5249 -19.9358 74.7851C-1.26572 77.075 15.6235 82.0763 32.3933 88.493C80.417 106.816 117.059 130.722 184.059 119.698C199.888 116.919 215.387 112.443 230.313 106.339C317.922 71.4959 398.962 -13.3161 444.452 10.7964"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-295.867 380.709C-222.05 216.517 -157.947 137.077 -103.848 101.942C-69.7652 79.7966 -40.5328 76.2596 -13.6286 78.489C5.35152 80.0725 22.2496 85.029 38.7732 91.6046C86.021 110.321 123.014 138.233 190.37 128.031C206.205 125.452 221.682 120.96 236.496 114.643C324.255 78.2783 403.221 -13.3842 450.173 7.52694"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-289.147 389.463C-216.439 228.521 -152.425 147.791 -98.058 110.333C-63.9785 86.918 -34.1044 81.0455 -7.38025 82.2873C11.9114 83.1788 28.8426 88.0585 45.0861 94.7386C91.5872 113.86 128.934 145.792 196.627 136.37C212.467 133.976 227.929 129.467 242.638 122.951C330.465 84.958 407.505 -13.3526 455.871 4.3041"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
          <path
            d="M-282.453 398.235C-166.258 142.287 -70.2409 85.3557 -1.00899 86.0715C74.5671 86.8508 111.162 156.37 202.998 144.74C310.727 131.082 403.465 -15.7918 461.625 1.08922"
            stroke="#C3CFFE" 
            strokeWidth="0.631198" 
            strokeMiterlimit="10" 
          />
        </g>
        <defs>
          <clipPath id="clip0_25429_41474">
            <rect width="372" height="191" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="education-card-cover__title">
        {props.title}
      </div>

    </div>
  )
});