import React, { memo, useEffect, useState } from "react";
import { Button, Dialog, DialogActions, RadioGroup } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { Radio, Space } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";

export const SelectedGoogleCalendarDialog = memo((
  {
    closeModal,
    calendarList,
    isLoading,
    onSubmit,
  }: any) => {
  const { t } = useTranslation();
  const [selectedCalendar, setSelectedCalendar] = useState<string | null>(null);

  useEffect(() => {
    const [defaultCalendar] = calendarList;
    setSelectedCalendar(defaultCalendar.id);
  }, []);

  const handleSubmit = () => {
    onSubmit(selectedCalendar, true);
  }

  return (
    <Dialog
      destroyOnClose
      title={t("ui:title.selected_google_calendar")}
      open={true}
      onClose={closeModal}
      closable={false}
    >
      <RadioGroup
        value={selectedCalendar}
        onChange={(event: RadioChangeEvent) => {
          setSelectedCalendar(event.target.value)
        }}
      >
        <Space direction="vertical">
          {calendarList.map((calendar: any) => (
            <Radio
              key={calendar.id}
              value={calendar.id}
            >
              {calendar.summary}
            </Radio>
          ))}
        </Space>
      </RadioGroup>
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="default"
        >
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="filled"
          loading={isLoading}
        >
          { t("ui:button.confirm") }
        </Button>
      </DialogActions>
    </Dialog>
  )
});