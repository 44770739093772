import React, { useCallback, useContext, useEffect, useState } from "react";
import "./OrgchartTypeSelectForm.scss";
import templateDefault from "../../../../../assets/images/orgchart-template-default.png";
import templateClassic from "../../../../../assets/images/orgchart-template-classic.png";
import templateSimplified from "../../../../../assets/images/orgchart-template-simplified.png";
import { useTranslation } from "react-i18next";
import { Button, Card, Icon, Popover, Spin, TabPane, Tabs, Text } from "../../../../uiKit";
import { useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { OrgchartTemplateDto } from "../../../../../api";
import { rootStore } from "../../../../../stores/rootStore";
import {
  ORGCHART_CLASSIC_TYPE,
  ORGCHART_DEFAULT_TYPE,
  ORGCHART_SIMPLIFIED_TYPE,
  ORGCHART_TYPES
} from "../../../../../constants/orgchartTypes";
import { OrgchartTemplateTypeDto, OrgchartTemplateTypeEnum } from "../../../../../api/models/OrgchartTemplateTypeDto";
import { OrgchartContext } from "../../../../../contexts/orgchartContext";
import { FullScreenIcon } from "../../../../../constants/icon";

interface IOrgchartTypeSelectFormView {
  selectedOrgchartType: "d" | "c" | "s" | null;
  selectedTemplateKey: string | null;
  handleSave: () => void;
  setSelectedOrgchartType: (type:OrgchartTemplateTypeEnum | null) => void;
  templates?: OrgchartTemplateDto[] | null;
  setTemplates: (value: OrgchartTemplateDto[] | null) => void;
  setSelectedTemplateKey: (key: string | null) => void;
  isLoading: boolean;
}

function OrgchartTypeSelectFormView(props: IOrgchartTypeSelectFormView) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const { orgchartTemplates } = useContext(OrgchartContext) ?? {};

  const [templates, setTemplates] = useState<OrgchartTemplateDto[] | []>([]);

  useEffect(() => {
    if (props.selectedOrgchartType) {
      const templates = orgchartTemplates[props.selectedOrgchartType] || []
      setTemplates(templates)
    }
  }, [orgchartTemplates, props.selectedOrgchartType]);


  const templateFolderPath = `${window.location.origin}/orgchart-templates/`;
  const localPopoversFolderPath = window.location.origin + "/orgchart-templates/Popovers/";
  const localPreviewsFolderPath = window.location.origin + "/orgchart-templates/Previews/";

  const handleReset = useCallback(() => {
    props.setSelectedOrgchartType(null);
    props.setSelectedTemplateKey(null);
    props.setTemplates(null);
  }, [props]);

  const handleOpenPreview = useCallback(
    (key: string) => {
      // const locale = rootStore.appStore?.getAppLocale ?? "ru";
      // const templateLocale = locale === 'ru' || locale === 'uk' ? 'ru' : 'en';
      const templateLocale = "ru";
      const link = `${templateFolderPath}${getTemplateFolderName(key)}/previews/${templateLocale}.pdf`;
      window.open(link, "_blank", "noreferrer");
    },
    [rootStore.appStore?.getAppLocale]
  );

  const getTemplateFolderName = useCallback((key: string) => {
    return key
      ? key
        .replace('c_', '')
        .replace('s_', '')
        .replaceAll('_', '-')
      : ''
  }, []);

  const getIconUrl = useCallback((key: string) => {
    const templateFolder = getTemplateFolderName(key) as String;
    if (templateFolder) {
      return `${templateFolderPath}${templateFolder}/icon.svg`
    }
  }, []);

  const getTemplateImage = useCallback((key) => {
    switch (key) {
      case ORGCHART_DEFAULT_TYPE:
        return templateDefault;
      case ORGCHART_CLASSIC_TYPE:
        return templateClassic;
      case ORGCHART_SIMPLIFIED_TYPE:
        return templateSimplified
    }
  }, []);

  return (
    <div className="orgchart-type-select-form full-width">
      <Tabs
        activeKey={
          props.selectedOrgchartType === null || props.selectedOrgchartType === "d" ? "orgchartType" : "orgchartSubType"
        }
        tabBarStyle={{ display: "none" }}
        destroyInactiveTabPane={false}
      >
        <TabPane key="orgchartType">
          <div className="__header">
            <span className="__title">{t("parse:orgchart1")}</span>
            {(authStore.getInitialInfo?.orgcharts?.filter((o) => !o.isEmpty) ?? []).length === 0 && (
              <span className="__subtitle">{t("parse:orgchart2")}</span>
            )}
          </div>

          <div className="__content d-stack-row align-center justify-center spacing-8 my-12">
            {ORGCHART_TYPES.map((item: OrgchartTemplateTypeDto) => (
              <Card
                key={item.type}
                onClick={() => props.setSelectedOrgchartType(item.type)}
                className={`orgchart-type-card ${props.selectedOrgchartType === item.type ? "__selected" : ""}`}
                clickable
              >
                <div className="orgchart-type-card__inner d-stack-column spacing-3 align-center justify-center">
                  <div className="orgchart-type-card__image">
                    <img
                      alt="template"
                      draggable="false"
                      width={item.type !== ORGCHART_DEFAULT_TYPE ? 160 : undefined}
                      src={getTemplateImage(item.type)}
                    />
                  </div>
                  <Text
                    size="16px"
                    children={t(item.title)}
                  />
                </div>
              </Card>
            ))}
          </div>
          <div className="d-stack-row justify-center spacing-3 full-width">
            <Button
              size="large"
              onClick={props.handleSave}
              disabled={props.selectedOrgchartType !== "d"}
              variant="filled"
              loading={props.isLoading}
            >
              {t("ui:button.create")}
            </Button>
          </div>
        </TabPane>
        <TabPane key="orgchartSubType">
          <div className="__subtype_header">
            <span className="__subtype_title">
              {props.selectedOrgchartType === "c"
                ? t("parse:chooseClassicOrgchartType")
                : t("parse:chooseSimplifiedOrgchartType")}
            </span>
          </div>

          <div className="__content d-stack-column flex-wrap align-center justify-center spacing-12 my-9 full-width">
            {props.isLoading || !templates?.length ? (
              <Spin spinning />
            ) : (
              <div className="orgchart-grid-wrapper">
                {templates?.map((v) => (
                  <Card
                    key={v.key}
                    onClick={() => props.setSelectedTemplateKey(v.key ?? "")}
                    style={{ height: 242 }}
                    className={`orgchart-type-card  ${props.selectedTemplateKey === v.key ? "__selected" : ""}`}
                    clickable
                  >
                    <div className="orgchart-type-card-wrap">
                      <Icon
                        className="guide-icon"
                        component={() => (
                          <FullScreenIcon />
                        )}
                        style={{ position: "absolute", right:12, top: 8 }}
                        onClick={() => handleOpenPreview(v.key ?? "")}
                      />
                      <img
                        alt={v.title ?? ""}
                        draggable="false"
                        src={getIconUrl(v.key ?? '')}
                      />
                      <Text size="14px" children={t(`ui:orgchart_template.${v.key}`)} />
                    </div>
                  </Card>
                 )
                )}
              </div>
            )}
            <div className="d-stack-row justify-center align-center spacing-3 full-width">
              <Button
                disabled={props.isLoading}
                size="large"
                onClick={handleReset}
                variant="default"
              >
                {t("ui:button.back")}
              </Button>
              <Button
                size="large"
                onClick={props.handleSave}
                disabled={props.selectedTemplateKey === null}
                variant="filled"
                loading={props.isLoading}
              >
                {t("ui:button.create")}
              </Button>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default observer(OrgchartTypeSelectFormView);
