import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { CreateSectionContent } from "../../pages";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { SectionPageHeaderButtons } from "../../components/SectionPageHeaderButtons/SectionPageHeaderButtons";
import "./CreateSection.scss";
import { BreadcrumbsNew } from "../../../../../elements/breadcrumbsNew/BreadcrumbsNew";
import { SectionContext } from "../../../../../../contexts/RegulationPage/SectionContext";
import { useTranslation } from "react-i18next";
import { NavigationMenuItemDto } from "../../../../../../api/models/NavigationMenuDto";

export const CreateSection = () => {
  const { t } = useTranslation();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  const { breadcrumbsList } = useContext(SectionContext);

  const createSectionBreadcrumbsList = useMemo(() => (
    [...breadcrumbsList,
      {
        name: t("common:breadcrumbs.create_topic"),
        id: -2,
        path: "",
      }
    ])
    , [breadcrumbsList]);

  const [editSection, setEditSection] = useState<NavigationMenuItemDto>({
    id: -1,
    items: [],
    name: '',
    order: -1,
    breadcrumbs: [],
    isFavorite: false,
  });


  const addedRegulations = useCallback((list: NavigationMenuItemDto[]) => {
    setEditSection((prev) => ({
        ...prev,
        items: [
          ...(prev.items ?? []),
          ...list,
        ]
    }));
  }, []);

  const removeRegulation = useCallback((id: number) => {
    setEditSection((prev) => ({
      ...prev,
      items: (prev.items ?? []).filter((item) => item.id !== id),
    }))
  }, []);

  return (
    <>
      <PageHeader>
        <div className="section-page-header">
          {!globalAuthorizedContext?.regulation?.isSidebarOpen && (
            <IconButton
              // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
              className="flex-shrink-0"
              onClick={() => globalAuthorizedContext?.regulation?.onSidebarOpenChange?.()}
              children={<Icon component={() => <FiSidebar />} />}
            />
          )}
          <BreadcrumbsNew
            breadcrumbsList={createSectionBreadcrumbsList}
          />
          <SectionPageHeaderButtons
            isEditable={true}
            permissionToEdit={true}
            isCreated={true}
          />
        </div>
      </PageHeader>
      <PageContent contentPosition={{ maxWidth: "1000px" }}>
        <CreateSectionContent
          section={editSection}
          addedRegulations={addedRegulations}
          removeRegulation={removeRegulation}
        />
      </PageContent>
    </>
  );
};
