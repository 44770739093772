import React from "react";
import { MenuView } from "./MenuView";
import { MenuProps } from "antd";

interface IMenu extends MenuProps {
  //
}
export function Menu(props: IMenu) {
  return <MenuView {...props} />;
}
