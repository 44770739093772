import React from "react";
import "./GroupCompanyInfo.scss";
import { IGroupCompany } from "./GroupCompany.interface";
import { ListCompanies } from "../ListCompanies/ListCompanies";
import { ListStaff } from "../ListStaff/ListStaff";
import { Card, Divider } from "../../../../../uiKit";

export const GroupCompanyInfo: React.FC<IGroupCompany> = (props) => {
  return (
    <Card
      className="flex-grow-1 flex-shrink-1 mr-1"
      bodyStyle={{ padding: 0, display: "flex" }}
      scrollStrategy="cardBody"
    >
      <ListCompanies
        companies={props.companies}
        onLoadGroupCompanyId={props.onLoadGroupCompanyId}
        onOpenAddCompanyToGroupDialog={props.onOpenAddCompanyToGroupDialog}
        onDeleteCompanyById={props.onDeleteCompanyById}
      />
      <Divider style={{ height: "calc(100% - 16px)", alignSelf: "center" }} isVertical />
      <ListStaff
        onDeleteEmployee={props.onDeleteEmployeeById}
        onOpenAddEmployeeDialog={props.onOpenAddEmployeeDialog}
        staff={props.userTypes}
      />
    </Card>
  );
};
