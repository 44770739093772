import React, { forwardRef } from "react";
import "./TextArea.scss";
import { TextAreaProps } from "antd/es/input";
import clsx from "clsx";
import { Input, InputRef } from "antd";

interface ITextArea extends TextAreaProps {
  readOnly?: boolean;
  isHiddenBorder?: boolean;
}

const { TextArea: TextAreaAntD } = Input;

export const TextArea = forwardRef<InputRef, ITextArea>(({
  className,
  readOnly,
  isHiddenBorder,
  ...rest
}: ITextArea, ref) => {
  return (
    <TextAreaAntD
      ref={ref}
      size="large"
      autoSize
      className={clsx("uiKit-textArea", className ?? "", {
        "no-interact": readOnly,
        "uiKit-textArea_no-border": isHiddenBorder,
        "__placeholder-trimmed":
          (typeof rest.autoSize === "boolean" && rest.autoSize == true) ||
          rest.autoSize == null ||
          (typeof rest.autoSize !== "boolean" && rest.autoSize?.minRows === 1),
      })}
      {...rest}
    />
  );
});
