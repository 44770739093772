import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { Checkbox, DatePicker, Text, TimeSpanPicker } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getLexicalTextContentLength, initialEditorContent } from "../../../../../utils/textEditor";
import { ToolbarType } from "../../../textEditor/types/types";
import { TextEditor } from "../../../textEditor/TextEditor";
import FileUploader from "../../../../service/fileUploader/FileUploader";
import { StaticFileDto } from "../../../../../api";
import { actionsKeysConst } from "../../../forms/types/consts";
import { getObjectFromTimeSpan } from "../../../../../helpers/dateFunctions";
import { useRootStore } from "../../../../../hooks";

interface IIssueRequiredActionsDialogTabChangeDeadline {
  editorRef: React.MutableRefObject<{ isEmptyEditor: () => boolean; resetEditor: () => void } | undefined>;
  onChange: (value: Date | null, key: string) => void;
  deadline: Date | null;
  dateStart: Date | null;
  timePlan: string,
  onInput: (value: string) => void;
  comment: string;
  isShowErrorValidateMessage: {
    showCommonError: boolean;
    showTimeFactError: boolean;
    showDeadlineError: boolean;
    showStartDateError: boolean;
  };
}

export const IssueRequiredActionsDialogTabChangeDeadline = (props: IIssueRequiredActionsDialogTabChangeDeadline) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const { dateStart, deadline, timePlan } = props;

  console.log("props", props);
  console.log("timePlan", props.timePlan);

  const [deadlineValue, setDeadlineValue] = useState<Date | null>(null);
  useEffect(() => {
    if (dateStart && timePlan) {
      const parsedTimePlan = getObjectFromTimeSpan(timePlan!);
      const newDeadline = dayjs(dateStart)
        .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
        .add({
          hours: parsedTimePlan.hours,
          minutes: parsedTimePlan.minutes,
          seconds: parsedTimePlan.seconds,
        });
      props.onChange(newDeadline?.toDate() ?? null, 'deadline');
    }
  }, [dateStart, timePlan])


  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      <div style={{ width: "100%" }} className="mt-2 d-stack-row justify-space-between">
        <div style={{ width: "48%" }}>
          <Form.Item className="mb-0" rules={[{ required: true, message: t("validation:field_required") }]}>
            <SubheaderText text={t("ui:subheader.executor_deadline")} />
            <DatePicker
              status={props.isShowErrorValidateMessage.showStartDateError ? "error" : ""}
              // disabled={props.onCheckToAvailability(blockedFieldsKeys.deadline)}
              className="full-width"
              value={props.dateStart ? dayjs(props.dateStart) : null}
              onChange={(value) => {
                props.onChange(value?.toDate() ?? null, 'dateStart')
              }}
              allowClear
              size="large"
              showTime={{ format: "HH:mm" }}
              disablePast
            />
            {/*{ props.isShowErrorValidateMessage.showStartDateError }*/}
          </Form.Item>
        </div>
        <div style={{ width: "48%" }}>
          <Form.Item className="mb-0" rules={[{ required: true, message: t("validation:field_required") }]}>
            <SubheaderText text={t("ui:subheader.deadline")} />
            <DatePicker
              status={props.isShowErrorValidateMessage.showDeadlineError ? "error" : ""}
              // disabled={props.onCheckToAvailability(blockedFieldsKeys.deadline)}
              className="full-width"
              value={props.deadline ? dayjs(props.deadline) : null}
              onChange={(value) => props.onChange(value?.toDate() ?? null, 'deadline')}
              allowClear
              size="large"
              showTime={{ format: "HH:mm" }}
              disablePast
            />
            { props.isShowErrorValidateMessage.showDeadlineError && (
              <Text
                className={"form-item-error"}
                size="12px"
                style={{ color: "var(--color-error-base)" }}
                children={t("validation:executor_deadline_in_past")}
              />
            )}

          </Form.Item>
        </div>
      </div>
      <div style={{ width: "100%" }} className="mt-4">
        <Form.Item
          name="reason"
        >
          <SubheaderText text={t("ui:subheader.indicate_reason_changing_deadline")} />
          <TextEditor
            id="ds-not-approval-editor"
            ref={props.editorRef}
            placeholder={t("ui:placeholder.write_reason")}
            variant={props.isShowErrorValidateMessage.showCommonError ? "error" : undefined}
            value={props.comment}
            initialValue={props.comment === initialEditorContent ? initialEditorContent : props.comment}
            onChange={props.onInput}
            toolbarType={ToolbarType.Hidden}
          />
        </Form.Item>
      </div>
    </div>
  );
};
