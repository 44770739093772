import React from "react";
import PageHeaderView from "./PageHeaderView";

export interface IPageHeaderTab {
  label: string;
  link: string;
}

interface IPageHeader {
  title?: string;
  children?: React.ReactNode;
  justify?: "start" | "end" | "space-between";
  tabs?: IPageHeaderTab[];
  contentPosition?: IPageHeaderPosition;
  className?: string;
  isOverflow?: boolean;
}

export interface IPageHeaderPosition {
  maxWidth?: string;
  // position?: "start" | "center" | "end";
}

function PageHeader(props: IPageHeader) {
  return <PageHeaderView {...props} />;
}

export default PageHeader;
