import React from "react";
import "./DownloadButton.scss";
import { IDownload } from "./DownloadButton.interface";
import { Button, Icon, IconButton, Popover } from "../../uiKit";
import { DownloadIcon } from "../../../constants/icon";
import { useTranslation } from "react-i18next";

export const DownloadButton = (props: IDownload) => {
  const { t } = useTranslation();
  return (
    <>
      <Popover
        className={"popover-download"}
        content={(
          <Button
            type="text"
            onClick={props.handleClickDownload}
          >
            { t("ui:button.download_report") }
          </Button>
        )}
        placement={"bottomRight"}
        arrow={{ pointAtCenter: false }}
        trigger="click"
      >
        <IconButton
          children={<Icon component={() => <DownloadIcon color={"var(--color-primary-base)"} />} />}
        />
      </Popover>
    </>
  );
};