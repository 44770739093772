import React, { memo, useMemo } from "react";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { IIssueActivityBooleanRow } from "./IssueActivityBooleanRow.interface";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./issueActivityBooleanRow.scss";
import { useTranslation } from "react-i18next";

export const IssueActivityBooleanRow = memo((props: IIssueActivityBooleanRow) => {
  const { t } = useTranslation();


  const prevText = useMemo(() => {
    return props.prevValue ? t(`ui:issue_activity.boolean.${props.prevValue}`) : t("parse:no");
  }, [props.prevValue]);


  const prevTextBackground = useMemo(() => {
    return props.prevValue ? "var(--color-calendar-default1)" : undefined
  }, [props.prevValue]);

  const textBackground = useMemo(() => {
    return props.prevValue ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)"
  }, [props.prevValue]);

  const currentText = useMemo(() => {
    return props.value ? t(`ui:issue_activity.boolean.${props.value}`) : t("parse:no");
  }, [props.prevValue]);

  return (
    <div className={"issue-activity-boolean-row"}>
      <IssueActivityText
        background={prevTextBackground}
        text={prevText}
        isInlineText
      />
      <Icon
        component={() => <ChevronRight size={16}/>}
      />
      <IssueActivityText
        background={textBackground}
        text={currentText}
        isInlineText
      />
    </div>
 )
})
