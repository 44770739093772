export enum AccessControlDialogKeys {
  isOpenAccessControlDialog = "isOpenAccessControlDialog",
  isOpenFunctionSelectionDialog = "isOpenFunctionSelectionDialog",
  isOpenCreationOrSelectionTemplateDialog = "isOpenCreationOrSelectionTemplateDialog",
  isOpenDeletingSectionDialog = "isOpenDeletingSectionDialog",
}

export enum TemplateDialogContentTypeKeys {
  Creation = "creation",
  Selection = "selection",
}
