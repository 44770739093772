import React from "react";
import { IGeneralEmoji } from "../../../interfaces/Reactions.interface";
import { IconButton } from "../../../../../uiKit";

export const GeneralEmoji = (props: IGeneralEmoji) => {
  const handleClick = () => props.onReact(props.emoji);

  return (
    <IconButton onClick={handleClick}>
      <span className="reaction-symbol">{props.emoji}</span>
    </IconButton>
  );
};
