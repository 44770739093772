import React, { useContext } from "react";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { HeaderBreadcrumbs } from "../../headers/headerBreadcrumbs/HeaderBreadcrumbs";
import { RegulationUsersStats } from "../../pages";
import { IRegulationUsersStats } from "./RegulationUsersStats.interface";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";

export const RegulationUserStats: React.FC<IRegulationUsersStats> = (props) => {
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  return (
    <>
      <PageHeader contentPosition={{ maxWidth: "1000px" }}>
        <div className="d-stack-row spacing-4 align-center my-2" style={{ minHeight: "32px" }}>
          {!globalAuthorizedContext?.regulation?.isSidebarOpen && (
            <IconButton
              // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
              className="flex-shrink-0"
              onClick={() => globalAuthorizedContext?.regulation?.onSidebarOpenChange?.()}
              children={<Icon component={() => <FiSidebar />} />}
            />
          )}
          <HeaderBreadcrumbs />
        </div>
      </PageHeader>
      <PageContent contentPosition={{ maxWidth: "1000px" }}>
        <RegulationUsersStats userStats={props.userStats} />
      </PageContent>
    </>
  );
};
