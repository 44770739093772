import React from "react";
import "./Divider.scss";
import clsx from "clsx";

interface IDividerView extends React.HTMLAttributes<HTMLDivElement> {
  contentAlign?: "start" | "center" | "end";
  children?: React.ReactNode;
  isVertical?: boolean;
  isNewDesign?: boolean;
}

export function DividerView(props: IDividerView) {
  return (
    <div
      id={props.id}
      style={props.style}
      className={clsx("uiKit-divider", props.className ?? "", "__align-" + (props.contentAlign ?? "center"), {
        __empty: props.children == null,
        __vertical: props.isVertical,
        "uiKit-divider_new-design": props.isNewDesign,
      })}
    >
      {props.children}
    </div>
  );
}
