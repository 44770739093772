import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { PlanningEmployeeCard } from "./planningEmployeeCard/PlanningEmployeeCard";
import { IPlanningEmployeesCards } from "./PlanningEmployeesCards.interface";
import "./PlanningEmployeesCards.scss";

export const PlanningEmployeesCards = memo((props: IPlanningEmployeesCards) => {
  const navigate = useNavigate();
  const handleRedirectToPlan = (planId: number) => {
    const uri = props.isApproval
      ? `/communication/approve-plan/${planId}`
      : `/communication/work-plans/${planId}`;
    navigate(uri);
  };

  return (
    <div className="planning-employee-card-list" style={{ overflow: "hidden" }}>
      {props.plans?.map((p, index) => (
        <PlanningEmployeeCard
          key={p.id}
          plan={p}
          plansLength={props.plans?.length}
          onRedirectToPlan={handleRedirectToPlan}
        />
      ))}
      <ScrollTrigger
        fullWidth
        marginTop={!!props.plans?.length}
        disabled={props.isDone}
        hidden={props.isDone}
        onIntersection={props.loadNext}
      />
    </div>
  );
});
