import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { TimeSpanPicker } from "../../../../uiKit";

export interface ILabelWithTimePicker {
  day: string;
  disabled?: boolean;
  fromValue: string | undefined;
  toValue: string | undefined;

  onFromValueChange(value: string): void;

  onToValueChange(value: string): void;
}

function LabelWithTimePicker(props: ILabelWithTimePicker) {
  const { t } = useTranslation();
  let disabledMinutes: number[] = [];
  for (let i = 0; i < 60; i++) {
    if (i % 10 != 0 && i % 10 != 5) {
      disabledMinutes = [...disabledMinutes, i];
    }
  }

  function replacePartOfString(start: number, end: number, string: string, newSubstring: string) {
    return string.substring(0, start) + newSubstring + string.substring(end);
  }

  function getDateByString(hhmmssString: string) {
    const [h, m, s] = hhmmssString.split(":");
    const d = new Date();
    let dateUTC = Date.UTC(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      Number(h) + new Date().getTimezoneOffset() / 60,
      Number(m),
      Number(s)
    );
    return dayjs(dateUTC);
  }

  return (
    <div className="d-flex align-center flex-nowrap label-with-picker">
        <div
          style={{ whiteSpace: "pre" }}
          className="mr-1"
        >
          {` ${t("ui:text.from_time")} `}
        </div>
        <TimeSpanPicker
          size="middle"
          value={props.fromValue ?? "8:00:00"}
          allowClear={false}
          minuteStep={5}
          style={{
            maxWidth: 84,
            minWidth: 84,
          }}
          hideDisabledOptions
          // onChange={(v) => props.onChangeUrgency(v ?? null)}
          onChange={(x) => props.onFromValueChange(x ?? "")}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
        <div
          style={{ whiteSpace: "pre" }}
          className="ml-2 mr-1"
        >
          {` ${t("ui:text.to_time")} `}
        </div>
        <TimeSpanPicker
          size="middle"
          value={props.toValue ?? "8:00:00"}
          allowClear={false}
          minuteStep={5}
          style={{
            maxWidth: 84,
            minWidth: 84,
          }}
          hideDisabledOptions
          // onChange={(v) => props.onChangeUrgency(v ?? null)}
          onChange={(x) => props.onToValueChange(x ?? "")}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      </div>
  );
}

export default LabelWithTimePicker;
