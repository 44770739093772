import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultValuesConst, FilterIssueHistoryKeys } from "../../../../../types/consts";
import { usePagingWithController } from "../../../../../../../../hooks/usePaging";
import { api } from "../../../../../../../../services";
import { RealtimeIssuesKeys } from "../../../../../../../pages/communication/misc/consts";
import { useIssueHub } from "../../../../../../../../signalr/hubs/issueHub";
import { IFilterItem, IIssueHistoryListComments } from "./IssueHistoryListComments.interface";
import { IssueHistoryListCommentsView } from "./IssueHistoryListCommentsView";
import { useLocalStorage, useRootStore } from "../../../../../../../../hooks";
import { BroadcastChannel } from "broadcast-channel";

export const IssueHistoryListComments = (props: IIssueHistoryListComments) => {
  const { t } = useTranslation();
  const { connection } = useIssueHub();
  const { authStore } = useRootStore();
  const issueChannel = new BroadcastChannel("issue");

  // const [currentFilter, setCurrentFilter] = useState<FilterIssueHistoryKeys>(FilterIssueHistoryKeys.all);
  const [currentFilter, setCurrentFilter] = useLocalStorage<FilterIssueHistoryKeys>(
    "filterIssueHistoryKey",
    FilterIssueHistoryKeys.all
  );
  const [reloadHistory, setReloadHistory] = useState<boolean>(false);
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);

  const filterItems: IFilterItem[] = [
    { id: 0, value: FilterIssueHistoryKeys.all, text: t("ui:select.all") },
    { id: 1, value: FilterIssueHistoryKeys.comment, text: t("ui:select.comments") },
    { id: 2, value: FilterIssueHistoryKeys.action, text: t("ui:select.actions") },
    { id: 3, value: FilterIssueHistoryKeys.log, text: t("ui:select.log") },
  ];

  const filterIssueActivity: IFilterItem[] = [
    { id: 1, value: FilterIssueHistoryKeys.commentActivity, text: t("ui:select.comments") },
    { id: 2, value: FilterIssueHistoryKeys.logActivity, text: t("ui:select.actions") },
    { id: 3, value: FilterIssueHistoryKeys.allActivity, text: t("ui:select.all") },
    // { id: 3, value: FilterIssueHistoryKeys.log, text: t("ui:select.log") },
  ];

  useEffect(() => {
    const filterList = props.isOldHistory ? filterItems : filterIssueActivity;
    const currentValue = filterList.find(({value}) => value === currentFilter);
    if (!currentValue) {
      setCurrentFilter(filterList[0].value);
    }
  }, []);


  const filter = props.isOldHistory ? filterItems : filterIssueActivity;

  const handleChangeFilter = (value: FilterIssueHistoryKeys) => setCurrentFilter(value);

  const handleReloadHistoryItems = useCallback(() => {
    setReloadHistory(true);
  }, []);

  const issueHistoryItems = usePagingWithController(
    api.issueHistory,
    undefined,
    {
      // @ts-ignore
      issueId: props.issueId,
      type: currentFilter,
      pageSize: defaultValuesConst.pageSize,
    },
    undefined
  );
  const issueActivity = usePagingWithController(
    api.issueActivity,
    undefined,
    {
      // @ts-ignore
      issueId: props.issueId,
      activityType: currentFilter === "null" ? null : currentFilter,
      pageSize: 7,
      // pageSize: defaultValuesConst.pageSize,
    },
    undefined
  );

  const realtimeMessageHandler = (message: any) => {
    if (
      (message.type === RealtimeIssuesKeys.HistoryUpdated || message.type === RealtimeIssuesKeys.HistoryDeleted) &&
      message.issueId === props.issueId &&
      message.byUserId != authStore.getInitialInfo?.identity?.id
    ) {
      handleReloadHistoryItems();
      }
  };

  const handleEditingCommentIdChange = useCallback((id: number | null) => {
    setEditingCommentId(id);
  }, []);

  useEffect(() => {
    if (connection) connection?.on("IssueUpdate", realtimeMessageHandler);
    return () => connection?.off("IssueUpdate", realtimeMessageHandler);
  }, [connection]);

  useEffect(() => {
    handleReloadHistoryItems();
  }, [currentFilter]);



  useEffect(() => {
    if (reloadHistory) {
      if (props.isOldHistory) {
        issueHistoryItems.restart(false);
      } else {
        issueActivity.restart(false);
      }
      setReloadHistory(false);
    }
  }, [reloadHistory]);

  const handleIssueChannelMessage = (evt: any) => {
    if (evt.issueId == props.issueId) {
      if ((issueHistoryItems.items ?? []).length) {
        issueHistoryItems.getOneItem({
          // @ts-ignore
          issueId: props.issueId,
          type: currentFilter,
          pageSize: defaultValuesConst.pageSize,
        });
      } else {
        handleReloadHistoryItems();
        setReloadHistory(false);
      }
    }
  };

  useEffect(() => {
    issueChannel?.addEventListener("message", handleIssueChannelMessage);

    return () => {
      issueChannel?.removeEventListener("message", handleIssueChannelMessage);
    };
  }, [issueHistoryItems.items.length]);

  const isDone = useMemo(() => {
    return props.isOldHistory ? issueHistoryItems.info.isDone : issueActivity.info.isDone;
  }, [issueHistoryItems.info.isDone, issueActivity.info.isDone]);
  const isLoading = useMemo(() => {
    return props.isOldHistory ? issueHistoryItems.info.isLoading : issueActivity.info.isLoading;
  }, [issueHistoryItems.info.isLoading, issueActivity.info.isLoading]);

  const loadNext = useCallback(() => {
    return props.isOldHistory ? issueHistoryItems.loadNext() : issueActivity.loadNext();
  }, [issueHistoryItems.loadNext, issueActivity.loadNext])

  return (
    <>
      <IssueHistoryListCommentsView
        currentFilter={currentFilter}
        filterItems={filter}
        onChangeFilter={handleChangeFilter}
        issueActivityItems={issueActivity.items}
        historyItems={issueHistoryItems.items}
        isDone={isDone}
        isLoading={isLoading}
        handleLoadNext={loadNext}
        onEditComment={props.onEditComment}
        onIssueHistoryIdChange={props.onIssueHistoryIdChange}
        onOpenDeleteCommentDialog={props.onOpenDeleteCommentDialog}
        onDeleteComment={props.onDeleteComment}
        currentStatusId={props.currentStatusId}
        editingCommentId={editingCommentId}
        onEditingCommentIdChange={handleEditingCommentIdChange}
        issueHistoryId={props.issueHistoryId}
        isOldHistory={props.isOldHistory}
      />
    </>
  );
};
