import React from "react";
import { Button, Dialog, DialogActions, Icon, IconButton, InputNumber, Radio, RadioGroup, Table } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { MetricReadDto, MetricSource2UserDto } from "../../../../api";
// import { getMetricNameWithPrefix } from "../../pages/dashboards/misc/helpers/getMetricNameWithPrefix";
import { ColumnType } from "antd/lib/table";
import { IMetricDataData, ITableDataEl } from "./MetricDataDialog";
import { UserInfo } from "../../../elements/userInfo/UserInfo";
import { truncateString } from "../../../../helpers/stringFunctions";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import "./MetricDataDialog.scss";

interface IMetricDataDialogView {
  data: IMetricDataData[];
  changedData: IMetricDataData[];
  isOpen?: boolean;
  loadingState: {
    isLoading: boolean;
    isDone: boolean;
  };
  currentMetricSource2UserId: number | null;
  metric: MetricReadDto | null;
  metricSource2UserId?: number;
  // onSuccess: () => void;
  onOpen: (isOpen: boolean) => void;
  onCurrentMetricSource2UserIdChange: (value: number | null) => void;
  onLoadMore: () => void;
  onDataChange: (
    record: ITableDataEl,
    value: string | number | null | undefined,
    type: "quota" | "value" | "reset"
  ) => any;
  onSave: () => void;
}

export function MetricDataDialogView(props: IMetricDataDialogView) {
  const { t } = useTranslation();
  const tableColumns: ColumnType<any>[] = [
    {
      title: t("parse:metrics_table.date"),
      dataIndex: "date",
      key: "date",
      width: "15%",
      align: "center"
    },
    {
      title: t("parse:metrics_table.quota"),
      dataIndex: "quota",
      key: "quota",
      width: 150,
      render: (value, record, index) => (
        <InputNumber
          disabled={props.metric?.renderType == 1}
          size="large"
          className="full-width"
          isNewStyle
          value={
            props.changedData
              ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
              ?.tableData?.find((tD) => tD.date == record.date)?.quota === undefined
              ? value
              : props.changedData
                  ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                  ?.tableData?.find((tD) => tD.date == record.date)?.quota
          }
          onChange={(v) => props.onDataChange(record, v, "quota")}
          placeholder={t("parse:enter_value")}
        />
      ),
      // width: "15%",
    },
    {
      title: t("parse:metrics_table.value"),
      dataIndex: "value",
      key: "value",
      render: (value, record, index) => {
        if (record.date == "01.10.2023") {
          // console.log(
          //   value,
          //   record,
          //   props.changedData?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
          // );
        }
        return (
          <InputNumber
            size="large"
            className="full-width"
            isNewStyle
            value={
              props.changedData
                ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                ?.tableData?.find((tD) => tD.date == record.date)?.value === undefined
                ? value
                : props.changedData
                    ?.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)
                    ?.tableData?.find((tD) => tD.date == record.date)?.value
            }
            onChange={(v) => props.onDataChange(record, v, "value")}
            placeholder={`${t("parse:enter_value")} 111`}
          />
        );
      },
      // width: "15%",
    },
    {
      title: t("parse:metrics_table.filler"),
      dataIndex: "filler",
      key: "filler",
      width: "25%",
      render: (value, record, index) =>
        value == null ? null : (
          <div className="d-flex align-center">
            <UserInfo
              user={value}
            />
          </div>
        ),
    },
    {
      title: t("parse:metrics_table.fill_date"),
      dataIndex: "fill_date",
      key: "fill_date",
      width: 150,
      align: "center",
    },
    {
      title: "",
      dataIndex: "",
      key: "action_delete",
      render: (value, record, index) => (
        <IconButton
          onClick={() => props.onDataChange(record, null, "reset")}
          className={"metrics-table-delete-button"}
        >
          <Icon component={() => <FiX />} />
        </IconButton>
      ),
      width: "1%",
    },
  ];
  // .filter(c => !(!props.metric.isOneCanvas || !(props.metric.isOneCanvas && (props.metric.children ?? []).length > 1)) ? c.key == "quota" : c) as ColumnType<IMetricDataData>

  const getMetricName = (child?: MetricSource2UserDto): string => {
    // let label: string | undefined | null = "";
    //
    // if (child != null) {
    //   let _l1 = props.metric?.sources?.find((s) => s.id == child?.metricSourceId)?.label;
    //   let _l2 = props.metric?.sources?.find((s) => s.existingSourceId == child?.metricSourceId)?.existingSource?.label;
    //   label = (_l1 ?? "").trim().length > 0 ? _l1 : _l2;
    // } else {
    //   let _l1 = props.metric?.sources?.find(
    //     (s) => s.id == props.metric?.children?.find((c) => c.id == props.graphId)?.metricSourceId
    //   )?.label;
    //   let _l2 = props.metric?.sources?.find(
    //     (s) => s.existingSourceId == props.metric?.children?.find((c) => c.id == props.graphId)?.metricSourceId
    //   )?.existingSource?.label;
    //   label = (_l1 ?? "").trim().length > 0 ? _l1 : _l2;
    // }

    return "";
    // return (label ?? "").trim().length > 0
    //   ? label ?? ""
    //   : props.metric?.renderType == 1
    //   ? truncateString(props.metric?.rolePosition?.role?.name ?? "", 18, "middle") +
    //     " - " +
    //     truncateString(props.metric?.rolePosition?.name ?? "", 18, "middle")
    //   : (props.metric?.children?.find((c) => c.id == props.graphId)?.user2Role?.user?.nameShort as string);
  };

  return (
    <Dialog
      scrollStrategy="dialogBody"
      className={"metric-data-dialog"}
      width={"80vw"}
      closable={false}
      maskClosable={false}
      // closable={!props.loadingState.isLoading}
      open={props.isOpen}
      onClose={() => (props.loadingState.isLoading ? undefined : props.onOpen(false))}
      title={
        props.metric != null
          ? truncateString(
              props.metric.nameFormatted ?? "",
              64
            )
          : t("common:menu.metric_settings_dropdown.data")
      }
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="d-flex flex-column" style={{ overflow: "hidden" }}>
        <div className="">
          {props.metric?.renderType == 1 && (
            <RadioGroup
              disabled={props.loadingState.isLoading}
              value={props.currentMetricSource2UserId != null ? props.currentMetricSource2UserId.toString() : null}
              onChange={(e) => props.onCurrentMetricSource2UserIdChange(e.target.value)}
              className="mb-4"
              children={
                <div className="d-stack-column spacing-2">
                  {(props.metric?.graphs ?? []).map((g) => (
                    <Radio
                      key={g.metricSource2UserId}
                      value={(g.metricSource2UserId as number).toString()}
                      children={g.name}
                      isNewDesign
                    />
                  ))}
                </div>
              }
            />
          )}
        </div>
        <div
          className="flex-grow-1 full-width metric-data-dialog-table"
          style={{ overflow: "auto" }}
        >
          <Table
            loading={props.loadingState.isLoading}
            scroll={{ x: "100%" }}
            size="small"
            tableLayout="auto"
            pagination={false}
            sticky
            bordered={false}
            isNewStyle
            columns={tableColumns}
            dataSource={
              props.data.find((d) => d.metricSource2UserId == props.currentMetricSource2UserId)?.tableData ?? []
            }
          />
          {/*{JSON.stringify(props.data.find((d) => d.graphId == props.currentGraphId)?.tableData ?? [])}*/}
          <ScrollTrigger onIntersection={props.onLoadMore} marginTop={true} disabled={props.loadingState.isLoading} />
        </div>
      </div>
      <DialogActions>
        <Button
          disabled={props.loadingState.isLoading}
          variant="text"
          children={t("ui:button.cancel")}
          onClick={() => props.onOpen(false)}
        />
        <Button
          className={"ml-4"}
          disabled={props.changedData.map((d) => d.tableData).flat(2).length == 0}
          loading={props.loadingState.isLoading}
          children={t("ui:button.apply")}
          onClick={props.onSave}
        />
      </DialogActions>
    </Dialog>
  );
}
