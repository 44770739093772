import "./ScheduleDatesList.scss";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { Button, Card, DatePicker } from "../../../../uiKit";
import clsx from "clsx";
import { LucideChevronLeft, LucideChevronRight } from "lucide-react";
import { useDateHelpers, useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";

interface IScheduleDatesListProps {
  isPeriodPickerOpen: boolean;
  datesToDisplay: Dayjs[][];
  periodPickerDate: {
    start: Dayjs;
    end: Dayjs;
  };

  onPeriodPickerNextClick: (event: any) => void;
  onPeriodPickerPreviousClick: (event: any) => void;
  onPeriodPickerChange: (value: Dayjs | null) => void;
  onPeriodPickerOpenChange: (isOpen: boolean) => void;
}

const ScheduleDatesListObserved = (props: IScheduleDatesListProps) => {
  const { authStore } = useRootStore();
  const { formatDate } = useDateHelpers();

  return (
    <div className="schedule__dates-list__wrapper d-flex">
      <div className="schedule__dates-list__limiter">
        <Button
          className="schedule__period-picker__button"
          onClick={() => props.onPeriodPickerOpenChange(true)}
          style={{ height: "48px" }}
          variant="filled"
          // disabled={rolesHistory.info.isLoading}
        >
          <LucideChevronLeft onClick={(event) => props.onPeriodPickerPreviousClick(event)} size={24} />
          {`${formatDate(props.periodPickerDate.start, {
            formatObject: {
              month: "short",
              hour: undefined,
              minute: undefined,
              timeZone: "Africa/Abidjan",
            },
          })} — ${formatDate(props.periodPickerDate.end, {
            formatObject: {
              month: "short",
              hour: undefined,
              minute: undefined,
              timeZone: "Africa/Abidjan",
            },
          })}`}
          <LucideChevronRight onClick={(event) => props.onPeriodPickerNextClick(event)} size={24} />
        </Button>
        <DatePicker
          timeZone="Africa/Abidjan"
          className="schedule__period-picker"
          onOpenChange={props.onPeriodPickerOpenChange}
          open={props.isPeriodPickerOpen}
          value={props.periodPickerDate.start.utc(true).tz(undefined, true)}
          onChange={props.onPeriodPickerChange}
          disabledDate={(d: Dayjs) => d.day() != authStore.getCurrentCompany?.weekStart}
        />
      </div>
      <div className="d-stack spacing-1">
        {props.datesToDisplay.map((d2d) => (
          <div key={d2d[0]?.toISOString()} className="d-stack-column spacing-1">
            <Card className="br-sm schedule__dates-list__month" isShadowed={false}>
              <span className="no-select" children={d2d[0].format(d2d.length < 3 ? "MMM" : "MMMM")} />
            </Card>
            <div className="d-stack spacing-1">
              {d2d.map((d) => (
                <Card
                  key={d.toISOString()}
                  className={clsx("schedule__dates-list__day", {
                    __today: dayjs().tz().date() == d.date(),
                  })}
                  isShadowed={false}
                >
                  <span className="no-select">
                    <span children={d.date()} />
                    <span children={d.format("dd")} />
                  </span>
                </Card>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ScheduleDatesList = observer(ScheduleDatesListObserved);
