import "./CommunicationSidebarCalendarPlanning.scss";
import { Empty, Icon, IconButton, Spin, Text } from "../../../../../uiKit";
import { ResizableBox } from "react-resizable";
import React, { useEffect, useRef } from "react";
import { ICalendarPlanningSidebarProps } from "./CommunicationSidebarCalendarPlanning";
import { IssueShortDto } from "../../../../../../api";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { Draggable } from "@fullcalendar/interaction";
import dayjs from "dayjs";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { CardIssue } from "../cardIssue/CardIssue";
import { SideBarIcon } from "../../../../../../constants/icon";
import { useTranslation } from "react-i18next";
import { CalendarFilterDropdownContent } from "../calendarFilterDropdownContent/CalendarFilterDropdownContent";
import { FilterButton } from "../../../../../elements/filterButton/FilterButton";
import {
  CalendarSidebarFilterDropdownContent
} from "../CalendarSidebarFilterDropdownContent/CalendarSidebarFilterDropdownContent";
import { GoogleCalendarBanner } from "../../../../../elements/googleCalendarBanner/GoogleCalendarBanner";

interface ICalendarPlanningSidebarViewProps extends ICalendarPlanningSidebarProps {
  isLoading: boolean;
  isDone: boolean;
  totalItems: number;
  totalTimePlan: string;
  items: IssueShortDto[];
  isReload: boolean;
  isActiveFilter: boolean;
  isOverdueDeadline: boolean;
  isNotStatusDone: boolean;

  onDragStart: (maxDate: Date) => void;
  onDragEnd: () => void;
  onLoadNext: () => void;
  setIsOverdueDeadline: (value: boolean) => void;
  setIsNotStatusDone: (value: boolean) => void;
}

interface IFullCalendarDraggableWrapperProps {
  issue: IssueShortDto;

  onDragStart: (maxDate: Date) => void;
  onDragEnd: () => void;
}

const FullCalendarDraggableWrapper = (props: IFullCalendarDraggableWrapperProps) => {
  const wrapperRef = useRef(null);

  const handleDragStart = () => {
    const dateDeadline = props.issue.dateDeadline != null ? dayjs(props.issue.dateDeadline) : null;
    const timePlan = props.issue.timePlan ? props.issue.timePlan : null;
    if (dateDeadline != null && timePlan != null) {
      const parsedTimePlan = getObjectFromTimeSpan(timePlan);
      const maxDate = dateDeadline
        .add({
          day: -(parsedTimePlan.days ?? 0),
          hour: -(parsedTimePlan.hours ?? 0),
          minute: -(parsedTimePlan.minutes ?? 0),
          second: -(parsedTimePlan.seconds ?? 0),
        })
        .toDate();
      props.onDragStart(maxDate);
    }
  };

  useEffect(() => {
    const draggable = new Draggable(wrapperRef.current!, {
      eventData: function () {
        const timePlan = props.issue.timePlan?.trim() === "00:00:00" ? "00:15" : props.issue.timePlan;
        return {
          id: props.issue.id,
          title: props.issue.name,
          duration: timePlan?.trim() || "00:15",
          create: true,
          event: {
            id: 0,
          },
          extendedProps: {
            issueData: props.issue,
          },
        };
      },
    });

    return () => draggable.destroy();
  }, [props.issue]);

  return (
    <div ref={wrapperRef} onMouseDown={handleDragStart} onMouseUp={props.onDragEnd}>
      <CardIssue noDrag issue={props.issue} />
    </div>
  );
};

export const CalendarPlanningSidebarView = (props: ICalendarPlanningSidebarViewProps) => {
  const { t } = useTranslation();

  return (
    <ResizableBox
      width={props.sidebarSize.width}
      className="calendar-planning-sidebar__resizer"
      axis="x"
      resizeHandles={[]}
      // resizeHandles={["e"]}
      maxConstraints={[Math.round(document.body.clientWidth / 2 - 250) || 520, undefined as any]}
      minConstraints={[260, undefined as any]}
      // onResize={(e, { size }) => props.onSidebarSizeChange({ width: size.width })}
      onResizeStart={() => props.onSidebarResizing(true)}
      onResizeStop={(e, { size }) => {
        props.onSidebarSizeChange({ width: size.width });
        props.onSidebarResizing(false);
      }}
    >
      <div className="calendar-planning-sidebar__wrapper full-height d-stack-column spacing-3 pa-4">
        <div className="calendar-planning-sidebar-header">
          <GoogleCalendarBanner />
          {/*<div className="d-stack-row align-center justify-end">*/}
          {/*  /!*<Button children="Распределить задачи" />*!/*/}
          {/*  <IconButton*/}
          {/*    className="flex-shrink-0 ml-4"*/}
          {/*    onClick={props.onSidebarOpenChange}*/}
          {/*    children={<Icon component={() => <SideBarIcon size={20} />} />}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="d-stack-row align-center justify-space-between">

            <Text
              size="14px"
              weight="bold"
              children={t('ui:calendar.unallocated_tasks')}
            />
            <FilterButton
              placement={"bottomLeft"}
              dropdownContent={
                <CalendarSidebarFilterDropdownContent
                  isOverdueDeadline={props.isOverdueDeadline}
                  setIsOverdueDeadline={props.setIsOverdueDeadline}
                  isNotStatusDone={props.isNotStatusDone}
                  setIsNotStatusDone={props.setIsNotStatusDone}
                  isLoading={props.isLoading}
                />
              }
              isActiveFilter={props.isActiveFilter}
            />

          </div>
          <div className="d-stack-column spacing-2 no-select">
            {/*<Text size="14px" weight="bold" children={"Не распределённые задачи"} />*/}
            <div className="d-stack-column">
              <Text
                size="12px"
                type="secondary"
                children={`${t('ui:calendar.total_tasks')} ${props.totalItems}`}
              />
              <Text
                size="12px"
                type="secondary"
                children={`${t('ui:calendar.planned_time')} ${props.totalTimePlan}`}
              />
            </div>
          </div>
        </div>
        <div
          className="calendar-planning-sidebar__content"
        >

          {!props.isReload && props.isLoading && !props.items.length ? (
            <div className="full-height full-width d-flex align-center justify-center">
              <Spin spinning={true} size="large" />
            </div>
          ) : !props.isReload && props.items.length == 0 ? (
            <Empty />
          ) : (
            <div className="d-stack-column spacing-2">
              {props.items.map((issue) => (
                <FullCalendarDraggableWrapper
                  issue={issue}
                  key={issue.id}
                  onDragStart={props.onDragStart}
                  onDragEnd={props.onDragEnd}
                />
              ))}
              {!props.isDone && <ScrollTrigger onIntersection={props.onLoadNext} marginTop={false} />}
            </div>
          )}
        </div>
      </div>
    </ResizableBox>
  );
};
