import React, { memo, ReactNode } from "react";
import DeleteMetricOrDashboardDialogView from "./DeleteMetricOrDashboardDialogView";

interface IDashboardDeleteDialog {
  open: boolean;
  title: string;
  description?: ReactNode;

  onDelete(): void;

  onOpen(isOpen: boolean): void;
}

const DeleteMetricOrDashboardDialog = (props: IDashboardDeleteDialog) => {
  return <DeleteMetricOrDashboardDialogView {...props} />;
};

export default memo(DeleteMetricOrDashboardDialog);
