import React, { useRef, useState } from "react";
import { IPlanningRejectDialog } from "./PlanningRejectDialog.interface";
import { PlanningRejectDialogView } from "./PlanningRejectDialogView";
import { useTranslation } from "react-i18next";
import { api } from "../../../../services";
import { initialEditorContent } from "../../../../utils/textEditor";

export const PlanningRejectDialog = (props: IPlanningRejectDialog) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>(initialEditorContent);
  const editorRef = useRef<{ resetEditor: () => void }>();

  const handleChangeReason = (newReason: string) => {
    setReason(newReason);
  };

  const handlePlanReject = async () => {
    await api.planHistory.create({
      planId: props.plan?.id,
      comment: {
        content: reason,
      },
    });
    props.onPlanReject()
    // props.onPlanReject && props.onPlanReject({ ...props.plan, status: 4 }, t("notifier:success.plan_rejected"));
    onCloseAndReset(false);
  };

  const onCloseAndReset = (value: boolean) => {
    setReason(initialEditorContent);
    editorRef.current?.resetEditor();
    props.onOpeningChange(value);
  };

  return (
    <PlanningRejectDialogView
      ref={editorRef}
      onOpeningChange={onCloseAndReset}
      isOpen={props.isOpen}
      reason={reason}
      onReasonChange={handleChangeReason}
      onSuccess={handlePlanReject}
      isLoadingButton={props.isLoadingButton}
    />
  );
};
