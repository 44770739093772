import React from "react";
import { IRoleSelectionBox } from "./UserSelectionDialog.interface";
import { useTranslation } from "react-i18next";
import { SearchInputField } from "../../../elements/searchInputField/SearchInputField";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { Text } from "../../../uiKit";
import { OrgchartTree } from "../../pages/regulation/components/OrgchartTree/OrgchartTree";

export const RoleSelectionBox = (props:IRoleSelectionBox) => {
  const { t } = useTranslation();

  return (
    <div
      className="user-selection-dialog__left-bar d-stack-column spacing-3"
      style={!props.isSupportOldUi ? { width: "100%" } : undefined}
    >
      {/*<h2>{t("ui:subheader.role_select")}</h2>*/}
      <OrgchartSwitcher
        value={props.orgchartId ?? undefined}
        onChange={(v) => typeof v === "number" && props.onChangeOrgchartId(v)}
        preventDefault
        selectProps={{
          allowClear: false,
          className: "full-width",
          placeholder: t("ui:placeholder.click_to_select"),
        }}
        drawType="select"
      />
      <SearchInputField
        value={props.searchRoleValue}
        setValue={props.onChangeSearchRoleValue}
        // variant="standard"
        backgroundInherit
        placeholder={t("ui:placeholder.enter_role")}
      />
        <OrgchartTree
          treeData={props.treeData}
          expandedKeys={props.expandedKeys}
          onExpand={props.onExpand}
          selectedRoles={props.selectedRoles}
          setSelectedRoles={props.setSelectedRoles}
          updateTreeItemData={props.updateTreeItemData}
          activeRole={props.activeRole}
          searchValue={props.searchRoleValue}
          onSelectActiveRole={props.onSelectActiveRole}
        />
      {/*)}*/}

    </div>
  );
};
