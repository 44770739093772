import React from "react";
import { DropdownView } from "./DropdownView";
import { DropdownProps, MenuProps } from "antd";

interface IDropdown extends DropdownProps {
  items?: IDropdownItem[];
}

export interface IDropdownItem {
  id?: number;
  key?: string | number;
  text?: string | React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  isDivider?: boolean;
  onClick?: (event?: any) => void;
}

const generateDropdownMenu = (items: IDropdownItem[]): MenuProps["items"] =>
  items.map((item, index) => ({
    key: item.key ?? index,
    type: item.isDivider ? "divider" : undefined,
    disabled: item.disabled,
    icon: item.icon,
    label: item.text,
    onClick: ({ domEvent }) => {
      item.onClick && item.onClick(domEvent);
      domEvent.stopPropagation();
    },
    // onTitleClick: ({ domEvent }) => {
    //   item.onClick && item.onClick();
    //   domEvent.stopPropagation();
    // },
  }));

export function Dropdown({ items, ...rest }: IDropdown) {
  // overlay={items != null || overlay == null ? generateDropdownMenu(items) : overlay}
  return <DropdownView menu={{ items: generateDropdownMenu(items ?? []) }} {...rest} />;
}
