import React, { useMemo, useState } from "react";
import { RoleDto } from "../../../../api";
import { api } from "../../../../services";
import OrgchartFunctionMoveDialogView from "./OrgchartFunctionMoveDialogView";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { ORGCHART_CLASSIC_TYPE, ORGCHART_SIMPLIFIED_TYPE } from "../../../../constants/orgchartTypes";

interface IOrgchartFunctionMoveDialog {
  allRoles: RoleDto[];
  roleId: number;

  onSave(): void;

  onClose(): void;
}

function OrgchartFunctionMoveDialog(props: IOrgchartFunctionMoveDialog) {
  const { orgchartStore } = useRootStore();

  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
  const [changeFunctionColor, setChangeFunctionColor] = useState<boolean>(true);
  const onSaveHandler = async () => {
    const roleData = props.allRoles?.find((role: RoleDto) => role.id == props.roleId);
    const r = await api.role.edit(props.roleId, {
      ...roleData,
      applyColorToChildren: changeFunctionColor,
      parentId: selectedRoleId,
      colorHex: changeFunctionColor
        ? props.allRoles.find((role: RoleDto) => role.id == selectedRoleId)?.colorHex
        : getCurrentRole()?.colorHex,
    });
    props.onSave();
  };

  // ?.filter((r: RoleDto) => r.id != props.roleId)
  //   ?.filter((ro) => ro.parentRoleId != props.roleId)
  //   ?.filter((rr) => props.allRoles?.find((role) => role.id == props.roleId)?.childRoles?.filter())

  const getFilteredRoles = (roles: RoleDto[], role2excludeId: number): RoleDto[] => {
    let roles2filter: RoleDto[] = [...roles];

    roles2filter.forEach((role: RoleDto) => {
      if (role.parentId == role2excludeId) {
        roles2filter = getFilteredRoles(roles2filter, role.id as number);
        roles2filter = roles2filter.filter((r) => r.parentId != role2excludeId);
      } else {
        // Do nothing
      }
    });

    return roles2filter;
  };

  const getCurrentRole = (): RoleDto => {
    return props.allRoles.find((r) => r.id == props.roleId) as RoleDto;
  };

  const filterRoles = useMemo(() => {
    const { type } = orgchartStore.getCurrentOrgchart ?? {};
    const isClassic = type?.startsWith(ORGCHART_CLASSIC_TYPE) || type?.startsWith(ORGCHART_SIMPLIFIED_TYPE);
    const list = getFilteredRoles(props.allRoles, props.roleId)
      .filter((r) => r.id != props.roleId)
      .filter((r) => isClassic ? (!!r.parentId || (r.childRoles ?? []).length < 2): r)
      .filter((r) => r.id != getCurrentRole().parentId) ?? [];
    return list;
  }, [props.allRoles, props.roleId])

  return (
    <OrgchartFunctionMoveDialogView
      allRoles={(props.allRoles ?? []) as RoleDto[]}
      onSave={onSaveHandler}
      onClose={props.onClose}
      roleId={props.roleId}
      onChipClick={setSelectedRoleId}
      selectedRoleId={selectedRoleId}
      filteredRoles={filterRoles}
      changeFunctionColor={changeFunctionColor}
      setChangeFunctionColor={setChangeFunctionColor}
    />
  );
}

export default observer(OrgchartFunctionMoveDialog);
