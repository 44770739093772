import React, { memo } from "react";
import { SelectProps } from "antd";
import { ISelectItem, SelectView } from "./SelectView";

export interface ISelect extends SelectProps {
  items?: ISelectItem[];
  fullWidth?: boolean;
  readOnly?: boolean;
  variant?: "default" | "filled";
  isSelectedDisabled?: boolean;
  isNewDesign?: boolean;
  isNoBorder?: boolean;
  isBigSize?: boolean;
  // TODO: Add block prop
}

export const Select = memo((props: ISelect) => {
  return <SelectView {...props} />;
})
