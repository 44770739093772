import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Dropdown, Icon, IconButton } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import "./SectionPageHeaderButtons.scss";
import { DotsIcon, EditIcon, LookIcon } from "../../../../../../constants/icon";
import { SectionContext } from "../../../../../../contexts/RegulationPage/SectionContext";
import { ISectionPageHeaderButtonsProps } from "./ISectionPageHeaderButtons";
import { defineAllow } from "../../containers/regulation/helpers";
import { allowsRegulationKeys } from "../../misc/consts";
import { AccessSettingsButton } from "../ButtonIcons/AccessSettingsButton";
import { AssignStudyButton } from "../ButtonIcons/AssignStudyButton";
import { EditButton } from "../ButtonIcons/EditButton";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";
import { useNavigate } from "react-router-dom";
import { IDropdownItem } from "../../../../../uiKit/navigation/dropdown/Dropdown";
import { useRootStore } from "../../../../../../hooks";

export const SectionPageHeaderButtons = (props: ISectionPageHeaderButtonsProps) => {
  const { isEditable, toEdit, isCreated } = props;
  const {
    isLoading,
    handleSave,
    handleCancel,
    handleOpenAssignForStudyDialog,
    handleOpenAccessSettingsDialog,
    handleSwitchEditable,
    handleOpenConfirmDialog,
    handleOpenAddingRegulation,
    section,
    handleClickCreateDocument,
    handleClickCreateSection,
  } = useContext(SectionContext);

  const { regulationStore } = useRootStore();

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleIsOpenChange = (value: boolean) => {
    setIsOpen(value);
  };

  const handleClickSave = () => {
    handleSave(toEdit)
  };

  const handleClickCancel = () => {
    handleCancel(toEdit)
  };

  const handleClickEdit = () => {
    handleSwitchEditable();
  };


  const creationDropdownItems = useMemo(
    () => [
      {
        id: 1,
        text: t("common:menu.regulation_creating_dropdown.new_document"),
        action: handleClickCreateDocument,
      },
      {
        id: 2,
        text: t("common:menu.regulation_creating_dropdown.new_topic"),
        action: handleClickCreateSection,
      },
    ],
    [section]
  );

  return (
    <div className="section-page-header-buttons">
      { isEditable && (
        <>
          <Button
            variant={"default"}
            onClick={handleOpenAddingRegulation}
          >
            { t("ui:button.add") }
          </Button>
          <Button
            loading={isLoading}
            onClick={handleClickSave}
            variant="filled"
            className="button-icon_16"
          >
            {t("ui:button.save")}
          </Button>
          <Button
            onClick={handleClickCancel}
            variant="default"
            className="button-icon_16"
          >
            {t("ui:button.cancel")}
          </Button>
        </>
      )}

      {/*{permissionToEdit && (*/}
      {/*  <IconButton*/}
      {/*    onClick={handleOpenDialog}*/}
      {/*    icon={<Icon component={() => (<LookIcon/>)} />}*/}
      {/*    isShowBorder={true}*/}
      {/*  />*/}
      {/*  */}
      {/*)}*/}
      {defineAllow(allowsRegulationKeys.edit, section?.actions ?? []) && !isEditable && (
        <DropdownMenu
          items={creationDropdownItems}
          iconType="chevron"
          trigger={["click"]}
          buttonProps={{
            type: "primary",
            text: t("ui:button.add"),
          }}
        />
      )}
      {defineAllow(allowsRegulationKeys.edit, section?.actions ?? []) && (
        <AccessSettingsButton onClick={handleOpenAccessSettingsDialog} />
      )}
      {defineAllow(allowsRegulationKeys.assignment, section?.actions ?? []) && (
        <AssignStudyButton onClick={handleOpenAssignForStudyDialog} />
      )}
      {defineAllow(allowsRegulationKeys.edit, section?.actions ?? []) && !isEditable && (
        <EditButton
          onClick={handleClickEdit}
        />
      )}
      { defineAllow(allowsRegulationKeys.edit, section?.actions ?? []) && (
        <Dropdown
          open={isOpen}
          onOpenChange={handleIsOpenChange}
          placement={"bottom"}
          rootClassName={"regulation-editor-toolbar-action-drop-down"}
          trigger={["click"]}
          dropdownRender={() => {
            return (
              <div
                className="regulation-editor-toolbar-action"
              >
                <div
                  className="regulation-editor-toolbar-action-item"
                  onClick={handleOpenAddingRegulation}
                >

                  <div className="regulation-editor-toolbar-action-item__title">
                    { t("ui:regulation_actions.added_to_section.title") }
                  </div>
                  <div className="regulation-editor-toolbar-action-item__description">
                    { t("ui:regulation_actions.added_to_section.text") }
                  </div>
                </div>
                {/*<div className="regulation-editor-toolbar-action-item">*/}
                {/*  <div className="regulation-editor-toolbar-action-item__title">*/}
                {/*    { t("ui:regulation_actions.history.title") }*/}
                {/*  </div>*/}
                {/*  <div className="regulation-editor-toolbar-action-item__description">*/}
                {/*    { t("ui:regulation_actions.history.text") }*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="regulation-editor-toolbar-action-item">
                  <div
                    className="regulation-editor-toolbar-action-item__title"
                    onClick={handleOpenConfirmDialog}
                  >
                    { t("ui:regulation_actions.delete.title") }
                  </div>
                  <div className="regulation-editor-toolbar-action-item__description">
                    { t("ui:regulation_actions.delete.text") }
                  </div>

                </div>
              </div>
            );
          }}
        >
          <IconButton
            onClick={() => handleIsOpenChange(true)}
            icon={<Icon component={() => (<DotsIcon />)} />}
            isShowBorder={true}
          />
        </Dropdown>
      )}
    </div>
  )
}