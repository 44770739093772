import { api } from "../../../../../services";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RoleDto, RolesWithOrgchartDto } from "../../../../../api";
import { DataNode } from "antd/es/tree";
import { RoleSelectorDto } from "../../../../../api/models/RoleSelectorDto";
import { useNotifier, useRootStore } from "../../../../../hooks";
import { useTranslation } from "react-i18next";
import { RegulationTreeNode } from "../../../../../api/models/RegulationTreeDto";
import {
  IFunctionTreeItem
} from "../../../dialogs/regulationAccessControlDialog/regulationFunctionSelectionDialog/functionTreeItem/FunctionTreeItem.interface";
import { positionsKeys } from "../../../dialogs/userSelectionNewDialog/UserSelectionDialog.interface";
import { NavigationMenuItemClaimGroupDto } from "../../../../../api/models/NavigationMenuItemClaimGroupDto";
import { MenuItemClaimType } from "../../../../../api/models/NavigationMenuDto";
import { IGeneratorTreeData, IUpdateTreeItem, IUpdateTreeItemData } from "./IGeneratorTreeData.interface";

export const useOrgchart = () => {
  const { authStore } = useRootStore();

  const isSupportOldUi = authStore.getCurrentCompanyUiType == 1;

  const notifier = useNotifier();
  const { t } = useTranslation();
  const [treeData, setTreeData] = useState<RegulationTreeNode[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<IFunctionTreeItem[]>([]);


  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const onExpand = useCallback((expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  }, []);

  const fetchOrgchartData = async (orgchartId: number, isRegular: boolean, id?: number) => {
    setIsLoading(true);

    const response = isRegular
      ? await api.regulation.getRoleSelectorsTree(id ?? -1, orgchartId)
      : await api.navigationMenu.getRoleSelectorsTree(id ?? -1, orgchartId);
    if (response) {
      const treeData = getTreeData(response, orgchartId, id);
      setTreeData(treeData);
    } else {
      // setRoles(null);
    }
    setIsLoading(false);
  };

  const fetchRoleSelectorsClaimTreeData = async (orgchartId: number, id?: number) => {
    setIsLoading(true);
    const response = await api.navigationMenu.getRoleSelectorsClaimTree(id ?? -1, orgchartId);
    if (response) {
      const treeData = getTreeData(response, orgchartId, id);
      setTreeData(treeData);
    } else {
      // setRoles(null);
    }
    setIsLoading(false);
  };
  const [claimType, setClaimType] = useState<MenuItemClaimType>(MenuItemClaimType.Read)

  const generatorTreeData = (
    {
      list,
      parentSelected,
      orgchartId,
      regulationId,
      parentClaimType
    }: IGeneratorTreeData): RegulationTreeNode[] =>  {

    const data = list
      .map((role) => {
        const {
          roleName: title,
          roleId: id,
          // hasItems,
          isSelected,
          includeNestedRoles = false,
          items: children = [],
          positions,
          selectedPositions = [],
          claimType,
          isRoot,
        } = role;
        if (isRoot) {
          const childrenKeys = children.map(({  roleId  }) => `${roleId}`)
          setExpandedKeys((prev) => ([...prev, `${id}`, ...childrenKeys]));
        }
        const isSelectedChildren = parentSelected || isSelected && includeNestedRoles;
        const childrenClaimType = parentSelected ? parentClaimType || claimType : claimType;

        return {
          title,
          key: `${id}`,
          id,
          children: children.length
            ? generatorTreeData({
              list: children,
              parentSelected: isSelectedChildren,
              orgchartId,
              regulationId,
              parentClaimType: childrenClaimType,
            })
            : [],
          isLeaf: children.length === 0,
          orgchartId,
          regulationId,
          isSelected: parentSelected || isSelected,
          includeNestedRoles: parentSelected || includeNestedRoles,
          positions,
          selectedPositions,
          claimType: parentSelected ? parentClaimType || claimType : claimType,
        }});
    return data as RegulationTreeNode[];
  }

  const getTreeData = useCallback((list: RoleSelectorDto[], orgchartId: number, regulationId?: number) => {
    const data = generatorTreeData({
      list,
      parentSelected: false,
      orgchartId,
      regulationId,
    });

    return data;
  }, []);

  const updateTreeData = (list: RegulationTreeNode[], key: React.Key, children: RegulationTreeNode[]): RegulationTreeNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  const handleLoadData = useCallback(async (item: any) => {
    return [];
      }, []);

  const updateChildren = (childrenList: RegulationTreeNode[], isSelected: boolean) => {
    childrenList.forEach((item) => {
      item.isSelected = isSelected;
      item.includeNestedRoles = isSelected;
      item.claimType = claimType;
      if (isSupportOldUi && isSelected) {
        item.selectedPositions = [];
        item.positionType = undefined;
      }

      if ((item.children ?? []).length) {
        updateChildren(item.children ?? [], isSelected);
      }
    });
  }

  const updateTreeItem = (
    {
      list,
      key,
      isSelected,
      parentTree,
      includeNestedRoles,
      parentBranch,
    }: IUpdateTreeItem
  ): RegulationTreeNode[] => {
    const indexCurrentNode = list.findIndex(item => item.key === key);
    if (indexCurrentNode !== -1) {

      const node = list[indexCurrentNode];

      if (includeNestedRoles && (node.children ?? []).length > 0) {
        updateChildren(node.children!, isSelected);
      }
      parentBranch?.split("-").forEach((item) => parentTree.push(+item));
      setSelectedRoles((prev) => {

        if (isSelected) {
          const currentRoleIndex = prev.findIndex(({ id }) => id === node.id);
          if (currentRoleIndex !== -1) {
          // && currentRole.claimType === claimType
            const currentRole = prev[currentRoleIndex];

            if (currentRole.claimType === claimType) {
              return prev;
            }
            const arr = [...prev];
            arr.splice(currentRoleIndex, 1, {
              ...currentRole,
              claimType,
            })
            return arr;
          }
          const {
            id,
            title,
            orgchartId,
            selectedPositions = [],
            positionType = null,
          } = node;
          const selectedList = includeNestedRoles
            ? prev.filter(({id}) => !node.children!.find((child) => child.id === id))
            : prev
          return [...selectedList, {
            id,
            title,
            orgchartId,
            selectedPositions: isSupportOldUi && includeNestedRoles ? [] : selectedPositions,
            includeNestedRoles: includeNestedRoles ?? false,
            positionType: isSupportOldUi && includeNestedRoles ? undefined : positionType,
            claimType,
          }];
        }
        const selectedChilds = includeNestedRoles || !node.includeNestedRoles
          ? []
          : (node.children ?? [])
            .filter((item) => item.isSelected)
            .map((child) => {
              const { id, title, orgchartId, selectedPositions = [], positionType } = child;

              return {
                id,
                title,
                orgchartId,
                selectedPositions: isSupportOldUi && includeNestedRoles ? [] : selectedPositions,
                positionType: isSupportOldUi && includeNestedRoles ? undefined : positionType,
                includeNestedRoles: includeNestedRoles ?? false,
                claimType,
              }
            });

        return [
          ...prev.filter(({id}) => id !== node.id),
          ...selectedChilds,
        ];
      });
      const updateList = [...list];
      updateList.splice(indexCurrentNode, 1, {
        ...node,
        isSelected,
        includeNestedRoles,
        selectedPositions: isSupportOldUi && includeNestedRoles ? [] : node.selectedPositions,
        positionType: isSupportOldUi && includeNestedRoles ? undefined : node.positionType,
        claimType,
      });
      return updateList
    }
    const newList = list.map((node) => {
      if (node.children?.length) {
        return {
          ...node,
          children: updateTreeItem(
            {
              list: node.children,
              key,
              isSelected,
              parentTree,
              includeNestedRoles,
              parentBranch: parentBranch ? `${parentBranch}-${node.id}` : `${node.id}`,
            }),
        }
      }
      return node;
    });
    return newList;
  }

  const updateIncludeNestedRoles = (list:RegulationTreeNode[], parentTree: number[], key: React.Key) => {
    const currentRole = list.find(role => parentTree.includes(role.id));
    if (currentRole) {
      if (currentRole.isSelected) {
        const selectedChildren = (currentRole.children ?? [])
          .filter((child) => child.key !== key && child.isSelected);

        const selectedChildrenList: IFunctionTreeItem[] = selectedChildren.map((children) => {
          const {
            id,
            title,
            includeNestedRoles,
            orgchartId,
            selectedPositions,
            claimType,
          } = children;
          const includeNestedRolesChecked = parentTree.includes(id) ? false : includeNestedRoles ?? false;
          return {
            id,
            title,
            includeNestedRoles: includeNestedRolesChecked,
            orgchartId: orgchartId!,
            selectedPositions: includeNestedRolesChecked && isSupportOldUi ? [] : selectedPositions ?? [],
            claimType,
          }
        });

        setSelectedRoles((prev) => {
          const selectedList = selectedChildrenList
            .filter((item) => !prev.find((el) => el.id === item.id));
          return [
            ...prev,
            ...selectedList,
          ]
        });
      }

      currentRole.includeNestedRoles = false;
      setSelectedRoles((prev) => {
        const roleIndex = prev.findIndex((role) => role.id === currentRole.id && role.includeNestedRoles);
        if (roleIndex !== -1) {
          const list = [...prev];
          const role = prev[roleIndex];
          role.includeNestedRoles = false;
          list.splice(roleIndex, 1, role);
          return list;
        }
        return prev;
      })
      // currentRole.children.
      updateIncludeNestedRoles(currentRole.children ?? [], parentTree, key);
    }
  }

  const updateTreeItemData = (
   data: IUpdateTreeItemData
  ): RegulationTreeNode[] => {
    const  {
      list,
      key,
      isSelected,
      includeNestedRoles,
      isUpdateClaimType,
    } = data;
    const parentTree: number[] = [];
    const newList = updateTreeItem({
      list,
      key,
      isSelected,
      parentTree,
      includeNestedRoles,
    });

    if (!isSelected || isUpdateClaimType) {
      updateIncludeNestedRoles(newList, parentTree, key);
    }
    setTreeData(newList);
    return newList;
  };

  const updateRolePositionItem = (
    list: RegulationTreeNode[],
    key: React.Key,
    selectedPositions: number[],
    positionType: positionsKeys | null | undefined,
  ): RegulationTreeNode[] => {

    const indexCurrentNode = list.findIndex(item => item.key === key);
    if (indexCurrentNode !== -1) {
      const node = list[indexCurrentNode];

      setSelectedRoles((prev) => {
        const currentRole = prev.find(({ id }) => id === node.id);
        if (currentRole) {
          const currentRoleIndex = prev.findIndex(({ id }) => id === node.id);
          if (currentRoleIndex !== -1) {
          const selectedList = [...prev];
          selectedList.splice(currentRoleIndex, 1, {
            ...currentRole,
            selectedPositions,
            positionType: positionType ?? undefined,
          })
          return selectedList;
          }

        } else {
          const { id, title, orgchartId = [], positions = [] } = node;

          return [...prev, {
            id,
            title,
            orgchartId: orgchartId as number,
            selectedPositions,
            includeNestedRoles: false,
            positionType: positionType ?? undefined,
            positions,
          }];
        }
        return prev;
        });

      const updateList = [...list];

      updateList.splice(indexCurrentNode, 1, {
        ...node,
        selectedPositions,
        positionType: positionType ?? undefined,
      });
      return updateList
    }

    const newList = list.map((node) => {
      if (node.children?.length) {
        return {
          ...node,
          children: updateRolePositionItem(node.children, key, selectedPositions, positionType),
        }
      }
      return node;
    });
    return newList;
  }


  const updateRolePosition
    = (list: RegulationTreeNode[], key: React.Key, selectedPositions: number[], positionType?: positionsKeys | null | undefined): RegulationTreeNode[] => {
    const newList = updateRolePositionItem(list, key, selectedPositions, positionType);
    setTreeData(newList);
    return newList;
  };

  return {
    isLoading,
    setIsLoading,
    fetchOrgchartData,
    fetchRoleSelectorsClaimTreeData,
    treeData,
    handleLoadData,
    expandedKeys,
    setExpandedKeys,
    selectedRoles,
    setSelectedRoles,
    updateTreeItemData,
    updateRolePosition,
    claimType,
    setClaimType,
  }
}