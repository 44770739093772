import React, { useEffect, useState } from "react";
import { useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { observer } from "mobx-react-lite";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { ListGroupsCompanies } from "../../contents/ListGroupsCompanies/ListGroupsCompanies";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { CreateCompanyGroupDialog } from "../../../../dialogs/createCompanyGroupDialog/CreateCompanyGroupDialog";
import { MainHeader } from "../../headers/mainHeader/MainHeader";

const MainPageObserver = () => {
  const { t } = useTranslation();
  const { groupCompanyStore } = useRootStore();
  const groups = usePagingWithController(api.companyGroup);

  const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState<boolean>(false);

  const handleOpenCreateGroupDialog = () => setOpenCreateGroupDialog(true);
  const handleCloseCreateGroupDialog = () => setOpenCreateGroupDialog(false);

  const handleRestartLoad = async () => {
    groups.reset();
    groups.restart();
  };

  useEffect(() => {
    handleRestartLoad();
  }, []);

  return (
    <>
      {openCreateGroupDialog && (
        <CreateCompanyGroupDialog
          open={openCreateGroupDialog}
          onClose={handleCloseCreateGroupDialog}
          onSuccess={handleRestartLoad}
        />
      )}
      <PageHeader title={t("ui:title.super_admin_drivers")}>
        <MainHeader />
      </PageHeader>
      <PageContent isLoading={groups.info.isLoading} zoom={{ percentage: groupCompanyStore.getZoom }}>
        <ListGroupsCompanies
          isLoading={groups.info.isLoading}
          groups={groups.items}
          isDone={groups.info.isDone}
          onLoadNext={groups.loadNext}
          onOpenCreateGroupDialog={handleOpenCreateGroupDialog}
        />
      </PageContent>
    </>
  );
};

export const MainPage = observer(MainPageObserver);
