import React, { memo } from "react";
import {
  IDropdownContentElement,
  IFilterCalendarSideBarDropdownContent,
} from "./CalendarSidebarFilterDropdownContent.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import {  Switch } from "../../../../../uiKit";
import "./CalendarSidebarFilterDropdownContent.scss";

export const CalendarSidebarFilterDropdownContent = memo((
  {
    isOverdueDeadline,
    setIsOverdueDeadline,
    isNotStatusDone,
    setIsNotStatusDone,
    isLoading,
  }: IFilterCalendarSideBarDropdownContent) => {
  const { t } = useTranslation();

  const unfinishedTasks= (
    <>
      <Switch
        disabled={isLoading}
        label={t("common:misc.sort_items.unfinished_tasks").toString()}
        checked={isNotStatusDone}
        isHiddenCheckedChildren={true}
        onChange={setIsNotStatusDone}
      />
    </>
  );

  const overdueDeadline= (
    <>
      <Switch
        disabled={isLoading}
        label={t("common:misc.sort_items.overdue_deadline").toString()}
        checked={isOverdueDeadline}
        isHiddenCheckedChildren={true}
        onChange={setIsOverdueDeadline}
      />
    </>
  );

  const elements: IDropdownContentElement[] = [
    {
      id: 1,
      name: "unfinished_tasks",
      allowedRelationsToRender: [],
      component: <div className="calendar-sidebar-filter-content__item" key={1}>{unfinishedTasks}</div>,
    },
    {
      id: 2,
      name: "overdueDeadline",
      allowedRelationsToRender: [],
      component: <div className="calendar-sidebar-filter-content__item" key={2}>{overdueDeadline}</div>,
    },
  ];

  return (
    <div
      className="d-stack-column pa-2 calendar-sidebar-filter-content"
      style={{ width: "300px" }}
    >
      <SubheaderText
        text={t("ui:subheader.calendar_sidebar_filter")}
      />
      {elements.map((item) => item.component)}
    </div>
  );
});