import React from "react";
import { Select, SelectOption } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { publishedSelectKeys } from "../../misc/constants";
import { IPublishedSwitcher } from "./PublishedSwitcher.interface";

export const PublishedSwitcher = (props: IPublishedSwitcher) => {
  const { t } = useTranslation();

  const publishOptions = [
    {
      id: 1,
      name: t("parse:published"),
      key: publishedSelectKeys.published,
    },
    {
      id: 2,
      name: t("parse:not_published"),
      key: publishedSelectKeys.notPublished,
    },
  ];

  return (
    <Select
      size="middle"
      value={props.publishOption}
      style={{ width: 180 }}
      onChange={(publish) => props.onChangePublishOption(publish)}
    >
      {publishOptions.map((item) => (
        <SelectOption key={item.id} value={item.key}>
          {item.name}
        </SelectOption>
      ))}
    </Select>
  );
};
