import React, { memo, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

interface IDashboardDeleteDialogView {
  open: boolean;
  title?: string;
  description?: ReactNode;

  onDelete(): void;

  onOpen(isOpen: boolean): void;
}

const DeleteMetricOrDashboardDialogView = (props: IDashboardDeleteDialogView) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    props.onOpen(false);
  }, [props]);

  const handleDelete = useCallback(() => {
    props.onDelete();
  }, [props]);

  return (
    <Dialog
      closable={false}
      open={props.open}
      onClose={handleClose}
      title={props.title}
      bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
      destroyOnClose
    >
      <div style={{ margin: 0 }}>{props.description}</div>
      <DialogActions>
        <Button onClick={handleClose} variant="default">
          {t("ui:button.cancel")}
        </Button>
        <Button type="primary" onClick={handleDelete} variant="filled">
          {t("ui:button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteMetricOrDashboardDialogView);
