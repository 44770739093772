import React from "react";
import { answerKeys, IQuestion } from "./PassingRegulationTest.interface";
import { Checkbox, Radio, RadioGroup, Text } from "../../../uiKit";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslation } from "react-i18next";

export const Question: React.FC<IQuestion> = (props) => {
  const { t } = useTranslation();
  const handleSelectSingleAnswer = (event: RadioChangeEvent) => {
    const answerId = Number((event.target as HTMLInputElement).value);
    props.onSelectAnswer(props.question.id!, answerId);
  };

  const handleSelectMultipleAnswer = (event: CheckboxChangeEvent, answerId: number) => {
    const answerValue = event.target.checked;
    props.onChangeAnswer(props.question.id!, answerId, answerValue);
  };

  const handleFindSingleRightAnswer = (): number | undefined =>
    props.question?.answers?.find((item) => item?.isRight)?.id ?? undefined;

  return (
    <div className="mb-1 ml-1">
      <h5 className="passing-regulation-test__question">{props.question.name}</h5>
      {props.answerKey === answerKeys.singleAnswer && (
        <RadioGroup
          className="passing-regulation-test__radio-group"
          value={handleFindSingleRightAnswer()}
          onChange={handleSelectSingleAnswer}
        >
          {props.question.answers?.map((answer) => {
            const result = props.questionAfterSend?.answers?.find((a) => a.id === answer.id);
            return (
              <div className="d-stack-column">
                <Radio key={answer.id} value={answer.id}>
                  <Text children={answer.name ?? ""} />
                </Radio>
                {result?.isRight && (
                  <Text
                    className="ml-6"
                    size="10px"
                    style={{ color: "var(--color-success-strong)" }}
                    children={t("ui:text.correct_answer")}
                  />
                )}
              </div>
            );
          })}
        </RadioGroup>
      )}
      {props.answerKey === answerKeys.multipleAnswer && (
        <div className="d-stack-column justify-start align-start spacing-2">
          {props.question.answers?.map((answer) => {
            const result = props.questionAfterSend?.answers?.find((a) => a.id === answer.id);
            return (
              <div className="d-stack-column">
                <div
                  className="d-stack-row justify-start align-center spacing-2"
                  key={answer.id}
                  style={{ marginLeft: "-8px !important" }}
                >
                  <Checkbox
                    className="passing-regulation-test__checkbox"
                    checked={answer.isRight}
                    onChange={(event) => handleSelectMultipleAnswer(event, answer.id!)}
                  />
                  <p className="passing-regulation-test__answer-label">{answer.name}</p>
                </div>
                {result?.isRight && (
                  <Text
                    className="ml-6"
                    size="10px"
                    style={{ color: "var(--color-success-strong)" }}
                    children={t("ui:text.correct_answer")}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
