import React, { useState } from "react";
import { IssueWithdrawDialogView } from "./IssueWithdrawDialogView";
import { IIssueWithdrawDialog } from "./IssueWithdrawDialogView.interface";

const IssueWithdrawDialogMemo = ({ open, onWithdrawIssue, onClose }: IIssueWithdrawDialog) => {
  const [executorId, setExecutorId] = useState<number | undefined>(undefined);
  const [deadline, setDeadline] = useState<Date | null>(null);

  const handleWithdraw = async () => {
    if (executorId) {
      onWithdrawIssue(executorId!, deadline);
      onCloseAndReset();
    }
  };

  const onCloseAndReset = () => {
    setExecutorId(undefined);
    setDeadline(null);
    onClose();
  };

  return (
    <IssueWithdrawDialogView
      setExecutorId={setExecutorId}
      executorId={executorId}
      deadline={deadline}
      setDeadline={setDeadline}
      handleWithdrawIssue={handleWithdraw}
      onClose={onCloseAndReset}
      open={open}
    />
  );
};

export const IssueWithdrawDialog = React.memo(IssueWithdrawDialogMemo);
