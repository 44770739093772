import React, { useMemo } from "react";
import { Tag } from "../tags/tag/Tag";
import { ITagLabel, TagLabelColor } from "./TagLabel.interface";
import "./TagLabel.scss";

export const TagLabel = ({ text, color, icon }: ITagLabel) => {

  const tagStyle = useMemo(() => {
    switch (color) {
      case TagLabelColor.primary:
        return {
          color: "var(--color-primary)",
          borderColor: "var(--color-primary-light)",
          background: "var(--color-primary-light2)",
        }
      case TagLabelColor.warning:
        return {
          color: "var(--color-warning)",
          borderColor: "var(--color-warning-light)",
          background: "var(--color-warning-light2)",
        }
      case TagLabelColor.success:
        return {
          color: "var(--color-success)",
          borderColor: "var(--color-success-light)",
          background: "var(--color-success-light2)",
        }
      case TagLabelColor.error:
        return {
          color: "var(--color-error)",
          borderColor: "var(--color-error-light)",
          background: "var(--color-error-light2)",
        }
      default:
        return {
          color: "var(--color-primary)",
          borderColor: "var(--color-primary-light)",
          background: "var(--color-primary-light2)",
        }
    }
  }, [color])

  return (
    <Tag
      className="tag-label"
      style={tagStyle}
    >
      {icon}
      { text }
    </Tag>
  )
};