import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { OrgchartDto } from "../../../api";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";
import { OrgchartSwitcherView } from "./OrgchartSwitcherView";
import { ISelect } from "../../uiKit/select/Select";
import { api } from "../../../services";

interface IOrgchartSwitcher {
  drawType?: "avatarsWithMenu" | "textWithMenu" | "sortTextWithMenu" | "select";
  value?: number[] | number;
  createEditDialogValue?: OrgchartDto;
  useAsFilter?: boolean;
  selectProps?: ISelect;
  preventDefault?: boolean;
  isViewUploadFile?: boolean;

  onSelectAllClick?: () => void;
  onChange?: (orgchartId: number | number[]) => void;
  onCreate?: (orgchartId: number) => void;
  onSave?: (orgchartId: number) => void;
  onCreateEditDialogClose?: () => void;
  onChangePosition?: (oldIndex: number, newIndex: number) => void;
}

interface IOrgchartSwitcherRef {
  openCreateDialog: () => void;
}

const Switcher = forwardRef<IOrgchartSwitcherRef, IOrgchartSwitcher>((props: IOrgchartSwitcher, ref) => {
  const { authStore, orgchartStore } = useRootStore();

  const [state, setState] = useState<OrgchartDto[]>([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);

  const handleChange = (orgchartId: number | number[]) => {
    props.onChange && props.onChange(orgchartId);
    if (props.useAsFilter || props.preventDefault) return;
    typeof orgchartId === "number" && orgchartStore.setCurrentOrgchartId(orgchartId);
  };

  const handleCreateSuccess = (orgchartId: number) => {
    if (props.createEditDialogValue != null) {
      props.onSave && props.onSave(orgchartId);
    } else {
      props.onCreate && props.onCreate(orgchartId);
    }
  };

  const handleChangePosition = useCallback(async (orgchartId: number, oldIndex: number, newIndex: number) => {
    // props.onChangePosition()
    setState((prev) => {
      const orgchartList = [...prev];
      const currentOrgchart = orgchartList[oldIndex];
      orgchartList.splice(oldIndex, 1);
      orgchartList.splice(newIndex, 0, currentOrgchart);
      return orgchartList;
    });
    await api.orgchart.changeOrgchartOrder(orgchartId, newIndex);
  }, []);


  useEffect(() => {
    setState(orgchartStore.getOrgchartsList ?? []);
  }, [orgchartStore.getOrgchartsList]);

  useImperativeHandle(ref, () => ({
    openCreateDialog: () => {
      setIsCreateDialogOpen(true);
    },
  }));

  return (
    <OrgchartSwitcherView
      currentOrgchartId={orgchartStore.getCurrentOrgchartId}
      state={state}
      drawType={props.drawType ?? "avatarsWithMenu"}
      value={props.value}
      isViewUploadFile={props.isViewUploadFile}
      selectProps={props.selectProps}
      createEditDialogValue={props.createEditDialogValue}
      useAsFilter={props.useAsFilter}
      onSelectAllClick={props.onSelectAllClick}
      isCreateDialogOpen={isCreateDialogOpen}
      onCreateSuccess={handleCreateSuccess}
      setIsCreateDialogOpen={setIsCreateDialogOpen}
      onChange={handleChange}
      onCreateEditDialogClose={props.onCreateEditDialogClose}
      onChangePosition={handleChangePosition}
    />
  );
});

export const OrgchartSwitcher = observer(Switcher);
