import React from "react";
import FileUploaderItemView from "./FileUploaderItemView";
import { TFileUploader } from "../FileUploader";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";

interface IFileUploaderItem {
  value: TFileUploader;
  readonly?: boolean;
  noDelete?: boolean;
  smallSize?: boolean;
  onItemClick: () => void;
  onDownloadClick: () => void;
  onDeleteClick: () => void;
}

function getFileType(name: string): "file" | "image" | "text" | "video" {
  let fileType: "file" | "image" | "text" | "video" = "file";
  if (name.match(/.(jpg|jpeg|png|gif)$/i)) {
    fileType = "image";
  }
  if (name.match(/.(doc|txt)$/i)) {
    fileType = "text";
  }
  if (name.match(/.(mp4|webm|avi|mov|wmv)$/i)) {
    fileType = "video";
  }
  return fileType;
}

function FileUploaderItem(props: IFileUploaderItem) {
  const { appStore } = useRootStore();

  const handleFileClick = (event: any) => {
    if (props.onItemClick != null) {
      props.onItemClick();
      return;
    }
    event.stopPropagation();
    if ((props.value.uploadedFile?.url ?? "").trim().length == 0) return;
    if (getFileType((props.value.uploadedFile?.url ?? "").trim()) == "image") {
      appStore.setImagesToViewLinks([
        { src: props.value.uploadedFile?.url ?? "", caption: props.value.file?.name ?? "" },
      ]);
    }
    if ((props.value.uploadedFile?.url ?? "").match(/.(pdf|doc|docx|ppt|pptx|xls|xlsx|txt|ods)$/i)) {
      appStore.setFilesToViewLinks([{ src: props.value.uploadedFile?.url ?? "", name: props.value.file?.name ?? "" }]);
    }
  };

  function openURL(target: string) {
    const win = window.open(target, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  const handleDeleteButtonClick = (event: any) => {
    event.stopPropagation();
    props.onDeleteClick();
  };

  const handleDownloadButtonClick = (event: any) => {
    event.stopPropagation();
    props.onDownloadClick();
  };

  return (
    <FileUploaderItemView
      value={props.value}
      onClick={handleFileClick}
      smallSize={props.smallSize}
      noDelete={props.noDelete}
      onDeleteClick={handleDeleteButtonClick}
      onDownloadClick={handleDownloadButtonClick}
      readonly={props.readonly}
    />
  );
}

export default observer(FileUploaderItem);
