import React from "react";
import { useTranslation } from "react-i18next";
import { IIntervalSettingsDialogProps } from "./IntervalSettingsDialog";
import { Alert, Button, DatePicker, Dialog, DialogActions, Input, InputNumber, Text } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import dayjs from "dayjs";

interface IIntervalSettingsDialogViewProps extends IIntervalSettingsDialogProps {
  isChangeWarningDialogOpen: boolean;

  checkIsSaveNotAvailable: () => boolean;
  setIsChangeWarningDialogOpen: (isOpen: boolean) => void;
  onSave: () => void;
}

const IntervalChangeWarningDialog = ({
  isOpen,
  onClose,
  onAccept,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose} title={t("parse:intervals.warning_dialog.title")} closable={false}>
      <Text>{t("parse:intervals.warning_dialog.text")}</Text>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button onClick={onAccept}>{t("ui:button.accept")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export const IntervalSettingsDialogView = (props: IIntervalSettingsDialogViewProps) => {
  const { t } = useTranslation();

  if (props.interval == null) return null;

  return (
    <>
      <IntervalChangeWarningDialog
        isOpen={props.isChangeWarningDialogOpen}
        onClose={() => props.setIsChangeWarningDialogOpen(false)}
        onAccept={() => {
          props.onSave();
          // props.setIsChangeWarningDialogOpen(false);
        }}
      />
      <Dialog open={props.isOpen} onClose={props.onClose} title={t("parse:intervals.dialog_title")} closable={false}>
        <div className="d-stack-column spacing-3">
          <div>
            <SubheaderText text={t("parse:intervals.name")} />
            <Input
              readOnly={props.isReadOnly}
              placeholder={t("parse:intervals.enter_value")}
              value={props.interval?.name ?? undefined}
              onInput={(event) => props.onChange({ ...props.interval, name: (event.target as HTMLInputElement).value })}
            />
          </div>
          <div>
            <SubheaderText text={t("parse:intervals.repeat_every")} />
            <div className="d-stack spacing-2">
              <div>
                <SubheaderText text={t("parse:intervals.day")} />
                <InputNumber
                  className="full-width"
                  readOnly={props.isReadOnly}
                  value={Number(props.interval?.day ?? 0)}
                  placeholder={t("parse:intervals.enter_value")}
                  min={0}
                  onChange={(value) =>
                    props.onChange({
                      ...props.interval,
                      day: Number(value),
                      week: 0,
                      month: 0,
                      year: 0,
                    })
                  }
                />
              </div>
              <div>
                <SubheaderText text={t("parse:intervals.week")} />
                <InputNumber
                  className="full-width"
                  readOnly={props.isReadOnly}
                  value={Number(props.interval?.week ?? 0)}
                  placeholder={t("parse:intervals.enter_value")}
                  min={0}
                  onChange={(value) =>
                    props.onChange({
                      ...props.interval,
                      week: Number(value),
                      day: 0,
                      month: 0,
                      year: 0,
                    })
                  }
                />
              </div>
              <div>
                <SubheaderText text={t("parse:intervals.month")} />
                <InputNumber
                  className="full-width"
                  readOnly={props.isReadOnly}
                  value={Number(props.interval?.month ?? 0)}
                  placeholder={t("parse:intervals.enter_value")}
                  min={0}
                  onChange={(value) =>
                    props.onChange({
                      ...props.interval,
                      month: Number(value),
                      day: 0,
                      week: 0,
                      year: 0,
                    })
                  }
                />
              </div>
              <div>
                <SubheaderText text={t("parse:intervals.year")} />
                <InputNumber
                  className="full-width"
                  readOnly={props.isReadOnly}
                  value={Number(props.interval?.year ?? 0)}
                  placeholder={t("parse:intervals.enter_value")}
                  min={0}
                  onChange={(value) =>
                    props.onChange({
                      ...props.interval,
                      year: Number(value),
                      day: 0,
                      week: 0,
                      month: 0,
                    })
                  }
                />
              </div>
            </div>
          </div>
          {props.interval?.day != 1 && (
            <div>
              <SubheaderText text={t("parse:intervals.date_start")} />
              <DatePicker
                readOnly={props.isReadOnly}
                allowClear={false}
                value={dayjs(props.interval?.dateStart as string)}
                size="large"
                onChange={(value) =>
                  props.onChange({ ...props.interval, dateStart: value == null ? undefined : value.toISOString() })
                }
              />
            </div>
          )}
          {props.checkIsSaveNotAvailable() && <Alert type="error" message={t("parse:interval_already_exists")} />}
        </div>
        <DialogActions>
          <Button onClick={props.onClose} variant="text">
            {t("ui:button.cancel")}
          </Button>
          {!props.isReadOnly && (
            <Button
              disabled={
                (props.interval.name ?? "").trim().length == 0 ||
                (props.interval.day == 0 &&
                  props.interval.week == 0 &&
                  props.interval.month == 0 &&
                  props.interval.year == 0) ||
                props.checkIsSaveNotAvailable()
              }
              loading={props.isLoading}
              onClick={props.onSave}
            >
              {t("ui:button.save")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
