import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CompanyShortDto } from "../../../../api";
import { Form } from "antd";
import { Button, Input, InputPassword, Text } from "../../../uiKit";

export interface ISignupFormView {
  step: number;
  companyData: CompanyShortDto;
  isLoading: boolean;
  setStep(value: number): void;
  handleCodeCheck({ code }: { code: string }): void;
  handleSignup({ code, email, password }: { code: string; email: string; password: string }): void;
}

function SignupFormView(props: ISignupFormView) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.step == 0) {
      if ((form.getFieldsValue()?.code ?? "").length > 0) {
        form.submit();
      }
    }
  }, [form]);

  return (
    <Form
      form={form}
      className="full-width"
      validateTrigger="onSubmit"
      requiredMark={false}
      autoComplete="on"
      layout="vertical"
      initialValues={{
        code: new URLSearchParams(window.location.search).get("code") ?? "",
        login: new URLSearchParams(window.location.search).get("email") ?? "",
      }}
      onFinish={(values) => {
        props.step == 0
          ? props.handleCodeCheck({ code: values.code })
          : props.handleSignup({ code: values.code, email: values.login, password: values.password });
      }}
    >
      <div className="mb-3">
        {props.step == 1 &&
          (props.companyData.name && props.companyData.name.trim().length > 0 ? (
            <Text>{t("text:signup.text2", { company: props.companyData.name })}</Text>
          ) : (
            <Text>{t("text:signup.text3")}</Text>
          ))}
      </div>
      <Form.Item
        name="code"
        label={t("ui:subheader.invite_code")}
        normalize={(value) => (value || "").toUpperCase().trim()}
        rules={[
          { required: true, message: t("validation:field_required") },
          { min: 8, max: 8, message: t("validation:min_char_number", { number: 8 }) },
        ]}
      >
        <Input
          disabled={props.step == 1}
          autoComplete="off"
          name="code"
          size="large"
          placeholder={t("ui:placeholder.invite_code")}
        />
      </Form.Item>
      {props.step == 1 && (
        <>
          <Form.Item
            name="login"
            label={t("ui:subheader.email")}
            rules={[
              { required: true, message: t("validation:field_required") },
              { type: "email", message: t("validation:wrong_email") },
            ]}
            normalize={(value) => value.trim()}
          >
            <Input name="login" size="large" placeholder={t("ui:placeholder.email")} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("ui:subheader.password")}
            rules={[
              { required: true, message: t("validation:field_required") },
              { min: 6, message: t("validation:min_char_number", { number: 6 }) },
            ]}
          >
            <InputPassword name="password" size="large" placeholder={t("ui:placeholder.password")} />
          </Form.Item>
          <Form.Item
            name="passwordCheck"
            label={t("ui:subheader.password_check")}
            rules={[
              { required: true, message: t("validation:field_required") },
              { min: 6, message: t("validation:min_char_number", { number: 6 }) },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("validation:passwords_should_match")));
                },
              }),
            ]}
            normalize={(value) => value.trim()}
          >
            <InputPassword name="passwordCheck" size="large" placeholder={t("ui:placeholder.password")} />
          </Form.Item>
        </>
      )}

      <Form.Item className="mt-6">
        <div className="d-flex">
          <Button size="large" variant="outlined" allowTextWrap onClick={() => navigate("/login")}>
            {t("ui:button.already_registered")}
          </Button>
          <Button
            className="ml-4 flex-grow-1"
            loading={props.isLoading}
            htmlType="submit"
            size="large"
            variant="filled"
          >
            {props.step == 0 ? t("ui:button.check_code") : t("ui:button.signup")}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default SignupFormView;
