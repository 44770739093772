import React from "react";
import enContent from "../../../../../utils/guides-lexical/policies/en.json";
import ruContent from "../../../../../utils/guides-lexical/policies/ru.json";
import { TextEditor as TextEditorLexical } from "../../../textEditor/TextEditor";
import { useRootStore } from "../../../../../hooks";

export const getContent = (localeKey: string | null): any | null => {
  switch (localeKey) {
    case "ru":
      return JSON.stringify(ruContent);
    case "en":
      return JSON.stringify(enContent);
    case "uk":
      return JSON.stringify(ruContent);
  }
};

export const PoliciesGuide = () => {
  const { hintStore } = useRootStore();

  return (
    // <TextEditorOld readOnly={true} id="guide-policy" onChange={() => {}} value={getContent(appStore.getAppLocale)} />
    // );
    <TextEditorLexical readOnly value={getContent(hintStore.getLang)} withoutBackground />
  );
};
