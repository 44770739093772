import { useEffect, useRef, useState } from "react";
import { TimezoneNotificationDialogView } from "./TimezoneNotificationDialogView";
import { useClaims, useLocalStorage, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { permissionKeys } from "../../../../utils/permissions";
import { api } from "../../../../services";
import { CompanyDto, UserDto } from "../../../../api";

interface ITimezoneNotificationDialogProps {
  //
}

export type TTimezoneNotificationDialogTimeZoneValue = {
  user?: string | null;
  company?: string | null;
};

const TimezoneNotificationDialogObserved = (props: ITimezoneNotificationDialogProps) => {
  const { authStore, helperStore } = useRootStore();
  const claims = useClaims();
  const localTimeZoneId = useRef<string>(Intl.DateTimeFormat().resolvedOptions().timeZone ?? "");
  const [isLoadingCanonical, setIsLoadingCanonical] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timeZoneValue, setTimeZoneValue] = useState<TTimezoneNotificationDialogTimeZoneValue>({
    user: null,
    company: authStore.getCurrentCompany?.timeZoneId ?? null,
  });

  const [skipTimeZone, setSkipTimeZone] = useLocalStorage<string | null | undefined>("skipTimeZone", '');

  const handleCloseModal = () => {
    setSkipTimeZone(timeZoneValue.user);
    setIsOpen(false);
  };

  const getCanonicalTimeZoneBasedOnLocalTZ = async () => {
    // if (helperStore.getTimezones.length == 0 || timeZoneValue.user != null || isLoadingCanonical) return;
    const tz = helperStore.getTimezones.find((tz) => tz.id == localTimeZoneId.current)?.id;
    if (tz != null) {
      return setTimeZoneValue((prev) => ({
        ...prev,
        user: tz ?? null,
      }));
    }
    setIsLoadingCanonical(true);
    const r = await api.helper.timezoneCanonical(localTimeZoneId.current);
    setTimeZoneValue((prev) => ({
      ...prev,
      user: r?.timeZoneId ?? null,
    }));
    setIsLoadingCanonical(false);
    if (skipTimeZone !== r?.timeZoneId) {
      setIsOpen(r?.timeZoneId !== authStore.getInitialInfo?.identity?.timeZoneId);
    }
  };

  const handleSave = async () => {
    if (authStore.getInitialInfo?.identity == null) return;
    setIsLoading(true);
    if (authStore.getInitialInfo?.identity?.timeZoneId != timeZoneValue.user) {
      const userData: UserDto = { ...authStore.getInitialInfo?.identity };
      await api.user.editPartially(
        authStore.getInitialInfo?.identity?.id!,
        {
          ...userData,
          timeZoneId: timeZoneValue.user,
        },
        userData
      );
    }
    setIsOpen(false);
    setIsLoading(false);
    await authStore.refreshInitialInfo();
  };

  useEffect(() => {
    getCanonicalTimeZoneBasedOnLocalTZ();
  }, [helperStore.getTimezones]);

  // useEffect(() => {
  //   // console.log("helperStore.getTimezones", helperStore.getTimezones);
  //   if (
  //     authStore.getInitialInfo?.identity?.timeZoneId == null
  //     // (claims.has(permissionKeys.company.edit.timeZone) && authStore.getCurrentCompany?.timeZoneId == null)
  //   ) {
  //     setIsOpen(true);
  //   }
  // }, []);


  return isOpen ? (
    <TimezoneNotificationDialogView
      isOpen={isOpen}
      timeZoneValue={timeZoneValue}
      isLoading={isLoading}
      isLoadingCanonical={isLoadingCanonical}
      timeZones={helperStore.getTimezones}
      isSetTimeZone={authStore.getInitialInfo?.identity?.timeZoneId == null}
      onTimeZoneValueChange={(value) => setTimeZoneValue((prev) => ({ ...prev, ...value }))}
      onSave={handleSave}
      handleCloseModal={handleCloseModal}
    />
  ) : null;
};

export const TimezoneNotificationDialog = observer(TimezoneNotificationDialogObserved);
