import React, { useCallback, useEffect, useState, memo, useMemo } from "react";
import "./RegulationComments.scss";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { IRegulationHistory } from "./RegulationHistory.interface";
import { historySortKeys, historySortKeysType } from "../../misc/consts";
import { RegulationHistoryHeader } from "./RegulationHistoryHeader";
import { HistoryListCards } from "../../../../../uiKit/History/HistoryListCards";
import { Card } from "../../../../../uiKit";
import { HistoryDto } from "../../../../../../api";
import { RegulationHistorySendBlock } from "./RegulationHistorySendBlock";
import { useTranslation } from "react-i18next";

export const RegulationHistory = memo((props: IRegulationHistory) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const notifier = useNotifier();

  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);


  const [isLoadNextPage, setIsLoadNextPage] = useState<boolean>(false);

  const [sortValue, setSortValue] = useState<historySortKeysType>(historySortKeys.onesFirst);

  const regulationHistory = usePagingWithController(
    api.regulationHistory,
    {
      // @ts-ignore
      regulationId: props.regulationId,
      pageSize: 10,
      orderBy: "date_created",
      order: sortValue === historySortKeys.onesFirst ? "desc" : "asc",
    },
    undefined
  );

  const handleLoadNextPage = useCallback(() => {
    setIsLoadNextPage(true); // костыль собачий, который решает проблему сортировки, почему то параметры, которые я передаю в хук, они динамически не изменяются, и хук использует первоначальные параметры, хотя они изменяются !!!!!
  }, []);


  const handleChangeSortValue = useCallback((newValue: historySortKeysType) => setSortValue(newValue), []);


  useEffect(() => {
    if (isLoadNextPage) {
      regulationHistory.loadNext();
      setIsLoadNextPage(false);
    }
  }, [isLoadNextPage]);

  useEffect(() => {
    regulationHistory.restart(true);
  }, [sortValue]);

  const handleUpdateComment = useCallback(async (id: number, data: HistoryDto) => {
    const response = await api.regulationHistory.edit(id, data);
    console.log("response", response);
    if (response) {
      regulationHistory.updateItem(id, data);
    }
    return !!response;
  }, [regulationHistory.items]);

  const list = useMemo(() => {
    return regulationHistory.items;
  }, [regulationHistory.items]);


  const handleRemoveComment = useCallback(async (id: number) => {
    const response = await api.regulationHistory.del(id);
    if (response) {
      notifier.show({
        message: t("notifier:success.deleted"),
        theme: "success",
      });
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
    regulationHistory.restart();
    return !!response;
  }, []);

  useEffect(() => {
    regulationHistory.reset();
    regulationHistory.restart(true);
  }, [props.regulationId]);

  return (
    <Card className="regulation-history__wrapper" isShadowed={false}>
      <div className="regulation-history-header">
        {!!list.length && (
          <RegulationHistoryHeader
            sortValue={sortValue}
            onChangeSortValue={handleChangeSortValue}
          />
        )}
      </div>
      <RegulationHistorySendBlock
        user={user}
        regulationId={props.regulationId}
        regulationHistory={regulationHistory}
        regulationContentId={props.regulationContentId}
      />

      <HistoryListCards
        historyItems={list}
        isDone={regulationHistory.info.isDone}
        loadNext={handleLoadNextPage}
        handleUpdateComment={handleUpdateComment}
        handleRemoveComment={handleRemoveComment}
        reactionsStrategyController={api.regulationHistory}
      />
    </Card>
  );
});
