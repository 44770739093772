import React from "react";
import { IBoardCollapseView } from "./BoardCollapse.interface";
import { Collapse, CollapsePanel, Empty } from "../../../../../uiKit";
import { mapToArrNumbers } from "./helpers";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import clsx from "clsx";
import { OrgchartCard } from "../../../../../elements/orgchartCard/OrgchartCard";
import { DragAndDropContainer } from "./DragAndDropContainer";
import { CounterChip } from "../../../../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../../../../elements/counterChip/misc/keys";
import { theme } from "antd";
import { useTranslation } from "react-i18next";

export const BoardCollapseView = (props: IBoardCollapseView) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { t } = useTranslation();

  if (!props.boardsByWithStats?.length) {
    return <Empty description={t("ui:title.no_tasks")} />;
  }


  return (
    <Collapse
      bordered={false}
      className="board-collapse"
      activeKey={props.openBoardIds.map((item) => item.toString())}
      onChange={(keys) => {
        props.onChangeOpenBoards(mapToArrNumbers(keys));
      }}
      style={{ background: token.colorBgContainerDisabled }}
    >
      {props.boardsByWithStats?.map((item) => (
        <CollapsePanel
          key={item.id?.toString() ?? ""}
          className="issue-board_collapse"
          header={<OrgchartCard avatarSize={25} fontSize={14} orgchart={item} />}
          showArrow={false}
          style={{ width: "100%" }}
          extra={
            <div className="d-stack-row align-center justify-space-between">
              <CounterChip colorMode={ColorModeKeys.faint} count={item.pendingIssuesCount} />
              <CounterChip className="ml-2" colorMode={ColorModeKeys.primary} count={item.inWorkIssuesCount} />
              <div
                className={clsx("ml-4", { "rotated-n180": props.openBoardIds.includes(item.id ?? 0) })}
                style={{ transition: "all 0.3s" }}
              >
                <IoIosArrowDown fontSize={18} color="var(--color-text-weak)" />
              </div>
            </div>
          }
        >
          <DragAndDropContainer
            onChangeBoardColumnsState={props.onChangeBoardColumnsState}
            board={props.board}
            orgchartId={!props.isGroupingByTags && !props.isGroupingByUsers ? item.id ?? 0 : undefined}
            tagId={props.isGroupingByTags && !props.isGroupingByUsers ? item.id ?? 0 : undefined}
            userId={props.isGroupingByUsers ? item.id ?? 0 : undefined}
            isGroupingByTags={props.isGroupingByTags}
            isGroupingByUsers={props.isGroupingByUsers}
            openBoardIds={props.openBoardIds}
          />
        </CollapsePanel>
      ))}
    </Collapse>
  );
};
