import React, { useEffect, useMemo } from "react";
import { Button, Checkbox, DatePicker, Dialog, DialogActions, Input, Select, TimeSpanPicker } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import dayjs from "dayjs";
import { CalendarEventTimeBlockDto } from "../../../../api/models/CalendarEventTimeBlockDto";
import { TimeBlockSubmitFormDto } from "../../../../api/models/TimeBlockSubmitFormDto";
import { useRootStore } from "../../../../hooks";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { DropdownMenu } from "../../../service/dropdownMenu/dropdownMenu";
import "./CalendarTimeBlock.scss";
import { blockedFieldsKeys, IssueActionConsts } from "../../forms/types/consts";
import { TextEditor } from "../../textEditor/TextEditor";
import { convertStringToLexicalValue, initialEditorContent } from "../../../../utils/textEditor";
import { ToolbarType } from "../../textEditor/types/types";
import uniqid from "uniqid";

export interface ICalendarTimeBlockView {
  eventTimeBlock: CalendarEventTimeBlockDto;
  handleClose(): void;
  handleChange: (data: TimeBlockSubmitFormDto) => void;
  handleSubmitForm: () => void;
  popupItems: any;
  isLoadingTimeBlock?: boolean;
}

export const CalendarTimeBlockView = (props: ICalendarTimeBlockView) => {
  const { eventTimeBlock, handleChange, handleClose, handleSubmitForm, popupItems, isLoadingTimeBlock } = props;

  const { authStore } = useRootStore();


  const { t } = useTranslation();
  const [form] = Form.useForm();


  const handleSubmit = () => {
    form.submit();
  };

  const disabledHours = useMemo(() => {
    if (eventTimeBlock?.dateWorkStart) {
      const currentHour = dayjs(eventTimeBlock?.dateWorkStart).hour();
      const currentMinute = dayjs(eventTimeBlock?.dateWorkStart).minute();
      const hoursLessThanCurrent = Array.from({ length: currentHour }, (v, k) => k);
      if (currentMinute === 59) {
        hoursLessThanCurrent.push(currentHour);
      }
      return hoursLessThanCurrent;
    }
    return [];
  }, [eventTimeBlock?.dateWorkStart])

  const disabledMinutes = (selectedHour: number) => {
    const currentHour = dayjs(eventTimeBlock?.dateWorkStart).hour();
    const currentMinute = dayjs(eventTimeBlock?.dateWorkStart).minute();
    if (selectedHour === currentHour) {
      return Array.from({ length: currentMinute + 1 }, (v, k) => k);
    }
    return [];
  };

  const handleChangeFullDay = (e: CheckboxChangeEvent) => {
    handleChange({ value: e.target.checked, key: 'isFullDay' });
  }

  const popupActionsItems = (popupItems ?? [])
    .map((item: any) => ({
      ...item,
      action: () => item.action(eventTimeBlock?.id),
    }));

  const descriptionValue = useMemo(() => {
    const value = convertStringToLexicalValue(eventTimeBlock?.description ?? '');
    return value
  }, [eventTimeBlock?.description])

  return (
    <Dialog
      title={eventTimeBlock?.recurringIssueId ? t("ui:title.recurring_issue") : t("ui:title.time_block")}
      open={true}
      onClose={handleClose}
    >
      {eventTimeBlock?.id && popupActionsItems.length ? (
        <div className="calendar-time-block-action">
          <DropdownMenu
            items={popupActionsItems}
          />
        </div>
      ) : null }
      <Form
        form={form}
        autoComplete="off"
        onFinish={handleSubmitForm}
      >
        <div className="form-row">
          <SubheaderText text={t("ui:subheader.time_block.name")} />
          <Form.Item
            name="name"
            rules={[
              { required: true, message: t("validation:field_required") },
            ]}
            initialValue={eventTimeBlock?.name}
          >
            <Input
              value={eventTimeBlock?.name || undefined}
              autoComplete="off"
              placeholder={t("ui:placeholder.time_block.name")}
              disabled={eventTimeBlock?.isReadOnly}
              onChange={(e) => handleChange({ value: e.target.value, key: 'name' })}
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <SubheaderText text={t("ui:subheader.time_block.description")} />
          <Form.Item
            name="description"
            initialValue={descriptionValue}
          >
            <TextEditor
              id={uniqid()}
              value={descriptionValue || undefined}
              initialValue={descriptionValue || undefined}
              onChange={(value) => handleChange({ value, key: 'description' })}
              placeholder={t("ui:placeholder.issue_description")}
              disabled={eventTimeBlock?.isReadOnly}
              toolbarType={ToolbarType.Dynamic}
              isSimplifiedToolbar
              variant="bordered"
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <SubheaderText text={t("ui:subheader.time_block.date")} />
          <DatePicker
            className="full-width"
            value={dayjs(eventTimeBlock?.dateWorkStart) ?? undefined}
            placeholder={t("ui:placeholder.time_block.selectDate")}
            onChange={(value) => handleChange({ value, key: 'dateWorkStart' })}
            disabled={eventTimeBlock?.isReadOnly}
            allowClear={false}
            size="large"
            disablePast
          />
        </div>
        <div className="form-row">
          <div className="d-stack-row justify-space-between align-start">
            <div style={{ width: "48%" }}>
              <SubheaderText text={t("ui:subheader.time_block.startTime")} />
              <Form.Item
                name="startTime"
                rules={[{ required: true, message: t("validation:field_required") }]}
                initialValue={dayjs(eventTimeBlock?.dateWorkStart)
                  .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
                  .format("HH:mm:ss") ?? null
                }
              >

                <TimeSpanPicker
                  disabled={eventTimeBlock?.isFullDay || eventTimeBlock?.isReadOnly}
                  size="large"
                  className="full-width"
                  placeholder={t("ui:placeholder.time_block.selectTime")}
                  value={dayjs(eventTimeBlock?.dateWorkStart)
                    .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
                    .format("HH:mm:ss") ?? null}
                  onChange={(value) => {
                    const [hour = 0, minute = 0, second = 0] = value?.split(":") ?? [];
                    const date = dayjs(eventTimeBlock?.dateWorkStart).set({ hour, minute, second });
                    const diff = date.diff(dayjs(eventTimeBlock?.dateWorkStart), "minute");
                    //
                    const dateEnd = dayjs(eventTimeBlock?.dateWorkEnd).add(diff, "minute") ;
                    form.setFieldValue("endTime", dateEnd.format("HH:mm:ss"));
                    handleChange({ value: date, key: 'dateWorkStart' });
                    handleChange({ value: dateEnd, key: 'dateWorkEnd' });
                  }}
                />
              </Form.Item>
            </div>
            <div style={{ width: "48%" }}>
              <SubheaderText text={t("ui:subheader.time_block.endTime")} />
              <Form.Item
                name="endTime"
                dependencies={['startTime']}
                initialValue={dayjs(eventTimeBlock?.dateWorkEnd)
                  .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
                  .format("HH:mm:ss") ?? null}
                rules={[
                  { required: true, message: t("validation:field_required") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const { dateWorkEnd, dateWorkStart } = eventTimeBlock;
                      if (dayjs(dateWorkStart).toDate() < dayjs(dateWorkEnd).toDate()) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(''));
                    },
                  }),
                ]}
              >
                <TimeSpanPicker
                  size="large"
                  className="full-width"
                  disabled={eventTimeBlock?.isFullDay || eventTimeBlock?.isReadOnly}
                  disabledHours={() => disabledHours}
                  disabledMinutes={disabledMinutes}
                  placeholder={t("ui:placeholder.time_block.selectTime")}
                  value={dayjs(eventTimeBlock?.dateWorkEnd)
                    .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
                    .format("HH:mm:ss") ?? null}
                  onChange={(value) => {
                    const [hour = 0, minute = 0, second = 0] = value?.split(":") ?? [];
                    const date = dayjs(eventTimeBlock?.dateWorkEnd).set({ hour, minute, second });
                    handleChange({ value: date, key: 'dateWorkEnd' });
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        { !eventTimeBlock?.isReadOnly ? (
          <div className="form-row">
            <Checkbox
              checked={eventTimeBlock?.isFullDay ?? false}
              onChange={handleChangeFullDay}
            >
              {t("ui:checkbox.full_day")}
            </Checkbox>
          </div>
        ) : null }
      </Form>
      { !eventTimeBlock?.isReadOnly ? (
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="text"
          >
            {t("ui:button.cancel")}
          </Button>
          <Button
            loading={isLoadingTimeBlock}
            onClick={handleSubmit}
            type="primary"
            variant="text"
          >
            {t("ui:button.save")}
          </Button>
        </DialogActions>
      ) : null }
    </Dialog>
  )
};