import React from "react";
import { useTranslation } from "react-i18next";

export const NoDataBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="my-6" style={{ color: "var(--color-gray-base)", textAlign: "center", minHeight: "210px" }}>
      {t("text:no_data")}
    </div>
  );
};
