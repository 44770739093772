import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import "./RegulationSidebar.scss";
import { DataNode } from "antd/es/tree";
import { IRegulationSidebarProps } from "./RegulationSidebar";
import { ResizableBox } from "react-resizable";
import { Button, Empty, Icon, IconButton, Spin, Tooltip, Tree } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { SidebarTreeControls } from "../sidebarTreeControls/SidebarTreeControls";
import { ArrowDownIcon } from "../../../../../../constants/icon";
import { RegulationSidebarTreeItem } from "./regulationSidebarTreeItem/RegulationSidebarTreeItem";
import { RegulationTreeItemNode, RegulationTreeNode } from "../../../../../../api/models/RegulationTreeDto";
import { useTranslation } from "react-i18next";
import { allowsRegulationKeys } from "../../misc/consts";
import { defineAllow } from "../../containers/regulation/helpers";
import { UserShortDto } from "../../../../../../api";
import { TreeProps } from "antd";
import { use } from "i18next";

interface IRegulationSidebarViewProps extends IRegulationSidebarProps {
  isLoading?: boolean;
  items: RegulationTreeNode[];
  selectedKeys: string[];
  expandedKeys: string[];
  loadedKeys: string[];

  onOpenAccessControlDialog: (id: number, createdByUser?: UserShortDto) => void;
  onOpenDeletingTopicDialog: (id: number, isRegulation: boolean, navigationItemId: number, hasItems?: boolean, parentName?: string, parentId?: number) => void;
  onExpand: (expandedKeys: string[]) => void;
  // onSelect: (selectedKey: string) => void;
  onSelect: (id: number, parentId: number, isRegulation: boolean, selectedKey: React.Key) => void;
  onLoadData: (data: RegulationTreeNode) => Promise<any>;
  onDrop:(data: any) => void;
  onCreateTopic: () => void;
}

const RegulationSidebarViewMemo = (props: IRegulationSidebarViewProps) => {
  const { t } = useTranslation();
  // console.log("loadedKeys");
  const handleLoadData = useCallback(async (item) => {
    return await props.onLoadData(item);
  }, []);

  const regulationSidebarContent = useRef<HTMLDivElement>(null);

  const [heightTree, setHeightTree] = useState<number | undefined>(undefined);

  useEffect(() => {
    // @ts-ignore
    setHeightTree(regulationSidebarContent.current?.clientHeight)
    // @ts-ignore
  }, [ regulationSidebarContent.current?.clientHeight]);

  const handleDragOver: TreeProps['onDragOver'] = useCallback((info) => {
    const { event } = info;
    const treeContainer = regulationSidebarContent.current?.querySelector('.ant-tree-list-holder');
    if (!treeContainer) return;

    const { clientY } = event;
    const { top, bottom } = treeContainer.getBoundingClientRect();
    const scrollSpeed = 20;
    if (clientY < top + 70) {
      treeContainer.scrollTop -= scrollSpeed;
    }
    if (clientY > bottom - 70) {
      treeContainer.scrollTop += scrollSpeed;
    }
  }, []);

  return (
    <ResizableBox
      width={props.sidebarSize.width}
      className="regulation-sidebar__resizer"
      axis="x"
      resizeHandles={["e"]}
      maxConstraints={[Math.round(document.body.clientWidth / 2 - 250) || 520, undefined as any]}
      minConstraints={[260, undefined as any]}
      // onResize={(e, { size }) => props.onSidebarSizeChange({ width: size.width })}
      onResizeStart={() => props.onSidebarResizing(true)}
      onResizeStop={(e, { size }) => {
        props.onSidebarSizeChange({ width: size.width });
        props.onSidebarResizing(false);
      }}
    >
      <div className="regulation-sidebar__wrapper full-height d-stack-column pa-4">
        <div className="regulation-sidebar-header">
            <Button
              variant="filled"
              theme="success"
              onClick={props.onCreateTopic}
            >
              { t("ui:button.create_section") }
            </Button>
          <IconButton
            className="flex-shrink-0"
            onClick={props.onSidebarOpenChange}
            children={<Icon component={() => <FiSidebar />} />}
          />
        </div>
        <div
          className="regulation-sidebar__content"
          ref={regulationSidebarContent}
        >
          {props.isLoading ? (
            <div className="full-height full-width d-flex align-center justify-center">
              <Spin spinning={true} size="large" />
            </div>
          ) : props.items.length == 0 ? (
            <Empty />
          ) : (
            <Tree
              // showLine
              draggable={{
                icon: false,
                // @ts-ignore
                nodeDraggable: (node: RegulationTreeItemNode) => node.draggable
              }}
              height={heightTree}
              multiple
              treeData={props.items}
              defaultExpandedKeys={props.expandedKeys}
              selectedKeys={props.selectedKeys}
              expandedKeys={props.expandedKeys}
              onDragOver={handleDragOver}
              blockNode
              titleRender={(titleNode) => {
                const currentNode = titleNode as RegulationTreeItemNode;
                return (
                   <RegulationSidebarTreeItem
                    title={currentNode.title}
                    id={currentNode.id}
                    navigationItemId={currentNode?.navigationItem?.id ?? 0}
                    isDraft={currentNode?.navigationItem?.regulation?.isDraft ?? false}
                    parentId={currentNode.navigationItem?.parentId ?? 0}
                    createdByUser={currentNode.navigationItem?.createdByUser}
                    isLeaf={currentNode.isLeaf}
                    key={currentNode.key}
                    icon={currentNode.icon}
                    itemKey={currentNode.key}
                    parentName={currentNode.parentName}
                    actions={currentNode.actions ?? []}
                    onOpenAccessControlDialog={props.onOpenAccessControlDialog}
                    onOpenDeletingTopicDialog={props.onOpenDeletingTopicDialog}
                    onSelect={props.onSelect}
                    hasItems={currentNode.hasItems}
                    isShowSettings={currentNode.draggable}
                  />

                );
              }}
              switcherIcon={(
                <Icon
                  className="tree-switcher-icon"
                  style={{ verticalAlign: "middle" }}
                  component={() => <ArrowDownIcon size={16} />}
                />
              )}
              virtual={true}
              rootStyle={{ overflow: "hidden" }}
              onExpand={(_, info) => {
                props.onExpand([info.node.key] as string[]);
              }}
              onSelect={
                (_, info) => {
                  // @ts-ignore
                  const { parentId = 0, regulationId, id,  withoutATopic } = info.node.navigationItem;

                  if (!withoutATopic) {
                    const isRegulation = !!regulationId;
                    const itemId = id || regulationId;
                    props.onSelect(itemId, parentId, isRegulation, info.node.key);
                  }
                }}
              onDrop={props.onDrop}
              loadData={handleLoadData}
              loadedKeys={props.loadedKeys}
            />
          )}
        </div>
      </div>
    </ResizableBox>
  );
};

export const RegulationSidebarView = memo(RegulationSidebarViewMemo);
