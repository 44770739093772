import React, { useMemo, useState, MouseEvent } from "react";
import { ArrowDownIcon, GoogleCalendarIcon, SettingsIcon } from "../../../constants/icon";
import "./GoogleCalendarBanner.scss";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import {
  GoogleCalendarSettingsModal
} from "../../modules/dialogs/GoogleCalendarSettingsModal/GoogleCalendarSettingsModal";

import { GoogleAuthConnect } from "../../modules/pages/settings/googleCalendarAuth/GoogleAuthConnect";

export const GoogleCalendarBanner = observer(() => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const [isShowContent, setIsShowContent] = useState<boolean>(false);

  const isConnectGoogleCalendar = useMemo(() => {
    return authStore.getGoogleCalendarAccount?.hasToken ?? false
  }, [authStore.getGoogleCalendarAccount]);


  const handleChangeShowContent = () => {
    setIsShowContent((prev) => !prev);
  };

  const [isShowGoogleCalendarSettings, setIsShowGoogleCalendarSettings] = useState<boolean>(false);

  const openGoogleCalendarSettings = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShowGoogleCalendarSettings(true);
  };

  const closeGoogleCalendarSettings = () => {
    setIsShowGoogleCalendarSettings(false);
  };


  return (
    <>
      {isShowGoogleCalendarSettings && (
        <GoogleCalendarSettingsModal
          open={isShowGoogleCalendarSettings}
          closeModal={closeGoogleCalendarSettings}
          isHiddenShowEventsSwitch={true}
        />
      )}

      <div
        className={`google-calendar-banner ${
          isConnectGoogleCalendar ? "google-calendar-banner_connect" : ""
        }`}
      >
        <div
          className={`google-calendar-banner-header ${
            isConnectGoogleCalendar ? "google-calendar-banner-header_connect" : ""
          }`}
          onClick={handleChangeShowContent}
        >
          <div className="google-calendar-banner-header__icon">
            <GoogleCalendarIcon />
          </div>
          <div className="google-calendar-banner-header__text">
            Google Calendar
          </div>
          {isConnectGoogleCalendar && (
            <div className="google-calendar-banner-header__settings">
              <a
                href={""}
                onClick={openGoogleCalendarSettings}
              >
                <SettingsIcon />
              </a>
            </div>
          )}
          <div
            className={`google-calendar-banner-header__arrow ${
              !isShowContent ? "google-calendar-banner-header__arrow_close" : ""
            }`}
          >
            <ArrowDownIcon />
          </div>
        </div>
        {isConnectGoogleCalendar ? (
          <div className="google-calendar-banner-content">
            <div
              className="google-calendar-banner-content__text google-calendar-banner-content__text_success">
              { t("ui:google_calendar_banner.text_connect") }
            </div>
            {isShowContent && (
              <div className="google-calendar-banner-content__toggle-block">
                <GoogleAuthConnect
                  isHiddenDisconnectButton={true}
                  isSmallSwitch={true}
                />
              </div>
            )}
          </div>
        ) : isShowContent && (
          <div className="google-calendar-banner-content">
            <div
              className="google-calendar-banner-content__text"
            >
              { t("ui:google_calendar_banner.text") }
            </div>

              <div className="google-calendar-banner-content__link">
                <a
                  href={""}
                  onClick={openGoogleCalendarSettings}
                >
                  {t("ui:google_calendar_banner.link")}
                </a>
              </div>

          </div>
          )
        }
      </div>
    </>
  )
});