import React, { useEffect, useState } from "react";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { api } from "../../../../services";
import { IAddCompanyToGroupDialog } from "./AddCompanyToGroupDialog.interface";
import { AddCompanyToGroupViewDialog } from "./AddCompanyToGroupViewDialog";

export const AddCompanyToGroupDialog = (props: IAddCompanyToGroupDialog) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<number>();

  const handleError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const clean = () => setCompanyId(undefined);

  const handleChangeCompany = (id: number) => setCompanyId(id);

  const handleAddCompany = async () => {
    setIsLoading(true);
    api.companyGroup
      .addCompanyToGroup(props.groupId, Number(companyId!))
      .then((res) => {
        if (res) {
          notifier.show({ message: t("notifier:success.add_company"), theme: "success" });
          props.onLoadGroupCompanyId();
          props.onClose();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    return clean;
  }, []);

  return (
    <AddCompanyToGroupViewDialog
      open={props.open}
      isLoading={isLoading}
      onClose={props.onClose}
      onAddCompany={handleAddCompany}
      onChangeCompany={handleChangeCompany}
      companyId={companyId}
    />
  );
};
