import React, { useEffect, useMemo, useState } from "react";
import { IMainRegulation } from "./Pages.interface";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useNotifier, useRootStore } from "../../../../../hooks";
import { useRouterHandler } from "../../../../pages/regulation/hooks/useRouterHandler";
import { cardKeys, cardKeysType, POLICIES_TAB_KEYS, tabKeysType } from "../misc/consts";
import { CommonListCards } from "../components/ListCards/CommonListCards";
import { useTranslation } from "react-i18next";
import { paginationTypeKeys } from "../components/ListCards/ListCards.interface";
import { ListRegulations } from "../components/ListCards/ListRegulations";
import { ListSections } from "../components/ListCards/ListSections";
import { tab } from "@testing-library/user-event/dist/tab";

const MainRegulationContentObserver = ({ regulationContentType }: IMainRegulation) => {
  const { regulationStore } = useRootStore();
  const { getTabKeys } = useRouterHandler();
  const { pathname } = useLocation();
  //const navigate = useNavigate();
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [cardKey, setCardKey] = useState<cardKeysType>();
  const [tabKey, setTabKey] = useState<tabKeysType>();

  const handleError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const switchTabHandler = () => {
    if (tabKey) {
      regulationStore.resetRegulationAndSection();
      regulationStore.setRegulationsFilters({ tab: tabKey });
      regulationStore.initialFetchRegulations();
    }
  };

  useEffect(() => {
    regulationStore.setOnError(handleError);
    regulationStore.setParentId(-1);
    return () => {
      regulationStore.resetRegulationAndSection();
    }
  }, []);

  useEffect(() => {
    switchTabHandler();
  }, [tabKey]);

  useEffect(() => {
    const currentTabKeys = getTabKeys();
    if (currentTabKeys) {
      setCardKey(currentTabKeys?.cardKey ?? undefined);
      setTabKey(currentTabKeys?.tabKey ?? undefined);
    }
  }, [pathname]);

  const navigationMenuItemList = useMemo(() => {
    return regulationStore.getRegulations;
  }, [regulationStore.getRegulations]);

  useEffect(() => {
    regulationStore.setParentId(-1);
  }, []);

  return (
    <>
      <ListRegulations
        isShowFavorites={cardKey === cardKeys.regulation}
        isLoading={regulationStore.getLoading}
        paginationType={paginationTypeKeys.byScroll}
        regulationContentType={regulationContentType}
        loadNext={regulationStore.fetchNextPageRegulations.bind(regulationStore)}
        regulations={navigationMenuItemList}
        isDone={regulationStore.getRegulationPagingOptions.pageIsLast}
      />
    </>
  );
};

export const MainRegulationContent = observer(MainRegulationContentObserver);
