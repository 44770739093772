import React, { useState } from "react";
import CompaniesManageFormView from "./CompaniesManageFormView";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { User2CompanyDto } from "../../../../api";

export interface ICompaniesManageForm {
  //
}

function CompaniesManageForm(props: ICompaniesManageForm) {
  const { authStore } = useRootStore();
  const [isAddCompanyDialogOpen, setIsAddCompanyDialogOpen] = useState<boolean>(
    (authStore.getInviteCode != null && authStore.getInviteCode.length > 0) ?? false
  );
  const [isLeaveCompanyDialogOpen, setIsLeaveCompanyDialogOpen] = useState<boolean>(false);
  const [companyToLeaveData, setCompanyToLeaveData] = useState<User2CompanyDto | null>(null);

  const handleAddCompanyDialogOpen = () => {
    setIsAddCompanyDialogOpen(true);
  };

  const handleAddCompanyDialogClose = () => {
    setIsAddCompanyDialogOpen(false);
    authStore.setInviteCode(null);
  };

  const handleLeaveCompanyDialogOpen = () => {
    setIsLeaveCompanyDialogOpen(true);
  };

  const handleLeaveCompanyDialogClose = () => {
    setIsLeaveCompanyDialogOpen(false);
  };

  const handleRefresh = async () => {
    await authStore.refreshInitialInfo();
  };

  const handleReauthorize = async () => {
    // await authStore.authorizeWithRefreshToken();
    window.location.reload();
  };

  return (
    <CompaniesManageFormView
      companies={authStore.getInitialInfo?.identity?.companies ?? []}
      isAddCompanyDialogOpen={isAddCompanyDialogOpen}
      currentCompanyId={authStore.getInitialInfo?.identity?.currentCompanyId ?? null}
      handleAddCompanyDialogOpen={handleAddCompanyDialogOpen}
      handleAddCompanyDialogClose={handleAddCompanyDialogClose}
      handleRefresh={handleRefresh}
      handleReauthorize={handleReauthorize}
      isLeaveCompanyDialogOpen={isLeaveCompanyDialogOpen}
      handleLeaveCompanyDialogOpen={handleLeaveCompanyDialogOpen}
      handleLeaveCompanyDialogClose={handleLeaveCompanyDialogClose}
      leaveCompanyData={companyToLeaveData ?? {}}
      setCompanyToLeaveData={setCompanyToLeaveData}
    />
  );
}

export default observer(CompaniesManageForm);
