import React, { memo, useCallback } from "react";
import { getColor } from "../../../../../../../utils/hex2rgb";
import { Tag } from "../../../../../../uiKit";
import { ITagItem } from "./ITagItem";

export const TagItem = memo((props: ITagItem) => {
  const handleSelectTags = useCallback(() => {
    props.onSelectTag(props.tag.id);
  }, []);

  return (
    <Tag
      key={props.tag.id}
      // closable
      className="d-flex align-center justify-center"
      style={{
        height: "24px",
        borderColor: getColor(props.tag?.color, props.isSelected ? 1 : 0.4),
        background: getColor(props.tag?.color, props.isSelected ? 1 : 0.05),
        color: props.isSelected ? "white" : getColor(props.tag?.color),
      }}
      color={props.tag?.color}
      bordered={false}
      onClick={handleSelectTags}
      // onClose={handleRemoveTag}
    >
      { props.tag.name }
    </Tag>
  )
})