import "./NotificationCenterNotification.scss";
import { NotificationDto } from "../../../../api";
import { useDateHelpers } from "../../../../hooks";
import { getStringContentType } from "../../../../helpers/stringFunctions";
import { StringContentType } from "../../../../enums/stringContentType";
import { TextEditor } from "../../../modules/textEditor/TextEditor";
import { ToolbarType } from "../../../modules/textEditor/types/types";
import { initialEditorContent } from "../../../../utils/textEditor";

interface INotificationCenterNotificationProps {
  notification?: NotificationDto;
}

export const NotificationCenterNotification = (props: INotificationCenterNotificationProps) => {
  const dateHelpers = useDateHelpers();

  if (props.notification == null || !props.notification.contentDto?.description?.trim()?.length) return null;

  if (getStringContentType(props.notification.contentDto.description) == StringContentType.textEditorContent) {
    return (
      <div className="d-stack-column spacing-1 mt-2">
        <TextEditor
          readOnly
          toolbarType={ToolbarType.Hidden}
          value={props.notification.contentDto.description}
          initialValue={initialEditorContent}
        />
        <span
          children={
            (props.notification.sender?.nameShort ?? "") +
            " • " +
            dateHelpers.formatDate(props.notification.dateCreated)
          }
        />
        <span children={props.notification.company?.name?.trim() || props.notification.company?.nameFallback} />
      </div>
    );
  }

  return (
    <div className="d-stack-column spacing-1 mt-2">
      <span children={props.notification.contentDto?.description} />
      <span
        children={
          (props.notification.sender?.nameShort ?? "") + " • " + dateHelpers.formatDate(props.notification.dateCreated)
        }
      />
      <span children={props.notification.company?.name?.trim() || props.notification.company?.nameFallback} />
    </div>
  );
};
