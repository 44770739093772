import React, { forwardRef } from "react";
import "./CreateTestsForm.scss";
import { ICreateTestsForm } from "./CreateTestsForm.interface";
import { QuestionGenerator } from "./QuestionGenerator/QuestionGenerator";
import { useTranslation } from "react-i18next";

export const CreateTestsForm: React.FC<ICreateTestsForm> = forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div style={props.styles} className="create-tests-form">
      <div className="create-tests-form__title">
        { t("ui:title.testing") }
      </div>
      <QuestionGenerator
        ref={ref}
        regulationContentId={props.regulationContentId}
        noImmediatelyCreateQuestion={props.noImmediatelyCreateQuestion}
        // onSave={props.onSave}
        editableMode={props.editableMode}
        onClose={props.onClose}
        onSaveChanges={props.onSaveChanges}
        onChangeHasChanges={props.onChangeHasChanges}
        defaultQuestions={props.defaultQuestions}
      />
    </div>
  );
});
