import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, Dialog, DialogActions, Input, Radio, RadioGroup } from "../../../uiKit";
import { Space } from "antd";

interface IDriverCalledAdminDialogView {
  open: boolean;
  callResultTypes: { id: number; value: string; text: string }[];
  callResult: { type: string | null; text: string };
  isLoading: boolean;

  setCallResult(data: { type: string | null; text: string }): void;

  onSave(): void;

  onClose(): void;
}

function DriverCalledAdminDialogView(props: IDriverCalledAdminDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.driver_called")} open={props.open} onClose={props.onClose} closable={false}>
      <div>
        <SubheaderText text={t("ui:subheader.call_result")} />
        <RadioGroup
          defaultValue={null}
          value={props.callResult.type}
          onChange={(event: any) => props.setCallResult({ ...props.callResult, type: event.target.value })}
        >
          <Space direction="vertical">
            {props.callResultTypes.map((cr) => (
              <Radio key={cr.id} value={cr.value} className="pa-1">
                {cr.text}
              </Radio>
            ))}
          </Space>
        </RadioGroup>
        <div className="mt-2">
          <SubheaderText text={t("ui:subheader.comment")} />
          <Input
            placeholder={t("ui:placeholder.comment")}
            value={props.callResult.text}
            onInput={(event: any) => props.setCallResult({ ...props.callResult, text: event.target.value })}
          />
        </div>
        {/*<SubheaderText text={t("text:leave_company.text1", { companyName: props.companyData.company?.name ?? "" })} />*/}
      </div>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          loading={props.isLoading}
          disabled={props.callResult.type == null}
          onClick={props.onSave}
          variant="filled"
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DriverCalledAdminDialogView;
