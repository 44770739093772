import React from "react";
import { useTranslation } from "react-i18next";
import CompanyEditForm from "../../forms/companyEditForm/CompanyEditForm";
import { Dialog } from "../../../uiKit";

function CompanySettingsOnFirstLoginDialogView(props: any) {
  const { t } = useTranslation();

  const handleSave = () => {
    window.location.reload();
  };

  return (
    <Dialog
      title={t("ui:title.edit_to_start_company")}
      // scrollStrategy="dialogBody"
      open={true}
      closable={false}
      onClose={() => {}}
    >
      <CompanyEditForm setLoading={() => {}} onSaveClick={handleSave} firstLaunch />
    </Dialog>
  );
}

export default CompanySettingsOnFirstLoginDialogView;
