import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { IconButton } from "../../uiKit";
import "./SandboxPage.scss";

function SandboxPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <PageHeader title={t("common:page_title.sandbox") + " / AY"}>
        <IconButton onClick={() => navigate("/sandbox")} size="small" className="ml-auto">
          <FiChevronLeft />
        </IconButton>
      </PageHeader>
      <PageContent isFilled></PageContent>
    </>
  );
}

export default SandboxPage;
