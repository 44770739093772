import React from "react";
import { TagView } from "./TagView";
import { TagProps } from "antd";

interface ITag extends TagProps {
  //
}

export function Tag(props: ITag) {
  return <TagView {...props} />;
}
