import React from "react";
import { PopoverProps } from "antd/lib/popover";
import { PopoverView } from "./PopoverView";

interface IPopover extends PopoverProps {
  //
}

export function Popover(props: IPopover) {
  return <PopoverView {...props} />;
}
