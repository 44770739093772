import React, { memo, useCallback, useMemo, useState } from "react";
import { RegulationFunctionSelectionDialogView } from "./RegulationFunctionSelectionDialogView";
import { TemplateDialogContentTypeKeys } from "../consts/consts";
import {
  RegulationCreationOrSelectionTemplateDialog
} from "../regulationCreationOrSelectionTemplateDialog/RegulationCreationOrSelectionTemplateDialog";
import { useOrgchart } from "../../../pages/regulation/hooks/useOrgchart";

export interface RegulationFunctionSelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenCreationOrSelectionTemplateDialog: () => void;
  onSetContentType: (contentType: TemplateDialogContentTypeKeys) => void;
  orgchartId: number | number[];
  sectionId?: number | null;
  handleChangeOrgchartId?: (orgchartId: number | number[]) => void;
}

export const RegulationFunctionSelectionDialog = memo((props: RegulationFunctionSelectionDialogProps) => {
  const [isOpenCreationOrSelectionTemplateDialog, setIsOpenCreationOrSelectionTemplateDialog] = useState<boolean>(false);

  const handleOpenCreationOrSelectionTemplateDialog = useCallback(() => {
    setIsOpenCreationOrSelectionTemplateDialog(true);
  }, []);

  const handleCloseCreationOrSelectionTemplateDialog = () => {
    setIsOpenCreationOrSelectionTemplateDialog(false);
  };


  return (
    <>
      {isOpenCreationOrSelectionTemplateDialog && (
          <RegulationCreationOrSelectionTemplateDialog
            isOpen={isOpenCreationOrSelectionTemplateDialog}
            onClose={handleCloseCreationOrSelectionTemplateDialog}
            contentType={TemplateDialogContentTypeKeys.Creation}
          />
        )}

      <RegulationFunctionSelectionDialogView
        {...props}
        onOpenCreationOrSelectionTemplateDialog={handleOpenCreationOrSelectionTemplateDialog}
      />
    </>
  );
});
