import React, { memo, useMemo } from "react";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { IIssueActivityPriorityRow } from "./issueActivityPriorityRow.interface";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./issueActivityPriorityRow.scss";
import { useTranslation } from "react-i18next";
import { initialEditorContent } from "../../../../../../../../utils/textEditor";
import { getColor } from "../../../../../../../../utils/hex2rgb";

export const IssueActivityPriorityRow = memo((props: IIssueActivityPriorityRow) => {
  const { t } = useTranslation();

  const isEmptyPrevText = useMemo(() => {
    return !props.prevValue || props.prevValue === initialEditorContent
  },[props.prevValue]);

  const isEmptyValueText = useMemo(() => {
    return !props.value || props.value === initialEditorContent
  },[props.value]);

  const valueText = useMemo(() => {
    return isEmptyValueText ? t("parse:no") : props.value;
  }, [isEmptyValueText, props.value]);

  const prevText = useMemo(() => {
    return isEmptyPrevText ? t("parse:no") : props.prevValue;
  }, [isEmptyPrevText, props.prevValue]);


  const prevTextBackground = useMemo(() => {
    const textBackground = props.previousPriority?.backgroundColor
      ? getColor(props.previousPriority.backgroundColor, 0.2)
      :  "var(--color-calendar-default1)" ;
    return props.prevValue ? textBackground : undefined
  }, [props.prevValue, props.previousPriority]);

  const textBackground = useMemo(() => {
    if (isEmptyValueText) {
      return undefined;
    }
    const background =
      props.prevValue ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)";
    return props.priority?.backgroundColor
      ? getColor(props.priority.backgroundColor, 0.2)
      : background;
  }, [props.prevValue, props.priority]);

  return (
    <div className={"issue-activity-priority-row"}>
      <IssueActivityText
        background={prevTextBackground}
        text={prevText}
        isInlineText={true}
        color={isEmptyPrevText ? "var(--color-calendar-text-base)" : props.previousPriority?.fontColor ?? undefined}
        lineHeight={1.143}
      />
      <Icon
        component={() => <ChevronRight size={16}/>}
      />
      <IssueActivityText
        background={textBackground}
        text={valueText}
        isInlineText={true}
        color={isEmptyValueText ? "var(--color-calendar-text-base)" : props.priority?.fontColor ?? undefined}
        lineHeight={1.143}
      />
    </div>
 )
})
