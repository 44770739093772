import React from "react";
import { RadioView } from "./RadiView";
import { RadioProps } from "antd";

interface IRadio extends RadioProps {
  isNewDesign?: boolean;
}

export function Radio(props: IRadio) {
  return <RadioView {...props} />;
}
