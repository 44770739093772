import { useTranslation } from "react-i18next";
import { Icon, IconButton, Select, Spin, Text } from "../../../../../uiKit";
import { IPlanningPageSubordinatesSidebarView } from "./PlanningPageMyPlanSidebar.interface";
import "./PlanningPageMyPlanSidebar.scss";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import React, { createRef, useCallback, useEffect, useMemo } from "react";
import { CardIssue } from "../../../communications/components/cardIssue/CardIssue";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { FilterButton } from "../../../../../elements/filterButton/FilterButton";
import {
  CalendarSidebarFilterDropdownContent
} from "../../../communications/components/CalendarSidebarFilterDropdownContent/CalendarSidebarFilterDropdownContent";
import {
  PlaningSidebarFilterDropdownContent
} from "../PlaningSidebarFilterDropdownContent/PlaningSidebarFilterDropdownContent";

export const PlanningPageMyPlanSidebarView = (props: IPlanningPageSubordinatesSidebarView) => {
  const { t } = useTranslation();

  const handleAddIssue = useCallback((issueId: number) => {
    props.onAddIssue(issueId);
  }, [props.planId]);

  const planningSidebar = createRef<HTMLDivElement>();

  useEffect(() => {
    if (planningSidebar.current) {
      planningSidebar.current.scrollTop = 0;
    }
  }, [props.filter]);

  return (
    <div className="planning-my-plan-sidebar__wrapper full-height d-stack-column pa-4">
      <div className="planning-my-plan-sidebar-header">

        <Text
          size="16x"
          weight="bold"
          children={t("ui:calendar.unallocated_tasks")}
        />
        <FilterButton
          placement={"bottomLeft"}
          isActiveFilter={props.isActiveFilter}
          dropdownContent={
            <PlaningSidebarFilterDropdownContent
              onChangeFilter={props.onChangeFilter}
              activeFilter={props.filter}
              isLoading={props.isLoading}
            />
          }
          // isActiveFilter={props.isActiveFilter}
        />

      </div>
      <div className="d-stack-column mb-2">
        <Text
          size="12px"
          type="secondary"
          children={`${t('ui:calendar.total_tasks')} ${props.totalItems}`}
        />
        <Text
          size="12px"
          type="secondary"
          children={`${t('ui:calendar.planned_time')} ${props.totalTimePlan}`}
        />
      </div>
      <div
        ref={planningSidebar}
        className="planning-my-plan-sidebar__content"
      >
        {props.issues?.length == 0 ? (
          <div className="full-height d-flex align-center justify-center">
            {props.isLoading ? (
              <Spin size="large" spinning />
            ) : (
              <Text size="14px" children={t("text:no_open_tasks.sidebar")} />
            )}
          </div>
        ) : (
          <div>
            {props.issues.map((issue) => (
              <CardIssue
                key={issue.id}
                noDrag
                issue={issue}
                onAddIssue={handleAddIssue}
              />
            ))}
            {/*{!props.isDone && props.issues?.length ? (*/}
            {!props.isDone && props.issues?.length ? (
              <ScrollTrigger
                onIntersection={props.loadNext}
                marginTop={false}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
