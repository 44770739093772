import React, { memo } from "react";
import "./DatePicker.scss";
import "antd/es/date-picker/style/index";
import { Dayjs } from "dayjs";
import { PickerDateProps } from "antd/lib/date-picker/generatePicker";
import clsx from "clsx";
import "react-day-picker/dist/style.css";
import { DatePickerGeneratedSingle } from "../datePickerGenerated/DatePickerGenerated";

export type TDatePickerViewProps = {
  // firstDayOfWeek?: number;
  timeZone?: string;
  readOnly?: boolean;
  isStatic?: boolean;
} & PickerDateProps<Dayjs>;

export const DatePickerView = memo(({ className, readOnly, dropdownClassName, isStatic, ...rest }: TDatePickerViewProps) => {
  return (
    <DatePickerGeneratedSingle
      className={clsx("uiKit-datePicker uiKit-datePicker_static", className ?? "", {
        "no-interact": readOnly,
      })}
      dropdownClassName={clsx("uiKit-datePicker__dropdown", dropdownClassName ?? "", {
        __showTimeActive: !!rest.showTime,
        "uiKit-datePicker__dropdown_static": isStatic,
      })}
      showToday={false}
      showTime={false}
      {...rest}
    />
  );
});
