import React from "react";
import { AvatarGroupView } from "./AvatarGroupView";
import { GroupProps } from "antd/es/avatar";

interface IAvatarGroup extends GroupProps {
  //
}

export function AvatarGroup(props: IAvatarGroup) {
  return <AvatarGroupView {...props} />;
}
