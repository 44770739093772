import React from 'react';
import { Dropdown, Switch } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import './TypeFunction.scss';
import { ArrowDownIcon } from "../../../../../constants/icon";
import { ITypeFunction } from "./TypeFunction.interface";


export const TypeFunction = ({ items, filterValue, onChange }: ITypeFunction) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      destroyPopupOnHide
      trigger={["click"]}
      // @ts-ignore
      items={items.map((item) => ({
        key: item.key,
        text: (
          <Switch
            checked={filterValue[item.key]}
            onChange={(value, e) => {
              e.stopPropagation();
              onChange(item.key, value)
            }}
            label={item.text}
            size={"small"}
            isHiddenCheckedChildren
          />
        )
      })
      )}
    >
      <div className="type-function">
        { t("parse:type_of_function") }
        <div className="type-function__icon">
          <ArrowDownIcon/>
        </div>

      </div>

    </Dropdown>
  )
}