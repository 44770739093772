import React from "react";

interface IDateTimeComponent {
  color: string;
  children: string;
}

export const DateTimeComponent = (props: IDateTimeComponent) => {
  return (
    <span
      style={{
        padding: "2px",
        pointerEvents: "none",
        userSelect: "none",
        backgroundColor: props.color,
        borderRadius: "4px",
        // verticalAlign: "middle",
      }}
    >
      {props.children}
    </span>
  );
};
