import React, { useCallback, useState } from "react";
import { IssueExecutorChangeDialogView } from "./IssueExecutorChangeDialogView";
import { IIssueExecutorChangeDialog } from "./IssueExecutorChangeDialog.interface";

export const IssueExecutorChangeDialog = (props: IIssueExecutorChangeDialog) => {
  const [reason, setReason] = useState<string>("");
  const [isWrongDate, setIsWrongDate] = useState<boolean>(
    props.updater?.currentState?.dateDeadline ? new Date(props.updater?.currentState?.dateDeadline) < new Date() : false
  );

  const handleChangeReason = useCallback((newReason: string) => {
    setReason(newReason);
  }, []);

  const handleChangeIsWrongDate = useCallback((value: boolean) => {
    setIsWrongDate(value);
  }, []);

  const handleClose = useCallback(
    (v: boolean) => {
      setReason("");
      props.onClose(v);
    },
    [props]
  );

  const handleSuccess = useCallback(async () => {
    if (reason.trim().length > 0) {
      props.onSuccess(reason);
      // handleClose(true);
    }
  }, [handleClose, props, reason]);

  return (
    <IssueExecutorChangeDialogView
      open={props.open}
      onClose={() => handleClose(false)}
      onSuccess={handleSuccess}
      text={reason}
      setText={handleChangeReason}
      updater={props.updater}
      isWrongDate={isWrongDate}
      setIsWrongDate={handleChangeIsWrongDate}
    />
  );
};
