import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RolePositionDto } from "../../../../api";
import { IPositionSelectionBox, positionsKeys } from "./UserSelectionDialog.interface";
import { Radio, RadioGroup } from "../../../uiKit";

export const PositionSelectionBox: React.FC<IPositionSelectionBox> = (props) => {
  const { t } = useTranslation();

  const [disabledAllManagers, setDisabledAllManagers] = useState<boolean>(false);
  const [disabledAllPerformers, setDisabledAllPerformers] = useState<boolean>(false);
  const [disabledAll, setDisabledAll] = useState<boolean>(false);

  const handleIdentifyDisabledAllManagersOrPerformers = () => {
    if (!props.disabledRules?.length) return;
    const currentRoles = props.disabledRules
      ?.filter((item) => item.orgchartId === props.orgchartId)
      .filter((item) => item.roleId === props.roleId);
    if (currentRoles) {
      currentRoles.forEach((currentRole) => {
        currentRole.rolePositionId == null && setDisabledAll(true);
        currentRole.rolePositionType === 1 && setDisabledAllManagers(true);
        currentRole.rolePositionType === 2 && setDisabledAllPerformers(true);
      });
    } else {
      setDisabledAll(false);
      setDisabledAllManagers(false);
      setDisabledAllPerformers(false);
    }
  };

  const handleIdentifyDisabledByPositionId = (positionId: number): boolean =>
    !!props.disabledRules?.find((item) => item.rolePositionId === positionId);

  useEffect(() => {
    setDisabledAll(false);
    setDisabledAllManagers(false);
    setDisabledAllPerformers(false);
    handleIdentifyDisabledAllManagersOrPerformers();
  }, [props.disabledRules, props.roleId]);

  useEffect(() => {
    props.setDisabledFromDown(disabledAll && props.rolePositionId === null);
    // props.setDisabledFromDown((props.rolePositionId === null && disabledAll) ? true : false)
  }, [props.roleId, disabledAll]);

  return (
    <div className="user-selection-dialog__right-bar d-stack-column spacing-2">
      {/*<Input style={{ visibility: "hidden" }} />*/}
      {/*<Input style={{ visibility: "hidden" }} />*/}
      <RadioGroup
        className="user-selection-dialog__right-bar__radio-group d-stack-column spacing-2"
        name="controlled-radio-buttons-group"
        value={props.rolePositionId === null && disabledAll ? undefined : props.rolePositionId}
        onChange={props.onChangeRolePositionId}
      >
        {!props.disabledAllAll && (
          <Radio disabled={disabledAll} value={null}>
            {t("ui:misc.radio_all")}
          </Radio>
        )}
        {props?.positions === undefined && (
          <Radio value={positionsKeys.allManagers} disabled={disabledAllManagers}>
            {t("common:user_selection_dialog.position_select_box.all_managers")}
          </Radio>
        )}
        <Radio value={positionsKeys.allPerformers} disabled={disabledAllPerformers}>
          {t("common:user_selection_dialog.position_select_box.all_performers")}
        </Radio>
        {props?.positions &&
          (props?.positions as RolePositionDto[])?.map((item) => (
            <Radio disabled={handleIdentifyDisabledByPositionId(item.id!)} value={item.id} key={item.id}>
              {item.name}
            </Radio>
          ))}
      </RadioGroup>
    </div>
  );
};
