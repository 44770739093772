import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../uiKit";
// import { toolsConst } from "../../misc/consts";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";

export const HeaderButtons = ({
  availableToolsKeys,
  isSidebarOpen,
}: {
  availableToolsKeys: string[];
  isSidebarOpen: boolean;
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleRedirectToCreateRegulation = () => navigate("/policy/create-policy");
  const handleRedirectToCreateSection = () => navigate("/policy/create-topic");
  const handleRedirectToStats = () => navigate("/policy/stats");

  const addPopupItems = [
    {
      id: 1,
      text: t("parse:regulation"),
      action: handleRedirectToCreateRegulation,
    },
    {
      id: 2,
      text: t("parse:section"),
      action: handleRedirectToCreateSection,
    },
  ];

  return (
    <div className="regulation-header_button-group d-stack-row spacing-2">
      <Button
        className="btn-icon-16"
        style={{ marginRight: "8px" }}
        // icon={<FiBarChart2 />}
        onClick={handleRedirectToStats}
        variant="default"
      >
        {t("ui:button.stats")}
      </Button>
      {/*{availableToolsKeys.includes(toolsConst.addSections) && (*/}
      {/*  <Button*/}
      {/*    variant="filled"*/}
      {/*    className="btn-icon-17"*/}
      {/*    onClick={handleRedirectToCreateSection}*/}
      {/*    icon={<BiBookAdd />}*/}
      {/*  >*/}
      {/*    {t("ui:button.add_section")}*/}
      {/*  </Button>*/}
      {/*)}*/}
      {/*{availableToolsKeys.includes(toolsConst.addRegulation) && (*/}
      {/*  <Button*/}
      {/*    className="btn-icon-19"*/}
      {/*    // icon={<IoIosAdd />}*/}
      {/*    variant="filled"*/}
      {/*    onClick={handleRedirectToCreateRegulation}*/}
      {/*  >*/}
      {/*    {t("ui:button.add_regulation")}*/}
      {/*  </Button>*/}
      {/*)}*/}
      {/*{availableToolsKeys.includes(toolsConst.addSectionsAndRegulations) && (*/}
      <DropdownMenu
        items={addPopupItems}
        iconType="chevron"
        trigger={["click"]}
        buttonProps={{
          type: "primary",
          text: t("ui:button.add"),
        }}
      />
      {/*)}*/}
    </div>
  );
};
