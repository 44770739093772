import React from "react";
import { MdTagFaces } from "@react-icons/all-files/md/MdTagFaces";
import { Icon, IconButton } from "../../../uiKit";
import { IAddReactionButton } from "../interfaces/Reactions.interface";
import { SmileIcon } from "../../../../constants/icon";
import "./AddReactionButton.scss";
import clsx from "clsx";

export const AddReactionButton: React.FC<IAddReactionButton> = ({ onClick, disabled, iconSize, isNewStyle, isBorder}) => {
  return (
    <div className={clsx("reaction-button", {
      "reaction-button_border": isBorder,
      "reaction-button_new-style": isNewStyle,
    })}>
      <IconButton
        onClick={onClick}
        // type="text"
        disabled={disabled}
        icon={
          <Icon
            component={() => (
              isNewStyle
                ? <SmileIcon size={iconSize ?? 16}/>
                : <MdTagFaces fontSize={iconSize ?? 22} />
            )}
          />
        }
      />
    </div>
  );
};
