import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import "./DriverPrePage.scss";
import { useApi, useDateHelpers, useRootStore } from "../../../hooks";
import { api } from "../../../services";
import React, { useEffect, useState } from "react";
import AdminDriverPeriodSelectDialog from "../../modules/dialogs/adminDriverPeriodSelectDialog/AdminDriverPeriodSelectDialog";
import "./DriverAdminPage.scss";
import WatchUserCardDialog from "../../modules/dialogs/watchUserCardDialog/WatchUserCardDialog";
import { AppTabsByPage } from "../../../utils/appLinksAndTabs";
import { Button, Card, Empty, Icon, Select, Table } from "../../uiKit";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { observer } from "mobx-react-lite";
import { AutocompleteModel, DriverStatsDtoPagingModel, DriverStatsOverall } from "../../../api";
import dayjs from "dayjs";
import { changeTimeInDateObject } from "../../../helpers/dateFunctions";
import { IDriverStatisticCard } from "./components/driverStatisticCard/DriverStatisticCard.interface";
import { DriverStatisticCard } from "./components/driverStatisticCard/DriverStatisticCard";
import { FiCalendar } from "react-icons/fi";

interface statsColumnsInterface {
  title: string;
  dataIndex: string;
  key: number;
  render?: any;
}

interface statsDataSourceInterface {
  id?: number;
  name?: string | null;
  processedCards?: number | null;
  processingTimeAvgInSeconds?: string;
  violationsFixed?: number | null;
  actionsText?: string | null;
}

function DriverAdminStatisticsPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const dateHelpers = useDateHelpers();
  const [isPeriodPickerOpen, setIsPeriodPickerOpen] = useState<boolean>(false);

  const [date, setDate] = useState({
    from: new Date().toISOString(),
    to: new Date().toISOString(),
  });

  const [dateBackup, setDateBackup] = useState(date);
  const [dateButtonText, setDateButtonText] = useState<string>("");
  const [isUserSelectToViewDialogOpen, setIsUserSelectToViewDialogOpen] = useState<boolean>(false);
  const [selectedCompanyGroupId, setSelectedCompanyGroupId] = useState<number | null>(null);

  const [adminDriverData, setAdminDriverData] = useState<DriverStatsDtoPagingModel | null>(null);
  const [overallData, setOverallData] = useState<DriverStatsOverall | null>(null);

  useEffect(() => {
    if (!selectedCompanyGroupId) return;

    loadData();
  }, [selectedCompanyGroupId]);

  const getDateTo = (dateFrom: string, dateTo: string) => {
    dayjs(dateFrom).isSame(dayjs(dateTo));
    const dF = changeTimeInDateObject(dayjs(dateFrom).toDate(), { hh: 0, mm: 0, ss: 0 });
    const dT = changeTimeInDateObject(dayjs(dateTo).toDate(), { hh: 23, mm: 59, ss: 0 });

    // return dayjs(dF).isSame(dayjs(dT), "date")
    return Math.abs(dayjs(dF).diff(dayjs(dT), "hour")) < 25
      ? dayjs(dF).add(1, "day").toISOString()
      : dayjs(dT).toISOString();
  };

  const loadData = async () => {
    setAdminDriverData(
      await api.driverAdmin.stats({
        companyGroupId: selectedCompanyGroupId ?? undefined,
        dateFrom: changeTimeInDateObject(new Date(date.from), { hh: 0, mm: 0, ss: 0 }).toISOString(),
        dateTo: getDateTo(date.from, date.to),
        // dateTo: dayjs(date.from).isSame(date.to, "date")
        //   ? changeTimeInDateObject(dayjs(date.to).add(1, "day").toDate(), { hh: 23, mm: 59, ss: 0 }).toISOString()
        //   : changeTimeInDateObject(new Date(date.to), { hh: 23, mm: 59, ss: 0 }).toISOString(),
        pageSize: 1000,
      })
    );
    setOverallData(
      await api.driverAdmin.overall({
        companyGroupId: selectedCompanyGroupId ?? undefined,
        dateFrom: changeTimeInDateObject(new Date(date.from), { hh: 0, mm: 0, ss: 0 }).toISOString(),
        dateTo: getDateTo(date.from, date.to),
        pageSize: 1000,
      })
    );
  };

  const companyGroupData = useApi(
    () => api.companyGroup.autocomplete({ hasAdminUserId: authStore.getInitialInfo?.identity?.id, pageSize: 1000 }),
    undefined,
    (v) => setSelectedCompanyGroupId((v?.items?.[0]?.id as number) ?? null)
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = new Date(dateBackup.from).getFullYear();
    const toYear = new Date(dateBackup.from).getFullYear();
    // Если выбран один день
    if (dayjs(dateBackup.from).isSame(dayjs(dateBackup.to), "day")) {
      setDateButtonText(
        dateHelpers.formatDate(dateBackup.from, {
          formatObject: { month: "short", hour: undefined, minute: undefined },
        })
      );
    } else {
      setDateButtonText(
        dateHelpers.formatDate(dateBackup.from, {
          showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
          formatObject: {
            hour: undefined,
            minute: undefined,
            month: "short",
          },
        }) +
          " - " +
          dateHelpers.formatDate(dateBackup.to, {
            showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
            formatObject: {
              hour: undefined,
              minute: undefined,
              month: "short",
            },
          })
      );
    }
  }, [dateBackup]);

  const handleDataChange = async () => {
    setIsPeriodPickerOpen(false);
    await loadData();
    setDateBackup(date);
  };

  const handleWatchUserCardViewClick = () => {
    setIsUserSelectToViewDialogOpen(true);
  };

  const handleWatchUserCardViewClose = () => {
    setIsUserSelectToViewDialogOpen(false);
  };

  const handleWatchUserCardViewClickDialogAccept = () => {
    setIsUserSelectToViewDialogOpen(false);
  };

  const pageMenuItems: PopupMenuItemTypes[] = [
    { id: 0, text: t("parse:view_user_card"), action: handleWatchUserCardViewClick },
  ];

  const statsColumns: statsColumnsInterface[] = [
    {
      key: 1,
      dataIndex: "name",
      title: t("common:driver.driver"),
    },
    {
      key: 2,
      dataIndex: "processedCards",
      title: t("common:driver.proceeded_cards"),
    },
    {
      key: 3,
      dataIndex: "processingTimeAvgInSeconds",
      title: t("common:driver.average_processing_time"),
    },
    {
      key: 4,
      dataIndex: "violationsFixed",
      title: t("common:driver.corrected_violations"),
    },
    {
      key: 5,
      dataIndex: "actionsText",
      title: t("common:driver.actions"),
      render(text: any, record: any) {
        return {
          props: {
            style: { whiteSpace: "pre" },
          },
          children: text,
        };
      },
    },
  ];

  const statsDataSource: statsDataSourceInterface[] =
    adminDriverData?.items?.map((item) => ({
      id: item.driver?.id,
      name: item.driver?.name,
      processedCards: item.processedCards,
      processingTimeAvgInSeconds:
        parseFloat(item.processingTimeAvgInSeconds?.toFixed(1) ?? "") + " " + t("parse:seconds"),
      violationsFixed: item.violationsFixed,
      actionsText: item.actionsText,
    })) ?? [];

  const statsData: IDriverStatisticCard[] = [
    {
      title: t("common:driver.card.queue.part1"),
      digit: overallData?.cardsInQueue,
      subtitle: t("common:driver.card.queue.part2"),
    },
    {
      title: t("common:driver.card.employees_per_driver.part1"),
      digit: overallData?.usersPerDriver,
      subtitle: t("common:driver.card.employees_per_driver.part2"),
    },
    {
      title: t("common:driver.card.average_working_time.part1"),
      digit: overallData?.dateDependant?.workTimeAverageInMinutes ?? "",
      subtitle: t("common:driver.card.average_working_time.part2"),
    },
    {
      title: t("common:driver.card.average_processing_time.part1"),
      digit: overallData?.dateDependant?.oneCardProcessTimeAverageInSeconds,
      subtitle: t("common:driver.card.average_processing_time.part2"),
    },
    {
      title: t("common:driver.card.average_number_of_cards.part1"),
      digit: overallData?.dateDependant?.cardsProcessedPerDriverAvg,
      subtitle: t("common:driver.card.average_number_of_cards.part2"),
    },
    {
      title: t("common:driver.card.average_number_of_violations.part1"),
      digit: overallData?.dateDependant?.violationsPerUserAvg,
      subtitle: t("common:driver.card.average_number_of_violations.part2"),
    },
  ];

  // useEffect(() => {
  //   adminDriverData.fetch();
  //   overallData.fetch();
  // }, [selectedCompanyGroupId]);

  return (
    <>
      {isPeriodPickerOpen && (
        <AdminDriverPeriodSelectDialog
          value={date}
          open={isPeriodPickerOpen}
          onClose={() => {
            setIsPeriodPickerOpen(false);
            setDate(dateBackup);
          }}
          onChange={(value: { from: string; to: string }) => setDate(value)}
          onSave={() => handleDataChange()}
          disableFuture
        />
      )}
      {isUserSelectToViewDialogOpen && (
        <WatchUserCardDialog
          open={isUserSelectToViewDialogOpen}
          onClose={handleWatchUserCardViewClose}
          onAccept={handleWatchUserCardViewClickDialogAccept}
        />
      )}
      <PageHeader>
        {selectedCompanyGroupId && (
          <Select
            size="middle"
            items={(companyGroupData.value?.items ?? []).map((i: AutocompleteModel) => ({
              id: i.id as number,
              text: i.text as string,
              value: i.id as number,
            }))}
            value={selectedCompanyGroupId}
            onChange={(val) => setSelectedCompanyGroupId(Number(val))}
            style={{ minWidth: "250px" }}
          />
        )}
        <div className="flex-grow-1" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <DropdownMenu items={pageMenuItems} />
        </div>
      </PageHeader>
      <PageContent
        isLoading={adminDriverData == null || overallData == null || companyGroupData.value == null}
        useTranslationInTabs
        isFilled={false}
        tabs={AppTabsByPage["driver"]}
      >
        <div className="driver-admin-page__wrapper">
          <Card
            className="flex-grow-1 flex-shrink-1 mr-1"
            title={t("common:driver.drivers")}
            size="default"
            scrollStrategy="cardBody"
            extra={
              <Button
                onClick={() => setIsPeriodPickerOpen(true)}
                variant="filled"
                icon={<Icon component={() => <FiCalendar />} />}
                children={dateButtonText}
              />
            }
          >
            <div style={{ width: "fit-content", minWidth: "100%" }}>
              <Table
                size="small"
                style={{ minWidth: "100%" }}
                // scroll={{ x: "100%" }}
                // scroll={{ x: "max-content" }}
                // tableLayout="auto"
                // sticky
                // className="full-width"
                // bordered
                pagination={false}
                dataSource={statsDataSource}
                columns={statsColumns}
              />
            </div>
          </Card>
          <Card
            className="ml-1 flex-shrink-0"
            title={t("common:driver.monitoring")}
            size="default"
            scrollStrategy="cardBody"
          >
            {overallData != null ? (
              <div className="driver-admin-page__info-cards__wrapper">
                {statsData.map((item) => (
                  <React.Fragment key={item.title}>
                    <DriverStatisticCard digit={item.digit} title={item.title} subtitle={item.subtitle} />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </div>
      </PageContent>
    </>
  );
}

export default observer(DriverAdminStatisticsPage);
