import React, { useState } from "react";
import RecurringTasksRecalculateDialogView from "./RecurringTasksRecalculateDialogView";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";

interface IIRecurringTasksRecalculateDialog {
  open: boolean;

  onClose: () => void;
  onApply: () => void;
}

function RecurringTasksRecalculateDialog(props: IIRecurringTasksRecalculateDialog) {
  const notifier = useNotifier();
  const [taskId, setTaskId] = useState<number | string>("");
  const [date, setDate] = useState<Date>(new Date());
  // date.setUTCHours(0, 0, 0, 0);

  const handleClose = () => {
    setTaskId("");
    setDate(new Date());
    // date.setUTCHours(0, 0, 0, 0);
    props.onClose();
  };

  const handleApply = async () => {
    if (taskId == null) {
      return;
    }
    // date.setUTCHours(0, 0, 0, 0);
    const r = await api.adminCompany.recalculateRecurringIssues(Number(taskId), date);
    notifier.showSuccessError("Перерасчёт задач успешен", "Что-то пошло не так", !!r);
    if (r) {
      handleClose();
    }
  };

  return (
    <RecurringTasksRecalculateDialogView
      taskId={taskId}
      date={date}
      setDate={setDate}
      setTaskId={(value) => setTaskId(value ?? "")}
      open={props.open}
      onClose={handleClose}
      onApply={handleApply}
    />
  );
}

export default RecurringTasksRecalculateDialog;
