import "./ScheduleCell.scss";
import { theme } from "antd";
import { addAlphaToHex } from "../../../../../helpers/colorFunctions";
import { useMemo } from "react";
import { createSelectable, TSelectableItemProps } from "react-selectable-fast";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import { User2RoleDto, UserScheduleDto } from "../../../../../api";
import { NumericRange } from "../../../../../types/customTypes";
import { useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { getObjectFromTimeSpan } from "../../../../../helpers/dateFunctions";

interface IScheduleCellProps {
  index: number;
  date: Dayjs;
  user2role: User2RoleDto;
  defaultPeriodSchedule: (UserScheduleDto | undefined)[];
  isDisabled?: boolean;
  useUserTimeZone: boolean;
}

const ScheduleCellSelectable = observer((props: IScheduleCellProps & TSelectableItemProps) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { authStore } = useRootStore();

  const getTimeZonedTimeSpan = (timeSpan: string | null | undefined): string => {
    if (timeSpan == null || timeSpan.trim().length == 0) return "";
    if (!props.useUserTimeZone) return timeSpan.slice(0, 5);
    // const userTimeZone = authStore.getInitialInfo?.identity?.timeZoneId?.trim() || null;
    const userTimeZone = props.user2role.user?.timeZoneId?.trim() || null;
    const companyTimeZone = authStore.getCurrentCompany?.timeZoneId?.trim() || null;
    if (userTimeZone == null || companyTimeZone == null) return timeSpan.slice(0, 5);
    const obj = getObjectFromTimeSpan(timeSpan);
    return dayjs.tz({ hours: obj.hours, minutes: obj.minutes }, companyTimeZone).tz(userTimeZone).format("HH:mm");
  };

  const userSchedule: UserScheduleDto | undefined = useMemo(
    () =>
      props.user2role.schedules?.find((s) =>
        props.date.isBetween(dayjs.utc(s.dateFrom).startOf("day"), dayjs.utc(s.dateTo).endOf("day"), "date", "[]")
      ),
    [props.user2role, props.date]
  );

  const isCardAvailable: boolean = useMemo(() => {
    if (props.user2role.dateFrom != null && props.user2role.dateTo != null) {
      return props.date.isBetween(
        dayjs.utc(props.user2role.dateFrom).startOf("day"),
        dayjs.utc(props.user2role.dateTo).endOf("day"),
        "date",
        "[]"
      );
    }
    return props.user2role.dateFrom != null
      ? props.date.isSameOrAfter(dayjs.utc(props.user2role.dateFrom).startOf("day"))
      : false;
  }, [props.user2role, props.date]);

  const cardType: NumericRange<0, 4> = useMemo(() => {
    if (!isCardAvailable) return 0;
    if (userSchedule != null) return (userSchedule.type ?? 0) as NumericRange<0, 4>;
    return (props.defaultPeriodSchedule[props.index]?.type ?? 0) as NumericRange<0, 4>;
  }, [props.defaultPeriodSchedule, props.index, userSchedule, isCardAvailable]);

  const cardText: string = useMemo(() => {
    if (!isCardAvailable) return "";
    if (userSchedule != null) {
      if (userSchedule.type == 1) {
        return getTimeZonedTimeSpan(userSchedule?.timeFrom) + "\n" + getTimeZonedTimeSpan(userSchedule?.timeTo);
      }
      return (userSchedule?.shortName?.trim() || userSchedule.name?.trim()) ?? "";
    }
    if (props.defaultPeriodSchedule[props.index]?.type == 1) {
      return (
        getTimeZonedTimeSpan(props.defaultPeriodSchedule[props.index]?.timeFrom) +
        "\n" +
        getTimeZonedTimeSpan(props.defaultPeriodSchedule[props.index]?.timeTo)
      );
    }
    return (
      (props.defaultPeriodSchedule[props.index]?.shortName?.trim() ||
        props.defaultPeriodSchedule[props.index]?.name?.trim()) ??
      ""
    );
  }, [props.defaultPeriodSchedule, props.index, userSchedule, isCardAvailable]);

  const cardColor = useMemo(() => {
    const colorWithAlpha = (hex: string) => addAlphaToHex(hex, 0.25);
    if ((cardText ?? "").trim().length == 0) return colorWithAlpha(token.colorBgContainerDisabled);
    switch (cardType as NumericRange<0, 4>) {
      case 1:
        return colorWithAlpha(token.colorSuccess);
      case 2:
        return colorWithAlpha(token.colorWarning);
      case 3:
        return colorWithAlpha(token.colorWarning);
      case 4:
        return colorWithAlpha(token.colorError);
      default:
        return colorWithAlpha(token.colorBgContainerDisabled);
    }
  }, [cardType, cardText]);

  return (
    <div
      ref={props.selectableRef}
      className={clsx("schedule-cell__wrapper", {
        __disabled: props.isDisabled || !isCardAvailable,
        __active: props.isSelecting,
        __selected: props.isSelected,
      })}
      style={{ backgroundColor: cardColor }}
    >
      <span children={cardText} />
    </div>
  );
});

export const ScheduleCell = createSelectable(ScheduleCellSelectable);
