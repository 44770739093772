import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";

import { $createEmojiNode, EmojiNode } from "../../nodes/EmojiNode";

export const INSERT_EMOJI_COMMAND: LexicalCommand<string> = createCommand("INSERT_EMOJI_COMMAND");

export default function EmojisPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([EmojiNode])) {
      throw new Error("EmojisPlugin: EmojiNode not registered on editor");
    }
    return editor.registerCommand<string>(
      INSERT_EMOJI_COMMAND,
      (payload) => {
        const emojiNode = $createEmojiNode("", payload);
        $insertNodes([emojiNode]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
