import React from "react";
import { DividerView } from "./DividerView";

interface IDivider extends React.HTMLAttributes<HTMLDivElement> {
  contentAlign?: "start" | "center" | "end";
  children?: React.ReactNode;
  isVertical?: boolean;
  isNewDesign?: boolean;
}

export function Divider(props: IDivider) {
  return <DividerView {...props} />;
}
