import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import EmployeeInviteListDialog from "../employeeInviteListDialog/EmployeeInviteListDialog";
import EmployeeInviteShowDialog from "../employeeInviteShowDialog/EmployeeInviteShowDialog";
import { AutocompleteModel, CompanyInviteDto } from "../../../../api";
import ExpandableContainer from "../../../elements/expandableContainer/ExpandableContainer";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectOption,
  Switch,
  Text,
} from "../../../uiKit";
import { ExecutorTypes } from "../../../pages/driver/types/DriverTypes";
import { OrgchartSwitcher } from "../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../../hooks";
import { OpeningGuideIcon } from "../../../elements/openingGuideIcon/OpeningGuideIcon";
import { guideKeys } from "../../../../stores/hintStore";
import { AccessTypeEnum } from "../../../../api/models/CompanyInviteDto";

interface IEmployeeInviteDialogView {
  open: boolean;
  inviteData: CompanyInviteDto;
  isAllInvitesDialogOpened: boolean;
  isShowInviteDialogOpened: boolean;
  allRoles: (AutocompleteModel & { orgchartId: number })[];
  isFunctionsSelectOpen: any;
  selectedOrgchartIds: number[];
  fullAccessUsersCount: number;
  fullAccessUsersLimit: string | number;
  restrictedAccessUsersCount: number;
  restrictedAccessUsersLimit: string | number;
  onOrgchartIdsChange: (value: number[]) => void;
  handleApplyFunctionsButtonClick: (type: string, value: boolean) => void;
  setIsFunctionsSelectOpen: (type: string, value: boolean) => void;

  handleClose(): void;

  handleRolesChange(roleIds: string[], type: ExecutorTypes): void;

  setInviteData(data: CompanyInviteDto): void;

  handleInviteCreate(): void;

  setIsAllInvitesDialogOpened(value: boolean): void;

  handleAllInvitesDialogClose(): void;

  handleAllInvitesDialogOpen(): void;

  handleShowInviteDialogCLose(): void;

  isStringEmail(str: string): boolean;
}

function EmployeeInviteDialogView(props: IEmployeeInviteDialogView) {
  const { t } = useTranslation();
  const { orgchartStore, authStore } = useRootStore();

  return (
    <>
      <EmployeeInviteShowDialog
        open={props.isShowInviteDialogOpened}
        onClose={props.handleShowInviteDialogCLose}
        inviteData={props.inviteData}
      />
      <EmployeeInviteListDialog open={props.isAllInvitesDialogOpened} onClose={props.handleAllInvitesDialogClose} />
      <Dialog title={t("ui:title.invite_employee")} open={props.open} onClose={props.handleClose} closable={false}>
        {/*<divider />*/}
        <div className="mb-2">
          <>
            <SubheaderText text={t("ui:subheader.email")} />
            <Input
              value={props.inviteData.email ?? ""}
              disabled={props.inviteData.isReusable}
              // @ts-ignore
              onInput={(event) => props.setInviteData({ ...props.inviteData, email: event.target.value })}
              placeholder={t("ui:placeholder.email")}
              // error={
              //   props.inviteData.email && props.inviteData.email.length > 0
              //     ? !props.isStringEmail(props.inviteData.email ?? "")
              //     : false
              // }
              // helperText={
              //   props.inviteData.email &&
              //   props.inviteData.email.length > 0 &&
              //   !props.isStringEmail(props.inviteData.email ?? "")
              //     ? t("validation:wrong_email")
              //     : undefined
              // }
              className="mb-3"
            />
          </>
          <div className="d-stack-row align-center">
            <Switch
              checked={props.inviteData.isReusable}
              onChange={(value) => props.setInviteData({ ...props.inviteData, isReusable: value, email: null })}
            />
            <Text children={t("ui:label.reusable_invite_code")} className="mr-1" />
            <OpeningGuideIcon contentKey={guideKeys.inviteEmployee} anchorId="1anchor" />
          </div>
        </div>
        {/*{!props.inviteData.isReusable && (*/}
        {/*  <>*/}
        {/*    <SubheaderText text={t("ui:subheader.email")} />*/}
        {/*    <Input*/}
        {/*      value={props.inviteData.email ?? ""}*/}
        {/*      // @ts-ignore*/}
        {/*      onInput={(event) => props.setInviteData({ ...props.inviteData, email: event.target.value })}*/}
        {/*      placeholder={t("ui:placeholder.email")}*/}
        {/*      // error={*/}
        {/*      //   props.inviteData.email && props.inviteData.email.length > 0*/}
        {/*      //     ? !props.isStringEmail(props.inviteData.email ?? "")*/}
        {/*      //     : false*/}
        {/*      // }*/}
        {/*      // helperText={*/}
        {/*      //   props.inviteData.email &&*/}
        {/*      //   props.inviteData.email.length > 0 &&*/}
        {/*      //   !props.isStringEmail(props.inviteData.email ?? "")*/}
        {/*      //     ? t("validation:wrong_email")*/}
        {/*      //     : undefined*/}
        {/*      // }*/}
        {/*      className="mb-3"*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}

        <div className="mb-1">
          <SubheaderText text={t("parse:access")} />
          <RadioGroup
            className="d-stack-column"
            value={props.inviteData.accessLevel}
            onChange={(event) => props.setInviteData({ ...props.inviteData, accessLevel: event.target.value })}
          >
            <Radio
              value={AccessTypeEnum.Full}
              children={t("parse:full_access")}
              disabled={
                typeof props.fullAccessUsersLimit === "number" && props.fullAccessUsersCount >= props.fullAccessUsersLimit
              }
            />
            <Radio
              value={AccessTypeEnum.Restricted}
              children={t("parse:limited_access")}
              disabled={
                typeof props.restrictedAccessUsersLimit === "number" && props.restrictedAccessUsersCount >= props.restrictedAccessUsersLimit
              }
            />
          </RadioGroup>
        </div>
        <div className="mb-1">
          <Text
            children={`${t("parse:user_limit_full")}: ${props.fullAccessUsersCount}/${
              props.fullAccessUsersLimit
            }`}
          />
        </div>
        <div className="mb-3">
          <Text
            children={`${t("parse:user_limit_restricted")}: ${props.restrictedAccessUsersCount}/${
              props.restrictedAccessUsersLimit
            }`}
          />
        </div>
        <div className="d-stack-row align-start justify-space-between">
          <div className="full-width">
            <ExpandableContainer text={t("ui:title.additional_settings")}>
              <div className="py-1" />
              <SubheaderText text={t("common:page_title.orgchart")} />
              <OrgchartSwitcher
                drawType="select"
                value={props.selectedOrgchartIds}
                onChange={(v) => Array.isArray(v) && props.onOrgchartIdsChange(v)}
                selectProps={{ mode: "multiple", style: { width: "100%" } }}
                preventDefault
              />
              <div className="py-1" />
              <SubheaderText
                text={
                  authStore.getCurrentCompanyUiType == 2
                    ? t("ui:subheader.employee_functions_administrator").split("(")[0]
                    : t("ui:subheader.employee_functions_administrator")
                }
              />
              <Select
                value={props.inviteData.roles?.filter((r) => r.user2RoleType == "admin")?.map((ci2r) => ci2r.roleId)}
                onChange={(value: string[]) => props.handleRolesChange(value ?? [], "admin")}
                mode="multiple"
                style={{ width: "100%" }}
                placeholder={t("parse:click_to_select")}
              >
                {props.allRoles?.map((role) => (
                  <SelectOption key={role.id} value={role.id}>
                    <Avatar
                      className="mr-2"
                      size="small"
                      src={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.image?.url}
                      color={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.colorHex}
                      text={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.name}
                    />
                    {role.text}
                  </SelectOption>
                ))}
                {/*<div className="pa-2" style={{ position: "sticky", bottom: 0 }}>*/}
                {/*  <button onClick={() => props.handleApplyFunctionsButtonClick("specialist", false)} variant="filled" block>*/}
                {/*    {t("ui:button.apply")}*/}
                {/*  </button>*/}
                {/*</div>*/}
              </Select>
              {/*<SelectAlternativeFilled*/}
              {/*  value={props.inviteData.roles?.filter((r) => r.user2RoleType == "admin")?.map((ci2r) => ci2r.roleId)}*/}
              {/*  onChange={(event: any) => props.handleRolesChange(event.target.value ?? [], "admin")}*/}
              {/*  multiple*/}
              {/*  fullWidth*/}
              {/*  showPlaceholder*/}
              {/*  placeholderText={t("parse:click_to_select")}*/}
              {/*  sx={{ mb: 1.5 }}*/}
              {/*  open={props.isFunctionsSelectOpen.admin}*/}
              {/*  onOpen={() => props.setIsFunctionsSelectOpen("admin", true)}*/}
              {/*  onClose={() => props.setIsFunctionsSelectOpen("admin", false)}*/}
              {/*  MenuProps={{*/}
              {/*    MenuListProps: {*/}
              {/*      sx: { pb: 0 },*/}
              {/*    },*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {props.allRoles?.map((role) => (*/}
              {/*    <MenuItem key={role.id} value={role.id}>*/}
              {/*      {role.text}*/}
              {/*    </MenuItem>*/}
              {/*  ))}*/}
              {/*  <div className="pa-2" style={{ position: "sticky", bottom: 0 }}>*/}
              {/*    <button onClick={() => props.handleApplyFunctionsButtonClick("admin", false)} variant="filled" block>*/}
              {/*      {t("ui:button.apply")}*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*</SelectAlternativeFilled>*/}
              {authStore.getCurrentCompanyUiType != 2 && (
                <>
                  <div className="py-2" />
                  <SubheaderText text={t("ui:subheader.employee_functions_executor_specialist")} />
                  <Select
                    value={props.inviteData.roles
                      ?.filter((r) => r.user2RoleType == "executor_specialist")
                      ?.map((ci2r) => ci2r.roleId)}
                    onChange={(value: string[]) => props.handleRolesChange(value ?? [], "executor_specialist")}
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={t("parse:click_to_select")}
                  >
                    {props.allRoles?.map((role) => (
                      <SelectOption key={role.id} value={role.id}>
                        <Avatar
                          className="mr-2"
                          size="small"
                          src={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.image?.url}
                          color={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.colorHex}
                          text={orgchartStore.getOrgchartsList.find((o) => o.id == role.orgchartId)?.name}
                        />
                        {role.text}
                      </SelectOption>
                    ))}
                    {/*<div className="pa-2" style={{ position: "sticky", bottom: 0 }}>*/}
                    {/*  <button onClick={() => props.handleApplyFunctionsButtonClick("specialist", false)} variant="filled" block>*/}
                    {/*    {t("ui:button.apply")}*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </Select>
                </>
              )}
            </ExpandableContainer>
          </div>
          <OpeningGuideIcon contentKey={guideKeys.inviteEmployee} ml={4} mt={11} anchorId="2anchor" />
        </div>
        <DialogActions>
          <Button onClick={props.handleAllInvitesDialogOpen}>{t("ui:button.all_invites")}</Button>
          <div className="flex-grow-1" />
          <Button variant="text" onClick={props.handleClose}>
            {t("ui:button.cancel")}
          </Button>
          <Button
            disabled={!props.inviteData.isReusable && !props.isStringEmail(props.inviteData.email ?? "")}
            variant="filled"
            onClick={props.handleInviteCreate}
          >
            {t("ui:button.invite")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default observer(EmployeeInviteDialogView);
