import React, { memo } from "react";
import { ITabsFilter } from "./tabsFilter.interface";
import { TabFilterItem } from "./tabFilterItem/tabFilterItem";

export const TabsFilter = memo((props: ITabsFilter) => {
  return (
    <div className="tabs-filter d-flex">
      {
        props.tabs.map((item) => (
          <TabFilterItem
            key={item.value ?? item.label}
            tab={item}
            activeTab={props.activeTab}
            onChange={props.onChange}
          />
        ))
      }
    </div>
  )
});
