import React, { memo, useMemo } from "react";
import { PluginsArrType } from "../ToolbarPlugin.interface";
import { useTranslation } from "react-i18next";
import { FORMAT_TEXT_COMMAND, LexicalEditor } from "lexical";
import { IS_APPLE } from "../../../utils/environment";
import { TextFormatType } from "../../../types/types";
import { LinkPopup } from "../../../ui/LinkPopup/LinkPopup";
import { IconButton } from "../../../../../uiKit";
import {
  AlignFormatType,
  BlockFormatType,
  ListFormatType,
  SelectFormatType,
} from "../FormatSelect/FormatSelect.interface";
import { FormatSelect } from "../FormatSelect/FormatSelect";
import { MentionsToolbarPopover } from "../../MentionsPlugin/MentionsToolbarPopover";
import { LucideBaseline, LucideBold, LucideItalic, LucideUnderline } from "lucide-react";
import { ColorPicker } from "../../../ui/ColorPicker/ColorPicker";

interface ICommunicationsDynamicToolbar {
  className?: string;
  activeEditor: LexicalEditor;
  // editor: LexicalEditor;
  alignType: AlignFormatType;
  listType: ListFormatType | null;
  isEditable: boolean;
  blockType: BlockFormatType | null;
  isUnderline: boolean;
  isItalic: boolean;
  isBold: boolean;
  setOpenLinkPopup: (value: boolean) => void;
  isLink: boolean;
  openLinkPopup: boolean;
  setLinkUrl: (value: string) => void;
  linkUrl: string;
  insertLink: () => void;
  activeLinkButton: boolean;
  onFontColorSelect: (value: string) => void;
  fontColor: string;
  isSimplifiedToolbar?: boolean;
  issueId?: number;
}

export const CommunicationsDynamicToolbar = memo((props: ICommunicationsDynamicToolbar) => {
  const { t } = useTranslation();

  // const formatBulletList = useCallback(() => {
  //   if (props.blockType !== BlockFormatType.BULLET) {
  //     props.activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  //   } else {
  //     props.activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  //   }
  // }, [props.blockType, props.activeEditor]);
  //
  // const formatNumberedList = useCallback(() => {
  //   if (props.blockType !== BlockFormatType.NUMBER) {
  //     props.activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  //   } else {
  //     props.activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  //   }
  // }, [props.blockType, props.activeEditor]);

  const plugins: PluginsArrType[] = useMemo(
    () => [
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            blockType={props.blockType}
            editor={props.activeEditor}
            formatType={SelectFormatType.BLOCK}
            isSimplifiedToolbar={props.isSimplifiedToolbar}
          />
        ),
        className: "ml-1",
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            editor={props.activeEditor}
            formatType={SelectFormatType.ALIGN}
            alignType={props.alignType}
            isSimplifiedToolbar={props.isSimplifiedToolbar}
          />
        ),
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            isSimplifiedToolbar
            editor={props.activeEditor}
            formatType={SelectFormatType.LIST}
            listType={props.listType}
          />
        ),
      },
      // {
      //   disabled: !props.isEditable,
      //   onClick: formatBulletList,
      //   title: t("common:text_editor_toolbar.select.bulleted_list"),
      //   icon: (
      //     <MdOutlineFormatListBulleted
      //       className={props.blockType === BlockFormatType.BULLET ? "toolbar__active" : ""}
      //     />
      //   ),
      // },
      // {
      //   disabled: !props.isEditable,
      //   onClick: formatNumberedList,
      //   title: t("common:text_editor_toolbar.select.numbered_list"),
      //   icon: (
      //     <MdOutlineFormatListNumbered
      //       className={props.blockType === BlockFormatType.NUMBER ? "toolbar__active" : ""}
      //     />
      //   ),
      // },
      {
        disabled: !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.BOLD);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.bold")} (⌘B)`
          : `${t("common:text_editor_toolbar.bold")} (Ctrl+B)`,
        icon: <LucideBold size={18} className={props.isBold ? "toolbar__active" : ""} />,
      },
      {
        disabled: !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.ITALIC);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.italic")} (⌘I)`
          : `${t("common:text_editor_toolbar.italic")} (Ctrl+I)`,
        icon: <LucideItalic size={18} className={props.isItalic ? "toolbar__active" : ""} />,
      },
      {
        disabled: !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.UNDERLINE);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.underline")} (⌘U)`
          : `${t("common:text_editor_toolbar.underline")} (Ctrl+U)`,
        icon: <LucideUnderline size={18} className={props.isUnderline ? "toolbar__active" : ""} />,
      },
      {
        component: <MentionsToolbarPopover editor={props.activeEditor} issueId={props.issueId}/>,
      },
      {
        component: (
          <ColorPicker
            disabled={!props.isEditable}
            color={props.fontColor}
            onChange={props.onFontColorSelect}
            title={t("common:text_editor_toolbar.color")}
            icon={<LucideBaseline size={18} />}
          />
        ),
      },
      {
        component: (
          <LinkPopup
            openLinkPopup={props.openLinkPopup}
            setOpenLinkPopup={props.setOpenLinkPopup}
            linkUrl={props.linkUrl}
            setLinkUrl={props.setLinkUrl}
            editor={props.activeEditor}
            isEditable={props.isEditable}
            isLink={props.isLink}
            activeLinkButton={props.activeLinkButton}
            insertLink={props.insertLink}
          />
        ),
      },
    ],
    [props, t]
  );

  return (
    <div className={`toolbar ${props.className ?? ""}`}>
      <div className="d-stack flex-wrap spacing-1">
        {plugins.map((p, i) =>
          p.component ? (
            <div className={`${p.className} d-flex align-center`} key={i}>
              {p.component}
            </div>
          ) : (
            <div key={i} className="d-flex align-center">
              <IconButton
                disabled={p.disabled}
                onClick={p.onClick}
                title={p.title}
                icon={p.icon}
                className={p.className}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
});
