import React from "react";
import { Radio, RadioGroupProps } from "antd";
import "./RadioGroup.scss";
import clsx from "clsx";

interface IRadioGroupView extends RadioGroupProps {
  //
}

const { Group } = Radio;

export function RadioGroupView({ className, ...rest }: IRadioGroupView) {
  return <Group className={clsx("uiKit-radioGroup", className ?? "")} {...rest} />;
}
