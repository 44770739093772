import React, { useEffect } from "react";
import { LocalStorageHelpers, useRootStore } from "../../../hooks";
import { MainPage } from "../../modules/pages/groupCompany/containers/mainPage/MainPage";
import { useParams } from "react-router-dom";
import { GroupCompanyPage } from "../../modules/pages/groupCompany/containers/groupCompanyPage/GroupCompanyPage";

const GroupCompany = (props: { addBot?: boolean }) => {
  const { groupCompanyStore } = useRootStore();

  const { id } = useParams();

  const zoomInitValue: number =
    typeof LocalStorageHelpers.get("communicationTableZoom") == "number"
      ? LocalStorageHelpers.get("communicationTableZoom") ?? 1
      : 1;

  useEffect(() => {
    groupCompanyStore.setZoom(zoomInitValue);
  }, []);

  return !!id ? <GroupCompanyPage addBot={props.addBot} id={Number(id)} /> : <MainPage />;
};

export default GroupCompany;
