import React from "react";
import "./GroupCompanyWrapper.scss";
import { IGroupCompanyWrapper } from "./GroupCompanyWrapper.interface";
import { useTranslation } from "react-i18next";
import GroupCompanyStats from "../../components/GroupCompanyStats/GroupCompanyStats";
import { GroupCompanyInfo } from "../../components/GroupCompanyInfo/GroupCompanyInfo";

export const GroupCompanyWrapper = (props: IGroupCompanyWrapper) => {
  const { t } = useTranslation();

  return (
    <div className="group-company-page__wrapper">
      <GroupCompanyInfo
        companies={props.companyGroup.companies ?? undefined}
        userTypes={props.companyGroup.userTypes ?? undefined}
        onDeleteCompanyById={props.onDeleteCompanyById}
        onDeleteEmployeeById={props.onDeleteEmployeeById}
        onLoadGroupCompanyId={props.onLoadGroupCompanyId}
        onOpenAddEmployeeDialog={props.onOpenAddEmployeeDialog}
        onOpenAddCompanyToGroupDialog={props.onOpenAddCompanyToGroupDialog}
      />
      <GroupCompanyStats
        dateButtonText={props.dateButtonText}
        onOpenSelectPeriodDialog={props.onOpenSelectPeriodDialog}
        groupStats={props.groupStats}
      />
    </div>
  );
};
