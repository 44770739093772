import React from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { Link, Title } from "../../uiKit";
import { useTranslation } from "react-i18next";

export const PrivacyCookiePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContent>
        <div className="pa-2 d-stack-column spacing-3">
          <Title children={t("common:menu.top_bar_profile.cookie")} level={4} />
          <div className="d-stack-column spacing-1">
            <Link
              href="/info/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              children={t("parse:privacy_policy")}
            />
            <Link
              href="/info/cookie-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              children={t("parse:cookie_policy")}
            />
          </div>
        </div>
      </PageContent>
    </>
  );
};
