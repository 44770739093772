const pad = (hash: string, len: number): string => {
  while (hash.length < len) {
    hash = "0" + hash;
  }
  return hash;
};

const fold = (hash: number, text: string): number => {
  let i, chr, len;
  if (text.length === 0) {
    return hash;
  }
  for (i = 0, len = text.length; i < len; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash < 0 ? hash * -2 : hash;
};

const foldObject = (hash: number, o: any, seen: boolean) => {
  const foldKey = (hash: number, key: string) => {
    return foldValue(hash, o[key], key, seen);
  };
  return Object.keys(o).sort().reduce(foldKey, hash);
};

const foldValue = (input: any, value: any, key: string, seen: any) => {
  let hash = fold(fold(fold(input, key), toString(value)), typeof value);
  if (value === null) {
    return fold(hash, "null");
  }
  if (value === undefined) {
    return fold(hash, "undefined");
  }
  if (typeof value === "object" || typeof value === "function") {
    if (seen.indexOf(value) !== -1) {
      return fold(hash, "[Circular]" + key);
    }
    seen.push(value);

    let objHash: any = foldObject(hash, value, seen);

    if (!("valueOf" in value) || typeof value.valueOf !== "function") {
      return objHash;
    }

    try {
      return fold(objHash, String(value.valueOf()));
    } catch (err) {
      // @ts-ignore
      return fold(objHash, "[valueOf exception]" + (err?.stack || err?.message));
    }
  }
  return fold(hash, value.toString());
};

const toString = (o: any) => {
  return Object.prototype.toString.call(o);
};

const sum = (o: any) => {
  return pad(foldValue(0, o, "", []).toString(16), 8);
};

export const useHash = () => {
  return {
    hashFn: sum,
  };
};
