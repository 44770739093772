import React, { useState } from "react";
import { Divider, Icon, IconButton, Text } from "../../../../uiKit";
import { LucideMinusSquare, LucidePlusSquare } from "lucide-react";
import "./OrgchartVfpCard.scss";
import { ORGCHART_VFP_FONT_SIZES } from "./OrgchartVfpCard";
import { useClaims } from "../../../../../hooks";
import { permissionKeys } from "../../../../../utils/permissions";

export interface IOrgchartVfpCardViewState {
  text: string;
  fontSize: typeof ORGCHART_VFP_FONT_SIZES[number];
}

interface IOrgchartVfpCardView {
  state: IOrgchartVfpCardViewState;
  onStateChange: (state: IOrgchartVfpCardViewState) => void;
  isHiddenPrintBlock?: boolean
  // onUpdate: () => void;
  // value?: string;
  // fontSize?: typeof ORGCHART_VFP_FONT_SIZES[number];
  // onValueChange?: () => void;
  // onFontSizeChange?: (value: typeof ORGCHART_VFP_FONT_SIZES[number]) => void;
}

export const OrgchartVfpCardView = (props: IOrgchartVfpCardView) => {
  const claims = useClaims();

  const [isEditing, setIsEditing] = useState(false);

  const getFontSize = (
    currentFontSize: typeof ORGCHART_VFP_FONT_SIZES[number],
    increase: boolean
  ): typeof ORGCHART_VFP_FONT_SIZES[number] => {
    const currentFontSizePosition = ORGCHART_VFP_FONT_SIZES.findIndex((s) => s == currentFontSize);
    if (increase && currentFontSizePosition == ORGCHART_VFP_FONT_SIZES.length - 1) return currentFontSize;
    if (!increase && currentFontSizePosition == 0) return currentFontSize;

    return increase
      ? ORGCHART_VFP_FONT_SIZES[currentFontSizePosition + 1]
      : ORGCHART_VFP_FONT_SIZES[currentFontSizePosition - 1];
  };

  return !claims.has(permissionKeys.orgchart.edit) && props.state.text.trim().length == 0 ? null : (
    <div
      className={`pa-1 mb-1 d-inline-block full-width __orgchart-vfp-block __pztZoom ${
        props.isHiddenPrintBlock ? 'printHidden' : ''
      }`}
      style={{ position: "sticky", left: 0 }}
    >
      <div
        className="br-md pa-3 d-stack-row spacing-1 align-start"
        style={{ background: "var(--color-layout-divider)" }}
      >
        <div className="flex-grow-1">
          <Text
            className="ma-0 full-width"
            style={{ inset: 0, fontSize: `${isEditing ? 14 : props.state.fontSize ?? 14}px` }}
            children={props.state.text}
            editable={
              claims.has(permissionKeys.orgchart.edit)
                ? {
                    triggerType: ["icon", "text"],
                    editing: isEditing,
                    onChange: (val) => props.onStateChange({ ...props.state, text: val }),
                    onStart: () => setIsEditing(true),
                    onEnd: () => setIsEditing(false),
                    onCancel: () => setIsEditing(false),
                  }
                : false
            }
          />
        </div>
        {!isEditing && claims.has(permissionKeys.orgchart.edit) && props.state.text.trim().length > 0 && (
          <div className="d-flex align-stretch ml-2 __font-size_buttons">
            <IconButton
              disabled={props.state.fontSize == ORGCHART_VFP_FONT_SIZES[0]}
              style={{ borderRadius: "6px 0 0 6px" }}
              icon={<Icon component={() => <LucideMinusSquare color="var(--color-text-weak)" size="16px" />} />}
              size="small"
              onClick={() =>
                props.onStateChange?.({ ...props.state, fontSize: getFontSize(props.state.fontSize, false) })
              }
            />
            <Divider className="my-1" isVertical />
            <IconButton
              disabled={props.state.fontSize == ORGCHART_VFP_FONT_SIZES[ORGCHART_VFP_FONT_SIZES.length - 1]}
              style={{ borderRadius: "0 6px 6px 0" }}
              size="small"
              icon={<Icon component={() => <LucidePlusSquare color="var(--color-text-weak)" size="16px" />} />}
              onClick={() =>
                props.onStateChange?.({
                  ...props.state,
                  fontSize: getFontSize(props.state.fontSize, true),
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
