import React from "react";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../../../hooks";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import { Text } from "../../../../../uiKit";
import { ISectionContentHeaderCreator } from "./SectionContentHeader.interface";

export const SectionContentHeaderCreator: React.FC<ISectionContentHeaderCreator> = (props) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();

  const dateCreated = formatDate(new Date(props.dateCreated!).toString(), {
    formatObject: {
      hour: undefined,
      minute: undefined,
    },
  });

  return (
    <>
      {props.creator && (
        // <div className="d-stack-column spacing-6">
          <UserCard
            avatarSize={32}
            boldName
            additionalInfo={`${t("parse:created_in")} ${dateCreated}`}
            userInfo={props.creator}
          />
        //<Text size="18px" weight={500} children={t("ui:title.policies")} />
        // </div>
      )}
    </>
  );
};
