import React, { memo } from "react";
import { MetricSettingsDialogView } from "./MetricSettingsDialogView";
import { TChartSettings } from "../../pages/dashboards/components/Metric2Dashboard/MetricToDashboard";

interface IMetricSettings {
  open: boolean;
  title: string;
  onSuccess: () => void;
  onOpen: (isOpen: boolean) => void;
  chartSettings: TChartSettings;
  // chartSettingsFromLS: TChartSettings;
  onChartSettingsChange: (value: TChartSettings) => void;
}

export const MetricSettingsDialog = memo((props: IMetricSettings) => {
  return <MetricSettingsDialogView {...props} />;
});
