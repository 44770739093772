import React, { useMemo } from "react";
import { IListUsersDialog } from "./ListUsersDialog.interface";
import { EmptyBlock } from "../../layouts/emptyBlock/EmptyBlock";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../../../elements/userInfo/UserInfo";
import "./ListUsersDialog.scss";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";

export const ListUsersDialogView: React.FC<IListUsersDialog> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={props.title}
      closable={false}
      className={"list-users-dialog"}
    >
      {props.users?.length === 0 ? (
        <EmptyBlock />
      ) : (
        <>
          <div className="d-stack-column  list-users align-start">
            {props.users?.map((user) => (
              <UserInfo
                key={user.id}
                nameStyle={{ fontWeight: "bold" }}
                avatarSize={25}
                user={user}
              />
            ))}
          </div>
          <ScrollTrigger
            disabled={props.isDone}
            onIntersection={props.loadNext}
            hidden={props.isDone}
            marginTop
            fullWidth
          />
        </>
      )}
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
