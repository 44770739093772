import React from "react";
import { Layout, SiderProps } from "antd";
import "./LayoutSider.scss";
import clsx from "clsx";

interface ILayoutSiderView extends SiderProps {
  //
}

const { Sider } = Layout;

export function LayoutSiderView({ className, ...rest }: ILayoutSiderView) {
  return <Sider className={clsx("uiKit-layoutSider", className ?? "")} {...rest} />;
}
