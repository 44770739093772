import React from "react";
import { Button, Collapse, CollapsePanel, Text } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { IDriverTextCollapse } from "./DriverTextCollapse.interface";

export const DriverTextCollapse = (props: IDriverTextCollapse) => {
  const { t } = useTranslation();

  return (
    <Collapse>
      <CollapsePanel key={1} header={t("ui:title.driver_script_title")}>
        <Text style={{ whiteSpace: "pre-wrap" }} children={props.text?.trim()} />
        <div className="mt-2 d-flex align-center justify-space-between">
          <div />
          <Button onClick={() => props.onCopy(props.text?.trim() ?? "")} variant="filled">
            {t("ui:button.copy")}
          </Button>
        </div>
      </CollapsePanel>
    </Collapse>
  );
};
