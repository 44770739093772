import "./NotificationCenterCard.scss";
import { NotificationDto } from "../../../../api";
import { Avatar, Card, IconButton, Text, Tooltip } from "../../../uiKit";
import { removeEmojiFromString } from "../../../../helpers/stringFunctions";
import { useDateHelpers, useRootStore } from "../../../../hooks";
import { LucideMail, LucideMailOpen } from "lucide-react";
import { TextEditor } from "../../../modules/textEditor/TextEditor";
import { initialEditorContent } from "../../../../utils/textEditor";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { If } from "../../conditionStatement";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useUserNameFormat } from "../../../../hooks/useUserNameFormat";

interface INotificationCenterCardProps {
  data: NotificationDto;
  isIsReadStatusInverted: boolean;
  isLoading: boolean;

  onNotificationClick: () => void;
  onMarkAsReadUnreadClick: () => void;
}

const NotificationCenterCardObserved = (props: INotificationCenterCardProps) => {
  const { t } = useTranslation();
  const { formatDate } = useDateHelpers();
  const { authStore } = useRootStore();
  const { getUserShortFullName, getAvatarText } = useUserNameFormat();

  const [isShowDescription, setIsShowDescription] = useState<boolean>(true);

  useEffect(() => {
    if (props.data.contentDto?.description?.startsWith(`{"root":`)) {
      const data = JSON.parse(props.data?.contentDto?.description);
      const [firstChildren = {} ] = data.root?.children || [];
      setIsShowDescription(!!firstChildren.children?.length ?? false);
    } else {
      setIsShowDescription(true);
    }

  }, [props]);

  const notificationName = useMemo(() => {
    const { importance, notificationType, sender } = props.data;
    const senderName = sender
      ? getUserShortFullName(
        sender.lastName ?? "",
        sender.firstName ?? "",
        sender.middleName ?? ""
      ) ?? t("ui:placeholder.new_employee", {id: sender?.id ?? 0}) ?? ""
      : "";
    const notificationKey = notificationType === "IssueAdded"
      ? `${notificationType}_${importance}`
      : notificationType;
    return t(`ui:notification_center.name.${notificationKey}`, {senderName});
  }, [props.data])

  return (
    <div
      className={clsx(`notification-center-card__wrapper`, {
        __readonly: authStore.getCurrentCompanyId != props.data.company?.id,
      })}
    >
      <Card
        className="br-none"
        clickable
        isShadowed={false}
        bodyStyle={{ padding: 0 }}
        bordered={false}
        onClick={authStore.getCurrentCompanyId != props.data.company?.id ? undefined : props.onNotificationClick}
      >
        <div className="d-stack spacing-4 py-4 px-4">
          <div className="flex-shrink-0">
            <Avatar
              color={props.data.sender?.color}
              text={getAvatarText(props.data.sender?.lastName ?? "", props.data.sender?.firstName ?? "")}
              src={props.data.sender?.avatar?.image?.url}
              type="user"
            />
          </div>
          <div className="flex-grow-1 d-stack-column spacing-2">
            <div className="d-flex flex-nowrap">
              <div className="d-stack spacing-2 flex-grow-1">
                <Text>
                  {/*${props.data.sender?.nameShort}*/}
                  <Text weight="bold" children={notificationName} />
                  <Text style={{ visibility: "hidden", opacity: 0 }} children="–" />
                  <Text
                    size="12px"
                    type="secondary"
                    children={props.data.dateCreated != null ? formatDate(props.data.dateCreated) : null}
                  />
                </Text>
              </div>
              <div
                className="flex-shrink-0 ml-2"
                style={{ alignSelf: "baseline", transform: "translateX(4px)", height: "24px", width: "24px" }}
              >
                <Tooltip
                  title={
                    props.data.isRead !== props.isIsReadStatusInverted
                      ? t("parse:mark_as_unread")
                      : t("parse:mark_as_read")
                  }
                  placement="left"
                >
                  <IconButton
                    size="small"
                    type="link"
                    loading={props.isLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onMarkAsReadUnreadClick();
                    }}
                  >
                    {props.data.isRead !== props.isIsReadStatusInverted ? (
                      <LucideMailOpen color="var(--color-text-weaker)" size={16} />
                    ) : (
                      <LucideMail size={16} />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <If condition={props.data.additionalParams?.entity == "issue"}>
              <Text>
                <Text
                  size="12px"
                  weight="bold"
                  type="secondary"
                  children={`#${props.data.additionalParams?.issueSubId ?? ""}`}
                />
                <Text size="12px" weight="bold" type="secondary" children=" • " />
                <Text
                  size="12px"
                  weight="bold"
                  type="secondary"
                  children={
                    props.data.additionalParams?.issueStatusName
                      ? t(`ui:notification_center.issue_status.${props.data.additionalParams?.issueStatusName}`)
                      : ""
                  }
                />
                <Text size="12px" weight="bold" type="secondary" children=" • " />
                <Text size="12px" children={props.data.additionalParams?.issueName ?? ""} />
              </Text>
            </If>

            <If condition={props.data.additionalParams?.entity == "regulation"}>
              <Text>
                <Text
                  size="12px"
                  weight="bold"
                  type="secondary"
                  children={props.data.additionalParams?.regulationName ?? ""}
                />
              </Text>
            </If>

            {isShowDescription && props.data.contentDto?.showDescription && (props.data.contentDto?.description ?? "").trim().length > 0 && (
              <div
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  position: "relative",
                  overflow: "hidden",
                  pointerEvents: "none",
                  border: "1px solid var(--color-layout-divider)",
                }}
              >
                {props.data.contentDto?.description?.startsWith(`{"root":`) ? (
                  <TextEditor
                    id={`notifications-center-card-${props.data.id}`}
                    readOnly
                    value={props.data.contentDto?.description}
                    initialValue={initialEditorContent}
                    withoutBackground
                  />
                ) : (
                  <Text size="14px" children={props.data.contentDto?.description?.trim()} />
                )}
              </div>
            )}

            <div className="d-stack spacing-2 align-center justify-end">
              <Text
                size="12px"
                weight="bold"
                type="secondary"
                children={props.data.company?.name?.trim() || props.data.company?.nameFallback}
              />
              <Avatar
                color={props.data.company?.color}
                text={props.data.company?.name?.trim()}
                src={props.data.company?.image?.url}
                size={20}
                type="user"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export const NotificationCenterCard = observer(NotificationCenterCardObserved);
