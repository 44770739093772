import React from "react";
import AdminDriverPeriodSelectDialogView from "./AdminDriverPeriodSelectDialogView";

interface IAdminDriverPeriodSelectDialog {
  open: boolean;
  value:
    | {
        from: string;
        to: string;
      }
    | undefined;
  allowNull?: boolean;
  onChange: (value: { from: string; to: string }) => void;
  onSave: () => void;
  onClose: () => void;
  disableFuture?: boolean;
}

function AdminDriverPeriodSelectDialog(props: IAdminDriverPeriodSelectDialog) {
  return (
    <AdminDriverPeriodSelectDialogView
      value={props.value}
      open={props.open}
      allowNull={props.allowNull}
      onClose={props.onClose}
      onChange={props.onChange}
      onSave={props.onSave}
      disableFuture={props.disableFuture}
    />
  );
}

export default AdminDriverPeriodSelectDialog;
