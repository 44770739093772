import React, { useEffect } from "react";
import ZoomButtonView from "./ZoomButtonView";

export interface IZoomButton {
  value: number;
  step?: number;
  min?: number;
  max?: number;
  valueToReset?: number;
  buttonProps?: {
    text?: {
      afterValue?: string;
    };
  };

  onChange(value: number): void;
}

function ZoomButton(props: IZoomButton) {
  const min = props.min ?? 70;
  const max = props.max ?? 120;
  const step = props.step ?? 10;


  const handleSetZoomValue = (value: number) => {
    props.onChange(value);
  };

  const handleChange = (value: number) => {
    const newValue = props.value + value;
    handleSetZoomValue(newValue);
  };

  const handleReset = () => {
    props.onChange(props.valueToReset ?? 100);
  };

  useEffect(() => {
    if (props.value > max) {
      props.onChange(max);
    } else if (props.value < min) {
      props.onChange(min);
    }
  }, [props.value]);

  return (
    <ZoomButtonView
      {...props}
      min={min}
      max={max}
      step={step}
      handleChange={handleChange}
      handleReset={handleReset}
      handleSetZoomValue={handleSetZoomValue}
    />
  );
}

export default ZoomButton;
