import React from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Empty, IconButton } from "../../../uiKit";
import { IIssuesArchiveView } from "./IssuesArchive.interface";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { CardIssue } from "../../pages/communications/components/cardIssue/CardIssue";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";
import { IssuesArchiveHeader } from "./issuesArchiveHeader/IssuesArchiveHeader";
import "./IssuesArchive.scss";

export const IssuesArchiveView = (props: IIssuesArchiveView) => {
  const { t } = useTranslation();

  return (
    <Drawer
      width={400}
      headerStyle={{ padding: "12px 20px" }}
      title={t("ui:title.archvive_of_issues")}
      classNames={{ body: "issues-archive" }}
      closable={false}
      extra={
        <IconButton size="large" onClick={props.onClose}>
          <FiX />
        </IconButton>
      }
      placement="right"
      open={props.isOpen}
      onClose={props.onClose}
    >
      <IssuesArchiveHeader
        searchValue={props.name ?? ""}
        changeSearch={props.onChangeName}
        orgchartId={props.orgchartId}
        onChangeOrgchartId={props.onChangeOrgchartId}
        senderId={props.senderId}
        onChangeSenderId={props.onChangeSenderId}
        executorId={props.executorId}
        onChangeExecutorId={props.onChangeExecutorId}
        isStatusNotDone={props.isStatusNotDone}
        onChangeStatusNotDone={props.onChangeStatusNotDone}
        sort={props.sort}
        onChangeSort={props.onChangeSort}
      />
      <div className="issues-archive-wrap">
        <div className="issues-archive-content">
          {props.issues?.map((issue, index) => (
            <CardIssue noDrag key={issue.id} issue={issue} />
          ))}
          {props.isDone && !props.issues?.length && <Empty style={{ height: "80%" }} />}
          <div style={{ width: "100%" }}>
            <ScrollTrigger
              disabled={props.isDone}
              onIntersection={props.loadNext}
              marginTop={(props.issues ?? []).length > 0}
              hidden={props.isDone}
            />
          </div>
        </div>
      </div>
    </Drawer>
);
};
