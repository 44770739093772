import React from "react";
import { Layout, theme } from "antd";
import "./LayoutHeader.scss";
import clsx from "clsx";

interface ILayoutHeaderView {
  className?: string;
  children?: React.ReactNode;
}

const { Header } = Layout;
const { useToken } = theme;

export function LayoutHeaderView({ className, ...rest }: ILayoutHeaderView) {
  const { token } = useToken();

  return (
    <Header
      style={{ background: token.colorBgContainer }}
      className={clsx("uiKit-layoutHeader", className ?? "")}
      {...rest}
    />
  );
}
