import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { DayOfWeek, MetricGraphPeriodDto, MetricReadDto, MetricSourceDto } from "../../../../../../api";
import { MetricToDashboardView } from "./MetricToDashboardView";
import { useTranslation } from "react-i18next";
import { TDashboardChartDataItem } from "../../dashboardChart/DashboardChart";
import { truncateString } from "../../../../../../helpers/stringFunctions";
import { Text } from "../../../../../uiKit";
import { removeDuplicatesFromArrayByKey } from "../../../../../../helpers/arrayFunctions";
import { api } from "../../../../../../services";

interface IMetricToDashboard {
  metric: MetricReadDto;
  metricSource2UserId?: number;
  deleteMetric: (id: number) => void;
  handleRestartLoad?: () => void;
  weekReportStart?: DayOfWeek;
}

export type TChartSettings = {
  min: number | null;
  max: number | null;
  isTrendActive: boolean;
  isLinesColorActive: boolean;
  isCompany: boolean;
};

type TMetricTooltipDataObj = {
  source: string;
  filler: string;
};

export enum DropdownKeys {
  SETTINGS = "settings",
  DELETE = "delete",
  DATA = "data",
}

export const MetricToDashboard = memo((props: IMetricToDashboard) => {
  const generateChartData = (metric: MetricReadDto): TDashboardChartDataItem[] => {
    const graphPeriodsArray: MetricGraphPeriodDto[] = removeDuplicatesFromArrayByKey(
      (metric.graphs?.map((g) => g.periods)?.flat() ?? []) as MetricGraphPeriodDto[],
      "label"
    )
      .filter((g: MetricGraphPeriodDto) => g.date != null)
      .sort(function (a: MetricGraphPeriodDto, b: MetricGraphPeriodDto) {
        return a.date! < b.date! ? -1 : a.date! > b.date! ? 1 : 0;
      });

    return graphPeriodsArray.map((period) => {
      return {
        id: Number(new Date(period.date!).getTime()),
        // id: Number(new Date(p.date!).getDate() + new Date(p.date!).getMonth() + new Date(p.date!).getFullYear()),
        dateISO: period.date as string,
        label: period.label as string,
        charts: {
          quota:
            props.metricSource2UserId != null
              ? metric.graphs
                  ?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                  ?.periods?.find((_p) => _p.label == period.label)?.quota?.quota ?? null
              : null,
          line:
            props.metricSource2UserId != null
              ? [
                  {
                    id: metric.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                      ?.metricSource2UserId!,
                    name: metric.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.name ?? "",
                    value:
                      metric.graphs
                        ?.find((g) => g.metricSource2UserId == props.metricSource2UserId)
                        ?.periods?.find((_p) => _p.label == period.label)?.value?.value ?? null,
                  },
                ]
              : props.metric.graphs?.map((g) => ({
                  id: g.metricSource2UserId!,
                  name: g.name ?? "",
                  value: g.periods?.find((_p) => _p.label == period.label)?.value?.value ?? null,
                })) ?? [],
        },
      };
    });
  };

  const generateChartSettings = (metric: MetricReadDto): TChartSettings => {
    return {
      min:
        metric.renderType === 0
          ? metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.settings?.yAxisMin ?? null
          : metric.settings?.yAxisMin ?? null,
      max:
        metric.renderType === 0
          ? metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.settings?.yAxisMax ?? null
          : metric.settings?.yAxisMax ?? null,
      isTrendActive:
        metric.renderType === 0
          ? metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.settings?.isTrendActive ??
            false
          : metric.settings?.isTrendActive ?? false,
      isLinesColorActive:
        metric.renderType === 0
          ? metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.settings
              ?.isLinesColorActive ?? true
          : metric.settings?.isLinesColorActive ?? true,
      isCompany:
        metric.renderType === 0
          ? metric?.graphs?.find((g) => g.metricSource2UserId == props.metricSource2UserId)?.settings
          ?.isCompany ?? false
          : metric.settings?.isCompany ?? false,
    };
  };

  const { t } = useTranslation();
  const [dropdownKey, setDropdownKey] = useState<DropdownKeys | undefined>(undefined);
  const [openDeleteMetricDialog, setOpenDeleteMetricDialog] = useState<boolean>(false);
  const [openMetricDataDialog, setOpenMetricDataDialog] = useState<boolean>(false);
  const [openMetricSettingsDialog, setOpenMetricSettingsDialog] = useState<boolean>(false);
  const [chartSettingsFromServer, setChartSettingsFromServer] = useState<TChartSettings>({
    min: null,
    max: null,
    isTrendActive: false,
    isLinesColorActive: true,
    isCompany: false,
  });
  const [chartSettingsLocal, setChartSettingsLocal] = useState<TChartSettings>({
    min: null,
    max: null,
    isTrendActive: false,
    isLinesColorActive: true,
    isCompany: false,
  });

  const [chartData, setChartData] = useState<TDashboardChartDataItem[]>(generateChartData(props.metric));

  const lookup = useMemo(
    () =>
      props.metric?.sources?.reduce((a: { [key: number]: number }, e: MetricSourceDto) => {
        if (e.metricId != undefined) {
          a[e.metricId] = ++a[e.metricId] || 0;
        }
        return a;
      }, {}),
    [props.metric?.sources]
  );

  // Отфильтрованный массив sources по metricId (источник 1 и 2)
  const filteredSourcesByMetricId = useMemo(
    () =>
      props.metric?.sources?.length == 1
        ? props.metric?.sources
        : props.metric?.sources?.filter((e) => e.metricId && lookup?.[e.metricId]),
    [lookup, props.metric?.sources]
  );

  const handleGetSourceByOrder = useCallback(
    (order: number) => {
      return filteredSourcesByMetricId?.find((s) => s.order == order);
    },
    [filteredSourcesByMetricId]
  );

  const generateSourceString = useCallback(
    (source: MetricSourceDto | undefined) => {
      return source?.existingSourceId
        ? [
            source?.metric?.name,
            source?.metric?.rolePosition?.role?.name,
            source?.metric?.rolePosition?.name,
            props.metric.renderType == 1
              ? ""
              : props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)?.user2Role?.user
                  ?.nameShort ??
                props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)
                  ?.metricSource2UserId,
          ]
            .filter((s) => s != null && s.toString().length > 0)
            .join(" - ")
        : t("ui:select.manual_method");
    },
    [props.metricSource2UserId, props.metric?.graphs, props.metric.renderType, t]
  );

  const generateFillerString = useCallback(
    (source: MetricSourceDto | undefined, severalFillers?: boolean) => {
      const _s =
        severalFillers === false || source?.existingSourceId == null
          ? source
          : props.metric.sources?.find((s) => s.id == source?.existingSourceId);
      return [
        _s?.fillerRolePosition?.role?.name,
        _s?.fillerRolePosition?.name,
        props.metric.renderType == 1
          ? ""
          : props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)?.user2Role?.user
              ?.nameShort ??
            props.metric?.graphs?.find((c) => c.metricSource2UserId == props.metricSource2UserId)?.metricSource2UserId,
      ]
        .filter((s) => s != null && s.toString().length > 0)
        .join(" - ");
    },
    [props.metricSource2UserId, props.metric?.graphs, props.metric.renderType, props.metric.sources]
  );

  const generatePopoverDataObj = useCallback((): TMetricTooltipDataObj[] => {
    const hasMetricSeveralFillers = filteredSourcesByMetricId?.length == 2;
    // const hasMetricSeveralFillers = props.metric.sources?.some((s1) =>
    //   props.metric.sources?.some((s2) => s2.metricId == s1.metricId && s2.id != s1.id)
    // );
    // return {
    //   source: hasMetricSeveralFillers
    //     ? [generateSourceString(handleGetSourceByOrder(0)), generateSourceString(handleGetSourceByOrder(1))]
    //     : [generateSourceString(handleGetSourceByOrder(0))],
    //   filler: hasMetricSeveralFillers
    //     ? [generateFillerString(handleGetSourceByOrder(0)), generateFillerString(handleGetSourceByOrder(1))]
    //     : [generateFillerString(handleGetSourceByOrder(0), false)],
    // };
    return hasMetricSeveralFillers
      ? [
          {
            source: generateSourceString(handleGetSourceByOrder(0)),
            filler: generateFillerString(handleGetSourceByOrder(0)),
          },
          {
            source: generateSourceString(handleGetSourceByOrder(1)),
            filler: generateFillerString(handleGetSourceByOrder(1)),
          },
        ]
      : [
          {
            source: generateSourceString(handleGetSourceByOrder(0)),
            filler: generateFillerString(handleGetSourceByOrder(0), false),
          },
        ];
  }, [filteredSourcesByMetricId?.length, generateFillerString, generateSourceString, handleGetSourceByOrder]);

  const popoverContent = useMemo(
    () => (
      <div className="d-stack-column spacing-1" style={{ width: "360px", wordWrap: "break-word" }}>
        <Text weight="bold" size="12px" children={truncateString(props.metric.nameFormatted ?? "", 50, "end")} />
        {(props?.metric?.description ?? "").trim().length > 0 && (
          <Text
            className={"dashboard-metric-description"}
            size="12px"
            children={props?.metric?.description}
          />
        )}
        <div className="d-stack-column spacing-0">
          <Text size="12px" type="secondary" children={t("ui:subheader.metric_responsible")} />
          <Text
            size="12px"
            children={`${props.metric.rolePosition?.role?.name} - ${props.metric.rolePosition?.name}`}
          />
        </div>
        {generatePopoverDataObj().map((data, index) => (
          <div className="d-stack-column spacing-1" key={index}>
            <div className="d-stack-column spacing-0">
              <Text
                size="12px"
                type="secondary"
                children={`${t("ui:subheader.metric_source")}${
                  filteredSourcesByMetricId?.length == 2 ? " " + (index + 1) : ""
                }`}
              />
              <Text size="12px" children={data.source} />
            </div>
            <div className="d-stack-column spacing-0">
              <Text
                size="12px"
                type="secondary"
                children={`${t("ui:subheader.metric_filling")}${
                  filteredSourcesByMetricId?.length == 2 ? " " + (index + 1) : ""
                }`}
              />
              <Text size="12px" children={data.filler} />
            </div>
          </div>
        ))}
      </div>
    ),
    [
      filteredSourcesByMetricId?.length,
      generatePopoverDataObj,
      props.metric?.description,
      props.metric.nameFormatted,
      props.metric.rolePosition?.name,
      props.metric.rolePosition?.role?.name,
      t,
    ]
  );

  const handleDropdownKeyChange = useCallback((key: DropdownKeys) => {
    setDropdownKey(key);
  }, []);

  const handleOpenDeleteMetricDialogChange = useCallback((isOpen: boolean) => {
    if (!isOpen) setDropdownKey(undefined);
    setOpenDeleteMetricDialog(isOpen);
  }, []);

  const handleOpenMetricDataDialogChange = useCallback((isOpen: boolean) => {
    if (!isOpen) setDropdownKey(undefined);
    setOpenMetricDataDialog(isOpen);
  }, []);

  const handleOpenMetricSettingsDialogChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setDropdownKey(undefined);
        setChartSettingsLocal(chartSettingsFromServer);
      }
      setOpenMetricSettingsDialog(isOpen);
    },
    [chartSettingsFromServer]
  );

  const handleSuccessSettingsDialog = useCallback(async () => {
    await api.metric.updateYAxisValues({
      metricId: props.metric.renderType === 0 ? null : props.metric.id,
      metricSource2UserId: props.metric.renderType === 0 ? props.metricSource2UserId : null,
      yAxisMin: chartSettingsLocal.min,
      yAxisMax: chartSettingsLocal.max,
      isTrendActive: chartSettingsLocal.isTrendActive,
      isLinesColorActive: chartSettingsLocal.isLinesColorActive,
      isCompany: chartSettingsLocal.isCompany,
    });
    const m = await api.metricRead.getById(props.metric.id!);
    if (m) {
      setChartSettingsFromServer(generateChartSettings(m));
      setChartSettingsLocal(generateChartSettings(m));
    }
    setOpenMetricSettingsDialog(false);
  }, [
    chartSettingsLocal.isLinesColorActive,
    chartSettingsLocal.isTrendActive,
    chartSettingsLocal.max,
    chartSettingsLocal.min,
    chartSettingsLocal.isCompany,
    generateChartSettings,
    props.metric.id,
    props.metric.renderType,
    props.metricSource2UserId,
  ]);

  const handleChartSettingsLocalChange = useCallback((value: TChartSettings) => {
    setChartSettingsLocal(value);
  }, []);

  useEffect(() => {
    if (dropdownKey == DropdownKeys.SETTINGS) {
      handleOpenMetricSettingsDialogChange(true);
    }
    if (dropdownKey == DropdownKeys.DELETE) {
      handleOpenDeleteMetricDialogChange(true);
    }
    if (dropdownKey == DropdownKeys.DATA) {
      handleOpenMetricDataDialogChange(true);
    }
  }, [
    dropdownKey,
    handleOpenDeleteMetricDialogChange,
    handleOpenMetricDataDialogChange,
    handleOpenMetricSettingsDialogChange,
  ]);

  useEffect(() => {
    setChartSettingsFromServer(generateChartSettings(props.metric));
    setChartSettingsLocal(generateChartSettings(props.metric));
  }, [props.metric]);

  useEffect(() => {
    setChartData(generateChartData(props.metric));
  }, [props.metric]);

  return (
    <MetricToDashboardView
      chartData={chartData}
      metric={props.metric}
      metricSource2UserId={props.metricSource2UserId}
      openDeleteMetricDialog={openDeleteMetricDialog}
      openMetricSettingsDialog={openMetricSettingsDialog}
      openMetricDataDialog={openMetricDataDialog}
      popoverContent={popoverContent}
      chartSettings={chartSettingsFromServer}
      chartSettingsLocal={chartSettingsLocal}
      onChartSettingsLocalChange={handleChartSettingsLocalChange}
      deleteMetric={props.deleteMetric}
      handleRestartLoad={props.handleRestartLoad}
      onDropdownKeyChange={handleDropdownKeyChange}
      onOpenDeleteMetricDialogChange={handleOpenDeleteMetricDialogChange}
      onOpenMetricSettingsDialogChange={handleOpenMetricSettingsDialogChange}
      onOpenMetricDataDialogChange={handleOpenMetricDataDialogChange}
      onSuccess={handleSuccessSettingsDialog}
      onChartDataChange={setChartData}
    />
  );
});
