import React, { memo, useCallback, useEffect, useState } from "react";
import { Tooltip } from "../../../../../../uiKit";
import { SidebarTreeControls } from "../../sidebarTreeControls/SidebarTreeControls";
import { IRegulationSidebarTreeItem } from "./RegulationSidebarTreeItem.interface";
import { useRootStore } from "../../../../../../../hooks";
import { defineAllow } from "../../../containers/regulation/helpers";
import { allowsRegulationKeys } from "../../../misc/consts";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export const RegulationSidebarTreeItem = memo((props: IRegulationSidebarTreeItem) => {
  const {
    title,
    icon,
    id,
    isLeaf,
    itemKey,
    parentId,
    onOpenAccessControlDialog,
    onOpenDeletingTopicDialog,
    onSelect,
    isShowSettings,
    parentName,
    navigationItemId,
    actions,
    createdByUser,
    isDraft,
    hasItems,
  } = props;

  const { regulationStore } = useRootStore();
  const { t } = useTranslation();

  const [isShowTreeControl, setIsShowTreeControl] = useState<boolean>(false);

  const handleClickDelete = useCallback(() => {
    onOpenDeletingTopicDialog(id, isLeaf ?? false, navigationItemId, hasItems, parentName, parentId);
  }, [id, navigationItemId, isLeaf, parentName, parentId, hasItems]);

  const handleOpenAccessControlDialog = useCallback(() => {
    onOpenAccessControlDialog(navigationItemId, createdByUser);
  }, [id, navigationItemId, isLeaf, parentName, createdByUser]);


  const handleSelect = useCallback(() => {
    const itemId = navigationItemId || id;

    onSelect(itemId, parentId, isLeaf ?? false, itemKey);
    if (isLeaf) {
      regulationStore.setRegulationIsEditable(true);
    }
  }, [navigationItemId, id, isLeaf, parentId, itemKey]);

  return (
    <Tooltip
      overlayStyle={{ pointerEvents: "none" }}
      title={title}
      align={{ offset: [16, 0] }}
      placement="right"
    >
      <div
        className="regulation-menu-item__content"
        onMouseEnter={() => isShowSettings ? setIsShowTreeControl(true) : false}
        onMouseLeave={() => isShowSettings ? setIsShowTreeControl(false) : false}
      >
        {icon}
        <div
          className="regulation-menu-item-title"
        >
          <div className={clsx(
            "regulation-menu-item-title__text",
            {"regulation-menu-item-title__text_draft": isDraft}
          )}>
            { title }
          </div>
          {isDraft && (
            <div className="regulation-menu-item-title__label">
              { t("parse:draft") }
            </div>
          )}

        </div>
        {isShowTreeControl && (
          defineAllow(allowsRegulationKeys.edit, actions ?? []) ||
          defineAllow(allowsRegulationKeys.delete, actions ?? [])
        ) && (
          <SidebarTreeControls
            isLeaf={isLeaf ?? false}
            onOpenAccessControlDialog={handleOpenAccessControlDialog}
            onOpenDeletingTopicDialog={handleClickDelete}
            treeItemKey={`${itemKey}`}
            treeItemId={id}
            onSelect={handleSelect}
            actions={actions}
            parentName={parentName}
            withoutATopic={!navigationItemId}
          />
        )}
      </div>
    </Tooltip>
  )
});
