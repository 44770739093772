import React from "react";
import ImgsViewer, { ImgsViewerImageType, ImgsViewerProps } from "react-images-viewer";
import "./ImageViewer.scss";

interface IImageViewerView {
  images: ImgsViewerImageType[];
  viewerProps?: ImgsViewerProps;
  currentImageIndex: number;

  onClickPrev: () => void;
  onClickNext: () => void;
  onClickThumbnail: (value: number) => void;
  onClose: () => void;
}

function getFileType(name: string): "file" | "image" | "text" | "video" {
  let fileType: "file" | "image" | "text" | "video" = "file";
  if (name.match(/.(jpg|jpeg|png|gif)$/i)) {
    fileType = "image";
  }
  if (name.match(/.(doc|txt)$/i)) {
    fileType = "text";
  }
  if (name.match(/.(mp4|webm|avi|mov|wmv)$/i)) {
    fileType = "video";
  }
  return fileType;
}

function ImageViewerView(props: IImageViewerView) {
  const initProps: ImgsViewerProps = {
    backdropCloseable: true,
    showThumbnails: true,
    // preloadNextImg: false,
    onClickThumbnail: (value) => props.onClickThumbnail(value),
    onClickPrev: () => props.onClickPrev(),
    onClickNext: () => props.onClickNext(),
    onClose: () => props.onClose(),
  };

  return props.images.length > 0 ? (
    <ImgsViewer
      isOpen={props.images.length > 0}
      currImg={props.currentImageIndex}
      imgs={props.images.map((i) => ({ src: i.src, caption: i.caption }))}
      // imgs={props.images.filter((f) => getFileType(f.src) == "image").map((i) => ({ src: i.src, caption: i.caption }))}
      {...(initProps ?? {})}
      {...props.viewerProps}
    />
  ) : (
    <div />
  );
}

export default ImageViewerView;
