import { Card } from "../../../../../../uiKit";
import { ISidebarStaticElement } from "./SidebarStaticElement.interface";
import "./SidebarStaticElement.scss";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SidebarStaticElement = (props: ISidebarStaticElement) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uri } = useParams();

  const handleRedirectToPlan = (uri: string) => {
    navigate(`/dashboard/${uri}`);
  };

  return (
    <Card
      className="sidebar-static-element"
      onClick={() => handleRedirectToPlan(props.uri)}
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        color: uri == props.uri ? "var(--color-primary-base)" : undefined,
      }}
    >
      {t(props.title)}
    </Card>
  );
};
