import React from "react";
import { ReactionDropdown } from "../../../../elements/emojiPicker/components/generalDropdown/ReactionDropdown";
import { TooltipPlacement } from "antd/lib/tooltip";

interface IEmojisPicker {
  onClose: () => void;
  onChange: (value: string) => void;
  placement?: TooltipPlacement;
  isNewStyle?: boolean;
}

export const EmojisPicker = (props: IEmojisPicker) => {
  return (
    <ReactionDropdown
      placement={props.placement}
      onReact={props.onChange}
      onClose={props.onClose}
      isDisabled={false}
      hideGeneralEmoji
      iconSize={16}
      isNewStyle={props.isNewStyle}
    />
  );
};
