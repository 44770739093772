import React, { memo, useMemo } from "react";
import "./BreadcrumbsNew.scss";
import { useNavigate } from "react-router-dom";
import { IBreadcrumbsNew } from "./BreadcrumbsNew.interface";
import { BreadcrumbsNewView } from "./BreadcrumbsNewView";

export const BreadcrumbsNew = memo((props: IBreadcrumbsNew) => {
  const { breadcrumbsList } = props;

  const navigate = useNavigate();
  const handleRedirect = (url: string) => navigate(url);

  return (
    <BreadcrumbsNewView
      onRedirect={handleRedirect}
      breadcrumbsList={breadcrumbsList}
      containerId={props.containerId}
    />
  );
});
