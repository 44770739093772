import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./SandboxPage.scss";
import { useNavigate } from "react-router-dom";
import {
  useDateHelpers,
  useHash,
  useIsMobile,
  useNotifier,
  useRootStore,
  useTheme,
  useWindowSize,
} from "../../../hooks";
// @ts-ignore
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { theme } from "antd";
import { ThemeSwitcher } from "../../modules/pages/settings/themeSwitcher/ThemeSwitcher";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { Dayjs } from "dayjs";

function SandboxPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const notifier = useNotifier();

  // window.onerror = function (error) {
  //   // do something clever here
  //   alert(error); // do NOT do this for real!
  // };
  // console.log(getObjectFromTimeSpan("10.12:12:12"));

  // Gradient Data
  let gD: any = [];
  let cD: any = [];

  // String(window.location.host).match(/tonnus\.[a-z]+/i)[0]

  // const generateGradientData = () => {
  //   const COLORS = {
  //     up: "green",
  //     down: "red",
  //     neutral: "blue",
  //   };
  //   const p = 100 / (cD.length - 1);
  //   let resArray: string[] = [];
  //
  //   cD.forEach((d, i) => {
  //     let c: any = null;
  //     if (i + 1 == cD.length) return;
  //     if (cD[i + 1].line == d.line) c = COLORS.neutral;
  //     // @ts-ignore
  //     if (cD[i + 1].line > d?.line) c = COLORS.up;
  //     // @ts-ignore
  //     if (cD[i + 1].line < d?.line) c = COLORS.down;
  //     if (cD[i] == null || cD[i + 1] == null) c = COLORS.neutral;
  //     resArray = [...resArray, c].filter((v) => v != null);
  //   });
  //
  //   return (
  //     <>
  //       {resArray.map((r, i) => {
  //         return (
  //           <>
  //             <stop offset={`${p * i}%`} stopColor={resArray[i]} />
  //             <stop offset={`${p * (i + 1)}%`} stopColor={resArray[i]} />
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const windowSize = useWindowSize();

  const colors: string[] = [
    "#DCE2F0",
    "#ffc6c6",
    "#ffe2c6",
    "#fff7c6",
    "#ceffc6",
    "#d0f4f1",
    "#c6d7ff",
    "#dec6ff",
    "#ffc6df",
    "#ffffff",
    "#000000",
    "#f1f1f1",
    "#1f1f1f",
    "#c6ffe3",
    "#e2ffc6",
    "#b3d8ff",
    "#468499",
    "#f1f100",
    "#1f1f00",
    "#0f10f1",
    "#1f0e2a",
    "#ff11ff",
  ];

  const { useToken } = theme;
  // const { token } = useTheme();
  const { height, width } = useWindowSize();
  const { token } = useToken();
  const isMobile = useIsMobile();
  const { color } = useTheme();
  const { hashFn } = useHash();
  const dateHelpers = useDateHelpers();

  // По Чите: 00:03, 18 число
  // По UTC: 15:03, 17 число
  const exampleDateString = "2023-10-17T15:03:09.301Z";

  const [dpVal, setDpVal] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [dpVal2, setDpVal2] = useState<Dayjs | null>(null);
  const [dpVal3, setDpVal3] = useState<Dayjs | null>(null);
  const [timeSpanPickerValue, setTimeSpanPickerValue] = useState<string | null>("12:35:00");
  // const [timeSpanPickerValue, setTimeSpanPickerValue] = useState<string | null>(null);

  return (
    <>
      <PageHeader title={t("common:page_title.sandbox") + " / YS"}>
        <div className="flex-grow-1" />
        <ThemeSwitcher displayMode="button" />
      </PageHeader>
      <PageContent>
        {/*<TimePicker size={"large"} value={dpVal} onChange={setDpVal} />*/}
        {/*showTime={{ format: "HH:mm" }}*/}
        {/*<DatePicker size={"large"} value={dpVal2} onChange={setDpVal2} showTime={{ format: "HH:mm" }} />*/}
        {/*/!*<DatePicker mode={"year"} value={dpVal2} onChange={setDpVal2} />*!/*/}
        {/*<span children={"---"} />*/}
        {/*<Text size={"20px"} weight={"bold"} children={dpVal2?.toISOString() ?? "no value"} />*/}
        {/*<div />*/}
        {/*<TimePicker value={dpVal3} onChange={setDpVal3} />*/}
        {/*<span children={"---"} />*/}
        {/*<Text size={"20px"} weight={"bold"} children={dpVal3?.toISOString() ?? "no value"} />*/}
        {/*<div />*/}
        {/*<TimeSpanPicker value={timeSpanPickerValue} onChange={setTimeSpanPickerValue} />*/}
        {/*<span children={"---"} />*/}
        {/*<Text size={"20px"} weight={"bold"} children={timeSpanPickerValue ?? "no value"} />*/}
        {/*<span children={"---"} />*/}
        {/*<Text*/}
        {/*  size={"20px"}*/}
        {/*  weight={"bold"}*/}
        {/*  children={dateHelpers.formatTimeSpan(timeSpanPickerValue, { returnAs: "split" })}*/}
        {/*/>*/}
        {/*<Divider className="my-5" />*/}
        {/*<div>*/}
        {/*  <Text children={`Current timeZoneId: ${authStore.getInitialInfo?.identity?.timeZoneId}`} />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <Text*/}
        {/*    children={`Date and time in selected timeZoneId: ${dateHelpers.formatDate(dayjs(), {*/}
        {/*      showYearWhenIsCurrent: true,*/}
        {/*    })}`}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <Text children={`UTC time: ${dayjs().utc().format("HH:mm")}`} />*/}
        {/*</div>*/}
        {/*<Card style={{ width: "350px" }} className="mb-4" title="AudioRecorder test">*/}
        {/*  <AudioRecorder />*/}
        {/*</Card>*/}
        {/*<Text size={"24px"}>{dayjs().format("YYYY-MM-DDTHH:mm:ssZ-zzz (z)")}</Text>*/}
        {/*<Divider />*/}
        {/*<Text size={"24px"}>{Intl.DateTimeFormat().resolvedOptions().timeZone}</Text>*/}
        {/*<Divider />*/}

        {/*<Card style={{ maxWidth: "600px" }} className="mb-4" title="AudioPlayer test">*/}
        {/*  /!*<AudioPlayer type="voice-message" src="https://dl.espressif.com/dl/audio/ff-16b-2c-44100hz.mp4" />*!/*/}
        {/*  /!*<AudioPlayer src="https://api.beta.tonnus.io/static/1/Kordhell_scarlxrd_Corpse_-_LIKE_YXU_WXULD_KNXW_AUTUMN_TREES_(musmore.com).mp3" />*!/*/}
        {/*  <AudioPlayer src="https://api.tonnus.io/static/1/ChrisWebby-TwistAgain(LaLaLa).mp3" />*/}
        {/*  <Divider className="my-4" />*/}
        {/*  <AudioPlayer src="https://api.beta.tonnus.io/static/1/Kordhell_scarlxrd_Corpse_-_LIKE_YXU_WXULD_KNXW_AUTUMN_TREES_(musmore.com).mp3" />*/}
        {/*  <Divider className="my-4" />*/}
        {/*  <AudioPlayer src="https://api.beta.tonnus.io/static/1/audioMessage-1686036699649.wav" />*/}
        {/*  /!*<AudioPlayer type="voice-message" src="https://rcapi.tonnus.io/static/1/ChrisWebby-TwistAgain(LaLaLa).mp3" />*!/*/}
        {/*  /!*<AudioPlayer type="voice-message" src="https://api.beta.tonnus.io/static/1/file_example_MP3_700KB.mp3" />*!/*/}
        {/*</Card>*/}

        {/*<Card className="mb-4">*/}
        {/*  <div className="d-stack stack-divided spacing-2">*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*  </div>*/}
        {/*  <div className="mt-4 d-stack-column stack-divided spacing-1">*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*    <Shape size={48} backgroundColor={"var(--color-primary-base)"} />*/}
        {/*  </div>*/}
        {/*</Card>*/}
        {/*<Card className="mb-4">*/}
        {/*  <Shape*/}
        {/*    size={32}*/}
        {/*    backgroundColor={"var(--color-primary-weaker)"}*/}
        {/*    style={{ color: invertColor("var(--color-primary-weaker)") }}*/}
        {/*    children={invertColor("var(--color-primary-weaker)")}*/}
        {/*  />*/}
        {/*</Card>*/}
        {/*<Card title="Theme Colors">*/}
        {/*  <div className="d-stack-column spacing-3">*/}
        {/*    {Object.keys(color).map((c) => {*/}
        {/*      return (*/}
        {/*        <div className="d-stack-column">*/}
        {/*          <SubheaderText text={c.charAt(0).toUpperCase() + c.slice(1)} />*/}
        {/*          <div className="d-stack spacing-2">*/}
        {/*            /!*@ts-ignore*!/*/}
        {/*            {Object.keys(color[c]).map((k) => {*/}
        {/*              {*/}
        {/*                // @ts-ignore*/}
        {/*                return typeof color[c][k] === "string" ? (*/}
        {/*                  <Shape*/}
        {/*                    size={60}*/}
        {/*                    // @ts-ignore*/}
        {/*                    backgroundColor={color[c][k]}*/}
        {/*                    children={k}*/}
        {/*                    className="d-flex align-center justify-center br-md"*/}
        {/*                    style={{*/}
        {/*                      // @ts-ignore*/}
        {/*                      color: addAlphaToHex(invertColor(color[c][k]), 0.6),*/}
        {/*                      wordBreak: "break-word",*/}
        {/*                      textAlign: "center",*/}
        {/*                      lineHeight: "1.1em",*/}
        {/*                      boxShadow: "var(--shadow-down-md)",*/}
        {/*                    }}*/}
        {/*                  />*/}
        {/*                ) : (*/}
        {/*                  <div className="d-stack-column">*/}
        {/*                    <SubheaderText*/}
        {/*                      text={*/}
        {/*                        c.charAt(0).toUpperCase() + c.slice(1) + " -> " + k.charAt(0).toUpperCase() + k.slice(1)*/}
        {/*                      }*/}
        {/*                    />*/}
        {/*                    <div className="d-stack spacing-2">*/}
        {/*                      /!*@ts-ignore*!/*/}
        {/*                      {Object.keys(color[c][k]).map((y) => (*/}
        {/*                        <Shape*/}
        {/*                          size={60}*/}
        {/*                          // @ts-ignore*/}
        {/*                          backgroundColor={color[c][k][y]}*/}
        {/*                          children={y}*/}
        {/*                          className="d-flex align-center justify-center br-md"*/}
        {/*                          style={{*/}
        {/*                            // @ts-ignore*/}
        {/*                            color: addAlphaToHex(invertColor(color[c][k][y]), 0.6),*/}
        {/*                            wordBreak: "break-word",*/}
        {/*                            textAlign: "center",*/}
        {/*                            lineHeight: "1.1em",*/}
        {/*                            boxShadow: "var(--shadow-down-md)",*/}
        {/*                          }}*/}
        {/*                        />*/}
        {/*                      ))}*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                );*/}
        {/*              }*/}
        {/*            })}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </div>*/}
        {/*</Card>*/}
        {/*<DatePickerRange />*/}
      </PageContent>
    </>
  );
}

export default SandboxPage;
