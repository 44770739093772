export const carrotQuestLocaleUk = {
  thanks: "Дякую",
  dialogs_history: "Історія діалогів",
  powered_by_text_before_logo: "Створено&nbsp;в",
  powered_by_text_after_logo: "",
  enter_message: "Введіть повідомлення…",
  enter_email: "Введіть електронну пошту",
  enter_phone: "Введіть номер телефону",
  leave_email: "Ви можете залишити свою електронну пошту та продовжити спілкування з нами через пошту:",
  page_title_new_message: "Нове повідомлення",
  privacy_policy: "Умови обробки даних",
  errorFileType:
    "Невірний формат файлу для завантаження. Можна завантажувати файли: jpg, jpeg, png, gif, txt, pdf, rar, zip, html, csv, docx, doc, xlsx, xls, sig, mp4, rtf , mov, tiff, tif, m4v, mp3, mpeg.",
  errorFileSize: "Файл занадто великий<br/>Максимальний розмір файлу — 10 МБ.",
  errorManyRequests: "Занадто багато файлів<br/>Ви надсилаєте файли занадто часто. Потрібно зачекати трохи",
  errorReplyTooBig: "Повідомлення не може бути довшим за 20 000 символів",
  errorUserBan: "Ви заблоковані<br/>У вас більше немає можливості писати нові повідомлення в чаті",
  error: "Щось пішло не так.<br/>Сталася помилка при завантаженні. Спробуйте ще раз.",
  close: "Закрити",
  button_start_message: "Написати",
  button_vk: "ВК",
  button_viber: "Viber",
  button_telegram: "Telegram",
  button_facebook: "Facebook",
  button_instagram: "Instagram",
  button_whatsapp: "WhatsApp",
  social_network_page_title: "Розпочати або продовжити діалог в {{networkName}}",
  social_network_page_desc_vk: [
    "Відкрийте ВК за допомогою кнопки нижче, щоб перейти до потрібного діалогу",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_viber: [
    "Відкрийте Viber за допомогою кнопки нижче, щоб перейти до потрібного діалогу",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_telegram: [
    "Відкрийте Telegram за допомогою кнопки нижче, щоб перейти до потрібного діалогу",
    "Натисніть кнопку «Start» в Telegram",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_facebook: [
    "Відкрийте Facebook за допомогою кнопки нижче, щоб перейти до потрібного діалогу",
    "Натисніть кнопку «Почати» в Facebook",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_instagram: [
    "Відкрийте Instagram за допомогою кнопки нижче, щоб перейти на потрібну сторінку",
    "Натисніть кнопку «Написати повідомлення» в Instagram",
    "Напишіть нам своє запитання",
  ],
  social_network_page_desc_whatsapp: [
    "Відкрийте WhatsApp за допомогою кнопки нижче, щоб перейти до потрібного діалогу",
    "Відправте заповнене повідомлення",
    "Напишіть нам своє запитання",
  ],
  social_network_page_open_button: "Відкрити {{networkName}}",
  social_network_qr_code_title: "Відскануйте цей QR-код, щоб відкрити {{networkName}} з іншого пристрою",
  bumperText:
    'Ми використовуємо cookies для поліпшення роботи сайту, аналізу трафіку та персоналізації. Використовуючи сайт або клацаючи на Я згоден, ви погоджуєтесь з нашою політикою використання особистих даних і cookies відповідно до Політики щодо особистих даних. Ви можете прочитати нашу політику <a href="{{privacy-policy-link}}", target="_blank">тут</a>',
  bumperButton: "Я згоден",
  confirmSubscriptionWithCheckbox:
    'Я згоден з <a href="{{subscription-policy}}" target="_blank">умовами обробки даних</a>',
  confirmSubscriptionWithoutCheckbox:
    'Натискаючи на кнопку, ви згоджуєтеся з <a href="{{subscription-policy}}" target="_blank">умовами обробки даних</a>',
  appeal: "Ви",
  vote_comment: "Що сталося?",
  vote_text: "Будь ласка, оцініть роботу оператора",
  vote_thanks: "Дякую за оцінку!",
  cart_button: "Детальніше",
  cart_no_name: "Без назви",
  cart_zero: "Дані про товари в кошику не завантажились",
  article_link: "Читати статтю",
  to_knowledge_base: "Знайти відповідь в базі знань",
  typing_status: "Друкувати…",
  notification_enter_message: "Введіть повідомлення…",
  notification_message_sending: "Повідомлення відправляється…",
  notification_message_sent: "Повідомлення відправлено",
  notification_file_sending: "Файл завантажується…",
  notification_file_sent: "Файл відправлено",
  notification_typing_status: "друкую",
  history_zero_data_title: "Ми з вами ще не спілкувались",
  history_zero_data_desc: "Тут буде історія ваших діалогів",
  history_group_collapse: "Згорнути",
  history_section_actual: "Недавно",
  history_section_archive: "Історія",
  back_to_chat: "Повернутися до чату",
  integration_zoom_button_text: "Приєднатися",
  integration_zoom_tip: "☝️ Підказка: приєднатися до відеоконференції можна прямо з браузера",
  dateMonth: {
    "0": "січня",
    "1": "лютого",
    "2": "березня",
    "3": "квітня",
    "4": "травня",
    "5": "червня",
    "6": "липня",
    "7": "серпня",
    "8": "вересня",
    "9": "жовтня",
    "10": "листопада",
    "11": "грудня",
  },
  fileSize: {
    "0": "Б",
    "1": "КБ",
    "2": "МБ",
    "3": "ГБ",
  },
  at: "в",
  edited: "Змінено",
  removed: "Видалено",
  removedMessage: "Повідомлення видалено",
  bot_answer_placeholder: "Введіть відповідь",
  bot_buttons_placeholder: "Виберіть варіант відповіді",
  connection_problem_toast: "<b>Немає з'єднання з чатом.</b> Ми спробуємо перепідключитися автоматично.",
  input_error_email: "Введіть коректний Email",
  input_error_phone: "Введіть коректний телефон",
  popup_close_all_button: "Сховати все",
  offline: "офлайн",
  online: "онлайн",
  whatsapp_auth_text:
    "Відправте це повідомлення нам, щоб перенести свій ідентифікатор веб-чата: {{userId}} сюди, в Whatsapp. Якщо ви відредагуєте або видалите це повідомлення, ви створите новий чат і зв'яжетесь з іншим оператором",
  popup_desc: "Поп-ап",
  image_desc: "Зображення",
  emoji_search_placeholder: "Пошук",
  emoji_zero_data: "Нічого не знайдено",
  emoji_category_people: "Смайлики і люди",
  emoji_category_nature: "Тварини і природа",
  emoji_category_food: "Їжа і напої",
  emoji_category_activity: "Активність",
  emoji_category_places: "Подорожі і місця",
  emoji_category_objects: "Предмети",
  emoji_category_symbols: "Символи",
  emoji_category_flags: "Прапори",
};

export const carrotAdditionalSettingsUk = {
  _messenger_mode: "has_dialogs",
  messenger_online_message: "Ми тут і готові допомогти",
  messenger_offline_message: "Оператор підключиться спеціально для вас.",
  messenger_welcome_message:
    "Добрий день! Ви звернулися в техпідтримку Tonnus. Напишіть, будь ласка, суть вашої проблеми, і ми відповімо вам найближчим часом. Дякую за Ваше звернення!",
};
