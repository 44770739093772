import React, { memo, useCallback, useState } from "react";
import { TimeSpanPickerView } from "./TimeSpanPickerView";
import { TTimePicker } from "../timePicker/TimePicker";
import dayjs, { Dayjs } from "dayjs";

type TTimeSpanPickerProps = {
  value?: string | null;
  onChange?: (value: string | null) => void;
} & Omit<TTimePicker, "value" | "onChange">;

export const TimeSpanPicker = memo((props: TTimeSpanPickerProps) => {
  const { value, format, onChange, ...rest } = props;

  const [isTouched, setIsTouched] = useState(false);

  const parseTimeSpan = (val?: string | null): Dayjs | null => {
    if (val == null) return null;
    const [hours, minutes, seconds] = val.split(":").map(Number);
    return dayjs()?.utc(false).tz("Africa/Abidjan", false).set({
      hours,
      minutes,
      seconds,
    });
  };

  const parseDayjsObject = (val: Dayjs | null): string | null => {
    if (val == null) return null;
    return val.tz("Africa/Abidjan", true).format("HH:mm:ss");
  };

  const getFormat = useCallback((val: Dayjs | null): string => {
    if (val == null) return "HH:mm";
    return val?.tz("Africa/Abidjan", true)?.format("HH:mm");
  }, []);

  const handleIsTouchedChange = useCallback((val: boolean) => {
    setIsTouched(val);
  }, []);

  return (
    <TimeSpanPickerView
      format={getFormat}
      value={parseTimeSpan(value)}
      timeZone={"Africa/Abidjan"}
      onChange={(val) => onChange?.(parseDayjsObject(val))}
      isTouched={isTouched}
      onIsTouchedChange={handleIsTouchedChange}
      {...rest}
    />
  );
});
