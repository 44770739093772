import React, { memo } from "react";
import { UserInfo } from "../../../elements/userInfo/UserInfo";
import { UserShortDto } from "../../../../api";

interface IUserComponent {
  id: number;
  color: string;
  userInfo?: UserShortDto;
}

export const UserComponent = memo((props: IUserComponent) => {
  return (
    <span
      style={{
        padding: "2px",
        verticalAlign: "middle",
        borderRadius: "var(--border-radius-sm)",
        display: "inline-block",
        backgroundColor: props.color,
      }}
    >
      <UserInfo
        userIdToFetch={props.id}
        requestOptions={{ ignoreContext: true }}
        user={props.userInfo}
      />
    </span>
  );
});
