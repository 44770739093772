import React, { forwardRef } from "react";
import { IActivateMessengerBotViewDialog } from "./ActivateMessengerBotDialog.interface";
import { VerificationCodeForm } from "../../forms/verificationCodeForm/VerificationCodeForm";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";

export const ActivateMessengerBotViewDialog = forwardRef((props: IActivateMessengerBotViewDialog, ref) => {
  const { t } = useTranslation();
  return (
    <Dialog title={t("ui:title.connecting_bot")} onClose={props.onClose} open={props.isOpen}>
      <VerificationCodeForm
        ref={ref}
        defaultCodeLength={props.defaultCodeLength}
        onCodeSubmit={props.onVerificationCode}
        onTryActivateAgain={props.onActivateBot}
        codeIsNumberOnly={props.codeIsNumberOnly}
        timerValueMinute={props.timerValueMinute}
        withTimer={props.withTimer}
      />
    </Dialog>
  );
});
