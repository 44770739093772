import React, { memo } from "react";
import { ListEducationCourseCard } from "../listEducationCourseCard/listEducationCourseCard";
import { IListEducationCourse } from "./listEducationCource.interface";
import "./listEducationCourse.scss";

export const ListEducationCourse = memo((props: IListEducationCourse) => {
  return(
    <div className="list-education-courses">
      { props.courses.map((course) => (
        <ListEducationCourseCard
          key={course.id}
          course={course}
          onOpenCourse={props.handleOpenCourse}
        />
      ))
      }
    </div>
  )
});
