import "./ScheduleCellContextMenu.scss";
import { ISchedulePageCellMenuOptions } from "../../../../pages/schedule/SchedulePage";
import { Dropdown, Shape } from "../../../../uiKit";
import React from "react";
import { addAlphaToHex } from "../../../../../helpers/colorFunctions";
import { useTranslation } from "react-i18next";
import { theme } from "antd";
import { TScheduleSetupDialogType } from "../scheduleSetupDialog/ScheduleSetupDialog";
import { IDropdownItem } from "../../../../uiKit/navigation/dropdown/Dropdown";

interface IScheduleCellContextMenuProps {
  menuCoordinates: ISchedulePageCellMenuOptions;

  onMenuItemClick: (key: TScheduleSetupDialogType) => void;
}

interface IMenuItem {
  key: TScheduleSetupDialogType;
  text: string;
  color: string;
}

export const ScheduleCellContextMenu = (props: IScheduleCellContextMenuProps) => {
  const { t } = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();

  const menuItems: IMenuItem[] = [
    {
      key: "fullDay",
      text: t("common:misc.working_day"),
      color: addAlphaToHex(token.colorSuccess, 0.4),
    },
    {
      key: "weekend",
      text: t("common:misc.weekend"),
      color: addAlphaToHex(token.colorWarning, 0.4),
    },
    {
      key: "vacation",
      text: t("common:misc.vacation"),
      color: addAlphaToHex(token.colorWarning, 0.4),
    },
    {
      key: "sick",
      text: t("common:misc.medical"),
      color: addAlphaToHex(token.colorError, 0.4),
    },
  ];

  // if (!props.menuCoordinates.isOpen) return null;

  return (
    <Dropdown
      open={props.menuCoordinates.isOpen}
      destroyPopupOnHide={true}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      rootClassName="schedule__cell-menu"
      placement="bottomLeft"
      trigger={[]}
      items={
        menuItems.map((item) => ({
          text: item.text,
          key: item.key,
          onClick: (event: any) => {
            event.stopPropagation();
            event.preventDefault();
            props.onMenuItemClick(item.key);
          },
          icon: <Shape size={14} backgroundColor={item.color} type="circle" />,
        })) as IDropdownItem[]
      }
    >
      <div
        style={{
          background: "transparent",
          height: "4px",
          width: "4px",
          position: "absolute",
          top: props.menuCoordinates.y ?? 0,
          left: props.menuCoordinates.x ?? 0,
        }}
      />
    </Dropdown>
  );
};
