import { CheckboxProps } from "antd";
import React from "react";
import { CheckboxView } from "./CheckboxView";

interface ICheckbox extends CheckboxProps {
  isNewDesign?: boolean;
}

export function Checkbox(props: ICheckbox) {
  return <CheckboxView {...props} />;
}
