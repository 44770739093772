import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { $createCustomNode, CustomNode } from "../../nodes/CustomNode";

export const INSERT_MENTION_COMMAND: LexicalCommand<number> = createCommand("INSERT_MENTION_COMMAND");

export default function MentionToolbarPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([CustomNode])) {
      throw new Error("EmojisPlugin: EmojiNode not registered on editor");
    }
    return editor.registerCommand<string>(
      INSERT_MENTION_COMMAND,
      (payload) => {
        const mentionNode = $createCustomNode("user_id", payload, "");
        $insertNodes([mentionNode]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
