import React from "react";
import "./TabPane.scss";
import { TabPaneProps, Tabs } from "antd";
import clsx from "clsx";

interface ITabPaneView extends TabPaneProps {
  //
}

const { TabPane } = Tabs;

export function TabPaneView({ className, ...rest }: ITabPaneView) {
  return <TabPane className={clsx("uiKit-tabPane", className ?? "")} {...rest} />;
}
