import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { IPlanningRejectDialogView } from "./PlanningRejectDialog.interface";
import { TextEditor as TextEditorLexical } from "../../../modules/textEditor/TextEditor";
import { ToolbarType } from "../../textEditor/types/types";
import { initialEditorContent } from "../../../../utils/textEditor";

export const PlanningRejectDialogView = forwardRef((props: IPlanningRejectDialogView, ref) => {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.reject_plan")} open={props.isOpen} onClose={() => props.onOpeningChange(false)}>
      {/*<TextEditorOld*/}
      {/*  id="plan-reject-editor"*/}
      {/*  placeholder={t("ui:placeholder.enter_a_comment")}*/}
      {/*  value={props.reason}*/}
      {/*  onChange={props.onReasonChange}*/}
      {/*  ref={ref}*/}
      {/*  toolbarType={ToolbarType.Hidden}*/}
      {/*  height="100px"*/}
      {/*/>*/}
      <TextEditorLexical
        id={"planning-reject-editor"}
        ref={ref}
        placeholder={t("ui:placeholder.enter_a_comment")}
        value={props.reason}
        initialValue={initialEditorContent}
        onChange={props.onReasonChange}
        height="100px"
        toolbarType={ToolbarType.Hidden}
      />
      <DialogActions>
        <Button onClick={props.onSuccess} type="primary" variant="text" loading={props.isLoadingButton}>
          {t("ui:button.decline")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
