import React from "react";
import { useColorSchemeStyles } from "../../../../../../hooks/useColorSchemeStyles";
import { colorSchemeType } from "../../../../../../utils/colorScheme";
import { IRegulationIndicator } from "./RegulationIndicator.interface";
import { Tag } from "../../../../../uiKit";

export const RegulationIndicatorView = ({ name, colorSchemeKey }: IRegulationIndicator) => {
  const { findColorByKey } = useColorSchemeStyles();
  const colorScheme = findColorByKey(colorSchemeKey as colorSchemeType);

  return (
    <Tag
      className="pa-2"
      color={colorScheme.backgroundColor}
      style={{ color: colorScheme.textColor, width: "115px", textAlign: "center", fontSize: "14px" }}
      children={name}
    />
  );
};
