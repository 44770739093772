import React from "react";
import "./DatePickerRange.scss";
import { Dayjs } from "dayjs";
import { RangePickerDateProps } from "antd/es/date-picker/generatePicker";
import clsx from "clsx";
import { DatePickerGeneratedRange } from "../datePickerGenerated/DatePickerGenerated";

export type TDatePickerRangeViewProps = {
  readOnly?: boolean;
} & RangePickerDateProps<Dayjs>;

export function DatePickerRangeView({ className, readOnly, ...rest }: TDatePickerRangeViewProps) {
  return (
    <DatePickerGeneratedRange
      className={clsx("uiKit-datePickerRange", className ?? "", {
        "no-interact": readOnly,
      })}
      inputReadOnly
      {...rest}
    />
  );
}
