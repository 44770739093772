import { UserShortDto } from "../../../../../../api";
import { AtSignMentionsRegex, AtSignMentionsRegexAliasRegex, CapitalizedNameMentionsRegex } from "../misc/consts";
import { MenuOption, MenuTextMatch } from "@lexical/react/LexicalTypeaheadMenuPlugin";

export function checkForCapitalizedNameMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
}

export function checkForAtSignMentions(text: string, minMatchLength: number): MenuTextMatch | null {
  let match = AtSignMentionsRegex.exec(text);
  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

export class MentionTypeaheadOption extends MenuOption {
  id: number;
  user: UserShortDto;

  constructor(name: string, user: UserShortDto) {
    super(name);
    this.user = user;
    this.id = user.id!;
  }
}
