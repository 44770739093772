import { UpdateNoteContentDto } from "../../../../../api";
import { validationErrorKeys } from "./constants";
import { getLexicalTextContentLength } from "../../../../../utils/textEditor";
import { NotificationType } from "../../../../../hooks/useNotifier";
import { TFunction } from "react-i18next";
import { LocalesKeys } from "../../../../../constants/localesKeys";

interface INoteValidation {
  t: TFunction<"translation", undefined>;
  notifier: (message: string, theme: NotificationType) => void;
}

const handlerErrorsValidation = (params: INoteValidation) => (error: Error) => {
  switch (error.message as validationErrorKeys) {
    case validationErrorKeys.noContent:
      return params.notifier(params.t("notifier:error.notes_no_content"), "error");
    case validationErrorKeys.noName:
      return params.notifier(params.t("notifier:error.notes_no_names"), "error");
    case validationErrorKeys.noAllNotes:
      return params.notifier(params.t("notifier:error.something_wrong"), "error");
  }
};

export const validationNotesForSave =
  (params: INoteValidation) =>
  (noteContents: UpdateNoteContentDto[], currentLocale: LocalesKeys): boolean | void => {
    const handlerErrors = handlerErrorsValidation(params);

    if (!noteContents.length) return handlerErrors(new Error(validationErrorKeys.noAllNotes));
    const currentNote
      = noteContents.find(({ cultureKey }) => cultureKey === currentLocale) ?? {};
    if (!currentNote.name || !currentNote.name.length) {
      return handlerErrors(new Error(validationErrorKeys.noName));
    } else if (!currentNote.content || getLexicalTextContentLength(currentNote.content) === 0) {
      return handlerErrors(new Error(validationErrorKeys.noContent));
    } else return true;
    // if (noteContents.find((item) => {
    //   console.log("item", item);
    //   return !item.name || !item.name.length
    // }))
    //   return handlerErrors(new Error(validationErrorKeys.noName));
    // else if (noteContents.find((item) => !item.content || getLexicalTextContentLength(item.content) === 0))
    //   return handlerErrors(new Error(validationErrorKeys.noContent));
    // else return true;
  };
