import React, { useCallback } from "react";
import { DatePickerRangeView } from "./DatePickerRangeView";
import { RangePickerDateProps } from "antd/es/date-picker/generatePicker";
import dayjs, { Dayjs } from "dayjs";

type TDatePickerRangeProps = {
  disableFuture?: boolean;
  disableToday?: boolean;
  disablePast?: boolean;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  readOnly?: boolean;
} & RangePickerDateProps<Dayjs>;

export function DatePickerRange({
  disableFuture,
  disablePast,
  disableToday,
  disabledDate,
  maxDate,
  minDate,
  ...rest
}: TDatePickerRangeProps) {
  const checkIsDateDisabled = useCallback(
    (date: Dayjs) => {
      const _nowDate = dayjs();
      const _date = date;
      const _minDate = minDate;
      const _maxDate = maxDate;
      // const _nowDate = dayjs().tz();
      // const _date = date.tz();
      // const _minDate = minDate?.tz();
      // const _maxDate = maxDate?.tz();

      if (disabledDate != null) return disabledDate(date);
      if (_minDate == null && _maxDate == null) {
        if (disablePast && disableFuture) return disableToday ? true : !_date.isSame(_nowDate, "date");
        if (disablePast && !disableToday) return _date.isBefore(_nowDate, "date");
        if (disablePast && disableToday) return _date.isSameOrBefore(_nowDate, "date");
        if (disableFuture && !disableToday) return _date.isAfter(_nowDate, "date");
        if (disableFuture && disableToday) return _date.isSameOrAfter(_nowDate, "date");
        if (!disablePast && !disableFuture && disableToday) return _date.isSame(_nowDate, "date");
      }
      if (_minDate != null && _maxDate != null) return !_date.isBetween(_minDate, _maxDate, "day", "[]");
      if (_minDate != null) return _date.isBefore(_minDate, "date");
      if (_maxDate != null) return _date.isAfter(_minDate, "date");
      return false;
    },
    [minDate, maxDate]
  );

  return <DatePickerRangeView disabledDate={checkIsDateDisabled} {...rest} />;
}
