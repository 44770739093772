import React, { useEffect } from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useTranslation } from "react-i18next";
import "./SandboxPage.scss";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
import { LocalStorageHelpers } from "../../../hooks";
import { Button } from "../../uiKit";

function SandboxPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const buttonsArray = [
    { id: 0, text: "A / Y", link: "/sandbox/ay" },
    { id: 1, text: "D / R", link: "/sandbox/dr" },
    { id: 2, text: "Y / S", link: "/sandbox/ys" },
  ];

  useEffect(() => {
    LocalStorageHelpers.set("sandboxLink", "");
  }, []);

  return (
    <>
      <PageHeader title={t("common:page_title.sandbox")} />

      <PageContent isFilled contentPosition={{ isCentered: true }}>
        <div className="d-stack spacing-3">
          {buttonsArray.map((b) => (
            <Button
              onClick={() => {
                LocalStorageHelpers.set("sandboxLink", b.link);
                navigate(b.link);
              }}
              className="px-14 py-10"
              style={{ fontSize: "24px" }}
              size="large"
              key={b.id}
              variant="filled"
            >
              {b.text}
            </Button>
          ))}
        </div>
      </PageContent>
    </>
  );
}

export default SandboxPage;
