import React from "react";
import { Form } from "antd";
import { Checkbox, DatePicker } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getLexicalTextContentLength, initialEditorContent } from "../../../../../utils/textEditor";
import { ToolbarType } from "../../../textEditor/types/types";
import { TextEditor } from "../../../textEditor/TextEditor";
import FileUploader from "../../../../service/fileUploader/FileUploader";
import { StaticFileDto } from "../../../../../api";
import { actionsKeysConst } from "../../../forms/types/consts";

interface IIssueRequiredActionsDialogTabSetDeadline {
  editorRef: React.MutableRefObject<{ isEmptyEditor: () => boolean; resetEditor: () => void } | undefined>;
  onChange: (value: Date | null) => void;
  deadline: Date | null;
  onInput: (value: string) => void;
  comment: string;
  isShowErrorValidateMessage: {
    showCommonError: boolean;
    showTimeFactError: boolean;
    showDeadlineError: boolean;
  };
  isChecked: boolean;
  onIsCheckedChange: (isChecked: boolean, key: actionsKeysConst) => void;
  showCheckboxToSaveLastComment: boolean;
  attachments?: StaticFileDto[];
}

export const IssueRequiredActionsDialogTabSetDeadline = (props: IIssueRequiredActionsDialogTabSetDeadline) => {
  const { t } = useTranslation();

  return (
    <div style={{ overflowY: "auto", height: "100%" }}>
      <Form.Item
        name="reason"
        extra={
          t("validation:min_char_number", { number: 1 }) +
          ". " +
          t("common:misc.entered_characters_number", { number: getLexicalTextContentLength(props.comment) })
        }
        rules={[{ required: true, message: t("validation:field_required") }]}
      >
        <TextEditor
          id="ds-not-approval-editor"
          ref={props.editorRef}
          placeholder={t("ui:placeholder.write_reason")}
          variant={props.isShowErrorValidateMessage.showCommonError ? "error" : undefined}
          value={props.comment}
          disabled={props.isChecked}
          initialValue={props.comment === initialEditorContent ? initialEditorContent : props.comment}
          onChange={props.onInput}
          toolbarType={ToolbarType.Hidden}
        />
      </Form.Item>
      <FileUploader hideInfo hideButton smallSize readonly value={props.attachments} />
      {props.showCheckboxToSaveLastComment && (
        <div className="mt-2">
          <Checkbox
            checked={props.isChecked}
            onChange={(e) => props.onIsCheckedChange(e.target.checked, actionsKeysConst.setDeadline)}
          >
            {t("ui:label.last_comment_is_reason")}
          </Checkbox>
        </div>
      )}
      <div
        className="mt-4"
        children={t("ui:subheader.issue_with_deadline")}
        style={{
          fontWeight: 600,
          fontSize: 16,
        }}
      />
      <div style={{ width: "100%" }} className="mt-2">
        <Form.Item className="mb-0" rules={[{ required: true, message: t("validation:field_required") }]}>
          <SubheaderText text={t("ui:subheader.deadline")} />
          <DatePicker
            status={props.isShowErrorValidateMessage.showDeadlineError ? "error" : ""}
            // disabled={props.onCheckToAvailability(blockedFieldsKeys.dateDeadline)}
            className="full-width"
            value={props.deadline ? dayjs(props.deadline) : null}
            onChange={(value) => props.onChange(value?.toDate() ?? null)}
            allowClear
            size="large"
            showTime={{ format: "HH:mm" }}
            disablePast
          />
        </Form.Item>
      </div>
    </div>
  );
};
