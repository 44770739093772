import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { IDraggableProvider } from "./CardIssue.interface";

export const DraggableProvider = (props: IDraggableProvider) => {
  return (
    <Draggable draggableId={(props.draggableId ?? 0).toString()} index={props.draggableIndex}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {props.children}
          </div>
        );
      }}
    </Draggable>
  );
};
