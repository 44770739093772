import React from 'react';
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { GoogleAuthConnect } from "../../pages/settings/googleCalendarAuth/GoogleAuthConnect";
import { useTranslation } from "react-i18next";
import { IGoogleCalendarSettingsModalProps } from "./GoogleCalendarSettingsModal.interface";
import "./GoogleCalendarSettingsModal.scss";

export const GoogleCalendarSettingsModal = ({open, closeModal, isHiddenShowEventsSwitch}: IGoogleCalendarSettingsModalProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className={"google-calendar-settings-modal"}
      open={open}
      title={t("ui:google_calendar_settings_modal.title")}
      onClose={closeModal}
    >
      <GoogleAuthConnect
        isCalendarSettingsModal={true}
        onCloseModal={closeModal}
        isHiddenShowEventsSwitch={isHiddenShowEventsSwitch}
      />
      <DialogActions>
        <Button
          onClick={closeModal}
          variant="default"
        >
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}