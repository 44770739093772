import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Divider, Dropdown, Empty, IconButton, Input } from "../../../../uiKit";
import { LexicalEditor } from "lexical";
import { INSERT_MENTION_COMMAND } from "./MentionsToolbarPlugin";
import { MentionTypeaheadOption } from "./utils/helpers";
import { useTranslation } from "react-i18next";
import "./MentionsToolbarPopover.scss";
import ScrollTrigger from "../../../../service/scrollTrigger/ScrollTrigger";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { theme } from "antd";
import { LucideAtSign } from "lucide-react";
import { usePagingWithController } from "../../../../../hooks/usePaging";
import { api } from "../../../../../services";
import useDebounce from "../../../../../hooks/useDebounce";
import { EmailIcon } from "../../../../../constants/icon";

interface IMentionsToolbarPlugin {
  editor: LexicalEditor;
  isNewStyle?: boolean;
  issueId?: number;
}

const { useToken } = theme;

export const MentionsToolbarPopover = memo((props: IMentionsToolbarPlugin) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isTriggerActive = useRef(false);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const users = usePagingWithController(
    api[props.issueId ? "issue" : "userShort"],
    {
      name: searchedValue,
    },
    { pageSize: 10 },
    undefined,
    undefined,
    undefined,
    undefined,
    props.issueId ? `${props.issueId}/mentionable-users/autocomplete` : undefined,
  );

  const searchDebounce = useDebounce(searchedValue != "" && searchedValue != null ? searchedValue : null, 400);

  const options = useMemo(() => users.items.map((user) => new MentionTypeaheadOption(user.name ?? "", user)), [users]);

  const { token } = useToken();

  const handleSearchedValueChange = useCallback((value: string) => {
    setSearchedValue(value);
  }, []);
  // const [selectedKey, setSelectedKey] = useState<string>("0");
  // const downPress = useKeyPress("ArrowDown");
  // const upPress = useKeyPress("ArrowUp");
  // const enterPress = useKeyPress("Enter");
  // const tabPress = useKeyPress("Tab");

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    overflow: "hidden",
    maxHeight: "350px",
    width: "250px",
  };

  const handleIsOpenChange = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const handleClosePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onSelectUser = useCallback(
    (id: number) => {
      props.editor.dispatchCommand(INSERT_MENTION_COMMAND, id);
      handleClosePopover();
    },
    [handleClosePopover, props.editor]
  );

  // useEffect(() => {
  //   if (props.users.length && downPress) {
  //     setSelectedKey((prevState) => (prevState < props.users.length - 1 ? prevState + 1 : prevState));
  //   }
  // }, [downPress, props.users.length]);
  //
  // useEffect(() => {
  //   if (props.users.length && upPress) {
  //     setSelectedKey((prevState) => (prevState > 0 ? prevState - 1 : prevState));
  //   }
  // }, [props.users.length, upPress]);
  //
  // useEffect(() => {
  //   if (props.users.length && enterPress) {
  //     onSelectUser(props.users.find((v, i) => i == selectedKey)?.id!);
  //   }
  // }, [selectedKey, enterPress, props.users.length, props.users, onSelectUser]);
  //
  // useEffect(() => {
  //   if (props.users.length && tabPress) {
  //     onSelectUser(props.users.find((v, i) => i == selectedKey)?.id!);
  //   }
  // }, [selectedKey, onSelectUser, props.users, tabPress]);

  // useEffect(() => {
  //   searchDebounce !== null && users.restart();
  // }, [searchDebounce]);

  useEffect(() => {
    isOpen && users.restart();
  }, [isOpen, searchDebounce]);

  useEffect(() => {
    isOpen &&
      setTimeout(() => {
        isTriggerActive.current = true;
      }, 500);
    !isOpen && (isTriggerActive.current = false);
  }, [isOpen]);


  return (
    // <Popover
    //   overlayInnerStyle={{
    //     maxHeight: 300,
    //     minHeight: 150,
    //     display: "flex",
    //     padding: "4px 4px 4px 8px",
    //     width: "max-content",
    //   }}
    //   trigger={"click"}
    //   placement={"bottom"}
    //   open={isOpen}
    //   onOpenChange={handleClosePopover}
    //   content={
    //     <div className="d-flex flex-column align-center full-height">
    //       <Input
    //         placeholder={t("ui:placeholder.search_by_employees")}
    //         value={props.searchedValue}
    //         onChange={(v) => props.onSearchedValueChange(v.currentTarget.value)}
    //       />
    //       <div className="flex-grow-1 mt-1" style={{ overflow: "auto" }}>
    //         {props.users.map((u, i: number) => {
    //           return (
    //             <div
    //               key={u.id}
    //               className={css`
    //                 align-items: center;
    //                 display: flex;
    //                 cursor: pointer;
    //                 height: 36px;
    //                 border-radius: var(--border-radius-sm);
    //                 background-color: ${highlightIndex == i ? "var(--color-layout-fill-base)" : undefined};
    //                 padding: 4px;
    //
    //                 &:hover {
    //                   background: var(--color-layout-fill-base);
    //                 }
    //               `}
    //               onClick={() => {
    //                 onSelectUser(u.id);
    //               }}
    //               onMouseEnter={() => {
    //                 setHighlightIndex(i);
    //               }}
    //             >
    //               <UserInfo user={u.user} isTooltipDisabled />
    //             </div>
    //           );
    //         })}
    //         <ScrollTrigger
    //           onIntersection={() => isTriggerActive.current && props.loadNext()}
    //           hidden={props.isDone}
    //           disabled={props.isDone}
    //           marginTop={props.users?.length > 0}
    //         />
    //       </div>
    //     </div>
    //   }
    // >
    //   <IconButton
    //     onClick={handleOpenPopover}
    //     // type="text"
    //     // disabled={disabled}
    //     icon={<Icon component={() => <GoMention />} />}
    //   />
    // </Popover>
    <Dropdown
      open={isOpen}
      onOpenChange={handleIsOpenChange}
      placement={"bottom"}
      rootClassName={"dd-root"}
      trigger={["click"]}
      menu={{
        items: options.map((u, i) => ({
          key: i,
          label: <UserInfo user={u.user} isTooltipDisabled />,
          onClick: () => onSelectUser(u.id),
        })),
      }}
      dropdownRender={(menu: any) => {
        return (
          <div
            style={contentStyle}
            className="d-stack-column spacing-1"
            // onKeyDown={(e) => {
            //   e.preventDefault();
            //   setSelectedKey(String(parseInt(selectedKey) + 1));
            //   // if (e.key == "ArrowUp") {
            //   //   setSelectedKey(String(parseInt(selectedKey) - 1));
            //   // }
            //   // if (e.key == "ArrowDown") {
            //   //   setSelectedKey(String(parseInt(selectedKey) + 1));
            //   // }
            //   // if (e.key == "Tab") {
            //   //   onSelectUser(props.users.find((v, i) => i == parseInt(selectedKey))?.id!);
            //   // }
            //   // if (e.key == "Enter") {
            //   //   onSelectUser(props.users.find((v, i) => i == parseInt(selectedKey))?.id!);
            //   // }
            // }}
          >
            <div className="d-stack-column spacing-2 ma-2">
              <Input
                size="middle"
                placeholder={t("ui:placeholder.search_by_employees")}
                value={searchedValue}
                onChange={(v) => handleSearchedValueChange(v.currentTarget.value)}
              />
              <Divider />
            </div>
            <div className="flex-grow-1" style={{ overflow: "auto" }}>
              {!menu.props.items.length && !users.info.isLoading ? <Empty /> : menu}
              <div className="mx-2 mb-2">
                <ScrollTrigger
                  onIntersection={() => isTriggerActive.current && users.loadNext()}
                  hidden={users.info.isDone}
                  disabled={users.info.isDone}
                  marginTop={false}
                />
              </div>
            </div>
          </div>
        );
      }}
    >
      <IconButton
        onClick={() => handleIsOpenChange(true)}
        icon={props.isNewStyle ? <EmailIcon/> : <LucideAtSign size={18}/>}
      />
    </Dropdown>
  );
});
