import React, { memo, useCallback, useEffect, useState } from "react";
import { DashboardCreateEditDialogView } from "./DashboardCreateEditDialogView";
import { IDashboardCreateEditDialog } from "./DashboardCreateEditDialog.interface";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { DashboardDto, Metric2DashboardDto } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { arrayMove } from "@dnd-kit/sortable";
import { DragEndEvent } from "@dnd-kit/core";

export const DashboardCreateEditDialog = memo((props: IDashboardCreateEditDialog) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dashboard, setDashboard] = useState<DashboardDto>({ columnCount: 3 });
  const [isOpenMetricToDashboardDialog, setIsOpenMetricToDashboardDialog] = useState<boolean>(false);

  const toggleCloseDialog = useCallback(() => {
    props.toggleDialog(false, false);
    setDashboard({});
  }, [props]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateDashboard = useCallback(async () => {
    setIsLoading(true);
    const r = await api.dashboard.create({
      ...dashboard,
      metrics: dashboard.metrics?.map((m, i) => ({ ...m, order: i + 1 })),
    });
    setIsLoading(false);
    if (r) {
      notifier.show({
        message: t("notifier:success.dashboard_create"),
        theme: "success",
      });
      props.onMyDashboardsRestart();
      toggleCloseDialog();
      navigate(`/dashboard/${r.id}`);
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  }, [dashboard, navigate, notifier, props, t, toggleCloseDialog]);

  const handleEditDashboard = useCallback(async () => {
    setIsLoading(true);
    const r = await api.dashboard.edit(dashboard?.id!, dashboard!);
    setIsLoading(false);
    if (r) {
      notifier.show({
        message: t("notifier:success.dashboard_update"),
        theme: "success",
      });
      props.onDashboardRestart();
      props.onMetricsRestart();
      props.onMyDashboardsRestart();
      toggleCloseDialog();
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  }, [dashboard, notifier, props, t, toggleCloseDialog]);

  const handleOpenMetricToDashboardDialog = useCallback((isOpen: boolean) => {
    setIsOpenMetricToDashboardDialog(isOpen);
  }, []);

  const handleDeleteMetric = useCallback(
    (id: number) => {
      setDashboard({
        ...dashboard,
        metrics: dashboard?.metrics?.filter((m) => (m.metricSource2UserId ? m.metricSource2UserId : m.metricId) != id),
      });
    },
    [dashboard]
  );

  const handleChangeDashboardData = useCallback(
    (data: DashboardDto) => setDashboard({ ...data, metrics: data.metrics?.map((m, i) => ({ ...m, order: i + 1 })) }),
    []
  );
  // setDashboard(data);

  const handleMetricDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setDashboard((dashboard) => {
        const oldIndex = dashboard.metrics?.findIndex((m) => m.order == active.id);
        const newIndex = dashboard.metrics?.findIndex((m) => m.order == over?.id);
        if (oldIndex == null || newIndex == null || dashboard.metrics?.length == 0) return dashboard;
        const metricsArr: Metric2DashboardDto[] = [
          ...(arrayMove(dashboard.metrics ?? [], oldIndex, newIndex) ?? []),
        ].map((m, i) => ({ ...m, order: i + 1 }));
        return {
          ...dashboard,
          metrics: metricsArr,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (props.dashboard && props.editMode) {
      setDashboard({
        ...props.dashboard,
        metrics: props.dashboard.metrics
          ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((m, i) => ({ ...m, order: i + 1 })),
        // .map((m) => ({ ...m, id: m.order })),
        // metrics: props.dashboard.metrics?.map((m, index) => ({ ...m, sortId: index + 1 })),
      });
      // setDashboard(props.dashboard);
    }
    return () => setDashboard({});
  }, [props.dashboard, props.editMode]);

  return (
    <DashboardCreateEditDialogView
      editMode={props.editMode}
      open={props.open}
      handleMetricDragEnd={handleMetricDragEnd}
      onClose={toggleCloseDialog}
      createDashboard={handleCreateDashboard}
      editDashboard={handleEditDashboard}
      dashboard={dashboard ?? undefined}
      metrics={props.metrics ?? undefined}
      onChangeDashboard={handleChangeDashboardData}
      onOpenMetricToDashboardDialog={handleOpenMetricToDashboardDialog}
      openMetricToDashboardDialog={isOpenMetricToDashboardDialog}
      onDeleteMetric={handleDeleteMetric}
      onOpenDeleteDashboardDialogChange={props.onOpenDeleteDashboardDialogChange}
      dashboardColumnsAmount={props.dashboardColumnsAmount}
      isLoading={isLoading}
    />
  );
});
