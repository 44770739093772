import React, { useEffect } from "react";
import { useRegulationHooks } from "../../hooks/useRegulationHooks";
import { RegulationContext } from "../../../../../../contexts/RegulationPage/RegulationContext";
import { CreateRegulation } from "../createRegulation/CreateRegulation";
import { Regulation } from "../regulation/Regulation";
import { ICreateRegulationWrapperProps } from "./ICreateRegulationWrapper";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const CreateRegulationWrapper = observer((props: ICreateRegulationWrapperProps) => {
  const {
    handleSave,
    regulationTitle,
    setRegulationTitle,
    editorRef,
    testRef,
    isLoading,
    isLoadingButton,
    setIsLoading,
    setUserSelectionRules,
    setIsOpenConfirmDialog,
    isOpenConfirmDialog,
    titleError,
    setTitleError,
    isOpenAccessSettingsDialog,
    setIsOpenAccessSettingsDialog,
    regulationContent,
    setRegulationContent,
    breadcrumbsList,
    navigationMenuItem,
    fetchNavigationMenuItem,
  } = useRegulationHooks();

  const { pathname } = useLocation();

  useEffect(() => {
    // if (!pathname.includes('policy/all/') || pathname.includes('/policy/create-policy')) {
    //   console.log('useEffect =============== 1233');
    //   fetchNavigationMenuItem();
    // }
    fetchNavigationMenuItem();
  }, [pathname]);
  return (
    <RegulationContext.Provider value={{
      handleSave,
      regulationTitle,
      setRegulationTitle,
      editorRef,
      testRef,
      isLoading,
      isLoadingButton,
      setIsLoading,
      setUserSelectionRules,
      setIsOpenConfirmDialog,
      isOpenConfirmDialog,
      titleError,
      setTitleError,
      isOpenAccessSettingsDialog,
      setIsOpenAccessSettingsDialog,
      regulationContent,
      setRegulationContent,
      breadcrumbsList,
      navigationMenuItem,
      fetchNavigationMenuItem,
  }}>
      {
        props.isView ? (
          <Regulation/>
          ) : (
          <CreateRegulation/>
        )
      }

    </RegulationContext.Provider>
  )
});