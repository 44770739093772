import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IExecutorSelect } from "./ExecutorSelectNew.interface";
import { RoleCard } from "../roleCard/RoleCard";
import "./ExecutorsSelectNew.scss";
import { Button } from "../../uiKit";
import { UserSelectionDialog } from "../../modules/dialogs/userSelectionNewDialog/UserSelectionDialog";
import {
  IFunctionTreeItem
} from "../../modules/dialogs/regulationAccessControlDialog/regulationFunctionSelectionDialog/functionTreeItem/FunctionTreeItem.interface";

export const ExecutorSelectNew = memo((props: IExecutorSelect) => {
  // linked_roles_to_regulation
  const isSection = false;
  const { t } = useTranslation();
  const [isOpenUserSelectionDialog, setIsOpenUserSelectionDialog] = useState<boolean>(false);

  const handleOpenUserSelectionDialog = useCallback(() => {
    setIsOpenUserSelectionDialog(true);
  }, []);
  const handleCloseUserSelectionDialog = useCallback(() => {
    setIsOpenUserSelectionDialog(false);
  }, []);

  const handleAddRole = useCallback((roles: IFunctionTreeItem[], ...arg) => {
    const addedRolesList = roles.map((role) => ({
      // id: role.id,
      roleId: role.id,
      // isExcluded: false,
      orgchartId: role.orgchartId,
      roleName: role.title,
      isSelected: true,
      includeNestedRoles: role.includeNestedRoles,
      selectedPositions: role.selectedPositions,
      role: {
        name: role.title,
      },
      positions: role.positions,
    }));


    // @ts-ignore
    props.onChange(addedRolesList);

  }, [props.selectedRulesList]);

  console.log("props.selectedRulesList", props.selectedRulesList);

  return (
    <>
      <UserSelectionDialog
        open={isOpenUserSelectionDialog}
        onClose={handleCloseUserSelectionDialog}
        // onSave={handleAddRole}
        onAddRoles={handleAddRole}
        selectedRolesList={props.selectedRulesList}
        isExcludedType={true}
        itemId={props.itemId}
        isRegular={props.isRegular}
      />
      <div className="executor-select">
        <div className="executor-select__title">
          { t(`ui:title.${isSection ? "linked_roles_to_section" : "linked_roles_to_regulation"}`) }
        </div>
        <div className="executor-select-list">
          {props.selectedRulesList.map((item) => (
            <RoleCard
              item={item}
              key={item.roleId}
              orgchartId={item.orgchartId!}
              roleId={item.roleId!}
              title={item.roleName ?? ""}
              onRemoveRole={props.handleRemoveRole}
            />
          ))}
        </div>
        <div className="executor-select-add-role">
          <Button
            onClick={handleOpenUserSelectionDialog}
            size="middle"
            variant="default"
          >
            {t("ui:button.add")}
          </Button>
        </div>
      </div>
    </>
  )
});