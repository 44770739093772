import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { api } from "../../../services";
import { useApi, useRootStore } from "../../../hooks";
import { useUpdateWithController } from "../../../hooks/useUpdate";
import EmployeeEditForm from "../../modules/forms/employeeEditForm/EmployeeEditForm";
import CompaniesManageForm from "../../modules/forms/companiesManageForm/CompaniesManageForm";

function SettingsPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const employeeData = useApi(
    () => api.user.getById(authStore.getInitialInfo?.identity?.id as number),
    () => {},
    (x) => updater.setInitialState(x!)
  );

  const updater = useUpdateWithController(api.user);

  return (
    <>
      <PageHeader title={t("common:page_title.settings_profile")}></PageHeader>
      <PageContent
        tabs={authStore.getInitialInfo?.settingsTabs}
        isFilled
        useTranslationInTabs
      >
        <div
          // divider={<divider sx={{ opacity: 0.5 }} orientation="vertical" flexItem />}
          className="d-stack-row spacing-4"
          style={{ height: "100%" }}
        >

            <EmployeeEditForm />
          <div style={{ maxWidth: "444px", width: "100%", overflowY: "auto", maxHeight: "100%" }}>
            <CompaniesManageForm />
          </div>
        </div>
      </PageContent>
    </>
  );
}

export default observer(SettingsPage);
