import React, { useEffect } from "react";
import "./PlanningCollapseContentTable.scss";
import { Table } from "../../../../../../uiKit";
import { css } from "@emotion/css";
import { ICustomIssue } from "../PlanningCollapseContent.interface";
import { ColumnsType } from "antd/es/table";
import { InternalGoogleEventStatus } from "../../../../../../../api/models/CalendarEventDto";

interface IPlanningCollapseContentTableView {
  dataSource: ICustomIssue[];
  columns: ColumnsType<ICustomIssue>;
  className?: string;
}

export const PlanningCollapseContentTableView = (props: IPlanningCollapseContentTableView) => {
  const body = css({
    "& thead > tr > th": {
      color: "#7F85A2",
      fontSize: "12px",
    },
  });

  return (
    <div>
      <Table
        rowClassName={(record: ICustomIssue) => {
          return ((
            record.isDeleted ||
            record.isExcludedFromPlan ||
            record.issue.googleCalendarData?.internalGoogleEventStatus === InternalGoogleEventStatus.Declined
          ) ? "table-row-deleted" : "")
        }}
        // showHeader={!!props.dataSource?.find((i: ICustomIssueType) => i.issue.roleId)?.roleId}
        // virtual
        // scroll={{ x: 2000, y: 400 }}
        className={`${body} planning-table ${props.className}`}
        pagination={false}
        dataSource={props.dataSource}
        columns={props.columns}
        style={{ minWidth: "max-content" }}
      />
    </div>
  );
};
