import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

export interface IBoardResetDialogView {
  open: boolean;
  boardId: number;
  isLoading: boolean;
  handleReset: () => void;

  onClose(): void;
}

function BoardResetDialogView(props: IBoardResetDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog
      title={props.boardId == -1 ? t("ui:title.all_boards_reset") : t("ui:title.board_reset")}
      open={props.open}
      onClose={props.onClose}
      closable={false}
    >
      <h4>{props.boardId == -1 ? t("text:board.reset_all") : t("text:board.reset")}</h4>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button loading={props.isLoading} color="error" danger onClick={props.handleReset} variant="filled">
          {t("ui:button.reset")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BoardResetDialogView;
