export const basicColors = [
  "#d0021b",
  "#f5a623",
  "#f9dc46",
  "#8b572a",
  "#7ed321",
  "#417505",
  "#bd10e0",
  "#4a90e2",
  "#50e3c2",
  "#b8e986",
  "#000000",
  "#4a4a4a",
  "#9b9b9b",
  "#ffffff",
];

export const WIDTH = 250;
export const HEIGHT = 150;
