import React, { CSSProperties, useMemo } from "react";
import "../../Reports.scss";
import "../../../../../pages/driver/DriverAdminPage.scss";
import { driverReportsNames, IReportsDriver, unitOfMeasurementConst } from "../../Report.interface";
import { useTranslation } from "react-i18next";
import { DriverStatisticCard } from "../../../../../pages/driver/components/driverStatisticCard/DriverStatisticCard";
import { IDriverStatisticCard } from "../../../../../pages/driver/components/driverStatisticCard/DriverStatisticCard.interface";

export const ReportsDriver = ({ data }: IReportsDriver) => {
  const { t } = useTranslation();

  const cardStyles: CSSProperties = {
    padding: "16px",
    marginBottom: "16px",
    marginRight: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const getRoundNumber = (number: number): string | number => {
    return number % 1 === 0 ? number : number.toFixed(1);
  };

  const statsData: IDriverStatisticCard[] = useMemo(
    () => [
      {
        title: t(driverReportsNames.writesCount),
        digit: data?.writesCount != undefined ? getRoundNumber(data.writesCount) : undefined,
        subtitle: t(unitOfMeasurementConst.times),
      },
      {
        title: t(driverReportsNames.callsCount),
        digit: data?.callsCount != undefined ? getRoundNumber(data?.callsCount) : undefined,
        subtitle: t(unitOfMeasurementConst.times),
      },
      {
        title: t(driverReportsNames.fixedViolations),
        digit: data?.fixedViolations != undefined ? getRoundNumber(data?.fixedViolations) : undefined,
        subtitle: "",
      },
      {
        title: t(driverReportsNames.processAvgTimeInMinutes),
        digit: data?.processAvgTimeInMinutes != undefined ? getRoundNumber(data?.processAvgTimeInMinutes) : undefined,
        subtitle: t(unitOfMeasurementConst.minutes),
      },
      {
        title: t(driverReportsNames.overallTimeSavedInHours),
        digit: data?.overallTimeSavedInHours != undefined ? getRoundNumber(data?.overallTimeSavedInHours) : undefined,
        subtitle: t(unitOfMeasurementConst.hours),
      },
    ],
    [data]
  );

  return (
    <>
      <div style={{ flexGrow: 1, paddingRight: "8px" }} className="driver-admin-page__column__content">
        {/*{overallData.value.}*/}
        <div className="d-stack-row spacing-2" style={{ flexWrap: "wrap" }}>
          {statsData.map((item) => (
            <React.Fragment key={item.title}>
              <DriverStatisticCard digit={item.digit} title={item.title} subtitle={item.subtitle} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
