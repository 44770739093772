import React, { useEffect, useState } from "react";
import { useNotifier } from "../../../../../hooks";
import OrgchartTypeSelectFormView from "./OrgchartTypeSelectFormView";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../services";
import { OrgchartTemplateDto } from "../../../../../api";
import {
  ORGCHART_CLASSIC_TYPE,
  ORGCHART_DEFAULT_TYPE,
  ORGCHART_SIMPLIFIED_TYPE
} from "../../../../../constants/orgchartTypes";
import { OrgchartTemplateTypeEnum } from "../../../../../api/models/OrgchartTemplateTypeDto";

interface IOrgchartTypeSelectForm {
  onCreate?: (type: any) => void;
}

function OrgchartTypeSelectForm(props: IOrgchartTypeSelectForm) {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [selectedOrgchartType, setSelectedOrgchartType] = useState<OrgchartTemplateTypeEnum | null>(null);
  const [selectedTemplateKey, setSelectedTemplateKey] = useState<string | null>(null);
  const [templates, setTemplates] = useState<OrgchartTemplateDto[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSave = async () => {
    setIsLoading(true);
    if (selectedOrgchartType == null) return;
    props.onCreate?.(selectedOrgchartType === ORGCHART_DEFAULT_TYPE ? selectedOrgchartType : selectedTemplateKey);
    // const r = await api.company.applyOrgchartTemplate(selectedOrgchartType);
    // if (r) {
    //   await authStore.refreshInitialInfo();
    //   orgchartContext?.refreshState();
    // } else {
    //   notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    // }
  };

  const handleGetClassicalTemplates = async () => {
    setIsLoading(true);
    const r = await api.orgchart.getClassicalTemplates();
    if (r === null) notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    setTemplates(r!);
    setIsLoading(false);
  };

  const handleGetSimplifiedTemplates = async () => {
    setIsLoading(true);
    const r = await api.orgchart.getSimplifiedTemplates();
    if (r === null) notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    setTemplates(r!);
    setIsLoading(false);
  };

  const getTemplatesList = async (type: OrgchartTemplateTypeEnum | null) => {
    if (type) {
      setIsLoading(true);
      const r = await api.orgchart.getTemplates(type);
      if (r === null) notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      setTemplates(r!);
      setIsLoading(false);
    }
  };

  const handleGetTemplates = () => {
    if (selectedOrgchartType === ORGCHART_CLASSIC_TYPE || ORGCHART_SIMPLIFIED_TYPE) {
      return getTemplatesList(selectedOrgchartType);
    }
    return undefined;
  };

  // useEffect(() => {
  //   handleGetTemplates();
  // }, [selectedOrgchartType]);

  return (
    <OrgchartTypeSelectFormView
      templates={templates}
      setTemplates={setTemplates}
      selectedOrgchartType={selectedOrgchartType}
      selectedTemplateKey={selectedTemplateKey}
      setSelectedOrgchartType={setSelectedOrgchartType}
      setSelectedTemplateKey={setSelectedTemplateKey}
      handleSave={handleSave}
      isLoading={isLoading}
    />
  );
}

export default OrgchartTypeSelectForm;
