import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect } from "react";
import { $createCustomNode, CustomNode } from "../../nodes/CustomNode";

export const INSERT_VIDEO_COMMAND: LexicalCommand<string> = createCommand("INSERT_VIDEO_COMMAND");

export default function VideoPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([CustomNode])) {
      throw new Error("VideoPlugin: CustomNode not registered on editor");
    }
    return editor.registerCommand<string>(
      INSERT_VIDEO_COMMAND,
      (payload) => {
        const customNode = $createCustomNode("video", payload, "");
        $insertNodes([customNode]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
