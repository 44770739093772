import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Select, Spin } from "../../../../../../../uiKit";
import { IssueHistoryCard } from "../issueHistoryCard/IssueHistoryCard";
import { EmptyBlock } from "../../../../../../layouts/emptyBlock/EmptyBlock";
import ScrollTrigger from "../../../../../../../service/scrollTrigger/ScrollTrigger";
import { IIssueHistoryListCommentsView } from "./IssueHistoryListComments.interface";
import { IssueContext } from "../../../../../../../../contexts/communication/issueContext";
import { UserShortDto } from "../../../../../../../../api";
import { IssueActivityItem } from "../issueActivity/IssueActivityItem";
import "./IssueHistoryListComments.scss";
import { TabsFilter } from "../../../../../../../uiKit/tabsFilter/tabsFilter";
import { TabFilter } from "../../../../../../../uiKit/tabsFilter/tabsFilter.interface";
import { FilterIssueHistoryKeys } from "../../../../../types/consts";

export const IssueHistoryListCommentsView = memo((props: IIssueHistoryListCommentsView) => {

  const historyList = props.isOldHistory ? props.historyItems : props.issueActivityItems;
  const isEmpty = historyList.length == 0 && props.isDone;

  const tabsFilter: TabFilter[] = props.filterItems.map(({  value, text: label }) => ({
    value,
    label,
  }));

  const handleChangeFilter = (activeTab: string | number | undefined) => {
    props.onChangeFilter(activeTab as FilterIssueHistoryKeys)
  }

  return (
    <>
      <div className="d-flex d-stack-row align-end justify-space-between">
        {props.isOldHistory ? (
          <div className="custom-select">
            <Select
              size={"middle"}
              style={{ minWidth: 170 }}
              items={props.filterItems}
              value={props.currentFilter}
              onChange={props.onChangeFilter}
            />
          </div>
        ) : (
          <div className={"mb-6"}>
            <TabsFilter
              tabs={tabsFilter}
              activeTab={props.currentFilter}
              onChange={handleChangeFilter}
            />
          </div>
        )}
      </div>
      <Spin spinning={props.isLoading} size="large">
        <div
          className="issue-activity-list"
          style={{
            minHeight: 395,
            // overflowY: "auto",
            width: "100%",
            flexGrow: 1,
            marginTop: 8,
          }}
        >
          {props.isOldHistory
            ? props.historyItems.length > 0 &&
            props.historyItems.map((issueHistoryItem, index) => {
              return (
                <IssueHistoryCard
                  id={issueHistoryItem.id}
                  issueHistoryItem={issueHistoryItem}
                  index={index}
                  reactions={issueHistoryItem.reactions}
                  dateCreated={issueHistoryItem.dateCreated}
                  dateUpdated={issueHistoryItem.dateUpdated}
                  dateDeleted={issueHistoryItem.dateDeletedAt}
                  isDeleted={issueHistoryItem.isDeleted}
                  key={issueHistoryItem.id}
                  logs={issueHistoryItem.logs}
                  createdByUser={issueHistoryItem.createdByUser}
                  comment={issueHistoryItem?.comment?.text}
                  users={issueHistoryItem?.comment?.recipients
                    ? issueHistoryItem?.comment?.recipients?.map((item) => item?.user) as UserShortDto[]
                    : []
                  }
                  actions={issueHistoryItem?.actions}
                  isRead={issueHistoryItem?.isRead}
                  status={issueHistoryItem?.status}
                  attachments={issueHistoryItem.comment?.attachments ?? []}
                  onEditComment={props.onEditComment}
                  onIssueHistoryIdChange={props.onIssueHistoryIdChange}
                  onOpenDeleteCommentDialog={props.onOpenDeleteCommentDialog}
                  currentStatusId={props.currentStatusId}
                  currentFilter={props.currentFilter}
                  editingCommentId={props.editingCommentId}
                  onEditingCommentIdChange={props.onEditingCommentIdChange}
                />
              );
            })
            : (props.issueActivityItems ?? []).map((item) => (
              <IssueActivityItem
                item={item}
                key={item.issueId}
                onEditComment={props.onEditComment}
                onDeleteComment={props.onDeleteComment}
                onIssueHistoryIdChange={props.onIssueHistoryIdChange}
                issueHistoryId={props.issueHistoryId}

              />
            ))}
          {isEmpty && <EmptyBlock />}
          <div style={{ width: "100%" }}>
            <ScrollTrigger
              disabled={props.isDone}
              onIntersection={props.handleLoadNext}
              marginTop={historyList.length > 0}
              hidden={props.isDone}
            />
          </div>
        </div>
      </Spin>
    </>
  );
});
