import React, { useState } from "react";
import BoardResetDialogView from "./BoardResetDialogView";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";

export interface IBoardResetDialog {
  open: boolean;
  boardId: number;

  onClose(): void;
  onReset(): void;
}

function BoardResetDialog(props: IBoardResetDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleReset = async () => {
    if (props.boardId == -1) {
      await handleResetAllBoards();
      return;
    }

    setIsLoading(true);
    const r = await api.adminCompany.resetBoard(props.boardId);
    notifier.showSuccessError(t("notifier:success.board_reset"), t("notifier:error.board_reset"), !!r);
    setIsLoading(false);
    if (r != null) {
      props.onReset();
      props.onClose();
    }
  };

  const handleResetAllBoards = async () => {
    setIsLoading(true);
    const r = await api.adminCompany.resetAllBoards();
    notifier.showSuccessError(t("notifier:success.boards_reset"), t("notifier:error.boards_reset"), !!r);
    setIsLoading(false);
    if (r != null) {
      props.onReset();
      props.onClose();
    }
  };

  return (
    <BoardResetDialogView
      isLoading={isLoading}
      boardId={props.boardId}
      handleReset={handleReset}
      onClose={props.onClose}
      open={props.open}
    />
  );
}

export default BoardResetDialog;
