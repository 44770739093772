import { RegulationContentDto, RegulationDto, SectionDto } from "../../../../../api";

export const isPassedTest = (regulation: RegulationDto | undefined, userId: number | undefined): boolean =>
  !!regulation?.usersWhoStudied?.find((item) => item.id === userId);

export const handleCountQuestions = (content: RegulationContentDto | undefined): number =>
  content?.questions?.length ?? 0;

export const handleGetAllRegulationsIdFromSection = (section: SectionDto | undefined): number[] =>
  section?.regulations?.map((item) => item.regulationId!) ?? [];

export const handleGetNoLearntRegulationsIdFromSection = (section: SectionDto | undefined): number[] =>
  section?.regulations?.filter((item) => !item?.regulation?.isStudied).map((item) => item.regulationId!) ?? [];

export const handleGetAllRegulationsIdsWithoutThis = (
  section: SectionDto | undefined,
  thisRegulationId: number
): number[] =>
  section?.regulations?.filter((item) => item.id !== thisRegulationId).map((item) => item.regulationId!) ?? [];

