import React from "react";
import "./OrgchartFunctionDrawer.scss";
import { useTranslation } from "react-i18next";
import { IOrgchartDrawerTab, TOrgchartDrawerTabKey } from "./OrgchartFunctionDrawer";
import { useClaims, useRootStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { Drawer, Empty, TabPane, Tabs } from "../../../../uiKit";
import { OrgchartFunctionDrawerHeader } from "../orgchartFunctionDrawerHeader/OrgchartFunctionDrawerHeader";
import { RoleDto } from "../../../../../api";
import { OrgchartDrawerTabPermissions } from "../orgchartDrawerTabs/orgchartDrawerTabPermissions/OrgchartDrawerTabPermissions";
import OrgchartDrawerTabRecurringTasks from "../orgchartDrawerTabs/orgchartDrawerTabRecurringTasks/OrgchartDrawerTabRecurringTasks";
import { If } from "../../../../service/conditionStatement";
import { OrgchartDrawerTabRegulations } from "../orgchartDrawerTabs/orgchartDrawerTabRegulations/OrgchartDrawerTabRegulations";
import OrgchartDrawerTabEmployee from "../orgchartDrawerTabs/orgchartDrawerTabEmployee/OrgchartDrawerTabEmployee";
import { OrgchartDrawerTabMetrics } from "../orgchartDrawerTabs/orgchartDrawerTabMetrics/OrgchartDrawerTabMetrics";

interface IOrgchartFunctionDrawerView {
  isOpen: boolean;
  roleId: number;
  roles: RoleDto[];
  currentRole: RoleDto | undefined;
  currentTabKey: TOrgchartDrawerTabKey | null;
  drawerTabs: IOrgchartDrawerTab[];
  isLoading: boolean;

  onCurrentTabChange: (newTabKey: TOrgchartDrawerTabKey) => void;
  onRoleChange: (role: RoleDto, withoutApiCall?: boolean) => void;
  onClose: () => void;
  // tabs: AppTabType[];
  // currentTabId: number;
  // functionData: RoleDto | null;
  // updater: any;
  // changeSubfunctionsColors: boolean;
  //
  // setChangeSubfunctionsColors: (value: boolean) => void;
  // handleFunctionChildrenDirectionSave: (direction: "h" | "v") => void;
  // handleFunctionColorSave: (color: string) => void;
  // setFunctionData: (data: RoleDto) => void;
  // refreshData: () => void;
  // onTabChange: (tabId: number) => void;
}

function OrgchartFunctionDrawerViewObserved(props: IOrgchartFunctionDrawerView) {
  const { t } = useTranslation();
  const { hintStore, helperStore, authStore, orgchartStore } = useRootStore();
  const claims = useClaims();

  return (
    <Drawer
      closable={true}
      open={props.isOpen}
      className="orgchart-function-drawer__wrapper"
      contentWrapperStyle={{
        maxWidth: "1200px",
        width: "100%",
      }}
      destroyOnClose
      title={
        <OrgchartFunctionDrawerHeader
          role={props.currentRole}
          currentTabKey={props.currentTabKey}
          drawerTabs={props.drawerTabs}
          onCurrentTabChange={props.onCurrentTabChange}
          onRoleChange={props.onRoleChange}
        />
      }
      headerStyle={{ paddingBottom: 0, alignItems: "flex-start" }}
      onClose={props.onClose}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        padding: "12px 0",
      }}
    >
      {props.currentRole == null || props.roleId == null ? (
        <Empty />
      ) : (
        <Tabs
          activeKey={props.currentTabKey ?? undefined}
          destroyInactiveTabPane={false}
          className="orgchart-function-drawer__tabs_content"
          tabBarStyle={{ display: "none" }}
          style={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          <TabPane key="recurringTasks">
            <If condition={props.drawerTabs.some((tab) => tab.tabKey == "recurringTasks")}>
              <OrgchartDrawerTabRecurringTasks currentRole={props.currentRole} />
            </If>
          </TabPane>
          <TabPane key="employees">
            <If condition={props.drawerTabs.some((tab) => tab.tabKey == "employees")}>
              <OrgchartDrawerTabEmployee functionData={props.currentRole} onRoleChange={props.onRoleChange} />
            </If>
          </TabPane>
          <TabPane key="permissions">
            <If condition={props.drawerTabs.some((tab) => tab.tabKey == "permissions")}>
              <OrgchartDrawerTabPermissions
                isLoading={props.isLoading}
                currentRole={props.currentRole}
                onRoleChange={props.onRoleChange}
              />
            </If>
          </TabPane>
          <TabPane key="metrics">
            <If condition={props.drawerTabs.some((tab) => tab.tabKey == "metrics")}>
              <OrgchartDrawerTabMetrics roleId={props.roleId} />
            </If>
          </TabPane>
          <TabPane key="regulations">
            <If condition={props.drawerTabs.some((tab) => tab.tabKey == "regulations")}>
              <OrgchartDrawerTabRegulations roleId={props.roleId} />
            </If>
          </TabPane>
        </Tabs>
      )}
    </Drawer>
  );
}

export const OrgchartFunctionDrawerView = observer(OrgchartFunctionDrawerViewObserved);
