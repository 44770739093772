import React, { memo } from "react";
import { useApi } from "../../../../hooks";
import { api } from "../../../../services";

export interface IRoleComponent {
  id: number;
  color: string;
}

export const RoleComponent = memo((props: IRoleComponent) => {
  const role = useApi(() => api.role.getById(props.id));

  return (
    <span
      contentEditable={false}
      style={{
        pointerEvents: "none",
        userSelect: "none",
        backgroundColor: props.color,
        borderRadius: "4px",
        padding: "2px",
        verticalAlign: "middle",
      }}
    >
      {role.value?.name}
    </span>
  );
});
