import React, { useCallback } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Button } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { CLEAR_EDITOR_COMMAND, CLEAR_HISTORY_COMMAND, EditorState } from "lexical";

export default function ActionsPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();

  const readTextFileFromSystem = (callback: (text: string) => void) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";
    input.addEventListener("change", (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files) {
        const file = target.files[0];
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");

        reader.onload = (readerEvent) => {
          if (readerEvent.target) {
            const content = readerEvent.target.result;
            callback(content as string);
          }
        };
      }
    });
    input.click();
  };

  // Adapted from https://stackoverflow.com/a/19328891/2013580
  const exportBlob = (data: EditorState, fileName: string) => {
    const a = document.createElement("a");
    const body = document.body;

    if (body === null) {
      return;
    }

    body.appendChild(a);
    a.style.display = "none";
    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: "octet/stream",
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  const handleImportFile = useCallback(() => {
    readTextFileFromSystem((text) => {
      const json = JSON.parse(text);
      const editorState = editor.parseEditorState(JSON.stringify(json));
      editor.setEditorState(editorState);
      editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
    });
  }, [editor]);

  const handleExportFile = useCallback(() => {
    const now = new Date();
    const editorState = editor.getEditorState();
    const config = {
      fileName: `TextEditorFile ${new Date().toISOString()}`,
      source: "Text Editor",
    };
    // const documentJSON: DocumentJSON = {
    //   editorState: editorState,
    //   // lastSaved: now.getTime(),
    //   // source: config.source || "Lexical",
    //   // version: 1,
    // };
    const fileName = config.fileName || now.toISOString();
    exportBlob(editorState, `${fileName}.json`);
  }, [editor]);

  const handleClearEditor = useCallback(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    editor.focus();
  }, [editor]);

  return (
    <div className="actions">
      <Button className="mr-2" onClick={handleImportFile}>
        {t("ui:button.upload")}
      </Button>
      <Button className="mr-2" onClick={handleExportFile}>
        {t("ui:button.save")}
      </Button>
      <Button onClick={handleClearEditor} type="default">
        {t("ui:button.clear")}
      </Button>
    </div>
  );
}
