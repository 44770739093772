import { Tree, TreeProps } from "antd";
import React from "react";
import "./Tree.scss";
import clsx from "clsx";

interface ITreeViewProps extends TreeProps {
  //
}

export const TreeView = ({ className, ...rest }: ITreeViewProps) => {
  return <Tree className={clsx("uiKit-tree", className ?? "")} {...rest} />;
};
