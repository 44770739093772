import React, { memo, useEffect, useMemo, useState } from "react";
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from "antd";
import {  ISortTablePropsType, RowProps } from "./SortTable.interface";
import { NavigationMenuItemDto } from "../../../api/models/NavigationMenuDto";



const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export const SortTable = memo(({ items, columns, handleDragEnd }: ISortTablePropsType) => {
  const [dataSource, setDataSource] = useState<NavigationMenuItemDto[]>(items);

  useEffect(() => {
    setDataSource(items);
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        const newList = arrayMove(prev, activeIndex, overIndex);
        const newActiveIndex = newList.findIndex((i) => i.id === active.id);
        const afterElement = newActiveIndex > 0 ? prev[newActiveIndex - 1] : undefined;
        const ids = newList.map((item) => item.id);
        handleDragEnd(+active.id, afterElement ? +afterElement.id : -1, ids);
        return newList;
      });
    }
  };

  const dataSourceItems = useMemo(() => {
    return dataSource.map((i) => i.id)
  }, [dataSource])

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={dataSourceItems}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: Row,
            },
          }}
          pagination={false}
          rowKey="id"
          // @ts-ignore
          columns={columns}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  );
});