import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./SettingUpAccessDialog.scss";
import { ISettingUpAccessDialog, keysRulesFor } from "./SettingUpAccessDialog.interface";
import { Button, Dialog, DialogActions } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import ExecutorSelect from "../../../elements/executorSelect/ExecutorSelect";
import { UserSelectionRule } from "../../../../api/types/userSelectionRule";
import { useNotifier, useRootStore } from "../../../../hooks";
import { handleAddRuleBtnIsDisabled } from "./helpers";
import { areArraysEqual } from "../../../../helpers/arrayFunctions";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { ExecutorSelectNew } from "../../../elements/executorSelectNew/ExecutorSelectNew";
import { api } from "../../../../services";
import { RoleSelectorDto } from "../../../../api/models/RoleSelectorDto";
import { useOrgchart } from "../../pages/regulation/hooks/useOrgchart";
import { SettingUpAccessDialogContext } from "./SettingUpAccessDialogContext";

export const SettingUpAccessDialog = (props: ISettingUpAccessDialog) => {
  const {
    isLoading,
    fetchOrgchartData,
    treeData,
    expandedKeys,
    setExpandedKeys,
    selectedRoles,
    setSelectedRoles,
    updateTreeItemData,
    updateRolePosition,
  } = useOrgchart();

  const notifier = useNotifier();

  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const [value, setValue] = useState<RoleSelectorDto[]>([]);
  const prevSelectorRoles = useRef<RoleSelectorDto[]>([]);

  const handleGetTitle = () => {
    if (props.keyRuleTo === keysRulesFor.section)
      return props.title ? `${props.title} - ${t("ui:title.access_to_section")}` : t("ui:title.access_to_section");
    if (props.keyRuleTo === keysRulesFor.regulation)
      return props.title ? `${props.title} - ${t("ui:title.assign_study")}` : t("ui:title.assign_study");
  };
  const isSupportOldUi = useMemo(() => {
    return authStore.getCurrentCompanyUiType == 1;
  }, [authStore.getCurrentCompanyUiType]);

  const addRules = useCallback((roles: RoleSelectorDto[]) => {
    setValue(roles);
  }, []);

  const changeRules = useCallback((roles: RoleSelectorDto[]) => {
    setValue(roles);
  }, []);

  const handleRemoveRole = useCallback((roleId: number, positionId?: number) => {
    if (isSupportOldUi) {
      const currentPosition =  value.find((item) => item.roleId === roleId);

      if (currentPosition && positionId) {
        const { selectedPositions = [] } = currentPosition;
        const selectedPositionsList = selectedPositions.includes(positionId)
          ? selectedPositions.filter((item: number) => item !== positionId)
          : [...selectedPositions, positionId];

        updateRolePosition(treeData, `${roleId}`, selectedPositionsList);
      } else {
        updateTreeItemData({
          list: treeData,
          key: `${roleId}`,
          isSelected: false,
          includeNestedRoles: true,
        });

      }
    } else {
      updateTreeItemData({
        list: treeData,
        key: `${roleId}`,
        isSelected: false,
        includeNestedRoles: true,
      });
    }
    setValue((prev) => {
      if (isSupportOldUi) {
        const list = prev.map((role) => {
          const { selectedPositions = [] } = role;
          const currentPosition = positionId ? selectedPositions.includes(positionId) : role.roleId === roleId;
          if (currentPosition) {
            return selectedPositions.length > 1
              ? {
                ...role,
                selectedPositions: selectedPositions.filter((id) => id !== positionId),
              }
              : null;
          }
          return role;
        }).filter((item) => item !== null);
        return list as RoleSelectorDto[];
      }
      return prev.filter((role) => role.roleId !== roleId);
    });
  }, [treeData, value]);

  const fetchRoleForStudy = useCallback(async (id: number, isRegular: boolean) => {
    const list = isRegular
      ? await api.regulation.getRoleForStudy(id)
      : await api.navigationMenu.getRoleForStudy(id);
    if (list !== null) {
      prevSelectorRoles.current = list;
      setValue(list);
    }
  }, []);

  useEffect(() => {
    if (props.regulationId || props.sectionId) {
      const id = props.regulationId || props.sectionId
      fetchRoleForStudy(id!, !!props.regulationId)
    }
  }, [props.regulationId, props.sectionId]);

  const [isLoader, setIsLoader] = useState<boolean>(false);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });


  const handleSaveAccessSettings = useCallback(async () => {
    const id = props.regulationId || props.sectionId;
    if ( props.regulationId || props.sectionId ) {
      setIsLoader(true);
      const data = value.map((item) => {
        if (item.includeNestedRoles || !isSupportOldUi) {
          return {
            ...item,
            selectedPositions: undefined,
          }
        }
        return item;
      })
      const status = props.regulationId
        ? await api.regulation.sendForStudy(props.regulationId, data)
        : await api.navigationMenu.sendForStudy(props.sectionId!, data);
      console.log("status1", status);
      setIsLoader(false);
      if (status === null) {
        handleApiError();
      } else {
        props.onClose(true);
      }
    }
  }, [value, props.regulationId, props.sectionId]);

  const handleClose = useCallback(() => props.onClose(), []);

  return (
    <Dialog
      title={handleGetTitle()}
      open={props.isOpen}
      onClose={handleClose}
      closable={false}
    >
      <SettingUpAccessDialogContext.Provider value={{
        isLoading,
        fetchOrgchartData,
        treeData,
        // handleLoadData,
        expandedKeys,
        setExpandedKeys,
        selectedRoles,
        setSelectedRoles,
        updateTreeItemData,
        updateRolePosition,
      }}>
        <ExecutorSelectNew
          selectedRulesList={value}
          onChange={addRules}
          // changeRules={changeRules}
          handleRemoveRole={handleRemoveRole}
          itemId={props.regulationId || props.sectionId}
          isRegular={!!props.regulationId}
        />
        </SettingUpAccessDialogContext.Provider>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            {t("ui:button.cancel")}
          </Button>
          <Button
            loading={isLoader}
            disabled={areArraysEqual(prevSelectorRoles.current ?? [], value)}
            onClick={handleSaveAccessSettings}
            variant="filled"
          >
            {t("ui:button.save")}
          </Button>
        </DialogActions>
    </Dialog>
  );
}