import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BoardStatusTransitionActionDto } from "../../../../api";
import CloseTaskWithoutProofConfirmationDialog from "../closeTaskWithoutProofConfirmationDialog/CloseTaskWithoutProofConfirmationDialog";
import { actionsKeysConst } from "../../forms/types/consts";
import { Button, Dialog, DialogActions, Spin } from "../../../uiKit";

interface IIssueRequiredActionsDialogView {
  open: boolean;
  isNoProofDialogConfirmationOpen: boolean;
  // boardData: BoardStatusDto | null;
  boardData: BoardStatusTransitionActionDto | null;
  requiredActionsList: { key: string; name: string; element: any }[];
  currentTabKey: string;
  isLoading: boolean;
  resetFunctionsList: () => void;
  setIsNoProofDialogConfirmationOpen: (value: boolean) => void;
  handleForceSaveClick: () => void;
  onNextClick: (force?: boolean) => void;
  onClose: () => void;
}

function IssueRequiredActionsDialogView(props: IIssueRequiredActionsDialogView) {
  const { t } = useTranslation();
  // const { strictDeadline, senderDeadlineExpired } = useContext(IssueContext);

  const handleGetDialogTitle = useCallback(() => {
    return props.requiredActionsList?.find((a) => a.key === props.boardData?.baseTransitionActionKey)?.name;
  }, [props.boardData?.baseTransitionActionKey, props.requiredActionsList]);

  return (
    <Dialog
      // title={t("common:tab.board_required_actions.tab_text")}
      title={handleGetDialogTitle()}
      open={props.open}
      onClose={props.onClose}
      width={660}
      // bodyStyle={{ overflowX: "hidden" }}
      scrollStrategy="dialogBody"
      closable={false}
      destroyOnClose
      maskClosable={false}
      // title={
      //   <Tabs size="small" activeKey={props.currentTabKey} onChange={() => {}} tabBarStyle={{ marginBottom: 0 }}>
      //     {props.boardData?.requiredActions?.map((action) => (
      //       <TabPane
      //         tab={
      //           props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.name ??
      //           action.baseTransitionActionKey!
      //         }
      //         disabled={action.baseTransitionActionKey !== props.currentTabKey}
      //         key={action.baseTransitionActionKey}
      //         // children={props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.element()}
      //       />
      //     ))}
      //   </Tabs>
      // }
    >
      <CloseTaskWithoutProofConfirmationDialog
        open={props.isNoProofDialogConfirmationOpen}
        onApply={() => {
          props.setIsNoProofDialogConfirmationOpen(false);
          props.handleForceSaveClick();
        }}
        onClose={() => {
          if (props.currentTabKey === actionsKeysConst.addProof) {
            props.resetFunctionsList();
          }
          props.setIsNoProofDialogConfirmationOpen(false);
        }}
      />
      <Spin spinning={props.isLoading}>
        <div>
          {/*{props.boardData?.requiredActions?.map((action) => (*/}
          <div
            // tab={
            //   props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.name ??
            //   action.baseTransitionActionKey!
            // }
            // disabled={action.baseTransitionActionKey !== props.currentTabKey}
            // key={props.boardData?.baseTransitionActionKey}
            // children={props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.element()}
            children={props.requiredActionsList
              ?.find((a) => a.key == props.boardData?.baseTransitionActionKey)
              ?.element()}
          />
          {/*))}*/}
          {/*<Tabs size="small" activeKey={props.currentTabKey} onChange={() => {}}>*/}
          {/*  {props.boardData?.requiredActions?.map((action) => (*/}
          {/*    <TabPane*/}
          {/*      // tab={*/}
          {/*      //   props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.name ??*/}
          {/*      //   action.baseTransitionActionKey!*/}
          {/*      // }*/}
          {/*      disabled={action.baseTransitionActionKey !== props.currentTabKey}*/}
          {/*      key={action.baseTransitionActionKey}*/}
          {/*      children={props.requiredActionsList?.find((a) => a.key == action.baseTransitionActionKey)?.element()}*/}
          {/*    />*/}
          {/*  ))}*/}
          {/*</Tabs>*/}
        </div>
      </Spin>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          //disabled={strictDeadline && senderDeadlineExpired && props.currentTabKey == actionsKeysConst.setDateWorkStart}
          loading={props.isLoading}
          onClick={() => props.onNextClick()}
          variant="filled"
        >
          {/*{props.boardData?.requiredActions &&*/}
          {/*  (props.currentTabKey ==*/}
          {/*  props.boardData.requiredActions[props.boardData.requiredActions.length - 1].baseTransitionActionKey*/}
          {/*    ? t("ui:button.save")*/}
          {/*    : t("ui:button.next"))}*/}
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IssueRequiredActionsDialogView;
