import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./IssueUpdateContainer.scss";
import { IChangeableExecutors, IIssueUpdateContainer } from "./IssueUpdateContainer.interface";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { IssueCustomFieldDto, IssueDto, StaticFileDto, type UserShortDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { actionsKeysConst, blockedFieldsKeys, IssueActionConsts } from "../../../types/consts";
import { IssueContext } from "../../../../../../contexts/communication/issueContext";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { IssueWithdrawDialog } from "../../../../dialogs/issueWithdrawDialog/IssueWithdrawDialog";
import { IssueExecutorChangeDialog } from "../../../../dialogs/issueExecutorChangeDialog/IssueExecutorChangeDialog";
import { IssueCopyDialog } from "../../../../dialogs/issueCopyDialog/issueCopyDialog";
import { IssueFormWrapper } from "../../forms/common/IssueFormWrapper";
import { IssueHistoryModule } from "../../modules/IssueHistoryModule/IssueHistoryModule";
import { IssueFormKeys } from "../../forms/common/IssueForm.interface";
import { IssueHeader } from "../../components/issueHeader/IssueHeader";
import { getDeleteIssueTitle, getDialogNotifications, getUpdateFormConfigByIssueType } from "../../helpers";
import { BroadcastChannel } from "broadcast-channel";
import { IssueArchiveDialog } from "../../../../dialogs/issueArchiveDialog/issueArchiveDialog";
import IssueRequiredActionsDialog from "../../../../dialogs/issueRequiredActionsDialog/IssueRequiredActionsDialog";
import { WarningsKeys } from "../../utils/keys/warningsKeys";
import dayjs from "dayjs";
import { getWarningData } from "../../utils/data";
import { getObjectFromTimeSpan } from "../../../../../../helpers/dateFunctions";
import { use } from "i18next";

export const IssueUpdateContainer = (props: IIssueUpdateContainer) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, helperStore, issueInitDataStore } = useRootStore();
  const issueChannel = new BroadcastChannel("issue");
  const issueArchiveChannel = new BroadcastChannel("issueArchive");


  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  const [isWithdrawIssueDialogOpen, setIsWithdrawIssueDialogOpen] = useState<boolean>(false);
  const [isCopyIssueDialogOpen, setIsCopyIssueDialogOpen] = useState<boolean>(false);
  const [attachmentsIssue, setAttachmentsIssue] = useState<StaticFileDto[]>([]);
  const [openDialogDeleteMessage, setOpenDialogDeleteMessage] = useState<boolean>(false);
  const [isExecutorChangeDialogOpened, setIsExecutorChangeDialogOpened] = useState<boolean>(false);
  const [changeableExecutors, setChangeableExecutors] = useState<IChangeableExecutors | undefined>(undefined);

  const handlerApiError = useCallback(() => {
    return notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  }, [])

  const handleExecutorChangeDialogSuccess = async (reason: string) => {
    //props.updater.applyChanges
    await props.updater.update();
    //setForceUpdate(true);
    setChangeableExecutors(undefined);
    setIsExecutorChangeDialogOpened(false);
    const r = await api.issueHistory.create({
      issueId: props.issueId,
      comment: { text: reason },
    });
    if (r == null) return handlerApiError();
  };

  const handleExecutorChangeDialogClosed = (status: boolean) => {
    if (!changeableExecutors) {
      return;
    }
    const { prevExecutorId, nextExecutorId, prevExecutorUser, nextExecutorUser } = changeableExecutors;
    const executorUserId = status ? nextExecutorId : prevExecutorId;
    const executorUser = status ? nextExecutorUser : prevExecutorUser;

    props.updater.applyChanges({
      executorUserId,
      dateDeadline: props.updater.beforeState?.dateDeadline,
      dateWorkStart: props.updater.beforeState?.dateWorkStart,
      executorUser: executorUser ?? null,
    });
    setIsExecutorChangeDialogOpened(false);
    setChangeableExecutors(undefined);
  };

  const handleChangeExecutor = (
    prevExecutorId: number,
    nextExecutorId: number,
    prevExecutorUser: UserShortDto | null = null,
    nextExecutorUser: UserShortDto | null = null,
  ) => {
    if (nextExecutorId === props.updater?.currentState?.executorUserId) return;
    setIsExecutorChangeDialogOpened(true);
    setChangeableExecutors({ prevExecutorId, nextExecutorId, prevExecutorUser, nextExecutorUser });
    props.updater.applyChanges({ executorUserId: nextExecutorId, executorUser: nextExecutorUser });
  };

  const onCloseWithdrawIssueDialog = useCallback(() => {
    setIsWithdrawIssueDialogOpen(false);
  }, []);
  const handleOpenWithdrawIssueDialog = useCallback(() => {
    setIsWithdrawIssueDialogOpen(true);
  }, []);
  const onCloseCopyIssueDialog = useCallback(() => {
    setIsCopyIssueDialogOpen(false);
  }, []);
  const handleOpenCopyIssueDialog = useCallback(() => {
    setIsCopyIssueDialogOpen(true);
  }, []);

  const [isArchiveIssueDialogOpen, setIsArchiveIssueDialogOpen] = useState<boolean>(false);
  const handleOpenSendForArchiveDialog = useCallback(() => {
    setIsArchiveIssueDialogOpen(true);
  }, []);
  const onCloseArchiveIssueDialog = useCallback(() => {
    setIsArchiveIssueDialogOpen(false);
  }, []);

  const handleToggleDeleteConfirmDialog = useCallback((open: boolean) => {
    setOpenDialogDeleteMessage(open);
  }, []);

  const setAttachmentsIssueSync = useCallback(
    (files: StaticFileDto[]) => {
      const newAttachments = [
        ...(props.updater?.currentState?.attachments ?? []),
        ...files
          .filter((f) => !props.updater?.currentState?.attachments?.some((f2) => f2.fileId == f.id))
          .map((f, i) => ({
            id: 0,
            issueId: props.updater?.currentState?.id,
            fileId: f.id,
            file: f,
          })),
      ];
      props.updater.applyChanges({ attachments: [...newAttachments] });
      // setAttachmentsIssue(updater?.currentState?.attachments?.map(a => a.file)?.filter(x => x != null))
      setForceUpdate(true);
    },
    [props.updater?.currentState, props.updater.update, setForceUpdate]
  );

  const handleDeleteAttachmentsByFileId = useCallback(
    (fileId: number) => {
      props.updater.applyChanges({
        attachments: props.updater?.currentState?.attachments?.filter((item) => item.fileId != fileId) ?? [],
      });
      setForceUpdate(true);
    },
    [props.updater?.currentState, props.updater?.currentState?.attachments, props.updater.update, setForceUpdate]
  );

  const isExecutor = useMemo(
    () => authStore?.getInitialInfo?.identity?.id === props.updater?.currentState?.executorUserId,
    [authStore?.getInitialInfo?.identity?.id, props.updater?.currentState?.executorUserId]
  );

  const isContributor = useMemo(
    () =>
      authStore!.getInitialInfo?.identity?.id === props.updater?.currentState?.executorUserId ||
      authStore?.getInitialInfo?.identity?.id === props.updater?.currentState?.createdByUserId,
    [
      authStore?.getInitialInfo?.identity?.id,
      props.updater?.currentState?.createdByUserId,
      props.updater?.currentState?.executorUserId,
    ]
  );

  const subId = useMemo(() => props.updater.currentState?.subId, [props.updater.currentState?.subId]);

  const status = useMemo(() => props.updater.currentState?.status?.name, [props.updater.currentState?.status?.name]);

  const indicators = useMemo(
    () => props.updater.currentState?.calculated?.indicators,
    [props.updater.currentState?.calculated?.indicators]
  );

  const actions = useMemo(
    () => {
      return props.updater.currentState?.calculated?.actions
    },
    [props.updater.currentState?.calculated?.actions]
  );

  const customFields = useMemo(
    () => helperStore.getIssueCustomField.find((item) => item.type === props.issueType && !!item.items.length)?.items,
    [helperStore.getIssueCustomField]
  );

  const strictDeadline =
    !!props.updater.currentState?.fields?.length &&
    !!customFields?.length &&
    !!props.updater?.currentState?.fields?.find((item) => item.key === customFields[0].key)?.valueBool;

  const formConfig = useMemo(() => getUpdateFormConfigByIssueType(props.issueType), [props.issueType]);

  const formNotifications = useMemo(() => getDialogNotifications(props.issueType, t, subId ?? undefined), [props.issueType, subId]);

  const senderDeadlineExpired = props.updater?.currentState?.dateDeadline
    ? new Date() >= new Date(props.updater?.currentState?.dateDeadline)
    : undefined;

  const handleWithdrawIssue = useCallback(
    async (newExecutorId: number, deadline: Date | null) => {
      if (props.updater.currentState) {
        const newWithdrawIssue = props.updater.currentState;
        newWithdrawIssue.executorUserId = newExecutorId;
        newWithdrawIssue.dateDeadline = deadline && deadline.toJSON();
        const r = await api.issue.edit(props.updater.currentState.id!, newWithdrawIssue);
        if (r == null) return handlerApiError();
        notifier.show({
          message: formNotifications.onWithdraw,
          theme: "success",
        });
      }
    },
    [props.updater.currentState]
  );

  const handleCopyIssue = useCallback(
    async (roleId, executorId, orgchartId) => {
      const copiedIssue = {
        ...props.updater?.currentState,
        executorUserId: executorId,
        orgchart: undefined,
        roleId: roleId,
        orgchartId,
      };
      if (props.updater?.currentState?.proofRequirement) {
        copiedIssue.proofRequirement!.id = 0;
        copiedIssue.proofRequirement!.rules!.map((item) => {
          item.id = 0;
          return item;
        });
        copiedIssue.proofRequirementId = 0;
      }
      if (copiedIssue.fields && copiedIssue.fields.length > 0) {
        copiedIssue.fields = copiedIssue.fields.map((item) => ({ ...item, id: undefined }));
      }
      if (copiedIssue.attachments && copiedIssue.attachments.length) {
        copiedIssue.attachments = copiedIssue.attachments.map((item) => ({ ...item, id: 0 }));
      }
      const r = await api.issue.create(copiedIssue);
      onCloseCopyIssueDialog();
      if (r == null) return handlerApiError();
      if (r && r.id) {
        props.onRedirectToOtherIssue(r.id);
      }
      notifier.show({
        message: formNotifications.onCreate,
        theme: "success",
      });
      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueStatus",
      });
    },
    [props.updater]
  );

  const handleArchiveIssue = useCallback(async (text) => {
    const status = await handleCreateIssueHistoryByActionKey(IssueActionConsts.archive, text);
    if (status) {
      // const response = await api.issue.archive(props.issueId);
      // if (response == null) return handlerApiError();
      issueInitDataStore.getOnSidebarIssuesRestart && issueInitDataStore.getOnSidebarIssuesRestart();
      onCloseArchiveIssueDialog();
      issueInitDataStore.reset();
      props.onClose();
      notifier.show({
        message: formNotifications.onArchived,
        theme: "success",
      });
      await issueChannel.postMessage({
        issueId: subId,
        type: "issueArchived",
      });
    }
  }, [props.issueId]);

  // const validationDeadLine = useCallback(() => {
  //   const { timePlan = '00:15', dateDeadline } = issueData;
  //   const parsedTimePlan = getObjectFromTimeSpan(timePlan!);
  //
  //   const dateDeadlineWithTimezone = dayjs(dateDeadline)
  //     .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
  //     .toDate();
  //
  //   const endDate =  dayjs(info.event.start)
  //     .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
  //     .add({
  //       hours: parsedTimePlan.hours,
  //       minutes: parsedTimePlan.minutes,
  //       seconds: parsedTimePlan.seconds,
  //     })
  //     .toDate();
  //
  //   if (issueData == null) {
  //     info.revert();
  //     return false;
  //   }
  //   console.log("dateDeadline", dateDeadline);
  //   if (dateDeadline && ((info.event.start as Date) > dateDeadlineWithTimezone || endDate > dateDeadlineWithTimezone)) {
  //     const { fields = [], isStrictDeadline } = issueData;
  //     console.log("issueData", issueData);
  //     console.log("isStrictDeadline", isStrictDeadline);
  //     const strictDeadlineField = fields.find((field: IssueCustomFieldDto) => field.key === blockedFieldsKeys.isStrictDeadline);
  //     const isStrictDeadlineFieldValue = strictDeadlineField?.valueBool ?? false;
  //     console.log("isStrictDeadlineFieldValue", isStrictDeadlineFieldValue);
  //
  //     if (!isStrictDeadline && !isStrictDeadlineFieldValue) {
  //       setIsOpenRequiredActionsDialog(true);
  //       console.log("issueData", issueData);
  //       console.log("issueData.dateDeadline", issueData.dateDeadline);
  //       console.log("info.event.start", info.event.start);
  //       setIssue({
  //         dateDeadline: issueData.dateDeadline,
  //         dateWorkStart: info.event.start,
  //         timePlan: issueData.timePlan,
  //         id: issueData.issueId ?? issueData.id,
  //       });
  //       setIntermediateData({
  //         warningKey: WarningsKeys.changeDeadline,
  //         event: {
  //           info,
  //           issueData,
  //         },
  //       });
  //     } else {
  //       setIntermediateData({
  //         warningKey: WarningsKeys.issuesOverdue,
  //         event: {
  //           info,
  //           issueData,
  //         },
  //       });
  //     }
  //     return false;
  //   }
  //   return true;
  // }, [props.updater.currentState])


  const unArchiveIssue = useCallback(async () => {
    const status = await handleCreateIssueHistoryByActionKey(IssueActionConsts.unArchive);
    if (status) {
      // const response = await api.issue.archive(props.issueId);
      // if (response == null) return handlerApiError();
      issueInitDataStore.getOnSidebarIssuesRestart && issueInitDataStore.getOnSidebarIssuesRestart();
      issueInitDataStore.reset();
      props.onClose();
      notifier.show({
        message: formNotifications.onUnArchived,
        theme: "success",
      });
      await issueArchiveChannel.postMessage({
        type: "reload",
      });
      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueUnArchived",
      });
    }
  }, [props.issueId, subId])

  const handleUnArchiveIssue = useCallback(async () => {
    const { isStrictDeadline, dateDeadline: issueDateDeadline } = props.updater.currentState ?? {};

    const dateDeadlineWithTimezone = dayjs()
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .toString();

    const deadlineHasArrived = new Date() > new Date(issueDateDeadline ?? "");
    if (deadlineHasArrived) {
      const newDeadline = issueDateDeadline ? dateDeadlineWithTimezone : "";
      const dateDeadline = !isStrictDeadline ? newDeadline : issueDateDeadline;
      setIssue({
        ...props.updater.currentState,
        dateDeadline,
      });

        if (isStrictDeadline) {
          setIsOpenStrictDeadlineDialog(true);
        } else {
          setIsOpenRequiredActionsDialog(true);
        }
    } else {
      await unArchiveIssue();
    }
  }, [props.issueId, props.updater.currentState]);

  const handleCreateIssueHistoryByActionKey = useCallback(
    async (actionKey: IssueActionConsts, text?: string) => {
      const r = await api.issueHistory.create({
        issueId: props.issueId,
        actions: [{ key: actionKey }],
        comment:  text ? { text } : undefined,
      });
      if (r == null) return handlerApiError();
      return true;
    },
    [handlerApiError, props.issueId]
  );

  const onDeleteIssue = async () => {
    if (props.issueId) {
      const r = await api.issue.del(props.issueId);
      if (r == null) return handlerApiError();
      //notifier.show({message: t("notifier:error.issue_deleted"), theme: "success",});
      issueInitDataStore.getOnSidebarIssuesRestart && issueInitDataStore.getOnSidebarIssuesRestart();
      handleToggleDeleteConfirmDialog(false);
      issueInitDataStore.reset();
      props.onClose();
      notifier.show({
        message: formNotifications.onDelete,
        theme: "success",
      });
      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueDelete",

      });
      // customEvent.dispatch("reloadIssueBoard", { afterDelete: true });
    }
  };

  const handleIssueUpdate = async () => {
    //
  };

  useEffect(() => {
    if (props.updater?.currentState?.attachments != null) {
      setAttachmentsIssue(props.updater?.currentState?.attachments.map((item) => item.file) as StaticFileDto[]);
    }
  }, [props.updater?.currentState?.attachments]);

  useEffect(() => {
    if (forceUpdate) {
      props.updater.update();
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  const [issue, setIssue] = useState<IssueDto | null>(null);
  const [isOpenRequiredActionsDialog, setIsOpenRequiredActionsDialog] = useState<boolean>(false);
  const handleConfirmChangeDeadline = async () => {
    setIsOpenRequiredActionsDialog(false);
    setIssue(null);
    setIsOpenStrictDeadlineDialog(false);
    await unArchiveIssue();
  };

  const handleCloseRequiredActionsDialog = () => {
    setIsOpenRequiredActionsDialog(false);
    setIssue(null);
    setIsOpenRequiredActionsDialog(false);
  };

  const [isOpenStrictDeadlineDialog, setIsOpenStrictDeadlineDialog] = useState<boolean>(false);

  const getModalSubTitle = useCallback((): string => {
    const { dateDeadline } = props.updater.currentState!;
    const dateDeadlineDate = new Date(dateDeadline ?? "");
    //
    // const dateFormat = dayjs(dateDeadlineDate)
    //   .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
    //   .format("HH:mm, DD.MM");
    //
    //
    // const { start } = info.event;
    //
    // const startDateFormat = dayjs(start)
    //   .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
    //   .format("HH:mm, DD.MM");
    // const [startTime, startDate] = startDateFormat.split(',')
    // return t(getWarningData().find((item) => item.key == intermediateData?.warningKey)?.description ?? "", {
    //   date: dateFormat,
    //   startDate,
    //   startTime,
    //
    // });
    return '123';
  }, [props.updater.currentState]);

  return (
    <IssueContext.Provider
      value={{
        senderDeadlineExpired,
        strictDeadline,
        isContributor,
        isExecutor,
        issueType: props.issueType,
      }}
    >
      {isOpenRequiredActionsDialog && !!issue && (
        <IssueRequiredActionsDialog
          open={isOpenRequiredActionsDialog}
          boardData={{
            baseTransitionActionKey: actionsKeysConst.changeDeadline,
          }}
          issueData={issue}
          onConfirmData={handleConfirmChangeDeadline}
          onClose={handleCloseRequiredActionsDialog}
        />
      )}
      {isOpenStrictDeadlineDialog &&(
        <CustomConfirmDialog
          open={true}
          onClose={handleCloseRequiredActionsDialog}
          onConfirm={handleConfirmChangeDeadline}
          title={t('ui:title.attention')}
          subTitle={getModalSubTitle()}
          buttonText={{
            confirm: t('ui:button.accept')
          }}
        />
      )}


      {openDialogDeleteMessage && (
        <CustomConfirmDialog
          open={openDialogDeleteMessage}
          onClose={() => handleToggleDeleteConfirmDialog(false)}
          onConfirm={onDeleteIssue}
          title={t(getDeleteIssueTitle(props.issueType))}
        />
      )}
      {isWithdrawIssueDialogOpen && (
        <IssueWithdrawDialog
          open={isWithdrawIssueDialogOpen}
          onClose={onCloseWithdrawIssueDialog}
          onWithdrawIssue={handleWithdrawIssue}
        />
      )}
      {/*{openIssueRejectDialog && (*/}
      {/*  <IssueRejectDialog*/}
      {/*    issueId={props.issueId}*/}
      {/*    open={openIssueRejectDialog}*/}
      {/*    onClose={onCloseIssueRejectDialog}*/}
      {/*    onRejectIssue={handleRejectIssue}*/}
      {/*  />*/}
      {/*)}*/}
      {isExecutorChangeDialogOpened && (
        <IssueExecutorChangeDialog
          open={isExecutorChangeDialogOpened}
          onClose={handleExecutorChangeDialogClosed}
          onSuccess={handleExecutorChangeDialogSuccess}
          updater={props.updater}
        />
      )}
      {isCopyIssueDialogOpen && (
        <IssueCopyDialog open={isCopyIssueDialogOpen} onClose={onCloseCopyIssueDialog} onCopyIssue={handleCopyIssue} />
      )}
      {isArchiveIssueDialogOpen && (
        <IssueArchiveDialog
          open={isArchiveIssueDialogOpen}
          onClose={onCloseArchiveIssueDialog}
          handleArchiveIssue={handleArchiveIssue}
        />
      )}
      <IssueHeader
        subId={subId}
        status={status}
        indicators={indicators}
        actions={actions}
        title={props.title}
        onClose={props.onClose}
        onOpenCopyIssueDialog={handleOpenCopyIssueDialog}
        onOpenSendForArchiveDialog={handleOpenSendForArchiveDialog}
        onOpenWithdrawIssueDialog={handleOpenWithdrawIssueDialog}
        onToggleDeleteConfirmDialog={handleToggleDeleteConfirmDialog}
        onCreateIssueHistoryByActionKey={handleCreateIssueHistoryByActionKey}
        onHandleUnArchive={handleUnArchiveIssue}
        updater={props.updater}
      />
      <div className="full-width d-flex flex-nowrap" style={{ overflow: "hidden", maxHeight: "calc(90vh - 112px)" }}>
        <IssueFormWrapper
          issueId={props.issueId}
          issueType={props.issueType}
          attachments={attachmentsIssue}
          onChangeExecutor={handleChangeExecutor}
          customFields={customFields}
          setAttachmentsIssueSync={setAttachmentsIssueSync}
          onDeleteAttachmentsByFileId={handleDeleteAttachmentsByFileId}
          updater={props.updater}
          formType={IssueFormKeys.update}
        />
          <IssueHistoryModule
            issueId={props.issueId}
            indicators={props.updater.currentState?.calculated?.indicators}
            allowedToSendCommentTypes={props.updater.currentState?.calculated?.allowedToSendCommentTypes}
            createdByUserId={props.updater?.currentState?.createdByUserId}
            executorUserId={props.updater?.currentState?.executorUserId}
            isOldHistory={!props.updater?.currentState?.hasActivityHistory}
            isExecutor={isExecutor}
            currentStatusId={props.updater.currentState?.status?.id}
          />

      </div>
    </IssueContext.Provider>
  );
};
