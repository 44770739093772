import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Alert, Button, Text } from "../../../uiKit";
import { useCallback, useState } from "react";
import { CustomConfirmDialog } from "../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { useLocation } from "react-router-dom";

interface IErrorFallback extends FallbackProps {
  id: string;
}

export function ErrorFallback({ id }: IErrorFallback) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refreshPage = useCallback(() => {
    setIsLoading(true);
    window.location.reload();
  }, []);

  const onConfirm = useCallback(() => {
    setIsLoading(true);
    try {
      localStorage.removeItem(id);
      refreshPage();
    } catch (e) {
      console.error(e);
    }
  }, [id, refreshPage]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleGetContent = () => {
    if (pathname === "/communication") {
      return (
        <div className="d-stack-column justify-space-between">
          <div>
            <Text>{t("text:text_editor_error_alert")}</Text>
          </div>
          <div className="d-stack justify-end">
            <Button loading={isLoading} type={"default"} onClick={onOpen}>
              {t("ui:button.delete")}
            </Button>
            <Button loading={isLoading} className="ml-2" onClick={refreshPage}>
              {t("ui:button.refresh")}
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="d-stack justify-space-between align-center">
        <div>
          <Text>{t("text:text_editor_error_alert")}</Text>
        </div>
        <div className="d-stack">
          <Button loading={isLoading} type={"default"} onClick={onOpen}>
            {t("ui:button.delete")}
          </Button>
          <Button loading={isLoading} className="ml-2" onClick={refreshPage}>
            {t("ui:button.refresh")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomConfirmDialog
        open={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        title={t("ui:title.confirm_deletion")}
      />
      <Alert type="error" className="pa-3" description={handleGetContent()} />
    </>
  );
}
