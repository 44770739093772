import React, { useCallback, useMemo } from "react";
import { addAlphaToHex } from "../../../../helpers/colorFunctions";
import { Tag } from "../../../uiKit";

interface IPriorityComponent {
  value: number;
}

export const PriorityComponent = (props: IPriorityComponent) => {
  const priorities = useMemo(
    () => [
      { id: 1, backgroundColor: "#B7E3FA", fontColor: "#5FBDEF" },
      { id: 2, backgroundColor: "#65B7F3", fontColor: "#65B7F3" },
      { id: 3, backgroundColor: "#177DDC", fontColor: "#177DDC" },
      { id: 4, backgroundColor: "#8FD460", fontColor: "#84C259" },
      { id: 5, backgroundColor: "#49AA19", fontColor: "#49AA19" },
      { id: 6, backgroundColor: "#FAEDB5", fontColor: "#EABF09" },
      { id: 7, backgroundColor: "#F3CC62", fontColor: "#E0AF29" },
      { id: 8, backgroundColor: "#D89614", fontColor: "#D89614" },
      { id: 9, backgroundColor: "#F37370", fontColor: "#F37370" },
      { id: 10, backgroundColor: "#D32029", fontColor: "#E31D02" },
    ],
    []
  );

  const currentPriority = useMemo(() => priorities?.find((p) => p.id === props.value), [priorities, props.value]);

  const handleGetPriorityColors = useCallback(() => {
    if (currentPriority) {
      return { backgroundColor: currentPriority.backgroundColor, fontColor: currentPriority.fontColor };
    }
  }, [currentPriority]);

  const handleGetPriorityValue = useCallback(() => {
    if (props.value && currentPriority) {
      return currentPriority.id;
    }
  }, [currentPriority, props.value]);

  return (
    <Tag
      hidden={
        !handleGetPriorityColors()?.backgroundColor &&
        !handleGetPriorityColors()?.fontColor &&
        !handleGetPriorityValue()
      }
      bordered={false}
      style={{
        backgroundColor: handleGetPriorityColors()?.backgroundColor
          ? addAlphaToHex(handleGetPriorityColors()?.backgroundColor ?? "", 0.2)
          : undefined,
        color: handleGetPriorityColors()?.fontColor ?? undefined,
        width: 24,
        height: 24,
        padding: 0,
        textAlign: "center",
        margin: 0,
      }}
    >
      {handleGetPriorityValue()}
    </Tag>
  );
};
