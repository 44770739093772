import React, { useEffect, useMemo } from "react";
import "./RecurringIssueHistoryDialog.scss";
import RecurringIssueHistoryDialogView from "./RecurringIssueHistoryDialogView";
import { usePagingWithController } from "../../../../hooks/usePaging";
import { api } from "../../../../services";
import { IssueDto } from "../../../../api";
import { IssueFilter } from "../../../../api/filters/issueFilter";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../hooks";
import { UserInfo } from "../../../elements/userInfo/UserInfo";

interface IRecurringIssueHistoryDialog {
  open: boolean;
  issueId: number | null;

  onClose: () => void;
}

export interface ITableColumns {
  title: string;
  dataIndex: string;
  key: number;
}

export interface IDataSource {
  key: number;
  id?: number | null;
  name?: string | null;
  result?: string | null;
  created_date?: string | null;
  due_date?: string | null;
  status?: string | null;
  sender?: JSX.Element;
  to_whom?: JSX.Element;
}

function RecurringIssueHistoryDialog(props: IRecurringIssueHistoryDialog) {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();
  const historyData = usePagingWithController<IssueDto, IssueFilter>(
    api.issue,
    { recurringIssueId: props.issueId },
    { pageSize: 30 }
  );

  const tableColumns: ITableColumns[] = [
    {
      title: "№",
      dataIndex: "id",
      key: 1,
    },
    {
      title: t("parse:name"),
      dataIndex: "name",
      key: 2,
    },
    {
      title: t("parse:result"),
      dataIndex: "result",
      key: 3,
    },
    {
      title: t("parse:created_date"),
      dataIndex: "created_date",
      key: 4,
    },
    {
      title: t("parse:due_date"),
      dataIndex: "due_date",
      key: 5,
    },
    {
      title: t("parse:status"),
      dataIndex: "status",
      key: 5,
    },
    {
      title: t("parse:sender"),
      dataIndex: "sender",
      key: 6,
    },
    {
      title: t("parse:to_whom"),
      dataIndex: "to_whom",
      key: 7,
    },
  ];

  useEffect(() => {
    if (props.open) {
      historyData.restart();
    } else {
      historyData.reset();
    }
  }, [props.open]);

  const dataSource = useMemo(() => {
    return historyData?.items?.map((history) => {
      return {
        key: history.id!,
          id: history.id,
        name: history.name,
        result: history.awaitedResult,
        created_date:
        history.dateCreated && dateHelpers.formatDate(history.dateCreated, { formatObject: { month: "short" } }),
          due_date:
        history.dateDeadline && dateHelpers.formatDate(history.dateDeadline, { formatObject: { month: "short" } }),
          status: t(`ui:issue_status.${
            history.status?.key?.
            replace("new_issues.from_recurring", "new_issues_from_recurring")?.
            replace("default.v1.status.approval", "approval")
          }`),
        sender: <UserInfo user={history.createdByUser} zIndex={1200}/>,
        to_whom: <UserInfo user={history.executorUser} zIndex={1200}/>,
      }
    })
  }, [historyData?.items]);

  return (
    <RecurringIssueHistoryDialogView
      open={props.open}
      tableColumns={tableColumns}
      dataSource={dataSource}
      history={historyData}
      onClose={props.onClose}
    />
  );
}

export default RecurringIssueHistoryDialog;
