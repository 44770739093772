export enum NodeType {
  Anchor = "anchor",
  Image = "image",
  Video = "video",
  File = "file",
}

export enum DataType {
  Orgchart = "orgchart_id",
  Role = "role_id",
  User = "user_id",
  Date = "date",
  Time = "time",
  Icon = "icon",
  Video = "video",
  Priority = "priority",
}

export enum TextFormatType {
  BOLD = "bold",
  UNDERLINE = "underline",
  ITALIC = "italic",
  CODE = "code",
  SUPERSCRIPT = "superscript",
  SUBSCRIPT = "subscript",
  STRIKETHROUGH = "strikethrough",
}

export enum ToolbarType {
  Dynamic = "dynamic",
  Hidden = "hidden",
}
