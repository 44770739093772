import { FORMAT_TEXT_COMMAND, REDO_COMMAND, UNDO_COMMAND } from "lexical";
import * as React from "react";
import { memo, useMemo } from "react";
import { ColorPicker } from "../../ui/ColorPicker/ColorPicker";
import { IS_APPLE } from "../../utils/environment";
import { FormatSelect } from "./FormatSelect/FormatSelect";
import { SelectFormatType } from "./FormatSelect/FormatSelect.interface";
import { Divider, IconButton } from "../../../../uiKit";
import { VscHorizontalRule } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { IToolbarPluginView, PluginsArrType } from "./ToolbarPlugin.interface";
import { useLocation } from "react-router-dom";
import { LinkPopup } from "../../ui/LinkPopup/LinkPopup";
import { TextFormatType } from "../../types/types";
import { EmojisPicker } from "../EmojisPlugin/EmojisPicker";
import { MentionsToolbarPopover } from "../MentionsPlugin/MentionsToolbarPopover";
import { ImagePopup } from "../../ui/ImagePopup/ImagePopup";
import {
  LucideAnchor,
  LucideBaseline,
  LucideBold,
  LucideItalic,
  LucidePaintbrush,
  LucideRedo,
  LucideTable,
  LucideUndo,
} from "lucide-react";
import {
  BackgroundColorIcon,
  BoldIcon,
  ItalicIcon,
  RedoIcon, TableIcon,
  TextColorIcon,
  UndoIcon
} from "../../../../../constants/icon";
import "./Toolbar.scss";


export const ToolbarPluginView = memo((props: IToolbarPluginView) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const plugins: PluginsArrType[] = useMemo(
    () => [
      {
        disabled: !props.canUndo || !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        },
        icon: props.isNewStyle ? <UndoIcon/> : <LucideUndo size={18} />,
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.undo")} (⌘Z)`
          : `${t("common:text_editor_toolbar.undo")} (Ctrl+Z)`,
      },
      {
        disabled: !props.canRedo || !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.redo")} (⌘Y)`
          : `${t("common:text_editor_toolbar.redo")} (Ctrl+Y)`,

        icon: props.isNewStyle ? <RedoIcon/> : <LucideRedo size={18} />,
        className: "mx-1",
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            blockType={props.blockType}
            editor={props.editor}
            formatType={SelectFormatType.BLOCK}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        disabled: !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.BOLD);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.bold")} (⌘B)`
          : `${t("common:text_editor_toolbar.bold")} (Ctrl+B)`,
        icon: props.isNewStyle ? <BoldIcon/> : <LucideBold size={18} className={props.isBold ? "toolbar__active" : ""} />,
      },
      {
        disabled: !props.isEditable,
        onClick: () => {
          props.activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.ITALIC);
        },
        title: IS_APPLE
          ? `${t("common:text_editor_toolbar.italic")} (⌘I)`
          : `${t("common:text_editor_toolbar.italic")} (Ctrl+I)`,
        icon: props.isNewStyle ? <ItalicIcon/> : <LucideItalic size={18} className={props.isItalic ? "toolbar__active" : ""} />,
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            isSimplifiedToolbar
            isCode={props.isCode}
            isSubscript={props.isSubscript}
            isSuperscript={props.isSuperscript}
            isUnderline={props.isUnderline}
            isStrikethrough={props.isStrikethrough}
            editor={props.editor}
            formatType={SelectFormatType.TEXT_FORMAT}
            onClearFormatting={props.onClearFormatting}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            isSimplifiedToolbar
            editor={props.editor}
            formatType={SelectFormatType.ALIGN}
            alignType={props.alignType}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            isSimplifiedToolbar
            editor={props.editor}
            formatType={SelectFormatType.LIST}
            listType={props.listType}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        component: (
          <ColorPicker
            disabled={!props.isEditable}
            color={props.fontColor}
            onChange={props.onFontColorSelect}
            title={t("common:text_editor_toolbar.color")}
            icon={props.isNewStyle ? <TextColorIcon/> : <LucideBaseline size={18} />}
          />
        ),
      },
      {
        component: (
          <ColorPicker
            disabled={!props.isEditable}
            color={props.bgColor}
            onChange={props.onBgColorSelect}
            title={t("common:text_editor_toolbar.background")}
            icon={props.isNewStyle ? <BackgroundColorIcon/> : <LucidePaintbrush size={18} />}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        disabled: !props.isEditable || props.isTable,
        title: t("common:text_editor_toolbar.table"),
        onClick: () => props.setIsOpenTableDialog(true),
        icon: props.isNewStyle ? <TableIcon/> : <LucideTable size={18} />,
      },
      {
        component: (
          <ImagePopup
            isEditable={props.isEditable}
            editor={props.activeEditor}
            isLoadingChange={props.isLoadingChange}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: (
          <LinkPopup
            openLinkPopup={props.openLinkPopup}
            setOpenLinkPopup={props.setOpenLinkPopup}
            linkUrl={props.linkUrl}
            setLinkUrl={props.setLinkUrl}
            editor={props.editor}
            isEditable={props.isEditable}
            isLink={props.isLink}
            activeLinkButton={props.activeLinkButton}
            insertLink={props.insertLink}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <EmojisPicker
          onChange={props.onChangeEmoji}
          onClose={() => {}}
          placement={"bottomLeft"}
          isNewStyle={props.isNewStyle}
        />,
      },
      {
        component: (
          <FormatSelect
            disabled={!props.isEditable}
            isSimplifiedToolbar
            editor={props.editor}
            isCode={props.isCode}
            onOpenVideoDialog={props.onOpenVideoDialog}
            formatType={SelectFormatType.INSERT}
            isNewStyle={props.isNewStyle}
          />
        ),
      },
      {
        component: <Divider isVertical className={"mx-1"} />,
      },
      {
        component: <MentionsToolbarPopover
            editor={props.activeEditor}
            issueId={props.issueId}
            isNewStyle={props.isNewStyle}
        />,
      },
      ...(pathname === "/edit-content"
        ? [
            {
              disabled: !props.isEditable,
              onClick: (e: any) => {
                props.onCreateAnchor(e);
              },
              icon: <LucideAnchor size={18} />,
            },
          ]
        : []),
      ...(props.toolbar ? [{
        component: props.toolbar
      }] : [])
    ],
    [props, t, pathname]
  );

  return (
    <div className={`toolbar ${props.className ?? ""} ${props.isNewStyle ? 'toolbar_new-style' : ''}`}>
      <div className="d-stack flex-wrap spacing-1">
        {plugins.map((p, i) =>
          p.component ? (
            <div className={`${p.className} d-flex align-center`} key={i}>
              {p.component}
            </div>
          ) : (
            <div key={i} className="d-flex align-center">
              <IconButton
                disabled={p.disabled}
                onClick={p.onClick}
                title={p.title}
                icon={p.icon}
                className={`${p.className}`}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
});
