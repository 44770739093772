import React from "react";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { IOpeningGuideIconView } from "./OpeningGuideIcon.interface";
import "./OpeningGuideIcon.scss";

// TODO: Add Icon component
export const OpeningGuideIconView = (props: IOpeningGuideIconView) => {
  return (
    <FiHelpCircle
      onMouseDown={(e) => {
        props.onClick();
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{ marginRight: `${props.mr}px`, marginLeft: `${props.ml}px`, marginTop: `${props.mt}px` }}
      className="guide-icon"
    />
  );
};
