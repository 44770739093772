import React, { useState } from "react";
import PageContent from "../../../modules/layouts/pageContent/PageContent";
import PageHeader from "../../../modules/layouts/pageHeader/PageHeader";
import { Card, Divider, Icon, IconButton, Link, TabPane, Tabs, Text, Title } from "../../../uiKit";
import "./UiPage.scss";
// import { BsInputCursorText } from "@react-icons/all-files/bs/BsInputCursorText";
import {
  BsCalendarDate,
  BsClock,
  BsInputCursor,
  BsInputCursorText,
  BsMenuDown,
  BsTextarea,
  BsUiRadios,
} from "react-icons/bs";
import { UiTabAvatar } from "./tabs/UiTabAvatar";
import { UiTabAvatarGroup } from "./tabs/UiTabAvatarGroup";
import { UiTabButton } from "./tabs/UiTabButton";
import { UiTabCheckbox } from "./tabs/UiTabCheckbox";
import { UiTabDatePicker } from "./tabs/UiTabDatePicker";
import { UiTabInput } from "./tabs/UiTabInput";
import { UiTabInputNumber } from "./tabs/UiTabInputNumber";
import { UiTabMenu } from "./tabs/UiTabMenu";
import { UiTabRadio } from "./tabs/UiTabRadio";
import { UiTabSelect } from "./tabs/UiTabSelect";
import { UiTabSwitch } from "./tabs/UiTabSwitch";
import { UiTabTextArea } from "./tabs/UiTabTextArea";
import { UiTabTextEditor } from "./tabs/UiTabTextEditor";
import { UiTabTimePicker } from "./tabs/UiTabTimePicker";
import { UiTabTooltip } from "./tabs/UiTabTooltip";
import { UiTabUserCard } from "./tabs/UiTabUserCard";
import { BiMessageAltError } from "react-icons/bi";
import { CgSelectR } from "react-icons/cg";
import { FiExternalLink, FiHome } from "react-icons/fi";
import { IoCheckboxOutline } from "react-icons/io5";
import { MdOutlineSmartButton } from "react-icons/md";
import { RxAvatar, RxSwitch } from "react-icons/rx";

import { IconContext } from "react-icons/lib";
import { useHash } from "../../../../hooks";

interface IUiKitCard {
  name: string;
  description?: string;
  icon?: React.ReactElement;
  content?: React.ReactNode | null;
  antLink?: string;
}

const UiTabMain = ({ onTabChange }: { onTabChange: (tabId: string) => void }) => {
  const { hashFn } = useHash();
  return (
    <div>
      <Divider className="mt-2 mb-8" />
      {/*<div className="d-flex align-center my-3">*/}
      {/*  <FiLink className="mr-1" color="var(--color-primary-strong)" />*/}
      {/*  <a target="_blank" href="https://ant.design/components/overview/" rel="noreferrer">*/}
      {/*    Все компоненты дизайн-системы AntD*/}
      {/*  </a>*/}
      {/*  <span></span>*/}
      {/*</div>*/}
      {uiTabs.map((t, tIndex) => (
        <>
          {tIndex > 0 && <Divider className="my-8" />}
          <Title children={t.name} />
          <IconContext.Provider value={{ color: "var(--color-primary-base)" }}>
            <div className="uiKitPage-elements__wrapper">
              {t.items.map((i, index) => (
                <Card
                  title={i.name}
                  extra={
                    i.icon != undefined && <Icon className="mr-2" component={() => i.icon as React.ReactElement} />
                  }
                  onClick={() => onTabChange(hashFn(i.name))}
                  key={index}
                  clickable
                >
                  {i.description != null && <Text children={i.description} />}
                </Card>
              ))}
            </div>
          </IconContext.Provider>
        </>
      ))}
      {/*<Title>Сервисные фичи</Title>*/}
      {/*<div className="my-8" />*/}
      {/*<Title>Базовые компоненты</Title>*/}
      {/*<IconContext.Provider value={{ color: "var(--color-primary-base)" }}>*/}
      {/*  <div className="uiKitPage-elements__wrapper">*/}
      {/*    {uiTabsBase.map((card, index) => (*/}
      {/*      <Card*/}
      {/*        title={card.name}*/}
      {/*        extra={*/}
      {/*          card.icon != undefined && <Icon className="mr-2" component={() => card.icon as React.ReactElement} />*/}
      {/*        }*/}
      {/*        onClick={() => onTabChange(index.toString())}*/}
      {/*        key={index}*/}
      {/*        clickable*/}
      {/*      >*/}
      {/*        {card.description != null && <Text children={card.description} />}*/}
      {/*      </Card>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</IconContext.Provider>*/}
      {/*<div className="my-8" />*/}
      {/*<Title>Комбинированные компоненты</Title>*/}
      {/*<div className="uiKitPage-elements__wrapper">*/}
      {/*  {uiTabsCombined.map((card, index) => (*/}
      {/*    <Card*/}
      {/*      title={card.name}*/}
      {/*      extra={*/}
      {/*        card.icon != undefined && <Icon className="mr-2" component={() => card.icon as React.ReactElement} />*/}
      {/*      }*/}
      {/*      onClick={() => onTabChange((uiTabsBase.length + index).toString())}*/}
      {/*      key={uiTabsBase.length + index}*/}
      {/*      clickable*/}
      {/*    >*/}
      {/*      /!*{card.icon != undefined && <Icon className="mr-2" component={() => card.icon as React.ReactElement} />}*!/*/}
      {/*      {card.description != null && <Text children={card.description} />}*/}
      {/*    </Card>*/}
      {/*  ))}*/}
      {/*</div>*/}
    </div>
  );
};

const uiTabs: { name: string; items: IUiKitCard[] }[] = [
  {
    name: "Сервисные фичи",
    items: [{ name: "Начало", icon: <FiHome />, content: null, description: "Главная страница UI-Kit" }],
  },
  {
    name: "Базовые компоненты",
    items: [
      {
        name: "Button",
        icon: <MdOutlineSmartButton />,
        content: <UiTabButton />,
        description: "Элемент, позволяющий совершать действия по нажатию (или серию действий)",
      },
      {
        name: "Input",
        icon: <BsInputCursorText />,
        content: <UiTabInput />,
        description: "С помощью этого компонента можно ввести какие-либо значения",
        antLink: "https://ant.design/components/input/",
      },
      {
        name: "InputNumber",
        icon: <BsInputCursor />,
        content: <UiTabInputNumber />,
        description: "Суть та же, что и у Input, но для ввода разрешены только числа",
      },
      {
        name: "TextArea",
        icon: <BsTextarea />,
        content: <UiTabTextArea />,
        description: "Своего рода многострочный Input",
      },
      // { id: "4", text: "InputPassword", tab: <UiTabInputPassword /> },
      {
        name: "Select",
        icon: <CgSelectR />,
        content: <UiTabSelect />,
        description: "Компонент, позволяющий выбрать что-либо из списка вариантов",
      },
      {
        name: "DatePicker",
        icon: <BsCalendarDate />,
        content: <UiTabDatePicker />,
        description: "Элемент для выбора даты",
      },
      {
        name: "TimePicker",
        icon: <BsClock />,
        content: <UiTabTimePicker />,
        description: "А это элемент для выбора времени",
      },
      {
        name: "Checkbox",
        icon: <IoCheckboxOutline />,
        content: <UiTabCheckbox />,
        description: "Компонент, позволяющий отметить нужные варианты методом тыка",
      },
      {
        name: "Radio",
        icon: <BsUiRadios />,
        content: <UiTabRadio />,
        description: "Как Select, но без выпадающего меню",
      },
      {
        name: "Switch",
        icon: <RxSwitch />,
        content: <UiTabSwitch />,
        description: "Двухпозиционная переключалка (Вкл / Выкл)",
      },
      {
        name: "Tooltip",
        icon: <BiMessageAltError />,
        content: <UiTabTooltip />,
        description: "Скрытая информация, показывающаяся по наведению",
      },
      { name: "Avatar", icon: <RxAvatar />, content: <UiTabAvatar />, description: "Аватарка..." },
      {
        name: "AvatarGroup",
        icon: <RxAvatar />,
        content: <UiTabAvatarGroup />,
        description: "Как одна аватарка, но когда их много в одной группе",
      },
      { name: "Menu", icon: <BsMenuDown />, content: <UiTabMenu />, description: "Менюшка" },
    ],
  },
  {
    name: "Комбинированные компоненты",
    items: [
      { name: "Карточка юзера", content: <UiTabUserCard />, description: "Информация о пользователе" },
      {
        name: "Текстовый редактор",
        content: <UiTabTextEditor />,
        description: "Прокачанное поле ввода, открывающее множество возможностей для ввода данных",
      },
    ],
  },
];

function UiPage() {
  const { hashFn } = useHash();
  const [currentTabId, setCurrentTabId] = useState<string>("0");

  return (
    <>
      <PageHeader title="UI Kit 😼">
        <Link
          className="ml-auto mr-auto"
          target="_blank"
          // href={uiTabsBase[Number(currentTabId)]?.antLink ?? "https://ant.design/components/overview/"}
          rel="noreferrer"
          children={
            <span>
              <Icon className="mr-2" component={() => <FiExternalLink />} />
              {/*{uiTabsBase[Number(currentTabId)]?.antLink != null*/}
              {true ? "Документация AntD" : "Все компоненты дизайн-системы AndD"}
            </span>
          }
        />
        <IconButton
          disabled={currentTabId == "0"}
          onClick={() => setCurrentTabId("0")}
          // className="ml-auto"
          icon={<Icon component={() => <FiHome />} />}
        />
      </PageHeader>
      <PageContent>
        <Tabs renderTabBar={() => <div />} tabBarGutter={0} tabBarStyle={{ display: "none" }} activeKey={currentTabId}>
          <TabPane tab={"0"} key={0} tabKey={"0"} children={<UiTabMain onTabChange={setCurrentTabId} />} />
          {
            // uiTabsBase
            // .concat(uiTabsCombined)
            // .filter((card) => card.content != null)
            uiTabs
              .map((t) => t.items)
              .flat()
              .filter((card) => card.content != null)
              .map((card, index) => (
                <TabPane tab={card.name} key={hashFn(card.name)} tabKey={hashFn(card.name)} children={card.content} />
              ))
          }
        </Tabs>
      </PageContent>
    </>
  );
}

export default UiPage;
