import React, { forwardRef } from "react";
import "./Input.scss";
import { Input as InputAntD, InputProps, InputRef } from "antd";
import clsx from "clsx";

export interface IInputProps extends InputProps {
  readOnly?: boolean;
  isNewDesign?: boolean;
  isBigSize?: boolean;
}

export const Input = forwardRef<InputRef, IInputProps>((
  {
    className,
    readOnly,
    isNewDesign,
    isBigSize,
    ...rest
  }: IInputProps, ref) => {
  return (
    <InputAntD
      ref={ref}
      size="large"
      className={clsx("uiKit-input", className ?? "", {
        "no-interact": readOnly,
        "uiKit-input_new-design": isNewDesign,
        "uiKit-input_big": isBigSize
      })}
      {...rest}
    />
  );
});
