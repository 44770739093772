import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RegulationFunctionSelectionDialogProps } from "./RegulationFunctionSelectionDialog";
import { Button, Dialog, Icon, Input, Radio, RadioGroup, Spin, Tree } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { TemplateDialogContentTypeKeys } from "../consts/consts";
import { CenterPageContentLayout } from "../../../layouts/centerPageContentLayout/CenterPageContentLayout";
import "./RegulationFunctionSelectionDialog.scss";

import { OrgchartTree } from "../../../pages/regulation/components/OrgchartTree/OrgchartTree";

import { RoleSelectorDto } from "../../../../../api/models/RoleSelectorDto";
import { RegulationTreeNode } from "../../../../../api/models/RegulationTreeDto";
import { AccessControlDialogContext } from "../AccessDialogContext";
import { MenuItemClaimType } from "../../../../../api/models/NavigationMenuDto";
import { IFunctionTreeItem } from "./functionTreeItem/FunctionTreeItem.interface";
import { api } from "../../../../../services";
import {
  NavigationMenuItemClaimGroupDto,
  NavigationMenuItemUserSelectionRuleClaimDto
} from "../../../../../api/models/NavigationMenuItemClaimGroupDto";
import { IUpdateTreeItemData } from "../../../pages/regulation/hooks/IGeneratorTreeData.interface";
import { SearchInputField } from "../../../../elements/searchInputField/SearchInputField";
import useDebounce from "../../../../../hooks/useDebounce";
import { OrgchartSwitcher } from "../../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { LuCheckCheck, LuGraduationCap } from "react-icons/lu";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";

export const RegulationFunctionSelectionDialogView = memo((props: RegulationFunctionSelectionDialogProps) => {
  const {
    isOpen,
    onClose,
    onOpenCreationOrSelectionTemplateDialog,
    onSetContentType,
    orgchartId,
    sectionId,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const treeValuesArr = Form.useWatch("tree", form);

  const {
    isLoading,
    fetchRoleSelectorsClaimTreeData,
    treeData,
    expandedKeys,
    setExpandedKeys,
    selectedRoles,
    setSelectedRoles,
    updateTreeItemData,
    setClaimGroup,
    claimGroup,
    claimType,
    setClaimType,
  } = useContext(AccessControlDialogContext);

  // const [roles, setRoles] = useState<RolesWithOrgchartDto | null>(null);
  // const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const onExpand = useCallback((expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  }, []);


  useEffect(() => {
    treeValuesArr?.length
      ? onSetContentType(TemplateDialogContentTypeKeys.Creation)
      : onSetContentType(TemplateDialogContentTypeKeys.Selection);
  }, [onSetContentType, treeValuesArr?.length]);


  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const fetchOrgchartData = async () => {
  //   setIsLoading(true);
  //   const response = await api.orgchart.getTreeWithRoles(orgchartId as number);
  //   if (response) {
  //     setRoles(response);
  //   } else {
  //     setRoles(null);
  //   }
  //   console.log("response", response);
  //   setIsLoading(false);
  //
  // };

  const [activeRole, setActiveRole] = useState<RoleSelectorDto | null>(null);


  const handleSelectActiveRole = useCallback((role: RoleSelectorDto | null) => {
    setActiveRole(role);
    // setActiveRole({
    //   ...role,
    //   claimType: claimType ?? undefined,
    // });
    // console.log("handleSelectActiveRole", id);
    // setActiveRoleId(id);
    // setCurrentPositions(positions);
    // setRolePositionId(null);
  }, [claimType]);


  useEffect(() => {
    fetchRoleSelectorsClaimTreeData(orgchartId as number, sectionId ?? undefined);
  }, [orgchartId, sectionId]);

  const onUpdateTreeItemData = useCallback((
    {
      list,
      key,
      isSelected,
      includeNestedRoles,
      isUpdateClaimType,
    }: IUpdateTreeItemData) => {
    return updateTreeItemData({
      list: treeData,
      key,
      isSelected,
      includeNestedRoles,
      isUpdateClaimType,
    });
  }, [treeData, claimType]);

  const onSave = () => {
  }
  // const [claimType, setClaimType] = useState<MenuItemClaimType>(MenuItemClaimType.Read)

  const handleOnSave = useCallback(async () => {
    // const addedRoles = roles.filter(
    //   (role) => !props.selectedRulesList.find((item) => item.roleId === role.id)
    // );
    //
    // @ts-ignore
    const addedRolesList: NavigationMenuItemUserSelectionRuleClaimDto[] = selectedRoles.map((role) => ({
      // id: role.id,
      roleId: role.id,
      // isExcluded: false,
      orgchartId: role.orgchartId,
      // roleName: role.title,
      // isSelected: true,
      includeNestedRoles: role.includeNestedRoles,
      // selectedPositions: role.selectedPositions,
      role: {
        name: role.title,
      },
      // positions: role.positions,
      claimType: role.claimType,
      isAllowed: true,
    }));
    const claimGroup = addedRolesList
      .filter((item) => item.claimType === claimType);

    const groupedData = addedRolesList.reduce((result: NavigationMenuItemClaimGroupDto, item) => {
      const key = item.claimType;
      if (!result[key]) {
        result[key] = [];
      }
      if (result[key]) {
        result[key]?.push(item);
      } else {
        result[key] = [item]
      }

      return result;
    }, {});

    setClaimGroup(groupedData);

    props.onClose();
    // const orgchartId = props.orgchartId as number;
    // if (props.sectionId) {
    //   const status = await api.navigationMenu.setRoleSelectorsClaim(props.sectionId, orgchartId, addedRolesList);
    //   console.log("status", status);

    // }
    // // @ts-ignore
    // props.onChange(addedRolesList);
    // // console.log("addedRoles", addedRolesList);

  }, [selectedRoles, claimType]);

  const onSelectedRoles = useCallback((...arg) => {
    console.log('onSelectedRoles', arg);

  }, []);

  useEffect(() => {
    if (isOpen) {
      const selectedRoles: IFunctionTreeItem[] = [];
      Object.entries(claimGroup).forEach(([claimType, selectedRolesList]) => {
        const arr: IFunctionTreeItem[] = selectedRolesList.map((role) => {
          const {
            roleId: id,
            role: {
              name: title,
            },
            includeNestedRoles,
            orgchartId,
            claimType,
          } = role;
          return {
            id,
            title: title || "",
            includeNestedRoles,
            orgchartId,
            claimType,
          }
        });
        selectedRoles.push(...arr);
      });
      setSelectedRoles(selectedRoles);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (!props.open) {
  //     setTimeout(() => {
  //       setCurrentPositions(undefined);
  //       setActiveRoleId(null);
  //       setSearchRoleValue("");
  //       setRolePositionId(null);
  //       rolesRef.current = [];
  //     }, 150);
  //   } else {
  //     console.log("props.selectedRolesList", props.selectedRolesList);
  //     const selectedRoles: IFunctionTreeItem[] = (props.selectedRolesList ?? []).map((role) => {
  //       const {
  //         roleId: id,
  //         roleName: title,
  //         includeNestedRoles,
  //         orgchartId,
  //         positions = []
  //       } = role;
  //       return {
  //         id,
  //         title,
  //         includeNestedRoles,
  //         orgchartId,
  //         selectedPositions: positions.map((position) => position.id!),
  //         positions,
  //       }
  //     });
  //     setSelectedRoles(selectedRoles);
  //   }
  // }, [props.open]);
  const claimTypesList = Object.keys(MenuItemClaimType).map((key) => ({
    value: key,
    text: t(`ui:title.regulation_access_dialog_collapse.${key}`)
  }));

  const [searchRoleValue, setSearchRoleValue] = useState<string>("");

  const debounce = useDebounce(searchRoleValue, 400);

  const findBranches = (tree: any, targetValue: string) => {
    const branches: RegulationTreeNode[] = [];

    const findElementsByName = (array: RegulationTreeNode[], search: string) => {
      array.forEach((node) => {
        if (node.title.toLowerCase().includes(search.toLowerCase())) {
          branches.push({...node, isLeaf: true, children: []});
        }
        if ((node?.children ?? []).length > 0) {
          findElementsByName((node?.children ?? []), search);
        }
      });
    };

    findElementsByName(tree, targetValue);
    return branches;
  }


  const treeDataRoles = useMemo(() => {
    return debounce ? findBranches(treeData, debounce) : treeData;
  }, [debounce, treeData]);

  const getIconComponent = useCallback((key: string) => {
    switch (key) {
      case MenuItemClaimType.Read:
        return <FiEye/>
      case MenuItemClaimType.Write:
        return <FiEdit3/>
      case MenuItemClaimType.Assignment:
        return <LuGraduationCap/>
      case MenuItemClaimType.FullAccess:
        return <LuCheckCheck/>
      default:
        return null;
    }
  }, []);

  // getIconComponent(item.value)
  return (
    <Dialog
      title={t("ui:subheader.role_select")}
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      closable={false}
      width={646}
    >
      {isLoading && (
        <CenterPageContentLayout
          className="function-selection-loader"
          horizontal
        >
          <Spin size="large" />
        </CenterPageContentLayout>
      )}
      <div className="function-selection-form">
        <div className="function-selection-form__row">
          <RadioGroup
            className="function-selection-radio-group"
            onChange={(e) => {
              setClaimType(e.target.value);
            }}
            value={claimType}
          >
            {claimTypesList.map((item) => (
              <Radio
                key={item.value}
                value={item.value}
                // children={item.text}
                isNewDesign
              >
                <div className="function-selection-radio-item">
                  <Icon
                    className={"function-selection-radio-item__icon"}
                  >
                    { getIconComponent(item.value) }
                  </Icon>
                  {item.text}
                </div>
              </Radio>
            ))}
          </RadioGroup>
        </div>
        <OrgchartSwitcher
          value={props.orgchartId ?? undefined}
          onChange={props.handleChangeOrgchartId}
          preventDefault
          selectProps={{
            allowClear: false,
            className: "full-width",
            placeholder: t("ui:placeholder.click_to_select"),
          }}
          drawType="select"
        />
        <SearchInputField
          value={searchRoleValue}
          setValue={setSearchRoleValue}
          // variant="standard"
          backgroundInherit
          placeholder={t("ui:placeholder.enter_role")}
        />
        <OrgchartTree
          treeData={treeDataRoles}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          selectedRoles={selectedRoles}
          setSelectedRoles={onSelectedRoles}
          updateTreeItemData={onUpdateTreeItemData}
          activeRole={activeRole}
          // searchValue={props.searchRoleValue}
          onSelectActiveRole={handleSelectActiveRole}
          claimType={claimType}
        />

        <div className="d-stack-row justify-end mt-8">
          {/*<Button*/}
          {/*  type="default"*/}
          {/*  variant="filled"*/}
          {/*  onClick={onOpenCreationOrSelectionTemplateDialog}*/}
          {/*>*/}
          {/*  {treeValuesArr?.length ? t("ui:button.save_templates") : t("ui:button.templates")}*/}
          {/*</Button>*/}

          <div>
            <Button
              type="default"
              onClick={onClose}
              variant="filled"
            >
              {t("ui:button.cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              variant="filled"
              className="ml-2"
              onClick={handleOnSave}
            >
              {t("ui:button.save")}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
});