import React, { useMemo, useState } from "react";
import { Button, Switch } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { GoogleIcon } from "../../../../../constants/icon";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import "./GoogleAuthDisconnect.scss";
import { GoogleAccountDisconnectDialog } from "./GoogleAccountDisconnectDialog/GoogleAccountDisconnectDialog";
import { HiddenGoogleEventsDialog } from "./HiddenGoogleEventsDialog/HiddenGoogleEventsDialog";
import { api } from "../../../../../services";
import { useNotifier, useRootStore } from "../../../../../hooks";
import clsx from "clsx";

export const GoogleAuthDisconnect = (
  {
    onToggle,
    onDisconnectCalendar,
    isShowGoogleEvents,
    isHiddenDisconnectButton,
    isSmallSwitch,
    isHiddenShowEventsSwitch,
  }: any) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isShowHiddenGoogleEventsDialog, setIsShowHiddenGoogleEventsDialog] = useState<boolean>(false);

  const handleChange = (value: boolean) => {
    if (!value) {
      setIsShowHiddenGoogleEventsDialog(true);
    } else {
      onToggle(true);
    }
  };

  const googleCalendarAccount = useMemo(() => {
    return authStore.getGoogleCalendarAccount?.connectedAccount ?? ""
  }, [authStore.getGoogleCalendarAccount]);

  const [isShowDisconnectDialog, setIsShowDisconnectDialog] = useState<boolean>(false);

  const handleClickDisconnect = () => {
    setIsShowDisconnectDialog(true);

  };

  const handleClickCancel = () => {
    setIsShowDisconnectDialog(false);
  };

  const notifier = useNotifier();

  const handleClickConfirm = async () => {
    setIsLoading(true);
    const data = await api.googleCalendar.disconnect();
    setIsLoading(false);
    if (data !== null) {
      await authStore.fetchGoogleCalendarAccount();
      setIsShowDisconnectDialog(false);
    } else {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    }
  };

  const handleClickCancelHiddenGoogleEvents = () => {
    setIsShowHiddenGoogleEventsDialog(false);
  };

  const handleClickConfirmHiddenGoogleEvents = async () => {
    setIsLoading(true);
    const status = await onDisconnectCalendar();
    setIsLoading(false);
    if (status) {
      setIsShowHiddenGoogleEventsDialog(false);
    }
  };

  return (
    <>
      {isShowDisconnectDialog && (
        <GoogleAccountDisconnectDialog
          isOpen={isShowDisconnectDialog}
          isLoading={isLoading}
          onClose={handleClickCancel}
          onConfirm={handleClickConfirm}
        />
      )}
      {isShowHiddenGoogleEventsDialog && (
        <HiddenGoogleEventsDialog
          isOpen={isShowHiddenGoogleEventsDialog}
          isLoading={isLoading}
          onClose={handleClickCancelHiddenGoogleEvents}
          onConfirm={handleClickConfirmHiddenGoogleEvents}
        />
      )}
      <div className="google-auth-info">
        {!isHiddenDisconnectButton && (
          <>
            <div className="google-auth-info__title">
              <SubheaderText noBottomOffset text={t("ui:subheader.google_account.title")} />
            </div>
            <div className={clsx("google-auth-info-disconnect-button", {
              "google-auth-info-disconnect-button_no-margin": isHiddenShowEventsSwitch
            })}>
              <div className="google-auth-info-disconnect-button__left">
                <div className="google-auth-info-disconnect-button__icon">
                  <GoogleIcon size={29} />
                </div>
                <div className="google-auth-info-disconnect-button__email">
                  {googleCalendarAccount}
                </div>
              </div>
              <Button
                variant="link"
                theme="error"
                onClick={handleClickDisconnect}
              >
                {t("ui:subheader.google_account.disconnect")}
              </Button>
            </div>
          </>
        )}
        {!isHiddenShowEventsSwitch && (
          <div className="google-auth-info-switch">
            <Switch
              checked={isShowGoogleEvents}
              onChange={handleChange}
              size={isSmallSwitch ? "small" : "default"}
              isHiddenCheckedChildren={true}
            />
            <SubheaderText
              className="google-auth-info-switch__text"
              noBottomOffset
              text={
                isSmallSwitch
                  ? t("ui:subheader.google_account.switcher_small")
                  : t("ui:subheader.google_account.switcher")
              }
            />
          </div>
        )}
      </div>
    </>
  )
};