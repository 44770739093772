import dayjs from "dayjs";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardDto, MetricDto, MetricReadDto } from "../../../api";
import { DashboardFilter } from "../../../api/filters/dashboardFilter";
import { MetricFilter } from "../../../api/filters/metricFilter";
import { LocalStorageHelpers, useApi, useNotifier, useRootStore } from "../../../hooks";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import { DashboardCreateEditDialog } from "../../modules/dialogs/dashboardCreateEditDialog/DashboardCreateEditDialog";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { ControlBar } from "../../modules/pages/dashboards/components/ControlBar/ControlBar";
import { DashboardContent } from "../../modules/pages/dashboards/contents/DashboardContent/DashboardContent";
import {
  dashboardSectionAndRoutesKeys,
  dashboardSections,
} from "../../modules/pages/dashboards/misc/constants/routesKeys";
import { getDashboardSection } from "../../modules/pages/dashboards/misc/helpers/urlFunctions";
import "./DashboardPage.scss";
import DeleteMetricOrDashboardDialog from "../../modules/dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import { Button, Empty, Text } from "../../uiKit";
import { DashboardSidebar } from "../../modules/pages/dashboards/components/DashboardSidebar/DashboardSidebar";

const DashboardPage = () => {
  const { authStore } = useRootStore();

  const notifier = useNotifier();

  const { t } = useTranslation();

  const userId = authStore.getInitialInfo?.identity?.id;
  const weekReportStart = authStore.getInitialInfo?.identity?.companies?.find(
    (u2c) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
  )?.company?.weekReportStart;

  const { uri } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openDeleteDashboardDialog, setOpenDeleteDashboardDialog] = useState<boolean>(false);
  const [isHideSidebar, setIsHideSidebar] = useState<boolean>(false);
  const [openCreateEditDashboardDialog, setOpenCreateEditDashboardDialog] = useState<boolean>(false);
  const [dashboardColumnsAmount, setDashboardColumnsAmount] = useState<number>(3);

  const todayDate = new Date();
  const halfYearAgoDate = dayjs(todayDate).subtract(15, "year").toDate();
  const nextMonthDate = dayjs(todayDate).add(2, "year").toDate();
  // const nextMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 5, 1);

  const uriSection: dashboardSectionAndRoutesKeys | number | null = getDashboardSection(uri);

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const getMetricFilter = useMemo((): MetricFilter => {
    return {
      fillerUserId: uriSection == dashboardSectionAndRoutesKeys.fillOut ? userId : undefined,
      isFromDirectSubordinates: uriSection == dashboardSectionAndRoutesKeys.mySubordinates ? true : undefined,
      responsibleUserId: uriSection == dashboardSectionAndRoutesKeys.myMetrics ? userId : undefined,
      strictByUserId: uriSection == dashboardSectionAndRoutesKeys.myMetrics ? userId : undefined,
      dashboardId:
        uriSection && !dashboardSections.includes(uriSection as dashboardSectionAndRoutesKeys)
          ? Number(uri)
          : undefined,
    };
  }, [uri, uriSection, userId]);

  const metrics = usePagingWithController<MetricDto, MetricFilter>(
    api.metric,
    {
      ...getMetricFilter,
      dateFrom: halfYearAgoDate.toISOString(),
      dateTo: nextMonthDate.toISOString(),
    },
    { pageSize: 20, order: "asc", orderBy: "order" },
    undefined,
    () => handleApiError(),
    () => setIsLoading(false)
  );

  const metricsRead = usePagingWithController<MetricReadDto, MetricFilter>(
    api.metricRead,
    {
      ...getMetricFilter,
      dateFrom: halfYearAgoDate.toISOString(),
      dateTo: nextMonthDate.toISOString(),
    },
    { pageSize: 20, order: "asc", orderBy: "order" },
    undefined,
    () => handleApiError(),
    () => setIsLoading(false)
  );

  const availableDashboards = usePagingWithController<DashboardDto, DashboardFilter>(
    api.dashboard,
    {
      isCreatedByCurrentUser: false,
      withContextForUserId: userId,
    },
    { pageSize: -1, order: "asc", orderBy: "name" },
    undefined,
    () => handleApiError(),
    () => {}
  );

  const myDashboards = usePagingWithController<DashboardDto, DashboardFilter>(
    api.dashboard,
    {
      isCreatedByCurrentUser: true,
    },
    { pageSize: -1, order: "asc", orderBy: "name" },
    undefined,
    () => handleApiError(),
    () => {}
  );

  const dashboard = useApi(
    () => api.dashboard.getById(uriSection as number, { includeMetrics: true }),
    () => handleApiError,
    (v) => setDashboardColumnsAmount(v?.columnCount!),
    {},
    false
  );

  const handleOpenDeleteDashboardDialogChange = useCallback((isOpen: boolean) => {
    setOpenDeleteDashboardDialog(isOpen);
  }, []);

  const handleCreateEditDashboardDialogOpen = useCallback((isOpen: boolean, isEditMode: boolean) => {
    setEditMode(isEditMode);
    setOpenCreateEditDashboardDialog(isOpen);
  }, []);

  const handleLoadMyDashboards = useCallback(() => {
    myDashboards.restart(true);
  }, [myDashboards]);

  const handleDashboardDelete = useCallback(async () => {
    handleOpenDeleteDashboardDialogChange(false);
    const r = await api.dashboard.del(dashboard?.value?.id!);
    if (r) {
      notifier.show({
        message: t("notifier:success.dashboard_delete"),
        theme: "success",
      });
      handleLoadMyDashboards();
      handleCreateEditDashboardDialogOpen(false, false);
      navigate(`/dashboard/${dashboardSectionAndRoutesKeys.myMetrics}`);
    } else {
      notifier.show({
        message: t("notifier:error.something_wrong"),
        theme: "error",
      });
    }
  }, [
    dashboard?.value?.id,
    handleCreateEditDashboardDialogOpen,
    handleLoadMyDashboards,
    handleOpenDeleteDashboardDialogChange,
    navigate,
    notifier,
    t,
  ]);

  const handleCheckAndFixIncorrectUri = useCallback(() => {
    if (!uriSection) return navigate(`/dashboard/${dashboardSectionAndRoutesKeys.myMetrics}`);
  }, [navigate, uriSection]);

  const handleHidingSidebar = useCallback(() => {
    setIsHideSidebar(!isHideSidebar);
  }, [isHideSidebar]);

  const handleDashboardColumnsAmountChange = useCallback(
    async (amount: number) => {
      setDashboardColumnsAmount(amount);
      if (dashboardSections.includes(uriSection as dashboardSectionAndRoutesKeys)) {
        LocalStorageHelpers.set(String(uriSection), amount);
      }
      if (dashboard.value?.id) {
        await api.dashboard.edit(dashboard.value?.id, { ...dashboard.value, columnCount: amount });
        dashboard.fetch();
      }
    },
    [dashboard, uriSection]
  );

  const handleLoadMetrics = useCallback(() => {
    setIsLoading(true);
    metrics.restart(true);
    metricsRead.restart(true);
  }, [metrics, metricsRead]);

  useEffect(() => {
    myDashboards.restart(true);
    availableDashboards.restart(true);
    return () => {};
  }, []);

  useEffect(() => {
    handleCheckAndFixIncorrectUri();
    if (dashboardSections.includes(uriSection as dashboardSectionAndRoutesKeys)) {
      dashboard.set(null);
      setDashboardColumnsAmount(LocalStorageHelpers.get(String(uriSection)) ?? 3);
    } else {
      dashboard.fetch();
    }
    if (uriSection) {
      setIsLoading(true);
      metrics.restart();
      metricsRead.restart();
    }
    return () => {};
  }, [handleCheckAndFixIncorrectUri, uriSection]);

  return (
    <>
      {openCreateEditDashboardDialog && (
        <DashboardCreateEditDialog
          open={openCreateEditDashboardDialog}
          toggleDialog={handleCreateEditDashboardDialogOpen}
          editMode={editMode}
          dashboard={dashboard.value ?? undefined}
          metrics={metrics.items ?? undefined}
          onMyDashboardsRestart={handleLoadMyDashboards}
          onDashboardRestart={dashboard.fetch}
          onMetricsRestart={handleLoadMetrics}
          onOpenDeleteDashboardDialogChange={handleOpenDeleteDashboardDialogChange}
          dashboardColumnsAmount={dashboardColumnsAmount}
        />
      )}
      <DeleteMetricOrDashboardDialog
        open={openDeleteDashboardDialog}
        onDelete={handleDashboardDelete}
        onOpen={handleOpenDeleteDashboardDialogChange}
        title={`${t("ui:title.delete_dashboard")} "${dashboard?.value?.name}"`}
        description={t("text:delete_dashboard_description")}
      />

      <PageContent
        isLoading={isLoading}
        style={{ overflow: "hidden" }}
        wrapperClassName="ma-n2 mb-1"
        className="d-stack-row spacing-4"
      >
        <div className={isHideSidebar ? "sidebar-hide" : "sidebar-visible"}>
          <DashboardSidebar
            isOpenDialogChange={handleCreateEditDashboardDialogOpen}
            onHidingSidebarChange={handleHidingSidebar}
            metricIsLoading={metrics.info.isLoading}
            metrics={metrics.items}
            myDashboards={myDashboards.items}
            myDashboardsIsDone={myDashboards.info.isDone}
            myDashboardsLoadNext={myDashboards.loadNext}
            availableDashboards={availableDashboards.items}
            availableDashboardsIsDone={availableDashboards.info.isDone}
            availableDashboardsLoadNext={availableDashboards.loadNext}
          />
        </div>
        <div className="flex-grow-1 d-stack-column pb-4">
          {isLoading ? (
            <div />
          ) : !myDashboards.items.length && !availableDashboards.items.length && !metrics.items.length ? (
            <Empty
              image={<img src={window.location.origin + "/dashboard.svg"} alt="Empty content" />}
              imageStyle={{ height: 200, width: 300, margin: "0 auto" }}
              description={
                <div className="d-stack-column justify-center align-center mt-12" style={{ width: "350px" }}>
                  <Text weight={"bold"} size="16px">
                    {t("text:no_dashboard")}
                  </Text>
                  <Button
                    variant="filled"
                    className="mt-12"
                    style={{ width: "250px" }}
                    onClick={() => handleCreateEditDashboardDialogOpen(true, false)}
                  >
                    {t("ui:button.create_dashboard")}
                  </Button>
                </div>
              }
            />
          ) : (
            <>
              <div className="my-4">
                <ControlBar
                  userId={userId}
                  dashboard={dashboard.value ?? undefined}
                  onOpenDeleteDashboardDialogChange={handleOpenDeleteDashboardDialogChange}
                  onOpenCreateEditDashboardDialogChange={handleCreateEditDashboardDialogOpen}
                  isHideSidebar={isHideSidebar}
                  onHidingSidebarChange={handleHidingSidebar}
                  dashboardColumnsAmount={dashboardColumnsAmount}
                  onDashboardColumnsAmountChange={handleDashboardColumnsAmountChange}
                />
              </div>
              <DashboardContent
                dashboard={dashboard.value ?? undefined}
                dashboardColumnsAmount={dashboardColumnsAmount}
                onRestartLoad={handleLoadMyDashboards}
                onMetricRestart={handleLoadMetrics}
                metrics={metricsRead}
                weekReportStart={weekReportStart}
                onDashboardRestart={dashboard.fetch}
              />
            </>
          )}
        </div>
      </PageContent>
    </>
  );
};

export default memo(DashboardPage);
