import React from "react";
import "./AvatarCompanySettings.scss";
import { useTranslation } from "react-i18next";
import AvatarUploadForm from "../avatarUploadForm/AvatarUploadForm";
import { useClaims } from "../../../../../hooks";
import { permissionKeys } from "../../../../../utils/permissions";
import { Avatar, Button } from "../../../../uiKit";

export interface IAvatarCompanySettingsView {
  size?: number;
  companyId?: number;
  imageUrl?: string;
  letter?: string;
  color?: string;
  isUploadDialogOpen: boolean;

  handleUploadDialogSuccess(id: number): void;
  handleDeleteClick(): void;
  handleUploadDialogOpen(): void;
  handleUploadDialogClose(): void;
}

function AvatarCompanySettingsView(props: IAvatarCompanySettingsView) {
  const { t } = useTranslation();
  const claims = useClaims();

  return (
    <>
      <AvatarUploadForm
        onClose={props.handleUploadDialogClose}
        onUpload={props.handleUploadDialogSuccess}
        open={props.isUploadDialogOpen}
        imageUrl={props.imageUrl}
        text={props.letter}
        color={props.color}
      />
      <div className="d-flex align-center justify-center" style={{ width: "100%" }}>
        <Avatar color={props.color} src={props.imageUrl} size={100} text={props.letter} />
        {claims.has(permissionKeys.company.edit.nameAndDesc) && (
          <div className="d-flex flex-column ml-4">
            <Button variant="text" onClick={() => props.handleUploadDialogOpen()}>
              {t("ui:button.edit")}
            </Button>
            <Button
              onClick={() => props.handleDeleteClick()}
              disabled={props.imageUrl == null || props.imageUrl.length == 0}
              variant="text"
              theme="error"
            >
              {t("ui:button.delete")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default AvatarCompanySettingsView;
