import React, { useEffect, useState } from "react";
import "./EditableTypography.scss";
import { IEditableTypography } from "./EditableTypography.interface";
import clsx from "clsx";
import { Button, IconButton, Input, Text, TextArea } from "../../uiKit";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";

export const EditableTypography = (props: IEditableTypography) => {
  const [showTextField, setShowTextField] = useState<boolean>(false);

  const handleChangeShowTextField = (state: boolean) => setShowTextField(state);

  const handleChangeValue = (event: any) => {
    !props.readonly && props.onChange(event.target.value as string);
  };
  const handleBlurInput = () => {
    handleChangeShowTextField(false);
    props.onBlur && props.onBlur();
  };
  const handleClickTypography = () => !props.readonly && handleChangeShowTextField(true);

  useEffect(() => {
    props.onChangeFocus && props.onChangeFocus(showTextField);
  }, [showTextField]);

  const inputStyles = clsx(
    "editable-typography__input",
    {
      "editable-typography__input-faint": props.faintColor,
      "editable-typography__input-big": props.big,
      "editable-typography__input-small": props.small,
      "editable-typography__input_new-design": props.isNewDesign,
    }
  );

  const titleStyles = clsx(
    "editable-typography__title",
    { "editable-typography__title-placeholder": props.value.trim().length === 0 },
    { "editable-typography__title-faint": props.faintColor },
    { "editable-typography__title-error": props.error },
    { "editable-typography__title-big": props.big },
    { "editable-typography__title-small": props.small }
  );

  return (
    <>
      {showTextField ||
      (!showTextField && props.value.trim().length === 0 && (props.placeholder?.length ?? 0) === 0) ? (
        !!props.multiline ? (
          <TextArea
            autoFocus
            status={props.error ? "error" : undefined}
            className={inputStyles}
            autoSize={{ maxRows: 6 }}
            value={props.value}
            placeholder={props.placeholder}
            onBlur={handleBlurInput}
            onInput={handleChangeValue}
          />
        ) : (
          <Input
            autoComplete="off"
            className={inputStyles}
            autoFocus
            status={props.error ? "error" : undefined}
            placeholder={props.placeholder}
            value={props.value}
            onBlur={handleBlurInput}
            onInput={handleChangeValue}
            isNewDesign={props.isNewDesign}
          />
        )
      ) : (
        <div
          className="d-stack-row spacing-2 align-center"
          onClick={!props.disabled ? handleClickTypography : undefined}
        >
          <Text
            onClick={!props.disabled ? handleClickTypography : undefined}
            style={{ cursor: props.disabled ? "default" : "pointer" }}
            className={titleStyles}
          >
            {props.value.trim().length > 0 ? props.value : props.placeholder}
          </Text>
          {
            !props.disabled && (
              <IconButton onClick={!props.disabled ? handleClickTypography : undefined}>
                <FiEdit3 className="editable-typography__icon" />
              </IconButton>
            )
            // <Button
            //   onClick={!disabled ? handleClickTypography : undefined}
            //   variant="text"
            //   icon={<FiEdit3 className="editable-typography__icon" />} />
          }
        </div>
      )}
    </>
  );
};
