import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useNotifier } from "../../../hooks";
import { api } from "../../../services";
import CompanyMainCard from "../../modules/pages/companies-management/companyMainCard/CompanyMainCard";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import EmployeeInviteShowDialog from "../../modules/dialogs/employeeInviteShowDialog/EmployeeInviteShowDialog";
import { CompanyForAdminDto, CompanyInviteDto } from "../../../api";
import BoardResetDialog from "../../modules/dialogs/boardResetDialog/BoardResetDialog";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { usePagingWithController } from "../../../hooks/usePaging";
import SignInAsAnotherUserDialog from "../../modules/dialogs/signInAsAnotherUserDialog/SignInAsAnotherUserDialog";
import "./CompaniesManagementPage.scss";
import RecurringTasksRecalculateDialog from "../../modules/dialogs/recurringTasksRecalculateDialog/RecurringTasksRecalculateDialog";
import CompanyPauseConfirmationDialog from "../../modules/dialogs/companyPauseConfirmationDialog/CompanyPauseConfirmationDialog";
import { FiSearch } from "@react-icons/all-files/fi/FiSearch";
import useDebounce from "../../../hooks/useDebounce";
import CreateEditCompanyDialog from "../../modules/dialogs/createEditCompanyDialog/CreateEditCompanyDialog";
import { Button, Card, Empty, Icon, Input, Select } from "../../uiKit";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { CustomConfirmDialog } from "../../modules/dialogs/customConfirmDialog/СustomConfirmDialog";
import { CompanyEmployeeStatsDialog } from "../../modules/dialogs/companyEmployeeStatsDialog/CompanyEmployeeStatsDialog";
import { AdminCompaniesStatsDialog } from "../../modules/dialogs/adminCompaniesStatsDialog/AdminCompaniesStatsDialog";
import { AdminCompanyByIdListDialog } from "../../modules/dialogs/adminCompanyByIdListDialog/AdminCompanyByIdListDialog";
import {
  CompaniesManagementInfoBlock
} from "../../modules/pages/companies-management/companiesManagementInfoBlock/CompaniesManagementInfoBlock";
import { DuplicateCompanyDialog } from "../../modules/dialogs/duplicateCompanyDialog/DuplicateCompanyDialog";

function CompaniesManagementPage() {
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState<string | null>(null);
  const currentFilterCopy = useRef<string | null>(null);
  const setCurrentFilterSync = (value: string) => {
    currentFilterCopy.current = value;
    setCurrentFilter(value);
  };
  const [currentFilterIsPaid, setCurrentFilterIsPaid] = useState<string>("null");
  const currentFilterIsPaidCopy = useRef<string>("null");
  const setCurrentFilterIsPaidSync = (value: string) => {
    currentFilterIsPaidCopy.current = value;
    setCurrentFilterIsPaid(value);
  };
  const [companiesIsTestFilter, setCompaniesIsTestFilter] = useState<string>("false");
  const companiesIsTestFilterCopy = useRef<string>("false");
  const setCompaniesIsTestFilterSync = (value: string) => {
    companiesIsTestFilterCopy.current = value;
    setCompaniesIsTestFilter(value);
  };
  const [companiesFilters, setCompaniesFilters] = useState<any>({
    name: "",
    contactName: "",
    contactPhoneNumber: "",
    externalId: "",
  });
  const convertToReqParam = (v: string, key?: string) => {
    if (key) return v === key || null;
    if (v == "true") return true;
    if (v == "false") return false;
    if (v == "null") return null;
    if (v == "null") return null;
    return null;
  };

  // const companies = useApi(() => api.adminCompany.getAll({ pageSize: 500 }));
  const companies = usePagingWithController(api.adminCompany, {
    type: currentFilterCopy.current == "all" ? null : currentFilterCopy.current,
    name: companiesFilters.name,
    contactName: companiesFilters.contactName,
    externalId: companiesFilters.externalId,
    contactPhoneNumber: companiesFilters.contactPhoneNumber,
    isTest: convertToReqParam(companiesIsTestFilterCopy.current),
    isPaid: convertToReqParam(currentFilterIsPaidCopy.current),
    isInactive: convertToReqParam(companiesIsTestFilterCopy.current) !== null
      ? false
      : convertToReqParam(companiesIsTestFilterCopy.current, 'isInactive'),
    isPartiallyPaid: convertToReqParam(currentFilterIsPaidCopy.current, 'isPartiallyPaid'),
  },
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    'with-users-count'
    );

  // const users = usePagingWithController(
  //   api.user,
  //   {
  //     ignoreContext: true,
  //     isInTestCompany: convertToReqParam(companiesIsTestFilterCopy.current),
  //     isInCompanyOfType: currentFilterCopy.current == "all" ? null : currentFilterCopy.current,
  //     isInPaidCompany: convertToReqParam(currentFilterIsPaidCopy.current),
  //     isPartiallyPaid: convertToReqParam(currentFilterIsPaidCopy.current, 'isPartiallyPaid'),
  //   },
  //   { pageSize: 0 }
  // );
  // const companies = usePaging<CompanyForAdminDto>((_, params) => api.adminCompany.getAll(params));
  const notifier = useNotifier();
  const [companyToManageId, setCompanyToManageId] = useState<number | null>(null);
  const [companyInvite, setCompanyInvite] = useState<CompanyInviteDto>({});
  const [boardToManageId, setBoardToManageId] = useState<number | null>(null);
  const [expandedCompanyId, setExpandedCompanyId] = useState<number | null>(null);
  const [companyToPauseId, setCompanyToPauseId] = useState<number | null>(null);
  const [isDeleteCompanyDialogOpen, setIsDeleteCompanyDialogOpen] = useState<boolean>(false);
  const [isRecurringTasksRecalculateDialogOpen, setIsRecurringTasksRecalculateDialogOpen] = useState<boolean>(false);
  const [isSignInAsAnotherUserDialogOpen, setIsSignInAsAnotherUserDialogOpen] = useState<boolean>(false);
  const [isCreateEditCompanyDialogOpen, setIsCreateEditCompanyDialogOpen] = useState<boolean>(false);
  const [isShowInviteDialogOpen, setIsShowInviteDialogOpen] = useState<boolean>(false);
  const [isBoardResetDialogOpen, setIsBoardResetInviteDialogOpen] = useState<boolean>(false);
  const [isEmployeeStatsDialogOpen, setIsEmployeeStatsDialogOpen] = useState<boolean>(false);
  const [isAdminCompanyByIdListDialogOpen, setIsAdminCompanyByIdListDialogOpen] = useState<boolean>(false);
  const [companyIdToEdit, setCompanyIdToEdit] = useState<number | null>(null);
  const [isCompaniesStatsDialogOpen, setIsCompaniesStatsDialogOpen] = useState<boolean>(false);
  const filters = [
    { id: 0, text: t("parse:all"), value: "all" },
    { id: 1, text: t("parse:new"), value: "new" },
    { id: 2, text: t("parse:alive"), value: "alive" },
    { id: 3, text: t("parse:dead"), value: "dead" },
  ];
  const filtersIsPaid = [
    { id: 0, text: t("parse:all"), value: "null" },
    { id: 1, text: t("parse:paid"), value: "true" },
    { id: 2, text: t("parse:free"), value: "false" },
    { id: 3, text: t("parse:partially_paid"), value: "isPartiallyPaid"},
  ];

  const filtersCompaniesIsTest = [
    { id: 0, text: t("parse:all"), value: "null" },
    { id: 1, text: t("parse:is_test"), value: "true" },
    { id: 2, text: t("parse:no_test"), value: "false" },
    { id: 3, text: t("parse:inactive"), value: "isInactive" },

  ];

  const debouncedFilters = useDebounce(companiesFilters, 500);

  // useEffect(() => {
  //   console.log("useEffect0");
  //   companies.restart();
  //   // users.restart();
  // }, []);

  const handleDeleteCompanyDialogClose = () => {
    setIsDeleteCompanyDialogOpen(false);
  };

  const handleCardClick = (id: number) => {
    expandedCompanyId == id ? setExpandedCompanyId(null) : setExpandedCompanyId(id);
  };

  const handleCompanyDelete = async () => {
    const r = await api.adminCompany.del(companyToManageId as number);
    notifier.showSuccessError(t("notifier:success.company_deleted"), t("notifier:error.company_deleted"), r != null);
    companies.restart(true);
    if (r != null) {
      setIsDeleteCompanyDialogOpen(false);
      // await companies.fetch();
    }
  };


  const [isDuplicateCompanyDialogOpen, setIsDuplicateCompanyDialogOpen] = useState<boolean>(false);
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState<boolean>(false);

  const handleDuplicateClick = useCallback((id: number) => {
    setCompanyToManageId(id);
    handleOpenDuplicateCompanyDialog();
  }, []);

  const handleOpenDuplicateCompanyDialog = useCallback(() => {
    setIsDuplicateCompanyDialogOpen(true);
  }, []);

  const handleCloseDuplicateCompanyDialog = useCallback(() => {
    setIsDuplicateCompanyDialogOpen(false);
  }, []);

  const handleDuplicateCompany = useCallback(async (ownerEmail: string) => {
    setIsLoadingDuplicate(true);
    const resp = await api.company.duplicate(companyToManageId as number, ownerEmail);
    setIsLoadingDuplicate(false);
    console.log("resp", resp);
    if (resp) {
      notifier.showSuccessError(t("notifier:success.duplicate_company"), t("notifier:error.duplicate_company"), resp != null);
      companies.restart(true);
      handleCloseDuplicateCompanyDialog();
    }
  }, [companyToManageId]);

  const handleBoardResetButtonClick = (boardId: number) => {
    setBoardToManageId(boardId);
    setIsBoardResetInviteDialogOpen(true);
  };

  const handleEmployeeStatsClick = (companyId: number) => {
    setCompanyToManageId(companyId);
    setIsEmployeeStatsDialogOpen(true);
  };

  const handleCompanyDeleteButtonClick = (companyId: number) => {
    setCompanyToManageId(companyId);
    setIsDeleteCompanyDialogOpen(true);
  };

  const handleCompanySettingsButtonClick = (companyId: number) => {
    setCompanyIdToEdit(companyId);
    setIsCreateEditCompanyDialogOpen(true);
  };

  const handleCompanyCreate = async (data: CompanyForAdminDto) => {
    setCompanyToManageId(data.id as number);
    setCompanyInvite(data.inviteForOwner as CompanyInviteDto);
    // await companies.fetch();
    await companies.reset();
    await companies.restart();
    // await users.reset();
    // await users.restart();
    setIsShowInviteDialogOpen(true);
  };

  const handleBoardResetSuccess = async () => {
    setExpandedCompanyId(null);
    setIsBoardResetInviteDialogOpen(false);
    // await companies.fetch();
    console.log("handleBoardResetSuccess");
    await companies.reset();
    await companies.restart();
  };

  const handleAllBoardsResetClick = () => {
    setBoardToManageId(-1);
    setIsBoardResetInviteDialogOpen(true);
  };

  const handleSignInAsAnotherUserClick = () => {
    setIsSignInAsAnotherUserDialogOpen(true);
  };

  const handleSignInAsAnotherUserDialogClose = () => {
    setIsSignInAsAnotherUserDialogOpen(false);
  };

  const handleSignInAsAnotherUserDialogAccept = () => {
    setIsSignInAsAnotherUserDialogOpen(false);
  };

  const handleCompaniesStatisticsOpen = () => {
    setIsCompaniesStatsDialogOpen(true);
  };

  const handleCompaniesStatisticsClose = () => {
    setIsCompaniesStatsDialogOpen(false);
  };

  const handleFilterChange = async (value: string) => {
    setCurrentFilterSync(value);
    // await companies.reset();
    // await companies.restart();
  };

  const handleFilterIsPaidChange = async (value: string) => {
    setCurrentFilterIsPaidSync(value);
    // await companies.reset();
    // await companies.restart();
  };

  const handleFilterIsTestCompaniesChange = async (value: string) => {
    setCompaniesIsTestFilterSync(value);
  };

  const handleCompaniesRefresh = async () => {
    // await companies.reset();
    console.log("handleCompaniesRefresh");
    await companies.restart(true);
  };

  const handleCompanyPause = async () => {
    if (companyToPauseId == null) return;
    const r = await api.adminCompany.pause(companyToPauseId);
    if (r) {
      notifier.show({ message: "Компания успешно приостановлена", theme: "success" });
      await companies.reset();
      await companies.restart();
    }
    setCompanyToPauseId(null);
  };

  useEffect(() => {
    console.log("useEffect1");
    companies.reset();
    companies.restart();
    // users.reset();
    // users.restart();
  }, [
    currentFilterCopy.current,
    currentFilterIsPaidCopy.current,
    companiesIsTestFilterCopy.current,
    debouncedFilters,
  ]);

  // useEffect(() => {
  //   console.log("useEffect2");
  //   companies.reset();
  //   companies.restart();
  // }, [debouncedFilters]);

  const companiesManageMenuItems: PopupMenuItemTypes[] = [
    { id: 0, text: t("parse:login_as_another_user"), action: () => handleSignInAsAnotherUserClick() },
    {
      id: 1,
      text: t("parse:recurring_task_recalculating"),
      action: () => setIsRecurringTasksRecalculateDialogOpen(true),
    },
    { id: 2, text: t("parse:reset_all_boards"), action: () => handleAllBoardsResetClick() },
    { id: 3, text: t("parse:companies_statistics"), action: () => handleCompaniesStatisticsOpen() },
    { id: 4, text: "Информация о компаниях по ID", action: () => setIsAdminCompanyByIdListDialogOpen(true) },
  ];

  const totalEmployees = (companies.info.usersCount?.Full ?? 0) + (companies.info.usersCount?.Restricted ?? 0)

  return (
    <>
      {isDeleteCompanyDialogOpen && (
        <CustomConfirmDialog
          title={t("ui:title.company_delete")}
          subTitle={t("text:companies_manage.delete")}
          onConfirm={handleCompanyDelete}
          onClose={handleDeleteCompanyDialogClose}
          open={isDeleteCompanyDialogOpen}
        />
      )}
      {/*<CompanyDeleteDialog*/}
      {/*  open={isDeleteCompanyDialogOpen}*/}
      {/*  onClose={handleDeleteCompanyDialogClose}*/}
      {/*  onDelete={handleCompanyDelete}*/}
      {/*/>*/}
      {isDuplicateCompanyDialogOpen && (
        <DuplicateCompanyDialog
          open={isDuplicateCompanyDialogOpen}
          onClose={handleCloseDuplicateCompanyDialog}
          onAccept={handleDuplicateCompany}
          isLoading={isLoadingDuplicate}
        />
      )}
      <SignInAsAnotherUserDialog
        open={isSignInAsAnotherUserDialogOpen}
        onClose={handleSignInAsAnotherUserDialogClose}
        onAccept={handleSignInAsAnotherUserDialogAccept}
      />
      <AdminCompanyByIdListDialog
        isOpen={isAdminCompanyByIdListDialogOpen}
        onClose={() => setIsAdminCompanyByIdListDialogOpen(false)}
      />
      <EmployeeInviteShowDialog
        inviteData={companyInvite}
        open={isShowInviteDialogOpen}
        onClose={() => setIsShowInviteDialogOpen(false)}
      />
      <CompanyPauseConfirmationDialog
        open={companyToPauseId != null}
        onClose={() => setCompanyToPauseId(null)}
        onAccept={() => handleCompanyPause()}
      />
      <RecurringTasksRecalculateDialog
        open={isRecurringTasksRecalculateDialogOpen}
        onClose={() => setIsRecurringTasksRecalculateDialogOpen(false)}
        onApply={() => setIsRecurringTasksRecalculateDialogOpen(false)}
      />
      <BoardResetDialog
        open={isBoardResetDialogOpen}
        boardId={boardToManageId as number}
        onClose={() => setIsBoardResetInviteDialogOpen(false)}
        onReset={() => handleBoardResetSuccess()}
      />
      {isEmployeeStatsDialogOpen && (
        <CompanyEmployeeStatsDialog
          open={isEmployeeStatsDialogOpen}
          companyId={companyToManageId}
          onClose={() => setIsEmployeeStatsDialogOpen(false)}
        />
      )}
      <AdminCompaniesStatsDialog open={isCompaniesStatsDialogOpen} onClose={handleCompaniesStatisticsClose} />
      <CreateEditCompanyDialog
        open={isCreateEditCompanyDialogOpen}
        onClose={() => {
          setIsCreateEditCompanyDialogOpen(false);
          setCompanyIdToEdit(null);
        }}
        onSave={() => handleCompaniesRefresh()}
        onCreate={(data) => handleCompanyCreate(data)}
        companyId={companyIdToEdit}
      />
      <PageHeader title={t("common:page_title.companies_management")}>
        <Button variant="outlined" style={{ marginLeft: "8px" }} onClick={() => setIsCreateEditCompanyDialogOpen(true)}>
          <Icon component={() => <FiPlus />} />
          {t("ui:button.create_company")}
        </Button>
        <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          <DropdownMenu items={companiesManageMenuItems} />
        </div>
      </PageHeader>
      <Card className="companies-management-page__panel d-flex flex-column" style={{ marginBottom: "12px" }}>
        <div className="d-stack spacing-1">
          <Select
            className="flex-shrink-0"
            style={{ width: "120px" }}
            value={currentFilter ?? "all"}
            onChange={(value) => handleFilterChange(value)}
            items={filters}
          />
          <Select
            className="flex-shrink-0"
            style={{ width: "240px" }}
            value={currentFilterIsPaid ?? "null"}
            onChange={handleFilterIsPaidChange}
            items={filtersIsPaid}
          />
          <Select
            className="flex-shrink-0"
            style={{ width: "120px" }}
            value={companiesIsTestFilter ?? "false"}
            onChange={handleFilterIsTestCompaniesChange}
            items={filtersCompaniesIsTest}
          />
          <Input
            className="flex-grow-1"
            value={companiesFilters.externalId}
            size="large"
            onInput={(event: any) => {
              event.stopPropagation();
              setCompaniesFilters({ ...companiesFilters, externalId: event.target.value });
            }}
            // bordered={false}
            prefix={<FiSearch />}
            placeholder={t("parse:external_id")}
          />
          <Input
            className="flex-grow-1"
            value={companiesFilters.contactName}
            size="large"
            onInput={(event: any) => {
              event.stopPropagation();
              setCompaniesFilters({ ...companiesFilters, contactName: event.target.value });
            }}
            // bordered={false}
            prefix={<FiSearch />}
            placeholder={t("ui:subheader.contact_person")}
          />
          <Input
            className="flex-grow-1"
            value={companiesFilters.contactPhoneNumber}
            size="large"
            onInput={(event: any) => {
              event.stopPropagation();
              setCompaniesFilters({ ...companiesFilters, contactPhoneNumber: event.target.value });
            }}
            // bordered={false}
            prefix={<FiSearch />}
            placeholder={t("ui:placeholder.contact_phone_number")}
          />
          <Input
            className="flex-grow-1"
            value={companiesFilters.name}
            size="large"
            onInput={(event: any) => {
              event.stopPropagation();
              setCompaniesFilters({ ...companiesFilters, name: event.target.value });
            }}
            // bordered={false}
            prefix={<FiSearch />}
            placeholder={t("parse:company_name")}
          />
        </div>
        <div className="d-flex spacing-8 d-stack-row mt-6 mb-6">
          <CompaniesManagementInfoBlock
            text={`${t("common:misc.total_companies")}:`}
            count={companies.info.totalItems ?? 0}
          />
          <CompaniesManagementInfoBlock
            text={`${t("common:misc.total_employees")}:`}
            count={totalEmployees}
          />
          <CompaniesManagementInfoBlock
            text={`${t("parse:employee_type.full")}:`}
            count={companies.info.usersCount?.Full ?? 0}
          />
          <CompaniesManagementInfoBlock
            text={`${t("parse:employee_type.limited")}:`}
            count={companies.info.usersCount?.Restricted ?? 0}
          />
        </div>
      </Card>
      <PageContent isLoading={companies.items.length == 0 && companies.info.isLoading}>
        {companies.items.length > 0 && (
          <div className="d-stack-column spacing-3">
            {companies?.items?.map((company, index) => (
              <CompanyMainCard
                key={company.id}
                handleCardClick={handleCardClick}
                marginTop={index > 0}
                expandedCardId={expandedCompanyId ?? undefined}
                onDeleteClick={handleCompanyDeleteButtonClick}
                onBoardResetClick={handleBoardResetButtonClick}
                onEmployeeStatsClick={handleEmployeeStatsClick}
                onDuplicateCompany={handleDuplicateClick}
                handlePauseClick={() => setCompanyToPauseId(company.id as number)}
                handleSettingsClick={handleCompanySettingsButtonClick}
                data={company}
              />
            ))}
            <ScrollTrigger
              disabled={companies.info.isDone}
              onIntersection={() => companies.loadNext()}
              marginTop={companies.items.length > 0}
              hidden={companies.info.isDone}
            />
          </div>
        )}
        {companies.items.length == 0 && !companies.info.isLoading && (
          <Card>
            <Empty className="my-4" />
          </Card>
        )}
      </PageContent>
    </>
  );
}

export default CompaniesManagementPage;
