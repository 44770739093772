import React, { memo } from "react";
import { RegulationCreationOrSelectionTemplateDialogProps } from "./RegulationCreationOrSelectionTemplateDialog";
import { Button, Dialog, DialogActions, Input, Select } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { TemplateDialogContentTypeKeys } from "../consts/consts";

export const RegulationCreationOrSelectionTemplateDialogView = memo(
  (props: RegulationCreationOrSelectionTemplateDialogProps) => {
    const { isOpen, onClose, contentType } = props;

    const { t } = useTranslation();

    const handleGetContent = () => {
      switch (contentType) {
        case TemplateDialogContentTypeKeys.Creation:
          return (
            <>
              <SubheaderText text={t("parse:enter_name")} />
              <Input placeholder={t("parse:enter_name")} />
            </>
          );
        case TemplateDialogContentTypeKeys.Selection:
          return (
            <>
              <SubheaderText text={t("parse:templates_list")} />
              <Select placeholder={t("parse:select_template")} fullWidth />
            </>
          );
        default:
          return null;
      }
    };

    return (
      <Dialog title={t("ui:title.regulation_templates")} open={isOpen} onClose={onClose} destroyOnClose>
        {handleGetContent()}
        <DialogActions>
          <Button variant="filled">
            {props.contentType === TemplateDialogContentTypeKeys.Creation ? t("ui:button.save") : t("ui:button.apply")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
