import React from "react";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { IssueProofRequirementDto, IssueProofRequirementRuleDto } from "../../../../api";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { getRoutineIssueTooltip, routineIssueTooltipKeys } from "../../../../utils/tooltip";
import { Tooltip } from "antd";
import { Input, Select, TextArea } from "../../../uiKit";
import { LocalesKeys } from "../../../../constants/localesKeys";
import { useRootStore } from "../../../../hooks";

interface IIssueProofFormView {
  data: IssueProofRequirementDto | null;
  proofTypesList: any[];
  rules: IssueProofRequirementRuleDto[];
  readOnly?: boolean;
  showHintIcon?: boolean;
  onBlur?: () => void;

  onChange(data: IssueProofRequirementDto | null): void;

  onProofTypeChange(type: string | null): void;

  onProofValueChange(value: number): void;
}

function IssueProofFormView(props: IIssueProofFormView) {
  const { appStore } = useRootStore();
  const { t } = useTranslation();

  const getTooltip = getRoutineIssueTooltip(appStore.getAppLocale as LocalesKeys);

  return (
    <>
      <div className="d-stack-column spacing-1">
        <div className="d-stack-row spacing-2">
          <div className="flex-grow-1">
            <div className="d-stack-row justify-start align-center spacing-1">
              <SubheaderText text={t("ui:subheader.proof")} />
              {props.showHintIcon && (
                <Tooltip zIndex={1301} title={getTooltip(routineIssueTooltipKeys.proof)} placement="bottom">
                  <div style={{ marginTop: "-2px" }}>
                    <FiHelpCircle className="guide-icon" />
                  </div>
                </Tooltip>
              )}
            </div>
            <Select
              dropdownStyle={{ zIndex: 1202 }}
              style={{ width: "100%" }}
              value={props.data?.rules?.[0]?.key ?? ""}
              items={props.proofTypesList}
              onBlur={() => (props.onBlur ? props.onBlur() : undefined)}
              readOnly={props.readOnly}
              onChange={(value) => props.onProofTypeChange(value)}
            />
          </div>
          {props.data?.rules != null && props.data?.rules?.length > 0 && (
            <div style={{ flexBasis: "40%" }}>
              <SubheaderText text={t("ui:misc." + props.data?.rules?.[0]?.key)} />
              <Input
                value={props.data?.rules?.[0]?.value}
                onInput={(event: any) => props.onProofValueChange(Number(event.target.value))}
                onBlur={() => (props.onBlur ? props.onBlur() : undefined)}
                type="number"
                readOnly={props.readOnly}
              />
            </div>
          )}
        </div>

        {props.data != null && (
          <div className="mt-2">
            <SubheaderText text={t("ui:subheader.proof_description")} />
            <TextArea
              placeholder={t("ui:placeholder.issue_proof_description")}
              value={props.data?.text || ""}
              onChange={(event: any) =>
                props.onChange({
                  ...props.data,
                  text: event.target.value,
                })
              }
              onBlur={() => (props.onBlur ? props.onBlur() : undefined)}
              readOnly={props.readOnly}
              autoSize
            />
          </div>
        )}
      </div>
    </>
  );
}

export default IssueProofFormView;
