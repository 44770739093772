import React, { useState } from "react";
import AvatarUserSettingsView from "./AvatarUserSettingsView";

export interface IAvatarUserSettings {
  size?: number;
  userId?: number;
  currentUser?: boolean;
  imageUrl?: string;
  text?: string | null | (string | undefined | null)[];
  color?: string;

  onDeleteClick(): void;
  onUploadSuccess(id: number): void;
}

function AvatarUserSettings(props: IAvatarUserSettings) {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);

  const handleUploadDialogClose = () => {
    setIsUploadDialogOpen(false);
  };

  const handleUploadDialogOpen = () => {
    setIsUploadDialogOpen(true);
  };

  const handleUploadDialogSuccess = (id: number) => {
    setIsUploadDialogOpen(false);
    props.onUploadSuccess(id);
  };

  const handleDeleteClick = () => {
    props.onDeleteClick();
  };

  return (
    <AvatarUserSettingsView
      {...props}
      isUploadDialogOpen={isUploadDialogOpen}
      handleUploadDialogOpen={handleUploadDialogOpen}
      handleUploadDialogClose={handleUploadDialogClose}
      handleUploadDialogSuccess={handleUploadDialogSuccess}
      handleDeleteClick={handleDeleteClick}
    />
  );
}

export default AvatarUserSettings;
