import React from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import "./Error404Page.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import page404 from "../../..//assets/images/page-404.svg";
import { Button, Title } from "../../uiKit";

function Error404Page() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <PageContent isFilled contentPosition={{ isCentered: true }}>
        <div className="d-stack-column spacing-10 align-center">
          <Title level={4}>{t("common:page_title.page_404") + " 🙁"}</Title>
          <img draggable="false" style={{ maxWidth: "40%" }} src={page404} />
          <ul style={{ listStyle: "none", textAlign: "center" }}>
            <li>Убедитесь, что Вы перешли по верной ссылке</li>
            <li>Проверьте, нет ли опечаток в адресе страницы</li>
          </ul>
          <Button onClick={() => navigate("/orgchart")} variant="filled">
            Вернуться на главную страницу
          </Button>
        </div>
      </PageContent>
    </>
  );
}

export default Error404Page;
