import React from "react";
import { IOrgchartCommonDrawer } from "./OrgchartCommonDrawer";
import { useTranslation } from "react-i18next";
import "./OrgchartCommonDrawer.scss";
import { AppTabType } from "../../../../../utils/appLinksAndTabs";
import { Drawer } from "../../../../uiKit";

interface IOrgchartCommonDrawerView extends IOrgchartCommonDrawer {
  tabs: AppTabType[];
  currentTabId: number;

  onTabChange(tabId: number): void;
}

function OrgchartCommonDrawerView(props: IOrgchartCommonDrawerView) {
  const { t } = useTranslation();

  return (
    <Drawer
      contentWrapperStyle={{
        maxWidth: "800px",
        width: "100%",
      }}
      open={props.open}
      destroyOnClose
      title={t("common:misc.all_recurring_tasks")}
      closable
      // keepMounted={false}
      onClose={() => props.onClose()}
    >
      {/*<CloseButton onClick={() => props.onClose()} />*/}
      {/*<div*/}
      {/*  className="mt-0 mb-2"*/}
      {/*  style={{*/}
      {/*    borderBottom: 1,*/}
      {/*    borderColor: "var(--color-divider-main)",*/}
      {/*    backgroundColor: "var(--color-white)",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Tabs value={props.currentTabId} onChange={(event, newValue) => props.onTabChange(newValue)}>*/}
      {/*    {props.tabs.map((tab: AppTabType) => (*/}
      {/*      <Tab key={tab.id} value={tab.id} label={tab.name} />*/}
      {/*    ))}*/}
      {/*  </Tabs>*/}
      {/*</div>*/}
      <div className="full-height" style={{ position: "relative", overflow: "hidden" }}>
        {props.tabs.map(
          (tab: AppTabType) =>
            tab.id == props.currentTabId && (
              <div style={{ height: "100%", width: "100%", position: "relative" }} key={tab.id}>
                {tab.element({
                  // functionData: props.functionData,
                  // refreshData: props.refreshData,
                  // setFunctionData: props.setFunctionData,
                  // setIsDrawerClosable: props.setIsDrawerClosable,
                })}
              </div>
            )
        )}
      </div>
    </Drawer>
  );
}

export default OrgchartCommonDrawerView;
