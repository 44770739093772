import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RegulationAccessControlDialogView } from "./RegulationAccessControlDialogView";
import {
  RegulationFunctionSelectionDialog
} from "./regulationFunctionSelectionDialog/RegulationFunctionSelectionDialog";
import { AccessControlDialogKeys, TemplateDialogContentTypeKeys } from "./consts/consts";
import { useNotifier, useRootStore } from "../../../../hooks";
import { AccessControlDialogContext } from "./AccessDialogContext";
import { useOrgchart } from "../../pages/regulation/hooks/useOrgchart";
import { api } from "../../../../services";
import {
  NavigationMenuItemClaimGroupDto,
  NavigationMenuItemUserSelectionRuleClaimDto
} from "../../../../api/models/NavigationMenuItemClaimGroupDto";
import { MenuItemClaimType } from "../../../../api/models/NavigationMenuDto";
import { useTranslation } from "react-i18next";
import { UserShortDto } from "../../../../api";
import { isEqual } from "lodash";
import { ISelectItem } from "../../../uiKit/select/SelectView";

export interface RegulationAccessControlDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onOpenFunctionSelectionDialog?: () => void;
  handleChangeOrgchartId?: (orgchartId: number | number[]) => void;
  orgchartId?: number | number[];
  sectionId?: number | null;
  regulationId?: number | null;
  onSave?: () => void;
  onChangeShowOnOrgStructure?: (value: boolean) => void;
  isButtonLoading?: boolean;
  createdByUser?: UserShortDto;
  sectionsList?: ISelectItem[];
  navigationMenuItemId?: number
  onChangeNavigationMenuItemId?: (id: number) => void;
  isDisabledSaveButton?: boolean;
  title?: string;
}

export const RegulationAccessControlDialog = memo((props: RegulationAccessControlDialogProps) => {
  const { orgchartStore } = useRootStore();

  const {
    isLoading,
    fetchRoleSelectorsClaimTreeData,
    treeData,
    expandedKeys,
    setExpandedKeys,
    selectedRoles,
    setSelectedRoles,
    updateTreeItemData,
    claimType,
    setClaimType,
  } = useOrgchart();

  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isOpenFunctionSelectionDialog, setIsOpenFunctionSelectionDialog] = useState<boolean>(false);
  const handleAccessControlDialogClose = () => {
    setIsOpenFunctionSelectionDialog(false);
  };
  const handleFunctionSelectionDialogOpen = () => {
    setIsOpenFunctionSelectionDialog(true);
  };

  const [orgchartId, setOrgchartId] = useState<number>(orgchartStore.getCurrentOrgchartId!);


  const handleChangeOrgchartId = useCallback((orgchartId: number | number[]) => {
    setOrgchartId(orgchartId as number);
  }, []);

  const handleAccessControlDialogOpen = () => {
    console.log("handleAccessControlDialogOpen");
  };

  const handleSetContentType = useCallback((contentType: TemplateDialogContentTypeKeys) => {
    console.log("contentType");
  }, []);

  const [claimGroup, setClaimGroup] = useState<NavigationMenuItemClaimGroupDto>({});
  const prevClaimGroup = useRef<NavigationMenuItemClaimGroupDto>({});

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const fetchClaimGroup = useCallback(async (id: number, isRegular: boolean) => {
    const group = isRegular
      ? await api.regulation.getClaimGroup(id)
      : await api.navigationMenu.getClaimGroup(id) ;
    if (group !== null) {
      prevClaimGroup.current = group;
      setClaimGroup(group);
    } else {
      handleApiError();
    }
  }, []);

  // const [navigationMenuItemId, setNavigationMenuItemId] = useState<number>(
  //   props.sectionsList?.length
  //     ? +props.sectionsList[0]?.value ?? -1
  //     : sectionId
  // );
  const [navigationMenuItemId, setNavigationMenuItemId] = useState<number>(props.sectionId ?? -1);

  const handleChangeNavigationMenuItemId = useCallback((id: number) => {
    setNavigationMenuItemId(id);
    fetchClaimGroup(id, false);
  }, []);


  const handleDeleteClaim = useCallback((item: NavigationMenuItemUserSelectionRuleClaimDto) => {
    const { claimType, id, roleId } = item;
    setClaimGroup((prev: NavigationMenuItemClaimGroupDto) => ({
        ...prev,
        [claimType]: (prev[claimType] ?? []).filter((item) => id ? item.id !== id : item.roleId !== roleId)
    }));
    updateTreeItemData({
      list: treeData,
      key: `${roleId}`,
      isSelected: false,
      includeNestedRoles: true,
    });
  }, [treeData]);

  useEffect(() => {
    if (props.regulationId || props.sectionId) {
      const id = props.regulationId || props.sectionId
      fetchClaimGroup(id!, !!props.regulationId)
    }
  }, [props.regulationId, props.sectionId]);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const handleClickSave = useCallback(async () => {
    setIsButtonLoading(true)
    const data: NavigationMenuItemUserSelectionRuleClaimDto[] = [];
    Object.values(claimGroup).forEach((value) => {
      data.push(...value);
    });
    if (props.sectionId) {
      setIsButtonLoading(true)
      const status =
        await api.navigationMenu.setRoleSelectorsClaim(props.sectionId, orgchartId, data);
      setIsButtonLoading(false);
        if (status === null) {
          handleApiError();
        } else {
          notifier.show({ message: t("notifier:success.access_control"), theme: "success" });
          props.onClose();
        }
      }
  }, [claimGroup, selectedRoles, props.sectionId]);

  const isDisabledSaveButton = useMemo(() => {
    return isEqual(claimGroup, prevClaimGroup.current);
  }, [claimGroup, prevClaimGroup.current]);

  const handleChangeShowOnOrgStructure = useCallback((showOnOrgStructure: boolean) => {
    setClaimGroup((prev) => ({
      ...prev,
      showOnOrgStructure
    }));
  }, []);

  return (
    <AccessControlDialogContext.Provider value={{
      isLoading,
      fetchRoleSelectorsClaimTreeData,
      treeData,
      expandedKeys,
      setExpandedKeys,
      selectedRoles,
      setSelectedRoles,
      updateTreeItemData,
      claimGroup,
      setClaimGroup,
      handleDeleteClaim,
      claimType,
      setClaimType,
    }}>
      <RegulationFunctionSelectionDialog
        isOpen={isOpenFunctionSelectionDialog}
        onClose={handleAccessControlDialogClose}
        onOpenCreationOrSelectionTemplateDialog={handleAccessControlDialogOpen}
        onSetContentType={handleSetContentType}
        orgchartId={orgchartId}
        handleChangeOrgchartId={handleChangeOrgchartId}
        sectionId={navigationMenuItemId}
      />
      <RegulationAccessControlDialogView
        {...props}
        onOpenFunctionSelectionDialog={handleFunctionSelectionDialogOpen}
        handleChangeOrgchartId={handleChangeOrgchartId}
        onChangeShowOnOrgStructure={handleChangeShowOnOrgStructure}
        orgchartId={orgchartId}
        onSave={handleClickSave}
        isDisabledSaveButton={isDisabledSaveButton}
        isButtonLoading={isButtonLoading}
        navigationMenuItemId={navigationMenuItemId}
        onChangeNavigationMenuItemId={handleChangeNavigationMenuItemId}
      />
    </AccessControlDialogContext.Provider>

  );
});
