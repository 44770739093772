import React, { forwardRef, useImperativeHandle } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { IVerificationCodeFormView } from "./VerificationCodeForm.interface";
import { Button, Input } from "../../../uiKit";
import { verificationCode } from "../validation/verificationCode";

export const VerificationCodeFormView = forwardRef((props: IVerificationCodeFormView, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleValidateCode = verificationCode({
    t,
    isNumberOnly: props.codeIsNumberOnly,
    length: props.defaultCodeLength,
  });

  const handleSubmit = () => form.submit();

  useImperativeHandle(ref, () => ({ submitForm: handleSubmit }));

  return (
    <Form name="verification-code" form={form} onFinish={props.onCodeSubmit}>
      <SubheaderText text={t("ui:subheader.code")} />
      <Form.Item
        name="code"
        hasFeedback
        preserve={false}
        rules={[
          { required: true, message: t("validation:field_required") },
          {
            validator: (rule, value, callback) => handleValidateCode({ rule, value, callback }),
          },
        ]}
      >
        <Input
          value={props.code}
          placeholder={t("ui:placeholder.code")}
          onChange={(event) => props.onChangeCode(event.target.value)}
        />
      </Form.Item>
      {props.remainingTime && (
        <h5>
          {t("parse:remained")} {props.remainingTime}
        </h5>
      )}
      <Button onClick={handleSubmit} htmlType="submit" variant="filled">
        {t("ui:button.send")}
      </Button>
    </Form>
  );
});
