import React from "react";
import { ITabFilterItem } from "./tabFilterItem.interface";
import "./TabFilterItem.scss";
import clsx from "clsx";

export const TabFilterItem = (props: ITabFilterItem) => {
  const handleClick = () => {
    props.onChange(props.tab.value);
  }

  return (
    <div
      className={clsx("tab-filter-item", {
        "tab-filter-item_active": props.activeTab === props.tab.value,
      })}
      onClick={handleClick}
    >
      { props.tab.label}
    </div>
  )
}
