import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import { GenerateConfig } from "rc-picker/lib/generate";
import dayjs, { Dayjs } from "dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import { forwardRef, memo } from "react";
import { TDatePickerViewProps } from "../datePicker/DatePickerView";
import { TDatePickerRangeViewProps } from "../datePickerRange/DatePickerRangeView";

type TDatePickerGeneratedSingleProps = TDatePickerViewProps & { timeZone?: string };
type TDatePickerGeneratedRangeProps = TDatePickerRangeViewProps & { timeZone?: string };

const datePickerGenerated = (timeZone?: string): GenerateConfig<Dayjs> => {
  return {
    // Common config
    ...dayjsGenerateConfig,

    // Get
    getNow: () => dayjs().tz(timeZone),
    // getFixedDate: (date) => dayjsGenerateConfig.getFixedDate(date).tz(timeZone),
    // getEndDate: (date) => date.tz(timeZone).endOf("month"),
    // getWeekDay: (date) => {
    //   return dayjsGenerateConfig.getWeekDay(date.tz(timeZone));
    //   // const clone = date.locale("en");
    //   // return clone.weekday() + clone.localeData().firstDayOfWeek();
    // },
    // getYear: (date) => date.tz(timeZone).year(),
    // getMonth: (date) => date.tz(timeZone).month(),
    // getDate: (date) => date.tz(timeZone).date(),
    // getHour: (date) => date.tz(timeZone).hour(),
    // getMinute: (date) => date.tz(timeZone).minute(),
    // getSecond: (date) => date.tz(timeZone).second(),
    //
    // // Set
    // addYear: (date, diff) => date.tz(timeZone).add(diff, "year"),
    // addMonth: (date, diff) => date.tz(timeZone).add(diff, "month"),
    // addDate: (date, diff) => date.tz(timeZone).add(diff, "day"),
    // setYear: (date, year) => date.tz(timeZone).year(year),
    // setMonth: (date, month) => date.tz(timeZone).month(month),
    // setDate: (date, num) => date.tz(timeZone).date(num),
    // setHour: (date, hour) => date.tz(timeZone).hour(hour),
    // setMinute: (date, minute) => date.tz(timeZone).minute(minute),
    // setSecond: (date, second) => date.tz(timeZone).second(second),
    //
    // // Compare
    // isAfter: (date1, date2) => date1.tz(timeZone).isAfter(date2.tz(timeZone)),
    // isValidate: (date) => date.tz(timeZone).isValid(),

    // Locale
    locale: {
      ...dayjsGenerateConfig.locale,
      // getWeekFirstDay: (locale) => dayjs().localeData().firstDayOfWeek(),
      // getWeekFirstDate: (locale, date) => date.weekday(0),
      // getWeek: (locale, date) => date.locale().week(),
      // getShortWeekDays: (locale) => dayjs().localeData().weekdaysMin(),
      // getShortMonths: (locale) => dayjs().localeData().monthsShort(),
      // format: (locale, date, format) => date.format(format),
    },
  };
};

const DatePickerGeneratedSingleRef = forwardRef<any, TDatePickerGeneratedSingleProps>(({ timeZone, ...rest }, ref) => {
  const Picker = generatePicker<Dayjs>(datePickerGenerated(timeZone));

  return <Picker {...rest} ref={ref} />;
});

const DatePickerGeneratedRangeRef = forwardRef<any, TDatePickerGeneratedRangeProps>(({ timeZone, ...rest }, ref) => {
  const Picker = generatePicker<Dayjs>(datePickerGenerated(timeZone));

  return <Picker.RangePicker {...rest} ref={ref} />;
});

export const DatePickerGeneratedSingle = memo(DatePickerGeneratedSingleRef);
export const DatePickerGeneratedRange = memo(DatePickerGeneratedRangeRef);
