import React from "react";
import { RadioGroupView } from "./RadioGroupView";
import { RadioGroupProps } from "antd";

interface IRadioGroup extends RadioGroupProps {
  //
}

export function RadioGroup(props: IRadioGroup) {
  return <RadioGroupView {...props} />;
}
