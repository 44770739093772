import React from "react";
import "./UserTooltip.scss";
import { UserTooltipView } from "./UserTooltipView";
import { UserDto, UserShortDto } from "../../../api";
import { ITooltip } from "../../uiKit/dataDisplay/tooltip/Tooltip";

export interface IUserTooltip {
  children: React.ReactNode;
  user?: UserDto | UserShortDto | null;
  tooltipProps?: ITooltip;
  zIndex?: number;
  isNewStyle?: boolean;
  isShowTooltip?: boolean;
}

export const UserTooltip = (props: IUserTooltip) => {
  return <UserTooltipView {...props} />;
};
