import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Empty, Icon, Spin, Text } from "../../../../uiKit";
import { CompanyIntervalDto } from "../../../../../api";
import { IntervalSettingsDialog } from "../intervalSettingsDialog/IntervalSettingsDialog";
import { permissionKeys } from "../../../../../utils/permissions";
import { useClaims } from "../../../../../hooks";
import { FiEdit2 } from "react-icons/fi";
import { FiEye } from "@react-icons/all-files/fi/FiEye";

interface IModuleIntervalListViewProps {
  isLoading: boolean;
  isIntervalDialogOpen: boolean;
  intervalToEdit: CompanyIntervalDto | null;
  companyIntervals: CompanyIntervalDto[];

  setIntervalToEdit: (interval: CompanyIntervalDto) => void;
  setIsIntervalDialogOpen: (isOpen: boolean) => void;
  onIntervalSave: () => void;
  onIntervalAdd: () => void;
  onIntervalChange: (interval: CompanyIntervalDto) => void;
}

export const ModuleIntervalListView = (props: IModuleIntervalListViewProps) => {
  const { t } = useTranslation();
  const claims = useClaims();

  return (
    <>
      <IntervalSettingsDialog
        isOpen={props.isIntervalDialogOpen}
        isReadOnly={!claims.has(permissionKeys.company.edit.modules)}
        isLoading={props.isLoading}
        interval={props.intervalToEdit}
        intervalArray={props.companyIntervals}
        onSave={props.onIntervalSave}
        onChange={props.onIntervalChange}
        onClose={() => props.setIsIntervalDialogOpen(false)}
      />
      <div className="d-stack-column spacing-4">
        <Text>{t("parse:intervals.intervals_settings")}</Text>
        <Spin spinning={props.isLoading}>
          {props.companyIntervals.length == 0 && !props.isLoading ? (
            <Empty />
          ) : (
            <div className="d-stack-column spacing-2">
              {props.companyIntervals
                .filter((i) => i.id != 0)
                .map((interval, index) => (
                  <Card
                    clickable
                    variant="secondary"
                    isShadowed={false}
                    onClick={() => {
                      props.setIntervalToEdit(interval);
                      props.setIsIntervalDialogOpen(true);
                    }}
                    key={interval.id}
                  >
                    <div className="d-flex align-center">
                      <Text className="flex-grow-1 mr-2">{interval.name}</Text>
                      <Icon
                        component={() => (claims.has(permissionKeys.company.edit.modules) ? <FiEdit2 /> : <FiEye />)}
                      />
                    </div>
                  </Card>
                ))}
            </div>
          )}
        </Spin>
        {claims.has(permissionKeys.company.edit.modules) && (
          <Button
            loading={props.isLoading}
            className="align-self-start"
            disabled={props.companyIntervals.some((i) => i.id == 0)}
            onClick={props.onIntervalAdd}
          >
            {t("ui:button.add")}
          </Button>
        )}
      </div>
    </>
  );
};
