import React from "react";
import "./Tooltip.scss";
import { Tooltip } from "antd";
import { AbstractTooltipProps, TooltipProps } from "antd/lib/tooltip";
import clsx from "clsx";

interface ITooltipView extends AbstractTooltipProps {
  isNoWrap?: boolean;
  isBreakSpaces?: boolean;
}

export function TooltipView({ className, isBreakSpaces, isNoWrap, ...rest }: ITooltipView & TooltipProps) {
  return (
    <Tooltip
      overlayClassName={clsx(
        "uiKit-tooltip", className ?? "",
        {
          "uiKit-tooltip_no-wrap": isNoWrap,
          "uiKit-tooltip_break-spaces": isBreakSpaces,
        }
      )}
      // color={"var(--color-white)"}
      {...rest}
    />
  );
}
