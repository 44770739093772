import React from "react";
import "./NotificationCenter.scss";
import { Badge, IconButton, Popover, Tooltip } from "../../uiKit";
import { FiBell } from "@react-icons/all-files/fi/FiBell";
import { NotificationDto } from "../../../api";
import { observer } from "mobx-react-lite";
import { NotificationCenterPopover } from "./notificationCenterPopover/NotificationCenterPopover";
import { INotificationCenterUnreadCount, TNotificationCenterTabKey } from "./NotificationCenter";
import { useTranslation } from "react-i18next";

export type TNotificationData = {
  [key in TNotificationCenterTabKey]: TNotificationDataItem;
};

export type TNotificationDataItem = {
  items: NotificationDto[];
  itemsTotalLength: number | undefined;
  isLoading: boolean;
  isDone: boolean;
};

interface INotificationCenterViewProps {
  currentTab: TNotificationCenterTabKey;
  isOpen: boolean;
  isUnreadOnly: boolean;
  unreadNotificationsCount: INotificationCenterUnreadCount;
  notificationData: TNotificationData;
  notificationsWithInvertedReadStatusIds: number[];
  loadingNotificationsIds: number[];
  isMarkAllAsReadLoading: boolean;
  isNewNotificationTooltipOpen: boolean;

  onCurrentTabChange: (newTab: TNotificationCenterTabKey) => void;
  onUnreadOnlyChange: (isUnreadOnly: boolean) => void;
  onOpenChange: (isOpen: boolean) => void;
  onNotificationClick: (notificationId: number, entityType?: string | null, entityId?: number | null) => void;
  onMarkAllAsReadClick: (tabKey: TNotificationCenterTabKey) => void;
  onMarkAsReadUnreadClick: (notificationId: number, initialIsReadStatus: boolean) => void;
  onLoadMore: (tabKey: TNotificationCenterTabKey) => void;
}

function NotificationCenterViewObserved(props: INotificationCenterViewProps) {
  const { t } = useTranslation();

  return (
    <Popover
      open={props.isOpen}
      overlayClassName="notification-center__wrapper"
      placement="bottomRight"
      trigger={["click"]}
      destroyTooltipOnHide
      overlayInnerStyle={{ padding: "0", overflow: "hidden" }}
      content={
        <NotificationCenterPopover
          currentTab={props.currentTab}
          isUnreadOnly={props.isUnreadOnly}
          unreadMessagesCount={props.unreadNotificationsCount}
          notificationData={props.notificationData}
          notificationsWithInvertedReadStatusIds={props.notificationsWithInvertedReadStatusIds}
          loadingNotificationsIds={props.loadingNotificationsIds}
          isMarkAllAsReadLoading={props.isMarkAllAsReadLoading}
          onCurrentTabChange={props.onCurrentTabChange}
          onUnreadOnlyChange={props.onUnreadOnlyChange}
          onNotificationClick={props.onNotificationClick}
          onMarkAllAsReadClick={props.onMarkAllAsReadClick}
          onMarkAsReadUnreadClick={props.onMarkAsReadUnreadClick}
          onLoadMore={props.onLoadMore}
        />
      }
      onOpenChange={props.onOpenChange}
    >
      {/*offset={[-10, 4]} dot={true} blink*/}
      <Tooltip
        title={t("parse:np_new_notification")}
        placement="bottom"
        // align={{ offset: [0, 4] }}
        open={props.isNewNotificationTooltipOpen}
        overlayClassName="no-interact"
      >
        <Badge
          count={props.unreadNotificationsCount.needActions}
          overflowCount={9}
          style={{ padding: "0 4px", pointerEvents: "none" }}
          size="small"
          offset={props.unreadNotificationsCount.all > 9 ? [-8, 7] : [-9, 7]}
        >
          <IconButton className="flex-shrink-0" onClick={() => {}}>
            <FiBell opacity={0.7} fontSize={20} />
          </IconButton>
        </Badge>
      </Tooltip>
    </Popover>
  );
}

export const NotificationCenterView = observer(NotificationCenterViewObserved);
