import React from "react";
import { Layout } from "antd";
import "./LayoutContent.scss";
import clsx from "clsx";

interface ILayoutContentView {
  className?: string;
  children?: React.ReactNode;
}

const { Content } = Layout;

export function LayoutContentView({ className, ...rest }: ILayoutContentView) {
  return <Content className={clsx("uiKit-layoutContent", className ?? "")} {...rest} />;
}
