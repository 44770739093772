import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dialog, DialogActions, TimeSpanPicker } from "../../../uiKit";
import { ISelectApproverViewDialog } from "./SelectApproverDialog.interface";
import { AutocompleteAsync } from "../../../elements/autocompleteAsync/AutocompleteAsync";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import "./SelectApproverDialog.scss";
import { useRootStore } from "../../../../hooks";

export const SelectApproverDialogView = (props: ISelectApproverViewDialog) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  const currentUserId: number = authStore.getInitialInfo?.identity?.id!;

  // const isAutonomousApproval =

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      bodyStyle={{ paddingBottom: 4 }}
      title={t("ui:title.submit_for_approval")}
      closable={false}
    >
      <Form
        form={form}
        name="validate_other"
        onFinish={props.onSubmitForApproval}
        className="select-approver-form"
      >
        {/*<SubheaderText text={t("ui:subheader.company")} />*/}
        {/*<Form.Item*/}
        {/*  className="full-width"*/}
        {/*  name="orgchartId"*/}
        {/*  initialValue={props.orgchartId}*/}
        {/*  rules={[*/}
        {/*    {*/}
        {/*      required: true,*/}
        {/*      message: t("validation:field_required"),*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*>*/}
        {/*  <OrgchartSwitcher
            value={props.orgchartId ?? undefined}
            onChange={props.onChangeOrgchartId}
            selectProps={{
              style: { width: "100%", marginBottom: 4 },
              placeholder: t("ui:placeholder.click_to_select"),
            }}
            drawType="select"
          />*/}
        {/*</Form.Item>*/}

        <div className="select-approver-form-row">
          <SubheaderText text={t("ui:label.approver")} />
          <Form.Item
            name="select"
            hasFeedback={!props.isPublishWithoutApproval}
            rules={[{ required: !props.isPublishWithoutApproval, message: t("validation:select_approver") }]}
          >
            <AutocompleteAsync
              type="user"
              value={props.approverId}
              requestOptions={{ relationType: "nearest_admin" }}
              withoutUserId={!props.isSelfAppoval ? currentUserId : undefined}
              onChange={(value) => props.onSelectApproverId(value ? +value : null)}
              disabled={props.isPublishWithoutApproval}
              isNewDesign
              isBigSize
            />
          </Form.Item>
        </div>
        {props.withUrgency && (
          <div className="select-approver-form-row">
              <SubheaderText text={t("ui:subheader.urgency")} />
              {/*<TimePicker*/}
              {/*  size="large"*/}
              {/*  className="full-width"*/}
              {/*  value={props.urgency ? getObjectFromTimeSpan(props.urgency).dayjsObject : undefined}*/}
              {/*  onChange={(v) => props.onChangeUrgency(v?.toDate() ?? null)}*/}
              {/*/>*/}
              <TimeSpanPicker
                size="large"
                className="full-width"
                allowClear={false}
                value={props.urgency}
                onChange={(v) => props.onChangeUrgency(v)}
                disabled={props.isPublishWithoutApproval}
              />
          </div>
        )}
        <div className="select-approver-form-row select-approver-form-row_checkbox">
          {props.withCheckbox && (
            <Form.Item name="checkbox" hasFeedback>
              <Checkbox checked={props.resetTestResults} onChange={props.onChangeResetTestResults}>
                {t("ui:checkbox.send_retraining")}
              </Checkbox>
            </Form.Item>
          )}

          {props.isShowPublishWithoutApprovalCheckbox && (
            <Form.Item name="checkbox" hasFeedback>
              <Checkbox checked={props.isPublishWithoutApproval} onChange={props.onChangePublishWithoutApproval}>
                {t("ui:checkbox.publish_without_approval")}
              </Checkbox>
            </Form.Item>
          )}
        </div>
      </Form>
      <DialogActions>
        <Button variant="default" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          {t("ui:button.send")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
