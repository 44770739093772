import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { removeId, removeTrailingSlash, replaceVariableId } from "../../../../helpers/pathname";
import { useRootStore } from "../../../../hooks";
import { cardKeysType, tabKeysType } from "../../../modules/pages/regulation/misc/consts";
import {
  breadcrumbKeys,
  BreadcrumbToken,
  breadcrumbTokensArr,
  initialPathname,
  nestingUrls,
} from "../../../modules/pages/regulation/misc/breadcrumbsKeys";
import { regulationContentType, routeToolsArr } from "../../../modules/pages/regulation/misc/headerTools";

export const useRouterHandler = () => {
  const { pathname } = useLocation();
  const { breadcrumbsStore } = useRootStore();

  const [availableToolsKeys, setAvailableToolsKeys] = useState<string[]>([]);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState<boolean>(true);
  const [toolsHide, setToolsHide] = useState<boolean>(true);

  const matchAvailableToolsByUrl = (): void => {
    const toolsKeys = routeToolsArr
      .find((item) => item.urlKey === removeTrailingSlash(pathname))
      ?.availableTools.map((toolsToken) => toolsToken.key);
    if (toolsKeys) {
      setAvailableToolsKeys(toolsKeys);
      showBreadcrumbs && setShowBreadcrumbs(false);
      toolsHide && setToolsHide(false);
    } else {
      !showBreadcrumbs && setShowBreadcrumbs(true);
      !toolsHide && setToolsHide(true);
    }
  };

  const matchBreadcrumbsSteps = () => {
    let currentBreadcrumbToken: BreadcrumbToken | undefined;
    // console.log("pathname", pathname);
    const trailingSlashPathname = removeTrailingSlash(pathname);
    // console.log("trailingSlashPathname", trailingSlashPathname);
    const pathnameSteps = trailingSlashPathname.split("/").filter((item) => item.length > 0);
    // console.log("pathnameSteps", pathnameSteps);

    if (pathnameSteps.length > 2) {
      nestingUrls.forEach((item) => {
        const nestingUrlWithoutId  = replaceVariableId(item.url, trailingSlashPathname)
        const pathNameWithoutId = trailingSlashPathname;

        if (pathNameWithoutId === nestingUrlWithoutId) {
          currentBreadcrumbToken = breadcrumbTokensArr.find((item2) => item2.id === item.breadcrumbTokenId);
        }
        if (currentBreadcrumbToken) {
          currentBreadcrumbToken.isMainPage = false;
        }
        return currentBreadcrumbToken;
      });
    }
    if (pathnameSteps.length === 2)
      currentBreadcrumbToken = breadcrumbTokensArr.find((item) => item.url === trailingSlashPathname);
    if (!currentBreadcrumbToken && removeTrailingSlash(pathname) !== initialPathname)
      currentBreadcrumbToken = breadcrumbTokensArr.find((item) => item.key === breadcrumbKeys.page404);
    breadcrumbsStore.setBreadcrumbsToken(currentBreadcrumbToken ?? undefined);
  };

  const getTabKeys = (): { cardKey: cardKeysType; tabKey: tabKeysType } | undefined => {
    const currentActiveTab = routeToolsArr.find((item) => item.urlKey === removeTrailingSlash(pathname));
    if (currentActiveTab) return { tabKey: currentActiveTab.tab.tabKey, cardKey: currentActiveTab.cardKey };
    return undefined;
  };

  const getTypeContent = (): regulationContentType => {
    return routeToolsArr.find((item) => removeTrailingSlash(pathname).includes(item.urlKey))?.tab.contentType ?? null;
  }
  const handlerValideRoutes = () => {
    // switch (pathname) {
    //   case "/policy/item" :
    //     navigate("/policy");
    //     break
    // }
  };

  useEffect(() => {
    handlerValideRoutes();
    matchAvailableToolsByUrl();
    matchBreadcrumbsSteps();
  }, [pathname]);

  return {
    availableToolsKeys,
    showBreadcrumbs,
    toolsHide,
    getTabKeys,
    getTypeContent,
    pathname,
  };
};
