import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  BoardStatusDto,
  BoardStatusTransitionActionDto,
  CompanyDto,
  CompanySettingsDto,
  IssueCommentDto,
  IssueDto,
  IssueHistoryDto,
  StaticFileDto,
} from "../../../../api";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore, useSequentialPromises } from "../../../../hooks";
import IssueRequiredActionsDialogView from "./IssueRequiredActionsDialogView";
import { observer } from "mobx-react-lite";
import {
  IssueRequiredActionsDialogTabAddProof,
  IssueRequiredActionsDialogTabAttachComment,
  IssueRequiredActionsDialogTabAttachMessage,
  IssueRequiredActionsDialogTabSetDeadline,
  IssueRequiredActionsDialogTabSetDeadlineStrict,
} from "./issueRequiredActionsDialogTabs";
import { api } from "../../../../services";
import { actionsKeysConst, FilterIssueHistoryKeys, IssueActionConsts } from "../../forms/types/consts";
import { compareArraysOfNumbers } from "../../../../helpers/arrayFunctions";
import { getImagesCount, getLexicalTextContentLength, initialEditorContent } from "../../../../utils/textEditor";
import { BroadcastChannel } from "broadcast-channel";
import dayjs from "dayjs";
import { getNearestDateMultipleOf5 } from "../../../../helpers/dateСomparison";
import {
  IssueRequiredActionsDialogTabChangeDeadline
} from "./issueRequiredActionsDialogTabs/IssueRequiredActionsDialogTabChangeDeadline";
import { getObjectFromTimeSpan } from "../../../../helpers/dateFunctions";

interface IIssueRequiredActionsDialog {
  open: boolean;
  boardData: BoardStatusTransitionActionDto | null;
  issueData: IssueDto;
  onClose: () => void;
  onRefreshData?: () => void;
  onConfirmData?: () => void;
  currentDestinationActionKey?: IssueActionConsts | null;
}

type TIssueRequiredActionsDialogData = {
  message: string;
  comment: string;
  deadline: Date | null;
  dateStart?: Date | null,
  lastCommentIsProof: boolean;
  lastCommentId?: number;
  checkboxDisabled: boolean;
  lastComment?: IssueHistoryDto;
  proof: {
    text: string;
    files: StaticFileDto[];
  };
};

function IssueRequiredActionsDialog(props: IIssueRequiredActionsDialog) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore, issueInitDataStore } = useRootStore();
  const functionsList = useRef(useSequentialPromises());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isNoProofDialogConfirmationOpen, setIsNoProofDialogConfirmationOpen] = useState<boolean>(false);
  const [currentTimeFact, setCurrentTimeFact] = useState<string>("");
  const [currentTimeFactForApproval, setCurrentTimeFactForApproval] = useState<string>("");

  const issueChannel = new BroadcastChannel("issue");

  const addProofEditorRef = useRef<{ isEmptyEditor: () => boolean; resetEditor: (content?: string) => void }>();

  const [currentTabKey, setCurrentTabKey] = useState<string>(props.boardData?.baseTransitionActionKey ?? "");
  const actionKey = useMemo(() => {
    return props.boardData?.actionKey ?? "";
  }, [props.boardData?.actionKey])


  const [isShowErrorValidateMessage, setIsShowErrorValidateMessage] = useState<{
    showCommonError: boolean;
    showTimeFactError: boolean;
    showDeadlineError: boolean;
    showStartDateError: boolean;
  }>({
    showCommonError: false,
    showTimeFactError: false,
    showDeadlineError: false,
    showStartDateError: false,
  });

  const dataInitialState: TIssueRequiredActionsDialogData = {
    message: initialEditorContent,
    comment: initialEditorContent,
    deadline: null,
    lastCommentIsProof: false,
    checkboxDisabled: false,
    proof: {
      text: initialEditorContent,
      files: [],
    },
  };

  const [data, setData] = useState<TIssueRequiredActionsDialogData>(dataInitialState);
  const lastCommentFiles = useRef<StaticFileDto[]>([]);
  const lastCommentFilesConst = useRef<StaticFileDto[]>([]);
  const lastCommentTextConst = useRef<string | null | undefined>();
  const dataCopy = useRef<TIssueRequiredActionsDialogData>(dataInitialState);
  const setDataSync = (d: TIssueRequiredActionsDialogData) => {
    dataCopy.current = d;
    setData(d);
  };
  const handleFetchLastComment = async (): Promise<IssueHistoryDto | null> => {
    const resp = await api.issueHistory.fetchLastComment(props.issueData.id!);

    if (resp == null) {
      setDataSync({
        ...dataCopy.current,
        lastCommentIsProof: false,
        checkboxDisabled: true
      });
      notifier.show({ message: t("notifier:error.last_comment_is_failed"), theme: "error" });
      return null;
    }

    const lastComment =  resp ? {
      ...resp,
      id: resp.issueHistoryId,
      comment: {
        ...resp,
        id: resp.issueHistoryId
      }
    } : undefined;

    setDataSync({ ...dataCopy.current, lastComment });
    return lastComment ?? null;
  };

  // TODO: Проверить загрузку файлов и последующее нажатие галочки "Использовать предыдущий комментарий"
  const onSaveProofFromLastComment = async () => {
    let lastComment = dataCopy.current.lastComment;
    if (lastComment == null) {
      notifier.show({ message: t("notifier:error.last_comment_is_failed"), theme: "error" });
      setDataSync({ ...dataCopy.current, lastCommentIsProof: false, checkboxDisabled: true });
      return;
    }
    const thisLastCommentFiles =
      lastComment.comment?.attachments?.map((x) => x.file as StaticFileDto)?.filter((y) => y != null) ?? [];
    lastCommentTextConst.current = lastComment.comment?.text;
    lastCommentFiles.current = [...thisLastCommentFiles];
    lastCommentFilesConst.current = [...thisLastCommentFiles];
    setDataSync({
      ...dataCopy.current,
      lastCommentIsProof: true,
      checkboxDisabled: true,
      proof: {
        ...dataCopy.current.proof,
        text: lastComment.comment?.text ? lastComment.comment?.text : initialEditorContent,
        files: [...thisLastCommentFiles],
      },
    });
    addProofEditorRef.current?.resetEditor(dataCopy.current.proof.text);
  };

  const handleSaveReasonFromLastComment = useCallback(() => {
    let lastComment = dataCopy.current.lastComment;
    if (lastComment) {
      const thisLastCommentFiles =
        lastComment.comment?.attachments?.map((x) => x.file as StaticFileDto)?.filter((y) => y != null) ?? [];
      setDataSync({
        ...dataCopy.current,
        comment: lastComment.comment?.text ?? initialEditorContent,
        proof: {
          ...dataCopy.current.proof,
          files: [...thisLastCommentFiles],
        },
      });
      addProofEditorRef.current?.resetEditor(dataCopy.current.comment);
    }
  }, []);

  const handleSaveMessageFromLastComment = useCallback(() => {
    let lastComment = dataCopy.current.lastComment;
    if (lastComment) {
      const thisLastCommentFiles =
        lastComment.comment?.attachments?.map((x) => x.file as StaticFileDto)?.filter((y) => y != null) ?? [];
      setDataSync({
        ...dataCopy.current,
        message: lastComment.comment?.text ?? initialEditorContent,
        proof: {
          ...dataCopy.current.proof,
          files: [...thisLastCommentFiles],
        },
      });
      addProofEditorRef.current?.resetEditor(dataCopy.current.message);
    }
  }, []);

  const prevComment = useRef<{
    text: string;
    files: StaticFileDto[];
  }>({
    text: initialEditorContent,
    files: [],
  });

  const handleIsCheckedChange = async (value: boolean, actionKey?: actionsKeysConst) => {
    const key = actionKey ?? props.boardData?.baseTransitionActionKey;
    if (value) {
      setIsChecked(value);
      switch (key) {
        case actionsKeysConst.addProof:
          prevComment.current = data.proof;
          return await onSaveProofFromLastComment();
        case actionsKeysConst.attachComment:
        case actionsKeysConst.setDeadline:
          return handleSaveReasonFromLastComment();
        case actionsKeysConst.attachMessage:
          return handleSaveMessageFromLastComment();
      }
    }
    if (!value) {
      setIsChecked(value);
      const proof = key === actionsKeysConst.addProof
        ? prevComment.current
        : {
            text: initialEditorContent,
            files: [],
          };
      if (key === actionsKeysConst.addProof) {
        prevComment.current = {
          text: initialEditorContent,
          files: [],
        };
      }
      setDataSync({
        ...dataCopy.current,
        comment: proof.text,
        message: proof.text,
        proof: {
          ...dataCopy.current.proof,
          ...proof,
        },
      });
      addProofEditorRef.current?.resetEditor(proof.text);
    }
  };

  const handleCurrentTimeFactChange = useCallback((value: string) => {
    setIsShowErrorValidateMessage((prev) => ({ ...prev, showTimeFactError: false }));
    setCurrentTimeFact(value);
  }, []);

  const handleCurrentTimeFactForApprovalChange = useCallback((value: string) => {
    setIsShowErrorValidateMessage((prev) => ({ ...prev, showTimeFactError: false }));
    setCurrentTimeFactForApproval(value);
  }, []);


  useEffect(() => {
    if (props.issueData.timeFact) {
      handleCurrentTimeFactChange(props.issueData.timeFact)
    }
  }, [props.issueData.timeFact]);

  useEffect(() => {
    if (props.issueData.timeFactForApproval) {
      handleCurrentTimeFactForApprovalChange(props.issueData.timeFactForApproval)
    }
  }, [props.issueData.timeFactForApproval]);

  const requiredActionsList: { key: string; name: string; element: any }[] = [
    {
      key: actionsKeysConst.addProof,
      name: t("common:tab.board_required_actions.tab_text"),
      element: () =>
        IssueRequiredActionsDialogTabAddProof({
          currentTimeFact: currentTimeFact,
          onCurrentTimeFactChange: handleCurrentTimeFactChange,
          isShowErrorValidateMessage: isShowErrorValidateMessage,
          issueData: props.issueData,
          lastCommentIsProof: data.lastCommentIsProof,
          proofText: data.proof.text,
          proofFiles: data.proof.files,
          editorRef: addProofEditorRef,
          checkboxDisabled: data.checkboxDisabled,
          showCheckboxToSaveLastComment: !!dataCopy.current.lastComment,
          onSaveProofFromLastComment: () => (data.lastCommentIsProof ? null : onSaveProofFromLastComment()),
          isChecked: isChecked,
          onIsCheckedChange: handleIsCheckedChange,
          onProofTextChange: (text) => {
            if (dataCopy.current.proof.text !== text) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            setDataSync({
              ...dataCopy.current,
              proof: { ...dataCopy.current.proof, text: text },
            });
          },
          setIsLoading,
          onProofFilesDeleteById: (fileId) => {
            setDataSync({
              ...dataCopy.current,
              proof: {
                ...dataCopy.current.proof,
                files: dataCopy.current.proof.files.filter((item) => item.id !== fileId),
              },
            });
            lastCommentFiles.current = lastCommentFiles.current.filter((f) => f.id != fileId);
          },
          onProofFilesChange: (files) => {
            if (dataCopy.current.proof.files !== files) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            console.log("files", files);
            setDataSync({
              ...dataCopy.current,
              proof: {
                ...dataCopy.current.proof,
                files: [
                  ...dataCopy.current.proof.files,
                  // ...lastCommentFiles.current,
                  // ...(dataCopy.current.proof.files ?? []).filter((f) => !files.some((fi) => fi.id == f.id)),
                  // ...files,
                  // ...files.map(f => (
                  // ))
                  ...files.filter((item) => !dataCopy.current.proof.files.find((item2) => item2.id === item.id)),
                ],
              },
            });
          },
        }),
    },
    {
      key: actionsKeysConst.setDateWorkStart,
      // name: t("common:tab.board_required_actions.set_datedeadline_strict"),${
      name: t("ui:title.board_require_actions"),
      element: () =>
        IssueRequiredActionsDialogTabSetDeadlineStrict({
          value: data.deadline,
          defaultValue: data.deadline ?? props?.issueData?.dateWorkStart
            ? dayjs(data.deadline ?? props?.issueData?.dateWorkStart)?.tz(undefined, false).toDate()
            : undefined,
          expired: props.issueData?.dateDeadline ? new Date() > new Date(props.issueData?.dateDeadline) : undefined,
          onChange: (value: Date) => setDataSync({ ...dataCopy.current, deadline: value as any }),
          isStrict: props.issueData?.fields?.find((f) => f.key == "is_strict_deadline" && f.valueBool == true) != null,
          maxDeadline: props.issueData?.calculated?.dateWorkStartTo
            ? new Date(props.issueData?.calculated?.dateWorkStartTo)
            : undefined,
          dateDeadline: props.issueData?.dateDeadline ? new Date(props.issueData?.dateDeadline) : undefined,
        }),
    },
    {
      key: actionsKeysConst.setDeadline,
      name: t("common:tab.board_required_actions.not_approval"),
      element: () =>
        IssueRequiredActionsDialogTabSetDeadline({
          isShowErrorValidateMessage: isShowErrorValidateMessage,
          deadline: data.deadline,
          comment: data.comment,
          onChange: (value: Date | null) => {
            if (value) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showDeadlineError: false }));
            }
            setDataSync({
              ...dataCopy.current,
              deadline: value ?? null,
            });
          },
          attachments: data.proof.files,
          isChecked: isChecked,
          showCheckboxToSaveLastComment: !!dataCopy.current.lastComment,
          onIsCheckedChange: handleIsCheckedChange,
          editorRef: addProofEditorRef,
          onInput: (comment) => {
            if (dataCopy.current.comment !== comment) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            setDataSync({ ...dataCopy.current, comment: comment });
          },
        }),
    },
    {
      key: actionsKeysConst.changeDeadline,
      name: t("common:tab.board_required_actions.change_deadline"),
      element: () =>
        IssueRequiredActionsDialogTabChangeDeadline({
          isShowErrorValidateMessage: isShowErrorValidateMessage,
          deadline: data.deadline,
          dateStart: data.dateStart || null,
          timePlan: props.issueData?.timePlan ?? "00:00:15",
          comment: data.comment,
          onChange: (value: Date | null, key: string) => {
            const errorKey = key === 'deadline' ? 'showDeadlineError' : 'showStartDateError';
            setIsShowErrorValidateMessage((prev) => ({
              ...prev,
              [errorKey]: !value
            }));
            setDataSync({
              ...dataCopy.current,
              [key]: value ?? null,
            });
          },
          editorRef: addProofEditorRef,
          onInput: (comment) => {
            if (dataCopy.current.comment !== comment) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            setDataSync({ ...dataCopy.current, comment: comment });
          },
        }),
    },
    {
      key: actionsKeysConst.attachComment,
      // name: t("common:tab.board_required_actions.attach_comment"),
      name: t("ui:title.board_require_actions"),
      element: () =>
        IssueRequiredActionsDialogTabAttachComment({
          isShowErrorValidateMessage: isShowErrorValidateMessage,
          comment: data.comment,
          attachments: data.proof.files,
          isChecked: isChecked,
          isApprove: actionKey === "approve",
          showCheckboxToSaveLastComment: !!dataCopy.current.lastComment,
          currentTimeFact: currentTimeFactForApproval,
          onCurrentTimeFactChange: handleCurrentTimeFactForApprovalChange,
          issueData: props.issueData,
          onIsCheckedChange: handleIsCheckedChange,
          editorRef: addProofEditorRef,
          onInput: (comment) => {
            if (dataCopy.current.comment !== comment) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            setDataSync({ ...dataCopy.current, comment: comment });
          },
        }),
    },
    {
      key: actionsKeysConst.attachMessage,
      // name: t("common:tab.board_required_actions.attach_message"),
      name: t("ui:title.board_require_actions"),
      element: () =>
        IssueRequiredActionsDialogTabAttachMessage({
          isShowErrorValidateMessage: isShowErrorValidateMessage.showCommonError,
          message: data.message,
          attachments: data.proof.files,
          isChecked: isChecked,
          showCheckboxToSaveLastComment: !!dataCopy.current.lastComment,
          onIsCheckedChange: handleIsCheckedChange,
          editorRef: addProofEditorRef,
          onInput: (message) => {
            if (dataCopy.current.message !== message) {
              setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: false }));
            }
            setDataSync({ ...dataCopy.current, message: message });
          },
        }),
    },
  ];

  const thisProofIsLastComment = (): boolean => {
    if (!data.lastCommentIsProof) return false;
    else {
      let text: boolean = false;
      let files: boolean = false;
      text = dataCopy.current.proof.text.length === lastCommentTextConst.current?.length;
      files = dataCopy.current.proof.files
        ? compareArraysOfNumbers(
            dataCopy.current.proof.files.map((item) => item.id!),
            lastCommentFilesConst.current.map((item) => item.id!)
          )
        : false;

      return text || files;
    }
  };

  // const handleProofSaveNoProof = async () => {
  //   // const r = await api.issue.edit(props.issueData.id as number, {
  //   //   ...props.issueData,
  //   //   proof: {
  //   //     ...props.issueData.proof,
  //   //     isResultAchieved: dataCopy.current.isResultAchieved,
  //   //   },
  //   // });
  //   //
  //   // r == null && showSomethingError();
  //   // const attachmentsArray = [];
  //
  //   // const z = await api.issueHistory.create({
  //   //   issueId: props.issueData.id,
  //   //   comment: undefined,
  //   // });
  //
  //   // if (z == null) {
  //   //   showSomethingError();
  //   //   return;
  //   // }
  //
  //   // const r = await api.issue.editPartially(
  //   //   props.issueData.id as number,
  //   //   {
  //   //     ...props.issueData,
  //   //     proof: {
  //   //       ...props.issueData.proof,
  //   //       historyId: z?.id,
  //   //     },
  //   //   },
  //   //   props.issueData
  //   // );
  //   //
  //   // r == null && showSomethingError();
  // };

  const handleProofSave = async (historyId?: number) => {
    const lastCommentEqualToThisProof = thisProofIsLastComment();

    const r = await api.issue.editPartially(
      props.issueData.id as number,
      {
        ...props.issueData,
        // timeFact: currentTimeFact,
        proof: {
          ...props.issueData.proof,
          historyId: lastCommentEqualToThisProof ? data.lastComment?.id : historyId,
        },
      },
      props.issueData,
      {},
      true,
    );

    r == null && showSomethingError();
  };

  const handleDateWorkStartSave = async () => {
    const dateStart =
      dataCopy.current.deadline ?? props.issueData.dateWorkStart ?? getNearestDateMultipleOf5(new Date())
    await api.issue.editPartially(
      props.issueData.id as number,
      {
        ...props.issueData,
        dateWorkStart: new Date(dateStart).toISOString(),
      },
      props.issueData
    );
  };

  const handleDateDeadlineSave = async () => {
    await api.issue.editPartially(
      props.issueData.id as number,
      {
        ...props.issueData,
        dateDeadline:
          dataCopy.current.deadline || props.issueData.dateDeadline!
            ? new Date((dataCopy.current.deadline || props.issueData.dateDeadline!) as Date).toISOString()
            : undefined,
      },
      props.issueData
    );
  };

  const handleChangeDateDeadlineSave = async () => {
    console.log("handleChangeDateDeadlineSave");
    await api.issue.editPartially(
      props.issueData.id as number,
      {
        ...props.issueData,
        dateDeadline:
          dataCopy.current.deadline || props.issueData.dateDeadline!
            ? new Date((dataCopy.current.deadline || props.issueData.dateDeadline!) as Date).toISOString()
            : undefined,
        dateWorkStart:
          dataCopy.current.dateStart || props.issueData.dateWorkStart!
            ? new Date((dataCopy.current.dateStart || props.issueData.dateWorkStart!) as Date).toISOString()
            : undefined,
      },
      props.issueData
    );
    props.onConfirmData && props.onConfirmData()
  };

  const showSomethingError = () => {
    notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
  };

  const canProofBeSkipped = () => {
    let value2return = false;
    // if (!props.boardData?.requiredActions?.some((r) => r.baseTransitionActionKey == "add_proof")) {
    if (props.boardData?.baseTransitionActionKey !== "add_proof") {
      return true;
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.images_min_count")) {
      value2return = Boolean(
        dataCopy.current.proof.files.length + (getImagesCount(dataCopy.current.proof.text) ?? 0) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.images_min_count")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.files_min_count")) {
      value2return = Boolean(
        dataCopy.current.proof.files.length + (getImagesCount(dataCopy.current.proof.text) ?? 0) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.files_min_count")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules?.some((r) => r.key == "require.text_min_length")) {
      value2return = Boolean(
        getLexicalTextContentLength(dataCopy.current.proof.text) >=
          (props.issueData.proofRequirement?.rules?.find((r) => r.key == "require.text_min_length")?.value ?? 0)
      );
    }

    if (props.issueData.proofRequirement?.rules == null || props.issueData.proofRequirement?.rules.length == 0) {
      value2return = true;
    }

    return value2return;
  };

  const handleGetCommentDto = (): IssueCommentDto | undefined => {
    switch (props.boardData?.baseTransitionActionKey) {
      case actionsKeysConst.attachComment:
        return {
          text: dataCopy.current.comment,
          attachments: dataCopy.current.proof.files.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        };
      case actionsKeysConst.attachMessage:
        return {
          text: dataCopy.current.message,
          attachments: dataCopy.current.proof.files.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
          recipients: [
            {
              userId:
                authStore.getInitialInfo?.identity?.id == props.issueData.createdByUserId
                  ? (props.issueData.executorUserId as number)
                  : (props.issueData.createdByUserId as number),
            },
          ],
        };
      case actionsKeysConst.addProof:
        const isEmptyComment = dataCopy.current.proof.text === initialEditorContent;
        if (isEmptyComment && !dataCopy.current.proof.files.length) {
          return undefined;
        }
        return {
          text: isEmptyComment ? undefined : dataCopy.current.proof.text,
          attachments: dataCopy.current.proof.files.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        };
      case actionsKeysConst.setDeadline:
        return {
          text: dataCopy.current.comment,
          attachments: dataCopy.current.proof.files.map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        };
      case actionsKeysConst.changeDeadline:
        return {
          text: dataCopy.current.comment,
        };
    }
  };

  const handleSaveClick = async (force?: boolean) => {
    console.log("handleSaveClick");
    if (!canProofBeSkipped() && !force && !currentTimeFact.length) {
      setIsShowErrorValidateMessage((prev) => (
        { ...prev, showCommonError: true }
      ));
    }
    if (!canProofBeSkipped() && !force) {
      // setIsNoProofDialogConfirmationOpen(true);
      setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: true }));
      return;
    }

    const { baseTransitionActionKey } = props.boardData || {};
    const isAddProof = baseTransitionActionKey === actionsKeysConst.addProof;
    if (!currentTimeFact.length && isAddProof) {
      const { timeFactRequired = false } = companySettings;
      if (timeFactRequired) {
        setIsShowErrorValidateMessage((prev) => ({ ...prev, showTimeFactError: true }));
        return;
      }
    }
    // if (
    //   (props.boardData?.baseTransitionActionKey === actionsKeysConst.attachComment &&
    //     getLengthContent(dataCopy.current.comment) === 0) ||
    //   (props.boardData?.baseTransitionActionKey === actionsKeysConst.attachMessage &&
    //     getLengthContent(dataCopy.current.message) === 0)
    // ) {
    //   setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: true }));
    //   return;
    // }
    // if (!canProofBeSkipped() && !force) {
    //   setIsShowErrorValidateMessage((prev) => ({ ...prev, showCommonError: true }));
    // }
    // if (props.boardData?.baseTransitionActionKey === actionsKeysConst.addProof && !currentTimeFact.length) {
    //   setIsShowErrorValidateMessage((prev) => ({ ...prev, showTimeFactError: true }));
    // }
    // if (
    //   (!canProofBeSkipped() && !force) ||
    //   (props.boardData?.baseTransitionActionKey === actionsKeysConst.addProof && !currentTimeFact.length)
    // ) {
    //   return;
    // }

    // const isAttachComment =
    //   (baseTransitionActionKey === actionsKeysConst.attachComment ||
    //     baseTransitionActionKey === actionsKeysConst.setDeadline) &&
    //   getLengthContent(dataCopy.current.comment) === 0;
    const isAttachComment =
      baseTransitionActionKey ===  (actionsKeysConst.setDeadline) &&
      getLexicalTextContentLength(dataCopy.current.comment) === 0;
    const isAttachMessage =
      baseTransitionActionKey === actionsKeysConst.attachMessage &&
      getLexicalTextContentLength(dataCopy.current.message) === 0;
    // const isAddProof = baseTransitionActionKey === actionsKeysConst.addProof && !currentTimeFact.length;
    // const isSetDeadline = baseTransitionActionKey === actionsKeysConst.setDeadline && !data.deadline;
    const isDeadlineRequired = baseTransitionActionKey === actionsKeysConst.changeDeadline;
    const startDate = isDeadlineRequired
      ? dataCopy.current.dateStart ?? dayjs()
      : dayjs();

    const { timePlan } = props.issueData;
    const parsedTimePlan = getObjectFromTimeSpan(timePlan ?? "00:15");
    const endDate =  dayjs(dataCopy.current.dateStart)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .add({
        hours: parsedTimePlan.hours,
        minutes: parsedTimePlan.minutes,
        seconds: parsedTimePlan.seconds,
      })
      .toDate();

    const dateDeadlineWithTimezone = dayjs(dataCopy.current.deadline)
      .tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local")
      .toDate();

    const isSetDeadline =
      (baseTransitionActionKey === actionsKeysConst.setDeadline || baseTransitionActionKey === actionsKeysConst.changeDeadline) &&
      !!dataCopy.current.deadline && (
      dayjs(dataCopy.current.deadline).tz(authStore.getInitialInfo?.identity?.timeZoneId ?? "local").isBefore(startDate) ||
      endDate > dateDeadlineWithTimezone
      )

    const needCommonError = isAttachComment || isAttachMessage || (!canProofBeSkipped() && !force);
    // const needTimeFactError = isAddProof;
    const needDeadlineError = isSetDeadline;

    // if (needCommonError || needTimeFactError) {
    if (needCommonError || (!isDeadlineRequired && needDeadlineError)) {
      return setIsShowErrorValidateMessage((prev) => ({
        ...prev,
        showCommonError: needCommonError,
        showDeadlineError: needDeadlineError,
        // showTimeFactError: needTimeFactError,
      }));
    }

    setIsLoading(true);
    await api.issue.editPartially(
      props.issueData?.id!,
      {
        ...props.issueData,
        timeFact: currentTabKey !== actionsKeysConst.attachComment ? currentTimeFact : props.issueData?.timeFact,
        timeFactForApproval: currentTabKey === actionsKeysConst.attachComment && actionKey === "approve"
          ? currentTimeFactForApproval
          : props.issueData?.timeFactForApproval,
      },
      props.issueData
    );

    await api.notification.setIsRead({ issueId: props.issueData?.id, setRead: true });
    const toGetComment: boolean =
      (currentTabKey == actionsKeysConst.attachComment && !isChecked) ||
      (currentTabKey == actionsKeysConst.setDeadline && !isChecked) ||
      currentTabKey == actionsKeysConst.changeDeadline ||
      (currentTabKey == actionsKeysConst.addProof && !isChecked) ||
      (currentTabKey == actionsKeysConst.attachMessage && !isChecked);


    const actions = isDeadlineRequired ? undefined : [{ key: props.currentDestinationActionKey }];

    const commentMessage = handleGetCommentDto();

    const isAddedComment =
      !(currentTabKey === actionsKeysConst.addProof ||
      currentTabKey === actionsKeysConst.attachComment ||
      currentTabKey === actionsKeysConst.setDeadline ||
      currentTabKey === actionsKeysConst.attachMessage);

    const comment =  isAddedComment || commentMessage
      ? commentMessage
      : undefined

    const r = await api.issueHistory.create({
      issueId: props.issueData.id,
      actions,
      ...(toGetComment && { comment }),
    });
    if (actionsKeysConst.addProof == currentTabKey) await handleProofSave(r?.id);
    if (actionsKeysConst.setDateWorkStart == currentTabKey) await handleDateWorkStartSave();
    if (actionsKeysConst.setDeadline == currentTabKey) await handleDateDeadlineSave();
    if (actionsKeysConst.changeDeadline == currentTabKey) await handleChangeDateDeadlineSave()

    await functionsList.current.run();
    setIsLoading(false);
    functionsList.current.reset();
    // customEvent.dispatch("reloadIssueData", { issueId: props.issueData?.id });
    // customEvent.dispatch("reloadIssueHistory", { issueId: props.issueData?.id });
    // customEvent.dispatch("reloadIssueBoard", { boardId: props.boardData?.boardId });
    issueInitDataStore.getOnStatusChange && issueInitDataStore.getOnStatusChange();
    props.onRefreshData && props.onRefreshData();
    // globalAuthorizedContext?.issue?.reload?.issueHistory && globalAuthorizedContext?.issue?.reload?.issueHistory();
    // globalAuthorizedContext?.issue?.reload?.issueData && globalAuthorizedContext?.issue?.reload?.issueData();
    r == null && showSomethingError();
    r != null && handleDialogClose();
    if (r != null) {
      await issueChannel.postMessage({
        issueId: props.issueData.id!,
        type: "issueStatus",
      });
    }
  };

  const handleDialogClose = () => {
    setCurrentTabKey("");
    setDataSync(dataInitialState);
    localStorage.removeItem("add-proof-editor");
    localStorage.removeItem("add-proof-editor-with-last-comment");
    props.onClose();
  };

  const checkToFetchLastComment = async () => {
    if (!dataCopy.current.lastComment) {
      const lastComment = await handleFetchLastComment();
      console.log("lastComment", lastComment);
      setDataSync({ ...dataCopy.current, lastComment: lastComment ?? undefined });
      if (lastComment?.isItLastComment) {
        handleIsCheckedChange(true);
      }
    }
  };

  const [companySettings, setCompanySettings] = useState<CompanySettingsDto> ({})

  const getCompanySettings = async () => {
    const companySettings = await api.company.getCompanySettings();
    if (companySettings) {
      setCompanySettings(companySettings)
    }
  }

  useEffect(() => {
    if (props.boardData?.baseTransitionActionKey === actionsKeysConst.addProof) {
      getCompanySettings();
    }
    checkToFetchLastComment();
  }, []);

  useEffect(() => {
    console.log("props.boardData?.baseTransitionActionKey", props.boardData?.baseTransitionActionKey);
    if (props.boardData?.baseTransitionActionKey === actionsKeysConst.setDeadline) {
      if (props.issueData.dateDeadline && dayjs(props.issueData.dateDeadline).isBefore(dayjs())) {
        setDataSync({ ...dataCopy.current, deadline: null });
        return;
      }
      props.issueData.dateDeadline &&
        setDataSync({
          ...dataCopy.current,
          deadline: new Date(props.issueData.dateDeadline),
        });
    } else if (props.boardData?.baseTransitionActionKey === actionsKeysConst.changeDeadline) {
      const { dateWorkStart, dateDeadline, timePlan } = props.issueData;
      const deadline = dateDeadline ? new Date(dateDeadline) : null;
      const dateStart = dateWorkStart ? new Date(dateWorkStart) : null;
      props.issueData.dateDeadline &&
      setDataSync({
        ...dataCopy.current,
        deadline,
        dateStart,
      });
    }
  }, [props.boardData?.baseTransitionActionKey, props.issueData.dateDeadline]);

  return (
    <IssueRequiredActionsDialogView
      open={props.open}
      isNoProofDialogConfirmationOpen={isNoProofDialogConfirmationOpen}
      boardData={props.boardData}
      onNextClick={handleSaveClick}
      requiredActionsList={requiredActionsList}
      currentTabKey={currentTabKey}
      onClose={() => handleDialogClose()}
      isLoading={isLoading}
      resetFunctionsList={() => functionsList.current.reset()}
      setIsNoProofDialogConfirmationOpen={setIsNoProofDialogConfirmationOpen}
      handleForceSaveClick={() => handleSaveClick(true)}
    />
  );
}

export default observer(IssueRequiredActionsDialog);
