import React from "react";
import { IBreadcrumbsView } from "./Breadcrumbs.interface";
import { Breadcrumb } from "antd";
import { Link } from "../../uiKit";

export const BreadcrumbsView = (props: IBreadcrumbsView) => {
  return (
    <Breadcrumb className="breadcrumbs__wrapper">
      {props.pathNames.map((item, index) => {
        const stepName = props.onGetStepName(item);
        if (index !== props.pathNames.length - 1) {
          return (
            <Breadcrumb.Item key={stepName}>
              <Link
                key={index}
                className="breadcrumbs-link"
                // underline="hover"
                // href={`/${pathNames.slice(0, index+1).join("/")}`}
                onClick={() => props.onRedirect(`/${props.pathNames.slice(0, index + 1).join("/")}`)}
              >
                {stepName}
              </Link>
            </Breadcrumb.Item>
          );
        } else {
          return (
            <Breadcrumb.Item key={stepName}>
              <Link className="overflow-text_30" key={index} style={{ cursor: "default", color: "inherit" }}>
                {stepName}
              </Link>{" "}
            </Breadcrumb.Item>
          );
        }
      })}
    </Breadcrumb>
  );
};
