import React, { memo, useContext, useEffect, useMemo } from "react";
import {  useRootStore } from "../../../../../../hooks";
import { HeaderBreadcrumbs } from "../../headers/headerBreadcrumbs/HeaderBreadcrumbs";
import { CreateRegulationContent } from "../../pages";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import {  Icon, IconButton } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import "./CreateRegulation.scss";
import { RegulationPageHeaderButtons } from "../../components/ RegulationPageHeaderButtons/RegulationPageHeaderButtons";

import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { observer } from "mobx-react-lite";
import { BreadcrumbsNew } from "../../../../../elements/breadcrumbsNew/BreadcrumbsNew";
import { RegulationContext } from "../../../../../../contexts/RegulationPage/RegulationContext";
import { useTranslation } from "react-i18next";

export const CreateRegulation = observer(() => {
  const { regulationStore } = useRootStore();
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);
  const { breadcrumbsList } = useContext(RegulationContext);
  const { t } = useTranslation();

  const createRegulationBreadcrumbsList = useMemo(() => {
    return [
      ...breadcrumbsList,
      {
        name: t("common:breadcrumbs.create_policy"),
        id: -2,
        path: "",
      }
    ]
  }, [breadcrumbsList]);

  return (
    <>
      <PageHeader>
        <div className="regulation-page-header">
          {!globalAuthorizedContext?.regulation?.isSidebarOpen && (
            <IconButton
              // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
              className="flex-shrink-0"
              onClick={() => globalAuthorizedContext?.regulation?.onSidebarOpenChange?.()}
              children={<Icon component={() => <FiSidebar />} />}
            />
          )}
          <BreadcrumbsNew
            breadcrumbsList={createRegulationBreadcrumbsList}
          />
          <RegulationPageHeaderButtons
          />
        </div>
      </PageHeader>
      <PageContent
        isLoading={regulationStore.getLoading}
      >
        <CreateRegulationContent />
      </PageContent>
    </>
  );
});
