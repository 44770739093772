import React from "react";
import { IRegulationViewOptions } from "./RegulationViewOptions.interface";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../uiKit";
import "./RegulationViewOptions.scss";

export const RegulationViewOptions: React.FC<IRegulationViewOptions> = (props) => {
  const { t } = useTranslation();

  return !props.testIsPassed && !!props.countQuestions && props.hasActiveContent && !props.isEditable ||
  !props.testIsPassed && !props.countQuestions && props.hasActiveContent && !props.isEditable ?
  (
    <div className="mb-8">
      {!props.testIsPassed && !!props.countQuestions && props.hasActiveContent && !props.isEditable && (
        <div className="regulation-test-button">
          {!props.isEditable && (
              <Button variant="filled" onClick={props.onClickPassTest}>
                {t("ui:button.pass_test")}
              </Button>
          )}
        </div>
      )}
      {!props.testIsPassed && !props.countQuestions && props.hasActiveContent && !props.isEditable && (
        <Button
            variant="filled"
            theme="success"
          loading={props.isLoadingStudyBtn}
          onClick={props.onStudyRegulation}
        >
          {t("ui:button.study")}
        </Button>
      )}
    </div>
  ) : null
};
