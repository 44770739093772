import React from "react";
import "./DotsLoader.scss";
import { IDotsLoader } from "./DotsLoader.interface";

export const DotsLoader = (props: IDotsLoader) => {
  const dotsList = [...Array(props.count ?? 3)];

  return (
    <div className="dots-loader-wrap">
      <div className="dots-loader">
        {dotsList.map((_, i) => (
          <div
            key={i}
            className="dots-loader__item"

          />
        ))}
      </div>
      {!!props.text && (
        <div className="dots-loader-text">
          { props.text }
        </div>
      )}
    </div>
  )
}