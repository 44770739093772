import React from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { Button, DatePicker, Dialog, DialogActions } from "../../../uiKit";
import { InputNumber } from "antd";
import dayjs from "dayjs";

interface IRecurringTasksRecalculateDialogView {
  open: boolean;
  taskId: number | string;
  date: Date;

  setDate: (value: Date) => void;
  setTaskId: (value: number | null) => void;
  onClose: () => void;
  onApply: () => void;
}

function RecurringTasksRecalculateDialogView(props: IRecurringTasksRecalculateDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} title="Перерасчёт повторяющейся задачи" onClose={props.onClose} closable={false}>
      <SubheaderText text="ID повторяющейся задачи" />
      <InputNumber min={1} style={{ marginBottom: "16px" }} value={Number(props.taskId)} onChange={props.setTaskId} />
      <SubheaderText text="Дата и время начала перерасчёта" />
      <DatePicker
        style={{ marginBottom: "16px" }}
        allowClear={false}
        showTime={{ format: "HH:mm" }}
        disableFuture
        value={dayjs(props.date)}
        onChange={(data) => data && props.setDate(data?.toDate() ?? null)}
      />
      <div style={{ opacity: 0.75 }}>
        <ul style={{ paddingLeft: "20px" }}>
          <li>Задачи будут перерасчитаны, начиная с указанной даты.</li>
          <li>Уже рассчитанные, но ещё не выпущенные задачи, будут удалены.</li>
          <li>Уже выпущенные задачи удалены не будут.</li>
        </ul>
      </div>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t("ui:button.cancel")}
        </Button>
        <Button
          disabled={props.taskId.toString().trim().length == 0 || props.taskId == 0}
          onClick={props.onApply}
          variant="filled"
        >
          Перерасчитать
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecurringTasksRecalculateDialogView;
