import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressUsersColumn } from "./ProgressUsersColumn";
import { IProgressUsers } from "./IProgressUsersColumn";
import { Link } from "../../../../../uiKit";
import { progressUsersKeys } from "../../misc/consts";
import { useRootStore } from "../../../../../../hooks";
import "./ProgressUsers.scss";

export const ProgressUsers: React.FC<IProgressUsers> = (props) => {
  const { t } = useTranslation();
  const { appStore } = useRootStore();

  const lintTextForLearntUsers =
    appStore.getAppLocale === "ru" || appStore.getAppLocale === "uk"
      ? `${t("parse:more_to_n")} ${props.learntUsersTotalItems - 10}`
      : `${props.learntUsersTotalItems - 10} ${t("parse:more_to_n")}`;
  const lintTextForNoLearntUsers =
    appStore.getAppLocale === "ru" || appStore.getAppLocale === "uk"
      ? `${t("parse:more_to_n")} ${props.noLearntUsersTotalItems - 10}`
      : `${props.noLearntUsersTotalItems - 10} ${t("parse:more_to_n")}`;

  return (
    <div className="regulation-progress-users">
      <div>
        <ProgressUsersColumn users={props.learntUsers} title={t("ui:title.learnt_regulation")} />
        {props.learntUsersTotalItems > 10 && (
          <Link
            className="report-driver-link"
            onClick={() => props.onOpenProgressUserDialog(progressUsersKeys.whoStudied)}
          >
            {lintTextForLearntUsers}
          </Link>
        )}
      </div>
      <div>
        <ProgressUsersColumn users={props.noLearntUsers} title={t("ui:title.no_learnt_regulation")} />
        {props.noLearntUsers.length > 10 && (
          <Link
            className="report-driver-link"
            onClick={() => props.onOpenProgressUserDialog(progressUsersKeys.toStudy)}
          >
            {lintTextForNoLearntUsers}
          </Link>
        )}
      </div>
    </div>
  );
};
