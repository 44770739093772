import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand, LexicalCommand } from "lexical";
import { useEffect } from "react";
import { $createAnchorNode, AnchorNode } from "../../nodes/AnchorNode";

export const INSERT_ANCHOR_COMMAND: LexicalCommand<string> = createCommand("INSERT_ANCHOR_COMMAND");

export default function AnchorPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([AnchorNode])) {
      throw new Error("AnchorPlugin: AnchorNode not registered on editor");
    }
    return editor.registerCommand<string>(
      INSERT_ANCHOR_COMMAND,
      (payload) => {
        const anchorNode = $createAnchorNode(payload, editor.isEditable());
        $insertNodes([anchorNode]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
