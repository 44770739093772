import React from "react";
import { Skeleton, SkeletonProps } from "antd";
import clsx from "clsx";
import "./Skeleton.scss";

interface ISkeletonView extends SkeletonProps {
  //
}

export function SkeletonView({ className, ...rest }: ISkeletonView) {
  return <Skeleton className={clsx("uiKit-skeleton", className ?? "")} {...rest} />;
}
