export const TIME_PLAN_FOR_APPROVAL_QUICK_VALUES = [
  {
    text: "ui:quickValuesTime.5min",
    value: "00:05",
  },
  {
    text: "ui:quickValuesTime.15min",
    value: "00:15",
  },
  {
    text: "ui:quickValuesTime.30min",
    value: "00:30",
  },
  {
    text: "ui:quickValuesTime.1h",
    value: "01:00",
  },
  {
    text: "ui:quickValuesTime.2h",
    value: "02:00",
  },
];

export const TIME_PLAN_QUICK_VALUES = [
  {
    text: "ui:quickValuesTime.5min",
    value: "00:05",
  },
  {
    text: "ui:quickValuesTime.15min",
    value: "00:15",
  },
  {
    text: "ui:quickValuesTime.30min",
    value: "00:30",
  },
  {
    text: "ui:quickValuesTime.1h",
    value: "01:00",
  },
  {
    text: "ui:quickValuesTime.2h",
    value: "02:00",
  },
  {
    text: "ui:quickValuesTime.4h",
    value: "04:00",
  },
]