import React, { useEffect, useState } from "react";
import OrgchartFunctionCreateEditDialogView from "./OrgchartFunctionCreateEditDialogView";
import { PermissionDto, RoleDto } from "../../../../api";
import { api } from "../../../../services";
import { useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";

export interface IOrgchartFunctionCreateDialog {
  functionData?: RoleDto;
  allRoles?: RoleDto[];
  allClaims?: PermissionDto[];
  // isDialogOpen: boolean;
  roleId?: number;
  functionId?: number;
  parentColor?: string;
  functionColorsArray?: string[];
  isLoader?: boolean;
  setFunctionData?: (data: RoleDto) => void;
  onCreate?: () => void;

  onSave(): void;

  onClose(): void;
}

function OrgchartFunctionCreateEditDialog(props: IOrgchartFunctionCreateDialog) {
  const { authStore, helperStore, orgchartStore } = useRootStore();
  const currentCompanyId = authStore.getCurrentCompanyId;

  const [functionData, setFunctionData] = useState<RoleDto>({
    id: 0,
    name: "",
    description: "",
    parentId: props.functionId,
    awaitedResult: "",
    colorHex: props.parentColor ?? undefined,
    companyId: currentCompanyId as number,
    orgchartId: undefined,
    claims: [],
  });

  useEffect(() => {
    if (props.roleId) {
      const r = api.role.getById(props.roleId).then((r) => setFunctionData(r as RoleDto));
    }
  }, []);

  const allClaims = helperStore.getPermissions;

  const [isLoader, setIsLoader] = useState<boolean>(false);

  const onCreateHandler = async () => {
    setIsLoader(true);
    const r = await api.role.create({
      ...functionData,
      name: functionData.name?.trim(),
      awaitedResult: functionData.awaitedResult?.trim(),
      orgchartId: orgchartStore.getCurrentOrgchartId ?? undefined,
    });
    setIsLoader(false);
    props.onSave();
  };

  const onSaveHandler = async () => {
    const r = await api.role.edit(props.roleId as number, functionData);
    props.onSave();
  };

  return (
    <OrgchartFunctionCreateEditDialogView
      functionData={functionData}
      allRoles={(props.allRoles ?? []) as RoleDto[]}
      allClaims={allClaims}
      functionColorsArray={helperStore.getColors}
      setFunctionData={setFunctionData}
      onCreate={onCreateHandler}
      onSave={onSaveHandler}
      onClose={props.onClose}
      roleId={props.roleId}
      isLoader={isLoader}
    />
  );
}

export default observer(OrgchartFunctionCreateEditDialog);
