import React from "react";
import { Checkbox, CheckboxProps } from "antd";
import "./Checkbox.scss";
import clsx from "clsx";

interface ICheckboxView extends CheckboxProps {
  isNewDesign?: boolean;
}

export function CheckboxView({ className, isNewDesign, ...rest }: ICheckboxView) {
  return (
    <Checkbox
      className={clsx("uiKit-checkbox", className ?? "", {"uiKit-checkbox_new-design": isNewDesign})}
      {...rest}
    />
  );
}
