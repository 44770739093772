import React from "react";
import { Dropdown } from "antd";
import { DropdownButtonProps } from "antd/lib/dropdown";
import "./DropdownButton.scss";
import clsx from "clsx";

interface IDropdownButtonView extends DropdownButtonProps {
  //
}

const { Button } = Dropdown;

export function DropdownButtonView({ className, type, ...rest }: IDropdownButtonView) {
  return <Button className={clsx("uiKit-dropdownButton", className ?? "")} type={type ?? "primary"} {...rest} />;
}
