import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { getLexicalTextContentLength, initialEditorContent } from "../../../../../utils/textEditor";
import { TextEditor } from "../../../textEditor/TextEditor";
import { ToolbarType } from "../../../textEditor/types/types";
import React from "react";
import { StaticFileDto } from "../../../../../api";
import FileUploader from "../../../../service/fileUploader/FileUploader";
import { Checkbox } from "../../../../uiKit";
import { actionsKeysConst } from "../../../forms/types/consts";

interface IIssueRequiredActionsDialogTabAttachMessage {
  message: string;
  onInput: (value: string) => void;
  isChecked: boolean;
  onIsCheckedChange: (isChecked: boolean, key: actionsKeysConst) => void;
  editorRef: React.MutableRefObject<{ isEmptyEditor: () => boolean; resetEditor: () => void } | undefined>;
  showCheckboxToSaveLastComment: boolean;
  attachments?: StaticFileDto[];
  isShowErrorValidateMessage: boolean;
}

export function IssueRequiredActionsDialogTabAttachMessage(props: IIssueRequiredActionsDialogTabAttachMessage) {
  const { t } = useTranslation();

  return (
    <div style={{ overflow: "hidden" }}>
      <Form.Item
        className="my-0"
        extra={
          t("validation:min_char_number", { number: 1 }) +
          ". " +
          t("common:misc.entered_characters_number", { number: getLexicalTextContentLength(props.message) })
        }
      >
        {/*<SubheaderText text={t("ui:subheader.message")} />*/}
        <TextEditor
          id="ds-attach-message-editor"
          ref={props.editorRef}
          placeholder={t("ui:title.executor_change.input_placeholder")}
          variant={props.isShowErrorValidateMessage ? "error" : undefined}
          value={props.message}
          disabled={props.isChecked}
          initialValue={props.message === initialEditorContent ? initialEditorContent : props.message}
          onChange={props.onInput}
          toolbarType={ToolbarType.Hidden}
        />
      </Form.Item>
      <FileUploader hideInfo hideButton smallSize readonly value={props.attachments} />
      {props.showCheckboxToSaveLastComment && (
        <div className="mt-2">
          <Checkbox
            checked={props.isChecked}
            onChange={(e) => props.onIsCheckedChange(e.target.checked, actionsKeysConst.attachMessage)}
          >
            {t("ui:label.last_comment_is_reason")}
          </Checkbox>
        </div>
      )}
    </div>
  );
}
