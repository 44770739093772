import React, { useCallback } from "react";
import "./EducationCard.scss";
import { IEducationCardView } from "./EducationCard.interface";
import { Card, CardMeta } from "../../../../../uiKit";
import { Progress } from "antd";
import { EducationCardCover } from "./EducationCardCover/EducationCardCover";
import { useTranslation } from "react-i18next";

export const EducationCardView = (props: IEducationCardView) => {
  const { t, i18n: { language } } = useTranslation();
  console.log("language", language);
  console.log("EducationCardView========s", props.educationItem);

  const content = props.educationItem.content[language];

  const Media = (
    <div style={{ position: "relative" }}>
      {props.cover ?? (
        <EducationCardCover
          title={content.name ?? ""}
        />
      )}

      {props.indicator && (
        <div className={`education-card-indicator education-card-indicator_${props.indicator.status}`}>
          {props.indicator.text}
        </div>
      )}

    </div>
  );

  const handleClick = useCallback(() => {
    // props.educationItem.isAvailable &&
    props.onOpenTrainingDialog({
      trainingKey: props.trainingKey,
      videoId: content.videoId,
      itemId: props.educationItem.id,
    })
  }, []);

  return (
    <Card
      hoverable
      cover={Media}
      onClick={handleClick}
      variant="secondary"
      // className={`education-card ${!props.educationItem.isAvailable && "education-card-disabled"}`}
      className={`education-card`}
    >
      <CardMeta
        title={content.name}
        description={content.description}
      />
      {props.isShowProgress && (
        <Progress
          className="education-card__progress"
          style={{ fontSize: "14px" }}
          percent={props.educationItem.progress}
          status={"normal"}
        />
      )}
    </Card>
  );
};
