import { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../hooks";
import { api } from "../../../services";
import { PlanningPageAdminWorkPlan } from "../../modules/pages/planning/containers/planningPageAdmin/planningPageAdminWorkPlans/planningPageAdminWorkPlan/PlanningPageAdminWorkPlan";
import { PlanningPageAdminWorkPlans } from "../../modules/pages/planning/containers/planningPageAdmin/planningPageAdminWorkPlans/PlanningPageAdminWorkPlans";
import { PlanningPageMyPlan } from "../../modules/pages/planning/containers/planningPageMyPlan/PlanningPageMyPlan";
import { PlanningPageAdminHeader } from "../../modules/pages/planning/headers/planningPageAdminHeader/PlanningPageAdminHeader";
import "./PlanningPage.scss";

const PlanningPage = () => {
  const url = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const plansForApproval = useApi(
    () => api.plan.getAll({ forApproval: true }),
    null,
    () => {}
  );

  const fetchApprovalPlans = useCallback(() => {
    return plansForApproval.fetch();
  }, [])

  useEffect(() => {
    if (url.pathname === "/communication" || url.pathname === "/communication/") {
      navigate("/communication/my-plan");
    }
  }, [navigate, url.pathname]);

  return (
    <>
      {params.id ? null : (
        // <div>
        // <PageHeader
        //
        // >
        //   <PlanningPageAdminHeader plansForApproval={plansForApproval.value?.items.length} />
        // </PageHeader>
        // </div>
        <div className="table-planning-header">
          <PlanningPageAdminHeader plansForApproval={plansForApproval.value?.items.length} />
        </div>
      )}
      {url.pathname == "/communication/my-plan" && <PlanningPageMyPlan fetchApprovalPlans={fetchApprovalPlans} />}
      {url.pathname == "/communication/work-plans" && <PlanningPageAdminWorkPlans />}
      {url.pathname == "/communication/approve-plan" && <PlanningPageAdminWorkPlans />}
      {url.pathname == "/communication/require-approval" && <PlanningPageAdminWorkPlans />}
      {params.id && (
        <PlanningPageAdminWorkPlan
          id={Number(params.id)}
          plansForApproval={plansForApproval.value?.items ?? []}
          fetchApprovalPlans={plansForApproval.fetch}
          isApproval={url.pathname.includes("/communication/approve-plan")}
        />
      )}
    </>
  );
};
export default PlanningPage;
