import React from "react";
import RecurringIssueCreateEditDialogView from "./RecurringIssueCreateEditDialogView";
import { RecurringIssueDto, RoleDto } from "../../../../api";

export interface IRecurringIssueCreateEditDialog {
  open: boolean;
  functionData?: RoleDto;
  data?: RecurringIssueDto;
  createOn?: "admins" | "executors" | "all";

  onCreate?: () => void;
  onSave?: () => void;
  onClose(): void;
}

function RecurringIssueCreateEditDialog(props: IRecurringIssueCreateEditDialog) {
  return <RecurringIssueCreateEditDialogView {...props} />;
}

export default RecurringIssueCreateEditDialog;
