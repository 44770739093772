import React from "react";
import { useRootStore } from "../../../../../../hooks";
import ZoomButton from "../../../../../elements/zoomButton/ZoomButton";
import { EditableTypography } from "../../../../../elements/editableTypography/EditableTypography";
import { IGroupCompanyHeader } from "./GroupCompanyHeader.interface";
import { Button, Icon } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { FaTelegramPlane } from "@react-icons/all-files/fa/FaTelegramPlane";
import { FiCalendar } from "@react-icons/all-files/fi/FiCalendar";

export const GroupCompanyHeader = (props: IGroupCompanyHeader) => {
  const { t } = useTranslation();
  const { groupCompanyStore } = useRootStore();

  return (
    <div style={{ width: "100%" }} className="d-stack-row align-center justify-space-between">
      <div style={{ maxWidth: "600px" }}>
        <EditableTypography value={props.name} onChange={props.onChangeName} onBlur={props.onBlur} />
      </div>
      <div className="d-stack-row align-center spacing-2">
        <Button
          icon={<Icon component={() => <FaTelegramPlane />} />}
          variant="filled"
          onClick={props.onOpenListBotsDialog}
        >
          {t("ui:button.bots")}
        </Button>
        <ZoomButton value={groupCompanyStore.getZoom} onChange={groupCompanyStore.setZoom.bind(groupCompanyStore)} />
      </div>
    </div>
  );
};
