import React from "react";
import "./InputGroup.scss";
import { Input } from "antd";
import { GroupProps } from "antd/es/input";
import clsx from "clsx";

interface IInputGroup extends GroupProps {
  readOnly?: boolean;
}

const { Group } = Input;

export function InputGroup({ className, readOnly, ...rest }: IInputGroup) {
  return (
    <Group
      size="large"
      className={clsx("uiKit-inputGroup", className ?? "", {
        "no-interact": readOnly,
      })}
      {...rest}
    />
  );
}
