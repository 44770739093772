import React, { memo, useCallback, useMemo } from "react";
import { IIssueActivityFile, } from "./IssueActivityFile.interface";
import "./IssueActivityFile.scss";
import { Button } from "../../../../../../../uiKit";
import { openURL } from "../../../../../../../../helpers/urlFunctions";
import { EntityAuditState } from "../../../../../../../../api/models/IssueActivityDto";


export const IssueActivityFile = memo((props: IIssueActivityFile) => {
  const handleClickLink = useCallback(() => {
    if (props.state !== EntityAuditState.Removed) {
      openURL(props.file?.url ?? "")
    }
  }, []);

  return (
    <Button
      className="pa-0"
      variant={"link"}
      style={{
        fontSize: 14,
        textDecoration: props.state === EntityAuditState.Removed ? "line-through" : "none",
        color: "var(--color-primary-base)"
      }}
      disabled={props.state === EntityAuditState.Removed}
      children={props.file?.fileName}
      onClick={handleClickLink}
      size={"small"}
    />
  )
})
