import React from "react";
import { FiLink } from "@react-icons/all-files/fi/FiLink";
import { Divider, Radio, RadioGroup } from "../../../../uiKit";

export function UiTabRadio() {
  return (
    <div className="my-3">
      <div className="d-flex align-center">
        <FiLink className="mr-1" color="var(--color-primary-strong)" />
        <a target="_blank" href="https://ant.design/components/radio/">
          Документация AntD
        </a>
      </div>
      <Divider className="my-3" />
      <div>
        {/*<Text style={{ fontSize: "16px" }}>Кастомные пропсы</Text>*/}
        {/*<div>*/}
        {/*  <div className="d-stack-column spacing-1">*/}
        {/*    <span>*/}
        {/*      <span style={{ fontWeight: "bold" }} children="readOnly" /> - read-only*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<Divider className="my-3" />*/}
        <div className="d-stack-column spacing-2">
          <RadioGroup className="d-stack-column spacing-1" defaultValue={0}>
            <Radio value={0} children="1 var" />
            <Radio children="2 var" />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
