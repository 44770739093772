import React from "react";
import { ThemeSwitchSuggestionDialogView } from "./ThemeSwitchSuggestionDialogView";

interface IThemeSwitchSuggestionDialog {
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
}

export function ThemeSwitchSuggestionDialog(props: IThemeSwitchSuggestionDialog) {
  return <ThemeSwitchSuggestionDialogView {...props} />;
}
