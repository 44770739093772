import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useUserNameFormat = () => {
  const { i18n } = useTranslation();

  const isEngFormat = useMemo(() => {
    const { language } = i18n;
    return language === 'en' || language === 'zh';
  }, [i18n?.language])

  const getFullName = (lastName: string, firstName: string, middleName?: string | null) => {
    if (isEngFormat) {
      const userName = firstName
        ? `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`
        :  lastName;
      return userName.trim().length ? userName : undefined
    }
    const userName= lastName
      ? `${lastName} ${firstName}${middleName ? ` ${middleName}` : ''}`
      : `${firstName}${middleName ? ` ${middleName}` : ''}`;
    return userName.trim().length ? userName : undefined
  }

  const getAvatarText = (lastName: string, firstName: string) => {
    if (isEngFormat) {
      return [firstName, lastName];
    }
    return [lastName, firstName];
  }

  const getUserShortFullName = (
    lastName: string | null | undefined,
    firstName: string | null | undefined,
    middleName?: string | null
  ): string => {
    const lastNameStr = (lastName ?? "").trim();
    const firstNameTrim = (firstName ?? "").trim();
    const firstNameStr = lastNameStr.length ? firstNameTrim[0] : firstNameTrim;
    const middleNameStr = (middleName ?? "").trim();
    return isEngFormat
      ? [
        firstNameStr.length ? firstNameStr + "." : "",
        middleNameStr.length ? ([...(middleNameStr ?? "")]?.[0] ?? "") + "." : "",
        lastName ?? "",
        ].join(" ")
      : [
        lastName ?? "",
        firstNameStr.length ? firstNameStr + "." : "",
        middleNameStr.length ? ([...(middleNameStr ?? "")]?.[0] ?? "") + "." : "",
      ].join(" ");
  };

  return {
    getFullName,
    getAvatarText,
    getUserShortFullName,
  }
}
