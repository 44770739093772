import React, { memo } from "react";
import "./TimeSpanPicker.scss";
import { TTimePickerView } from "../timePicker/TimePickerView";
import clsx from "clsx";
import { TimePicker } from "../timePicker/TimePicker";
import dayjs from "dayjs";

type TTimeSpanPickerViewProps = {
  isTouched: boolean;
  onIsTouchedChange: (val: boolean) => void;
} & TTimePickerView;

export const TimeSpanPickerView = memo((props: TTimeSpanPickerViewProps) => {
  const { className, dropdownClassName, readOnly, isTouched, onIsTouchedChange, ...rest } = props;

  return (
    <TimePicker
      className={clsx("uiKit-timeSpanPicker", className ?? "", {
        "no-interact": readOnly,
      })}
      dropdownClassName={clsx("uiKit-timeSpanPicker__dropdown", dropdownClassName ?? "")}
      showNow={false}
      inputReadOnly
      showSecond={false}
      {...rest}
      format={rest.value != null || isTouched ? "HH:mm" : () => ""}
      onOpenChange={(isOpen) => onIsTouchedChange(isOpen)}
      defaultValue={dayjs()?.utc(false).tz("Africa/Abidjan", false).set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      })}
      // format="HH:mm"
    />
  );
});
