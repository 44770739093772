import React from "react";
import { Typography } from "antd";
import "./Title.scss";
import { TitleProps } from "antd/lib/typography/Title";
import clsx from "clsx";

interface ITitleProps extends TitleProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

const { Title } = Typography;

export function TitleView({ size, weight, className, style, ...rest }: ITitleProps) {
  return (
    <Title
      className={clsx("uiKit-title", className ?? "")}
      style={{ fontWeight: weight, fontSize: size, ...style }}
      {...rest}
    />
  );
}
