import React from "react";
import { Dayjs } from "dayjs";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import "./TimePicker.scss";
import clsx from "clsx";
import { TimePicker as TimePickerAntD } from "antd";
import { DatePickerGeneratedSingle } from "../datePickerGenerated/DatePickerGenerated";

export type TTimePickerView = {
  readOnly?: boolean;
  timeZone?: string;
} & Omit<PickerTimeProps<Dayjs>, "picker">;

export const { RangePicker } = TimePickerAntD;

export function TimePickerView({ className, readOnly, dropdownClassName, ...rest }: TTimePickerView) {
  return (
    <DatePickerGeneratedSingle
      className={clsx("uiKit-timePicker", className ?? "", {
        "no-interact": readOnly,
      })}
      dropdownClassName={clsx("uiKit-timePicker__dropdown", dropdownClassName ?? "")}
      showNow={false}
      inputReadOnly
      // @ts-ignore
      picker={"time"}
      {...rest}
    />
  );
}
