import React from "react";
import { InputPhoneNumberView } from "./InputPhoneNumberView";
import { PhoneInputProps } from "react-phone-input-2";

interface IInputPhoneNumber extends PhoneInputProps {
  readOnly?: boolean;
}

export function InputPhoneNumber(props: IInputPhoneNumber) {
  return <InputPhoneNumberView {...props} />;
}
