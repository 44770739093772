import React from "react";
import { LinkView } from "./LinkView";
import { LinkProps } from "antd/lib/typography/Link";

interface ILink extends LinkProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

export function Link(props: ILink) {
  return <LinkView {...props} />;
}
