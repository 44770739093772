import React, { memo } from "react";
import "./Metrics.scss";
import { IMetrics } from "./Metrics.interface";
import { MetricToDashboard } from "../Metric2Dashboard/MetricToDashboard";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import { Empty, Spin } from "../../../../../uiKit";
import { EmptyMetric } from "../Metric2Dashboard/EmptyMetric/EmptyMetric";

export const Metrics = memo((props: IMetrics) => {
  return (
    <Spin spinning={false} size="large">
      {!props.metrics?.length ? (
        <Empty />
      ) : (
        <>
          <div
            className="metrics full-height"
            style={{ gridTemplateColumns: `repeat(${props.dashboardColumnsAmount ?? 3}, minmax(0, 1fr))` }}
          >
            {/*// .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))*/}
            {/*{props.metrics?.map((m) => {*/}
            {/*  return m.isOneCanvas ? (*/}
            {/*    <MetricToDashboard*/}
            {/*      key={m.id}*/}
            {/*      metric={m}*/}
            {/*      deleteMetric={props.deleteMetric}*/}
            {/*      handleRestartLoad={props.restart}*/}
            {/*    />*/}
            {/*  ) : m.graphs?.length ? (*/}
            {/*    m.graphs.map((g, index) => (*/}
            {/*      <MetricToDashboard*/}
            {/*        key={g.id! * index}*/}
            {/*        metric={m}*/}
            {/*        graphId={g.id}*/}
            {/*        deleteMetric={props.deleteMetric}*/}
            {/*        handleRestartLoad={props.restart}*/}
            {/*      />*/}
            {/*    ))*/}
            {/*  ) : (*/}
            {/*    <EmptyMetric metric={m} deleteMetric={props.deleteMetric} />*/}
            {/*  );*/}
            {/*})}*/}
            {props.metrics?.map((m) => {
              return m.renderType === 1 ? (
                <MetricToDashboard
                  key={m.id}
                  metric={m}
                  deleteMetric={props.deleteMetric}
                  handleRestartLoad={props.restart}
                />
              ) : m.graphs?.length ? (
                m.graphs.map((g, index) => (
                  <MetricToDashboard
                    key={String(g.metricSource2UserId) + "/" + index}
                    metric={m}
                    metricSource2UserId={g.metricSource2UserId}
                    deleteMetric={props.deleteMetric}
                    handleRestartLoad={props.restart}
                  />
                ))
              ) : (
                <EmptyMetric metric={m} deleteMetric={props.deleteMetric} />
              );
            })}
          </div>
          <div className="ml-2">
            <ScrollTrigger
              fullWidth
              marginTop={!!props.metrics?.length}
              disabled={props.isDone}
              hidden={props.isDone}
              onIntersection={props.loadNext}
            />
          </div>
        </>
      )}
    </Spin>
  );
});
