import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { AnswersGenerator } from "../AnswersGenerator/AnswersGenerator";
import { IQuestion } from "../CreateTestsForm.interface";
import { Input } from "../../../../uiKit";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { CloseIcon } from "../../../../../constants/icon";
import "./Question.scss";


export const Question: React.FC<IQuestion> = (props) => {
  const { t } = useTranslation();

  const handleChangeQuestion = (event: React.FormEvent<HTMLInputElement>) => {
    props.onChangeQuestionName(event.currentTarget.value, props.questionId!)
  }

  const handleClickDelete = () => {
    props.onDeleteQuestion(props.questionId!)
  };

  return (
    <div className="question-wrap">
        <div className="question">
          <SubheaderText
          className={"question__label"}
            text={t("ui:subheader.question")}
          />
          <div className="question-input-row">
            <Input
              placeholder={t("ui:placeholder.come_up_question")}
              value={props.name ?? ""}
              onInput={handleChangeQuestion}
            />
            <div
              className="question-answer-delete-button"
              onClick={handleClickDelete}
            >
              <CloseIcon size={14}/>
            </div>
          </div>
        </div>
      <AnswersGenerator
        questionId={props.questionId}
        // onAttachAnswers={props.onAttachAnswers}
        disabled={!props.name.trim().length}
        answers={props.answers ?? []}
        onAppendEmptyAnswer={props.onAppendEmptyAnswer}
        onChangeAnswerRight={props.onChangeAnswerRight}
        onChangeAnswerName={props.onChangeAnswerName}
        onDeleteAnswer={props.onDeleteAnswer}
      />
    </div>
  );
};
