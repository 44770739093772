import React from "react";
import "./AvatarUserSettings.scss";
import { useTranslation } from "react-i18next";
import AvatarUploadForm from "../avatarUploadForm/AvatarUploadForm";
import { Avatar, Button } from "../../../../uiKit";

export interface IAvatarUserSettingsView {
  size?: number;
  userId?: number;
  currentUser?: boolean;
  imageUrl?: string;
  text?: string | null | (string | undefined | null)[];
  color?: string;
  isUploadDialogOpen: boolean;

  handleUploadDialogSuccess(id: number): void;
  handleDeleteClick(): void;
  handleUploadDialogOpen(): void;
  handleUploadDialogClose(): void;
}

function AvatarUserSettingsView(props: IAvatarUserSettingsView) {
  const { t } = useTranslation();

  return (
    <>
      <AvatarUploadForm
        onClose={props.handleUploadDialogClose}
        onUpload={props.handleUploadDialogSuccess}
        open={props.isUploadDialogOpen}
        imageUrl={props.imageUrl}
        text={props.text}
        color={props.color}
      />
      <div className="d-flex align-center justify-center" style={{ width: "100%" }}>
        <Avatar color={props.color} src={props.imageUrl} size={100} text={props.text} />
        <div className="d-flex flex-column ml-4">
          <Button variant="text" onClick={() => props.handleUploadDialogOpen()}>
            {t("ui:button.edit")}
          </Button>
          <Button
            onClick={() => props.handleDeleteClick()}
            disabled={props.imageUrl == null || props.imageUrl.length == 0}
            variant="text"
            theme="error"
          >
            {t("ui:button.delete")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AvatarUserSettingsView;
