import React from "react";
import "./UserButton.scss";
import { IUsertButton } from "./UsertButton.interface";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../hooks";
import { Button, DropdownButton } from "../../../../uiKit";
import { IssueQueryKeys } from "../../../../../constants/issueTypeKeys";
import { useNavigate } from "react-router-dom";
import { openURL } from "../../../../../helpers/pathname";
import { closeByKeys } from "../../../../uiKit/navigation/dropdownButton/DropdownButton";
import { useGetCommunicationsAccess } from "../../../../../hooks/useGetCommunicationsAccess";

export const UsertButton = (props: IUsertButton) => {
  const { t } = useTranslation();
  const { issueInitDataStore } = useRootStore();
  const navigate = useNavigate();

  const { getCommunicationsAccess } = useGetCommunicationsAccess();

  const isHaveAccessCommunications = getCommunicationsAccess();

  const handleOpenOnetimeTask = () => {
    issueInitDataStore.setIssueData({ executorUserId: props.user?.id });
    navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });
  };
  const handleOpenRoutineDialog = () => {
    issueInitDataStore.setIssueData({ executorUserId: props.user?.id });
    navigate({ search: `?${IssueQueryKeys.routineCreate}=${true}` });
  };

  const handleRedirectToProfile = () => {
    if (props.user?.id == null) return;
    openURL(`/employee/${props.user?.id}`);
  };

  const sendPopupItems = [
    {
      id: 1,
      text: t("ui:button.create_task"),
      action: handleOpenOnetimeTask,
    },
    // {
    //   id: 2,
    //   text: t("ui:button.create_decision"),
    //   action: handleOpenDecisionDialog,
    // },
    // {
    //   id: 3,
    //   text: t("ui:button.create_message"),
    //   action: handleOpenAppealDialog,
    // },
    // {
    //   id: 4,
    //   text: t("ui:button.create_routine"),
    //   action: handleOpenRoutineDialog,
    //   permissionKey: permissionKeys.recurringIssue.add,
    // },
  ];

  return isHaveAccessCommunications ? (
    <DropdownButton
      items={sendPopupItems}
      size="middle"
      style={{ width: "400px" }}
      trigger={["click"]}
      closeBy={closeByKeys.hover}
      text={t("ui:button.to_profile")}
      onClick={handleRedirectToProfile}
    />
  ) : (
    <Button
      size="middle"
      onClick={handleRedirectToProfile}
    >
      { t("ui:button.to_profile") }
    </Button>
  );
};
