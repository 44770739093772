import React, { useContext, useEffect, useMemo, useState } from "react";
import { ICreateCompanyGroupDialogView } from "./OrgchartSaveTemplateDialog.interface";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { Button, Dialog, DialogActions, Select } from "../../../uiKit";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { LocaleSwitcher } from "../../../elements/LocaleSwitcher/LocaleSwitcher";
import { OrgchartContext } from "../../../../contexts/orgchartContext";
import { OrgchartTemplateDto } from "../../../../api";
import { LocalesKeys } from "../../../../constants/localesKeys";
import { useNotifier, useRootStore } from "../../../../hooks";
import { api } from "../../../../services";
import { NotificationType } from "../../../../hooks/useNotifier";

export const OrgchartSaveTemplateDialogView: React.FC<ICreateCompanyGroupDialogView> = (props) => {
  const { appStore, orgchartStore } = useRootStore();

  const { t } = useTranslation();
  const notifier = useNotifier();

  const [form] = Form.useForm();
  const handleSubmit = () => form.submit();

  const { orgchartTemplates } = useContext(OrgchartContext) ?? {};

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>('');
  const handleChangeSelectedTemplate = (value: string) => {
    setSelectedTemplate(value);
  }

  const [language, setLanguage] = useState<LocalesKeys>(appStore.getAppLocale as LocalesKeys);

  const handleChangeLanguage= (value: LocalesKeys) => {
    setLanguage(value);
  };

  const templatesList = useMemo(() => {
    const items: OrgchartTemplateDto[] = [];
    Object.keys(orgchartTemplates).forEach((key) => {
      const list = orgchartTemplates[key].map((item: OrgchartTemplateDto) => ({
        ...item,
        title: `${item.title} (${key === 'c' ? 'У' : 'К'})`
      }))
      items.push(...list);
    });
    return items.map((item) => ({
      value: item.key ?? '',
      text: item.title ?? ''
    }));
  }, [orgchartTemplates]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveTemplate = async () => {
    const sourceOrgchartId = orgchartStore.getCurrentOrgchartId;
    setIsLoading(true);
    const data = {sourceOrgchartId, language };
    const status = await api.orgchart.saveTemplate(selectedTemplate, data);
    const requestStatus: NotificationType = status ? 'success' : 'error';
    const text = status ? "success.orgchart_template_saved" : "error.try_again_later";
    notifier.show({
      message: t(`notifier:${text}`),
      theme: requestStatus
    })
    setIsLoading(false);

    if (status) {
      props.onClose();
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={t("ui:title.save_template")}
      closable={false}
    >
      <Form
        form={form}
        name="OrgchartSaveTemplate"
        onFinish={handleSaveTemplate}>
        <SubheaderText text={t("ui:subheader.templates")} />
        <Form.Item
          className="form-item__mb3"
          // label={t("ui:label.name")}
          initialValue={selectedTemplate}
          name="selectedTemplate"
          rules={[{ required: true, message: t("validation:field_required") }]}
        >
          <Select
            className="flex-grow-1"
            onChange={handleChangeSelectedTemplate}
            value={selectedTemplate}
            placeholder={t("ui:placeholder.click_to_select")}
            items={templatesList}
          />
        </Form.Item>
        <div className="d-stack-row justify-space-between align-center">
          <div>
            <SubheaderText text={t("ui:subheader.locale")} />
            <LocaleSwitcher
              size={'large'}
              value={language}
              currentLocale={language}
              onChangeLocale={handleChangeLanguage}
              isFullWidth
            />
          </div>
        </div>
      </Form>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="filled"
          loading={isLoading}
        >
          {t("ui:button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
