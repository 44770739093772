import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useIsMobile, useRootStore } from "../../../hooks";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { Card, Shape, Text } from "../../uiKit";
import PageContent from "../../modules/layouts/pageContent/PageContent";

function InfoPageObserved() {
  const { t } = useTranslation();
  const { devStore, authStore, appStore } = useRootStore();
  const isMobile = useIsMobile();

  return (
    <>
      <PageHeader title={"Info"} />
      <PageContent>
        <div className="d-stack-column spacing-2">
          <Card title="App">
            <div className="d-stack-column spacing-2">
              <Text>
                <b children="Language:" /> {appStore.getAppLocale}
              </Text>
              <Text>
                <b children="Theme:" /> {appStore.getAppTheme == 1 ? "dark" : "light"}
              </Text>
              <Text>
                <b children="Mobile version:" /> {JSON.stringify(isMobile)}
              </Text>
            </div>
          </Card>
          <Card title="Current user">
            <div className="d-stack-column spacing-2">
              <Text>
                <b children="ID:" /> {authStore.getInitialInfo?.identity?.id}
              </Text>
              <Text>
                <b children="Full Name:" /> {authStore.getInitialInfo?.identity?.name}
              </Text>
              <Text>
                <b children="Short Name:" /> {authStore.getInitialInfo?.identity?.nameShort}
              </Text>
              <Text>
                <b children="Name Fallback:" /> {authStore.getInitialInfo?.identity?.nameFallback}
              </Text>
              <Text>
                <b children="Current Culture:" /> {authStore.getInitialInfo?.identity?.currentCultureKey}
              </Text>
              <Text>
                <b children="TimeZone from UTC:" /> {authStore.getInitialInfo?.identity?.timeZoneFromUtc}
              </Text>
              <Text>
                <b children="Is E-mail confirmed:" />{" "}
                {JSON.stringify(authStore.getInitialInfo?.identity?.isEmailConfirmed)}
              </Text>
              <Text>
                <b children="Color:" /> {authStore.getInitialInfo?.identity?.color}
                <Shape
                  className="ml-1"
                  style={{ verticalAlign: "sub" }}
                  type="circle"
                  size={16}
                  backgroundColor={authStore.getInitialInfo?.identity?.color ?? undefined}
                />
              </Text>
            </div>
          </Card>
          <Card title="Current company">
            <div className="d-stack-column spacing-2">
              <Text>
                <b children="ID:" />{" "}
                {
                  authStore.getInitialInfo?.identity?.companies?.find(
                    (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                  )?.companyId
                }
              </Text>
              <Text>
                <b children="Name:" />{" "}
                {
                  authStore.getInitialInfo?.identity?.companies?.find(
                    (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                  )?.company?.name
                }
              </Text>
              <Text>
                <b children="Name Fallback:" />{" "}
                {
                  authStore.getInitialInfo?.identity?.companies?.find(
                    (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                  )?.company?.nameFallback
                }
              </Text>
              <Text>
                <b children="TimeZone from UTC:" />{" "}
                {
                  authStore.getInitialInfo?.identity?.companies?.find(
                    (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                  )?.company?.timeZoneFromUtc
                }
              </Text>
              <Text>
                <b children="Color:" />{" "}
                {
                  authStore.getInitialInfo?.identity?.companies?.find(
                    (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                  )?.company?.color
                }
                <Shape
                  className="ml-1"
                  style={{ verticalAlign: "sub" }}
                  type="circle"
                  size={16}
                  backgroundColor={
                    authStore.getInitialInfo?.identity?.companies?.find(
                      (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                    )?.company?.color ?? undefined
                  }
                />
              </Text>
              <Text>
                <b children="UI Type: " />
                {authStore.getInitialInfo?.identity?.companies?.find(
                  (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                )?.company?.uiType == 2
                  ? "new"
                  : "old"}
              </Text>
              <Text>
                <b children="User Limit: " />
                {authStore.getInitialInfo?.identity?.companies?.find(
                  (c) => c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId
                )?.company?.userLimit ?? "∞"}
              </Text>
              {/*<Text>*/}
              {/*  <b children="UI type: " />*/}
              {/*</Text>*/}
            </div>
          </Card>
        </div>
      </PageContent>
    </>
  );
}

export const InfoPage = observer(InfoPageObserved);
