import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IRoleCard } from "./RoleCard.interface";
import { api } from "../../../services";
import { useNotifier, useRootStore } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { OrgchartDto } from "../../../api";
import { OrgchartCard } from "../orgchartCard/OrgchartCard";
import "./RoleCard.scss";
import { CloseIcon } from "../../../constants/icon";
import { Position } from "@antv/g2plot";

// @ts-ignore
export const RoleCard = memo((props: IRoleCard) => {
  const { authStore, orgchartStore } = useRootStore();
  const { t } = useTranslation();

  const notifier = useNotifier();
  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const [orgchart, setOrgchart] = useState<OrgchartDto | null>(null);

  const getOrgchartAvatar = useMemo((): JSX.Element | null => {
    if (!orgchart) return null;
    return (
      <OrgchartCard
        orgchart={orgchart}
        isAvatarOnly
        showTooltip
        avatarSize={24}
      />
    );
  }, [orgchart]);

  const getOrgchartById = useCallback(async () => {
    const currentOrgchart =
      orgchartStore.getOrgchartsList.find((orgchart) => orgchart.id == props.orgchartId);
    if (currentOrgchart) {
      setOrgchart(currentOrgchart);
    } else {
      const currentOrgchart = await api.orgchart.getById(props.orgchartId);
      if (currentOrgchart) {
        setOrgchart(currentOrgchart);
      } else {
        handlerApiError();
      }
    }
  }, [props.orgchartId, orgchartStore.getOrgchartsList]);

  useEffect(() => {
    getOrgchartById();
  }, []);

  const handleRemoveRole =(id: number, positionId?: number) => {
    props.onRemoveRole(id, positionId);
  };

  // console.log("item", props.item);
  const isSupportOldUi = useMemo(() => {
    return authStore.getCurrentCompanyUiType == 1;
  }, [authStore.getCurrentCompanyUiType]);

  const roleCardList = useMemo(() => {
    if (isSupportOldUi) {
      if (props.item.selectedPositions?.length) {
        return props.item.selectedPositions?.map((positionId) => {
          const { positions = [] } = props.item;
          const positionName =
            positions.find((position) => position.id === positionId)?.name ?? positionId;
          return {
            roleName: `${props.item.roleName}-${positionName}`,
            roleId: props.item.roleId,
            positionId,
          }
        });
      }
      return [props.item];
    }
    return [props.item];
  }, [props.item]);

  return roleCardList.map((item) => (
    <div
      // @ts-ignore
      key={item.positionId ?? item.roleId}
      className="role-card"
    >
      <div className="role-card__icon">
        {getOrgchartAvatar}
      </div>
      <div className="role-card__title">
        {item.roleName}
      </div>
      <div
        className="role-card__close"
        // @ts-ignore
        onClick={() => handleRemoveRole(item.roleId, item.positionId)}
      >
        <CloseIcon size={24} />
      </div>
    </div>
  ))
});