import { CompanyModuleTypeDto, type MenuItemDto } from "../api";

const generationModuleName = (key: number) => {
  switch (key) {
    case 1:
      return "orgchart";
    case 2:
      return "tasks";
    case 3:
      return "reports";
    case 4:
      return "schedule";
    case 5:
      return "employees";
    case 6:
      return "education";
    case 7:
      return "knowledge_base";
    case 8:
      return "dashboards";
    case 10:
      return "planning";
    case 11:
      return "calendar_planning";
  }
}

export const generationModules = (modules: CompanyModuleTypeDto[]) => {
  return modules.map((module: CompanyModuleTypeDto) => ({
    ...module,
    name: `modules.${generationModuleName(module?.key ?? 0)}`
  }))
}