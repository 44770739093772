import React from "react";
import { Radio, RadioProps } from "antd";
import "./Radio.scss";
import clsx from "clsx";

interface IRadioView extends RadioProps {
  isNewDesign?: boolean
}

export function RadioView({ className, isNewDesign, ...rest }: IRadioView) {
  return (
    <Radio
      className={clsx("uiKit-radio", className ?? "", {"uiKit-radio_new-design": isNewDesign})}
      {...rest}
    />
  );
}
