import { IssueShortDto } from "../../../../api";
import { sortKeys } from "../../../pages/communication/misc/consts";

export interface IIssuesArchive {
  isOpen: boolean;
  onClose: () => void;
}

export interface IIssuesArchiveView {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  onChangeName: (name: string) => void;
  orgchartId?: number;
  executorId?: number;
  senderId?: number;
  onChangeOrgchartId: (id: number) => void;
  onChangeExecutorId: (id: number) => void;
  onChangeSenderId: (id: number) => void;
  isStatusNotDone: boolean;
  onChangeStatusNotDone: (value: boolean) => void;
  issues?: IssueShortDto[];
  isDone?: boolean;
  loadNext: () => void;
  sort: ArchiveSortFiltersKeys;
  onChangeSort: (value: ArchiveSortFiltersKeys) => void;
}

export enum ArchiveSortFiltersKeys {
  asc = "asc",
  desc = "desc",
}