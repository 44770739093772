import React from "react";
import { useTranslation } from "react-i18next";
import "./InfoLinksBlock.scss";
import { Link } from "../../../uiKit";

function InfoLinksBlock() {
  const { t, i18n } = useTranslation();

  const linksArray = [
    {
      id: 0,
      name: t("parse:privacy_policy"),
      url: `${window.location?.origin}/info/privacy-policy.html`,
    },
    {
      id: 1,
      name: t("parse:cookie_policy"),
      url: `${window.location?.origin}/info/cookie-policy.html`,
    },
  ];

  return (
    <div className="d-stack-column spacing-1 align-center">
      {linksArray.map((l) => (
        <Link
          key={l.id}
          type="secondary"
          target="_blank"
          rel="noreferrer"
          size="12px"
          href={l.url}
          children={l.name}
          style={{ lineHeight: "1em", textAlign: "center" }}
        />
      ))}
    </div>
  );
}

export default InfoLinksBlock;
