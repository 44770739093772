import React, { useEffect, useRef, useState } from "react";
import { TimePickerView } from "./TimePickerView";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { Button } from "../../../general/button/Button";
import { useHash } from "../../../../../hooks";

export type TTimePicker = {
  readOnly?: boolean;
  timeZone?: string;
} & Omit<PickerTimeProps<Dayjs>, "picker">;

export function TimePicker({
  value,
  open,
  format = "HH:mm",
  className,
  dropdownClassName,
  onChange,
  onBlur,
  onSelect,
  onOpenChange,
  ...rest
}: TTimePicker) {
  const { t } = useTranslation();
  const { hashFn } = useHash();
  const timePickerId = useRef<string>(hashFn(require("lodash.uniqueid")() + "-" + new Date().getTime()));

  const currentValue = useRef<Dayjs | undefined | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getFormat = (val: Dayjs | null, f: typeof format) => {
    if (val == null) return "";
    return val.format(typeof f === "string" ? f : "HH:mm");
  };

  const handleOpenChange = (val: boolean) => {
    if (!isOpen && val) {
      currentValue.current = value;
      setIsOpen(val);
      onOpenChange && onOpenChange(true);
    }
  };

  const handleSelect = (val: Dayjs | null) => {
    currentValue.current = val?.utc(false);
    onSelect && val != null && onSelect(val?.utc());
  };

  const handleChange = (val: Dayjs | null) => {
    currentValue.current = val;
    if (val == null) {
      onChange && onChange(null, "");
      setIsOpen(false);
      onOpenChange && onOpenChange(false);
      currentValue.current = null;
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    onOpenChange && onOpenChange(false);
    currentValue.current = null;
  };

  const handleBlur = () => {
    // if (!isOpen) return;
    // onChange &&
    // onChange(currentValue.current?.utc(false) ?? null, getFormat(currentValue.current?.utc(false) ?? null, format));
    // setIsOpen(false);
    // onOpenChange && onOpenChange(false);
    // currentValue.current = null;
  };

  const handleOutsideClick = (evt: any) => {
    if (!isOpen) return;
    const targetEl =
      evt.target.closest(`.tp-${timePickerId.current}`) ?? evt.target.closest(`.tpd-${timePickerId.current}`);
    if (targetEl == null) {
      onChange &&
        onChange(currentValue.current?.utc(false) ?? null, getFormat(currentValue.current?.utc(false) ?? null, format));
      setIsOpen(false);
      onOpenChange && onOpenChange(false);
      currentValue.current = null;
      // handleCancel();
    } else {
      //
    }
  };

  const handleKeyboardEvent = (evt: any) => {
    if (evt.key == "Enter") {
      onChange &&
        onChange(currentValue.current?.utc(false) ?? null, getFormat(currentValue.current?.utc(false) ?? null, format));
      setIsOpen(false);
      onOpenChange && onOpenChange(false);
      currentValue.current = null;
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    // document.getElementById("appLayout")?.addEventListener("click", handleOutsideClick);
    // document
    //   .getElementsByClassName(`tp-${timePickerId.current}`)?.[0]
    //   ?.addEventListener("keydown", handleKeyboardEvent);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
      // document.getElementById("appLayout")?.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <TimePickerView
      className={`${className ?? ""} tp-${timePickerId.current}`}
      dropdownClassName={`${dropdownClassName ?? ""} tpd-${timePickerId.current}`}
      format={format}
      open={isOpen}
      // defaultPickerValue={value?.tz(undefined, false) ?? undefined}
      // TODO: true -> false
      value={value?.tz(rest.timeZone, true)}
      onOpenChange={handleOpenChange}
      onChange={handleChange}
      onBlur={handleBlur}
      onSelect={handleSelect}
      renderExtraFooter={() => (
        <div className="px-2">
          <Button size="small" className="full-width" onClick={handleCancel} children={t("ui:button.cancel")} />
        </div>
      )}
      {...rest}
    />
  );
}
