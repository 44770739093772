import React, { useEffect, useRef, useState } from "react";
import TimePickerInlinePrimaryView from "./TimePickerInlinePrimaryView";

interface ITimePickerInlinePrimary {
  value?: string;
  onChange?: (value: string) => void;
  defaultTime?: { hours: string; minutes: string };
}

function TimePickerInlinePrimary(props: ITimePickerInlinePrimary) {
  const initialState = {
    hours: props.value?.split(":")?.[0] ?? "12",
    minutes: props.value?.split(":")?.[1] ?? "00",
  };
  const [time, setTime] = useState(initialState);
  const timeCopy = useRef(initialState);

  const setTimeSync = (value: { hours: string; minutes: string }) => {
    setTime(value);
    timeCopy.current = value;
    props.onChange && props.onChange(value.hours + ":" + value.minutes);
  };

  const handleHoursChange = (value: string) => {
    setTimeSync({ ...timeCopy.current, hours: value });
  };

  const handleMinutesChange = (value: string) => {
    setTimeSync({ ...timeCopy.current, minutes: value });
  };

  useEffect(() => {
    if (props.defaultTime) {
      setTime(props.defaultTime);
      timeCopy.current = props.defaultTime;
    }
    if (props.defaultTime === null) {
      const defaultTime = { hours: "00", minutes: "00" };
      setTime(defaultTime);
      timeCopy.current = defaultTime;
    }
  }, [props.defaultTime]);

  const generateArray = (
    from: number,
    to: number,
    numberLength?: number | null,
    multipleOf?: number
  ): (number | string)[] => {
    let numbersArray: (string | number)[] = Array.from({ length: to - from + 1 }, (_, i) => i + from);
    if (multipleOf != null) {
      numbersArray = numbersArray.filter((val) => Number(val) % multipleOf == 0);
    }
    if (numberLength != null) {
      numbersArray = numbersArray.map((val) => ("0000" + (Number(val) + from)).slice(-numberLength));
    }
    return numbersArray;
  };

  return (
    <TimePickerInlinePrimaryView
      time={time}
      onHoursChange={handleHoursChange}
      onMinutesChange={handleMinutesChange}
      generateArray={generateArray}
    />
  );
}

export default TimePickerInlinePrimary;
