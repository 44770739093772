import React from "react";
import { CreateRegulationSectionForm } from "../../../forms/createRegulationSectionForm/CreateRegulationSectionForm";
import { ICreateSection } from "../../../../../types/CreateSection.interface";

export const CreateSectionContent = (props: ICreateSection) => {
  return (
    <CreateRegulationSectionForm
      section={props.section}
      addedRegulations={props.addedRegulations}
      removeRegulation={props.removeRegulation}
    />
  );
};
