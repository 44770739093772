import React, { forwardRef, memo, ReactNode, useCallback, useEffect, useMemo } from "react";
import { TextEditorContainer } from "./TextEditorContainer";
import { ErrorBoundary } from "react-error-boundary";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import TextEditorTheme from "./themes/TextEditorTheme";
import TextEditorNodes from "./nodes/TextEditorNodes";
import { useLocalStorage } from "../../../hooks";
import { initialEditorContent } from "../../../utils/textEditor";
import { ErrorFallback } from "./components/ErrorFallback";
import { ToolbarType } from "./types/types";
import { IssueCommentRecipientDto, UserShortDto } from "../../../api";

export interface ITextEditor {
  id?: string;
  initialValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  height?: number | string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  toolbarType?: ToolbarType;
  isSimplifiedToolbar?: boolean;
  isHidingByClick?: boolean;
  anchorKey?: string;
  withoutBackground?: boolean;
  // TODO: Change to "ghost" | "bordered" | { readOnly: ..., editable: ... }
  variant?: "ghost" | "bordered" | "error" | "new-style";
  onSave?: (text?: string) => void;
  isNewStyle?: boolean;
  onFocus?: () => void;
  isShowEditorTitle?: boolean;
  titlePlaceholder?: string;
  titleValue?: string;
  titleError?: boolean;
  onChangeTitle?: (value: string) => void;
  toolbar?: ReactNode;
  users?: UserShortDto[] | IssueCommentRecipientDto[] | null;
  issueId?: number;
  isShowBorder?: boolean;
}

export const TextEditor = memo(
  forwardRef((props: ITextEditor, ref) => {
    const [lexicalContent, setLexicalContent] = useLocalStorage<string>(
      props.id ?? "editor",
      props.value ?? initialEditorContent
    );

    function onError(error: Error) {
      console.error(error);
    }

    const initialConfig: InitialConfigType = useMemo(
      () => {
        return {
          editorState: props.readOnly ? props.value : lexicalContent,
          editable: !props.readOnly,
          namespace: "text-editor",
          theme: {
            ...TextEditorTheme,
            users: props.users,
          },
          onError,
          nodes: TextEditorNodes,
        }
      },
      [lexicalContent, props.readOnly, props.value, props.users]
    );

    const handleChangeContent = useCallback(
      (value: string) => {
        if (!props.readOnly) {
          props.onChange?.(value);
          props.id && !props.id.includes("ds-") && setLexicalContent(value);
        }
      },
      [props, setLexicalContent]
    );

    // useEffect(() => {
    //   props.value && console.log(JSON.parse(props.value));
    // }, [props.value]);

    return (
      <ErrorBoundary FallbackComponent={(args) => ErrorFallback({ ...args, id: props.id ?? "editor" })}>
        <LexicalComposer initialConfig={initialConfig}>
          <TextEditorContainer {...props} ref={ref} onChange={handleChangeContent} />
        </LexicalComposer>
      </ErrorBoundary>
    );
  })
);
