import React, { useEffect } from "react";
import smileWink from "../../../../assets/images/smiles/wink.png";
import { useTranslation } from "react-i18next";
import { ISuccessfulCompletion } from "./PassingRegulationTest.interface";
import { Text } from "../../../uiKit";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import { TConductorInstance } from "react-canvas-confetti/dist/types";

export const SuccessfulCompletion: React.FC<ISuccessfulCompletion> = ({ onCloseWitchReload }) => {
  const { t } = useTranslation();

  const onInit = ({ conductor }: { conductor: TConductorInstance }) => {
    conductor?.shoot();
  };

  const handleDecorateOptions = (options: any) => {
    return {
      ...options,
      spread: 180,
      particleCount: 100,
      startVelocity: 80
    }
  }

  return (
    <div className="">
      <Realistic
        onInit={onInit}
        decorateOptions={handleDecorateOptions}
      />
      <div className="d-stack justify-space-around align-center spacing-2 passing-regulation-test__result-block">
        <div className="passing-regulation-test__result-block-paper">
          <img src={smileWink} alt="smile wink" />
        </div>
        <Text className="passing-regulation-test__result-block-title">{t("parse:success_completion_test")}</Text>
      </div>
    </div>
  );
};
