import React from "react";
import { IEducationSection } from "./EducationSection.interface";
import { useTranslation } from "react-i18next";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { EducationSectionView } from "./EducationSectionView";
import { observer } from "mobx-react-lite";

export const EducationSection = observer((props: IEducationSection) => {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const { authStore } = useRootStore();

  const educationHistory = authStore?.getInitialInfo?.identity?.educationHistory;

  const getCurrentTrainingKey = (vidioId: string): string | undefined | null => {
    return props.educationItem.children?.find((item) => item.videoId === vidioId)?.key;
  };

  const handleSuccessFullCompletion = (vidioId: string) => {
    const keyToReq = getCurrentTrainingKey(vidioId);
    keyToReq &&
      api.education
        .saveEducationProgress(keyToReq)
        .then((res) => {
          notifier.show({ message: t("notifier:success.success_training"), theme: "success", timeout: 10 });
        })
        .catch((error) => {
          notifier.show({ message: t("notifier:error.something_wrong"), theme: "error", timeout: 7 });
        });
  };

  const handleGetProgress = (vidioId?: string | null): { status: string; text: string } => {
    const trainingKey: string | null | undefined = vidioId && getCurrentTrainingKey(vidioId);
    const status = educationHistory?.find((item) => item.key === trainingKey);
    return {
      status: status ? "success" : "error",
      text: status ? t("common:indicator.passed") : t("common:indicator.not_passed"),
    };
  };

  return (
    <EducationSectionView
      onClick={props.onClick}
      educationItem={props.educationItem}
      onSuccessFullCompletion={handleSuccessFullCompletion}
      onGetProgress={handleGetProgress}
    />
  );
});
