import React from "react";
import { Input } from "antd";
import "./InputPassword.scss";
import { PasswordProps } from "antd/lib/input";
import clsx from "clsx";

interface IInputPasswordView extends PasswordProps {
  //
}

const { Password } = Input;

export function InputPasswordView({ className, ...rest }: IInputPasswordView) {
  return <Password size="large" className={clsx("uiKit-inputPassword", className ?? "")} {...rest} />;
}
