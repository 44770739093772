import React from "react";
import "../../Reports.scss";
import { IReport } from "../../Report.interface";
import { ReportsGroup } from "../../components/reportsGroup/ReportsGroup";

export const ReportsCommunication = ({ reports, users, reportsType, selectDate, orgchartFilter }: IReport) => {
  return (
    <div className="d-stack-column justify-start align-start spacing-2">
      {reports?.map((item, index) => (
        <ReportsGroup
          key={index}
          reportsType={reportsType}
          reports={item}
          users={users}
          selectDate={selectDate}
          orgchartFilter={orgchartFilter}
        />
      ))}
    </div>
  );
};
