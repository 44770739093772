import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { findId } from "../../../../../../helpers/pathname";
import { api } from "../../../../../../services";
import { useDateHelpers, useNotifier, useRootStore, useSequentialPromises } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { CompanyGroupDto, DriverStatsOverall } from "../../../../../../api";
import PageHeader from "../../../../layouts/pageHeader/PageHeader";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { observer } from "mobx-react-lite";
import { AddCompanyToGroupDialog } from "../../../../dialogs/addCompanyToGroupDialog/AddCompanyToGroupDialog";
import { driverType, driverTypeKeys, validateName } from "../../helpers";
import AdminDriverPeriodSelectDialog from "../../../../dialogs/adminDriverPeriodSelectDialog/AdminDriverPeriodSelectDialog";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { GroupCompanyWrapper } from "../../contents/GroupCompanyWrapper/GroupCompanyWrapper";
import { IGroupCompanyPage } from "./GroupCompanyPage.interface";
import { AppendDriverToCompanyGroupDialog } from "../../../../dialogs/appendDriverToCompanyGroupDialog/AppendDriverToCompanyGroupDialog";
import { GroupCompanyHeader } from "../../headers/groupCompanyHeader/GroupCompanyHeader";
import { ListMessengersBotDialog } from "../../../../dialogs/listMessengersBotDialog/ListMessengersBotDialog";
import { CreateCompanyGroupDialog } from "../../../../dialogs/createCompanyGroupDialog/CreateCompanyGroupDialog";
import dayjs from "dayjs";
import { changeTimeInDateObject } from "../../../../../../helpers/dateFunctions";

const GroupCompanyPageObserver = (props: IGroupCompanyPage) => {
  const { groupCompanyStore } = useRootStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dateHelpers = useDateHelpers();
  const navigate = useNavigate();
  const notifier = useNotifier();
  const promisesListRef = useRef(useSequentialPromises());

  const id = findId(pathname);

  const groupCompanyId = props.id ?? id;

  const [groupCompany, setGroupCompany] = useState<CompanyGroupDto>();
  const [companyName, setCompanyName] = useState<string>(t("text:loading_data"));
  const [groupStats, setGroupStats] = useState<DriverStatsOverall>();

  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>();

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openAddCompanyToGroupDialog, setOpenAddCompanyToGroupDialog] = useState<boolean>(false);
  const [openPeriodPickerDialog, setOpenPeriodPickerDialog] = useState<boolean>(false);
  const [openAddEmployeeDialog, setOpenAddEmployeeDialog] = useState<boolean>(false);
  const [openListBotsDialog, setOpenListBotsDialog] = useState<boolean>(false);
  const [openEditGroupDialog, setOpenEditGroupDialog] = useState<boolean>(false);

  const [driverTypeDialog, setDriverTypeDialog] = useState<driverType>(driverTypeKeys.driver);

  const [statsPeriod, setStatsPeriod] = useState<{ from: string; to: string }>({
    from: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
    to: new Date(new Date().setHours(23, 59, 0, 0)).toISOString(),
  });
  const [statsPeriodBackup, setStatsPeriodBackup] = useState<{ from: string; to: string }>(statsPeriod);
  const [dateButtonText, setDateButtonText] = useState<string>("");

  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const handleOpenEditGroupDialog = () => setOpenEditGroupDialog(true);
  const handleCloseEditGroupDialog = () => {
    setOpenEditGroupDialog(false);
    navigate(`/driver/super-admin/${groupCompany?.id}`);
  };
  const handleOpenAddCompanyToGroupDialog = () => setOpenAddCompanyToGroupDialog(true);
  const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    handleResetConfirmOperations();
  };
  const handleCloseAddCompanyToGroupDialog = () => setOpenAddCompanyToGroupDialog(false);
  const handleOpenSelectPeriodDialog = () => setOpenPeriodPickerDialog(true);
  const handleOpenAddEmployeeDialog = (driverType: driverType) => {
    setDriverTypeDialog(driverType);
    setOpenAddEmployeeDialog(true);
  };
  const handleCloseAddEmployeeDialog = () => setOpenAddEmployeeDialog(false);
  const handleOpenListBotsDialog = () => setOpenListBotsDialog(true);
  const handleCloseListBotsDialog = () => setOpenListBotsDialog(false);
  const handleChangeName = (newName: string) => setCompanyName(newName);

  const handleStatsPeriodChange = async () => {
    setOpenPeriodPickerDialog(false);
    handleLoadStats();
    setStatsPeriodBackup(statsPeriod);
  };

  const handleStartConfirmOperations = async () => {
    setConfirmLoading(true);
    promisesListRef.current.run().finally(() => handleResetConfirmOperations());
  };

  const handleResetConfirmOperations = () => {
    promisesListRef.current.reset();
    setConfirmLoading(false);
    setConfirmMessage(undefined);
    groupCompanyStore.setDeleteCompanyId(undefined);
    groupCompanyStore.setDeleteEmployeeId(undefined);
  };

  const handleChangeDeleteCompanyId = (companyId: number) => {
    groupCompanyStore.setDeleteCompanyId(companyId);
    setConfirmMessage(t("ui:subheader.confirm_delete_company"));
    handleOpenConfirmDialog();
    promisesListRef.current.add(handleDeleteCompany);
  };

  const handleChangeEmployeeDeleteId = (employeeId: number) => {
    groupCompanyStore.setDeleteEmployeeId(employeeId);
    setConfirmMessage(t("ui:subheader.confirm_delete_employee"));
    handleOpenConfirmDialog();
    promisesListRef.current.add(handleDeleteEmployee);
  };

  const handleLoadStats = async () => {
    await api.driverAdmin
      .overall({
        // dateFrom: statsPeriod.from,
        // dateTo: dayjs(statsPeriod.from).isSame(statsPeriod.to, "date")
        //   ? dayjs(statsPeriod.to).add(1, "day").toISOString()
        //   : statsPeriod.to,
        dateFrom: changeTimeInDateObject(new Date(statsPeriod.from), { hh: 0, mm: 0, ss: 0 }).toISOString(),
        dateTo: dayjs(statsPeriod.from).isSame(statsPeriod.to, "date")
          ? changeTimeInDateObject(dayjs(statsPeriod.to).add(1, "day").toDate(), {
              hh: 23,
              mm: 59,
              ss: 0,
            }).toISOString()
          : changeTimeInDateObject(new Date(statsPeriod.to), { hh: 23, mm: 59, ss: 0 }).toISOString(),
        companyGroupId: groupCompanyId,
      })
      // .overall({ dateFrom: statsPeriod.from, dateTo: statsPeriod.to, companyGroupId: groupCompanyId })
      .then((res) => res && setGroupStats(res))
      .catch(handleApiError);
  };

  const handleDeleteEmployee = async () => {
    if (groupCompanyStore.getDeleteEmployeeId) {
      setConfirmLoading(true);
      await api.companyGroup
        .deleteDriver(groupCompanyStore.getDeleteEmployeeId)
        .then(() => {
          notifier.show({ message: t("notifier:success.delete_employee"), theme: "success" });
          setOpenConfirmDialog(false);
          handleLoadGroupCompanyId();
        })
        .catch(handleApiError);
    } else handleApiError();
  };

  const handleDeleteCompany = async () => {
    if (groupCompanyStore.getDeleteCompanyId) {
      setConfirmLoading(true);
      await api.companyGroup
        .deleteCompany(groupCompanyStore.getDeleteCompanyId)
        .then(() => {
          notifier.show({ message: t("notifier:success.delete_company"), theme: "success" });
          setOpenConfirmDialog(false);
          handleLoadGroupCompanyId();
        })
        .catch(handleApiError);
    } else handleApiError();
  };

  const handleUpdateGroupName = async () => {
    if (validateName(groupCompany?.name ?? "", companyName)) {
      groupCompanyStore.setLoading(true);
      await api.companyGroup
        .edit(groupCompanyId, {
          ...groupCompany,
          name: companyName,
        })
        .then(() => {
          notifier.show({ message: t("notifier:success.edit_name"), theme: "success" });
          handleLoadGroupCompanyId();
        })
        .catch(handleApiError)
        .finally(() => groupCompanyStore.setLoading(false));
    }
  };

  const handleLoadGroupCompanyId = async () => {
    groupCompanyStore.setLoading(true);
    await api.companyGroup
      .getById(props.id ?? groupCompanyId)
      .then((res) => {
        if (res) {
          setGroupCompany(res);
          setCompanyName(res.name!);
        }
      })
      .catch(handleApiError)
      .finally(() => {
        groupCompanyStore.setLoading(false);
      });
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const fromYear = new Date(statsPeriodBackup.from).getFullYear();
    const toYear = new Date(statsPeriodBackup.from).getFullYear();
    // Если выбран один день
    if (dayjs(statsPeriodBackup.from).isSame(dayjs(statsPeriodBackup.to), "day")) {
      setDateButtonText(
        dateHelpers.formatDate(statsPeriodBackup.from, {
          formatObject: { month: "short", hour: undefined, minute: undefined },
        })
      );
    } else {
      setDateButtonText(
        dateHelpers.formatDate(statsPeriodBackup.from, {
          showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
          formatObject: {
            hour: undefined,
            minute: undefined,
            month: "short",
          },
        }) +
          " - " +
          dateHelpers.formatDate(statsPeriodBackup.to, {
            showYearWhenIsCurrent: fromYear != currentYear || toYear != currentYear,
            formatObject: {
              hour: undefined,
              minute: undefined,
              month: "short",
            },
          })
      );
    }
  }, [statsPeriodBackup]);

  useEffect(() => {
    if (groupCompanyId || props.id) {
      handleLoadStats();
      handleLoadGroupCompanyId();
    } else navigate("/error404page");
  }, [props.id, groupCompanyId]);

  useEffect(() => {
    if (props.addBot) {
      openListBotsDialog && handleCloseListBotsDialog();
      openAddEmployeeDialog && handleCloseAddEmployeeDialog();
      openConfirmDialog && handleCloseConfirmDialog();
      openAddCompanyToGroupDialog && handleCloseAddCompanyToGroupDialog();
      if (openPeriodPickerDialog) {
        setOpenPeriodPickerDialog(false);
        setStatsPeriod(statsPeriodBackup);
      }
      !openEditGroupDialog && setOpenEditGroupDialog(true);
    }
  }, [props.addBot]);

  return (
    <>
      {openEditGroupDialog && (
        <CreateCompanyGroupDialog
          companyGroup={groupCompany}
          open={openEditGroupDialog}
          telegramFormOnly={props.addBot}
          onClose={handleCloseEditGroupDialog}
        />
      )}
      {openListBotsDialog && (
        <ListMessengersBotDialog
          isOpen={openListBotsDialog}
          onClose={handleCloseListBotsDialog}
          messengers={groupCompany?.messengers}
        />
      )}
      {openAddEmployeeDialog && groupCompany && (
        <AppendDriverToCompanyGroupDialog
          open={openAddEmployeeDialog}
          groupCompany={groupCompany}
          type={driverTypeDialog}
          onReloadData={handleLoadGroupCompanyId}
          onClose={handleCloseAddEmployeeDialog}
        />
      )}
      {openConfirmDialog && (
        <CustomConfirmDialog
          title={t("ui:title.confirm")}
          subTitle={confirmMessage}
          onConfirm={handleStartConfirmOperations}
          onClose={handleCloseConfirmDialog}
          loading={confirmLoading}
          open={openConfirmDialog}
        />
      )}
      {openAddCompanyToGroupDialog && (
        <AddCompanyToGroupDialog
          open={openAddCompanyToGroupDialog}
          groupId={groupCompanyId}
          onClose={handleCloseAddCompanyToGroupDialog}
          onLoadGroupCompanyId={handleLoadGroupCompanyId}
        />
      )}
      {openPeriodPickerDialog && (
        <AdminDriverPeriodSelectDialog
          value={statsPeriod}
          open={openPeriodPickerDialog}
          onClose={() => {
            setOpenPeriodPickerDialog(false);
            setStatsPeriod(statsPeriodBackup);
          }}
          onChange={(value: { from: string; to: string }) => setStatsPeriod(value)}
          onSave={handleStatsPeriodChange}
          disableFuture
        />
      )}
      <PageHeader>
        <GroupCompanyHeader
          name={companyName}
          onOpenListBotsDialog={handleOpenListBotsDialog}
          onChangeName={handleChangeName}
          onBlur={handleUpdateGroupName}
        />
      </PageHeader>
      <PageContent isLoading={groupCompanyStore.getLoading} zoom={{ percentage: groupCompanyStore.getZoom }}>
        {groupCompany && (
          <GroupCompanyWrapper
            companyGroup={groupCompany}
            groupStats={groupStats}
            dateButtonText={dateButtonText}
            onOpenAddEmployeeDialog={handleOpenAddEmployeeDialog}
            onDeleteCompanyById={handleChangeDeleteCompanyId}
            onDeleteEmployeeById={handleChangeEmployeeDeleteId}
            onOpenSelectPeriodDialog={handleOpenSelectPeriodDialog}
            onLoadGroupCompanyId={handleLoadGroupCompanyId}
            onOpenAddCompanyToGroupDialog={handleOpenAddCompanyToGroupDialog}
          />
        )}
      </PageContent>
    </>
  );
};

export const GroupCompanyPage = observer(GroupCompanyPageObserver);
