import React, { ReactNode } from "react";
import "./DialogActions.scss";

interface IDialogActionsView {
  className?: string;
  children?: ReactNode;
}

export function DialogActionsView({ className, ...rest }: IDialogActionsView) {
  return null;
}
