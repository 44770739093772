import React, { memo, useEffect, useRef, useState } from "react";
import "./PassingRegulationTest.scss";
import { PassingRegulationTestView } from "./PassingRegulationTestView";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { IPassingRegulationTest, PassingRegulationQestion } from "./PassingRegulationTest.interface";
import { RegulationContentQuestionDto } from "../../../../api";
import {
  compareArrays,
  comparisonAnswers,
  getQuestionWithResetAnswers,
  handeleIdentifyAnswerKey,
  rewriteMultipleAnswer,
  rewriteSingleAnswer,
} from "./helpers";
import { api } from "../../../../services";

const PassingRegulationTestDialogMemo = (props: IPassingRegulationTest) => {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialQuestions = useRef<RegulationContentQuestionDto[]>([]);

  const [isSuccessCompletion, setIsSuccessCompletion] = useState<boolean | undefined>(undefined);
  const [isFailedCompletion, setIsFailedCompletion] = useState<boolean | undefined>(undefined);

  const [currentQuestions, setCurrentQuestions] = useState<PassingRegulationQestion[]>([]);
  const [currentQuestionsAfterSend, setCurrentQuestionsAfterSend] = useState<PassingRegulationQestion[]>([]);

  const handlerSuccessfulCompletion = () => {
    setIsLoading(true);
    api.regulation
      .study(props.regulationId)
      .then(() => setIsSuccessCompletion(true))
      .catch(() => {
        notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlerFailedCompletion = () => {
    setIsFailedCompletion(true);
  };

  const handleCompleteTest = () => {
    let isAllRight: boolean = comparisonAnswers(initialQuestions.current, currentQuestions);
    // setCurrentQuestions();
    if (isAllRight) handlerSuccessfulCompletion();
    else handlerFailedCompletion();
  };

  const handleChangeAnswer = (questionId: number, answerId: number, value: boolean) => {
    setCurrentQuestions((prev) => rewriteMultipleAnswer(prev, questionId, answerId, value));
  };

  const handleSelectAnswer = (questionId: number, answerId: number) => {
    setCurrentQuestions((prev) => rewriteSingleAnswer(prev, questionId, answerId));
  };

  const handleClose = () => {
    setIsFailedCompletion(false);
    setIsSuccessCompletion(false);
    isSuccessCompletion ? (props.onCloseWitchReload ? props.onCloseWitchReload() : props.onClose()) : props.onClose();
  };

  const handleTryAgain = () => {
    setIsFailedCompletion(false);
    setIsSuccessCompletion(false);
    const arr = compareArrays(initialQuestions.current, currentQuestions);
    setCurrentQuestions(arr);
    setCurrentQuestionsAfterSend(arr);
  };

  const handleTakeInitialState = () => {
    setIsFailedCompletion(false);
    setIsSuccessCompletion(false);
    if (props.questions) {
      initialQuestions.current = props.questions;
      const currentInitialQuestions: PassingRegulationQestion[] = props.questions.map((question) => ({
        ...question,
        answerKey: handeleIdentifyAnswerKey(question),
        answers: getQuestionWithResetAnswers(question),
      }));
      currentInitialQuestions && setCurrentQuestions(currentInitialQuestions);
    }
  };

  useEffect(() => {
    handleTakeInitialState();
    //TODO : remove dependence
  }, []);

  return (
    <PassingRegulationTestView
      isSuccessCompletion={isSuccessCompletion}
      isFailedCompletion={isFailedCompletion}
      questions={currentQuestions}
      questionsAfterSend={currentQuestionsAfterSend}
      isLoading={isLoading}
      onCloseWitchReload={props.onCloseWitchReload}
      onSimplerolleUp={props.onClose}
      onCompleteTest={handleCompleteTest}
      onChangeAnswer={handleChangeAnswer}
      onSelectAnswer={handleSelectAnswer}
      onTakeInitialState={handleTakeInitialState}
      onClose={handleClose}
      open={props.open}
      onTryAgain={handleTryAgain}
    />
  );
};

export const PassingRegulationTestDialog = memo(PassingRegulationTestDialogMemo);
