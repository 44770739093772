import "./CommunicationModeSwitch.scss";
import { Card, Icon, Segmented } from "../../../../../uiKit";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { LucideCalendar, LucideLayoutGrid } from "lucide-react";
import { observer } from "mobx-react-lite";
import { CommunicationModeKey } from "../../constants/keys";
import { useLocalStorage, useRootStore } from "../../../../../../hooks";
import { ListChecks } from "../../../../../../constants/icon";
import { SegmentedValue } from "rc-segmented";
import { useLocation, useNavigate } from "react-router-dom";

interface ICommunicationModeSwitchProps {
}

const CommunicationModeSwitchObserved = (props: ICommunicationModeSwitchProps) => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const url = useLocation();

  const { t } = useTranslation();
  const { communicationStore } = useRootStore();

  const relationsList = useMemo<SegmentedLabeledOption[]>(() => {
    const { companyModules = [] } = authStore.getInitialInfo ?? {};
    const isShowCalendarPlaningModule = !!companyModules?.find(({type}) => type === 11) ?? false;
    const isShowTablePlaningModule = !!companyModules?.find(({type}) => type === 10) ?? false;

    const list = [
      {
        value: CommunicationModeKey.Kanban,
        icon:
          <Icon component={() => <LucideLayoutGrid size={16} />} />

      }
    ];
    if (isShowCalendarPlaningModule) {
      list.push( {
        value: CommunicationModeKey.CalendarPlanning,
        icon: (
          <Icon component={() => <LucideCalendar size={16} />} />
        )
      });
    }

    if (isShowTablePlaningModule) {
      list.push( {
        value: CommunicationModeKey.TablePlanning,
        icon: (
          <Icon component={() => <ListChecks size={16} />} />
        )
      })
    }
    return list;

  }, [authStore]);

  const [tablePlaningActiveTab, setTablePlaningActiveTab] = useLocalStorage<string>("tablePlaningActiveTab","")


  const handleChangeMode = useCallback((mode: SegmentedValue) => {
    if (mode === CommunicationModeKey.TablePlanning) {
      navigate(tablePlaningActiveTab ?? "/communication");
    } else {
      navigate("/communication");
      setTablePlaningActiveTab(url.pathname)
    }
    communicationStore.setMode(mode as CommunicationModeKey)
  }, [url, tablePlaningActiveTab]);

  return (
    <div className="communication-mode-switch-wrapper">
      <Card className="br-sm" isShadowed={false} bodyStyle={{ padding: 0 }}>
        <Segmented
          style={{
            backgroundColor: "transparent",
        }}
          // className="pa-0"
          // disabled={props.isDisabled}
          options={relationsList}
          value={communicationStore.getMode}
          onChange={handleChangeMode}
        />
      </Card>
    </div>
  );
};

export const CommunicationModeSwitch = observer(CommunicationModeSwitchObserved);
