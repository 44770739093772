import React, { useState } from "react";
import DriverCalledAdminDialogView from "./DriverCalledAdminDialogView";
import { useTranslation } from "react-i18next";
import { api } from "../../../../services";
import { useNotifier } from "../../../../hooks";

interface IDriverCalledAdminDialog {
  open: boolean;
  controlSessionId: number;

  onSuccess(): void;
  onClose(): void;
}

function DriverCalledAdminDialog(props: IDriverCalledAdminDialog) {
  const { t } = useTranslation();
  const notifier = useNotifier();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [callResult, setCallResult] = useState({
    type: null,
    text: "",
  });

  const callResultTypes = [
    { id: 0, text: t("ui:label.call_result.busy"), value: "driver.action.call.result.busy" },
    { id: 1, text: t("ui:label.call_result.fail"), value: "driver.action.call.result.fail" },
    { id: 2, text: t("ui:label.call_result.promised_to_call_executor"), value: "driver.action.call.admin.result.ptce" },
    {
      id: 3,
      text: t("ui:label.call_result.gave_other_contacts"),
      value: "driver.action.call.admin.result.goc",
    },
  ];

  const handleSaveButtonClick = async () => {
    setIsLoading(true);
    const r = await api.controlSessionHistory.create({
      id: 0,
      controlSessionId: props.controlSessionId,
      text: callResult.text,
      baseKey: "driver.action.call.admin",
      actionResultKey: callResult.type,
    });
    notifier.showSuccessError(t("notifier:success.history_added_wrote"), t("notifier:error.history_added_wrote"), !!r);
    if (r == null) {
      setIsLoading(false);
      return;
    }
    props.onSuccess();
    setIsLoading(false);
    setCallResult({
      type: null,
      text: "",
    });
  };

  const handleCloseButtonClick = () => {
    setIsLoading(false);
    setCallResult({
      type: null,
      text: "",
    });
    props.onClose();
  };

  return (
    <DriverCalledAdminDialogView
      open={props.open}
      isLoading={isLoading}
      callResult={callResult}
      callResultTypes={callResultTypes}
      onClose={handleCloseButtonClick}
      onSave={handleSaveButtonClick}
      setCallResult={setCallResult as any}
    />
  );
}

export default DriverCalledAdminDialog;
