import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ExecutorsSelect.scss";
import { UserSelectionRule } from "../../../api/types/userSelectionRule";
import { AutocompleteModel, RoleDto } from "../../../api";
import { UserSelectionDialog } from "../../modules/dialogs/userSelectionDialog/UserSelectionDialog";
import { Button, IButton } from "../../uiKit/general/button/Button";
import { Spin } from "../../uiKit";
import SubheaderText from "../subheaderText/SubheaderText";
import { UserSelectionChip } from "../userSelectionChip/UserSelectionChip";
import { useRootStore } from "../../../hooks";
import {
  IFunctionTreeItem
} from "../../modules/dialogs/regulationAccessControlDialog/regulationFunctionSelectionDialog/functionTreeItem/FunctionTreeItem.interface";

interface IExecutorSelectView {
  data: (UserSelectionRule & { idx?: number })[];
  isWhoInNaming?: boolean;
  isLoading: boolean;
  isDialogOpen: boolean;
  isDialogToExclude: boolean;
  customSubheaders?: {
    include?: React.ReactNode;
    exclude?: React.ReactNode;
  };
  allRoles: RoleDto[] | AutocompleteModel[];
  guideIcon?: React.ReactNode;
  buttonProps?: {
    include?: IButton;
    exclude?: IButton;
  };
  onRuleDelete: (idx: number | undefined) => void;
  onRuleAdd: (rule: UserSelectionRule, role?: RoleDto | AutocompleteModel) => void;
  onDialogOpen: (toExclude: boolean) => void;
  onDialogClose: () => void;
}


export function ExecutorSelectView(props: IExecutorSelectView) {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const findRoleNameById = (id: number): any => {
    if (props.isLoading) return t("text:loading_data");
    return authStore.getCurrentCompanyUiType == 2
      ? (props.allRoles as AutocompleteModel[]).find((item) => item.id == id)?.text ?? t("ui:misc.radio_all")
      : (props.allRoles as RoleDto[]).find((item) => item.id == id)?.name ?? t("ui:misc.radio_all");
  };

  const findRolePositionNameById = (roleId: number, positionId: number, positionType?: 1 | 2): any => {
    if (props.isLoading) return "";
    if (positionType != null) {
      if (positionType == 1) return t("common:user_selection_dialog.position_select_box.all_managers");
      if (positionType == 2) return t("common:user_selection_dialog.position_select_box.all_performers");
    } else {
      return (
        (props.allRoles as RoleDto[])
          .find((item) => item.id === roleId)
          ?.positions?.find((item2) => item2.id === positionId)?.name ?? t("ui:misc.radio_all")
      );
    }
  };

  // const [selectedRoles, setSelectedRoles] = useState<IFunctionTreeItem[]>([]);

  return (
    <div className="executor-select__wrapper">
      <UserSelectionDialog
        open={props.isDialogOpen}
        onClose={props.onDialogClose}
        onSave={props.onRuleAdd}
        propsRoles={props.allRoles}
        isExcludedType={props.isDialogToExclude}
      />
      <Spin spinning={props.isLoading}>
        <div className="d-stack-column spacing-3">
          <div className="d-stack-column spacing-2">
            <div className="d-stack spacing-1 align-center">
              {props.customSubheaders?.include ?? (
                <SubheaderText
                  noBottomOffset
                  text={props.isWhoInNaming ? t("ui:subheader.who_available_alt") : t("ui:subheader.who_available")}
                />
              )}
              <div className="mt-1">{props.guideIcon}</div>
            </div>
            <div className="__cards-container">
              {props.data
                .filter((d) => !d.isExcluded)
                .map((d) => (
                  <UserSelectionChip
                    key={d.idx as number}
                    id={d.id as number}
                    orgchartId={d.orgchartId}
                    text1={findRoleNameById(d.roleId as number)}
                    onRemove={() => props.onRuleDelete(d.idx)}
                    text2={
                      authStore.getCurrentCompanyUiType == 2
                        ? undefined
                        : findRolePositionNameById(d.roleId!, d.rolePositionId!, d.rolePositionType)
                    }
                  />
                ))}
            </div>
            <div className="executor-select__includes">
              <Button
                onClick={() => props.onDialogOpen(false)}
                size="middle"
                variant="default"
                {...props.buttonProps?.include}
              >
                {t("ui:button.add")}
              </Button>
            </div>
          </div>

          {props.data.some((d) => d.rolePositionId == null) && (
            <div className="d-stack-column spacing-2">
              {props.customSubheaders?.exclude ?? (
                <SubheaderText text={t("ui:subheader.who_not_available")} noBottomOffset />
              )}
              <div className="__cards-container">
                {props.data
                  .filter((d) => d.isExcluded)
                  .map((d) => (
                    <UserSelectionChip
                      key={d.idx as number}
                      id={d.id as number}
                      orgchartId={d.orgchartId}
                      text1={findRoleNameById(d.roleId as number)}
                      onRemove={() => props.onRuleDelete(d.idx)}
                      text2={findRolePositionNameById(d.roleId!, d.rolePositionId!, d.rolePositionType)}
                    />
                  ))}
              </div>
              <div className="executor-select__excludes">
                <Button
                  onClick={() => props.onDialogOpen(true)}
                  size="middle"
                  variant="default"
                  {...props.buttonProps?.exclude}
                >
                  {t("ui:button.add")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
}
