import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from "lexical";
import "./FormatSelect.scss";
import { $setBlocksType } from "@lexical/selection";
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode, HeadingTagType } from "@lexical/rich-text";
import * as React from "react";
import { memo, useCallback, useMemo } from "react";
import {
  AlignFormatType,
  BlockFormatType,
  IFormatSelect,
  InsertFormatType,
  ListFormatType,
  SelectFormatType,
  TextFormatType,
} from "./FormatSelect.interface";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon, IconButton, Text } from "../../../../../uiKit";
import {
  LucideAlignCenter,
  LucideAlignJustify,
  LucideAlignLeft,
  LucideAlignRight,
  LucideChevronDown,
  LucideCode,
  LucideHeading1,
  LucideHeading2,
  LucideHeading3,
  LucideIndent,
  LucideList,
  LucideListChecks,
  LucideListOrdered,
  LucideMinusSquare,
  LucideMoreHorizontal,
  LucideOutdent,
  LucideQuote,
  LucideStrikethrough,
  LucideSubscript,
  LucideSuperscript,
  LucideTrash,
  LucideType,
  LucideUnderline,
  LucideYoutube,
} from "lucide-react";
import { IDropdownItem } from "../../../../../uiKit/navigation/dropdown/Dropdown";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { DotsIcon } from "../../../../../../constants/icon";

export const FormatSelect = memo((props: IFormatSelect) => {
  const { blockType, alignType, editor, disabled, formatType, listType, isNewStyle } = props;
  const { t } = useTranslation();

  const formatParagraph = useCallback(() => {
    if (blockType !== BlockFormatType.PARAGRAPH) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))
          $setBlocksType(selection, () => $createParagraphNode());
      });
    }
  }, [blockType, editor]);

  const formatHeading = useCallback(
    (headingSize: HeadingTagType) => {
      if (blockType !== headingSize) {
        editor.update(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(headingSize));
          }
        });
      }
    },
    [blockType, editor]
  );

  const formatBulletList = useCallback(() => {
    if (listType !== ListFormatType.BULLET) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [listType, editor]);

  const formatNumberedList = useCallback(() => {
    if (listType !== ListFormatType.NUMBER) {
      editor?.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor?.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [editor, listType]);

  const formatQuote = useCallback(() => {
    if (listType !== ListFormatType.QUOTE) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $setBlocksType(selection, () => $createQuoteNode());
        }
      });
    }
  }, [listType, editor]);

  const formatCheckList = useCallback(() => {
    if (listType !== ListFormatType.CHECK) {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [listType, editor]);

  const blockSelectItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: BlockFormatType.PARAGRAPH,
        onClick: () => formatParagraph(),
        text: t("common:text_editor_toolbar.select.normal"),
        icon: <LucideType className={blockType === BlockFormatType.PARAGRAPH ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 1,
        value: BlockFormatType.H1,
        onClick: () => formatHeading(BlockFormatType.H1),
        text: t("common:text_editor_toolbar.select.h1"),
        icon: <LucideHeading1 className={blockType === BlockFormatType.H1 ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 2,
        value: BlockFormatType.H2,
        onClick: () => formatHeading(BlockFormatType.H2),
        text: t("common:text_editor_toolbar.select.h2"),
        icon: <LucideHeading2 className={blockType === BlockFormatType.H2 ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 3,
        value: BlockFormatType.H3,
        onClick: () => formatHeading(BlockFormatType.H3),
        text: t("common:text_editor_toolbar.select.h3"),
        icon: <LucideHeading3 className={blockType === BlockFormatType.H3 ? "toolbar__active" : ""} size={18} />,
      },
    ],
    [blockType, formatHeading, formatParagraph, t]
  );

  const alignSelectItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: AlignFormatType.LEFT,
        onClick: () => editor?.dispatchCommand(FORMAT_ELEMENT_COMMAND, AlignFormatType.LEFT),
        text: t("common:text_editor_toolbar.select.align_left"),
        icon: <LucideAlignLeft className={alignType === AlignFormatType.LEFT ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 1,
        value: AlignFormatType.CENTER,
        onClick: () => editor?.dispatchCommand(FORMAT_ELEMENT_COMMAND, AlignFormatType.CENTER),
        text: t("common:text_editor_toolbar.select.align_center"),
        icon: <LucideAlignCenter className={alignType === AlignFormatType.CENTER ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 2,
        value: AlignFormatType.RIGHT,
        onClick: () => editor?.dispatchCommand(FORMAT_ELEMENT_COMMAND, AlignFormatType.RIGHT),
        text: t("common:text_editor_toolbar.select.align_right"),
        icon: <LucideAlignRight className={alignType === AlignFormatType.RIGHT ? "toolbar__active" : ""} size={18} />,
      },
      {
        id: 3,
        value: AlignFormatType.JUSTIFY,
        onClick: () => editor?.dispatchCommand(FORMAT_ELEMENT_COMMAND, AlignFormatType.JUSTIFY),
        text: t("common:text_editor_toolbar.select.align_justify"),
        icon: (
          <LucideAlignJustify className={alignType === AlignFormatType.JUSTIFY ? "toolbar__active" : ""} size={18} />
        ),
      },
      {
        id: 4,
        value: AlignFormatType.INDENT,
        onClick: () => editor?.dispatchCommand(INDENT_CONTENT_COMMAND, undefined),
        text: t("common:text_editor_toolbar.select.indent"),
        icon: <LucideIndent size={18} />,
      },
      {
        id: 5,
        value: AlignFormatType.OUTDENT,
        onClick: () => editor?.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined),
        text: t("common:text_editor_toolbar.select.outdent"),
        icon: <LucideOutdent size={18} />,
      },
    ],
    [alignType, editor, t]
  );

  const insertFormatSelectItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: InsertFormatType.HR,
        onClick: () => editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
        text: t("common:text_editor_toolbar.hr"),
        icon: <LucideMinusSquare size={18} />,
      },
      {
        id: 1,
        value: InsertFormatType.VIDEO,
        onClick: () => props.onOpenVideoDialog?.(),
        text: t("common:text_editor_toolbar.video"),
        icon: <LucideYoutube size={18} />,
      },
      {
        id: 2,
        value: InsertFormatType.CODE,
        onClick: () => editor?.dispatchCommand(FORMAT_TEXT_COMMAND, InsertFormatType.CODE),
        text: t("common:text_editor_toolbar.code"),
        icon: <LucideCode className={props.isCode ? "toolbar__active" : ""} size={18} />,
      },
    ],
    [editor, props, t]
  );

  const textFormatSelectItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: TextFormatType.UNDERLINE,
        text: t("common:text_editor_toolbar.underline"),
        onClick: () => editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.UNDERLINE),
        icon: <LucideUnderline size={18} className={props.isUnderline ? "toolbar__active" : ""} />,
      },
      {
        id: 2,
        value: TextFormatType.STRIKETHROUGH,
        text: t("common:text_editor_toolbar.strikethrough"),
        onClick: () => editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.STRIKETHROUGH),
        icon: <LucideStrikethrough size={18} className={props.isStrikethrough ? "toolbar__active" : ""} />,
      },
      {
        id: 3,
        value: TextFormatType.SUBSCRIPT,
        onClick: () => {
          if (props.isSuperscript) {
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUPERSCRIPT);
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUBSCRIPT);
          } else {
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUBSCRIPT);
          }
        },
        text: t("common:text_editor_toolbar.subscript"),
        icon: <LucideSubscript size={18} className={props.isSubscript ? "toolbar__active" : ""} />,
      },
      {
        id: 4,
        value: TextFormatType.SUPERSCRIPT,
        onClick: () => {
          if (props.isSubscript) {
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUBSCRIPT);
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUPERSCRIPT);
          } else {
            editor?.dispatchCommand(FORMAT_TEXT_COMMAND, TextFormatType.SUPERSCRIPT);
          }
        },
        text: t("common:text_editor_toolbar.superscript"),
        icon: <LucideSuperscript size={18} className={props.isSuperscript ? "toolbar__active" : ""} />,
      },
      {
        id: 5,
        value: TextFormatType.CLEAR,
        onClick: () => props.onClearFormatting?.(),
        text: t("common:text_editor_toolbar.clear_formatting"),
        icon: <LucideTrash size={18} />,
      },
    ],
    [editor, props, t]
  );

  const listFormatSelectItems: IDropdownItem[] = useMemo(
    () => [
      {
        id: 0,
        value: ListFormatType.BULLET,
        text: t("common:text_editor_toolbar.select.bulleted_list"),
        onClick: () => formatBulletList(),
        icon: <LucideList size={18} className={props.listType === ListFormatType.BULLET ? "toolbar__active" : ""} />,
      },
      {
        id: 1,
        value: ListFormatType.NUMBER,
        text: t("common:text_editor_toolbar.select.numbered_list"),
        onClick: () => formatNumberedList(),
        icon: (
          <LucideListOrdered size={18} className={props.listType === ListFormatType.NUMBER ? "toolbar__active" : ""} />
        ),
      },
      {
        id: 2,
        value: ListFormatType.CHECK,
        text: t("common:text_editor_toolbar.select.check_list"),
        onClick: () => formatCheckList(),
        icon: (
          <LucideListChecks size={18} className={props.listType === ListFormatType.CHECK ? "toolbar__active" : ""} />
        ),
      },
      {
        id: 3,
        value: ListFormatType.QUOTE,
        onClick: () => formatQuote(),
        text: t("common:text_editor_toolbar.select.quote"),
        icon: <LucideQuote size={18} className={props.listType === ListFormatType.QUOTE ? "toolbar__active" : ""} />,
      },
    ],
    [formatBulletList, formatCheckList, formatNumberedList, formatQuote, props.listType, t]
  );

  const handleGetAlignIcon = useCallback(() => {
    switch (alignType) {
      case AlignFormatType.LEFT:
        return <LucideAlignLeft size={18} />;
      case AlignFormatType.CENTER:
        return <LucideAlignCenter size={18} />;
      case AlignFormatType.RIGHT:
        return <LucideAlignRight size={18} />;
      case AlignFormatType.JUSTIFY:
        return <LucideAlignJustify size={18} />;
      case AlignFormatType.INDENT:
        return <LucideIndent size={18} />;
      case AlignFormatType.OUTDENT:
        return <LucideOutdent size={18} />;
      default:
        return <LucideAlignLeft size={18} />;
    }
  }, [alignType]);

  const handleGetListIcon = useCallback(() => {
    switch (listType) {
      case ListFormatType.BULLET:
        return <LucideList size={18} />;
      case ListFormatType.NUMBER:
        return <LucideListOrdered size={18} />;
      case ListFormatType.CHECK:
        return <LucideListChecks size={18} />;
      case ListFormatType.QUOTE:
        return <LucideQuote size={18} />;
      default:
        return <LucideList size={18} />;
    }
  }, [listType]);

  const handleGetHeadingBlockText = useCallback(() => {
    switch (blockType) {
      case BlockFormatType.PARAGRAPH:
        return <Text>{t("common:text_editor_toolbar.select.normal")}</Text>;
      case BlockFormatType.H1:
        return <Text>{t("common:text_editor_toolbar.select.h1")}</Text>;
      case BlockFormatType.H2:
        return <Text>{t("common:text_editor_toolbar.select.h2")}</Text>;
      case BlockFormatType.H3:
        return <Text>{t("common:text_editor_toolbar.select.h3")}</Text>;
      default:
        return <Text>t("common:text_editor_toolbar.select.normal")</Text>;
    }
  }, [blockType, t]);

  const handleGetDropdownItems = useCallback(
    (format: SelectFormatType) => {
      switch (format) {
        case SelectFormatType.BLOCK:
          return blockSelectItems;
        case SelectFormatType.ALIGN:
          return alignSelectItems;
        case SelectFormatType.TEXT_FORMAT:
          return textFormatSelectItems;
        case SelectFormatType.LIST:
          return listFormatSelectItems;
        case SelectFormatType.INSERT:
          return insertFormatSelectItems;
        default:
          return undefined;
      }
    },
    [alignSelectItems, blockSelectItems, insertFormatSelectItems, listFormatSelectItems, textFormatSelectItems]
  );

  const handleGetChildren = useCallback(
    (format: SelectFormatType) => {
      switch (format) {
        case SelectFormatType.BLOCK:
          return (
            <div className="d-stack-row align-center spacing-1">
              {handleGetHeadingBlockText()}
              <Icon component={() => isNewStyle ? <DotsIcon/> : <LucideChevronDown />}></Icon>
            </div>
          );
        case SelectFormatType.ALIGN:
          return (
            <div className="d-stack-row align-center spacing-1">
              {handleGetAlignIcon()}
              <Icon component={() => isNewStyle ? <DotsIcon/> : <LucideChevronDown />}></Icon>
            </div>
          );
        case SelectFormatType.TEXT_FORMAT:
        case SelectFormatType.INSERT:
          return <IconButton icon={<LucideMoreHorizontal />} />;
        case SelectFormatType.LIST:
          return (
            <div className="d-stack-row align-center spacing-1">
              {handleGetListIcon()}
              <Icon component={() =>isNewStyle ? <DotsIcon/> : <LucideChevronDown />}></Icon>
            </div>
          );
        default:
          return null;
      }
    },
    [handleGetAlignIcon, handleGetHeadingBlockText, handleGetListIcon]
  );

  return (
    <Dropdown trigger={["click"]} items={handleGetDropdownItems(formatType)} disabled={disabled}>
      <div
        className={`${
          formatType !== SelectFormatType.TEXT_FORMAT && formatType !== SelectFormatType.INSERT && "format-select pa-1"
        }`}
      >
        {handleGetChildren(formatType)}
      </div>
    </Dropdown>
  );
});
