import React from 'react';
import "./TimePickerQuickValue.scss";
import clsx from "clsx";
import { ITimePickerQuickValue } from "./ITimePickerQuickValue";
import { useTranslation } from "react-i18next";

export const TimePickerQuickValue = (props: ITimePickerQuickValue) => {
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    props.onChange(props.item.value);
  }

  return (
    <div
      className={clsx("time-picker-quick-value", {
        "time-picker-quick-value_active": props.isActive,
      })}
      onClick={handleClick}
    >
      { t(props.item.text) }
    </div>
  )
}