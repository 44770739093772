import React from "react";
import SubheaderText from "../subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { RecurringIssueDateDeadlineConfigUntilDto } from "../../../api";
import TimePickerInlinePrimary from "../timePickerInlinePrimary/TimePickerInlinePrimary";
import "./DeadlineSelect.scss";
import { guideKeys } from "../../../stores/hintStore";
import { OpeningGuideIcon } from "../openingGuideIcon/OpeningGuideIcon";
import { IconButton, Select, SelectOption } from "../../uiKit";
import { FiX } from "@react-icons/all-files/fi/FiX";

interface IDeadlineSelectView {
  value: {
    dateDeadlineAfterTime: string | null;
    dateDeadlineUntil: RecurringIssueDateDeadlineConfigUntilDto | null;
  };
  clearable?: boolean;
  deadlineAfterType: number;
  deadlineAfterValue: number;
  deadlineAfterValues: (string | number)[];
  deadlineType: string;

  onDeadlineAfterValueChange(value: number): void;

  onDeadlineAfterTypeChange(type: number): void;

  onTypeChange(type: string): void;

  onDayChange(value: number): void;

  onTimeChange(value: string): void;

  onClearButtonCLick(): void;
}

function DeadlineSelectView(props: IDeadlineSelectView) {
  const { t } = useTranslation();

  const deadlineTypes = [
    { id: 0, name: t("common:menu.deadline_select.after_time"), value: "after_time" },
    { id: 1, name: t("common:menu.deadline_select.until_day"), value: "until_day" },
  ];
  const list = {
    days: [
      { id: 0, name: t("common:date.day.sunday") },
      { id: 1, name: t("common:date.day.monday") },
      { id: 2, name: t("common:date.day.tuesday") },
      { id: 3, name: t("common:date.day.wednesday") },
      { id: 4, name: t("common:date.day.thursday") },
      { id: 5, name: t("common:date.day.friday") },
      { id: 6, name: t("common:date.day.saturday") },
    ],
    months: [
      { id: 0, name: t("common:misc.time_period_picker.every_month") },
      { id: 1, name: t("common:date.month.january") },
      { id: 2, name: t("common:date.month.february") },
      { id: 3, name: t("common:date.month.march") },
      { id: 4, name: t("common:date.month.april") },
      { id: 5, name: t("common:date.month.may") },
      { id: 6, name: t("common:date.month.june") },
      { id: 7, name: t("common:date.month.july") },
      { id: 8, name: t("common:date.month.august") },
      { id: 9, name: t("common:date.month.september") },
      { id: 10, name: t("common:date.month.october") },
      { id: 11, name: t("common:date.month.november") },
      { id: 12, name: t("common:date.month.december") },
    ],
    types: [
      { id: 0, name: t("common:misc.date_type.minutes") },
      { id: 1, name: t("common:misc.date_type.hours") },
      { id: 2, name: t("common:misc.date_type.days") },
      { id: 3, name: t("common:misc.date_type.months") },
    ],
  };

  return (
    <div>
      <div className="d-stack-row justify-start align-center spacing-1">
        <SubheaderText text={t("ui:subheader.deadline")} />
        <div style={{ marginBottom: "4px" }}>
          <OpeningGuideIcon contentKey={guideKeys.recurringIssues} anchorId="4anchor" />
        </div>
      </div>
      <div className="d-stack-row spacing-2">
        <Select
          // size="large"
          onChange={(value) => props.onTypeChange(value)}
          value={props.deadlineType}
          fullWidth
        >
          {deadlineTypes.map((pt) => (
            <SelectOption key={pt.id} value={pt.value}>
              {pt.name}
            </SelectOption>
          ))}
        </Select>

        {props.value.dateDeadlineUntil && (
          <>
            <Select
              onChange={(value) => props.onDayChange(+value)}
              // onChange={(event) => props.onValue1Change(item.idx as number, Number(event.target.value))}
              value={props.value.dateDeadlineUntil.untilDayOfWeek}
              fullWidth
            >
              {list.days.map((day) => (
                <SelectOption key={day.id} value={day.id}>
                  {day.name}
                </SelectOption>
              ))}
            </Select>
          </>
        )}

        {props.value.dateDeadlineUntil != null && (
          <TimePickerInlinePrimary
            value={props.value.dateDeadlineAfterTime?.substring(-5) ?? "12:00"}
            onChange={(value) => props.onTimeChange(value)}
          />
        )}

        {props.value.dateDeadlineAfterTime && (
          <Select
            onChange={(value) => props.onDeadlineAfterValueChange(+value)}
            value={props.deadlineAfterValue}
            style={{ maxHeight: "30vh" }}
          >
            {props.deadlineAfterValues.map((value) => (
              <SelectOption key={value} value={value}>
                {value}
              </SelectOption>
            ))}
          </Select>
        )}

        {props.value.dateDeadlineAfterTime && (
          <Select
            onChange={(value) => props.onDeadlineAfterTypeChange(+value)}
            value={props.deadlineAfterType}
            style={{ width: "100%" }}
          >
            {list.types.map((type) => (
              <SelectOption key={type.id} value={type.id}>
                {type.name}
              </SelectOption>
            ))}
          </Select>
        )}

        {props.clearable && (props.value.dateDeadlineUntil != null || props.value.dateDeadlineAfterTime != null) && (
          <IconButton
            size="large"
            onClick={() => props.onClearButtonCLick()}
            className="DeadlineSelect-removeButton ml-1"
          >
            <FiX />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default DeadlineSelectView;
