import React, { memo } from "react";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { InputNumber } from "../../../../uiKit";
import { useTranslation } from "react-i18next";
import { ICompanyUsersAccessLevel } from "./ICompanyUsersAccessLevel";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";

export const CompanyUsersAccessLevel = memo((props: ICompanyUsersAccessLevel) => {
  const { t } = useTranslation();

  const handleChangeAccessLevel = (value: ValueType | null) => {
    const limit = value ? value as number : null;
    props.onChange(props.item.accessLevel, limit)
  }

  return (
    <div
      key={props.item.accessLevel}
    >
      <SubheaderText text={t(`parse:user_limit_${props.item.accessLevel.toLowerCase()}`)} />
      <InputNumber
        className="full-width"
        placeholder={"∞"}
        min={0}
        value={props.item.limit ?? ""}
        onChange={handleChangeAccessLevel}
        // onChange={(value) =>
        //   props.setSettingsState({
        //     ...props.settingsState,
        //     userLimit: Number(value) == 0 ? null : Number(value)
        //   })
        // }
      />
    </div>
  )
});