import React, { useRef } from "react";
import "./OrgchartDrawerTabMetrics.scss";
import { Button, Card, Spin, Text } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { PagingInfo } from "../../../../../../hooks/usePaging";
import { MetricDto } from "../../../../../../api";
import { Metric } from "../../../metrics/Metric/Metric";
import ScrollTrigger from "../../../../../service/scrollTrigger/ScrollTrigger";
import DeleteMetricOrDashboardDialog from "../../../../dialogs/deleteMetricOrDashboardDialog/DeleteMetricOrDashboardDialog";
import { MetricCreateEditDialog } from "../../../../dialogs/metricCreateEditDialog/MetricCreateEditDialog";

interface IOrgchartDrawerTabMetricsViewProps {
  metricId: number;
  isCreateEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  metrics: {
    info: PagingInfo;
    items: MetricDto[];
  };

  onCreateEditDialogOpenChange: (isOpen: boolean) => void;
  onDeleteDialogOpenChange: (isOpen: boolean) => void;
  onMetricIdChange: (metricId: number) => void;
  onIsEditModeChange: (isEditMode: boolean) => void;
  onMetricCreateEdit: () => void;
  onMetricDelete: () => void;
  onLoadNext: () => void;
}

export const OrgchartDrawerTabMetricsView = (props: IOrgchartDrawerTabMetricsViewProps) => {
  const { t } = useTranslation();
  const resetRef = useRef<any>();

  const handleCreateDialogOpen = () => {
    props.onIsEditModeChange(false);
    // resetRef.current?.reset();
    props.onCreateEditDialogOpenChange(true);
  };

  return (
    <>
      {props.isCreateEditDialogOpen && (
        <MetricCreateEditDialog
          ref={resetRef}
          open={props.isCreateEditDialogOpen}
          toggleDialog={props.onCreateEditDialogOpenChange}
          editMode={props.isEditMode}
          onSuccess={props.onMetricCreateEdit}
          metricId={props.metricId}
          toggleEditMode={props.onIsEditModeChange}
          metrics={props.metrics.items}
        />
      )}

      <DeleteMetricOrDashboardDialog
        open={props.isDeleteDialogOpen}
        onOpen={props.onDeleteDialogOpenChange}
        onDelete={props.onMetricDelete}
        title={t("ui:title.delete_metric")}
        description={""}
      />
      <Spin
        spinning={props.isLoading || (props.metrics.info.isLoading && props.metrics.items.length == 0)}
        className="orgchart-function-drawer__tabs__loader"
      >
        {props.metrics.items.length > 0 ? (
          <>
            <div
              className="mt-n3 pb-3 d-flex flex-nowrap justify-space-between"
              style={{
                position: "sticky",
                top: "-12px",
                backgroundColor: "var(--color-layout-drawer)",
                zIndex: 1,
              }}
            >
              <Button onClick={handleCreateDialogOpen} variant="filled" children={t("ui:button.create")} />
            </div>
            <div className="d-stack-column spacing-2">
              {props.metrics.items.map((m) => {
                return (
                  <Metric
                    key={m.id}
                    name={m.nameFormatted || ""}
                    onEditClick={() => {
                      props.onMetricIdChange(m.id!);
                      props.onIsEditModeChange(true);
                      props.onCreateEditDialogOpenChange(true);
                    }}
                    onDeleteClick={() => {
                      props.onMetricIdChange(m.id!);
                      props.onDeleteDialogOpenChange(true);
                    }}
                  />
                );
              })}
              <ScrollTrigger
                disabled={props.metrics.info.isLoading}
                hidden={props.metrics.info.isDone}
                onIntersection={props.onLoadNext}
              />
            </div>
          </>
        ) : (
          <Card variant="secondary" isShadowed={false} className="orgchart-drawer-tab_metrics-group_card">
            <div className="pa-4 d-stack-row justify-space-between align-center">
              <Text size="16px" children={t("ui:title.create_first_metric")} />
              <Button onClick={handleCreateDialogOpen} variant="filled" children={t("ui:button.create")} />
            </div>
          </Card>
        )}
      </Spin>
    </>
  );
};
