import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AppPagesTabs } from "../../../../utils/appLinksAndTabs";
import { MenuItemDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import { removeTrailingSlash } from "../../../../helpers/pathname";
import { Card, TabPane, Tabs } from "../../../uiKit";
import clsx from "clsx";

export interface IPageTabs {
  tabs: MenuItemDto[];
  useTranslation?: boolean;
  isNewDesign?: boolean;
}

function PageTabs(props: IPageTabs) {
  const allTabs = AppPagesTabs;

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [url, setUrl] = useState(removeTrailingSlash(window.location.pathname));

  useEffect(() => {
    const currentPathName = removeTrailingSlash(pathname);
    if (currentPathName != url) setUrl(currentPathName);
  }, [pathname]);

  const tabsClasses = clsx(
    "page-content-sheet-tabs mb-2",
    {
      "page-content-sheet-tabs_new-style": props.isNewDesign,
    }
  )
  return (
    <Card
      className={tabsClasses}
    >
      <Tabs tabBarGutter={0} animated={false} activeKey={url} tabPosition="top" onClick={(d) => console.log(d)}>
        {props.tabs?.map((pTab: MenuItemDto & { link?: string }) => (
          <TabPane
            key={pTab.link ?? allTabs.find((tab) => tab.key == pTab.key)?.link}
            tab={
              <Link
                // onClick={(e) => e.stopPropagation()}
                to={pTab.link ?? allTabs.find((tab) => tab.key == pTab.key)?.link ?? "/"}
                children={props.useTranslation ? t(pTab.name ?? "") : pTab.name}
              />
            }
          />
        ))}
      </Tabs>
    </Card>
  );
}

export default observer(PageTabs);
