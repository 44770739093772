import React from "react";
import { Typography } from "antd";
import "./Text.scss";
import { TextProps } from "antd/lib/typography/Text";
import clsx from "clsx";

interface ITextView extends TextProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

const { Text } = Typography;

export function TextView({ size, weight, className, style, ...rest }: ITextView) {
  return (
    <Text
      className={clsx("uiKit-text", className ?? "")}
      style={{ fontWeight: weight, fontSize: size, ...style }}
      {...rest}
    />
  );
}
