import { IPeriod } from "../../../../elements/pickPeriodButton/PickPeriodButton.interface";
import React from "react";

export enum ConfigKeys {
  phoneNumber,
  userName,
  period,
}

export interface IFiltersContainerParams {
  phoneNumber?: string;
  userName?: string;
  period?: IPeriod;
}

export interface IFiltersContainer {
  params: IFiltersContainerParams;
  config: ConfigKeys[];
  isLoading: boolean;
  onChange: React.Dispatch<React.SetStateAction<IFiltersContainerParams>>;
  onRefresh: () => void;
}

export interface IFiltersContainerView extends Omit<IFiltersContainer, "onChange" | "onRefresh"> {
  onChangePhone: (value: string) => void;
  onChangeName: (value: string) => void;
  onChangePeriod: (value: IPeriod | undefined) => void;
  onRefresh: () => void;
}
