import React, { useState } from "react";
import EmployeeMainCardView from "./EmployeeMainCardView";
import { UserDto } from "../../../../../api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useClaims, useRootStore } from "../../../../../hooks";
import { permissionKeys } from "../../../../../utils/permissions";
import { PopupMenuItemTypes } from "../../../../service/dropdownMenu/dropdownMenu.interface";
import { observer } from "mobx-react-lite";

export interface IEmployeeCardMain {
  employeeData: UserDto;
  onChangeAccessType: (userId: number, type: number) =>  void;
  onDelete(userId: number): void;
}

function EmployeeMainCard(props: IEmployeeCardMain) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const claims = useClaims();
  const { authStore } = useRootStore();
  const [isCardLoading, setIsCardLoading] = useState(false);

  const handleOpenClick = () => {
    setIsCardLoading(true);
    navigate("/employee/" + props.employeeData.id);
  };
  const handleEditClick = () => {
    setIsCardLoading(true);
    navigate("/employee/" + props.employeeData.id + "/edit");
  };
  const handleDeleteClick = () => {
    props.onDelete(props.employeeData.id as number);
  };

  const menuItems: PopupMenuItemTypes[] = [
    { id: 0, text: t("common:menu.employee_card.open"), action: handleOpenClick },
    {
      id: 1,
      text: t("common:menu.employee_card.edit"),
      hidden: !claims.has(permissionKeys.user.edit),
      action: handleEditClick,
    },
    {
      id: 2,
      hidden:
        !claims.has(permissionKeys.user.delete) || authStore.getInitialInfo?.identity?.id == props.employeeData.id,
      // hidden: !claims.has(permissionKeys.user.delete),
      text: t("common:menu.employee_card.delete"),
      action: handleDeleteClick,
    },
  ];

  const handleCardClick = () => {
    setIsCardLoading(true);
    navigate("/employee/" + props.employeeData.id);
  };

  return (
    <EmployeeMainCardView
      {...props}
      menuItems={menuItems}
      isCardLoading={isCardLoading}
      handleCardClick={handleCardClick}
      isDisabledChangeAccessType={!claims.has(permissionKeys.user.edit)}
    />
  );
}

export default observer(EmployeeMainCard);
