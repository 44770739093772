import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IssueDto, IssueScheduleDto, type IssueShortDto, PlanDto } from "../../../../../../api";
import { IssueQueryKeys } from "../../../../../../constants/issueTypeKeys";
import { useApi, useDateHelpers, useNotifier, useRootStore } from "../../../../../../hooks";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { IPlanningPageMyPlan } from "./PlanningPageMyPlan.interface";
import "./PlanningPageMyPlan.scss";
import PageContent from "../../../../layouts/pageContent/PageContent";
import { PlanningPageMainContent } from "../../contents/planningPageMainContent/PlanningPageMainContent";
import { PlanningPageMyPlanSidebar } from "../../contents/planningPageMyPlanSidebar/PlanningPageMyPlanSidebar";
import { useTranslation } from "react-i18next";
import {
  PlaningFilterValue
} from "../../contents/planningPageMyPlanSidebar/PlanningPageMyPlanSidebar.interface";
import dayjs from "dayjs";

export const PlanningPageMyPlan = (props: IPlanningPageMyPlan) => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const notifier = useNotifier();

  const { formatTimeSpan } = useDateHelpers();

  const userId = authStore.getInitialInfo?.identity?.id;

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const handleToggleSidebarOpen = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, [])

  const [currentPlanId, setCurrentPlanId] = useState<number | undefined>(undefined);

  const restartPlanHistory = useRef<{ restart: () => void }>();

  const plansShort = usePagingWithController(
    api.planShort,
    { userId: userId, orderBy: "date_created", orderByType: "desc" },
    { pageSize: 10 }
  );

  const currentPlan = useApi<PlanDto | null>(
    () => api.plan.getById(currentPlanId!, {
      userId,
      attachCanBeMigratedFromPlanId: true,
      withPlannedIssues: true,
    }),
    null,
    () => {},
    {},
    false,
  );

  const plan = useMemo<PlanDto | undefined>(() => {
    if (!currentPlan.value) {
      return undefined;
    }
    const { isCurrent, dateApprovedAt, dateTo } = currentPlan.value ?? {};
    const currentDate = dayjs(new Date());
    const diff = currentDate.diff(dayjs(dateTo), "day");

    const canBeMigratedFromPlan = isCurrent ? !dateApprovedAt : (diff < 0 && !dateApprovedAt);
    console.log("canBeMigratedFromPlan", canBeMigratedFromPlan);
    console.log("currentPlan.value", currentPlan.value);
    return {
      ...currentPlan.value,
      canBeMigratedFromPlan,
    }
  }, [currentPlan.value]);

  const fetchPlan = useCallback(() => {
    return currentPlan.fetch();
  }, [currentPlanId]);

  // const currentPlanId = useMemo(() => {
  //   return currentPlan.value?.id ?? null;
  // }, [currentPlan.value?.id])

  const filterList = useMemo(() => ([
    {
      value: PlaningFilterValue.unfinishedTasks,
      text: t("ui:subheader.unclosed_tasks"),
    },
    {
      value: PlaningFilterValue.unallocatedTasks,
      text: t("ui:subheader.unallocated_tasks"),
    },
  ]), []);

  const [filter, setFilter] = useState<PlaningFilterValue>(PlaningFilterValue.unallocatedTasks);
  const handleChangeFilter = useCallback((value: PlaningFilterValue) => {
    setFilter(value);
  }, []);


  const incompleteIssues = usePagingWithController(
    api.plan,
    {},
    {},
    undefined,
    undefined,
    undefined,
    undefined,
    "incomplete-issues",
  );

  const incompleteIssuesList = useMemo(() => {
    const { items = [] } = incompleteIssues ?? {};
    return items as IssueDto[];
  }, [incompleteIssues.items]);

  const unassignedIssues = usePagingWithController(
    api.plan,
    {},
    {},
    undefined,
    undefined,
    undefined,
    undefined,
    "unassigned-issues",
  );

  useEffect(() => {
    const list = filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues
      : unassignedIssues;
    const { info, items, restart } = list
      ? incompleteIssues
      : unassignedIssues;

    if (!items.length && !info.isDone) {
      restart();
    }
  }, [filter]);

  const unassignedIssuesList = useMemo(() => {
    const { items = [] } = unassignedIssues ?? {};
    return items as IssueDto[];
  }, [unassignedIssues.items]);


  const issuesList = useMemo(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssuesList
      : unassignedIssuesList;
  }, [filter, incompleteIssuesList, unassignedIssuesList]);

  const isLoading = useMemo(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.info.isLoading
      : unassignedIssues.info.isLoading
  }, [filter, incompleteIssues.info.isLoading, unassignedIssues.info.isLoading]);

  const isDone = useMemo(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.info.isDone
      : unassignedIssues.info.isDone
  }, [filter, incompleteIssues.info.isDone, unassignedIssues.info.isDone]);

  const totalTimePlan: string = useMemo(() => {
    const plannedTime = filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.info.timePlanTotal
      : unassignedIssues.info.timePlanTotal
    if (!plannedTime || plannedTime === "00:00:00") return `0${t("parse:date_helper.hour")}`;
    return formatTimeSpan(plannedTime);
  }, [filter, incompleteIssues.info.timePlanTotal, unassignedIssues.info.timePlanTotal]);

  const totalItems = useMemo(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.info.totalItems ?? 0
      : unassignedIssues.info.totalItems ?? 0;
  }, [filter, incompleteIssues.info.totalItems, unassignedIssues.info.totalItems]);


  const handleRestart = useCallback(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.restart(true)
      : unassignedIssues.restart(true);
  }, [filter]);

  const handleLoadNext = useCallback(() => {
    return filter === PlaningFilterValue.unfinishedTasks
      ? incompleteIssues.loadNext()
      : unassignedIssues.loadNext();
  }, [filter]);

  const handleOpenCreateIssueDialog = useCallback(() =>
    navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` })
    , []);

  const handlePlanIdChange = useCallback((planId: number) => {
    setCurrentPlanId(planId);
    // setIsLoading(true);
  }, []);

  useEffect(() => {
    plansShort.restart();
  }, []);

  useEffect(() => {
    if (plansShort.items.length) {
      setCurrentPlanId(plansShort.items.find((i) => i.isCurrent)?.id);
    }
    return () => setCurrentPlanId(undefined);
  }, [plansShort.items]);

  useEffect(() => {
    currentPlan.fetch();
  }, [currentPlanId]);

  useEffect(() => {
    if (currentPlan.value) {
      handleRestart();
      // issues.fetch();
      // scheduleIssues.restart(true);
      restartPlanHistory.current?.restart && restartPlanHistory.current?.restart();
    }
  }, [currentPlan.value, currentPlan.value?.id]);

  const handleAddIssueToPlan = useCallback(async (issueId: number) => {
    if (currentPlanId) {
      // setLoadingTaskId(issueId);
      const resp = await api.plan.addedIssueToPlan(currentPlanId, issueId);
      if (resp !== null) {
        notifier.show({
          message: t("notifier:success.issue_added"),
          theme: "success",
        });
        // handleLoadRestart();
      } else {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
      }
      return resp !== null;
      //     ...props.plan,
      //     issues: handleGetArray(),
      //   });
    }
    return false;
  }, [currentPlanId]);



  return (
    <div
      className="d-stack-row ml-n2 my-n2 full-height planning-page"
      style={{ overflow: "hidden", width: "calc(100% + 8px)", height: "calc(100% + 16px)" }}
    >
      <div className={isSidebarOpen ? "sidebar-visible" : "sidebar-hidden"}>
        <PlanningPageMyPlanSidebar
          isSidebarOpen={isSidebarOpen}
          onSidebarOpenChange={handleToggleSidebarOpen}
          issues={issuesList}
          isDone={isDone}
          onAddIssueToPlan={handleAddIssueToPlan}
          isLoading={isLoading}
          planId={currentPlanId}
          totalTimePlan={totalTimePlan}
          totalItems={totalItems}
          isActiveFilter={filter === PlaningFilterValue.unallocatedTasks}
          // fetchPlans={currentPlan.fetch}
          fetchPlans={fetchPlan}
          issuesFromCommunicationsRestart={handleRestart}
          // issuesRestart={issues.fetch}
          // issuesRestart={issuesRestart}
          loadNext={handleLoadNext}
          filterList={filterList}
          filter={filter}
          onChangeFilter={handleChangeFilter}
        />
      </div>
      <div className="flex-grow-1 d-flex flex-column pb-2 plan-container">
        <PageContent isLoading={
          plansShort.info.isLoading ||
          (currentPlan.isLoading && !plan?.plannedIssues?.length)/*|| issues.info.isLoading */
        }>
          <PlanningPageMainContent
            ref={restartPlanHistory}
            onOpenCreateIssueDialog={handleOpenCreateIssueDialog}
            onAddIssueToPlan={handleAddIssueToPlan}
            plan={plan}
            issues={plan?.plannedIssues ?? []}
            googleIssues={plan?.plannedGoogleEvents ?? []}
            // isLoadingIssue={issues.info.isLoading}
            // scheduleIssues={scheduleIssues.items}
            fetchPlans={fetchPlan}
            issuesFromCommunicationsRestart={handleRestart}
            // issuesRestart={issuesRestart}
            fetchApprovalPlans={props.fetchApprovalPlans}
            onPlanIdChange={handlePlanIdChange}
            isLoading={plansShort.info.isLoading || (currentPlan.isLoading && !plan?.plannedIssues?.length)}
            isSidebarOpen={isSidebarOpen}
            onSidebarOpenChange={handleToggleSidebarOpen}
            userId={userId}
            plansShort={plansShort.items}
            currentPlanId={currentPlanId}
          />
        </PageContent>
      </div>
    </div>
  );
};
