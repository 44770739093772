import React from "react";
import "./OrgchartSaveTemplateDialog.scss";
import { ICreateCompanyGroupDialog } from "./OrgchartSaveTemplateDialog.interface";
import { OrgchartSaveTemplateDialogView } from "./OrgchartSaveTemplateDialogView";

export const OrgchartSaveTemplateDialog = (props: ICreateCompanyGroupDialog) => {
  return (
    <OrgchartSaveTemplateDialogView
      onClose={props.onClose}
      open={props.open}
    />
  );
};
