import React from "react";
import "./Chip.scss";
import clsx from "clsx";

type TChipColorScheme = "success" | "error" | "warning" | "info" | "primary" | "default";

export interface IChip {
  type?: TChipColorScheme;
  label?: string | number | null;
  className?: string;
  showZero?: boolean;
  style?: React.CSSProperties;
}

export const Chip = (props: IChip) => {
  return props.label &&
    typeof props.label == "number" &&
    props.label == 0 &&
    (!props.showZero || props.showZero == null) ? null : (
    <div style={props.style} className={clsx("uiKit-chip", "__" + (props.type ?? "primary"), props.className ?? "")}>
      <span>{props.label}</span>
    </div>
  );
};
