import React from "react";

export const BookIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="29"
      height="30"
      className={className}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2188 9.77539H23.5625V11.5879H17.2188V9.77539ZM17.2188 14.3066H23.5625V16.1191H17.2188V14.3066ZM17.2188 18.8379H23.5625V20.6504H17.2188V18.8379ZM5.4375 9.77539H11.7812V11.5879H5.4375V9.77539ZM5.4375 14.3066H11.7812V16.1191H5.4375V14.3066ZM5.4375 18.8379H11.7812V20.6504H5.4375V18.8379Z"
        fill="#AFB4C9"
      />
      <path
        d="M25.375 5.24414H3.625C3.14444 5.24462 2.6837 5.43573 2.3439 5.77554C2.00409 6.11535 1.81298 6.57608 1.8125 7.05664V23.3691C1.81298 23.8497 2.00409 24.3104 2.3439 24.6502C2.6837 24.99 3.14444 25.1812 3.625 25.1816H25.375C25.8556 25.1812 26.3163 24.99 26.6561 24.6502C26.9959 24.3104 27.187 23.8497 27.1875 23.3691V7.05664C27.187 6.57608 26.9959 6.11535 26.6561 5.77554C26.3163 5.43573 25.8556 5.24462 25.375 5.24414ZM3.625 7.05664H13.5938V23.3691H3.625V7.05664ZM15.4062 23.3691V7.05664H25.375V23.3691H15.4062Z"
        fill="#AFB4C9"
      />
    </svg>
  );
};
