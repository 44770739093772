import React from "react";
import "./Table.scss";
import { Table, TableProps } from "antd";
import clsx from "clsx";

interface ITableView extends TableProps<any> {
  isNewStyle?: boolean;
}

export function TableView({ className, isNewStyle, ...rest }: ITableView) {
  return <Table className={
    clsx("uiKit-table", {
      "uiKit-table_new-style" : isNewStyle,
    }, className ?? "")
  } {...rest} />;
}
