import React, { useEffect, useRef, useState } from "react";
import { ModuleIntervalListView } from "./ModuleIntervalListView";
import { useClaims, useRootStore } from "../../../../../hooks";
import { CompanyDto, CompanyIntervalDto } from "../../../../../api";
import { observer } from "mobx-react-lite";
import { api } from "../../../../../services";

interface IModuleIntervalListProps {
  //
}

const ModuleIntervalListObserved = (props: IModuleIntervalListProps) => {
  const { authStore } = useRootStore();
  const claims = useClaims();
  const companyId = authStore.getInitialInfo?.identity?.currentCompanyId;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companyIntervals, setCompanyIntervals] = useState<CompanyIntervalDto[]>([]);
  const [companyData, setCompanyData] = useState<CompanyDto | null>(null);
  const [isIntervalDialogOpen, setIsIntervalDialogOpen] = useState<boolean>(false);
  const [intervalToEdit, setIntervalToEdit] = useState<CompanyIntervalDto | null>(null);
  const emptyInterval = useRef<CompanyIntervalDto>({
    id: 0,
    companyId: companyId ?? undefined,
    name: "",
    dateStart: new Date().toISOString(),
    day: 1,
    week: 0,
    month: 0,
    year: 0,
  });

  const getCompanyData = async () => {
    setIsLoading(true);
    const r = await api.company.getById(companyId as number);
    setIsLoading(false);
    if (r == null) {
      return;
    }
    setCompanyIntervals(r.intervals?.sort((a, b) => a.id! - b.id!) ?? []);
    setCompanyData(r);
  };

  const handleIntervalAdd = () => {
    setIntervalToEdit(emptyInterval.current);
    // setCompanyIntervals((i) => [...i, emptyInterval]);
    setIsIntervalDialogOpen(true);
  };

  const handleIntervalChange = (interval: CompanyIntervalDto) => {
    setIntervalToEdit({ ...intervalToEdit, ...interval });
  };

  const handleIntervalSave = async () => {
    if (companyId == null || companyData == null || intervalToEdit == null) return;
    setIsLoading(true);
    const r = await api.company.edit(companyId as number, {
      ...(companyData as CompanyDto),
      intervals: companyIntervals.some((i) => i.id == intervalToEdit.id)
        ? companyIntervals.map((i) => (i.id == intervalToEdit.id ? intervalToEdit : i))
        : [...companyIntervals, intervalToEdit],
    });
    setIsLoading(false);
    if (r == null) {
      return;
    }
    setCompanyIntervals(r.intervals?.sort((a, b) => a.id! - b.id!) ?? []);
    setCompanyData(r);
    setIsIntervalDialogOpen(false);
    setIntervalToEdit(null);
    await authStore.refreshInitialInfo();
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <ModuleIntervalListView
      isLoading={isLoading}
      isIntervalDialogOpen={isIntervalDialogOpen}
      intervalToEdit={intervalToEdit}
      companyIntervals={companyIntervals}
      setIsIntervalDialogOpen={setIsIntervalDialogOpen}
      setIntervalToEdit={setIntervalToEdit}
      onIntervalAdd={handleIntervalAdd}
      onIntervalChange={handleIntervalChange}
      onIntervalSave={handleIntervalSave}
    />
  );
};

export const ModuleIntervalList = observer(ModuleIntervalListObserved);
