import React from "react";
import { Divider, Icon, IconButton, Text, Tooltip } from "../../../../../../../uiKit";
import { FiMenu } from "react-icons/fi";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface DashboardCollapseListItemProps {
  id?: number;
  name?: string | null;
  uri?: string;
  order: number | null;
  onRedirectToPlan: (id?: number) => void;
}

export const DashboardCollapseListItem = (props: DashboardCollapseListItemProps) => {
  const { id, uri, name, order, onRedirectToPlan } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: order as number,
    animateLayoutChanges: () => false,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Tooltip overlayStyle={{ pointerEvents: "none" }} title={name} align={{ offset: [8, 0] }} placement="right">
        <div
          className={`pa-1 collapse__item ${Number(uri) == id ? "__is_active" : ""} d-flex align-center `}
          onClick={() => onRedirectToPlan(id)}
        >
          <IconButton
            {...attributes}
            {...listeners}
            className="flex-shrink-0"
            style={{ cursor: "grab" }}
            children={<Icon component={() => <FiMenu />} />}
          />
          <Text size="12px" children={name} className={"dashboard-item-text"} />
        </div>
        <Divider />
      </Tooltip>
    </div>
  );
};
