import React from "react";
import { Button, Dialog } from "../../../uiKit";
import { useTranslation } from "react-i18next";
import { IListMessengersBotViewDialog } from "./ListMessengersBotDialog.interface";
import { MessengerCard } from "./components/messengerCard/MessengerCard";
import { EmptyBlock } from "../../layouts/emptyBlock/EmptyBlock";
import { useLocation, useNavigate } from "react-router-dom";

export const ListMessengersBotViewDialog = (props: IListMessengersBotViewDialog) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirectToAddBot = () => navigate(pathname + `/add-bot`);
  // list of bots
  return (
    <Dialog title={t("ui:title.list_bots")} open={props.isOpen} onClose={props.onClose}>
      <div style={{ height: "400px" }}>
        {(props.messangers?.length ?? 0) > 0 ? (
          props.messangers?.map((item) => (
            <MessengerCard
              onClick={props.onActivateBot}
              config={JSON.parse(item.configs!)}
              isActivated={item.isActive}
              type={item.type}
              key={item.id}
              id={item.id}
            />
          ))
        ) : (
          <EmptyBlock
            description={
              <div>
                <p>{t("text:no_data")}</p>
                <Button onClick={handleRedirectToAddBot}>{t("ui:button.add")}</Button>
              </div>
            }
          />
        )}
      </div>
    </Dialog>
  );
};
