import React from "react";
import Menu from "antd/lib/menu";
import { MenuItemProps } from "antd";
import "./MenuItem.scss";

interface IMenuItem extends MenuItemProps {
  //
}

const { Item } = Menu;

export function MenuItem(props: IMenuItem) {
  return <Item {...props} />;
}
