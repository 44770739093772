import React from "react";
import { ButtonProps } from "antd/lib/button";
import { IconButtonView } from "./IconButtonView";
import { b } from "@fullcalendar/core/internal-common";

interface IIconButton extends ButtonProps {
  // TODO: Add 'outlined' type
  isActiveFilter?: boolean;
  isShowBorder?: boolean;
}

export function IconButton(props: IIconButton) {
  return <IconButtonView {...props} />;
}

// Фикс для тултипа, когда props.disabled == true
IconButton.__ANT_BUTTON = true;
