import React from "react";
import { useTranslation } from "react-i18next";
import "./EmployeeInviteShowDialog.scss";
import { FiKey } from "@react-icons/all-files/fi/FiKey";
import { FiCopy } from "@react-icons/all-files/fi/FiCopy";
import { FiLink2 } from "@react-icons/all-files/fi/FiLink2";
import { CompanyInviteDto } from "../../../../api";
import { Button, Dialog, DialogActions, Icon } from "../../../uiKit";

export interface IEmployeeInviteShowDialogView {
  open: boolean;
  inviteData: CompanyInviteDto;

  handleCodeCopy(code: string): void;

  handleLinkCopy(link: string): void;

  onClose(): void;
}

function EmployeeInviteShowDialogView(props: IEmployeeInviteShowDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.invite")} open={props.open} onClose={props.onClose} closable={false}>
      <div className="d-stack-row justify-space-between align-center px-3">
        <div className="d-stack-row align-center">
          <div
            className="d-stack-column justify-center mr-4"
            style={{ backgroundColor: "#bdbdbd", borderRadius: "50%", padding: "8px" }}
          >
            <FiKey size={20} style={{ stroke: "var(--color-white)" }} />
          </div>
          <div>
            <h3 className="ma-0">
              {!!props.inviteData.code?.length
                ? props.inviteData.code?.toUpperCase()
                : t("ui:label.reusable_invite_code")}
            </h3>
            <p className="ma-0" style={{ color: "var(--color-gray-strong)" }}>
              {!!props.inviteData.email?.length ? props.inviteData.email : t("ui:label.reusable_invite_code")}
            </p>
          </div>
        </div>
        <div className="d-stack-row align-center">
          <Button
            size="large"
            variant="text"
            className="employee-invite-dialog_button"
            onClick={() => props.handleCodeCopy(props.inviteData?.code?.toString().toUpperCase() ?? "")}
            icon={<Icon component={() => <FiCopy />} />}
          />
          <Button
            size="large"
            variant="text"
            className="employee-invite-dialog_button"
            onClick={() => props.handleLinkCopy(props.inviteData.url ?? "")}
            icon={<Icon component={() => <FiLink2 />} />}
          />
        </div>
      </div>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="filled">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeInviteShowDialogView;
