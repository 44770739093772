import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dialog, DialogActions, InputNumber, Switch } from "../../../uiKit";
import { TChartSettings } from "../../pages/dashboards/components/Metric2Dashboard/MetricToDashboard";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";

interface IMetricSettingsView {
  open: boolean;
  title?: string;
  onSuccess: () => void;
  chartSettings: TChartSettings;
  // chartSettingsFromLS: TChartSettings;
  onChartSettingsChange: (value: TChartSettings) => void;

  onOpen(isOpen: boolean): void;
}

export const MetricSettingsDialogView = memo((props: IMetricSettingsView) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    props.onChartSettingsChange(props.chartSettings);
    props.onOpen(false);
  }, [props]);

  const handleSuccess = useCallback(() => {
    props.onSuccess();
    props.onOpen(false);
  }, [props]);

  const handleMinValueChange = useCallback(
    (value: string | number | null) => {
      props.onChartSettingsChange({ ...props.chartSettings, min: value === null ? null : Number(value) });
    },
    [props]
  );

  const handleMaxValueChange = useCallback(
    (value: string | number | null) => {
      props.onChartSettingsChange({ ...props.chartSettings, max: value === null ? null : Number(value) });
    },
    [props]
  );

  return (
    <Dialog width={450} open={props.open} onClose={handleClose} title={props.title} closable={false}>
      <div className="d-stack-column">
        <SubheaderText text={t("ui:subheader.metric_settings")} />
        <div className="d-stack-row spacing-2">
          <InputNumber
            value={props.chartSettings.min ?? null}
            onChange={(v) => handleMinValueChange(v)}
            type="number"
            className="full-width"
            placeholder={t("ui:placeholder.min")}
          />
          <InputNumber
            value={props.chartSettings.max ?? null}
            onChange={(v) => handleMaxValueChange(v)}
            type="number"
            className="full-width"
            placeholder={t("ui:placeholder.max")}
          />
        </div>
        <div className="my-2" />
        <Switch
          checked={props.chartSettings.isTrendActive}
          onChange={(v) =>
            props.onChartSettingsChange({
              ...props.chartSettings,
              isTrendActive: v,
            })
          }
          label={t("parse:trend_line")}
        />
        <div className="my-2" />
        <Switch
          checked={props.chartSettings.isLinesColorActive}
          onChange={(v) =>
            props.onChartSettingsChange({
              ...props.chartSettings,
              isLinesColorActive: v,
            })
          }
          label={t("parse:line_color")}
        />
        <div className="my-2" />
        <Checkbox
          checked={props.chartSettings.isCompany}
          onChange={(e) => {
            console.log(e);
            props.onChartSettingsChange({
              ...props.chartSettings,
              isCompany: e.target.checked,
            })
          }}
        >
          {t("parse:apply_to_company")}
        </Checkbox>
      </div>
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button type="primary" onClick={handleSuccess} variant="filled">
          {t("ui:button.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
