import React from "react";
import { IUserReactionCard } from "../interfaces/Reactions.interface";
import { UsersTooltip } from "./UsersTooltip";
import clsx from "clsx";

export const UserReactionCard: React.FC<IUserReactionCard> = (props) => {
  const backgroundColor = props.isNewStyle
    ? "var(--color-primary-base)" : "var(--color-layout-fill-tertiary)";


  return (
    <UsersTooltip users={props.reactionChip.userNames}>
      <div
        style={{
          backgroundColor: props.disabled ? "var(--color-layout-fill-quaternary)" : backgroundColor,
          cursor: props.disabled ? "not-allowed" : "default",
          fontSize: props.iconSize ? props.iconSize * 0.71 : 12,
        }}
        onClick={() => !props.disabled && props.onClickByReaction(props.reactionChip.emoji)}
        className={clsx("d-stack-row justify-space-between spacing-2 align-center user-reaction-chip ma-1",
          {
            "user-reaction-chip_new-style": props.isNewStyle,
          })
        }
      >
        <span
          className={clsx("reaction-symbol", {
            "reaction-symbol_new-style": props.isNewStyle
          })}
        >
          {props.reactionChip.emoji}
        </span>
        <span>{props.reactionChip.usersCount}</span>
      </div>
    </UsersTooltip>
  );
};
