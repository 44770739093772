import React, { memo } from "react";
import { UpdateMetricDialogView } from "./UpdateMetricDialogView";
import { TDashboardChartDataItem } from "../../pages/dashboards/dashboardChart/DashboardChart";

interface IUpdateMetricDialog {
  value: TDashboardChartDataItem | null;
  onChangeCurrentMetricData: (value: TDashboardChartDataItem | null) => void;
  onUpdateClick: () => void;
  onToggleDialogVisibility: (value: boolean) => void;
  open: boolean;
  metricData: {
    name: string | null;
    date: string | null;
    isQuotaAvailable: boolean;
  };
}

export const UpdateMetricDialog = memo((props: IUpdateMetricDialog) => {
  return <UpdateMetricDialogView {...props} />;
});
