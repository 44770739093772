import React, { memo, useCallback } from "react";
import {
  IDropdownContentElement, IFilterPlaningSideBarDropdownContent
} from "./PlaningSidebarFilterDropdownContent.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import {  Switch } from "../../../../../uiKit";
import "./PlaningSidebarFilterDropdownContent.scss";
import { PlaningFilterValue } from "../planningPageMyPlanSidebar/PlanningPageMyPlanSidebar.interface";

export const PlaningSidebarFilterDropdownContent = memo((props: IFilterPlaningSideBarDropdownContent) => {
  const { t } = useTranslation();

  const handleChangeFilter = (value: PlaningFilterValue) => {
    props.onChangeFilter(value);
  }

  const unclosedTasks= (
    <>
      <Switch
        disabled={props.isLoading}
        label={t("ui:subheader.unclosed_tasks")}
        checked={props.activeFilter === PlaningFilterValue.unfinishedTasks}
        isHiddenCheckedChildren={true}
        onChange={() => handleChangeFilter(PlaningFilterValue.unfinishedTasks)}
      />
    </>
  );

  const unallocatedTasks= (
    <>
      <Switch
        disabled={props.isLoading}
        label={t("ui:subheader.unallocated_tasks")}
        checked={props.activeFilter === PlaningFilterValue.unallocatedTasks}
        isHiddenCheckedChildren={true}
        onChange={() => handleChangeFilter(PlaningFilterValue.unallocatedTasks)}
      />
    </>
  );

  const elements: IDropdownContentElement[] = [
    {
      id: 1,
      name: "unfinished_tasks",
      component: <div className="calendar-sidebar-filter-content__item" key={1}>{unclosedTasks}</div>,
    },
    {
      id: 2,
      name: "overdueDeadline",
      component: <div className="calendar-sidebar-filter-content__item" key={2}>{unallocatedTasks}</div>,
    },
  ];

  return (
    <div
      className="d-stack-column pa-2 planing-sidebar-filter-content"
      style={{ width: "300px" }}
    >
      <SubheaderText
        text={t("ui:subheader.calendar_sidebar_filter")}

      />
      {elements.map((item) => item.component)}
    </div>
  );
});