import React from "react";
import { getLocaleStringByNumber } from "../../../../../../helpers/localeFunctions";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../../uiKit";
import { IColumnsSelect } from "./ColumnsSelect.interface";

export const ColumnsSelect = (props: IColumnsSelect) => {
  const { t } = useTranslation();

  const columnsSelectItems = Array(...Array(4).keys()).map((v) => ({
    id: v,
    value: v + 1,
    text: `${v + 1} ${t("parse:column_amount." + getLocaleStringByNumber(v + 1))}`,
  }));

  return (
    <Select
      value={props.value ?? 3}
      onChange={props.onChange}
      variant={props.variant}
      size={props.size}
      items={columnsSelectItems}
    />
  );
};
