import React, { memo, useMemo } from "react";
import { IssueActivityUser } from "../issueActivityUser/issueActivityUser";
import { IIssueActivityUserRow } from "./IssueActivityUserRow.interface";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./issueActivityUserRow.scss";

export const IssueActivityUserRow = memo((props: IIssueActivityUserRow) => {
  const { t } = useTranslation();

  const prevUserBackground = useMemo(() => {
    return props.prevUser ? "var(--color-calendar-default1)" : undefined
  }, [props.prevUser]);

  const currentUserBackground = useMemo(() => {
    return props.prevUser ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)"
  }, [props.prevUser]);

  return (
    <div className={"issue-activity-user-row"}>
      {props.prevUser
        ? (
          <IssueActivityUser
            user={props.prevUser}
            background={prevUserBackground}
          />
        )
        : (
          <IssueActivityText
            text={t("parse:no")}
            isInlineText
          />
        )
      }
      <Icon
        component={() => <ChevronRight size={16} />}
      />
      {props.user
        ? (
          <IssueActivityUser
            user={props.user}
            background={currentUserBackground}
          />
        )
        : (
          <IssueActivityText
            text={t("parse:no")}
            isInlineText
          />
        )
      }
    </div>
  )
});
