import React, { useEffect } from "react";
import { ICardRegulation } from "./CardRegulation.interface";
import { CardRegulationView } from "./CardRegulationView";
import { useNavigate } from "react-router-dom";

export const CardRegulation: React.FC<ICardRegulation> = (props) => {
  const navigate = useNavigate();
    // ?contentType=${props.regulationContentType}
  const handleRedirectToRegulationView = () =>
    props.onClick
      ? props.onClick(props.regulation.id ?? props.regulation.regulationId!)
      : navigate(`/policy/all/${props.regulation.id ?? props.regulation.regulationId}/`);

  return <CardRegulationView {...props} onClick={handleRedirectToRegulationView} />;
};
