import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./LoginForm.scss";
import { Form } from "antd";
import { Button, Input, InputPassword } from "../../../uiKit";

interface ILoginFormView {
  isLoading: boolean;
  handleLogin: ({ login, password }: { login: string; password: string }) => void;
}

function LoginFormView(props: ILoginFormView) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Form
      className="full-width"
      validateTrigger="onSubmit"
      requiredMark={false}
      autoComplete="on"
      layout="vertical"
      onFinish={(values) => {
        props.handleLogin({ login: values.login, password: values.password });
      }}
    >
      <Form.Item
        name="login"
        label={t("ui:subheader.email")}
        rules={[
          { required: true, message: t("validation:field_required") },
          { type: "email", message: t("validation:wrong_email") },
        ]}
        normalize={(value) => value.trim()}
      >
        <Input name="login" size="large" placeholder={t("ui:placeholder.email")} />
      </Form.Item>
      <Form.Item
        name="password"
        label={t("ui:subheader.password")}
        rules={[
          { required: true, message: t("validation:field_required") },
          { min: 6, message: t("validation:min_char_number", { number: 6 }) },
        ]}
      >
        <InputPassword name="password" size="large" placeholder={t("ui:placeholder.password")} />
      </Form.Item>

      <Form.Item className="mt-6">
        <div className="d-flex">
          <Button size="large" variant="outlined" onClick={() => navigate("/signup")}>
            {t("ui:button.signup")}
          </Button>
          <Button
            className="ml-4 flex-grow-1"
            loading={props.isLoading}
            htmlType="submit"
            size="large"
            variant="filled"
          >
            {t("ui:button.login")}
          </Button>
        </div>
      </Form.Item>
      <Form.Item>
        <Link className="forgot_password_link" to="/restore-password">
          {t("ui:button.forgot_password")}
        </Link>
      </Form.Item>
    </Form>
  );
}

export default LoginFormView;
