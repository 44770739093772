import React from "react";
import { FiLink } from "@react-icons/all-files/fi/FiLink";
import { Divider, Text, TextArea } from "../../../../uiKit";

export function UiTabTextArea() {
  return (
    <div className="my-3">
      <div className="d-flex align-center">
        <FiLink className="mr-1" color="var(--color-primary-strong)" />
        <a target="_blank" href="https://ant.design/components/input/">
          Документация AntD
        </a>
      </div>
      <Divider className="my-3" />
      <div>
        <Text style={{ fontSize: "16px" }}>Кастомные пропсы</Text>
        <div>
          <div className="d-stack-column spacing-1">
            <span>
              <span style={{ fontWeight: "bold" }} children="readOnly" /> - read-only
            </span>
          </div>
        </div>
        <Divider className="my-3" />
        <div className="d-stack-column spacing-2 uiKitPage-page-container">
          <TextArea placeholder="default" />
          <TextArea readOnly placeholder="readOnly" />
        </div>
      </div>
    </div>
  );
}
