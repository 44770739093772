import React, { useRef, useState } from "react";
import { IntervalSettingsDialogView } from "./IntervalSettingsDialogView";
import { CompanyIntervalDto } from "../../../../../api";
import { formatDateToDateString } from "../../../../../helpers/formatFunctions";

export interface IIntervalSettingsDialogProps {
  isOpen: boolean;
  isReadOnly: boolean;
  isLoading: boolean;
  interval: CompanyIntervalDto | null;
  intervalArray: CompanyIntervalDto[];

  onSave: () => void;
  onChange: (value: CompanyIntervalDto) => void;
  onClose: () => void;
}

export const IntervalSettingsDialog = (props: IIntervalSettingsDialogProps) => {
  const [isChangeWarningDialogOpen, setIsChangeWarningDialogOpen] = useState<boolean>(false);
  const intervalBackup = useRef<CompanyIntervalDto | null>(props.interval);

  const checkIsSaveNotAvailable = (): boolean => {
    return props.interval?.day == 1
      ? props.intervalArray.some(
          (i) =>
            i.day == props.interval?.day &&
            i.week == props.interval?.week &&
            i.month == props.interval?.month &&
            i.year == props.interval?.year &&
            props.interval?.dateStart != null &&
            i.dateStart != null &&
            // formatDateToDateString(new Date(i.dateStart)) == formatDateToDateString(new Date(interval?.dateStart)) &&
            i.id != props.interval?.id
        )
      : props.intervalArray.some(
          (i) =>
            i.day == props.interval?.day &&
            i.week == props.interval?.week &&
            i.month == props.interval?.month &&
            i.year == props.interval?.year &&
            props.interval?.dateStart != null &&
            i.dateStart != null &&
            formatDateToDateString(new Date(i.dateStart)) ==
              formatDateToDateString(new Date(props.interval?.dateStart)) &&
            i.id != props.interval?.id
        );
  };

  const handleSave = () => {
    if (props.interval?.id == 0 || isChangeWarningDialogOpen) {
      // Create new interval
      props.onSave();
      setIsChangeWarningDialogOpen(false);
    } else {
      // Save existing interval
      if (
        intervalBackup.current?.day != props.interval?.day ||
        intervalBackup.current?.week != props.interval?.week ||
        intervalBackup.current?.month != props.interval?.month ||
        intervalBackup.current?.year != props.interval?.year ||
        intervalBackup.current?.dateStart != props.interval?.dateStart
      ) {
        setIsChangeWarningDialogOpen(true);
      } else {
        props.onSave();
      }
    }
  };

  if (props.interval == null) return null;

  return (
    <IntervalSettingsDialogView
      isChangeWarningDialogOpen={isChangeWarningDialogOpen}
      setIsChangeWarningDialogOpen={setIsChangeWarningDialogOpen}
      checkIsSaveNotAvailable={checkIsSaveNotAvailable}
      {...props}
      onSave={handleSave}
    />
  );
};
