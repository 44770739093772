import React from "react";
import Menu, { MenuItemGroupProps } from "antd/lib/menu";
import "./MenuItemGroup.scss";

interface IMenuItemGroup extends MenuItemGroupProps {
  //
}

const { ItemGroup } = Menu;

export function MenuItemGroup(props: IMenuItemGroup) {
  return <ItemGroup {...props} />;
}
