import React, { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../../uiKit";
import { IEditTagsDialog } from "./EditTagsDialog.interface";
import { EditTag } from "./EditTag/EditTag";
import { api } from "../../../../../services";
import "./EditTagsDialog.scss";
import { CustomConfirmDialog } from "../../customConfirmDialog/СustomConfirmDialog";
import { useNotifier } from "../../../../../hooks";

export const EditTagsDialog = memo((props: IEditTagsDialog) => {
  const { t } = useTranslation();

  const notifier = useNotifier();
  const handleApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const [isOpenDeleteTagDialog, setIsOpenDeleteTagDialog] = useState<boolean>(false);
  const handleCloseDeleteTagDialog = useCallback(() => {
    setIsOpenDeleteTagDialog(false);
  }, []);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const [deleteTagId, setDeleteTagId] = useState<number | null>(null);
  const[deleteTagName, setDeleteTagName] = useState<string>("");


  const handleOpenDeleteDialog = useCallback((tagId: number, tagName: string) => {
    setDeleteTagId(tagId);
    setDeleteTagName(tagName);
    setIsOpenDeleteTagDialog(true);
  }, []);

  const handleDeleteTag = useCallback(async () => {
    setIsDeleteLoading(true);
    const status = await api.tags.del(deleteTagId!);
    console.log("status", status);
    if (status) {
      notifier.show({
        message: t("notifier:success.tag_delete", { tag: deleteTagName}),
        theme: "success",
      });
      props.resetData();
      setDeleteTagId(null);
      setDeleteTagName("");
      setIsOpenDeleteTagDialog(false);
    } else {
      handleApiError();
    }
    setIsDeleteLoading(false);
  }, [deleteTagId, deleteTagName]);

  const [editTagId, setEditTagId] = useState<number | null>(null);
  const handleChangeEditTagId = useCallback((id: number | null) => {
    setEditTagId(id);
  }, []);

  return (
    <>
      {isOpenDeleteTagDialog && (
        <CustomConfirmDialog
          open={isOpenDeleteTagDialog}
          onClose={handleCloseDeleteTagDialog}
          title={t("ui:delete_tag_confirm_dialog.title", {tag: deleteTagName})}
          subTitle={t("ui:delete_tag_confirm_dialog.description")}
          onConfirm={handleDeleteTag}
          loading={isDeleteLoading}
        />
        
      )}
      <Dialog
        closable={false}
        title={t("ui:edit_tag_dialog.title")}
        onClose={props.onClose}
        open={true}
      >
        <div className="edit-tags-container">
          {props.tags.map((tag) => (
            <EditTag
              key={tag.id}
              tag={tag}
              editTagId={editTagId}
              onDelete={handleOpenDeleteDialog}
              onChangeEditTagId={handleChangeEditTagId}
              onUpdateTag={props.onUpdateTag}
            />
          ))}
        </div>
        <DialogActions>
          <Button
            onClick={props.onClose}
            variant="default"
          >
            {t("ui:button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
});