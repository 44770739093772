import React, { useState } from "react";
import { Button, Dialog, DialogActions, Input, Table } from "../../../uiKit";
import { ICompanyColumn } from "../../pages/companies-management/companyMainCard/CompanyMainCard";
import { useTranslation } from "react-i18next";
import { api } from "../../../../services";
import { removeMultipleSpacesFromString } from "../../../../helpers/stringFunctions";

interface IAdminCompanyByIdListDialogProps {
  isOpen: boolean;

  onClose: () => void;
}

interface ITableData {
  companyId: number;
  companyName: string;
  companyEmployeeCount: number;
}

const tableColumns: ICompanyColumn[] = [
  {
    title: "ID компании",
    dataIndex: "companyId",
    key: "companyId",
  },
  {
    title: "Название компании",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Всего сотрудников",
    dataIndex: "companyEmployeeCount",
    key: "companyEmployeeCount",
  },
];

export const AdminCompanyByIdListDialog = (props: IAdminCompanyByIdListDialogProps) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companiesIds, setCompaniesIds] = useState<string>("");
  const [tableData, setTableData] = useState<ITableData[]>([]);

  const getData = async () => {
    const ids = removeMultipleSpacesFromString(companiesIds)
      .split(/[\s,]+/)
      .map((v) => Number(v.trim()))
      .filter((v) => !Number.isNaN(v));
    if (ids.length == 0) return;

    let _data: ITableData[] = [];

    setIsLoading(true);
    for await (const id of [...new Set(ids)]) {
      const r = await api.adminCompany.getById(id);

      if (r != null) {
        _data = [
          ..._data,
          {
            companyName: (r.name?.trim() || r.nameFallback?.trim()) ?? "",
            companyId: r.id!,
            companyEmployeeCount: r.usersCount?.Full ?? 0,
          },
        ];
      }
      // const r1 = await api.company.getById(id);
      // if (r1 != null) {
      //   const r2 = await api.user.autocomplete({
      //     pageSize: -1,
      //     companyId: id,
      //   });
      //   if (r2 != null) {
      //     _data = [..._data, { companyId: id, companyName: r1.name ?? "", companyEmployeeCount: r2.totalItems ?? 0 }];
      //   }
      // }
    }

    setTableData(_data);
    setIsLoading(false);
  };

  const handleClose = () => {
    props.onClose();
    setIsLoading(false);
    setCompaniesIds("");
    setTableData([]);
  };

  return (
    <Dialog title="Информация о компаниях по ID" width={800} open={props.isOpen} onClose={handleClose}>
      <div className="d-stack-column spacing-3">
        <div className="d-stack spacing-2">
          <Input
            disabled={isLoading}
            value={companiesIds}
            size="middle"
            placeholder="ID компаний через запятую"
            onChange={(e) => setCompaniesIds(e.target.value)}
          />
          <Button
            loading={isLoading}
            disabled={companiesIds.trim().length == 0}
            children="Загрузить"
            onClick={getData}
          />
        </div>
        <Table loading={isLoading} columns={tableColumns} dataSource={tableData} pagination={false} />
      </div>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" children={t("ui:button.cancel")} />
      </DialogActions>
    </Dialog>
  );
};
