import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FileUploader.scss";
import FileUploaderItem from "./fileUploaderItem/FileUploaderItem";
import { TFileUploader } from "./FileUploader";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../../hooks";
import { FileDrop } from "react-file-drop";
import ScrollContainer from "react-indiana-drag-scroll";
import { FiChevronRight } from "@react-icons/all-files/fi/FiChevronRight";
import { FiChevronLeft } from "@react-icons/all-files/fi/FiChevronLeft";
import { Button, CollapsibleContainer, Divider, IconButton, Text } from "../../uiKit";

interface IFileUploaderView {
  uploadFilesInputRef?: any;
  files: TFileUploader[];
  requiredNumber?: number;
  containerClass?: string;
  hideInfo?: boolean;
  hideButton?: boolean;
  accept?: string;
  filesContainerMaxHeight?: string;
  additionallyCountImg?: number;
  // value?: StaticFileDto[];
  noDragAndDrop?: boolean;
  readonly?: boolean;
  smallSize?: boolean;
  noDelete?: boolean;
  onFileInputChanged?: (event: any) => void;
  onFileDropped?: (event: any) => void;
  onFileItemClick: (fId: number) => void;
  onDownloadItemClick: (fId: number) => void;
  onDeleteItemClick: (fId: number, fromValue: boolean) => void;
}

function FileUploaderView(props: IFileUploaderView) {
  const { t } = useTranslation();
  const { appStore } = useRootStore();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [disabledScrollbuttons, setDisabledScrollbuttons] = useState<boolean>(true);
  const [disabledScrollLeftButton, setDisabledScrollLeftButton] = useState<boolean>(true);
  const [disabledScrollRightButton, setDisabledScrollRightButton] = useState<boolean>(true);
  const [isCollapsed, setIsCollapsed] = useState<number>(0);

  const checkElementHasScroll = (): boolean => {
    const element = scrollWrapperRef?.current?.parentElement;
    if (element == null) return false;
    return element.scrollWidth <= element.clientWidth;
  };

  const handleCheckOffsetLeft = () => scrollWrapperRef?.current?.parentElement?.scrollLeft === 0;
  const handleCheckOffsetRight = () => {
    const element = scrollWrapperRef.current?.parentElement!;
    return element.scrollWidth! - (element.clientWidth! + element.scrollLeft) <= 15;
  };

  const scrollHandler = () => {
    setDisabledScrollLeftButton(handleCheckOffsetLeft());
    setDisabledScrollRightButton(handleCheckOffsetRight());
  };

  const handleScroll = (direction: "left" | "right") => {
    const element = scrollWrapperRef?.current?.parentElement;
    if (element == null) return;
    const elWidth = element?.clientWidth;
    element?.scrollBy({
      left: direction === "left" ? -elWidth : elWidth,
      behavior: "smooth",
    });
  };

  const scrollToLeft = () => handleScroll("left");
  const scrollToRight = () => handleScroll("right");

  useEffect(() => {
    const scrollContainer = scrollWrapperRef?.current?.parentElement;
    scrollContainer && scrollContainer?.addEventListener("scroll", scrollHandler);
    return () => {
      scrollContainer?.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollWrapperRef?.current]);

  /*useEffect(() => {
    console.log(props.files, props.files.length);
  }, [props.files]);*/

  useEffect(() => {
    const noScroll = checkElementHasScroll();
    setDisabledScrollbuttons(noScroll);
    if (!noScroll && scrollWrapperRef?.current !== null) {
      setDisabledScrollLeftButton(handleCheckOffsetLeft());
      setDisabledScrollRightButton(handleCheckOffsetRight());
    }
  }, [props.files.length]);

  // useEffect(() => {
  //   console.log(123);
  // }, [appStore.getIsAppOnMouseDragAndDrop]);

  return (
    <div style={{ padding: props.hideInfo && props.files.length == 0 ? 0 : 0 }} className="file-uploader__container">
      {!props.noDragAndDrop && (
        <CollapsibleContainer
          //TODO: YS check this moment
          isOpen={appStore.getIsAppOnMouseDragAndDrop}
          //     timeout={75}
        >
          {/*<CollapsePanel showArrow={false} key={1} header={""} style={{ width: "100%" }}>*/}
          <FileDrop
            onFrameDragEnter={(event) => appStore.setIsAppOnMouseDragAndDrop(true)}
            onFrameDragLeave={(event) => appStore.setIsAppOnMouseDragAndDrop(false)}
            onFrameDrop={(event) => appStore.setIsAppOnMouseDragAndDrop(false)}
            onDrop={(files, event) => props?.onFileDropped && props.onFileDropped(event)}
          >
            <Text>Отпустите файлы здесь для загрузки</Text>
          </FileDrop>
          {/*</CollapsePanel>*/}
        </CollapsibleContainer>
      )}
      {props.files.length > 0 && (
        <div className="__scroll-wrapper">
          {!disabledScrollbuttons && (
            <>
              {!disabledScrollLeftButton && (
                <IconButton onClick={scrollToLeft} className="scroll-button-arrow_to_prev">
                  <FiChevronLeft fontSize={28} />
                </IconButton>
              )}
              {!disabledScrollRightButton && (
                <IconButton onClick={scrollToRight} className="scroll-button-arrow_to_next">
                  <FiChevronRight fontSize={28} />
                </IconButton>
              )}
            </>
          )}
          <ScrollContainer
            hideScrollbars={false}
            horizontal
            vertical
            // ref={ref}
            className={"file-uploader__sub-container file-uploader__sub-container " + (props.containerClass ?? "")}
            style={{ maxHeight: props.filesContainerMaxHeight ?? undefined }}
          >
            <div
              className="d-stack-row justify-start align-center"
              ref={scrollWrapperRef}
              // className="file-uploader__sub-container-items"
              // sx={{overflowY : "auto",pb:2}}
              // spacing={1}
            >
              {/*.sort((a, b) => (a.order >= b.order ? 1 : -1))*/}
              {props.files.map((file) => (
                <div key={file.fId}>
                  <FileUploaderItem
                    // key={file.fId}
                    value={file}
                    readonly={props.readonly}
                    noDelete={props.noDelete}
                    smallSize={props.smallSize}
                    onItemClick={() => props.onFileItemClick(file.fId)}
                    onDeleteClick={() => props.onDeleteItemClick(file.fId, file.fileFromValue ?? false)}
                    onDownloadClick={() => props.onDownloadItemClick(file.fId)}
                  />
                </div>
              ))}
            </div>
            {!props.hideInfo && <Divider className="my-1" style={{ opacity: 0.5 }} />}
          </ScrollContainer>
        </div>
      )}

      {!props.hideInfo && (
        <div className="d-flex mt-1 align-center">
          {!props.hideButton && (
            <Button
              style={{ marginTop: 1 }}
              onClick={() => props?.uploadFilesInputRef?.current?.click()}
              variant="filled"
            >
              {t("ui:button.add_files")}
            </Button>
          )}
          <Text className="file-uploader__files-number flex-grow-1">
            {t("common:misc.file_uploaded_number", {
              number: props.files.length + (props.additionallyCountImg ?? 0),
            }).toString() + (props.requiredNumber ? ` / ${props.requiredNumber}` : "")}
          </Text>
        </div>
      )}

      <input
        style={{ display: "none" }}
        type="file"
        multiple
        onChange={props?.onFileInputChanged}
        accept={props.accept ?? undefined}
        ref={(input) => {
          if (props?.uploadFilesInputRef) props.uploadFilesInputRef.current = input;
        }}
      />
    </div>
  );
}

export default observer(FileUploaderView);
