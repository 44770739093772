import React, { useState } from "react";
import OrgchartSortDialogView from "./OrgchartSortDialogView";
import { RoleDto, RoleOrderModel } from "../../../../api";
import { api } from "../../../../services";
import { DropResult } from "react-beautiful-dnd";
import { useNotifier } from "../../../../hooks";
import { useTranslation } from "react-i18next";

export interface IOrgchartSortDialog {
  allRoles: RoleDto[];
  roleId: number;

  onSave(): void;
  onClose(): void;
}

function OrgchartSortDialog(props: IOrgchartSortDialog) {
  const notifier = useNotifier();
  const { t } = useTranslation();

  const [rolesState, setRolesState] = useState<(RoleDto & { idx: number })[]>(
    props.allRoles
      ?.filter((role: RoleDto) => role.parentId == props.roleId)
      ?.sort((a: any, b: any) => a.order - b.order)
      .map((r, i) => ({ ...r, idx: i }))
  );

  const onSaveHandler = async () => {
    const modelToSend: RoleOrderModel[] = rolesState.map((role: RoleDto, index: number) => {
      return { roleId: role.id, order: index };
    });
    const r = await api.role.reorderChildren(props.roleId, modelToSend);
    if (!r) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    }
    props.onSave();
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd({ destination, source }: DropResult) {
    if (!destination) return;

    const newItems = reorder(rolesState, source.index, destination.index);
    setRolesState(newItems);
  }

  return (
    <OrgchartSortDialogView
      onDragEnd={onDragEnd}
      allRoles={props.allRoles}
      rolesState={rolesState}
      onSave={onSaveHandler}
      onClose={props.onClose}
      roleId={props.roleId}
    />
  );
}

export default OrgchartSortDialog;
