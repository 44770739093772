import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

export interface IEmployeeDeleteDialogView {
  open: boolean;

  onDelete(): void;

  onClose(): void;
}

function EmployeeDeleteDialogView(props: IEmployeeDeleteDialogView) {
  const { t } = useTranslation();

  return (
    <Dialog title={t("ui:title.employee_delete")} open={props.open} onClose={() => props.onClose()} closable={false}>
      {/*{t("text:employee.delete")}*/}
      <div style={{ opacity: 0.75 }}>
        <ul style={{ paddingLeft: "20px" }}>
          <li children={t("parse:user_delete_warning.tasks_to_task_manager")} />
          <li children={t("parse:user_delete_warning.tasks_to_nearest_manager")} />
          <li children={t("parse:user_delete_warning.metrics_will_be_deleted")} />
          <li children={t("parse:user_delete_warning.user_will_be_removed")} />
        </ul>
      </div>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="text">
          {t("ui:button.cancel")}
        </Button>
        <Button theme="error" onClick={() => props.onDelete()} variant="filled">
          {t("ui:button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDeleteDialogView;
