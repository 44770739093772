import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { RootStoreContext } from "./contexts/rootStoreContext";
import { rootStore } from "./stores/rootStore";
import Interceptors from "./plugins/interceptors";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

Interceptors.setup(rootStore);
ReactGA.initialize("G-82DVK69QK5", {
  testMode:
    window.location?.hostname == "localhost" ||
    window.location?.hostname == "beta.tonnus.io" ||
    window.location?.hostname == "rc.tonnus.io",
});

ReactDOM.render(
  <React.StrictMode>
    <RootStoreContext.Provider value={rootStore}>
      {/*<LocalizationProvider locale={rootStore.appStore.getAppLocale == "ru" ? ru : en} dateAdapter={AdapterDateFns}>*/}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/*</LocalizationProvider>*/}
    </RootStoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
