import React, { memo, MouseEvent, MouseEventHandler, useCallback, useEffect, useMemo, useRef } from "react";
import { Checkbox, Icon } from "../../../../../uiKit";
import "./FunctionTreeItem.scss";
import { IFunctionTreeItem, IFunctionTreeItemProps } from "./FunctionTreeItem.interface";
import { RoleSelectorDto } from "../../../../../../api/models/RoleSelectorDto";
import { MenuItemClaimType } from "../../../../../../api/models/NavigationMenuDto";
import { FiEye } from "@react-icons/all-files/fi/FiEye";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";
import { LuCheckCheck, LuGraduationCap } from "react-icons/lu";


export const FunctionTreeItem = memo((props: IFunctionTreeItemProps) => {
  const {
    role,
    updateTreeItemData,
    treeData,
    searchValue,
    claimType,
    onSelectActiveRole,
    activeRole,
  } = props;

  const timer = useRef<NodeJS.Timeout>();
   const handleClick = () => {
     const isSelected = role.isSelected && (claimType ? role.claimType === claimType : true);

     if (!isSelected) {
       const {
         id:roleId,
         positions = [],
         selectedPositions,
         title: roleName,
         includeNestedRoles = false,
         orgchartId,
       } = role;
       onSelectActiveRole?.({
         roleId,
         positions,
         selectedPositions,
         roleName,
         isSelected: isSelected ?? false,
         includeNestedRoles,
         hasItems: false,
         items: [],
         orgchartId,
       });
     }
     updateTreeItemData({
       list: treeData,
       key: role.key,
       isSelected: !isSelected,
       isUpdateClaimType: claimType ? role.claimType !== claimType : false,
     });
  }

  const handleDoubleClick = () => {
    const isSelected = claimType
      ? role.isSelected && role.claimType === claimType
      : role.isSelected;
    if (!isSelected) {
      handleClickSelectActiveRole();
    }
    updateTreeItemData({
      list: treeData,
      key: role.key,
      isSelected: !isSelected,
      includeNestedRoles: true,
      isUpdateClaimType: claimType ? role.claimType !== claimType : false,
    });
  };

   const isChecked = useMemo(() => {
     return role.isSelected;
   }, [role]);

  useEffect(() => {

    if (activeRole?.roleId === role.id) {
      const {
        id: roleId,
        positions = [],
        selectedPositions,
        title: roleName,
        includeNestedRoles = false,
        isSelected = false,
        positionType,
      } = role;
      onSelectActiveRole?.({
        roleId,
        positions,
        selectedPositions,
        roleName,
        isSelected,
        includeNestedRoles,
        hasItems: false,
        items: [],
        positionType,
      });
    }
  }, [activeRole?.roleId]);


   //
   // const getChildren = (role: any) => {
   //   const children: IFunctionTreeItem[] = [];
   //   (role?.children ?? []).forEach((item: IFunctionTreeItem) => {
   //     const { id, title, orgchartId } = item;
   //     children.push({ id, title, orgchartId });
   //     children.push(...getChildren(item));
   //   });
   //   return children;
   // }

   // const childrenList = useMemo(() => {
   //   const { id, title, orgchartId } = role;
   //   const children: IFunctionTreeItem[] = [{id, title, orgchartId}];
   //   (role?.children ?? []).forEach((item: any) => {
   //     const { id, title, orgchartId } = item;
   //     children.push({ id, title, orgchartId });
   //     children.push(...getChildren(item));
   //   });
   //   return children;
   // }, []);

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    clearTimeout(timer.current);
    const timeout = searchValue ? 0 : 200

    if (event.detail === 1 || searchValue) {
      timer.current = setTimeout(handleClick, timeout)
    } else if (event.detail === 2) {
      handleDoubleClick();
    }
  };

  const handleClickSelectActiveRole = useCallback((e?: MouseEvent<HTMLSpanElement>) => {
    if (e) {
      e.stopPropagation();
    }
    if (isChecked) {
      const {
        id:roleId,
        positions = [],
        selectedPositions,
        title: roleName,
        includeNestedRoles = false
      } = role;
      onSelectActiveRole?.({
        roleId,
        positions,
        selectedPositions,
        roleName,
        isSelected: isChecked,
        includeNestedRoles,
        hasItems: false,
        items: [],
      });
    }
  }, [isChecked, role]);

  const getIconComponent = useMemo(() => {
    switch (role.claimType) {
      case MenuItemClaimType.Read:
        return <FiEye/>
      case MenuItemClaimType.Write:
        return <FiEdit3/>
      case MenuItemClaimType.Assignment:
        return <LuGraduationCap/>
      case MenuItemClaimType.FullAccess:
        return <LuCheckCheck/>
      default:
        return null;
    }
  }, [role.claimType]);

  return (
    <div
      className="function-tree-item"
      onClick={onClickHandler}
    >
      <Checkbox
        checked={claimType ? role.isSelected && (role.claimType === claimType || !role.claimType) : role.isSelected}
        isNewDesign={!role.includeNestedRoles}
      >
       <span
         className="function-tree-item-content"
         onClick={handleClickSelectActiveRole}
       >
         { role.title }
         {role.isSelected && (
           <Icon
             className={"function-tree-item-content__icon"}
           >
             { getIconComponent }
           </Icon>
         )}

       </span>
      </Checkbox>
    </div>
  )
});