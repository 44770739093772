import React from "react";
import { RoleDto } from "../../../../api";
import { api } from "../../../../services";
import OrgchartFunctionDeleteDialogView from "./OrgchartFunctionDeleteDialogView";

function OrgchartFunctionDeleteDialog(props: any) {
  const onDeleteHandler = async () => {
    const r = await api.role.del(props.roleId);
    props.onDelete();
  };

  return (
    <OrgchartFunctionDeleteDialogView
      allRoles={(props.allRoles ?? []) as RoleDto[]}
      onDelete={onDeleteHandler}
      onClose={props.onClose}
      roleId={props.roleId}
    />
  );
}

export default OrgchartFunctionDeleteDialog;
