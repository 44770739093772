import React from "react";
import "./Regulation.scss";
import { Skeleton } from "antd";

export const RegulationSkeleton = () => {
  return (
    <div className="d-stack-column">
      <Skeleton.Input active size="large" className="mb-1 regulation-skeleton_title" />
      <div className="d-stack-row align-center">
        <Skeleton.Avatar active size={35} className="mr-4" />
        <div className="d-stack-column">
          <Skeleton.Input active size="small" className="mb-1 regulation-skeleton_header_user-name" />
          <Skeleton.Input active className="regulation-skeleton_header_date-created" />
        </div>
      </div>
      <div className="d-stack-column spacing-1 mt-4 mb-4">
        <Skeleton.Input active size="small" className="regulation-skeleton_w-60 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-90 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-60 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-90 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-60 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-90 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-70 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-100 regulation-skeleton_h-14" />
        <Skeleton.Input active size="small" className="regulation-skeleton_w-80 regulation-skeleton_h-14" />
      </div>
    </div>
  );
};
