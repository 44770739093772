import React from "react";

interface IIf {
  condition: boolean;
  noElse?: boolean;
  children?: React.ReactNode;
}

function getIndexOfElse(children?: React.ReactNode) {
  if (!Array.isArray(children)) {
    return -1;
  }
  return children.findIndex((child) => child && child.type.name === "Else");
}

export function If(props: IIf): JSX.Element | null {
  const indexOfElse = props.noElse == true || props.noElse == null ? -1 : getIndexOfElse(props.children);

  if (indexOfElse === -1) {
    return props.condition ? <>{props.children}</> ?? null : null;
  }

  if (props.condition && Array.isArray(props.children)) {
    return <>{props.children.slice(0, indexOfElse)}</> ?? null;
  }

  if (Array.isArray(props.children)) {
    return <>{props.children.slice(indexOfElse + 1)}</> ?? null;
  }

  return <>{props.children}</> ?? null;
}
