import React from "react";
import { useTranslation } from "react-i18next";
import { IProgressUsersColumn } from "./IProgressUsersColumn";
import { Text, Title } from "../../../../../uiKit";
import { UserInfo } from "../../../../../elements/userInfo/UserInfo";

export const ProgressUsersColumn: React.FC<IProgressUsersColumn> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="mb-2">
      <Title className="mb-4" style={{ fontSize: "16px", fontWeight: 500 }}>
        {props.title}
      </Title>
      {props.users.length === 0 ? (
        <Text className="mb-4" style={{ color: "var(--color-gray-base)" }}>
          {t("parse:no_data")}
        </Text>
      ) : (
        <div className="d-stack-column spacing-3 justify-center align-start">
          {props.users?.slice(0, 10)?.map((user) => (
            <UserInfo
              key={user.id}
              nameStyle={{ fontWeight: "500" }}
              avatarSize={25} user={user}
            />
            // <UserCardView key={user.id} avatarSize={25} boldName userInfo={user} />
          ))}
        </div>
      )}
    </div>
  );
};
