import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { Icon, Tree } from "../../../../../uiKit";
import { CloseIcon } from "../../../../../../constants/icons";
import { ArrowDownIcon } from "../../../../../../constants/icon";
import {
  FunctionTreeItem
} from "../../../../dialogs/regulationAccessControlDialog/regulationFunctionSelectionDialog/functionTreeItem/FunctionTreeItem";
import "./OrgchartTree.scss";
import { IOrgchartTree } from "./IOrgchartTree";
import { RegulationTreeNode } from "../../../../../../api/models/RegulationTreeDto";
import { useTranslation } from "react-i18next";


export const OrgchartTree = memo((props: IOrgchartTree) => {
  const { t } = useTranslation();
  const [isShowInfo, setIsShowInfo] = useState<boolean>(true);


  return (
    <div className="orgchart-tree">
      {isShowInfo && (
        <div className="function-selection-info mb-3">
          { t("ui:useful_advice")}
          <div
            className="function-selection-info__close-icon"
            onClick={() => setIsShowInfo(false)}
          >
            <CloseIcon />
          </div>
        </div>
      )}
      <Form.Item
        className="mb-0"
        name="tree"
        trigger="onCheck"
        valuePropName="checkedKeys"
      >
        <Tree
          className="function-tree"
          onExpand={props.onExpand}
          expandedKeys={props.expandedKeys}
          switcherIcon={
            <Icon
              style={{ verticalAlign: "middle" }}
              component={() => <ArrowDownIcon color="var(--color-gray-weak)" size={16} />}
            />
          }
          rootStyle={{ fontSize: "12px" }}
          treeData={props.treeData}
          // loadData={props.onLoadData}
          titleRender={(treeNode) => (
            <FunctionTreeItem
              role={treeNode as RegulationTreeNode}
              treeData={props.treeData}
              selectedRoles={props.selectedRoles}
              setSelectedRoles={props.setSelectedRoles}
              updateTreeItemData={props.updateTreeItemData}
              activeRole={props.activeRole}
              onSelectActiveRole={props.onSelectActiveRole}
              searchValue={props.searchValue}
              claimType={props.claimType}
            />
          )}
        />
      </Form.Item>
    </div>
  )
})