import React, { useContext } from "react";
import { IDropdownContentElement, IFilterDropdownContent } from "./CalendarFilterDropdownContent.interface";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { Select } from "../../../../../uiKit";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { RelationsKeys } from "../../constants/keys";
import { Tags } from "../tags/Tags";

const FilterDropdownContentMemo = (props: IFilterDropdownContent) => {
  const {
    calendarRoleId,
    tags,
  } = useContext(BoardFilterContext);

  const { t } = useTranslation();


  const roleSelect = (
    <>
      <SubheaderText text={t("ui:subheader.filter_issue_role")} />
      <Select
        style={{ width: 282 }}
        allowClear={true}
        value={calendarRoleId ?? null}
        onChange={(id) => props.handleChangeCalendarRoleId(id)}
        items={props.rolesList}
      />
    </>
  );

  const elements: IDropdownContentElement[] = [
    {
      id: 1,
      name: "function_select",
      allowedRelationsToRender: [],
      component: <React.Fragment key={1}>{roleSelect}</React.Fragment>,
    },
    {
      id: 2,
      name: "tags",
      allowedRelationsToRender: [RelationsKeys.My, RelationsKeys.Controlled, RelationsKeys.Participated],
      component: (
        <Tags
          key={8}
          selectedTags={tags}
          onSelectedTags={props.onSelectedTags}
          onChangeIsOpenFilter={props.onChangeIsOpenFilter}
        />
      ),
    },
  ];

  return (
    <div className="filter-dropdown-content d-stack-column spacing-2 pa-2" style={{ width: "320px !important" }}>
      {elements.map((item) => item.component)}
    </div>
  );
};

export const CalendarFilterDropdownContent = React.memo(FilterDropdownContentMemo);
