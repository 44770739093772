import React from "react";
import { CardView } from "./CardView";
import { CardProps } from "antd";

export type TCardVariant = "default" | "secondary" | "transparent";
export type TCardScrollStrategy = "cardBody" | "default" | undefined;

interface ICard extends CardProps {
  className?: string;
  bodyClassName?: string;
  clickable?: boolean;
  variant?: TCardVariant;
  scrollStrategy?: TCardScrollStrategy;
  isShadowed?: boolean;
  isLoading?: boolean;
  isNoPadding?: boolean;
}

export function Card(props: ICard) {
  return <CardView {...props} />;
}
