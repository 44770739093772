import React, { memo, useCallback, useEffect, useState } from "react";
import { CalendarTimeBlockView } from "./CalendarTimeBlockView";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CalendarEventTimeBlockDto } from "../../../../api/models/CalendarEventTimeBlockDto";
import { CompanyScheduleDto } from "../../../../api";
import { ScheduleFormTypes } from "../../forms/companyEditForm/companyEditFormScheduleForm/CompanyEditFormScheduleForm";
import { TimeBlockSubmitFormDto } from "../../../../api/models/TimeBlockSubmitFormDto";

export interface ICalendarTimeBlock {
  item: CalendarEventTimeBlockDto;
  onClose(): void;
  handleSaveTimeBlock?: (data: CalendarEventTimeBlockDto) => Promise<void>;
  popupItems: any;
  isLoadingTimeBlock?: boolean;
}

export const CalendarTimeBlock = memo((props: ICalendarTimeBlock) => {
  const { item , onClose, handleSaveTimeBlock, popupItems, isLoadingTimeBlock } = props;

  const { t } = useTranslation();

  const [eventTimeBlock, setEventTimeBlock] = useState<CalendarEventTimeBlockDto>(item);


  const handleSubmitForm = useCallback(() => {
    handleSaveTimeBlock && handleSaveTimeBlock(eventTimeBlock);
  }, [eventTimeBlock])

  const handleChange = useCallback(({ value, key }: TimeBlockSubmitFormDto) => {
    setEventTimeBlock((prev: CalendarEventTimeBlockDto) => ({
      ...prev,
      [key]: value
    }))
  }, []);

  return (
    <CalendarTimeBlockView
      handleClose={onClose}
      eventTimeBlock={eventTimeBlock}
      handleChange={handleChange}
      handleSubmitForm={handleSubmitForm}
      popupItems={popupItems}
      isLoadingTimeBlock={isLoadingTimeBlock}
    />
  )
});