import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { OrgchartSwitcher } from "../../../../../elements/orgchartSwitcher/OrgchartSwitcher";
import { AutocompleteAsync } from "../../../../../elements/autocompleteAsync/AutocompleteAsync";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";
import { IArchivedFilterDropdownContent } from "./ArchivedFilterDropdownContent.interface";
import "./FilterDropdownContent.scss";
import { Switch } from "../../../../../uiKit";

export const FilterDropdownContent = memo((
  {
    orgchartId,
    onChangeOrgchartId,
    senderId,
    onChangeSenderId,
    executorId,
    onChangeExecutorId,
    isStatusNotDone,
    onChangeStatusNotDone,
  }: IArchivedFilterDropdownContent
) => {
  const { userRelation } = useContext(BoardFilterContext);

  const { t } = useTranslation();

  return (
    <div className="archive-issues-header-filter">
      <div className="archive-issues-header-filter-row">
        <SubheaderText text={t("ui:subheader.orgchart")} />
        <OrgchartSwitcher
          value={orgchartId}
          onChange={(value) => onChangeOrgchartId(value as number)}
          selectProps={{
            allowClear: true,
            style: { width: "100%" },
            placeholder: t("ui:placeholder.click_to_select"),
          }}
          preventDefault
          drawType="select"
        />
      </div>
      <div className="archive-issues-header-filter-row">
        <SubheaderText text={t("ui:subheader.issue_sender")} />
        <AutocompleteAsync
          className="mb-1 full-width"
          type="user"
          queryUri="current-initiators"
          requestOptions={{
            userRelation,
            isArchived: true
          }}
          size="large"
          allowClear
          // additionalItems={[
          //   {
          //     id: authStore.getInitialInfo?.identity?.id,
          //     text: authStore.getInitialInfo?.identity?.nameFallback,
          //   },
          // ]}
          placeholder={t("ui:placeholder.click_to_select")}
          value={senderId}
          onChange={onChangeSenderId}
        />
      </div>
      <div className="archive-issues-header-filter-row">
        <SubheaderText text={t("ui:subheader.issue_executor")} />
        <AutocompleteAsync
          className="mb-1 full-width"
          type="user"
          queryUri="current-executors"
          requestOptions={{
            userRelation,
            isArchived: true,
          }}
          size="large"
          allowClear
          placeholder={t("ui:placeholder.click_to_select")}
          value={executorId}
          onChange={onChangeExecutorId}
        />
      </div>
      <div className="archive-issues-header-filter-row">
        <Switch
          label={t("ui:label.manual_archiving")}
          checked={isStatusNotDone}
          onChange={onChangeStatusNotDone}
          isHiddenCheckedChildren
        />
      </div>
    </div>
  );
});