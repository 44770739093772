import React from "react";
import "./CollapsePanel.scss";
import { Collapse, CollapsePanelProps } from "antd";
import clsx from "clsx";

interface ICollapsePanelView extends CollapsePanelProps {
  //
}

const { Panel } = Collapse;

export function CollapsePanelView({ className, ...rest }: ICollapsePanelView) {
  return <Panel className={clsx("uiKit-collapsePanel", className ?? "")} {...rest} />;
}
