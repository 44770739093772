import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions } from "../../../uiKit";

interface ICompanyPauseConfirmationDialog {
  open: boolean;

  onClose: () => void;
  onAccept: () => void;
}

function CompanyPauseConfirmationDialog(props: ICompanyPauseConfirmationDialog) {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} title={t("parse:pause_company")} onClose={() => props.onClose()} closable={false}>
      <h4>{t("parse:pause_company_text")}</h4>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {" "}
          {t("ui:button.cancel")}
        </Button>
        <Button
          onClick={() => {
            props.onAccept();
            props.onClose();
          }}
          variant={"filled"}
          danger
        >
          {t("parse:pause")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyPauseConfirmationDialog;
