import React from "react";
import { IListEducationSectionsView } from "./ListEducationSections.interface";
import { EducationSection } from "../educationSection/EducationSection";

export const ListEducationSectionsView = (props: IListEducationSectionsView) => {
  return (
    <div className="d-stack-column justify-start align-start pa-1 spacing-5 full-width">
      {props.items.map((item, index) => (
          <EducationSection
            key={item.key}
            educationItem={item}
            onClick={props.onClick}
          />
      ))}
    </div>
  );
};
