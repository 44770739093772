import React from "react";
import { FiLink } from "@react-icons/all-files/fi/FiLink";
import { Avatar, Divider } from "../../../../uiKit";

export function UiTabAvatar() {
  return (
    <div className="my-3">
      <div className="d-flex align-center">
        <FiLink className="mr-1" color="var(--color-primary-strong)" />
        <a target="_blank" href="https://ant.design/components/avatar/">
          Документация AntD
        </a>
      </div>
      <Divider className="my-3" />
      <div>
        {/*<Text style={{ fontSize: "16px" }}>Кастомные пропсы</Text>*/}
        {/*<div>*/}
        {/*  <div className="d-stack-column spacing-1">*/}
        {/*    <span>*/}
        {/*      <span style={{ fontWeight: "bold" }} children="readOnly" /> - read-only*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<Divider className="my-3" />*/}
        <div className="d-stack-row spacing-2">
          <Avatar />
          <Avatar src="https://pixelbox.ru/wp-content/uploads/2021/04/cats-ava-steam-95.jpg" />
        </div>
      </div>
    </div>
  );
}
