import React from "react";
import { TableView } from "./TableView";
import { TableProps } from "antd";

interface ITable extends TableProps<any> {
  isNewStyle?: boolean;
}

export function Table(props: ITable) {
  return <TableView bordered tableLayout="auto" {...props} />;
}
