import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { removeTrailingSlash } from "../../../../../../helpers/pathname";
import { AppPagesTabs, AppPageTabType } from "../../../../../../utils/appLinksAndTabs";
import { Chip } from "../../../../../uiKit";
import { IPlanningPageAdminHeader } from "./PlanningPageAdminHeader.interface";
import { PlanningPageAdminHeaderView } from "./PlanningPageAdminHeaderView";

export const PlanningPageAdminHeader = (props: IPlanningPageAdminHeader) => {
  const allTabs = AppPagesTabs;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [url, setUrl] = useState(removeTrailingSlash(window.location.pathname));

  const handleTabNameGet = (tab: AppPageTabType): string | ReactNode => {
    const getGuideContentKey = (): JSX.Element | null => {
      switch (tab.id) {
        case 0:
          return null;
        case 1:
          return null;
        case 2:
          return <Chip label={props.plansForApproval} className="ml-2" />;
        default:
          return null;
      }
    };
    return (
      <Link to={tab.link ?? allTabs.find((t) => t.key == tab.key)?.link ?? "/"}>
        {t(tab.name ?? "")}
        {props.plansForApproval && props.plansForApproval > 0 ? getGuideContentKey() : null}
      </Link>
    );
  };

  useEffect(() => {
    const currentPathName = removeTrailingSlash(pathname);
    if (currentPathName != url) setUrl(currentPathName);
  }, [pathname, url]);

  return <PlanningPageAdminHeaderView url={url} onTabNameGet={handleTabNameGet} allTabs={allTabs} />;
};
