import React from "react";
import { TreeView } from "./TreeView";
import { TreeProps } from "antd";

interface ITreeProps extends TreeProps {
  //
}

export const Tree = (props: ITreeProps) => {
  return <TreeView {...props} />;
};
