import React from "react";
import { Collapse, CollapseProps } from "antd";
import "./Collapse.scss";
import clsx from "clsx";
import { ArrowDownIcon } from "../../../../constants/icon";

interface ICollapseView extends CollapseProps {
  separated?: boolean;
  isNewDesign?: boolean;
}

export function CollapseView({ separated, className, isNewDesign, ...rest }: ICollapseView) {
  return <Collapse
    className={clsx("uiKit-collapse", className ?? "", { __separated: separated })}
    {...rest}
    expandIcon={isNewDesign ? ({  isActive  }) => {
      return (
        <div className={clsx("uiKit-collapse__arrow", {
          "uiKit-collapse__arrow_active": isActive,
        })}>
          <ArrowDownIcon size={24}/>
        </div>
      )
    } : undefined}
  />;
}
