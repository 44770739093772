import React from "react";
import { UserInfo } from "../../../../elements/userInfo/UserInfo";
import { useRootStore } from "../../../../../hooks";

export function UiTabUserCard() {
  const { authStore } = useRootStore();

  return (
    <div className="my-3">
      {/*<div className="d-flex align-center">*/}
      {/*<FiLink className="mr-1" color="var(--color-primary-strong)" />*/}
      {/*<a target="_blank" href="https://ant.design/components/tooltip/">*/}
      {/*  Документация AntD*/}
      {/*</a>*/}
      {/*</div>*/}
      {/*<Divider className="my-3" />*/}
      <div>
        {/*<Text style={{ fontSize: "16px" }}>Кастомные пропсы</Text>*/}
        {/*<div>*/}
        {/*  <div className="d-stack-column spacing-1">*/}
        {/*    <span>*/}
        {/*      <span style={{ fontWeight: "bold" }} children="readOnly" /> - read-only*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<Divider className="my-3" />*/}
        <div className="d-stack-column spacing-2 uiKitPage-page-container">
          <div className="d-flex justify-space-between">
            <UserInfo user={authStore.getInitialInfo?.identity} />
            <span>Стандартный компонент</span>
          </div>
          <div className="d-flex justify-space-between">
            <UserInfo user={authStore.getInitialInfo?.identity} />
            <span>С полными именем</span>
          </div>
          <div className="d-flex justify-space-between">
            <UserInfo user={authStore.getInitialInfo?.identity} isTooltipDisabled />
            <span>Без тултипа</span>
          </div>
        </div>
      </div>
    </div>
  );
}
