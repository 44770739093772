import React, { useState } from "react";
import FunctionPositionCardView from "./FunctionPositionCardView";
import { RoleDto, RolePositionDto } from "../../../../../api";

interface IFunctionPositionCard {
  data: RolePositionDto;
  functionData: RoleDto;
  readOnly: boolean;
  isLoading: boolean;

  handleUserAddOnPosition: (positionId: number, userId: number) => void;
  handleUserDeleteFromPosition: (positionId: number, userId: number) => void;
  handlePositionDataUpdate: (data: RolePositionDto) => void;
  handlePositionDelete: (positionId: number) => void;
}

function FunctionPositionCard(props: IFunctionPositionCard) {
  const [positionName, setPositionName] = useState(props.data.name ?? "");
  const [positionAR, setPositionAR] = useState(props.data.description ?? "");

  const handleUserAddOnPosition = (positionId: number, userId: number) => {
    props.handleUserAddOnPosition(positionId, userId);
  };

  const handleUserDeleteFromPosition = (positionId: number, userId: number) => {
    props.handleUserDeleteFromPosition(positionId, userId);
  };

  const handleDataSave = () => {
    props.handlePositionDataUpdate({ ...props.data, name: positionName, description: positionAR });
  };

  return (
    <FunctionPositionCardView
      readOnly={props.readOnly}
      functionData={props.functionData}
      data={props.data}
      isLoading={props.isLoading}
      positionName={positionName}
      positionAR={positionAR}
      setPositionName={setPositionName}
      setPositionAR={setPositionAR}
      handleUserAddOnPosition={handleUserAddOnPosition}
      handleUserDeleteFromPosition={handleUserDeleteFromPosition}
      handleDataSave={handleDataSave}
      handlePositionDelete={props.handlePositionDelete}
    />
  );
}

export default FunctionPositionCard;
