import React, { useContext } from "react";
import { ISectionViewHeader } from "./SectionViewHeader.interface";
import { Icon, IconButton } from "../../../../../uiKit";
import { FiSidebar } from "@react-icons/all-files/fi/FiSidebar";
import { GlobalAuthorizedContext } from "../../../../../../contexts/globalAuthorizedContext";
import { BreadcrumbsNew } from "../../../../../elements/breadcrumbsNew/BreadcrumbsNew";
import { SectionPageHeaderButtons } from "../../components/SectionPageHeaderButtons/SectionPageHeaderButtons";
import "./SectionHeader.scss";

export const SectionViewHeader: React.FC<ISectionViewHeader> = (props) => {
  const globalAuthorizedContext = useContext(GlobalAuthorizedContext);

  return (
    <div className="my-2 d-stack-row spacing-4 align-center full-width" style={{ minHeight: "32px" }}>
      {!globalAuthorizedContext?.regulation?.isSidebarOpen && (
        <IconButton
          // disabled={!props.metrics.length && !props.availableDashboards.length && !props.myDashboards.length}
          className="flex-shrink-0"
          onClick={() => globalAuthorizedContext?.regulation?.onSidebarOpenChange?.()}
          children={<Icon component={() => <FiSidebar />} />}
        />
      )}
      { props.breadcrumbsList.length >1 && (
        <div className="section-view-header-breadcrumbs">
          <BreadcrumbsNew
            breadcrumbsList={props.breadcrumbsList}
          />
        </div>
      )}
      <SectionPageHeaderButtons
        toEdit={props.toEdit}
        isEditable={props.isEditable}
        permissionToEdit={props.permissionToEdit}
      />
      {
        // <div className="d-stack-row justify-end align-center spacing-4 ml-auto">
        //   {props.permissionToEdit &&
        //     (props.isEditable ? (
        //       <CancelButton onClick={props.onSwitchEditable} />
        //     ) : (
        //       <EditButton onClick={props.onSwitchEditable} />
        //     ))}
        //   {props.permissionToEdit && <AccessSettingsButton onClick={props.onOpenDialog} isSection={true}/>}
        //   {props.permissionToEdit && <DeleteButton onClick={props.onDeleteSection} />}
        // </div>
      }
    </div>
  );
};
