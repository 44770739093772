import React, { useEffect } from "react";
import { SectionContext } from "../../../../../../contexts/RegulationPage/SectionContext";
import { CreateSection } from "../createSection/CreateSection";
import { useSectionHooks } from "../../hooks/useSectionHooks";
import { Section } from "../section/Section";
import { ICreateSectionWrapperProps } from "./ICreateSectionWrapper";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const CreateSectionWrapper = observer((props: ICreateSectionWrapperProps) => {
  const {
    handleSave,
    setNewRegulations,
    setUserSelectionRules,
    newRegulations,
    userSelectionRules,
    isRequestLoading,
    titleSection,
    setTitleSection,
    isOpenAccessSettingsDialog,
    isOpenAssignForStudyDialog,
    handleOpenAssignForStudyDialog,
    handleCloseAssignForStudyDialog,
    handleCloseAccessSettingsDialog,
    handleOpenAccessSettingsDialog,
    handleCancel,
    breadcrumbsList,
    section,
    // handlePutAccessSettings,
    isEditable,
    handleSwitchEditable,
    handleOffEditable,
    isOpenConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    regulationIds,
    setRegulationIds,
    navigationMenuItemIds,
    setNavigationMenuItemIds,
    isOpenAddingRegulations,
    handleOpenAddingRegulation,
    handleCloseAddingRegulation,
    fetchSection,
    handleAddedRegulationsToSection,
    getRegulations,
    setSection,
    onMove,
    onRemovalRegulationsFromSection,
    onAppointApprover,
    handleSubmitForPublish,
    handleSubmitForReject,
    isLoading,
    handleLoadSection,
    handleClickCreateDocument,
    handleClickCreateSection,
  } = useSectionHooks();

  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes('policy/all/')) {
      console.log('useEffect handleLoadSection');
      handleLoadSection();
    }
  }, [pathname]);

  return (
    <SectionContext.Provider
      value={{
        handleSave,
        setNewRegulations,
        userSelectionRules,
        setUserSelectionRules,
        newRegulations,
        isRequestLoading,
        titleSection,
        setTitleSection,
        isOpenAccessSettingsDialog,
        isOpenAssignForStudyDialog,
        handleOpenAssignForStudyDialog,
        handleCloseAssignForStudyDialog,
        handleCloseAccessSettingsDialog,
        handleOpenAccessSettingsDialog,
        handleCancel,
        breadcrumbsList,
        section,
        setSection,
        // handlePutAccessSettings,
        isEditable,
        handleSwitchEditable,
        handleOffEditable,
        isOpenConfirmDialog,
        handleOpenConfirmDialog,
        handleCloseConfirmDialog,
        regulationIds,
        setRegulationIds,
        navigationMenuItemIds,
        setNavigationMenuItemIds,
        isOpenAddingRegulations,
        handleOpenAddingRegulation,
        handleCloseAddingRegulation,
        fetchSection,
        handleAddedRegulationsToSection,
        getRegulations,
        onMove,
        onRemovalRegulationsFromSection,
        onAppointApprover,
        handleSubmitForPublish,
        handleSubmitForReject,
        isLoading,
        handleClickCreateDocument,
        handleClickCreateSection,
    }}>
      {props.isView ? (
        <Section/>
        ) : (
        <CreateSection/>
      )}

    </SectionContext.Provider>
  )
});