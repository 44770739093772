import React from "react";
import { Button, Dialog, DialogActions } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { IHiddenGoogleEventsDialog } from "./HiddenGoogleEventsDialog.Interface";
import "./HiddenGoogleEventsDialog.scss";

export const HiddenGoogleEventsDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
 }: IHiddenGoogleEventsDialog) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ui:title.confirm")}
      width={525}
      open={isOpen}
    >
      <div className="google-account-disconnect-dialog-text">
        {t("ui:hidden_google_events_dialog.text")}
      </div>
      <DialogActions>
        <>
          <Button
            onClick={onClose}
            variant="default"
          >
            {t("ui:button.cancel")}
          </Button>
          <Button
            onClick={onConfirm}
            loading={isLoading}
          >
            {t("ui:button.confirm")}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  )
}