import React from "react";
import { useTranslation } from "react-i18next";
import { policyTabKeys } from "./RegulationStatsDialog";
import { IRegulationStatsDialogView } from "./RegulationStatsDialog.interface";
import { Button, Dialog, DialogActions, Empty, Spin } from "../../../uiKit";
import { Tabs } from "antd";
import { CardRegulation } from "../../pages/regulation/components/CardRegulation/CardRegulation";
import { regulationContentTypeKeys } from "../../pages/regulation/misc/headerTools";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";

export const RegulationStatsDialogView: React.FC<IRegulationStatsDialogView> = (props) => {
  const { t } = useTranslation();

  const { TabPane } = Tabs;

  return (
    <Dialog
      title={
        <div>
          {props.userName}
          <Tabs tabBarStyle={{ marginBottom: 0 }} activeKey={props.activeTabKey} onChange={props.onChangeActiveTab}>
            <TabPane tab={t("ui:table.total")} key={policyTabKeys.overall} />
            <TabPane tab={t("ui:table.studied")} key={policyTabKeys.studied} />
            <TabPane tab={t("ui:table.to_study")} key={policyTabKeys.to_study} />
          </Tabs>
        </div>
      }
      open={props.open}
      scrollStrategy="dialogBody"
      onClose={props.onClose}
      closable={false}
    >
      <Spin size="large" spinning={props.isLoading}>
        <div>
          {props.regulations.length === 0 ? (
            <Empty className="my-4" />
          ) : (
            <div className="d-stack-column justify-start align-center spacing-2">
              {props.regulations.map((item) => (
                <CardRegulation
                  isRound
                  regulationContentType={regulationContentTypeKeys.approved}
                  nameAndDate
                  regulation={{ id: item?.id, name: item.name, dateCreated: item.dateCreated }}
                  key={item.id}
                />
              ))}
              <ScrollTrigger
                disabled={props.isDone}
                onIntersection={props.onLoadNext}
                marginTop={props.regulations.length > 0}
                hidden={props.isDone}
              />
            </div>
          )}
        </div>
      </Spin>
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
