import { LinkProps } from "antd/lib/typography/Link";
import React from "react";
import { Typography } from "antd";
import "./Link.scss";
import clsx from "clsx";

interface ILinkView extends LinkProps {
  size?: string;
  weight?: number | "normal" | "bold" | "lighter" | "bolder";
}

const { Link } = Typography;

export function LinkView({ size, weight, className, style, ...rest }: ILinkView) {
  return (
    <Link
      className={clsx("uiKit-link", className ?? "")}
      style={{ fontWeight: weight, fontSize: size, ...style }}
      // target="_blank"
      {...rest}
    />
  );
}
