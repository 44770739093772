import React, { createRef, useEffect, useRef, useState, useCallback, useMemo } from "react";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { LocalStorageHelpers, useClaims, useLocalStorage, useNotifier, useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { api } from "../../../services";
import OrgchartFunctionCreateEditDialog from "../../modules/dialogs/orgchartFunctionCreateEditDialog/OrgchartFunctionCreateEditDialog";
import { IOrgchartContext, OrgchartContext } from "../../../contexts/orgchartContext";
import { OrgchartFunctionDrawer } from "../../modules/pages/orgchart/orgchartFunctionDrawer/OrgchartFunctionDrawer";
import OrgchartFunctionMoveDialog from "../../modules/dialogs/orgchartFunctionMoveDialog/OrgchartFunctionMoveDialog";
import OrgchartFunctionDeleteDialog from "../../modules/dialogs/orgchartFunctionDeleteDialog/OrgchartFunctionDeleteDialog";
import ZoomButton from "../../elements/zoomButton/ZoomButton";
import OrgchartSortDialog from "../../modules/dialogs/orgchartFunctionSortDialog/OrgchartSortDialog";
import { useParams } from "react-router-dom";
import VideoDialog from "../../modules/dialogs/videoDialog/VideoDialog";
import { permissionKeys } from "../../../utils/permissions";
import "./OrgchartPage.scss";
import OrgchartCommonDrawer from "../../modules/pages/orgchart/orgchartCommonDrawer/OrgchartCommonDrawer";
import {
  MetricDto,
  OrgchartDto,
  OrgchartItemDto,
  OrgchartTemplateDto,
  RoleDto,
  RolesWithOrgchartDto
} from "../../../api";
import { CustomConfirmDialog } from "../../modules/dialogs/customConfirmDialog/СustomConfirmDialog";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { Button, Text } from "../../uiKit";
import { OrgchartSwitcher } from "../../elements/orgchartSwitcher/OrgchartSwitcher";
import OrgchartTypeSelectForm from "../../modules/pages/orgchart/orgchartTypeSelectForm/OrgchartTypeSelectForm";
import { OpeningGuideIcon } from "../../elements/openingGuideIcon/OpeningGuideIcon";
import { guideKeys } from "../../../stores/hintStore";
import OrgchartFunctionCardHubbard from "../../modules/pages/orgchart/orgchartFunctionCardHubbard/OrgchartFunctionCardHubbard";
import { OrgchartVfpCard } from "../../modules/pages/orgchart/orgchartVfpCard/OrgchartVfpCard";
import { isSafari } from "react-device-detect";
import { OrgchartTemplateTypeEnum } from "../../../api/models/OrgchartTemplateTypeDto";
import {
  ORGCHART_CLASSIC_TYPE,
  ORGCHART_DEFAULT_TYPE,
  ORGCHART_SIMPLIFIED_TYPE
} from "../../../constants/orgchartTypes";
import { OrgchartSaveTemplateDialog } from "../../modules/dialogs/orgchartSaveTemplateDialog/OrgchartSaveTemplateDialog";
import { TypeFunction } from "../../modules/pages/orgchart/typeFunction/TypeFunction";
import { IOrgchartFilter, OrgchartFilterContext } from "../../../contexts/orgchartFilterContext";
import { MetricCreateEditDialog } from "../../modules/dialogs/metricCreateEditDialog/MetricCreateEditDialog";

function OrgchartPage() {
  const { authStore, helperStore, orgchartStore } = useRootStore();
  const { roleId } = useParams();
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [orgchartId, setOrgchartId] = useState(orgchartStore.getCurrentOrgchartId);
  const [orgchartData, setOrgchartData] = useState<RolesWithOrgchartDto | null>(null);
  const [orgchartDataToEdit, setOrgchartDataToEdit] = useState<OrgchartDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSoftLoading, setIsSoftLoading] = useState<boolean>(false);
  const switcherRef = createRef<any>();
  // let Sticky = require("../../../scripts/sticky.js");

  const orgchartFilterTags = [
    { text: t("parse:employee_filter"), value: true, key: "employeeFilter" },
    { text: t("parse:result_filter"), value: true, key: "resultFilter" },
    { text: t("parse:description_filter"), value: false, key: "descriptionFilter" },
    { text: t("parse:show_metrics"), value: false, key: "withMetrics" },
  ];
  // orgchartFilterTags.push({ text: t("parse:show_metrics"), value: false, key: "withMetrics" });

  // const currentCompanyId = authStore.getCurrentCompanyId;
  // if (
  //   currentCompanyId === 2 ||
  //   currentCompanyId === 1311 ||
  //   currentCompanyId === 20 ||
  //   currentCompanyId === 907 ||
  //   currentCompanyId === 1355
  // ) {
  //   orgchartFilterTags.push({ text: t("parse:show_metrics"), value: false, key: "withMetrics" });
  // }

  const [orgchartFilters, setOrgchartFilters] = useState<IOrgchartFilter>(
    () => LocalStorageHelpers.get("orgchartFilters") ?? {
      employeeFilter: true,
      resultFilter: true,
      descriptionFilter: false,
      withMetrics: false,
    }
  );

  const isFirstRender = useRef(true);

  const handleChangeOrgchartFilters = useCallback((key, value) => {
    setOrgchartFilters((prev: any) => ({
      ...prev,
      [key]: value
    }));
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleRefresh(true);
  }, [orgchartFilters.withMetrics]);

  const [orgchartMenuItems, setOrgchartMenuItems] = useState<PopupMenuItemTypes[]>([
    { id: 1, text: t("common:misc.all_recurring_tasks"), action: () => handleAllTasksShow() },
    { id: 2, text: t("common:misc.print_orgchart"), action: () => handleOrgchartPrint() },
  ]);
  const [isDeleteOrgchartDialogOpen, setIsDeleteOrgchartDialogOpen] = useState<boolean>(false);

  const currentDrawType = useMemo(
    () =>
      orgchartStore.getCurrentOrgchart?.type?.startsWith(ORGCHART_CLASSIC_TYPE) || orgchartStore.getCurrentOrgchart?.type?.startsWith(ORGCHART_SIMPLIFIED_TYPE)
        ? ORGCHART_CLASSIC_TYPE
        : ORGCHART_DEFAULT_TYPE,
    [orgchartStore.getCurrentOrgchart?.type]
  );

  // useEffect(() => {
  //   const dT = orgchartStore.getCurrentOrgchart?.type;
  //   if (dT != drawType && dT != null) {
  //     setDrawType(dT as "d" | "c");
  //   }
  // }, [authStore.getInitialInfo, orgchartStore.getOrgchartsList, orgchartStore.getCurrentOrgchart]);

  useEffect(() => {
    LocalStorageHelpers.set("orgchartFilters", orgchartFilters);
  }, [orgchartFilters]);

  const claims = useClaims();

  // const orgchartData = useApi(() => api.orgchart.getTreeWithRoles(orgchartId as number));

  const fetchOrgchartData = async (oId?: number, softReload?: boolean) => {
    oId != null && orgchartStore.setCurrentOrgchartId(oId);
    if (orgchartStore.getCurrentOrgchartId == null || orgchartStore.getCurrentOrgchart?.isEmpty) {
      setIsLoading(false);
      setIsSoftLoading(false);
      setOrgchartData(null);
      return;
    }

    // !isLoading && setIsSoftLoading(true);
    !isLoading && !softReload && setIsLoading(true);
    !isSoftLoading && softReload && setIsSoftLoading(true);

    const r = await api.orgchart
      .getTreeWithRoles(orgchartStore.getCurrentOrgchartId, {
        withMetrics: orgchartFilters.withMetrics,
    });
    setIsSoftLoading(false);
    setOrgchartData(r);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    if (r == null) {
      return;
    }
  };

  const handleOrgchartCreate = () => {
    switcherRef && switcherRef.current && switcherRef.current.openCreateDialog();
  };

  const openCreateDialog = useRef<any>(null);

  const [isOrgchartSaveTemplateDialog, setIsOrgchartSaveTemplateDialog] = useState<boolean>(false);

  const handleClickDuplicate = useCallback(async () => {
    setIsLoading(true);
    const resp = await api.orgchart.duplicateOrgchart(orgchartStore.getCurrentOrgchartId!);
    if (resp === null) {
      setIsLoading(false);
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
    } else {
      orgchartStore.addedOrgchart(resp);
      notifier.show({ message: t("notifier:success.orgchart_duplicate"), theme: "success" });
    }
  }, [orgchartStore.getCurrentOrgchartId]);

  useEffect(() => {
    let menu: PopupMenuItemTypes[] = [...orgchartMenuItems];

    if (claims.has(permissionKeys.orgchart.edit) && !menu.some((m) => m.id === 0)) {
      menu = [
        {
          id: 0,
          text: t("parse:edit_orgchart"),
          action: () => {
            setOrgchartDataToEdit(orgchartStore.getCurrentOrgchart as OrgchartDto);
            openCreateDialog.current();
          },
        },
        ...menu,
      ];
    }

    if (claims.has(permissionKeys.orgchart.template.edit) && !menu.some((m) => m.id === 3)) {
      menu = [
        {
          id: 3,
          text: t("parse:save_template"),
          action: () => setIsOrgchartSaveTemplateDialog(true),
        },
        ...menu,
      ]
    }

    if (claims.has(permissionKeys.orgchart.add) && !menu.some((m) => m.id === 4)) {
      menu = [
        ...menu,
        { id: 4, text: t("common:misc.copy_orgchart"), action: handleClickDuplicate },
      ];
    }

    if (claims.has(permissionKeys.orgchart.delete) && !menu.some((m) => m.id === 5)) {
      menu = [
        ...menu,
        { id: 5, text: t("common:misc.delete_orgchart"), action: () => setIsDeleteOrgchartDialogOpen(true) },
      ];
    }

    if (!claims.has(permissionKeys.orgchart.edit)) {
      menu = menu.filter((m) => m.id !== 0);
    }

    // if (!claims.has(permissionKeys.orgchart.add)) {
    //   menu = menu.filter((m) => m.id !== 0);
    // }

    // if (!claims.has(permissionKeys.orgchart.template.edit)) {
    //   menu = menu.filter((m) => m.id !== 3);
    // }

    // if (!claims.has(permissionKeys.orgchart.add)) {
    //   menu = menu.filter((m) => m.id !== 0);
    // }


    if (!claims.has(permissionKeys.orgchart.delete)) {
      menu = menu.filter((m) => m.id !== 5);
    }
    setOrgchartMenuItems(menu);
  }, [authStore.getInitialInfo, orgchartData]);

  const handleRefresh = async (softReload?: boolean) => {
    await fetchOrgchartData(undefined, softReload);
  };
  // TODO: Проверить отрисовку линий

  useEffect(() => {
    if (switcherRef.current == null || switcherRef.current.openCreateDialog == null) return;
    openCreateDialog.current = switcherRef.current.openCreateDialog;
  }, [switcherRef]);

  const changeState = (state: IOrgchartContext) => {
    setOrgchartContextState({ ...orgchartContextInitState, ...state });
  };

  const resetState = () => {
    setOrgchartContextState((prev) => ({
      ...orgchartContextInitState,
      orgchartTemplates: prev.orgchartTemplates
    }));
  };


  const getTemplatesList = async (type: OrgchartTemplateTypeEnum | null) => {
    if (type) {
      const r = await api.orgchart.getTemplates(type);
      if (r === null) {
        return []
      }
      return r.sort((item1: OrgchartTemplateDto, item2: OrgchartTemplateDto) => {
        if (item1?.sequence && item2?.sequence) {
          return item1?.sequence - item2?.sequence
        }
        return 0
      });
    }
  }

  const fetchOrgchartTemplate = useCallback(async () => {
    const templatesClassic: OrgchartTemplateDto[] | undefined = await getTemplatesList(ORGCHART_CLASSIC_TYPE);
    const templatesSimple: OrgchartTemplateDto[] | undefined =  await getTemplatesList(ORGCHART_SIMPLIFIED_TYPE);
    return {
      [ORGCHART_CLASSIC_TYPE]: templatesClassic,
      [ORGCHART_SIMPLIFIED_TYPE]: templatesSimple,
    }
  }, []);

  const getTemplates = async () => {
    const orgchartTemplates = await fetchOrgchartTemplate();
    setOrgchartContextState((prev) => ({
      ...prev,
      orgchartTemplates,
    }));
  }

  useEffect( () => {
    getTemplates();
  }, []);

  const handleEditMetric = useCallback((metric: MetricDto) => {
    setEditMetric(metric);
    setIsEditMetricDialogOpen(true);
  }, []);

  const orgchartContextInitState: IOrgchartContext = {
    openedDialog: null,
    blockId: null,
    updateState: changeState,
    resetState: resetState,
    refreshState: handleRefresh,
    orgchartTemplates: [],
    handleEditMetric,
  };

  const handleOrgchartDelete = async () => {
    setIsLoading(true);
    const r = await api.orgchart.del(orgchartId as number);
    await authStore.refreshInitialInfo();
    setIsDeleteOrgchartDialogOpen(false);
    await fetchOrgchartData();
    setIsLoading(false);
  };

  const handleOrgchartTypeSelect = async (type: any) => {
    if (orgchartId == null) return;
    const r = await api.orgchart.applyTemplate(orgchartId, type);
    if (r == null || !r) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      return;
    }
    if (
      (authStore.getInitialInfo?.orgcharts ?? []).length == 0 ||
      (authStore.getInitialInfo?.orgcharts ?? []).length == 1
    ) {
      await authStore.authorizeWithRefreshToken();
    }
    await authStore.refreshInitialInfo();
    // orgchartStore.setCurrentOrgchartId(orgchartStore.getCurrentOrgchartId);
    await fetchOrgchartData(orgchartStore.getCurrentOrgchartId ?? undefined);
  };

  const handleOrgchartPrintFromSafari = () => {
    const popup = window.open("", "_blank", "width=1,height=1,left=100,top=100");
    if (!popup) return;
    popup.addEventListener("afterprint", (event) => {
      popup.close();
      setPrintBlockId(null);
    });
    const content = document.getElementById("pageContent");
    if (!content) return;
    popup.document.head.innerHTML = document.head.innerHTML;
    popup.document.body.innerHTML = content.innerHTML;
    setTimeout(() => {
      popup.print();
      setTimeout(() => {
        popup.close();
        setPrintBlockId(null);
      });
    });
  };

  const [printBlockId, setPrintBlockId] = useState<number | null>(null);
  const handleOrgchartBlockPrint = useCallback((id: number) => {
    setPrintBlockId(id);
  }, []);


  const handleOrgchartPrint = () => {
    if (!isSafari) {
      window.onafterprint = () => {
        setPrintBlockId(null);
      };
      return window.print();
    }
    handleOrgchartPrintFromSafari();
  };

  useEffect(() => {
    if (printBlockId) {
      handleOrgchartPrint()
    }
  }, [printBlockId]);

  // const sticky = new Sticky(".orgchart-card__content__wrapper");

  const [orgchartContextState, setOrgchartContextState] = useState<IOrgchartContext>(orgchartContextInitState);
  const [zoom, setZoom] = useLocalStorage<number>("zoomOrgchart", 100);

  useEffect(() => {
    if (roleId != null && roleId.length > 0) {
      setTimeout(() => {
        setOrgchartContextState({
          ...orgchartContextInitState,
          ...{ blockId: Number(roleId), openedDialog: "openFunction" },
        });
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (orgchartContextState.openedDialog == "openFunction") {
      changeURL("/orgchart/" + (orgchartContextState.blockId ?? ""));
    } else if (orgchartContextState.openedDialog == null) {
      changeURL("/orgchart");
    }
  }, [orgchartContextState.blockId]);

  useEffect(() => {
    LocalStorageHelpers.set("orgchartZoom", zoom);
  }, [zoom]);

  const changeURL = (text: string) => {
    // encodeURIComponent(text)
    // const queryParams = window.location.search.substring(1);
    // const parsedQuery =
    //   queryParams.trim().length > 0
    //     ? JSON.parse(
    //         '{"' + decodeURI(queryParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    //       )
    //     : {};
    const queryParams = window.location.search;
    window.history.pushState({}, "", text + queryParams ?? "");
  };

  const handleAllTasksShow = () => {
    setOrgchartContextState({
      ...orgchartContextState,
      openedDialog: "openDrawer/recurringTasks",
    });
  };

  const getRoleDataById = useCallback((roleId: number): RoleDto | null =>
      orgchartData?.roles?.find((r) => r.id == roleId) ?? null,
    [orgchartData?.roles]);

  const getSortedChildren = (childrenRoles: OrgchartItemDto[]): OrgchartItemDto[] => {
    return [
      ...childrenRoles.sort(
        (a, b) => (getRoleDataById(a.roleId as number)?.order ?? 0) - (getRoleDataById(b.roleId as number)?.order ?? 0)
      ),
    ];
  };
  useEffect(() => {
    setOrgchartId(orgchartStore.getCurrentOrgchartId);
  }, [orgchartStore.getCurrentOrgchartId]);

  useEffect(() => {
    if (orgchartId == null && isLoading) {
      setIsLoading(false);
      return;
    }
    fetchOrgchartData();
  }, [orgchartId]);

  const visibilityFilters = useMemo(() => {
    const { employeeFilter: employee, resultFilter: result, descriptionFilter: description } = orgchartFilters;
    return {
      employee,
      result,
      description,
    }
  }, [orgchartFilters]);

  const orgchartParams = useMemo(() => ({
    isRootRole: false,
    isVertical: false,
    isRootRoleChild: false,
    isLastChild: false,
    hasParent: false,
    blockId: orgchartData?.root?.roleId,
  }), [orgchartData?.root?.roleId]);

  const roles = useMemo(() => {
    return orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? [];
  }, [orgchartData?.roles]);

  const orgchartBlockData = useMemo(() => {
    return orgchartData?.root ?? null
  }, [orgchartData?.root]);

  const orgchartBlockRole = useMemo(() => {
    return orgchartData?.root?.roleId != null ? getRoleDataById(orgchartData?.root?.roleId) : null
  }, [orgchartData?.root?.roleId, orgchartData?.roles]);

  const [isEditMetricDialogOpen, setIsEditMetricDialogOpen] = useState<boolean>(false);
  const [editMetric, setEditMetric] = useState<MetricDto | null>(null);

  const onCreateEditDialogOpenChange = useCallback((value: any) => {
    setIsEditMetricDialogOpen(value);
  }, []);

  const handleMetricEdit = useCallback(() => {
    setIsEditMetricDialogOpen(false);
    handleRefresh();
    setEditMetric(null);
  }, []);

  const handleChangePosition = useCallback((oldIndex: number, newIndex: number) => {

  }, []);

  const isDevMode =
    window.location?.hostname == "localhost" ||
    window.location?.hostname == "beta.tonnus.io" ||
    window.location.hostname == "dev.bbooster.io";

  return (
    // @ts-ignore
    <OrgchartContext.Provider value={orgchartContextState}>
      <VideoDialog
        trainingKey="ed.o.s"
        name="orgchartSettings"
        onWatchedClickObject={{ orgchartSettings: true }}
        hidden={
          !claims.hasOneOf([
            permissionKeys.orgchart.role.add,
            permissionKeys.orgchart.role.delete,
            permissionKeys.orgchart.role.sort,
            permissionKeys.orgchart.role.edit,
            permissionKeys.orgchart.role.move,
          ])
        }
      />
      <OrgchartSaveTemplateDialog
        open={isOrgchartSaveTemplateDialog}
        onClose={() => setIsOrgchartSaveTemplateDialog(false)}
      />
      <CustomConfirmDialog
        open={isDeleteOrgchartDialogOpen}
        onClose={() => {
          setIsDeleteOrgchartDialogOpen(false);
        }}
        loading={isLoading}
        onConfirm={() => handleOrgchartDelete()}
        title={t("parse:delete_orgchart_dialog.title")}
        subTitle={t("parse:delete_orgchart_dialog.text")}
      />
      {/* TODO: Normal dialog */}
      <VideoDialog
        trainingKey="ed.o.i"
        name="orgchart"
        onWatchedClickObject={{ orgchart: true }}
        hidden={authStore.getInitialInfo?.identity?.name?.trim().length == 0}
      />
      {isEditMetricDialogOpen && (
        <MetricCreateEditDialog
          open={isEditMetricDialogOpen}
          toggleDialog={onCreateEditDialogOpenChange}
          editMode={true}
          onSuccess={handleMetricEdit}
          metricId={editMetric?.id}
          metrics={editMetric ? [editMetric] : []}
        />
      )}
      {/*authStore.getInitialInfo?.identity?.companies?.find(*/}
      {/*(u2c: User2CompanyDto) => u2c.companyId == authStore.getInitialInfo?.identity?.currentCompanyId*/}
      {/*)?.company?.orgchartType == null*/}
      <PageHeader>
        <OrgchartSwitcher
          ref={switcherRef}
          drawType="sortTextWithMenu"
          isViewUploadFile={isDevMode}
          createEditDialogValue={orgchartDataToEdit ?? undefined}
          onCreate={(oId) => fetchOrgchartData(oId)}
          onSave={() => {
            setOrgchartDataToEdit(null);
            authStore.refreshInitialInfo().then(() => fetchOrgchartData());
          }}
          onCreateEditDialogClose={() => {
            setOrgchartDataToEdit(null);
          }}
        />
        {!!orgchartStore.getOrgchartsList.length && (
          <OpeningGuideIcon ml={8} contentKey={guideKeys.multiaccount} />
        )}
        {!orgchartStore.getCurrentOrgchart?.isEmpty && orgchartId != null && (
          <div className="ml-auto d-stack spacing-2">
            <ZoomButton
              value={zoom}
              onChange={setZoom}
              min={1}
              max={200}
            />
            <TypeFunction
              items={orgchartFilterTags}
              filterValue={orgchartFilters}
              onChange={handleChangeOrgchartFilters}
            />
            <DropdownMenu items={orgchartMenuItems.sort((a, b) => a.id - b.id)} />
          </div>
        )}
      </PageHeader>
      <PageContent
        isLoading={isLoading || isSoftLoading}
        isDraggable
        isFilled={orgchartStore.getCurrentOrgchart != null && orgchartStore.getCurrentOrgchart?.isEmpty != true}
        className="orgchart-page__wrapper"
        contentPosition={{
          isCentered: orgchartStore.getCurrentOrgchart == null || orgchartStore.getCurrentOrgchart?.isEmpty == true,
        }}
        // zoomProperty="transform"
        zoom={{ percentage: orgchartStore.getCurrentOrgchart?.isEmpty ? 1 : zoom, strategy: "combined" }}
        // pageMap={{}}
      >
        <OrgchartFilterContext.Provider
          value={{
            orgchartFilters,
          }}
        >
          {/*COMMON DRAWER*/}
          <OrgchartCommonDrawer
            open={orgchartContextState.openedDialog?.includes("openDrawer") == true}
            onClose={() => {
              setOrgchartContextState({ ...orgchartContextState, openedDialog: null });
              setTimeout(() => {
                resetState();
              }, 300);
            }}
          />
          {/*FUNCTION DRAWER*/}
          <OrgchartFunctionDrawer
            isOpen={orgchartContextState.openedDialog == "openFunction"}
            roles={orgchartData?.roles ?? []}
            roleId={orgchartContextState.blockId as number}
            onClose={() => {
              setOrgchartContextState({ ...orgchartContextState, openedDialog: null });
              setTimeout(() => {
                resetState();
              }, 300);
            }}
          />
          {/*ADD DIALOG*/}
          {orgchartContextState.openedDialog == "add" && (
            <OrgchartFunctionCreateEditDialog
              allRoles={orgchartData?.roles ?? []}
              functionId={orgchartContextState.blockId as number}
              onClose={() => {
                resetState()
              }}
              parentColor={orgchartData?.roles?.find((r) => r.id == orgchartContextState.blockId)?.colorHex ?? undefined}
              onSave={() => {
                handleRefresh(true);
                resetState();
              }}
            />
          )}
          {/*EDIT DIALOG*/}
          {orgchartContextState.openedDialog == "edit" && (
            <OrgchartFunctionCreateEditDialog
              allRoles={orgchartData?.roles ?? []}
              roleId={orgchartContextState.blockId as number}
              onClose={() => {
                resetState();
              }}
              onSave={() => {
                handleRefresh(true);
                resetState();
              }}
            />
          )}
          {/*MOVE DIALOG*/}
          {orgchartContextState.openedDialog == "move" && (
            <OrgchartFunctionMoveDialog
              allRoles={orgchartData?.roles ?? []}
              roleId={orgchartContextState.blockId as number}
              onClose={() => {
                resetState()
              }}
              onSave={() => {
                handleRefresh(true);
                resetState();
              }}
            />
          )}
          {/*SORT DIALOG*/}
          {orgchartContextState.openedDialog == "sort" && (
            <OrgchartSortDialog
              allRoles={orgchartData?.roles ?? []}
              roleId={orgchartContextState.blockId as number}
              onClose={() => {
                resetState()
              }}
              onSave={() => {
                handleRefresh(true);
                resetState();
              }}
            />
          )}
          {/*DELETE DIALOG*/}
          {orgchartContextState.openedDialog == "delete" && (
            <OrgchartFunctionDeleteDialog
              allRoles={orgchartData?.roles}
              roleId={orgchartContextState.blockId}
              onClose={() => {
                resetState();
              }}
              onDelete={() => {
                handleRefresh(true);
                resetState();
              }}
            />
          )}
          {/* MAIN CONTENT */}
          {/* MAIN CONTENT */}
          {/* MAIN CONTENT */}
          {!isLoading && orgchartStore.getOrgchartsList.length == 0 && (
            <div className="d-stack-column spacing-12">
              <Text
                // type="secondary"
                weight="bold"
                size="32px"
                style={{ opacity: "0.8", whiteSpace: "pre", textAlign: "center" }}
                children={t("parse:no_orgchart")}
              />
              {claims.has(permissionKeys.orgchart.add) && (
                <Button
                  className="align-self-center"
                  onClick={handleOrgchartCreate}
                  size="large"
                  children={t("parse:create_orgchart")}
                />
              )}
            </div>
          )}
          {!isLoading && orgchartStore.getCurrentOrgchart?.isEmpty == true && (
            <OrgchartTypeSelectForm onCreate={handleOrgchartTypeSelect} />
          )}
          {!isLoading && orgchartStore.getCurrentOrgchart?.isEmpty == false && (
            <OrgchartVfpCard
              isHiddenPrintBlock={!!printBlockId}
              orgchartId={orgchartId}
              setIsSoftLoading={setIsSoftLoading}
              value={orgchartStore.getCurrentOrgchart.valuableFinalProduct}
            />

            // (orgchartStore.getCurrentOrgchart.valuableFinalProduct ?? "").trim().length > 0 &&
            // <div
            //   className="pa-1 mb-1 d-inline-block full-width __orgchart-vfp-block"
            //   style={{ position: "sticky", left: 0 }}
            // >
            //   <div
            //     className="br-md pa-3 d-stack-row spacing-1 align-baseline"
            //     style={{ background: "var(--color-layout-divider)" }}
            //   >
            //     <Text
            //       className="flex-shrink-0"
            //       weight="bold"
            //       size="14px"
            //       children={orgchartStore.getCurrentOrgchartResultName + ":"}
            //     />
            //     <Text
            //       size="14px"
            //       editable={
            //         claims.has(permissionKeys.orgchart.edit)
            //           ? {
            //               triggerType: ["icon", "text"],
            //               onChange: handleVfpUpdate,
            //               autoSize: true,
            //             }
            //           : false
            //       }
            //       className="flex-grow-1 __vfp-editable"
            //       weight="normal"
            //       children={" " + (orgchartStore.getCurrentOrgchart.valuableFinalProduct ?? "")}
            //     />
            //   </div>
            // </div>
          )}
          {orgchartStore.getCurrentOrgchart?.isEmpty == false && (
            <div
              id="orgchart__wrapper"
              className={`d-inline-flex __pztScale orgchart-wrapper ${isLoading ? 'orgchart-wrapper_loading' : ''}`}
            >
              {currentDrawType === ORGCHART_DEFAULT_TYPE && (
                <OrgchartFunctionCardHubbard
                  orgchartDrawType={ORGCHART_DEFAULT_TYPE}
                  zoom={zoom}
                  roles={roles}
                  printBlockId={printBlockId}
                  handleOrgchartBlockPrint={handleOrgchartBlockPrint}
                  blockData={orgchartBlockData}
                  blockRole={orgchartBlockRole}
                  params={orgchartParams}
                />
              )}
              {currentDrawType == ORGCHART_CLASSIC_TYPE && (
                <>
                  <OrgchartFunctionCardHubbard
                    orgchartDrawType={ORGCHART_CLASSIC_TYPE}
                    zoom={zoom}
                    printBlockId={printBlockId}
                    handleOrgchartBlockPrint={handleOrgchartBlockPrint}
                    roles={orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? []}
                    blockData={orgchartData?.root ?? null}
                    blockRole={orgchartData?.root?.roleId != null ? getRoleDataById(orgchartData?.root?.roleId) : null}
                    params={{
                      isRootRole: true,
                      isVertical: false,
                      isRootRoleChild: false,
                      isLastChild: false,
                      hasParent: false,
                      blockId: orgchartData?.root?.roleId,
                    }}
                  />
                  <OrgchartFunctionCardHubbard
                    orgchartDrawType={ORGCHART_CLASSIC_TYPE}
                    zoom={zoom}
                    printBlockId={printBlockId}
                    handleOrgchartBlockPrint={handleOrgchartBlockPrint}
                    roles={orgchartData?.roles?.sort((a: any, b: any) => a.order - b.order) ?? []}
                    blockData={getSortedChildren(orgchartData?.root?.children ?? [])?.[1] ?? null}
                    blockRole={
                      (getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId != null
                        ? getRoleDataById(getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId as number)
                        : null) as any
                    }
                    params={{
                      isRootRole: false,
                      isVertical: false,
                      isRootRoleChild: true,
                      isLastChild: false,
                      hasParent: false,
                      blockId: getSortedChildren(orgchartData?.root?.children ?? [])?.[1]?.roleId,
                    }}
                  />
                </>
              )}
            </div>
          )}
        </OrgchartFilterContext.Provider>
      </PageContent>

    </OrgchartContext.Provider>
  );
}

export default observer(OrgchartPage);
