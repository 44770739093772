import React from "react";
import { Button, Dialog, DialogActions } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";
import { IGoogleAccountDisconnectDialog } from "./GoogleAccountDisconnectDialog.Interface";
import "./GoogleAccountDisconnectDialog.scss";

export const GoogleAccountDisconnectDialog = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
 }: IGoogleAccountDisconnectDialog) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("ui:google_account_disconnect_dialog.title")}
      width={525}
      open={isOpen}
    >
      <div className="google-account-disconnect-dialog-text">
        <p>
          {t("ui:google_account_disconnect_dialog.text")}
        </p>
        <p>
          {t("ui:google_account_disconnect_dialog.question")}
        </p>
      </div>
      <DialogActions>
        <>
          <Button
            onClick={onClose}
            variant="default"
          >
            {t("ui:button.cancel")}
          </Button>
          <Button
            onClick={onConfirm}
            loading={isLoading}
          >
            {t("ui:button.confirm")}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  )
}