import React, { useCallback, useEffect, useRef, useState } from "react";
import "./PageContent.scss";
import { MenuItemDto } from "../../../../api";
import PageTabs from "../settingsPageTabs/pageTabs";
import clsx from "clsx";
import { Card, Spin } from "../../../uiKit";
import { useScrollContainer } from "../../../../hooks";
// import PageMap from "../../../service/pageMap/PageMap";
// import useResizeObserver from "use-resize-observer";

class AppPageTabType {}

interface IPageContentPosition {
  maxWidth?: string;
  position?: "start" | "center" | "end";
  isCentered?: boolean;
}

interface IScaledWrapperSize {
  width: undefined | number;
  height: undefined | number;
}

interface IPageContentZoom {
  percentage?: number;
  strategy?: "combined" | "zoom" | "transform";
}

// При strategy == "combined" элементы внутри <PageContent />
// разделяются на две группы:
// с className="__pztZoom" масштабируются с помощью CSS-свойства Zoom,
// с className="__pztScale" - с помощью CSS Transform Scale

interface IPageContent {
  className?: string;
  wrapperClassName?: string;
  isFilled?: boolean;
  isLoading?: boolean;
  isDraggable?: boolean;
  tabs?: MenuItemDto[] | AppPageTabType[] | null;
  zoom?: IPageContentZoom;
  // Add draggable ignore elements
  useTranslationInTabs?: boolean;
  contentPosition?: IPageContentPosition;
  // pageMap?: IPageContentMap;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  wrapperBodyStyle?: React.CSSProperties;
  innerDivProps?: React.InsHTMLAttributes<HTMLDivElement>;
  children?: React.ReactNode;
  isNewDesign?: boolean;
}

// Add pageMap

function PageContent(props: IPageContent) {
  const scaledElementRef = useRef<HTMLDivElement>(null);
  const [scaledWrapperSize, setScaledWrapperSize] = useState<IScaledWrapperSize>({
    width: undefined,
    height: undefined,
  });
  const { ref: draggableElementRef } = useScrollContainer({
    mouseScroll: {
      ignoreElements: "textarea",
    },
  });

  const recalculateScaledWrapperSize = useCallback(() => {
    const _scaledElementHeight = scaledElementRef.current?.clientHeight;
    const _scaledElementWidth = scaledElementRef.current?.clientWidth;
    const _ratio = (props?.zoom?.percentage ?? 100) / 100;

    const height =
      _scaledElementHeight == null || props?.zoom?.percentage == null ? undefined : _scaledElementHeight * _ratio + 1;
    const width =
      _scaledElementWidth == null || props?.zoom?.percentage == null ? undefined : _scaledElementWidth * _ratio + 1;

    if (height === scaledWrapperSize.height && width === scaledWrapperSize.width) {
      return;
    }
    console.log(
      _scaledElementHeight == null || props?.zoom?.percentage == null ? undefined : _scaledElementHeight * _ratio + 1
    );
    console.log(
      _scaledElementWidth == null || props?.zoom?.percentage == null ? undefined : _scaledElementWidth * _ratio + 1
    );
    setScaledWrapperSize({
      height,
      width,
    });
  }, [props?.zoom?.percentage, scaledWrapperSize.height, scaledWrapperSize.width]);

  useEffect(() => {
    props.zoom != null && recalculateScaledWrapperSize();
  }, [props.zoom, props.children]);

  return (
    <>
      {props.tabs && props.tabs.length > 0 && (
        <PageTabs
          useTranslation={!!props.useTranslationInTabs}
          tabs={props.tabs}
          isNewDesign={props.isNewDesign}
        />
      )}
      {/*{props.pageMap != null && window.location?.hostname == "localhost" && <PageMap />}*/}
      <Card
        variant={!props.isFilled ? "transparent" : undefined}
        className={clsx("page-content__wrapper", props.wrapperClassName)}
        style={{
          maxWidth: props.contentPosition?.maxWidth,
          marginLeft:
            props.contentPosition?.position == "center" ||
            props.contentPosition?.position == "end" ||
            props.contentPosition?.position == null
              ? "auto"
              : undefined,
          marginRight:
            props.contentPosition?.position == "center" ||
            props.contentPosition?.position == "start" ||
            props.contentPosition?.position == null
              ? "auto"
              : undefined,
          ...props.wrapperStyle,
        }}
        bodyStyle={{
          padding: !props.isFilled ? 0 : "8px",
          ...props.wrapperBodyStyle,
        }}
      >
        <Spin
          wrapperClassName="page-content__loader"
          spinning={!!props.isLoading}
          size="large"
          // tip={t("text:loading_data")}
        >
          <div
            id="pageContent"
            ref={props.isDraggable ? draggableElementRef : undefined}
            className={clsx("page-content__content", `__zoom-value-${props.zoom?.percentage ?? 100}`, props.className, {
              __centered: props.contentPosition?.isCentered,
              "__zoom-combined": props.zoom?.strategy == "combined",
              "__zoom-transform": props.zoom?.strategy == "transform",
              "__zoom-zoom": props.zoom?.strategy == "zoom" || props.zoom?.strategy == null,
            })}
            style={{
              zoom:
                props.zoom?.percentage != null && (props.zoom.strategy == "zoom" || props.zoom?.strategy == null)
                  ? `${props.zoom?.percentage ?? 100}%`
                  : undefined,
              ...props.style,
            }}
            {...props.innerDivProps}
            children={props.children}
          />
        </Spin>
      </Card>
    </>
  );
}

export default PageContent;
