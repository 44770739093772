import { IssueDto, Plan2IssueDto, PlanDto } from "../../../../../../api";

export enum PlaningFilterValue {
  unfinishedTasks = "unfinishedTasks",
  unallocatedTasks = "unallocatedTasks",
}

export interface filterList {
  text: string;
  value: PlaningFilterValue;
}

export interface IPlanningPageSubordinatesSidebar {
  issues: IssueDto[];
  isLoading: boolean;
  isActiveFilter: boolean;
  // plan?: PlanDto;
  planId: number | undefined;
  fetchPlans?: (withoutStateReset?: boolean | undefined) => void;
  issuesFromCommunicationsRestart?: () => void;
  // issuesRestart?: (withoutStateReset?: boolean | undefined) => void;
  onSidebarOpenChange?: () => void;
  isSidebarOpen?: boolean;
  loadNext: () => void;
  isDone: boolean;
  filterList: filterList[];
  filter: PlaningFilterValue;
  onChangeFilter: (value: PlaningFilterValue) => void;
  onAddIssueToPlan: (issueId: number) => Promise<boolean>;
  totalTimePlan: string;
  totalItems: number;
}
// export interface IPlanningPageSubordinatesSidebarView extends Omit<IPlanningPageSubordinatesSidebar, "planId"> {

export interface IPlanningPageSubordinatesSidebarView extends Omit<IPlanningPageSubordinatesSidebar, "onAddIssueToPlan"> {
  onAddIssue: (issueId: number, issue?: Plan2IssueDto) => void;
  loadingTaskId: number | undefined;
}
