import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { IIssueActivityComment } from "./IssueActivityComment.interface";
import { HistoryCard } from "../../../../../../../uiKit/History/HistoryCard";
import {
  HistoryDto, type HistoryReactionDto,
  IssueCommentAttachmentDto,
  type IssueCommentRecipientDto, PlanCommentDto, type RegulationCommentDto, StaticFileDto,
  type UserShortDto
} from "../../../../../../../../api";
import { api } from "../../../../../../../../services";
import { useNotifier } from "../../../../../../../../hooks";
import { useTranslation } from "react-i18next";


export const IssueActivityComment = memo((props: IIssueActivityComment) => {
    const handleSaveClick = async (id: number, data: HistoryDto) => {
      const { attachments, comment } = data;
      props.onEditComment(comment?.content ?? "", attachments);
    return true;
  }
  const handleRemoveComment = async (id: number) => {
    const status = await props.onDeleteComment(id);
    return status;
  }


  const comment: HistoryDto = useMemo(() => {
    const { issueHistoryId: id, text , attachments: attachmentsList = [] } = props.item.comment ?? {};
    const attachments = attachmentsList
      ? attachmentsList.map(({file}) => file!) : [];
    return {
      id,
      text: text ?? "",
      attachments,
    };
  }, [props.item.comment]);

  console.log("props.item.comment", props.item);
  return (
    <HistoryCard
      historyId={props.item.comment?.issueHistoryId!}
      item={comment}
      dateCreated={props.item.dateCreated}
      index={0}
      // reactions={props.item.reactions ?? []}
      reactions={[]}
      creatorId={props.item.createdByUserId!}
      creator={props.item.createdByUser!}
      isEditing={props.item.createdByUserId === props.user?.id}
      editCommentId={props.issueHistoryId}
      commentContent={props.item.comment?.text ?? undefined}
      commentAttachments={props.item.comment?.attachments ?? []}
      handleEditClick={props.onIssueHistoryIdChange}
      handleSaveClick={handleSaveClick}
      handleRemoveComment={handleRemoveComment}
      reactionsStrategyController={api.issueHistory}
      isHiddenUser
      isHiddenReactions
      isSimplifiedToolbar
    />
  )
})
