import React from "react";
import { Breadcrumbs } from "../../../../../elements/breadcrumbs/Breadcrumbs";
import { breadcrumbsKeys } from "../../../../../elements/breadcrumbs/Breadcrumbs.interface";

export const HeaderBreadcrumbs = () => {
  return (
    <div className="regulation-header__align">
      <Breadcrumbs type={breadcrumbsKeys.regulation} />
    </div>
  );
};
