import React from "react";
import { ICompaniesManagementInfoBlock } from "./CompaniesManagementInfoBlock.interface";
import "./CompaniesManagementInfoBlock.scss";

export const CompaniesManagementInfoBlock = (props: ICompaniesManagementInfoBlock) => {
  return (
    <div className="companies-management-info-block">
      <div className="companies-management-info-block__title">
        {props.text}
      </div>
      <div className="companies-management-info-block__number">
        {props.count}
      </div>
    </div>
  )
}
