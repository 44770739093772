import React from "react";
import { IRegulationIcons } from "./RegulationIcons.interface";
import { IconButton, Tooltip } from "../../../../../uiKit";
import { useTranslation } from "react-i18next";

export const DeleteButton: React.FC<IRegulationIcons> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("ui:tooltip.delete")}>
      <IconButton
        className="regulation-button-icons__delete"
        loading={props.loading}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.15986 2.04631H5.96287C6.07121 2.04631 6.15986 1.95767 6.15986 1.84933V2.04631H13.6453V1.84933C13.6453 1.95767 13.734 2.04631 13.8423 2.04631H13.6453V3.81919H15.4182V1.84933C15.4182 0.980125 14.7115 0.273438 13.8423 0.273438H5.96287C5.09367 0.273438 4.38698 0.980125 4.38698 1.84933V3.81919H6.15986V2.04631ZM18.57 3.81919H1.23521C0.799378 3.81919 0.447266 4.1713 0.447266 4.60713V5.39507C0.447266 5.50342 0.535909 5.59206 0.644252 5.59206H2.1315L2.73969 18.47C2.77909 19.3097 3.47346 19.972 4.31312 19.972H15.4921C16.3342 19.972 17.0261 19.3121 17.0655 18.47L17.6737 5.59206H19.1609C19.2693 5.59206 19.3579 5.50342 19.3579 5.39507V4.60713C19.3579 4.1713 19.0058 3.81919 18.57 3.81919ZM15.3025 18.1992H4.50271L3.90683 5.59206H15.8984L15.3025 18.1992Z" />
        </svg>
      </IconButton>
    </Tooltip>
  );
};
