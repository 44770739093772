import React from "react";
import "./Dialog.scss";
import { Modal, ModalProps } from "antd";
import { TDialogScrollStrategy } from "./Dialog";
import clsx from "clsx";

interface IDialogView extends ModalProps {
  warning?: boolean;
  scrollStrategy?: TDialogScrollStrategy;
  isNewDesign?: boolean;
}

export function DialogView({ className, scrollStrategy, isNewDesign, ...rest }: IDialogView) {
  return (
    <Modal
      className={clsx("uiKit-dialog", className ?? "", {
        "__ss-dialog-body": scrollStrategy == "dialogBody",
        "uiKit-dialog_new-style": isNewDesign
      })}
      destroyOnClose={true}
      // destroyOnClose={rest.destroyOnClose ?? scrollStrategy == "dialogBody"}
      centered
      {...rest}
    />
  );
}
