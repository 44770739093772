import React from "react";
import { GeneralEmojisKeys } from "../../../constants/generalEmojis";
import { GeneralEmoji } from "./GeneralEmoji";
import { IListGeneralEmoji } from "../../../interfaces/Reactions.interface";
import { AddReactionButton } from "../../AddReactionButton";

export const ListGeneralEmojis = (props: IListGeneralEmoji) => {
  const handleReact = (emoji: GeneralEmojisKeys) => {
    props.onReact(emoji);
    props.onClose();
  };

  return (
    <div className={`d-stack-row align-center ${!props.hideGeneralEmoji && "pa-2"}`}>
      {!props.hideGeneralEmoji &&
        Object.values(GeneralEmojisKeys).map((item) => (
          <GeneralEmoji isDisabled={props.isDisabled} onReact={handleReact} key={item} emoji={item} />
        ))}
      <AddReactionButton
        disabled={props.isDisabled}
        onClick={props.onOpenPicker}
        iconSize={props.iconSize}
        isNewStyle={props.isNewStyle}
      />
    </div>
  );
};
