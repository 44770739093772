import React, { memo, useMemo } from "react";
import { IssueActivityText } from "../issueActivityText/issueActivityText";
import { IIssueActivityTextRow } from "./issueActivityTextRow.interface";
import { Icon } from "../../../../../../../uiKit";
import { ChevronRight } from "lucide-react";
import "./issueActivityTextRow.scss";
import { useTranslation } from "react-i18next";
import { initialEditorContent } from "../../../../../../../../utils/textEditor";

export const IssueActivityTextRow = memo((props: IIssueActivityTextRow) => {
  const { t } = useTranslation();

  const isEmptyPrevText = useMemo(() => {
    return !props.prevValue || props.prevValue === initialEditorContent
  },[props.prevValue]);

  const isEmptyValueText = useMemo(() => {
    return !props.value || props.value === initialEditorContent
  },[props.value]);

  const valueText = useMemo(() => {
    const value = props.value && props.isTime && props.isInlineText
      ?  props.value.split(":").slice(0, 2).join(":")
      : props.value
    return isEmptyValueText ? t("parse:no") : value;
  }, [isEmptyValueText, props.value, props.isTime, props.isInlineText]);

  const prevText = useMemo(() => {
    const value = props.prevValue && props.isTime && props.isInlineText
      ?  props.prevValue.split(":").slice(0, 2).join(":")
      : props.prevValue
    return isEmptyPrevText ? t("parse:no") : value;
  }, [isEmptyPrevText, props.prevValue, props.isTime, props.isInlineText]);


  const prevTextBackground = useMemo(() => {
    return props.prevValue ? "var(--color-calendar-default1)" : undefined
  }, [props.prevValue]);

  const textBackground = useMemo(() => {
    if (isEmptyValueText) {
      return undefined;
    }
    return props.prevValue ? "var(--color-calendar-primary-weaker1)" : "var(--color-calendar-default1)"
  }, [props.prevValue]);

  return (
    <div className={"issue-activity-text-row"}>
      <IssueActivityText
        background={prevTextBackground}
        text={prevText}
        isInlineText={!isEmptyPrevText ? props.isInlineText : true}
        color={isEmptyPrevText ? "var(--color-calendar-text-base)" : undefined}
      />
      <Icon
        component={() => <ChevronRight size={16}/>}
      />
      <IssueActivityText
        background={textBackground}
        text={valueText}
        isInlineText={props.isInlineText}
        // color={isEmptyValueText ? "var(--color-calendar-text-base)" : undefined}
      />
    </div>
 )
})
