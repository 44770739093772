import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useRootStore, useTheme } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { Badge, ButtonFloat, Icon, IconButton, Select, SelectOption } from "../../../../uiKit";
import { FiMoon, FiSun } from "react-icons/fi";
import SubheaderText from "../../../../elements/subheaderText/SubheaderText";
import { Portal } from "react-portal";
import { ThemeSwitchSuggestionDialog } from "../../../dialogs/themeSwitchSuggestionDialog/ThemeSwitchSuggestionDialog";
import dayjs from "dayjs";
import { FiClock } from "@react-icons/all-files/fi/FiClock";
import { api } from "../../../../../services";

interface IThemeSwitcher {
  displayMode?: "select" | "button";
}

function ThemeSwitcherObserved(props: IThemeSwitcher) {
  const { t, i18n } = useTranslation();
  const { appStore, devStore, authStore } = useRootStore();
  const { color } = useTheme();
  const appThemesArray = [
    { id: 0, name: t("parse:theme.light") },
    { id: 1, name: t("parse:theme.dark") },
  ];

  const [themeId, setThemeId] = useState<number | null>(appStore.getAppTheme ?? null);
  const [isSuggestionDialogUsed, setIsSuggestionDialogUsed] = useLocalStorage<boolean>(
    "themeSuggestionDialogUsed",
    false
  );
  const [isSuggestionDialogOpen, setIsSuggestionDialogOpen] = useState<boolean>(false);

  const handleThemeChange = (id: number) => {
    appStore.setAppTheme(id);
    // setIsSuggestionDialogUsed(true);
  };

  const handleThemeColorsChange = (themeId: number | null) => {
    window.carrotquest && (window.carrotquest.darkTheme = themeId == 1);
    // const r = document.querySelector<HTMLElement>(":root");
    // console.log(getComputedStyle(r!));
    // r!.style!.setProperty("--color-primary", themeId == 0 ? "red" : "green");
  };

  const handleUserTZChange = async () => {
    if (authStore.getInitialInfo?.identity == null) return;
    await api.user.edit(authStore.getInitialInfo?.identity?.id!, {
      ...authStore.getInitialInfo.identity,
      timeZoneId: authStore.getInitialInfo?.identity?.timeZoneId == "Asia/Chita" ? "America/Los_Angeles" : "Asia/Chita",
    });
    await authStore.refreshInitialInfo();
  };

  // if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
  //   console.log("Seems like you prefers dark scheme...");
  // }

  useEffect(() => {
    if (themeId == null) {
      handleThemeChange(0);
    }
  }, []);

  useEffect(() => {
    if (
      !isSuggestionDialogUsed &&
      // appStore.getAppTheme != 1 &&
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      appStore.setAppTheme(1);
      setIsSuggestionDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    if (themeId != appStore.getAppTheme) {
      setThemeId(appStore.getAppTheme);
    }
    handleThemeColorsChange(appStore.getAppTheme);
  }, [appStore.getAppTheme]);

  useEffect(() => {
    document.documentElement.setAttribute("data-app-theme-id", (themeId ?? 0).toString());
    // for (const cssVarObj of generateCSSVariables({ color }).variables) {
    //   document.documentElement.style.setProperty(cssVarObj.name, cssVarObj.value);
    // }
    // console.log(generateCSSVariables({ color }).strings);
  }, [themeId]);

  return (
    <>
      <ThemeSwitchSuggestionDialog
        isOpen={isSuggestionDialogOpen}
        onClose={() => {
          setIsSuggestionDialogOpen(false);
          setIsSuggestionDialogUsed(true);
        }}
        onDecline={() => {
          appStore.setAppTheme(0);
          setIsSuggestionDialogOpen(false);
          setIsSuggestionDialogUsed(true);
        }}
      />
      {(devStore.getIsThemeSwitcherFloatButtonVisible || window.location?.hostname == "localhost") && (
        <Portal>
          <ButtonFloat
            style={{ zIndex: 2147483647, bottom: 104 }}
            onClick={handleUserTZChange}
            description={<span style={{ fontSize: "10px" }} children={dayjs().tz().utcOffset() / 60} />}
            shape="square"
            className="printHidden"
            icon={<Icon component={() => <FiClock opacity={0.7} fontSize={20} />} />}
          />
          <ButtonFloat
            style={{ zIndex: 2147483647 }}
            onClick={() => handleThemeChange(themeId == 1 ? 0 : 1)}
            className="printHidden"
            icon={
              <Icon
                component={() =>
                  themeId == 1 ? <FiSun opacity={0.7} fontSize={20} /> : <FiMoon opacity={0.7} fontSize={20} />
                }
              />
            }
          />
        </Portal>
      )}
      {props.displayMode == "button" ? (
        <Badge
          count="β"
          size="small"
          offset={[-18, 18]}
          style={{ opacity: 0.85, transform: "scale(0.8)", pointerEvents: "none" }}
          // style={{ minWidth: "10px", height: "10px", fontSize: "8px", lineHeight: "1em" }}
          // style={{ backgroundColor: "transparent", color: "var(--color-error-base)" }}
        >
          <IconButton
            className="flex-shrink-0"
            onClick={() => handleThemeChange(themeId == 1 ? 0 : 1)}
            icon={
              <Icon
                component={() =>
                  themeId == 1 ? <FiSun opacity={0.7} fontSize={20} /> : <FiMoon opacity={0.7} fontSize={20} />
                }
              />
            }
          />
        </Badge>
      ) : (
        <>
          <SubheaderText text={t("ui:subheader.app_theme") + " (β)"} />
          <Select value={themeId} fullWidth onChange={(value) => handleThemeChange(Number(value))}>
            {appThemesArray.map((theme) => (
              <SelectOption key={theme.id} value={theme.id}>
                {theme.name}
              </SelectOption>
            ))}
          </Select>
        </>
      )}
    </>
  );
}

export const ThemeSwitcher = observer(ThemeSwitcherObserved);
